import {
  DocumentLocale,
  EntitlementType,
  LivePlusUiModuleBody,
  LivePlusUiModuleBodyModuleTypeEnum
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankLivePlusModule: NewModuleFunction<LivePlusUiModuleBody> = (module) =>
  <LivePlusUiModuleBody>{
    moduleType: LivePlusUiModuleBodyModuleTypeEnum.LIVE_PLUS,
    moduleId: generateId(),
    title: {
      [DocumentLocale.ES]: ''
    },
    entitlements: [EntitlementType.ANON, EntitlementType.FREE, EntitlementType.SUB],
    ...module
  };
