import React, { forwardRef } from 'react';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { BackgroundBody, SpinPremiaUiModuleBody } from '../../../../../API';
import { FormBody, FormField, SectionHeader } from '../../styles';
import { useLocales, useTheme } from '../../../../../hooks';
import { Controller, Path, useFormContext } from 'react-hook-form';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { LocaleKeys } from '../../../../../locales/i18n';
import { FormControlLabel, Switch } from '@mui/material';
import generateId from '../../../../../utils/generateId';

export const testIds = {
  formBody: 'spin-premia.form-body',
  formField: (name: Path<SpinPremiaUiModuleBody> | keyof BackgroundBody): string => `spin-premia.form-field.${name}`
};

type SpinPremiaFieldProps = {
  name: keyof SpinPremiaUiModuleBody;
  required?: boolean;
  'data-testid'?: string;
};
interface SpinPremiaBackgroundFieldProps extends Omit<SpinPremiaFieldProps, 'name'> {
  name: keyof BackgroundBody;
  required?: boolean;
  'data-testid'?: string;
}

const SpinPremiaForm = forwardRef<UIModuleTypeForm<SpinPremiaUiModuleBody>>(() => {
  const { control, watch } = useFormContext<SpinPremiaUiModuleBody>();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const moduleId = watch('moduleId');

  const AssetPicker = ({
    name,
    assetType,
    required = false
  }: SpinPremiaFieldProps & { assetType: AssetTypesEnum }): React.ReactElement => (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormField>
          <AssetBrowser
            required={required}
            label={t(`layouts.spin_premia.fields.${name}`)}
            assetId={moduleId || ''}
            assetType={assetType}
            value={value as string}
            data-testid={testIds.formField(name)}
            hasError={!!error}
            onChange={onChange}
          />
        </FormField>
      )}
    />
  );

  const BackgroundAssetPicker = ({
    name,
    assetType,
    required = false
  }: SpinPremiaBackgroundFieldProps & { assetType: AssetTypesEnum }): React.ReactElement => (
    <Controller
      control={control}
      name={`background.${name}`}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormField>
          <AssetBrowser
            required={required}
            label={t(`layouts.spin_premia.fields.${name}`)}
            assetId={moduleId || ''}
            assetType={assetType}
            value={value as string}
            data-testid={testIds.formField(name)}
            hasError={!!error}
            onChange={onChange}
          />
        </FormField>
      )}
    />
  );

  const LokaliseField = (name: Path<SpinPremiaUiModuleBody>, required = false, label?: string): React.ReactElement => {
    label = !label ? `layouts.spin_premia.fields.${name}` : `layouts.spin_premia.labels.${label}`;

    return (
      <FormField>
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange } }) => (
            <LokaliseAutocomplete
              label={t(label as LocaleKeys)}
              name={name}
              value={value as string}
              onChange={onChange}
              required={required}
              data-testid={testIds.formField(name)}
            />
          )}
        />
      </FormField>
    );
  };

  const ToggleField = ({
    name
  }: {
    name: keyof Pick<SpinPremiaUiModuleBody, 'planTypeToggle' | 'checkbox'>;
  }): React.ReactElement => (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <FormControlLabel
          control={<Switch color={formControlColor} checked={value} onChange={onChange} />}
          label={t(`layouts.spin_premia.fields.${name}`)}
        />
      )}
    />
  );

  return (
    <FormBody data-testid={testIds.formBody}>
      <div>
        <SectionHeader>{t('layouts.spin_premia.headers.logos')}</SectionHeader>
        <AssetPicker name="vixLogo" assetType={AssetTypes.spinPremia.vixLogo} required />
        <AssetPicker name="spinLogo" assetType={AssetTypes.spinPremia.spinLogo} required />
      </div>
      <div>
        <SectionHeader>{t('layouts.spin_premia.headers.background')}</SectionHeader>
        <BackgroundAssetPicker name="portraitFillImage" assetType={AssetTypes.spinPremia.portrait} />
        <BackgroundAssetPicker name="landscapeFillImage" assetType={AssetTypes.spinPremia.landscape} />
        <BackgroundAssetPicker name="mobileFillImage" assetType={AssetTypes.spinPremia.mobile} />
        <BackgroundAssetPicker name="ctvFillImage" assetType={AssetTypes.spinPremia.ctv} />
        <BackgroundAssetPicker name="desktopFillImage" assetType={AssetTypes.spinPremia.desktop} />
        <BackgroundAssetPicker name="tabletFillImage" assetType={AssetTypes.spinPremia.tablet} />
        <FormField>
          <Controller
            control={control}
            name={'background.id'}
            defaultValue={generateId()}
            render={({ field: { value, onChange } }) => <input type="hidden" value={value} onChange={onChange} />}
          />
        </FormField>
      </div>
      <div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.custom_plan_picker')}</SectionHeader>
          {LokaliseField('planPickerHeader', true)}
          {LokaliseField('planPickerSubHeader')}
          {LokaliseField('planTypeOpt1')}
          {LokaliseField('planTypeOpt2')}
          {LokaliseField('planName')}
          {LokaliseField('planCostPoint')}
          {LokaliseField('planDescription')}
          {LokaliseField('planValueProp')}
          {LokaliseField('planValuePropSeeMoreCta')}
          {LokaliseField('planValuePropSeeLessCta')}
          {LokaliseField('planCta')}
          {LokaliseField('legalCopy', true)}
          <ToggleField name="planTypeToggle" />
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.otp1')}</SectionHeader>
          {LokaliseField('otp1Header', true)}
          {LokaliseField('otp1SubHeader')}
          {LokaliseField('opt1CountryCode', true)}
          {LokaliseField('opt1MobileHint', true)}
          {LokaliseField('opt1Cta', true)}
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.otp2')}</SectionHeader>
          {LokaliseField('otp2Header', true)}
          {LokaliseField('otp2SubHeader')}
          {LokaliseField('opt2EditNumber', true)}
          {LokaliseField('opt2ExpirationNotice', true)}
          {LokaliseField('opt2ResendCode', true)}
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.plan_summary')}</SectionHeader>
          {LokaliseField('summaryHeader', true)}
          {LokaliseField('summarySubHeader')}
          {LokaliseField('summaryPlanName', true)}
          {LokaliseField('summaryPlanDescription')}
          {LokaliseField('summaryPlanSku', true)}
          {LokaliseField('summaryPlanPoints', true)}
          {LokaliseField('summaryPlanTotal', true)}
          {LokaliseField('summaryReminderHeader', true)}
          {LokaliseField('summaryStartDate', true)}
          {LokaliseField('summaryEndDate', true)}
          {LokaliseField('summaryLegalCopy', true)}
          {LokaliseField('summaryCta1', true)}
          {LokaliseField('summaryCta2', true)}
          <ToggleField name="checkbox" />
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.used_account_error')}</SectionHeader>
          {LokaliseField('usedAccountError.header', true, 'header')}
          {LokaliseField('usedAccountError.body', true, 'body')}
          {LokaliseField('usedAccountError.fstCta', true, 'fstCta')}
          {LokaliseField('usedAccountError.sndCta', true, 'sndCta')}
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.unregistered_number_error')}</SectionHeader>
          {LokaliseField('unregisteredNumberError.header', true, 'header')}
          {LokaliseField('unregisteredNumberError.body', true, 'body')}
          {LokaliseField('unregisteredNumberError.fstCta', true, 'fstCta')}
          {LokaliseField('unregisteredNumberError.sndCta', true, 'sndCta')}
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.funds_error')}</SectionHeader>
          {LokaliseField('fundsError.header', true, 'header')}
          {LokaliseField('fundsError.body', true, 'body')}
          {LokaliseField('fundsError.fstCta', true, 'fstCta')}
          {LokaliseField('fundsError.sndCta', true, 'sndCta')}
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.failed_error')}</SectionHeader>
          {LokaliseField('failedError.header', true, 'header')}
          {LokaliseField('failedError.body', true, 'body')}
          {LokaliseField('failedError.fstCta', true, 'fstCta')}
          {LokaliseField('failedError.sndCta', true, 'sndCta')}
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.linked_account_confirmation')}</SectionHeader>
          {LokaliseField('linkedAccountConfirmation.body', true, 'body')}
          {LokaliseField('linkedAccountConfirmation.ack', true, 'ack')}
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.unlinked_account_confirmation')}</SectionHeader>
          {LokaliseField('unlinkedAccountConfirmation.body', true, 'body')}
          {LokaliseField('unlinkedAccountConfirmation.ack', true, 'ack')}
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.unlink_account')}</SectionHeader>
          {LokaliseField('unlinkAccount.header', true, 'header')}
          {LokaliseField('unlinkAccount.body', true, 'body')}
          {LokaliseField('unlinkAccount.fstCta', true, 'fstCta')}
          {LokaliseField('unlinkAccount.sndCta', true, 'sndCta')}
        </div>
      </div>
    </FormBody>
  );
});

SpinPremiaForm.displayName = 'SpinPremiaForm';

export default SpinPremiaForm;
