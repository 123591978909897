/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiPaginatedResponseOfMediaResponse } from '../model';
// @ts-ignore
import { ApiResponseOfMediaResponse } from '../model';
// @ts-ignore
import { ApiResponseOfMediaResponseArray } from '../model';
// @ts-ignore
import { GetAllMediaQuery } from '../model';
// @ts-ignore
import { MediaExportQuery } from '../model';
// @ts-ignore
import { SmartQueryBody } from '../model';
// @ts-ignore
import { UiModuleSportsEventType } from '../model';
/**
 * MediaApi - axios parameter creator
 * @export
 */
export const MediaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Export all media results of provided query in a csv file.
         * @param {MediaExportQuery} mediaExportQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCsv: async (mediaExportQuery: MediaExportQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mediaExportQuery' is not null or undefined
            assertParamExists('exportCsv', 'mediaExportQuery', mediaExportQuery)
            const localVarPath = `/api/v1/media/exportCsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mediaExportQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the the media using curated content and smart query content for collection
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByCollectionId: async (collectionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('getByCollectionId', 'collectionId', collectionId)
            const localVarPath = `/api/v1/media/collections/{collectionId}`
                .replace(`{${"collectionId"}}`, encodeURIComponent(String(collectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the extra images associated to a video asset.
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (contentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('getById', 'contentId', contentId)
            const localVarPath = `/api/v1/media/contents/{contentId}`
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the extra images associated to a video asset.
         * @param {Array<string>} [contentIds] List of content identifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIds: async (contentIds?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/media/getByIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentIds) {
                localVarQueryParameter['contentIds'] = contentIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all the information from a video and the different images associated with the asset.
         * @param {SmartQueryBody} smartQueryBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBySmartQuery: async (smartQueryBody: SmartQueryBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'smartQueryBody' is not null or undefined
            assertParamExists('getBySmartQuery', 'smartQueryBody', smartQueryBody)
            const localVarPath = `/api/v1/media/getBySmartQuery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(smartQueryBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves from content search the channels associated to the ids specified.
         * @param {Array<string>} [contentIds] List of content identifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelsByIds: async (contentIds?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/media/getChannelsByIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentIds) {
                localVarQueryParameter['contentIds'] = contentIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all the information from a video and the different images associated with the asset using provided eventsType and optional leagueIds & sportIds
         * @param {UiModuleSportsEventType} eventsType 
         * @param {Array<string>} [leagueIds] Leagues\&#39; identifiers
         * @param {Array<string>} [sportIds] Sports\&#39; identifiers
         * @param {Array<string>} [allowedCountries] List of search country codes
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMatches: async (eventsType: UiModuleSportsEventType, leagueIds?: Array<string>, sportIds?: Array<string>, allowedCountries?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventsType' is not null or undefined
            assertParamExists('getMatches', 'eventsType', eventsType)
            const localVarPath = `/api/v1/media/matches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (eventsType !== undefined) {
                localVarQueryParameter['eventsType'] = eventsType;
            }

            if (leagueIds) {
                localVarQueryParameter['leagueIds'] = leagueIds;
            }

            if (sportIds) {
                localVarQueryParameter['sportIds'] = sportIds;
            }

            if (allowedCountries) {
                localVarQueryParameter['allowedCountries'] = allowedCountries;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all the information from a video and the different images associated with the asset using provided matchIds
         * @param {Array<string>} matchIds List of match identifiers
         * @param {boolean} [excludeExpired] Flag that indicates if expired events will be excluded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatchesByIds: async (matchIds: Array<string>, excludeExpired?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchIds' is not null or undefined
            assertParamExists('getMatchesByIds', 'matchIds', matchIds)
            const localVarPath = `/api/v1/media/matches/getByIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (matchIds) {
                localVarQueryParameter['matchIds'] = matchIds;
            }

            if (excludeExpired !== undefined) {
                localVarQueryParameter['excludeExpired'] = excludeExpired;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves paginated information from a video and the different images associated with the asset using provided eventsType and optional leagueIds & sportIds
         * @param {UiModuleSportsEventType} eventsType 
         * @param {number} limit 
         * @param {number} page Search result page
         * @param {Array<string>} [leagueIds] Leagues\&#39; identifiers
         * @param {Array<string>} [sportIds] Sports\&#39; identifiers
         * @param {Array<string>} [allowedCountries] List of search country codes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatchesV2: async (eventsType: UiModuleSportsEventType, limit: number, page: number, leagueIds?: Array<string>, sportIds?: Array<string>, allowedCountries?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventsType' is not null or undefined
            assertParamExists('getMatchesV2', 'eventsType', eventsType)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getMatchesV2', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getMatchesV2', 'page', page)
            const localVarPath = `/api/v2/media/matches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (eventsType !== undefined) {
                localVarQueryParameter['eventsType'] = eventsType;
            }

            if (leagueIds) {
                localVarQueryParameter['leagueIds'] = leagueIds;
            }

            if (sportIds) {
                localVarQueryParameter['sportIds'] = sportIds;
            }

            if (allowedCountries) {
                localVarQueryParameter['allowedCountries'] = allowedCountries;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all the information from a video and the different images associated with the asset.
         * @param {GetAllMediaQuery} getAllMediaQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated: async (getAllMediaQuery: GetAllMediaQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getAllMediaQuery' is not null or undefined
            assertParamExists('getPaginated', 'getAllMediaQuery', getAllMediaQuery)
            const localVarPath = `/api/v1/media`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getAllMediaQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves from content search the transmissions associated to the ids specified.
         * @param {Array<string>} [contentIds] List of content identifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransmissionsByIds: async (contentIds?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/media/getTransmissionsByIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentIds) {
                localVarQueryParameter['contentIds'] = contentIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaApi - functional programming interface
 * @export
 */
export const MediaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaApiAxiosParamCreator(configuration)
    return {
        /**
         * Export all media results of provided query in a csv file.
         * @param {MediaExportQuery} mediaExportQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportCsv(mediaExportQuery: MediaExportQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportCsv(mediaExportQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the the media using curated content and smart query content for collection
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByCollectionId(collectionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfMediaResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByCollectionId(collectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the extra images associated to a video asset.
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(contentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfMediaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the extra images associated to a video asset.
         * @param {Array<string>} [contentIds] List of content identifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIds(contentIds?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfMediaResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIds(contentIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all the information from a video and the different images associated with the asset.
         * @param {SmartQueryBody} smartQueryBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBySmartQuery(smartQueryBody: SmartQueryBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfMediaResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBySmartQuery(smartQueryBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves from content search the channels associated to the ids specified.
         * @param {Array<string>} [contentIds] List of content identifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannelsByIds(contentIds?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfMediaResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChannelsByIds(contentIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all the information from a video and the different images associated with the asset using provided eventsType and optional leagueIds & sportIds
         * @param {UiModuleSportsEventType} eventsType 
         * @param {Array<string>} [leagueIds] Leagues\&#39; identifiers
         * @param {Array<string>} [sportIds] Sports\&#39; identifiers
         * @param {Array<string>} [allowedCountries] List of search country codes
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getMatches(eventsType: UiModuleSportsEventType, leagueIds?: Array<string>, sportIds?: Array<string>, allowedCountries?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfMediaResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMatches(eventsType, leagueIds, sportIds, allowedCountries, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all the information from a video and the different images associated with the asset using provided matchIds
         * @param {Array<string>} matchIds List of match identifiers
         * @param {boolean} [excludeExpired] Flag that indicates if expired events will be excluded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMatchesByIds(matchIds: Array<string>, excludeExpired?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfMediaResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMatchesByIds(matchIds, excludeExpired, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves paginated information from a video and the different images associated with the asset using provided eventsType and optional leagueIds & sportIds
         * @param {UiModuleSportsEventType} eventsType 
         * @param {number} limit 
         * @param {number} page Search result page
         * @param {Array<string>} [leagueIds] Leagues\&#39; identifiers
         * @param {Array<string>} [sportIds] Sports\&#39; identifiers
         * @param {Array<string>} [allowedCountries] List of search country codes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMatchesV2(eventsType: UiModuleSportsEventType, limit: number, page: number, leagueIds?: Array<string>, sportIds?: Array<string>, allowedCountries?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfMediaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMatchesV2(eventsType, limit, page, leagueIds, sportIds, allowedCountries, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all the information from a video and the different images associated with the asset.
         * @param {GetAllMediaQuery} getAllMediaQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginated(getAllMediaQuery: GetAllMediaQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfMediaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginated(getAllMediaQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves from content search the transmissions associated to the ids specified.
         * @param {Array<string>} [contentIds] List of content identifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransmissionsByIds(contentIds?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfMediaResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransmissionsByIds(contentIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MediaApi - factory interface
 * @export
 */
export const MediaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaApiFp(configuration)
    return {
        /**
         * Export all media results of provided query in a csv file.
         * @param {MediaExportQuery} mediaExportQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCsv(mediaExportQuery: MediaExportQuery, options?: any): AxiosPromise<void> {
            return localVarFp.exportCsv(mediaExportQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the the media using curated content and smart query content for collection
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByCollectionId(collectionId: string, options?: any): AxiosPromise<ApiResponseOfMediaResponseArray> {
            return localVarFp.getByCollectionId(collectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the extra images associated to a video asset.
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(contentId: string, options?: any): AxiosPromise<ApiResponseOfMediaResponse> {
            return localVarFp.getById(contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the extra images associated to a video asset.
         * @param {Array<string>} [contentIds] List of content identifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIds(contentIds?: Array<string>, options?: any): AxiosPromise<ApiResponseOfMediaResponseArray> {
            return localVarFp.getByIds(contentIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all the information from a video and the different images associated with the asset.
         * @param {SmartQueryBody} smartQueryBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBySmartQuery(smartQueryBody: SmartQueryBody, options?: any): AxiosPromise<ApiResponseOfMediaResponseArray> {
            return localVarFp.getBySmartQuery(smartQueryBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves from content search the channels associated to the ids specified.
         * @param {Array<string>} [contentIds] List of content identifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelsByIds(contentIds?: Array<string>, options?: any): AxiosPromise<ApiResponseOfMediaResponseArray> {
            return localVarFp.getChannelsByIds(contentIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all the information from a video and the different images associated with the asset using provided eventsType and optional leagueIds & sportIds
         * @param {UiModuleSportsEventType} eventsType 
         * @param {Array<string>} [leagueIds] Leagues\&#39; identifiers
         * @param {Array<string>} [sportIds] Sports\&#39; identifiers
         * @param {Array<string>} [allowedCountries] List of search country codes
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMatches(eventsType: UiModuleSportsEventType, leagueIds?: Array<string>, sportIds?: Array<string>, allowedCountries?: Array<string>, options?: any): AxiosPromise<ApiResponseOfMediaResponseArray> {
            return localVarFp.getMatches(eventsType, leagueIds, sportIds, allowedCountries, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all the information from a video and the different images associated with the asset using provided matchIds
         * @param {Array<string>} matchIds List of match identifiers
         * @param {boolean} [excludeExpired] Flag that indicates if expired events will be excluded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatchesByIds(matchIds: Array<string>, excludeExpired?: boolean, options?: any): AxiosPromise<ApiResponseOfMediaResponseArray> {
            return localVarFp.getMatchesByIds(matchIds, excludeExpired, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves paginated information from a video and the different images associated with the asset using provided eventsType and optional leagueIds & sportIds
         * @param {UiModuleSportsEventType} eventsType 
         * @param {number} limit 
         * @param {number} page Search result page
         * @param {Array<string>} [leagueIds] Leagues\&#39; identifiers
         * @param {Array<string>} [sportIds] Sports\&#39; identifiers
         * @param {Array<string>} [allowedCountries] List of search country codes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatchesV2(eventsType: UiModuleSportsEventType, limit: number, page: number, leagueIds?: Array<string>, sportIds?: Array<string>, allowedCountries?: Array<string>, options?: any): AxiosPromise<ApiPaginatedResponseOfMediaResponse> {
            return localVarFp.getMatchesV2(eventsType, limit, page, leagueIds, sportIds, allowedCountries, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all the information from a video and the different images associated with the asset.
         * @param {GetAllMediaQuery} getAllMediaQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated(getAllMediaQuery: GetAllMediaQuery, options?: any): AxiosPromise<ApiPaginatedResponseOfMediaResponse> {
            return localVarFp.getPaginated(getAllMediaQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves from content search the transmissions associated to the ids specified.
         * @param {Array<string>} [contentIds] List of content identifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransmissionsByIds(contentIds?: Array<string>, options?: any): AxiosPromise<ApiResponseOfMediaResponseArray> {
            return localVarFp.getTransmissionsByIds(contentIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MediaApi - interface
 * @export
 * @interface MediaApi
 */
export interface MediaApiInterface {
    /**
     * Export all media results of provided query in a csv file.
     * @param {MediaExportQuery} mediaExportQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApiInterface
     */
    exportCsv(mediaExportQuery: MediaExportQuery, options?: any): AxiosPromise<void>;

    /**
     * Retrieves the the media using curated content and smart query content for collection
     * @param {string} collectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApiInterface
     */
    getByCollectionId(collectionId: string, options?: any): AxiosPromise<ApiResponseOfMediaResponseArray>;

    /**
     * Retrieves the extra images associated to a video asset.
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApiInterface
     */
    getById(contentId: string, options?: any): AxiosPromise<ApiResponseOfMediaResponse>;

    /**
     * Retrieves the extra images associated to a video asset.
     * @param {Array<string>} [contentIds] List of content identifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApiInterface
     */
    getByIds(contentIds?: Array<string>, options?: any): AxiosPromise<ApiResponseOfMediaResponseArray>;

    /**
     * Retrieves all the information from a video and the different images associated with the asset.
     * @param {SmartQueryBody} smartQueryBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApiInterface
     */
    getBySmartQuery(smartQueryBody: SmartQueryBody, options?: any): AxiosPromise<ApiResponseOfMediaResponseArray>;

    /**
     * Retrieves from content search the channels associated to the ids specified.
     * @param {Array<string>} [contentIds] List of content identifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApiInterface
     */
    getChannelsByIds(contentIds?: Array<string>, options?: any): AxiosPromise<ApiResponseOfMediaResponseArray>;

    /**
     * Retrieves all the information from a video and the different images associated with the asset using provided eventsType and optional leagueIds & sportIds
     * @param {UiModuleSportsEventType} eventsType 
     * @param {Array<string>} [leagueIds] Leagues\&#39; identifiers
     * @param {Array<string>} [sportIds] Sports\&#39; identifiers
     * @param {Array<string>} [allowedCountries] List of search country codes
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MediaApiInterface
     */
    getMatches(eventsType: UiModuleSportsEventType, leagueIds?: Array<string>, sportIds?: Array<string>, allowedCountries?: Array<string>, options?: any): AxiosPromise<ApiResponseOfMediaResponseArray>;

    /**
     * Retrieves all the information from a video and the different images associated with the asset using provided matchIds
     * @param {Array<string>} matchIds List of match identifiers
     * @param {boolean} [excludeExpired] Flag that indicates if expired events will be excluded
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApiInterface
     */
    getMatchesByIds(matchIds: Array<string>, excludeExpired?: boolean, options?: any): AxiosPromise<ApiResponseOfMediaResponseArray>;

    /**
     * Retrieves paginated information from a video and the different images associated with the asset using provided eventsType and optional leagueIds & sportIds
     * @param {UiModuleSportsEventType} eventsType 
     * @param {number} limit 
     * @param {number} page Search result page
     * @param {Array<string>} [leagueIds] Leagues\&#39; identifiers
     * @param {Array<string>} [sportIds] Sports\&#39; identifiers
     * @param {Array<string>} [allowedCountries] List of search country codes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApiInterface
     */
    getMatchesV2(eventsType: UiModuleSportsEventType, limit: number, page: number, leagueIds?: Array<string>, sportIds?: Array<string>, allowedCountries?: Array<string>, options?: any): AxiosPromise<ApiPaginatedResponseOfMediaResponse>;

    /**
     * Retrieves all the information from a video and the different images associated with the asset.
     * @param {GetAllMediaQuery} getAllMediaQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApiInterface
     */
    getPaginated(getAllMediaQuery: GetAllMediaQuery, options?: any): AxiosPromise<ApiPaginatedResponseOfMediaResponse>;

    /**
     * Retrieves from content search the transmissions associated to the ids specified.
     * @param {Array<string>} [contentIds] List of content identifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApiInterface
     */
    getTransmissionsByIds(contentIds?: Array<string>, options?: any): AxiosPromise<ApiResponseOfMediaResponseArray>;

}

/**
 * MediaApi - object-oriented interface
 * @export
 * @class MediaApi
 * @extends {BaseAPI}
 */
export class MediaApi extends BaseAPI implements MediaApiInterface {
    /**
     * Export all media results of provided query in a csv file.
     * @param {MediaExportQuery} mediaExportQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public exportCsv(mediaExportQuery: MediaExportQuery, options?: any) {
        return MediaApiFp(this.configuration).exportCsv(mediaExportQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the the media using curated content and smart query content for collection
     * @param {string} collectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getByCollectionId(collectionId: string, options?: any) {
        return MediaApiFp(this.configuration).getByCollectionId(collectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the extra images associated to a video asset.
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getById(contentId: string, options?: any) {
        return MediaApiFp(this.configuration).getById(contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the extra images associated to a video asset.
     * @param {Array<string>} [contentIds] List of content identifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getByIds(contentIds?: Array<string>, options?: any) {
        return MediaApiFp(this.configuration).getByIds(contentIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all the information from a video and the different images associated with the asset.
     * @param {SmartQueryBody} smartQueryBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getBySmartQuery(smartQueryBody: SmartQueryBody, options?: any) {
        return MediaApiFp(this.configuration).getBySmartQuery(smartQueryBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves from content search the channels associated to the ids specified.
     * @param {Array<string>} [contentIds] List of content identifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getChannelsByIds(contentIds?: Array<string>, options?: any) {
        return MediaApiFp(this.configuration).getChannelsByIds(contentIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all the information from a video and the different images associated with the asset using provided eventsType and optional leagueIds & sportIds
     * @param {UiModuleSportsEventType} eventsType 
     * @param {Array<string>} [leagueIds] Leagues\&#39; identifiers
     * @param {Array<string>} [sportIds] Sports\&#39; identifiers
     * @param {Array<string>} [allowedCountries] List of search country codes
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getMatches(eventsType: UiModuleSportsEventType, leagueIds?: Array<string>, sportIds?: Array<string>, allowedCountries?: Array<string>, options?: any) {
        return MediaApiFp(this.configuration).getMatches(eventsType, leagueIds, sportIds, allowedCountries, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all the information from a video and the different images associated with the asset using provided matchIds
     * @param {Array<string>} matchIds List of match identifiers
     * @param {boolean} [excludeExpired] Flag that indicates if expired events will be excluded
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getMatchesByIds(matchIds: Array<string>, excludeExpired?: boolean, options?: any) {
        return MediaApiFp(this.configuration).getMatchesByIds(matchIds, excludeExpired, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves paginated information from a video and the different images associated with the asset using provided eventsType and optional leagueIds & sportIds
     * @param {UiModuleSportsEventType} eventsType 
     * @param {number} limit 
     * @param {number} page Search result page
     * @param {Array<string>} [leagueIds] Leagues\&#39; identifiers
     * @param {Array<string>} [sportIds] Sports\&#39; identifiers
     * @param {Array<string>} [allowedCountries] List of search country codes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getMatchesV2(eventsType: UiModuleSportsEventType, limit: number, page: number, leagueIds?: Array<string>, sportIds?: Array<string>, allowedCountries?: Array<string>, options?: any) {
        return MediaApiFp(this.configuration).getMatchesV2(eventsType, limit, page, leagueIds, sportIds, allowedCountries, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all the information from a video and the different images associated with the asset.
     * @param {GetAllMediaQuery} getAllMediaQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getPaginated(getAllMediaQuery: GetAllMediaQuery, options?: any) {
        return MediaApiFp(this.configuration).getPaginated(getAllMediaQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves from content search the transmissions associated to the ids specified.
     * @param {Array<string>} [contentIds] List of content identifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getTransmissionsByIds(contentIds?: Array<string>, options?: any) {
        return MediaApiFp(this.configuration).getTransmissionsByIds(contentIds, options).then((request) => request(this.axios, this.basePath));
    }
}
