import React, { useEffect, useRef } from 'react';
import { useLayoutsRouter, useLocales, useTheme } from '../../../hooks';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { withLayoutsTypeLowercase, withLayoutsType, withNewLayout } from '../../../state/Layouts';
import { InputAdornment, InputLabel, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Button from '../../shared/Button';
import Drawer from '../../shared/Drawer';
import { Help, SaveAlt } from '@mui/icons-material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { LayoutType, Page } from '../../../API';
import { CustomValidators } from '../../../utils/customValidators';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import { PermissionsLevel } from '../../../utils/types/permissionsTypes';
import { useData } from '../../../data-layer';
import FormControl from '../../shared/FormControl';
import PermissionsGroupSelector from '../../shared/PermissionsGroupSelector';
import { pageToPathParam } from '../../../utils/layouts';
import { DUMMY_LAYOUT_ID } from '../../../utils/appDefaults';
import TextValidator from '../../shared/TextValidator';
import { usePermissionsGuard } from '../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../state/theme';

const useStyles = makeStyles()((theme) => ({
  formBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    gap: theme.spacing(6)
  },
  textInput: {
    minWidth: 480,
    marginBottom: theme.spacing(4)
  },
  footerButton: {
    minWidth: 120,
    marginRight: theme.spacing(4)
  },
  urlContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4)
  },
  urlSection: {
    fontSize: '1.2em',
    marginRight: 4,
    marginBottom: theme.spacing(4)
  },
  permissionsGroupSelector: {
    marginTop: theme.spacing(6),
    width: 300
  }
}));

export const testIds = {
  formBody: 'page-form.form-body',
  saveButton: 'page-form.save-button',
  cancelButton: 'page-form.cancel-button',
  urlPathTextField: 'page-form.url-path-text-field'
};

const pageUniquenessRule = 'pageUniqueness';

function PageForm(): JSX.Element {
  const { canSave } = usePermissionsGuard({
    homepageOption: HomepageOptions.LAYOUTS
  });
  const formRef = useRef<ValidatorForm>(null);
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { updateLayoutsRoute } = useLayoutsRouter();
  const layoutsType = useRecoilValue(withLayoutsType);
  const layoutsTypeLowercase = useRecoilValue(withLayoutsTypeLowercase);
  const { hasAnyPermission } = usePermissions();

  const {
    pages: {
      state: { withFormMetadata, withRecordBucket },
      hook: { closeForm, savePage }
    }
  } = useData();
  const pagePages = useRecoilValue(withRecordBucket(LayoutType.PAGE));
  const menuPages = useRecoilValue(withRecordBucket(LayoutType.MENU));
  const specialPages = useRecoilValue(withRecordBucket(LayoutType.SPECIAL));
  const promoPages = useRecoilValue(withRecordBucket(LayoutType.PROMOS));

  const newLayout = useSetRecoilState(withNewLayout);
  const formMetadata = useRecoilValue(withFormMetadata);
  const isOpen = formMetadata.isShowingForm;
  const page = formMetadata.record;

  const { handleSubmit, control, reset } = useForm<Page>();

  useEffect(() => {
    if (page) {
      reset(page);
    }
  }, [page]);

  ValidatorForm.addValidationRule(pageUniquenessRule, (value) => {
    if (pagePages && menuPages && specialPages && promoPages) {
      const pages = pagePages.concat(menuPages).concat(specialPages).concat(promoPages);
      return pages.map((page) => page.urlPath).indexOf(`/${value}`) < 0;
    }
    return true;
  });

  const onSubmit: SubmitHandler<Page> = async (page) => {
    // Add the / to the front of the url path if needed
    const pageToSave = { ...page, urlPath: (/^\//.test(page.urlPath) ? '' : '/') + page.urlPath };
    await savePage(pageToSave);
    updateLayoutsRoute({ pageUrl: pageToPathParam(pageToSave), layoutId: DUMMY_LAYOUT_ID });
    newLayout(pageToSave);
  };

  return (
    <Drawer
      open={isOpen}
      onSubmit={handleSubmit(onSubmit)}
      formRef={formRef}
      onClose={closeForm}
      headerLeft={<Typography variant="h6">{t(`layouts.new_${layoutsTypeLowercase}`)}</Typography>}
      footerLeft={
        <Button
          endIcon={<SaveAlt />}
          type="submit"
          disabled={!hasAnyPermission(PermissionsLevel.UPSERT) || !canSave}
          data-testid={testIds.saveButton}
        >
          {t(`layouts.save_${layoutsTypeLowercase}`)}
        </Button>
      }
      footerRight={
        <Button color="grey" className={classes.footerButton} onClick={closeForm} data-testid={testIds.cancelButton}>
          {t('general.cancel')}
        </Button>
      }
    >
      <div className={classes.formBody} data-testid={testIds.formBody}>
        <div>
          <Typography variant="h6">{t(`layouts.${layoutsTypeLowercase}_key`)}</Typography>
          <div className={classes.urlContainer}>
            <div className={classes.urlSection}>{layoutsType !== LayoutType.MENU && 'vix.tv'}/</div>
            <Controller
              name="urlPath"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextValidator
                  className={classes.textInput}
                  color={formControlColor}
                  name="urlPath"
                  placeholder={t('general.slug_enter_valid')}
                  value={value}
                  onChange={onChange}
                  validators={['required', CustomValidators.isPagePathValid, pageUniquenessRule]}
                  errorMessages={[
                    t('general.field_is_required'),
                    t('general.page_path_is_required'),
                    t('layouts.page_url_uniqueness_text')
                  ]}
                  data-testid={testIds.urlPathTextField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={t('general.slug_help_text')} placement="top" arrow>
                          <Help />
                        </Tooltip>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
          </div>
          <FormControl className={classes.permissionsGroupSelector}>
            <InputLabel>{t('permissions.permissions_group')}</InputLabel>
            <Controller
              name="ownerPermissionsGroup"
              control={control}
              render={({ field: { onChange, value } }) => (
                <PermissionsGroupSelector value={value} onChange={onChange} required />
              )}
            />
          </FormControl>
        </div>
      </div>
    </Drawer>
  );
}

export default PageForm;
