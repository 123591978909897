import { amber, blue, cyan, deepPurple, indigo, lightBlue, lime, pink, purple, red, teal } from '@mui/material/colors';
import { createTheme, Theme } from '@mui/material/styles';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true;
  }
}

declare module '@mui/material' {
  interface Color {
    main: string;
    dark: string;
  }
}

export enum SpacingEnum {
  COMPACT = 'compact',
  BALANCED = 'balanced',
  SPACIOUS = 'spacious'
}

export type ThemeOptions = {
  darkMode: boolean;
  spacing: SpacingEnum;
  colors: {
    primary: string;
    secondary: string;
  };
};

const bgHue = 227;
const tint = 3;

export const themeColors = {
  navbar: `hsl(${bgHue}, 41%, 7%)`,
  error: red.A400,
  success: '#22aa00',
  orange: '#FF5900', // ViX orange,
  orangeGradient:
    'linear-gradient(141.31deg, #ca2173 0%, #f34c1b 24.49%, #ff5900 49.49%, #fc4d08 74.5%, #f52d1f 100.03%)',
  light: {
    default: `hsl(${bgHue}, ${tint}%, 98%)`,
    paper: `hsl(${bgHue}, ${tint}%, 93%)`,
    divider: 'rgba(0, 0, 0, 0.12)',
    countryBadgeColor: `hsl(${bgHue}, ${tint}%, 81%)`,
    buttonGrey: `hsl(${bgHue}, ${tint}%, 83%)`,
    scrollbar: {
      shadow: 'rgba(0,0,0,0.2)',
      track: `hsl(${bgHue}, ${tint}%, 88%)`,
      thumb: `hsl(${bgHue}, ${tint}%, 77%)`,
      simpleBar: `hsl(${bgHue}, ${tint}%, 60%)`
    }
  },
  dark: {
    default: `hsl(${bgHue}, ${tint}%, 13%)`,
    paper: `hsl(${bgHue}, ${tint}%, 19%)`,
    divider: 'rgba(255, 255, 255, 0.13)',
    countryBadgeColor: `hsl(${bgHue}, ${tint}%, 38%)`,
    buttonGrey: `hsl(${bgHue}, ${tint}%, 45%)`,
    scrollbar: {
      shadow: 'rgba(255,255,255,0.1)',
      track: `hsl(${bgHue}, ${tint}%, 25%)`,
      thumb: `hsl(${bgHue}, ${tint}%, 45%)`,
      simpleBar: `hsl(${bgHue}, ${tint}%, 60%)`
    }
  }
};

export const defaultPrimary = '#3f2c9e';

export const primaryColors = [
  defaultPrimary,
  deepPurple.A700,
  indigo.A700,
  blue[800],
  lightBlue[800],
  cyan[900],
  teal[600],
  amber.A700,
  themeColors.orange,
  pink[800],
  purple[600]
];

export const defaultSecondary = cyan.A700;

export const secondaryColors = [
  defaultSecondary,
  teal.A400,
  lime.A400,
  amber.A400,
  themeColors.orange,
  pink.A200,
  pink.A100,
  purple.A200,
  deepPurple.A100,
  blue.A200,
  lightBlue.A100
];

const spacingIncrements = [0, 1, 2, 3, 4, 6, 8, 12, 16, 20, 25, 30, 40, 50, 60];
const spacingMapping = {
  [SpacingEnum.COMPACT]: 3,
  [SpacingEnum.BALANCED]: 4,
  [SpacingEnum.SPACIOUS]: 5
};

export const createCustomTheme = (
  isDarkMode: boolean,
  primaryColor: string,
  secondaryColor: string,
  spacing: SpacingEnum
): Theme => {
  const whichMode = isDarkMode ? 'dark' : 'light';
  return createTheme({
    palette: {
      mode: whichMode,
      background: {
        default: themeColors[whichMode].default,
        paper: themeColors[whichMode].paper
      },
      divider: themeColors[whichMode].divider,
      grey: {
        main: themeColors[whichMode].buttonGrey
      },
      primary: {
        main: primaryColor
      },
      secondary: {
        main: secondaryColor
      },
      error: {
        main: themeColors.error
      },
      success: {
        main: themeColors.success
      }
    },
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'standard'
        }
      },
      MuiSelect: {
        defaultProps: {
          variant: 'standard'
        }
      }
    },
    spacing: spacingIncrements.map((x) => x * spacingMapping[spacing])
  });
};
