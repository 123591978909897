import { useEffect, useRef, useState } from 'react';
import axios from 'axios';

let activeRequestCount = 0;

export function useGlobalLoading(): { isLoading: boolean } {
  const requestInterceptor = useRef<number>();
  const responseInterceptor = useRef<number>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setupInterceptors();
    return destroyInterceptors;
  }, []);

  const incrementActiveRequestCount = () => {
    activeRequestCount++;
    setIsLoading(activeRequestCount > 0);
  };

  const decrementActiveRequestCount = () => {
    activeRequestCount--;
    setIsLoading(activeRequestCount > 0);
  };

  const setupInterceptors = () => {
    // Add a request interceptor
    requestInterceptor.current = axios.interceptors.request.use(
      function (config) {
        incrementActiveRequestCount();
        return config;
      },
      function (error) {
        decrementActiveRequestCount();
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    responseInterceptor.current = axios.interceptors.response.use(
      function (response) {
        decrementActiveRequestCount();
        return response;
      },
      function (error) {
        decrementActiveRequestCount();
        return Promise.reject(error);
      }
    );
  };

  const destroyInterceptors = () => {
    if (requestInterceptor.current !== undefined) {
      axios.interceptors.request.eject(requestInterceptor.current);
    }
    if (responseInterceptor.current !== undefined) {
      axios.interceptors.response.eject(responseInterceptor.current);
    }
  };

  return {
    isLoading
  };
}
