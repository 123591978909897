/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiResponseOfDataExchangeResponseArray } from '../model';
// @ts-ignore
import { ApiResponseOfImportEntityResponse } from '../model';
// @ts-ignore
import { ApiResponseOfValidateImportEntityResponse } from '../model';
// @ts-ignore
import { ExportEntityType } from '../model';
// @ts-ignore
import { ImportBody } from '../model';
/**
 * DataExchangeApi - axios parameter creator
 * @export
 */
export const DataExchangeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {ExportEntityType} [entityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEntity: async (id: string, entityType?: ExportEntityType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('exportEntity', 'id', id)
            const localVarPath = `/api/v1/dataExchange/exportEntity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dataExchange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ImportBody} importBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importEntity: async (importBody: ImportBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'importBody' is not null or undefined
            assertParamExists('importEntity', 'importBody', importBody)
            const localVarPath = `/api/v1/dataExchange/importEntity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ImportBody} importBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateImportEntity: async (importBody: ImportBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'importBody' is not null or undefined
            assertParamExists('validateImportEntity', 'importBody', importBody)
            const localVarPath = `/api/v1/dataExchange/validateImportEntity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataExchangeApi - functional programming interface
 * @export
 */
export const DataExchangeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataExchangeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {ExportEntityType} [entityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportEntity(id: string, entityType?: ExportEntityType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportEntity(id, entityType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfDataExchangeResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ImportBody} importBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importEntity(importBody: ImportBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfImportEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importEntity(importBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ImportBody} importBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateImportEntity(importBody: ImportBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfValidateImportEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateImportEntity(importBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataExchangeApi - factory interface
 * @export
 */
export const DataExchangeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataExchangeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {ExportEntityType} [entityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEntity(id: string, entityType?: ExportEntityType, options?: any): AxiosPromise<void> {
            return localVarFp.exportEntity(id, entityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: any): AxiosPromise<ApiResponseOfDataExchangeResponseArray> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ImportBody} importBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importEntity(importBody: ImportBody, options?: any): AxiosPromise<ApiResponseOfImportEntityResponse> {
            return localVarFp.importEntity(importBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ImportBody} importBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateImportEntity(importBody: ImportBody, options?: any): AxiosPromise<ApiResponseOfValidateImportEntityResponse> {
            return localVarFp.validateImportEntity(importBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataExchangeApi - interface
 * @export
 * @interface DataExchangeApi
 */
export interface DataExchangeApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {ExportEntityType} [entityType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataExchangeApiInterface
     */
    exportEntity(id: string, entityType?: ExportEntityType, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataExchangeApiInterface
     */
    getAll(options?: any): AxiosPromise<ApiResponseOfDataExchangeResponseArray>;

    /**
     * 
     * @param {ImportBody} importBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataExchangeApiInterface
     */
    importEntity(importBody: ImportBody, options?: any): AxiosPromise<ApiResponseOfImportEntityResponse>;

    /**
     * 
     * @param {ImportBody} importBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataExchangeApiInterface
     */
    validateImportEntity(importBody: ImportBody, options?: any): AxiosPromise<ApiResponseOfValidateImportEntityResponse>;

}

/**
 * DataExchangeApi - object-oriented interface
 * @export
 * @class DataExchangeApi
 * @extends {BaseAPI}
 */
export class DataExchangeApi extends BaseAPI implements DataExchangeApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {ExportEntityType} [entityType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataExchangeApi
     */
    public exportEntity(id: string, entityType?: ExportEntityType, options?: any) {
        return DataExchangeApiFp(this.configuration).exportEntity(id, entityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataExchangeApi
     */
    public getAll(options?: any) {
        return DataExchangeApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImportBody} importBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataExchangeApi
     */
    public importEntity(importBody: ImportBody, options?: any) {
        return DataExchangeApiFp(this.configuration).importEntity(importBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImportBody} importBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataExchangeApi
     */
    public validateImportEntity(importBody: ImportBody, options?: any) {
        return DataExchangeApiFp(this.configuration).validateImportEntity(importBody, options).then((request) => request(this.axios, this.basePath));
    }
}
