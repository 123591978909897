import React, { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash-es';
import {
  TextValidator as DefaultTextValidator,
  ValidatorComponentProps,
  ValidatorForm
} from 'react-material-ui-form-validator';
import { useTheme } from '../../../hooks';

export interface ITextValidatorProps extends ValidatorComponentProps {
  debounced?: boolean;
  debounceDelay?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  'data-testid'?: string;
  customValidationName?: string;
  customValidationFunction?: (e: string | undefined) => boolean;
}

export const testIds = {
  textValidator: 'text-validator'
};

function TextValidator({
  debounced = true,
  debounceDelay = 300,
  onChange,
  value,
  InputProps,
  'data-testid': dataTestId,
  customValidationName,
  customValidationFunction,
  ...props
}: ITextValidatorProps): JSX.Element {
  const [localValue, setLocalValue] = useState(value || '');
  const { formControlColor } = useTheme();

  useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  useEffect(() => {
    if (customValidationName && customValidationFunction) {
      ValidatorForm.addValidationRule(customValidationName, customValidationFunction);
    }

    return () => {
      if (customValidationName && customValidationFunction) {
        ValidatorForm.removeValidationRule(customValidationName);
      }
    };
  }, [customValidationName, customValidationFunction]);

  const fireOnChangeWithText = (inputValue: string) => {
    onChange?.({ target: { value: inputValue } } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleChangeDebounced = useMemo(() => debounce(fireOnChangeWithText, debounceDelay), [debounceDelay]);

  const onInputChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const enteredText = evt.target.value;
    setLocalValue(enteredText);
    if (debounced) {
      handleChangeDebounced(enteredText);
    } else {
      fireOnChangeWithText(enteredText);
    }
  };

  return (
    <DefaultTextValidator
      value={localValue}
      color={formControlColor}
      data-testid={dataTestId || testIds.textValidator}
      InputProps={{
        ...InputProps,
        onChange: onInputChange
      }}
      {...props}
    />
  );
}

export default TextValidator;
