import { Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ContentPreview } from '..';
import { LiveNewsModuleContentItem } from '../../../../../API';
import { useData } from '../../../../../data-layer';
import { useLocales } from '../../../../../hooks';
import { getAssetUrl } from '../../../../../utils/assetHelpers';
import { DisplayAsOptions } from '../../../../../utils/types/genericTypes';

export interface IChannelsPreviewProps {
  channelsContents: LiveNewsModuleContentItem[];
  showing: boolean;
}

const useStyles = makeStyles()(() => ({
  landscapeThumbnail: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  landscapeBackgroundImg: {
    width: '100%'
  }
}));

type ChannelsPreviewModel = {
  contentId: string;
  backgroundImage: string;
  title: string;
};

export const testIds = {
  thumbnail: 'live-news-preview.thumbnail'
};

const fallbackPath = '/assets/vix-landscape-fallback.jpeg';

export function ChannelsPreview({ channelsContents, showing }: IChannelsPreviewProps): React.ReactElement {
  const { classes } = useStyles();
  const {
    channels: {
      state: { withAllRecordsById },
      hook: { getByIds: getChannelsByIds }
    }
  } = useData();
  const { localize } = useLocales();
  const [previewItems, setPreviewItems] = useState<ChannelsPreviewModel[]>();
  const channelsById = useRecoilValue(withAllRecordsById);
  const [shouldPopulatePreviewItems, setShouldPopulatePreviewItems] = useState(false);
  const { t } = useLocales();

  useEffect(() => {
    if (showing) {
      fetchContentIfNeeded();
    }
  }, [showing, channelsContents]);

  useEffect(() => {
    if (shouldPopulatePreviewItems) {
      setShouldPopulatePreviewItems(false);
      populatePreviewItems();
    }
  }, [shouldPopulatePreviewItems]);

  const fetchContentIfNeeded = async () => {
    const channelIdsToFetch = channelsContents.map(({ contentId }) => contentId).filter((id) => !channelsById[id]);
    if (!channelIdsToFetch.length) {
      return setShouldPopulatePreviewItems(true);
    }
    setPreviewItems(undefined);
    await getChannelsByIds(channelIdsToFetch);
    setShouldPopulatePreviewItems(true);
  };

  const getBackgroundImage = (channelsContentItem: LiveNewsModuleContentItem) => {
    const background = localize(channelsById[channelsContentItem.contentId]?.landscapeImage);
    if (!background) return fallbackPath;
    return getAssetUrl(background);
  };

  const populatePreviewItems = () => {
    const newPreviewItems = channelsContents.map((channelsContentItem) => {
      const channelById = channelsById[channelsContentItem.contentId];
      return {
        contentId: channelsContentItem.contentId,
        backgroundImage: getBackgroundImage(channelsContentItem),
        title: channelById ? localize(channelById.title) : t('general.channel_not_found')
      };
    });
    setPreviewItems(newPreviewItems);
  };

  const ChannelsThumbnail = (contentItem: ChannelsPreviewModel) => {
    return (
      <Tooltip title={contentItem.title}>
        <div className={classes.landscapeThumbnail} data-testid={testIds.thumbnail}>
          <img className={classes.landscapeBackgroundImg} src={contentItem.backgroundImage} />
        </div>
      </Tooltip>
    );
  };

  return (
    <ContentPreview
      showing={showing}
      displayAs={DisplayAsOptions.LANDSCAPE}
      model={{
        contentList: previewItems,
        thumbnailComponent: ChannelsThumbnail,
        idField: 'contentId'
      }}
      loaderCount={channelsContents.length}
    />
  );
}
