import { isEqual } from 'lodash-es';
import { useNavigate, useParams } from 'react-router-dom';
import { LayoutType } from '../../API';
import { AppRoutes } from '../../Routes';

export type LayoutsPageParams = {
  pageUrl: string;
  countries: string;
  layoutId: string;
  type: LayoutType;
};

type UseLayoutsRouterReturnType = {
  updateLayoutsRoute: (newParams: Partial<LayoutsPageParams>, replace?: boolean) => void;
};

export function useLayoutsRouter(): UseLayoutsRouterReturnType {
  const params = useParams<LayoutsPageParams>();
  const navigate = useNavigate();

  const updateLayoutsRoute = (newParamsPartial: Partial<LayoutsPageParams>, replace = false) => {
    if (!params.pageUrl || !params.countries || !params.layoutId || !params.type) return;
    const newParams: LayoutsPageParams = {
      pageUrl: newParamsPartial.pageUrl || encodeURIComponent(params.pageUrl),
      countries: newParamsPartial.countries || params.countries,
      layoutId: newParamsPartial.layoutId || params.layoutId,
      type: newParamsPartial.type || params.type
    };
    // Only navigate if any of the params have changed
    if (!isEqual(newParams, { ...params, pageUrl: encodeURIComponent(params.pageUrl) })) {
      navigate(AppRoutes.layouts(newParams), { replace });
    }
  };

  return { updateLayoutsRoute };
}
