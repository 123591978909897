import React, { useEffect, useMemo, useState } from 'react';
import { Check, Close, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, FormControlLabel, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { range } from 'lodash-es';
import { DateTime } from 'luxon';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../../../../../hooks';
import { dayOfTheWeekLocalized } from '../../../../../../utils/dateHelpers';
import ClearableDatePicker from '../../../../../shared/ClearableDatePicker';
import FormControl from '../../../../../shared/FormControl';
import IconButton from '../../../../../shared/IconButton';
import { DocumentLocale } from '../../../../../../API';
import { useLokalise } from '../../../../../../hooks/General/useLokalise';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(2, 4),
    marginBottom: theme.spacing(4),
    border: `1px solid ${theme.palette.background.paper}`,
    borderRadius: theme.shape.borderRadius
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  headerDetails: {
    fontWeight: 'bold'
  },
  formSection: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center'
  },
  keySelector: {
    minWidth: 300,
    marginBottom: theme.spacing(4)
  },
  daySelector: {
    minWidth: 200,
    marginBottom: theme.spacing(4)
  },
  formLabel: {
    position: 'absolute',
    transform: 'translate(0, -1.5px) scale(0.75)'
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start'
  }
}));

type HeroMessageProps = {
  namePrefix?: string;
};

export const testIds = {
  collapse: 'hero-message-collapse',
  keySelector: 'hero-message.key-selector',
  keyOption: 'hero-message.key-option',
  daySelector: 'hero-message.day-selector',
  dayOption: 'hero-message.day-option',
  clearButton: 'hero-message.clear-button'
};

const isUsingDate = (text: string | undefined) => /_date_/.test(text ?? '');
const isUsingDay = (text: string | undefined) => /_dotw_/.test(text ?? '');

export function isHeroMessageValid(
  subtitleText: string | undefined,
  subtitleDate: string | undefined,
  subtitleDay: number | undefined
): boolean {
  const useDate = isUsingDate(subtitleText);
  const useDay = isUsingDay(subtitleText);
  if (useDate) {
    return !!subtitleDate;
  }
  if (useDay) {
    return subtitleDay !== undefined;
  }
  return true;
}

export function HeroMessage({ namePrefix }: HeroMessageProps): JSX.Element {
  const { control, watch, setValue } = useFormContext();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const { currentLang } = useLocales();
  const { getTranslations } = useLokalise();
  const heroMessages = getTranslations('hero_');
  const heroMessageKeys = Object.keys(heroMessages);
  const [showDayPicker, setShowDayPicker] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const getName = (fieldName: string) => (namePrefix ? `${namePrefix}.${fieldName}` : fieldName);

  const [subtitleText, subtitleDay, subtitleDate] = watch([
    getName('subtitleText'),
    getName('subtitleDay'),
    getName('subtitleDate')
  ]);

  const isValid = useMemo(
    () => isHeroMessageValid(subtitleText, subtitleDate, subtitleDay),
    [subtitleText, subtitleDay, subtitleDate]
  );

  useEffect(() => {
    const useDate = isUsingDate(subtitleText);
    const useDay = isUsingDay(subtitleText);
    setShowDatePicker(useDate);
    setShowDayPicker(useDay);
    if (useDate) {
      if (!subtitleDate) setValue(getName('subtitleDate'), '');
      setValue(getName('subtitleDay'), undefined);
    }
    if (useDay) {
      setValue(getName('subtitleDate'), undefined);
      if (!subtitleDay) setValue(getName('subtitleDay'), 0);
    }
    if (!useDate && !useDay) {
      setValue(getName('subtitleDate'), undefined);
      setValue(getName('subtitleDay'), undefined);
    }
  }, [subtitleText]);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClear = () => {
    setValue(getName('subtitleText'), '');
    setValue(getName('subtitleDay'), undefined);
    setValue(getName('subtitleDate'), undefined);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header} onClick={toggleExpanded} data-testid={testIds.collapse}>
        <FormControlLabel
          label={
            <Typography style={{ fontStyle: 'italic' }} color="textSecondary">
              {t(`layouts.hero_messages.hero_message${subtitleText ? '' : '_not_configured'}`)}
            </Typography>
          }
          control={isExpanded ? <ExpandLess /> : <ExpandMore />}
        />
        <div className={classes.headerDetails}>
          {heroMessages[subtitleText] &&
            heroMessages[subtitleText] +
              (showDayPicker && subtitleDay !== undefined
                ? dayOfTheWeekLocalized(subtitleDay, DocumentLocale.ES)
                : '') +
              (showDatePicker && subtitleDate
                ? DateTime.fromISO(subtitleDate)
                    .setLocale(DocumentLocale.ES)
                    .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
                : '')}
        </div>
        <div style={{ flexGrow: 1 }} />
        {isValid ? !!subtitleText && <Check color="success" /> : <Close color="error" />}
      </div>
      <Collapse in={isExpanded} timeout="auto">
        <div className={classes.formSection}>
          <Controller
            control={control}
            name={getName('subtitleText')}
            render={({ field: { value, onChange } }) => (
              <FormControl>
                <InputLabel className={classes.formLabel} shrink>
                  {t('layouts.translationKey')}
                </InputLabel>
                <Select
                  label={t('layouts.translationKey')}
                  className={classes.keySelector}
                  value={value}
                  onChange={onChange}
                  data-testid={testIds.keySelector}
                  data-test-value={value}
                  displayEmpty
                  defaultValue=""
                  renderValue={(value) => heroMessages[value] || t('general.none')}
                >
                  <MenuItem value="" data-testid={testIds.keyOption}>
                    <em>{t('general.none')}</em>
                  </MenuItem>
                  {heroMessageKeys?.map((lokaliseKey) => (
                    <MenuItem
                      key={lokaliseKey}
                      value={lokaliseKey}
                      className={classes.menuItem}
                      data-testid={testIds.keyOption}
                      data-value={lokaliseKey}
                    >
                      <Typography>{heroMessages[lokaliseKey] || lokaliseKey}</Typography>
                      <Typography variant="subtitle2" color="textSecondary">
                        {lokaliseKey}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          {showDatePicker && (
            <Controller
              name={getName('subtitleDate')}
              control={control}
              rules={{ required: t('general.field_is_required') }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <ClearableDatePicker
                  label={t('date_picker.date_label')}
                  value={value}
                  onChange={onChange}
                  clearable={false}
                  error={!!error}
                  helperText={error?.message}
                  minDate={DateTime.now().toISODate() || ''}
                />
              )}
            />
          )}
          {showDayPicker && (
            <Controller
              control={control}
              name={getName('subtitleDay')}
              render={({ field: { value, onChange } }) => (
                <FormControl>
                  <InputLabel className={classes.formLabel} shrink>
                    {t('date_picker.day_of_the_week')}
                  </InputLabel>
                  <Select
                    className={classes.daySelector}
                    value={value || 0}
                    defaultValue={0}
                    onChange={onChange}
                    data-testid={testIds.daySelector}
                    data-test-value={value}
                    renderValue={(value) =>
                      value !== undefined ? dayOfTheWeekLocalized(value, currentLang) : t('general.none')
                    }
                  >
                    {range(0, 7).map((number) => (
                      <MenuItem key={number} value={number} data-testid={testIds.dayOption}>
                        {dayOfTheWeekLocalized(number, currentLang)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          )}
          <div>
            {subtitleText && (
              <IconButton
                title={t('tooltips.clear')}
                onClick={handleClear}
                size="small"
                data-testid={testIds.clearButton}
              >
                <Close />
              </IconButton>
            )}
          </div>
        </div>
      </Collapse>
    </div>
  );
}
