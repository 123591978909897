import React from 'react';
import { LocalizedFieldBody } from '../../../API';
import { useLocales } from '../../../hooks';

type ReturnType = {
  fallback: LocalizedFieldBody;
  'data-testid'?: string;
};

export function LokaliseFallback({ fallback, 'data-testid': dataTestId = '' }: ReturnType): React.ReactElement {
  const { localize, t } = useLocales();
  return (
    <span data-testid={dataTestId} title={t('errors.lokalise.missing_lokalise_key')}>
      {localize(fallback)}
      &nbsp; *
    </span>
  );
}
