import React, { useState } from 'react';
import { StayPrimaryPortrait } from '@mui/icons-material';
import { LivePlusUiModuleBody } from '../../../../../API';
import { useLocales, useTheme } from '../../../../../hooks';
import Button from '../../../../shared/Button';
import Localized from '../../../../shared/Localized';
import { LivePlusPreview } from '../../ContentPreview/LivePlusPreview';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';

export type LivePlusListItemProps = IBaseUIModuleListItemProps<LivePlusUiModuleBody>;

export const testIds = {
  button: 'live-plus-list-item.button'
};

export function LivePlusListItem({ uiModule, ...props }: LivePlusListItemProps): JSX.Element {
  const { t } = useLocales();
  const [isPreviewShowing, setIsPreviewShowing] = useState(false);
  const { formControlColor } = useTheme();

  const onPreviewButtonClick = () => {
    setIsPreviewShowing(true);
  };

  return (
    <>
      <UIModuleListItem
        uiModule={uiModule}
        topLineTitle={<Localized prop={uiModule.title} />}
        collectionLine={[
          <Button
            key="preview"
            variant="text"
            size="small"
            startIcon={<StayPrimaryPortrait />}
            color={formControlColor}
            onClick={onPreviewButtonClick}
            data-testid={testIds.button}
          >
            {t('layouts.preview_contents')}
          </Button>
        ]}
        {...props}
      />
      <LivePlusPreview livePlusModule={uiModule} isOpen={isPreviewShowing} setIsOpen={setIsPreviewShowing} />
    </>
  );
}
