import React, { useEffect, useState } from 'react';
import { CircularProgress, LinearProgress, Stack, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useLocales, useStatsig, useTheme } from '../../../hooks';
import { Science, Visibility, VisibilityOff, Warning } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { withExperiments } from '../../../state/Experiments';
import { StatsigExperiment, UiModuleExperimentGroup } from '../../../API';

export interface IExperimentsBadgeProps {
  moduleExperiments: UiModuleExperimentGroup[];
  moduleCanUseExperiments?: boolean;
}

const progressSize = 28;

const useStyles = makeStyles()((theme) => ({
  progressContainer: {
    position: 'relative',
    display: 'inline-flex'
  },
  iconContainer: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tooltipHeader: {
    fontSize: '1.1em',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  experimentId: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2)
  },
  groupSize: {
    color: theme.palette.text.secondary
  },
  totalExposure: {
    margin: theme.spacing(3, 0, 1)
  }
}));

export function ExperimentsBadge({
  moduleExperiments,
  moduleCanUseExperiments
}: IExperimentsBadgeProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const experiments = useRecoilValue(withExperiments);
  const { getExperiments } = useStatsig();
  const { formControlColor } = useTheme();
  const [selectedExperiment, setSelectedExperiment] = useState<StatsigExperiment | undefined>();
  const [totalExposure, setTotalExposure] = useState(0);

  useEffect(() => {
    if (!experiments) {
      getExperiments();
    }
  }, []);

  useEffect(() => {
    if (experiments && moduleExperiments.length) {
      const newSelectedExperiment = experiments.find((exp) => exp.id === moduleExperiments[0].experimentId);
      setSelectedExperiment(newSelectedExperiment);
      let newTotalExposure = 0;
      newSelectedExperiment?.groups.forEach((group) => {
        if (moduleExperiments.find((value) => value.groupId === group.id)) {
          newTotalExposure += group.size;
        }
      });
      setTotalExposure(newTotalExposure);
    }
  }, [moduleExperiments, experiments]);

  return (
    <Tooltip
      placement="top"
      arrow
      title={
        selectedExperiment && (
          <>
            <div className={classes.tooltipHeader}>
              {!moduleCanUseExperiments && <Warning color="warning" />}
              {t(
                moduleCanUseExperiments
                  ? 'experiments.visibility_controlled'
                  : 'experiments.experiments_disabled_for_module_type'
              )}
            </div>
            <div className={classes.experimentId}>{selectedExperiment.id}</div>
            {selectedExperiment.groups.map((group) => {
              const isVisibleForGroup = !!moduleExperiments.find((value) => value.groupId === group.id);
              return (
                <Stack key={group.id} direction="row" gap={2} alignItems="center">
                  {isVisibleForGroup ? <Visibility /> : <VisibilityOff color="disabled" />}
                  <span>{group.name}</span>
                  <span className={classes.groupSize}>({group.size}%)</span>
                </Stack>
              );
            })}
            <div className={classes.totalExposure}>
              {t('experiments.total_exposure')} {totalExposure}%
            </div>
            <LinearProgress
              variant="determinate"
              color={moduleCanUseExperiments ? formControlColor : 'warning'}
              value={totalExposure}
            />
          </>
        )
      }
    >
      <Box className={classes.progressContainer}>
        <CircularProgress
          variant="determinate"
          value={totalExposure}
          size={progressSize}
          color={moduleCanUseExperiments ? formControlColor : 'warning'}
        />
        <Box className={classes.iconContainer}>
          <Science fontSize="small" color={moduleCanUseExperiments ? 'inherit' : 'disabled'} />
        </Box>
      </Box>
    </Tooltip>
  );
}
