import { DocumentLocale, TeamBody, TeamResponse } from '../../../../API';
import { localeCompareFunction, UseLocalesReturnType } from '../../../../hooks';
import { useTeamsAPI } from '../../../../hooks/API/Sports/useTeamsAPI';
import { CreateDataManager, DataManagerReturnType } from '../../../DataManager';
import { TeamsHook, TeamsHookReturnType } from './TeamsHook';
import { TeamsState, TeamsStateFullReturnType } from './TeamsState';

export type TeamsDataManagerType = {
  state: TeamsStateFullReturnType;
  hook: TeamsHookReturnType;
};

export interface TeamsDataManagerReturnType extends DataManagerReturnType<TeamResponse, TeamBody> {
  state: TeamsStateFullReturnType;
  hook: () => TeamsHookReturnType;
}

export const TeamsDataManager = CreateDataManager<TeamResponse, TeamBody, TeamsDataManagerReturnType>({
  stateInitializer: TeamsState,
  hookInitializer: TeamsHook,
  name: 'teams',
  idField: 'id',
  useApiHook: useTeamsAPI,
  generateNew: (sportId: string, tournamentId?: string) => ({
    name: {
      [DocumentLocale.ES]: ''
    },
    teamId: '',
    tournaments: tournamentId ? [tournamentId] : [],
    sport: sportId
  }),
  // Function used to generate a team string for success & error handling
  toString: (team: TeamBody | TeamResponse, locales: UseLocalesReturnType) =>
    `${locales.localize(team.name)} (${team.teamId})`,
  // Bucket stuff
  bucketIdToQueryParams: (bucketId: string) => [bucketId],
  getRecordBucketIds: (record: TeamResponse | TeamBody) => record.tournaments || [],
  // Curried function to initialize a sort function
  sortFunctionInitializer: (locales) => localeCompareFunction<TeamResponse>(locales, 'name')
});
