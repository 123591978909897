/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { EntitlementType } from './entitlement-type';
import { LocalizedFieldBody } from './localized-field-body';
import { TargetPlatform } from './target-platform';
import { UiModuleExperimentGroup } from './ui-module-experiment-group';

/**
 * 
 * @export
 * @interface LivePlusUiModuleBody
 */
export interface LivePlusUiModuleBody {
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    moduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    moduleType: LivePlusUiModuleBodyModuleTypeEnum;
    /**
     * 
     * @type {Array<TargetPlatform>}
     * @memberof LivePlusUiModuleBody
     */
    targetPlatform?: Array<TargetPlatform>;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof LivePlusUiModuleBody
     */
    caption?: LocalizedFieldBody;
    /**
     * 
     * @type {Array<EntitlementType>}
     * @memberof LivePlusUiModuleBody
     */
    entitlements?: Array<EntitlementType>;
    /**
     * 
     * @type {Array<UiModuleExperimentGroup>}
     * @memberof LivePlusUiModuleBody
     */
    experiments?: Array<UiModuleExperimentGroup> | null;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof LivePlusUiModuleBody
     */
    title: LocalizedFieldBody;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    landscapeTakeoverImage: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    portraitTakeoverImage: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    desktopTakeoverImage?: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    tabletTakeoverImage?: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    mobileTakeoverImage?: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    ctvTakeoverImage?: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    takeoverHeader: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    takeoverLead: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    takeoverExitCta: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    formHeader: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    formLead: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    formEmailPlaceholder: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    formEmailValidationError: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    formInvalidEmailValidationError?: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    formOptInLabel: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    formOptInValidationError: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    formSubmitCta: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    formCancelCta: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    landscapeEndGameImage: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    portraitEndGameImage: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    desktopEndGameImage?: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    tabletEndGameImage?: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    mobileEndGameImage?: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    ctvEndGameImage?: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    endGameHeader: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    endGameLead: string;
    /**
     * 
     * @type {string}
     * @memberof LivePlusUiModuleBody
     */
    endGameContinueVixCta: string;
}

/**
    * @export
    * @enum {string}
    */
export enum LivePlusUiModuleBodyModuleTypeEnum {
    LIVE_PLUS = 'LIVE_PLUS'
}



