import { OptionsObject, SnackbarKey, SnackbarMessage, SnackbarOrigin, useSnackbar, VariantType } from 'notistack';

import { useRecoilState } from 'recoil';
import { withNotificationHistory, withShowNotificationDrawer } from '../../state/notifications';
import { SnackbarActions } from '../../utils/snackbarUtils';
import { ReactElement } from 'react';

// Aliases, so we could seamlessly switch to a different notification provider, if needed

export type NotificationKey = SnackbarKey;
export type NotificationMessage = SnackbarMessage;
export type NotificationOptions = OptionsObject;
export type NotificationDebug = Record<string, unknown>;

type NotificationDetailTypes = string | ReactElement;
export type NotificationDetails = NotificationDetailTypes | Array<NotificationDetailTypes>;

export type NotificationHistoryType = {
  key: NotificationKey;
  message: NotificationMessage;
  details?: NotificationDetails;
  options?: NotificationOptions;
  isRead?: boolean;
  timestamp: Date;
  debugInfo?: NotificationDebug;
};

const anchorOrigin: SnackbarOrigin = {
  horizontal: 'center',
  vertical: 'top'
};

export type UseNotificationsReturnType = {
  notificationHistory: Array<NotificationHistoryType>;
  clearNotification: (key: NotificationKey) => void;
  clearNotificationHistory: () => void;
  closeNotificationsDrawer: () => void;
  markAllAsRead: () => void;
  notify: (
    message: NotificationMessage,
    details?: NotificationDetails,
    options?: NotificationOptions
  ) => NotificationKey;
  notifyError: (
    message: NotificationMessage,
    details?: NotificationDetails,
    options?: NotificationOptions,
    debugInfo?: NotificationDebug
  ) => NotificationKey;
  notifyWarning: (
    message: NotificationMessage,
    details?: NotificationDetails,
    options?: NotificationOptions
  ) => NotificationKey;
  notifyInfo: (
    message: NotificationMessage,
    details?: NotificationDetails,
    options?: NotificationOptions
  ) => NotificationKey;
  notifySuccess: (
    message: NotificationMessage,
    details?: NotificationDetails,
    options?: NotificationOptions
  ) => NotificationKey;
};

export function useNotifications(): UseNotificationsReturnType {
  const [notificationHistory, setNotificationHistory] = useRecoilState(withNotificationHistory);
  const [, setShowNotificationDrawer] = useRecoilState(withShowNotificationDrawer);
  const { enqueueSnackbar } = useSnackbar();

  const clearNotification = (key: SnackbarKey) => {
    setNotificationHistory(notificationHistory.filter((n: NotificationHistoryType) => n.key !== key));
  };

  // Display a snackbar, with a specific variant, and add it to the notification history
  const curryNotification = (variant: VariantType) => {
    return (
      message: NotificationMessage,
      details?: NotificationDetails,
      options?: NotificationOptions,
      debugInfo?: NotificationDebug
    ): NotificationKey => {
      options = { variant, anchorOrigin, action: SnackbarActions, ...options };
      const key = enqueueSnackbar(message, options);
      setNotificationHistory((notifications) => [
        { key, message, details, options, timestamp: new Date(), debugInfo },
        ...notifications
      ]);
      return key;
    };
  };

  const markAllAsRead = () => {
    setNotificationHistory(
      notificationHistory.map((history) => ({ ...history, isRead: true }) as NotificationHistoryType)
    );
  };

  return {
    notify: curryNotification('default'),
    notifyError: curryNotification('error'),
    notifyWarning: curryNotification('warning'),
    notifySuccess: curryNotification('success'),
    notifyInfo: curryNotification('info'),
    notificationHistory,
    clearNotification,
    markAllAsRead,
    clearNotificationHistory: () => setNotificationHistory([]),
    closeNotificationsDrawer: () => setShowNotificationDrawer(false)
  };
}
