import { startCase, toLower } from 'lodash-es';

export function toTitleCase(str: string): string {
  return startCase(toLower(str));
}

export function toBoolean(str?: string): boolean | undefined {
  if (str?.toLowerCase() === 'true') return true;
  if (str?.toLowerCase() === 'false') return false;
  return undefined;
}

export const stringToRegExp = (str: string, flags?: string): RegExp =>
  RegExp(str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'), flags);
