import React, { forwardRef } from 'react';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { AccountCreationUiModuleBody } from '../../../../../API';
import { FormBody, FormField, SectionHeader } from '../../styles';
import { useLocales, useTheme } from '../../../../../hooks';
import { Controller, useFormContext } from 'react-hook-form';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { LocaleKeys } from '../../../../../locales/i18n';
import { FormControlLabel, Switch } from '@mui/material';

export const testIds = {
  formBody: 'account-creation.form-body',
  formField: (name: keyof AccountCreationUiModuleBody): string => `account-creation.form-field.${name}`
};

type AccountCreationFieldProps = {
  name: keyof AccountCreationUiModuleBody;
  required?: boolean;
  'data-testid'?: string;
};

const AccountCreationForm = forwardRef<UIModuleTypeForm<AccountCreationUiModuleBody>>(() => {
  const { control, watch } = useFormContext<AccountCreationUiModuleBody>();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const moduleId = watch('moduleId');

  const AssetPicker = ({
    name,
    assetType,
    required = false
  }: AccountCreationFieldProps & { assetType: AssetTypesEnum }): React.ReactElement => (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormField>
          <AssetBrowser
            required={required}
            label={t(`layouts.account_creation.fields.${name}`)}
            assetId={moduleId || ''}
            assetType={assetType}
            value={value as string}
            data-testid={testIds.formField(name)}
            hasError={!!error}
            onChange={onChange}
          />
        </FormField>
      )}
    />
  );

  const LokaliseField = (name: keyof AccountCreationUiModuleBody, required = false): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.account_creation.fields.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  return (
    <FormBody data-testid={testIds.formBody}>
      <div>
        <SectionHeader>{t('layouts.account_creation.headers.images')}</SectionHeader>
        <AssetPicker name="vixLogo" assetType={AssetTypes.accountCreation.vixLogo} required />
        <AssetPicker name="partnerLogo" assetType={AssetTypes.accountCreation.partnerLogo} />
      </div>
      <div>
        <div>
          <SectionHeader>{t('layouts.account_creation.headers.account_creation_keys')}</SectionHeader>
          {LokaliseField('header', true)}
          {LokaliseField('subHeader')}
          {LokaliseField('emailText', true)}
          {LokaliseField('passwordText', true)}
          {LokaliseField('vixDisclaimer', true)}
          {LokaliseField('partnerDisclaimer')}
        </div>
        <div>
          <SectionHeader>{t('layouts.account_creation.headers.sign_in_keys')}</SectionHeader>
          {LokaliseField('signInTitle')}
          {LokaliseField('subHeaderSignIn')}
          {LokaliseField('signInCta', true)}
          {LokaliseField('signUpCta', true)}
          {LokaliseField('mainCtaSignIn')}
          {LokaliseField('secondaryCtaSignIn')}
          {LokaliseField('createAccountCtaSignIn')}
        </div>
        <Controller
          control={control}
          name="legalCheckbox"
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              control={<Switch color={formControlColor} checked={value} onChange={onChange} />}
              label={t('layouts.account_creation.fields.legalCheckbox')}
            />
          )}
        />
      </div>
    </FormBody>
  );
});

AccountCreationForm.displayName = 'AccountCreationForm';

export default AccountCreationForm;
