import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useData } from '../../../../data-layer';
import { useLocales, useTheme } from '../../../../hooks';

const useStyles = makeStyles()((theme) => ({
  contentAlerts: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    userSelect: 'none'
  },
  slackLogo: {
    height: 18
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  }
}));

type ContentAlertsProps = {
  contentId: string;
};

export const testIds = {
  switch: 'content-alerts.switch'
};

const slackChannelName = '#content_notifications';
const slackChannelUrl = 'https://slack.com/app_redirect?channel=C041TLK59BM';

export function ContentAlerts({ contentId }: ContentAlertsProps): React.ReactElement {
  const { classes } = useStyles();
  const { formControlColor } = useTheme();
  const { t } = useLocales();
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    media: {
      hook: { getContentSubscriptionStatus, setContentSubscriptionStatus }
    }
  } = useData();

  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    getStatus();
  }, [contentId]);

  const getStatus = async () => {
    setIsDisabled(true);
    const status = await getContentSubscriptionStatus(contentId);
    // undefined status means request failed, only update state and re-enable switch if request was successful
    if (status !== undefined) {
      setIsSubscribed(status);
      setIsDisabled(false);
    }
  };

  const handleSwitchChange = async ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    const oldStatus = isSubscribed;
    setIsSubscribed(checked);
    // if request timesout/fails in any way, revert to old value
    if (!(await setContentSubscriptionStatus(contentId, checked))) {
      setIsSubscribed(oldStatus);
    }
  };

  return (
    <Tooltip title={t('content.drawer.new_episode_alerts_tooltip', { channel: slackChannelName })} arrow>
      <div className={classes.container}>
        <FormControlLabel
          control={
            <Switch
              size="medium"
              disabled={isDisabled}
              color={formControlColor}
              checked={isSubscribed}
              onChange={handleSwitchChange}
              data-testid={testIds.switch}
            />
          }
          label={<div className={classes.contentAlerts}>{t('content.drawer.new_episode_alerts')}</div>}
        />
        <a href={slackChannelUrl} target="_blank" rel="noreferrer" className={classes.slackLogo}>
          <img src="/assets/slack-logo.svg" className={classes.slackLogo} />
        </a>
      </div>
    </Tooltip>
  );
}
