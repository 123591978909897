import { useSetRecoilState } from 'recoil';
import { AssetBody, AssetResponse, FocusMode } from '../../../API';
import { AssetsAPI } from '../../../hooks/API/Assets/AssetsAPI';
import { CacheManager, DataManagerHook, DataManagerHookReturnType, IDataManagerHookProps } from '../../DataManager';

export interface AssetsHookReturnType extends DataManagerHookReturnType<AssetResponse, AssetBody> {
  getByFilePath: (filePath: string) => Promise<AssetResponse | undefined>;
  getBackgroundPosition: (asset: AssetResponse, defaultPosition: string) => string;
}

export function AssetsHook(params: IDataManagerHookProps<AssetResponse, AssetBody>): AssetsHookReturnType {
  const dataManagerHook = DataManagerHook<AssetResponse, AssetBody>(params);
  const setCache = useSetRecoilState(params.state.withDataCache);
  const { addRecordToCache } = CacheManager(params.idField, setCache);

  const api = params.useApiHook() as AssetsAPI;

  const getByFilePath = async (filePath: string): Promise<AssetResponse | undefined> => {
    try {
      const {
        data: { body: asset }
      } = await api.getByFilePath(filePath);
      addRecordToCache(asset);
      return asset;
    } catch {
      return undefined;
    }
  };

  const toPercent = (value: number) => (Math.max(Math.min(value, 100), 0) * 100).toFixed(2) + '%';

  const getBackgroundPosition = (asset: AssetResponse, defaultPosition: string) => {
    if (asset.focusOptions) {
      return {
        [FocusMode.TOP_LEFT]: '0% 0%',
        [FocusMode.TOP]: '50% 0%',
        [FocusMode.TOP_RIGHT]: '100% 0%',
        [FocusMode.LEFT]: '0% 50%',
        [FocusMode.CENTER]: '50% 50%',
        [FocusMode.RIGHT]: '100% 50%',
        [FocusMode.BOTTOM_LEFT]: '0% 100%',
        [FocusMode.BOTTOM]: '50% 100%',
        [FocusMode.BOTTOM_RIGHT]: '100% 100%',
        [FocusMode.AUTO]: defaultPosition,
        [FocusMode.FACE]: defaultPosition,
        [FocusMode.CUSTOM]:
          toPercent((asset.focusOptions.focusX as number) / asset.width) +
          ' ' +
          toPercent((asset.focusOptions.focusY as number) / asset.height)
      }[asset.focusOptions.mode];
    }
    return defaultPosition;
  };

  return {
    ...dataManagerHook,
    getByFilePath,
    getBackgroundPosition
  };
}
