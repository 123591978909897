import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { InputLabel, MenuItem, Select } from '@mui/material';
import Button from '../../shared/Button';
import { useTheme } from '../../../hooks';
import { Add, Delete } from '@mui/icons-material';
import CountryPickerLite from '../../shared/CountryPickerLite';
import IconButton from '../../shared/IconButton';
import FormControl from '../../shared/FormControl';
import { PermissionsGroupId, TargetPlatform } from '../../../API';
import { useNavigate } from 'react-router-dom';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import { AppRoutes } from '../../../Routes';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  container: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(4),
    margin: theme.spacing(4, 0)
  },
  containerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: theme.spacing(5),
    marginBottom: theme.spacing(4)
  },
  countryPicker: {
    width: 420
  },
  deviceSelector: {
    width: 420
  },
  planGroupSelector: {
    width: 260
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(4)
  },
  grow: {
    flexGrow: 1
  }
}));

const planGroups = ['7-days-free', 'intro-pricing', 'oxxo', 'pty-roku', 'roku-special'];

type DeviceOverride = {
  planGroup: string;
  devices: string[];
};

type CountryOverride = {
  planGroup: string;
  countries: string[];
  deviceOverrides: DeviceOverride[];
};

export function DefaultPlanGroups(): JSX.Element {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { formControlColor } = useTheme();
  const [globalConfig, setGlobalConfig] = useState(planGroups[0]);
  const [countryOverrides] = useState<CountryOverride[]>([
    {
      planGroup: planGroups[1],
      countries: ['US', 'MX'],
      deviceOverrides: [
        {
          planGroup: planGroups[0],
          devices: ['samsungtv']
        }
      ]
    }
  ]);

  const { hasReadPermission } = usePermissions();
  const hasMonetizationReadPermissions = hasReadPermission(PermissionsGroupId.MONETIZATION);

  useEffect(() => {
    if (!hasMonetizationReadPermissions) {
      navigate(AppRoutes.welcome);
    }
  }, [hasMonetizationReadPermissions]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div>
          <div className={classes.containerHeader}>
            <h3>Global Default</h3>
            <PlanGroupSelector className={classes.planGroupSelector} value={globalConfig} onChange={setGlobalConfig} />
          </div>
        </div>
        {countryOverrides.map((countryOverride, i) => (
          <div className={classes.container} key={i}>
            <div className={classes.row}>
              <div className={classes.grow}>
                <div className={classes.containerHeader}>
                  <h3>Country Override</h3>
                  <PlanGroupSelector
                    className={classes.planGroupSelector}
                    value={countryOverride.planGroup}
                    onChange={setGlobalConfig}
                  />
                  <CountryPickerLite
                    className={classes.countryPicker}
                    label="Countries"
                    value={countryOverride.countries}
                    onChange={() => {
                      // do something
                    }}
                  />
                </div>

                {countryOverride.deviceOverrides.map((deviceOverride, j) => (
                  <div className={classes.container} key={j}>
                    <div className={classes.row}>
                      <div className={classes.grow}>
                        <div className={classes.containerHeader}>
                          <h3>Device Override</h3>
                          <PlanGroupSelector
                            className={classes.planGroupSelector}
                            value={deviceOverride.planGroup}
                            onChange={setGlobalConfig}
                          />
                          <DeviceSelector
                            className={classes.deviceSelector}
                            value={deviceOverride.devices}
                            onChange={() => {
                              //
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <IconButton>
                          <Delete />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <IconButton>
                  <Delete />
                </IconButton>
              </div>
            </div>

            <Button variant="outlined" color={formControlColor} endIcon={<Add />}>
              New Device Override
            </Button>
          </div>
        ))}
        <Button variant="outlined" color={formControlColor} endIcon={<Add />}>
          New Country Override
        </Button>
      </div>
      <Button>Save Changes</Button>
    </div>
  );
}

function PlanGroupSelector({
  value,
  onChange,
  className
}: {
  value: string;
  onChange: (val: string) => void;
  className?: string;
}): JSX.Element {
  return (
    <FormControl className={className}>
      <InputLabel shrink variant="standard">
        Plan Group
      </InputLabel>
      <Select
        label="Plan Group"
        value={value}
        onChange={(evt) => {
          onChange(evt.target.value);
        }}
      >
        {planGroups.map((planGroup) => (
          <MenuItem key={planGroup} value={planGroup}>
            {planGroup}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function DeviceSelector({
  value,
  onChange,
  className
}: {
  value: string[];
  onChange: (val: string[]) => void;
  className?: string;
}): JSX.Element {
  return (
    <FormControl className={className}>
      <InputLabel shrink variant="standard">
        Devices
      </InputLabel>
      <Select
        label="Devices"
        multiple
        value={value}
        onChange={(evt) => {
          onChange(evt.target.value as string[]);
        }}
      >
        {Object.values(TargetPlatform).map((device) => (
          <MenuItem key={device} value={device}>
            {device}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
