import {
  DocumentLocale,
  EntitlementType,
  OneBrandPromoUiModuleBody,
  OneBrandPromoUiModuleBodyModuleTypeEnum
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction, DynamicModuleFunction } from '../../../utils/layouts';

export const blankOneBrandPromoModule: NewModuleFunction<OneBrandPromoUiModuleBody> = (module) => ({
  moduleType: OneBrandPromoUiModuleBodyModuleTypeEnum.ONE_BRAND_PROMO,
  moduleId: generateId(),
  title: {
    [DocumentLocale.ES]: ''
  },
  entitlements: [EntitlementType.ANON, EntitlementType.FREE, EntitlementType.SUB],
  landscapeFillImage: '',
  portraitFillImage: '',
  ctaUrlPath: '',
  ctaName: '',
  popupHeader: '',
  popupSubheader: '',
  popupCtaText: '',
  fullHeader: '',
  fullSubheader: '',
  fullCtaText: '',
  spinPremia: {
    id: generateId()
  },
  ...module
});

export const setDynamicFieldsOnOneBrandPromoModule: DynamicModuleFunction<OneBrandPromoUiModuleBody> = (module) =>
  <OneBrandPromoUiModuleBody>{
    ...module,
    ...(!module.spinPremia?.id && {
      spinPremia: {
        ...module?.spinPremia,
        id: generateId()
      }
    })
  };
