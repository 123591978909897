import React from 'react';
import LiveSearch from './LiveSearch';
import { FormProvider, useForm } from 'react-hook-form';
import { WithFilters } from '../../shared/SearchFilters';

export interface LiveSearchFormProps {
  term?: string;
  sportIds?: string[];
  leagueIds?: string[];
  limit?: number;
  page?: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LiveSearchWithFilters = WithFilters<LiveSearchFormProps, any>;

function LiveSearchForm(): JSX.Element {
  const formMethods = useForm<LiveSearchWithFilters>();

  return (
    <FormProvider {...formMethods}>
      <LiveSearch />
    </FormProvider>
  );
}

export default LiveSearchForm;
