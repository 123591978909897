/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiPaginatedResponseOfPageLayoutResponse } from '../model';
// @ts-ignore
import { ApiPaginatedResponseOfPageLayoutRevision } from '../model';
// @ts-ignore
import { ApiResponseOfLayoutValidationResult } from '../model';
// @ts-ignore
import { ApiResponseOfPageLayoutResponse } from '../model';
// @ts-ignore
import { ApiResponseOfPageLayoutResponseArray } from '../model';
// @ts-ignore
import { LayoutStatus } from '../model';
// @ts-ignore
import { PageLayoutBody } from '../model';
// @ts-ignore
import { PageLayoutPatchBody } from '../model';
// @ts-ignore
import { QuickScheduling } from '../model';
// @ts-ignore
import { SortDirection } from '../model';
// @ts-ignore
import { TimeQueryDirection } from '../model';
/**
 * LayoutsApi - axios parameter creator
 * @export
 */
export const LayoutsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Updates the contents from the **layouts** available on the **Vix CMS** application by specifying `urlPath` and `countries` parameters.
         * @param {PageLayoutBody} pageLayoutBody 
         * @param {QuickScheduling} [quickScheduling] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (pageLayoutBody: PageLayoutBody, quickScheduling?: QuickScheduling, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageLayoutBody' is not null or undefined
            assertParamExists('create', 'pageLayoutBody', pageLayoutBody)
            const localVarPath = `/api/v1/layouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (quickScheduling !== undefined) {
                localVarQueryParameter['quickScheduling'] = quickScheduling;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pageLayoutBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] Date used to get csv of given date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCsv: async (date?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/layouts/downloadCsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {Array<string>} [countries] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCsv: async (startDate: string, endDate: string, countries?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('exportCsv', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('exportCsv', 'endDate', endDate)
            const localVarPath = `/api/v1/layouts/exportCsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the contents of a specific **layout** in the **Vix CMS** application by providing an `id` parameter.
         * @param {string} pageLayoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (pageLayoutId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageLayoutId' is not null or undefined
            assertParamExists('getById', 'pageLayoutId', pageLayoutId)
            const localVarPath = `/api/v1/layouts/{pageLayoutId}`
                .replace(`{${"pageLayoutId"}}`, encodeURIComponent(String(pageLayoutId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all the **layouts** in `Draft` status in the **Vix CMS** application by specifying `urlPath` and `countries` parameters. `Draft` layouts do not have a scheduled date to go live.
         * @param {string} layoutTypes 
         * @param {string} urlPath 
         * @param {Array<string>} countries 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrafts: async (layoutTypes: string, urlPath: string, countries: Array<string>, limit?: number, page?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'layoutTypes' is not null or undefined
            assertParamExists('getDrafts', 'layoutTypes', layoutTypes)
            // verify required parameter 'urlPath' is not null or undefined
            assertParamExists('getDrafts', 'urlPath', urlPath)
            // verify required parameter 'countries' is not null or undefined
            assertParamExists('getDrafts', 'countries', countries)
            const localVarPath = `/api/v1/layouts/{layoutTypes}/drafts`
                .replace(`{${"layoutTypes"}}`, encodeURIComponent(String(layoutTypes)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (urlPath !== undefined) {
                localVarQueryParameter['urlPath'] = urlPath;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all the historical changes of a specific **layout** in the **Vix CMS** application by providing an `id` parameter.
         * @param {string} pageLayoutId 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [authorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory: async (pageLayoutId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, authorId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageLayoutId' is not null or undefined
            assertParamExists('getHistory', 'pageLayoutId', pageLayoutId)
            const localVarPath = `/api/v1/layouts/{pageLayoutId}/history`
                .replace(`{${"pageLayoutId"}}`, encodeURIComponent(String(pageLayoutId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (authorId !== undefined) {
                localVarQueryParameter['authorId'] = authorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all the **layouts** in `LIVE` status in the **Vix CMS** application by specifying `layoutType`, `urlPath` and `countries` parameters. `LIVE` status layouts have a scheduled date to go live in the application.
         * @param {string} layoutTypes 
         * @param {string} urlPath 
         * @param {Array<string>} countries 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLive: async (layoutTypes: string, urlPath: string, countries: Array<string>, limit?: number, page?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'layoutTypes' is not null or undefined
            assertParamExists('getLive', 'layoutTypes', layoutTypes)
            // verify required parameter 'urlPath' is not null or undefined
            assertParamExists('getLive', 'urlPath', urlPath)
            // verify required parameter 'countries' is not null or undefined
            assertParamExists('getLive', 'countries', countries)
            const localVarPath = `/api/v1/layouts/{layoutTypes}/live`
                .replace(`{${"layoutTypes"}}`, encodeURIComponent(String(layoutTypes)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (urlPath !== undefined) {
                localVarQueryParameter['urlPath'] = urlPath;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the contents of the **layouts** available in the **Vix CMS** application by specifying `urlPath`, `countries`, and `time` parameters.
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [urlPath] 
         * @param {Array<string>} [countries] 
         * @param {string} [time] 
         * @param {TimeQueryDirection} [direction] 
         * @param {LayoutStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedule: async (limit?: number, page?: number, urlPath?: string, countries?: Array<string>, time?: string, direction?: TimeQueryDirection, status?: LayoutStatus, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/layouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (urlPath !== undefined) {
                localVarQueryParameter['urlPath'] = urlPath;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (time !== undefined) {
                localVarQueryParameter['time'] = (time as any instanceof Date) ?
                    (time as any).toISOString() :
                    time;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates one o multiple **layout** fields in the **Vix CMS** application by providing an `id` parameter.
         * @param {string} pageLayoutId 
         * @param {PageLayoutPatchBody} pageLayoutPatchBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patch: async (pageLayoutId: string, pageLayoutPatchBody: PageLayoutPatchBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageLayoutId' is not null or undefined
            assertParamExists('patch', 'pageLayoutId', pageLayoutId)
            // verify required parameter 'pageLayoutPatchBody' is not null or undefined
            assertParamExists('patch', 'pageLayoutPatchBody', pageLayoutPatchBody)
            const localVarPath = `/api/v1/layouts/{pageLayoutId}`
                .replace(`{${"pageLayoutId"}}`, encodeURIComponent(String(pageLayoutId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pageLayoutPatchBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/layouts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a single **layout** in the **Vix CMS** application by providing an `id` parameter.
         * @param {string} pageLayoutId 
         * @param {PageLayoutBody} pageLayoutBody 
         * @param {QuickScheduling} [quickScheduling] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (pageLayoutId: string, pageLayoutBody: PageLayoutBody, quickScheduling?: QuickScheduling, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageLayoutId' is not null or undefined
            assertParamExists('update', 'pageLayoutId', pageLayoutId)
            // verify required parameter 'pageLayoutBody' is not null or undefined
            assertParamExists('update', 'pageLayoutBody', pageLayoutBody)
            const localVarPath = `/api/v1/layouts/{pageLayoutId}`
                .replace(`{${"pageLayoutId"}}`, encodeURIComponent(String(pageLayoutId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (quickScheduling !== undefined) {
                localVarQueryParameter['quickScheduling'] = quickScheduling;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pageLayoutBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validates geo restrictions and time windows on media on all ui modules for a specific **layout** in the **Vix CMS** application
         * @param {PageLayoutBody} pageLayoutBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateLayout: async (pageLayoutBody: PageLayoutBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageLayoutBody' is not null or undefined
            assertParamExists('validateLayout', 'pageLayoutBody', pageLayoutBody)
            const localVarPath = `/api/v1/layouts/validateLayout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pageLayoutBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LayoutsApi - functional programming interface
 * @export
 */
export const LayoutsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LayoutsApiAxiosParamCreator(configuration)
    return {
        /**
         * Updates the contents from the **layouts** available on the **Vix CMS** application by specifying `urlPath` and `countries` parameters.
         * @param {PageLayoutBody} pageLayoutBody 
         * @param {QuickScheduling} [quickScheduling] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(pageLayoutBody: PageLayoutBody, quickScheduling?: QuickScheduling, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfPageLayoutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(pageLayoutBody, quickScheduling, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] Date used to get csv of given date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadCsv(date?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadCsv(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {Array<string>} [countries] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportCsv(startDate: string, endDate: string, countries?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportCsv(startDate, endDate, countries, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the contents of a specific **layout** in the **Vix CMS** application by providing an `id` parameter.
         * @param {string} pageLayoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(pageLayoutId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfPageLayoutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(pageLayoutId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all the **layouts** in `Draft` status in the **Vix CMS** application by specifying `urlPath` and `countries` parameters. `Draft` layouts do not have a scheduled date to go live.
         * @param {string} layoutTypes 
         * @param {string} urlPath 
         * @param {Array<string>} countries 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDrafts(layoutTypes: string, urlPath: string, countries: Array<string>, limit?: number, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfPageLayoutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDrafts(layoutTypes, urlPath, countries, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all the historical changes of a specific **layout** in the **Vix CMS** application by providing an `id` parameter.
         * @param {string} pageLayoutId 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [authorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistory(pageLayoutId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, authorId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfPageLayoutRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistory(pageLayoutId, limit, page, sortBy, sortDirection, authorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all the **layouts** in `LIVE` status in the **Vix CMS** application by specifying `layoutType`, `urlPath` and `countries` parameters. `LIVE` status layouts have a scheduled date to go live in the application.
         * @param {string} layoutTypes 
         * @param {string} urlPath 
         * @param {Array<string>} countries 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLive(layoutTypes: string, urlPath: string, countries: Array<string>, limit?: number, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfPageLayoutResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLive(layoutTypes, urlPath, countries, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the contents of the **layouts** available in the **Vix CMS** application by specifying `urlPath`, `countries`, and `time` parameters.
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [urlPath] 
         * @param {Array<string>} [countries] 
         * @param {string} [time] 
         * @param {TimeQueryDirection} [direction] 
         * @param {LayoutStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchedule(limit?: number, page?: number, urlPath?: string, countries?: Array<string>, time?: string, direction?: TimeQueryDirection, status?: LayoutStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfPageLayoutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchedule(limit, page, urlPath, countries, time, direction, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates one o multiple **layout** fields in the **Vix CMS** application by providing an `id` parameter.
         * @param {string} pageLayoutId 
         * @param {PageLayoutPatchBody} pageLayoutPatchBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patch(pageLayoutId: string, pageLayoutPatchBody: PageLayoutPatchBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfPageLayoutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patch(pageLayoutId, pageLayoutPatchBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a single **layout** in the **Vix CMS** application by providing an `id` parameter.
         * @param {string} pageLayoutId 
         * @param {PageLayoutBody} pageLayoutBody 
         * @param {QuickScheduling} [quickScheduling] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(pageLayoutId: string, pageLayoutBody: PageLayoutBody, quickScheduling?: QuickScheduling, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfPageLayoutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(pageLayoutId, pageLayoutBody, quickScheduling, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validates geo restrictions and time windows on media on all ui modules for a specific **layout** in the **Vix CMS** application
         * @param {PageLayoutBody} pageLayoutBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateLayout(pageLayoutBody: PageLayoutBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfLayoutValidationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateLayout(pageLayoutBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LayoutsApi - factory interface
 * @export
 */
export const LayoutsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LayoutsApiFp(configuration)
    return {
        /**
         * Updates the contents from the **layouts** available on the **Vix CMS** application by specifying `urlPath` and `countries` parameters.
         * @param {PageLayoutBody} pageLayoutBody 
         * @param {QuickScheduling} [quickScheduling] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(pageLayoutBody: PageLayoutBody, quickScheduling?: QuickScheduling, options?: any): AxiosPromise<ApiResponseOfPageLayoutResponse> {
            return localVarFp.create(pageLayoutBody, quickScheduling, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] Date used to get csv of given date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCsv(date?: string, options?: any): AxiosPromise<void> {
            return localVarFp.downloadCsv(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {Array<string>} [countries] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCsv(startDate: string, endDate: string, countries?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.exportCsv(startDate, endDate, countries, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the contents of a specific **layout** in the **Vix CMS** application by providing an `id` parameter.
         * @param {string} pageLayoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(pageLayoutId: string, options?: any): AxiosPromise<ApiResponseOfPageLayoutResponse> {
            return localVarFp.getById(pageLayoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all the **layouts** in `Draft` status in the **Vix CMS** application by specifying `urlPath` and `countries` parameters. `Draft` layouts do not have a scheduled date to go live.
         * @param {string} layoutTypes 
         * @param {string} urlPath 
         * @param {Array<string>} countries 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrafts(layoutTypes: string, urlPath: string, countries: Array<string>, limit?: number, page?: number, options?: any): AxiosPromise<ApiPaginatedResponseOfPageLayoutResponse> {
            return localVarFp.getDrafts(layoutTypes, urlPath, countries, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all the historical changes of a specific **layout** in the **Vix CMS** application by providing an `id` parameter.
         * @param {string} pageLayoutId 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [authorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory(pageLayoutId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, authorId?: string, options?: any): AxiosPromise<ApiPaginatedResponseOfPageLayoutRevision> {
            return localVarFp.getHistory(pageLayoutId, limit, page, sortBy, sortDirection, authorId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all the **layouts** in `LIVE` status in the **Vix CMS** application by specifying `layoutType`, `urlPath` and `countries` parameters. `LIVE` status layouts have a scheduled date to go live in the application.
         * @param {string} layoutTypes 
         * @param {string} urlPath 
         * @param {Array<string>} countries 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLive(layoutTypes: string, urlPath: string, countries: Array<string>, limit?: number, page?: number, options?: any): AxiosPromise<ApiResponseOfPageLayoutResponseArray> {
            return localVarFp.getLive(layoutTypes, urlPath, countries, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the contents of the **layouts** available in the **Vix CMS** application by specifying `urlPath`, `countries`, and `time` parameters.
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [urlPath] 
         * @param {Array<string>} [countries] 
         * @param {string} [time] 
         * @param {TimeQueryDirection} [direction] 
         * @param {LayoutStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedule(limit?: number, page?: number, urlPath?: string, countries?: Array<string>, time?: string, direction?: TimeQueryDirection, status?: LayoutStatus, options?: any): AxiosPromise<ApiPaginatedResponseOfPageLayoutResponse> {
            return localVarFp.getSchedule(limit, page, urlPath, countries, time, direction, status, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates one o multiple **layout** fields in the **Vix CMS** application by providing an `id` parameter.
         * @param {string} pageLayoutId 
         * @param {PageLayoutPatchBody} pageLayoutPatchBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patch(pageLayoutId: string, pageLayoutPatchBody: PageLayoutPatchBody, options?: any): AxiosPromise<ApiResponseOfPageLayoutResponse> {
            return localVarFp.patch(pageLayoutId, pageLayoutPatchBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a single **layout** in the **Vix CMS** application by providing an `id` parameter.
         * @param {string} pageLayoutId 
         * @param {PageLayoutBody} pageLayoutBody 
         * @param {QuickScheduling} [quickScheduling] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(pageLayoutId: string, pageLayoutBody: PageLayoutBody, quickScheduling?: QuickScheduling, options?: any): AxiosPromise<ApiResponseOfPageLayoutResponse> {
            return localVarFp.update(pageLayoutId, pageLayoutBody, quickScheduling, options).then((request) => request(axios, basePath));
        },
        /**
         * Validates geo restrictions and time windows on media on all ui modules for a specific **layout** in the **Vix CMS** application
         * @param {PageLayoutBody} pageLayoutBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateLayout(pageLayoutBody: PageLayoutBody, options?: any): AxiosPromise<ApiResponseOfLayoutValidationResult> {
            return localVarFp.validateLayout(pageLayoutBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LayoutsApi - interface
 * @export
 * @interface LayoutsApi
 */
export interface LayoutsApiInterface {
    /**
     * Updates the contents from the **layouts** available on the **Vix CMS** application by specifying `urlPath` and `countries` parameters.
     * @param {PageLayoutBody} pageLayoutBody 
     * @param {QuickScheduling} [quickScheduling] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApiInterface
     */
    create(pageLayoutBody: PageLayoutBody, quickScheduling?: QuickScheduling, options?: any): AxiosPromise<ApiResponseOfPageLayoutResponse>;

    /**
     * 
     * @param {string} [date] Date used to get csv of given date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApiInterface
     */
    downloadCsv(date?: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {Array<string>} [countries] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApiInterface
     */
    exportCsv(startDate: string, endDate: string, countries?: Array<string>, options?: any): AxiosPromise<void>;

    /**
     * Retrieves the contents of a specific **layout** in the **Vix CMS** application by providing an `id` parameter.
     * @param {string} pageLayoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApiInterface
     */
    getById(pageLayoutId: string, options?: any): AxiosPromise<ApiResponseOfPageLayoutResponse>;

    /**
     * Retrieves all the **layouts** in `Draft` status in the **Vix CMS** application by specifying `urlPath` and `countries` parameters. `Draft` layouts do not have a scheduled date to go live.
     * @param {string} layoutTypes 
     * @param {string} urlPath 
     * @param {Array<string>} countries 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApiInterface
     */
    getDrafts(layoutTypes: string, urlPath: string, countries: Array<string>, limit?: number, page?: number, options?: any): AxiosPromise<ApiPaginatedResponseOfPageLayoutResponse>;

    /**
     * Retrieves all the historical changes of a specific **layout** in the **Vix CMS** application by providing an `id` parameter.
     * @param {string} pageLayoutId 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {string} [authorId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApiInterface
     */
    getHistory(pageLayoutId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, authorId?: string, options?: any): AxiosPromise<ApiPaginatedResponseOfPageLayoutRevision>;

    /**
     * Retrieves all the **layouts** in `LIVE` status in the **Vix CMS** application by specifying `layoutType`, `urlPath` and `countries` parameters. `LIVE` status layouts have a scheduled date to go live in the application.
     * @param {string} layoutTypes 
     * @param {string} urlPath 
     * @param {Array<string>} countries 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApiInterface
     */
    getLive(layoutTypes: string, urlPath: string, countries: Array<string>, limit?: number, page?: number, options?: any): AxiosPromise<ApiResponseOfPageLayoutResponseArray>;

    /**
     * Retrieves the contents of the **layouts** available in the **Vix CMS** application by specifying `urlPath`, `countries`, and `time` parameters.
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [urlPath] 
     * @param {Array<string>} [countries] 
     * @param {string} [time] 
     * @param {TimeQueryDirection} [direction] 
     * @param {LayoutStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApiInterface
     */
    getSchedule(limit?: number, page?: number, urlPath?: string, countries?: Array<string>, time?: string, direction?: TimeQueryDirection, status?: LayoutStatus, options?: any): AxiosPromise<ApiPaginatedResponseOfPageLayoutResponse>;

    /**
     * Updates one o multiple **layout** fields in the **Vix CMS** application by providing an `id` parameter.
     * @param {string} pageLayoutId 
     * @param {PageLayoutPatchBody} pageLayoutPatchBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApiInterface
     */
    patch(pageLayoutId: string, pageLayoutPatchBody: PageLayoutPatchBody, options?: any): AxiosPromise<ApiResponseOfPageLayoutResponse>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApiInterface
     */
    remove(id: string, options?: any): AxiosPromise<void>;

    /**
     * Creates a single **layout** in the **Vix CMS** application by providing an `id` parameter.
     * @param {string} pageLayoutId 
     * @param {PageLayoutBody} pageLayoutBody 
     * @param {QuickScheduling} [quickScheduling] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApiInterface
     */
    update(pageLayoutId: string, pageLayoutBody: PageLayoutBody, quickScheduling?: QuickScheduling, options?: any): AxiosPromise<ApiResponseOfPageLayoutResponse>;

    /**
     * Validates geo restrictions and time windows on media on all ui modules for a specific **layout** in the **Vix CMS** application
     * @param {PageLayoutBody} pageLayoutBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApiInterface
     */
    validateLayout(pageLayoutBody: PageLayoutBody, options?: any): AxiosPromise<ApiResponseOfLayoutValidationResult>;

}

/**
 * LayoutsApi - object-oriented interface
 * @export
 * @class LayoutsApi
 * @extends {BaseAPI}
 */
export class LayoutsApi extends BaseAPI implements LayoutsApiInterface {
    /**
     * Updates the contents from the **layouts** available on the **Vix CMS** application by specifying `urlPath` and `countries` parameters.
     * @param {PageLayoutBody} pageLayoutBody 
     * @param {QuickScheduling} [quickScheduling] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApi
     */
    public create(pageLayoutBody: PageLayoutBody, quickScheduling?: QuickScheduling, options?: any) {
        return LayoutsApiFp(this.configuration).create(pageLayoutBody, quickScheduling, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] Date used to get csv of given date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApi
     */
    public downloadCsv(date?: string, options?: any) {
        return LayoutsApiFp(this.configuration).downloadCsv(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {Array<string>} [countries] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApi
     */
    public exportCsv(startDate: string, endDate: string, countries?: Array<string>, options?: any) {
        return LayoutsApiFp(this.configuration).exportCsv(startDate, endDate, countries, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the contents of a specific **layout** in the **Vix CMS** application by providing an `id` parameter.
     * @param {string} pageLayoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApi
     */
    public getById(pageLayoutId: string, options?: any) {
        return LayoutsApiFp(this.configuration).getById(pageLayoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all the **layouts** in `Draft` status in the **Vix CMS** application by specifying `urlPath` and `countries` parameters. `Draft` layouts do not have a scheduled date to go live.
     * @param {string} layoutTypes 
     * @param {string} urlPath 
     * @param {Array<string>} countries 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApi
     */
    public getDrafts(layoutTypes: string, urlPath: string, countries: Array<string>, limit?: number, page?: number, options?: any) {
        return LayoutsApiFp(this.configuration).getDrafts(layoutTypes, urlPath, countries, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all the historical changes of a specific **layout** in the **Vix CMS** application by providing an `id` parameter.
     * @param {string} pageLayoutId 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {string} [authorId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApi
     */
    public getHistory(pageLayoutId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, authorId?: string, options?: any) {
        return LayoutsApiFp(this.configuration).getHistory(pageLayoutId, limit, page, sortBy, sortDirection, authorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all the **layouts** in `LIVE` status in the **Vix CMS** application by specifying `layoutType`, `urlPath` and `countries` parameters. `LIVE` status layouts have a scheduled date to go live in the application.
     * @param {string} layoutTypes 
     * @param {string} urlPath 
     * @param {Array<string>} countries 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApi
     */
    public getLive(layoutTypes: string, urlPath: string, countries: Array<string>, limit?: number, page?: number, options?: any) {
        return LayoutsApiFp(this.configuration).getLive(layoutTypes, urlPath, countries, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the contents of the **layouts** available in the **Vix CMS** application by specifying `urlPath`, `countries`, and `time` parameters.
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [urlPath] 
     * @param {Array<string>} [countries] 
     * @param {string} [time] 
     * @param {TimeQueryDirection} [direction] 
     * @param {LayoutStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApi
     */
    public getSchedule(limit?: number, page?: number, urlPath?: string, countries?: Array<string>, time?: string, direction?: TimeQueryDirection, status?: LayoutStatus, options?: any) {
        return LayoutsApiFp(this.configuration).getSchedule(limit, page, urlPath, countries, time, direction, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates one o multiple **layout** fields in the **Vix CMS** application by providing an `id` parameter.
     * @param {string} pageLayoutId 
     * @param {PageLayoutPatchBody} pageLayoutPatchBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApi
     */
    public patch(pageLayoutId: string, pageLayoutPatchBody: PageLayoutPatchBody, options?: any) {
        return LayoutsApiFp(this.configuration).patch(pageLayoutId, pageLayoutPatchBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApi
     */
    public remove(id: string, options?: any) {
        return LayoutsApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a single **layout** in the **Vix CMS** application by providing an `id` parameter.
     * @param {string} pageLayoutId 
     * @param {PageLayoutBody} pageLayoutBody 
     * @param {QuickScheduling} [quickScheduling] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApi
     */
    public update(pageLayoutId: string, pageLayoutBody: PageLayoutBody, quickScheduling?: QuickScheduling, options?: any) {
        return LayoutsApiFp(this.configuration).update(pageLayoutId, pageLayoutBody, quickScheduling, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validates geo restrictions and time windows on media on all ui modules for a specific **layout** in the **Vix CMS** application
     * @param {PageLayoutBody} pageLayoutBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsApi
     */
    public validateLayout(pageLayoutBody: PageLayoutBody, options?: any) {
        return LayoutsApiFp(this.configuration).validateLayout(pageLayoutBody, options).then((request) => request(this.axios, this.basePath));
    }
}
