import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { Close, Error } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

const iconStyles = {
  fontSize: 20,
  marginInlineEnd: 8
};

const useStyles = makeStyles<void, 'closeIcon'>()((_theme, _params, classes) => ({
  closeButton: {
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
      [`& > .${classes.closeIcon}`]: {
        color: 'rgba(0, 0, 0, 0.45)'
      }
    },
    '&:active': {
      background: 'rgba(0, 0, 0, 0.1)'
    }
  },
  closeIcon: {
    color: 'rgba(0, 0, 0, 0.3)'
  }
}));

export const SNACKBAR_ICONS = {
  error: <Error fontSize="small" style={iconStyles} />
};

export const SnackbarActions = (key: string | number): JSX.Element => {
  const { closeSnackbar } = useSnackbar();
  const { classes } = useStyles();

  return (
    <div className={classes.closeButton} onClick={() => closeSnackbar(key)}>
      <Close className={classes.closeIcon} fontSize="small" />
    </div>
  );
};
