export enum ScheduledTimeDays {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export const testIds = {
  root: 'schedule-time.root',
  keyValueRow: 'schedule-time.key-value-row',
  addScheduleButton: 'schedule-time.add-schedule-button',
  removeScheduleButton: 'schedule-time.remove-schedule-button'
};
