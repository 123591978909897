import React from 'react';
import { Chip } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { useLocales } from '../../../../hooks';

export function FilterBadge({
  filters,
  'data-testid': dataTestId
}: {
  filters: unknown[] | undefined;
  'data-testid'?: string;
}): JSX.Element {
  const { t } = useLocales();
  return (
    <>
      {!!filters?.length && (
        <Chip
          size="small"
          icon={<FilterList fontSize="small" />}
          label={t('collections.advanced_search.filters.active_filters', {
            x: filters.length
          })}
          data-testid={dataTestId}
        />
      )}
    </>
  );
}
