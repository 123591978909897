import { MutableRefObject, useEffect, useRef } from 'react';

type ReturnType = {
  mounted: MutableRefObject<boolean>;
  willUnmount: MutableRefObject<boolean>;
};

export function useLifecycle(): ReturnType {
  const willUnmount = useRef(false);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
      willUnmount.current = true;
    };
  }, []);

  return { mounted, willUnmount };
}
