import { useRecoilValue } from 'recoil';
import { PermissionsGroupId } from '../../API';
import { withCurrentUserPermissionsByGroup } from '../../state/auth';
import { PermissionsLevel } from '../../utils/types/permissionsTypes';

export type usePresetsReturnType = {
  hasPermission: (permissionsLevel: PermissionsLevel, permissionsGroupId?: PermissionsGroupId) => boolean;
  hasPermissions: (permissionsGroupId?: PermissionsGroupId) => Record<PermissionsLevel, boolean>;
  hasReadPermission: (permissionsGroupId?: PermissionsGroupId) => boolean;
  hasUpsertPermission: (permissionsGroupId?: PermissionsGroupId) => boolean;
  hasDeletePermission: (permissionsGroupId?: PermissionsGroupId) => boolean;
  hasPublishPermission: (permissionsGroupId?: PermissionsGroupId) => boolean;
  hasAnyPermission: (permissionsLevel: PermissionsLevel) => boolean;
};

export function usePermissions(): usePresetsReturnType {
  const currentUserPermissions = useRecoilValue(withCurrentUserPermissionsByGroup);

  const hasPermission = (permissionsLevel: PermissionsLevel, permissionsGroupId?: PermissionsGroupId): boolean => {
    return (
      !permissionsGroupId ||
      (currentUserPermissions[permissionsGroupId] && currentUserPermissions[permissionsGroupId][permissionsLevel])
    );
  };

  const hasPermissions = (permissionsGroupId?: PermissionsGroupId): Record<PermissionsLevel, boolean> => {
    return Object.values(PermissionsLevel).reduce(
      (acc, permissionsLevel) => ({ ...acc, [permissionsLevel]: hasPermission(permissionsLevel, permissionsGroupId) }),
      {} as Record<PermissionsLevel, boolean>
    );
  };

  const hasReadPermission = (permissionsGroupId?: PermissionsGroupId): boolean => {
    return hasPermission(PermissionsLevel.READ, permissionsGroupId);
  };

  const hasUpsertPermission = (permissionsGroupId?: PermissionsGroupId): boolean => {
    return hasPermission(PermissionsLevel.UPSERT, permissionsGroupId);
  };

  const hasDeletePermission = (permissionsGroupId?: PermissionsGroupId): boolean => {
    return hasPermission(PermissionsLevel.DELETE, permissionsGroupId);
  };

  const hasPublishPermission = (permissionsGroupId?: PermissionsGroupId): boolean => {
    return hasPermission(PermissionsLevel.PUBLISH, permissionsGroupId);
  };

  const hasAnyPermission = (permissionsLevel: PermissionsLevel): boolean => {
    return (
      Object.values(PermissionsGroupId)
        .map((permissionsGroupId: PermissionsGroupId) => hasPermission(permissionsLevel, permissionsGroupId))
        .filter(Boolean).length > 0
    );
  };

  return {
    hasPermission,
    hasPermissions,
    hasReadPermission,
    hasUpsertPermission,
    hasDeletePermission,
    hasPublishPermission,
    hasAnyPermission
  };
}
