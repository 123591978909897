import { CollectionListUiModuleBody, CollectionListUiModuleBodyModuleTypeEnum, DocumentLocale } from '../../../API';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankCollectionListModule: NewModuleFunction<CollectionListUiModuleBody> = (module) => ({
  moduleType: CollectionListUiModuleBodyModuleTypeEnum.COLLECTION_LIST,
  title: {
    [DocumentLocale.ES]: ''
  },
  caption: {
    [DocumentLocale.ES]: ''
  },
  collections: [],
  templateUrlPath: '',
  ...module
});
