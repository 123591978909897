import { atom } from 'recoil';
import { ValidateImportEntityResponse } from '../../API';
import { UIModuleType } from '../../utils/layouts';

export interface UiModuleImportInconsistenciesModalProps {
  isOpen: boolean;
  uiModuleType?: UIModuleType;
  data?: ValidateImportEntityResponse | null;
}

export const withUiModuleImportInconsistenciesModal = atom<UiModuleImportInconsistenciesModalProps>({
  key: 'exchange.uiModuleImportInconsistenciesModal',
  default: {
    isOpen: false,
    uiModuleType: undefined,
    data: null
  }
});
