import React from 'react';
import { makeStyles } from 'tss-react/mui';
import CountryBadgesMerged from '../../shared/CountryBadgesMerged';
import { useLocales } from '../../../hooks';
import { themeColors } from '../../../theme';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: themeColors.orange,
    borderRadius: 99,
    alignItems: 'center',
    color: '#000',
    padding: 1,
    paddingLeft: theme.spacing(2)
  },
  live: {
    textTransform: 'uppercase',
    marginRight: theme.spacing(2)
  }
}));

export interface ILiveCountriesBadgeProps {
  countryCodes: string[];
}

export const testIds = {
  componentRoot: 'live-countries-badge.component-root'
};

export default function LiveCountriesBadge({ countryCodes }: ILiveCountriesBadgeProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();

  return (
    <div className={classes.root} data-testid={testIds.componentRoot}>
      <span className={classes.live}>{t('general.live')}</span>
      <CountryBadgesMerged countryCodes={countryCodes} />
    </div>
  );
}
