import React, { useEffect } from 'react';
import { Visibility } from '@mui/icons-material';
import { ListItem, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useData } from '../../../../data-layer';
import { useLocales, useTheme } from '../../../../hooks';
import Button from '../../Button';
import CollectionLabel from '../../CollectionLabel';
import { makeStyles } from 'tss-react/mui';

export const testIds = {
  viewBtn: 'content-collection-list-item.view-btn',
  contentCountLabel: 'content-collection-list-item.content-count-label'
};

const useStyles = makeStyles()((theme) => ({
  listItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(4)
  },
  collection: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'stretch',
    alignItems: 'center'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    flexGrow: 1
  },
  actions: {
    display: 'flex',
    gap: theme.spacing(2)
  }
}));

export interface ContentCollectionListItemProps {
  collectionId: string;
  onView: (collectionId: string) => void;
  'data-testid'?: string;
}

export default function ContentCollectionListItem({
  collectionId,
  onView,
  'data-testid': dataTestId
}: ContentCollectionListItemProps): JSX.Element {
  const {
    collections: {
      state: { withRecordById },
      hook: { queueIdToFetch }
    }
  } = useData();

  const { classes } = useStyles();
  const { formControlColor } = useTheme();
  const { t } = useLocales();

  const collection = useRecoilValue(withRecordById(collectionId));

  useEffect(() => {
    if (!collection) {
      queueIdToFetch(collectionId);
    }
  }, [collectionId]);

  if (!collection) return <></>;

  const handleViewClick = () => onView(collectionId);

  return (
    <ListItem className={classes.listItem} data-testid={dataTestId}>
      <div className={classes.collection}>
        <div className={classes.details}>
          <CollectionLabel collectionId={collectionId} />
          <Typography data-testid={testIds.contentCountLabel} variant="caption">
            {t('content.drawer.n_content', { n: collection.contents.length })}
          </Typography>
        </div>
        <div className={classes.actions}>
          <Button
            variant="text"
            color={formControlColor}
            onClick={handleViewClick}
            startIcon={<Visibility />}
            data-testid={testIds.viewBtn}
          >
            {t('content.drawer.view')}
          </Button>
        </div>
      </div>
    </ListItem>
  );
}
