import React, { useState } from 'react';
import { DataObjectRounded } from '@mui/icons-material';
import { IconButtonProps, Popover } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../../hooks';
import generateId from '../../../utils/generateId';
import IconButton from '../IconButton';
import { ObjectTreeView } from '../ObjectTreeView';

export type ObjectPreviewProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any;
  title: string;
} & Pick<IconButtonProps, 'size'>;

const useStyles = makeStyles()((theme) => ({
  treeView: {
    flexGrow: 1,
    overflowY: 'auto',
    width: 400,
    height: 300,
    maxHeight: 300,
    padding: theme.spacing(2, 0),
    wordBreak: 'break-all'
  }
}));

export function ObjectPreview({ object, title, size }: ObjectPreviewProps): React.ReactElement {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useLocales();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? generateId() : undefined;

  return (
    <>
      <IconButton aria-describedby={id} size={size} onClick={handleClick} title={t('general.preview_object')}>
        <DataObjectRounded fontSize={size} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <ObjectTreeView object={object} title={title} className={classes.treeView} />
      </Popover>
    </>
  );
}
