import { useHandleError, useLocales } from '..';
import { FiltersResponse } from '../../API';
import { useFiltersAPI } from '../API/Filters/useFiltersAPI';

type ReturnType = {
  getFilter: (index: string, field: string) => Promise<FiltersResponse[] | undefined>;
  getSuggestions: (propertyName: string, searchTerm: string) => Promise<string[] | undefined>;
};

export function useFilters(): ReturnType {
  const filtersApi = useFiltersAPI();
  const { handleError } = useHandleError();
  const { t } = useLocales();

  const getFilter = async (index: string, field: string) => {
    try {
      const { data: response } = await filtersApi.getPaginated(index, field);
      return response.body.results;
    } catch (err) {
      console.error(err);
      handleError(err, t('errors.filters.getFilter'), [
        `${t('filters.index')}: ${index}`,
        `${t('filters.field')}: ${field}`
      ]);
    }
  };

  const getSuggestions = async (propertyName: string, searchTerm: string) => {
    try {
      const { data: response } = await filtersApi.getSuggestions(propertyName, searchTerm);
      return response.body.suggestions;
    } catch (err) {
      console.error(err);
      handleError(err, t('errors.filters.getSuggestions'), [
        `${t('filters.propertyName')}: ${propertyName}`,
        `${t('filters.term')}: ${searchTerm}`
      ]);
    }
  };

  return {
    getFilter,
    getSuggestions
  };
}
