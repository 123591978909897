import React, { forwardRef } from 'react';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { PlaybackPaywallUiModuleBody } from '../../../../../API';
import { Controller, useFormContext } from 'react-hook-form';
import { FormBody, FormField, SectionHeader } from '../../styles';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { LocaleKeys } from '../../../../../locales/i18n';
import { useLocales } from '../../../../../hooks';

export const testIds = {
  root: 'playback-paywall.root',
  formBody: 'playback-paywall.form-body',
  formField: (name: keyof PlaybackPaywallUiModuleBody): string => `playback-paywall.form-field.${name}`
};

const PlaybackPaywallForm = forwardRef<UIModuleTypeForm<PlaybackPaywallUiModuleBody>>(() => {
  const { control } = useFormContext<PlaybackPaywallUiModuleBody>();
  const { t } = useLocales();

  const LokaliseField = (name: keyof PlaybackPaywallUiModuleBody, required = false): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.playback_paywall.fields.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  return (
    <FormBody data-testid={testIds.formBody}>
      <div>
        <SectionHeader>{t('layouts.playback_paywall.headers.trailer')}</SectionHeader>
        {LokaliseField('postTrailerPrimaryText', true)}
        {LokaliseField('postTrailerPrimaryCta', true)}
        {LokaliseField('postTrailerSecondaryCtaAuth')}
        {LokaliseField('postTrailerSecondaryCtaAnon')}
      </div>
      <div>
        <SectionHeader>{t('layouts.playback_paywall.headers.up_next')}</SectionHeader>
        {LokaliseField('postEpisodePrimaryCta', true)}
        {LokaliseField('postEpisodeSecondaryCtaAuth')}
        {LokaliseField('postEpisodeSecondaryCtaAnon')}
      </div>
    </FormBody>
  );
});

PlaybackPaywallForm.displayName = 'PlaybackPaywallForm';

export default PlaybackPaywallForm;
