import { Check, ContentPaste, Launch } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import { useLocales } from '../../../hooks';
import { copyTextToClipboard } from '../../../utils/clipboard';
import IconButton from '../IconButton';

export interface IdBadgeProps {
  className?: string;
  id: string;
  linkUrl?: string;
  linkIsExternal?: boolean;
  showIdLabel?: boolean;
  'data-testid'?: string;
  forceWhiteForeground?: boolean; // Set to true when using this component inside of a tooltip
}

const useStyles = makeStyles<{ forceWhiteForeground: boolean }>()((theme, { forceWhiteForeground }) => {
  const fgColor = forceWhiteForeground ? '#fff' : 'unset';
  return {
    badge: {
      fontFamily: 'monospace',
      '& > span': {
        paddingRight: 0
      }
    },
    container: {
      display: 'flex',
      alignItems: 'center'
    },
    iconButton: {
      margin: 0,
      color: fgColor
    },
    muiIcon: {
      fontSize: '0.9em'
    },
    chipContainer: {
      display: 'inline-flex',
      alignItems: 'center',
      fontFamily: 'monospace',
      overflow: 'hidden',
      maxWidth: '100%'
    },
    idLabel: {
      fontSize: '0.75em',
      marginRight: '3px',
      opacity: 0.6
    },
    span: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: fgColor
    }
  };
});

export const testIds = {
  copyButton: 'id-badge.copy-button',
  linkButton: 'id-badge.link-button'
};

export function IdBadge({
  id,
  className,
  linkUrl,
  linkIsExternal,
  showIdLabel = false,
  forceWhiteForeground = false,
  ...props
}: IdBadgeProps): React.ReactElement {
  const { classes } = useStyles({ forceWhiteForeground });
  const { t } = useLocales();
  const [copied, setCopied] = useState(false);

  const copyIdToClipboard: React.MouseEventHandler = async (evt) => {
    evt.stopPropagation();
    if (copied) return;
    await copyTextToClipboard(id);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  const onLinkClick: React.MouseEventHandler = (evt) => {
    evt.stopPropagation();
    if (linkIsExternal) {
      window.open(linkUrl);
    }
  };

  return (
    <div className={classes.chipContainer}>
      {showIdLabel && <div className={classes.idLabel}>ID</div>}
      <Chip
        className={`${classes.badge} ${className}`}
        size="small"
        label={
          <div className={classes.container}>
            <span className={classes.span}>{id}</span>
            <IconButton
              title={t(`general.${copied ? 'copied' : 'copy_to_clipboard'}`)}
              className={classes.iconButton}
              size="small"
              onClick={copyIdToClipboard}
              data-testid={testIds.copyButton}
            >
              {!copied && <ContentPaste className={classes.muiIcon} />}
              {copied && <Check className={classes.muiIcon} />}
            </IconButton>
            {linkUrl && (
              <IconButton
                className={classes.iconButton}
                size="small"
                onClick={onLinkClick}
                data-testid={testIds.linkButton}
              >
                <Launch className={classes.muiIcon} />
              </IconButton>
            )}
          </div>
        }
        data-testid={props['data-testid']}
      />
    </div>
  );
}
