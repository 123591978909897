import React from 'react';
import { Checkbox, Chip, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Autocomplete from '@mui/material/Autocomplete';
import { useLocales, useTheme } from '../../../hooks';

export interface IMultiSelectProps {
  className?: string;
  label: string;
  options: string[];
  value: string[];
  onChange: (changedValues: string[]) => void;
  required?: boolean;
  renderLabel?: (value: string) => string;
}

const useStyles = makeStyles()((theme) => ({
  optionCheckbox: {
    marginRight: theme.spacing(2)
  }
}));

export const testIds = {
  autocomplete: 'multi-select.autocomplete',
  option: 'multi-select.option',
  chip: 'multi-select.chip',
  textField: 'text-field'
};

export function MultiSelect({
  className,
  label,
  options,
  value,
  onChange,
  required,
  renderLabel = (value) => value
}: IMultiSelectProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  return (
    <Autocomplete
      multiple
      options={options}
      disableCloseOnSelect
      color={formControlColor}
      renderOption={(props, option, { selected }) => (
        <li {...props} data-testid={testIds.option} data-option={option}>
          <Checkbox className={classes.optionCheckbox} checked={selected} color={formControlColor} />
          {renderLabel(option)}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          required={required}
          {...params}
          color={formControlColor}
          data-testid={testIds.textField}
          error={required && !value.length}
          helperText={required && !value.length && t('general.field_is_required')}
          label={label}
        />
      )}
      //TODO KEY ISSUE
      renderTags={(value: string[], getTagProps) =>
        value.map((option: string, index: number) => (
          // eslint-disable-next-line react/jsx-key
          <Chip
            size="small"
            label={renderLabel(option)}
            data-testid={testIds.chip}
            data-option={option}
            {...getTagProps({ index })}
          />
        ))
      }
      value={value}
      onChange={(_, changedValues) => onChange(changedValues)}
      className={className}
      data-testid={testIds.autocomplete}
    />
  );
}
