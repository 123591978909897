import React from 'react';
import { CustomIcon, ICustomIconProps } from '../../CustomIcon/CustomIcon';

export function SubIcon(props: Omit<ICustomIconProps, 'pathString' | 'viewBox'>): React.ReactElement {
  return (
    <CustomIcon
      // eslint-disable-next-line max-len
      pathString="M181,1 C233.467051,1 276,43.5329488 276,96 C276,116.995774 269.18892,136.400735 257.655759,152.125886 C313.093538,168.365781 360.052205,201.911739 360.985835,249.552044 L361,251 L361,341 C361,354.194444 354.471499,360.860388 341.414497,361 L21,361 C7.80555556,361 1.13961227,354.471499 1,341.414497 L1,251 C1,202.591201 48.3464844,168.529821 104.343578,152.1232 C92.8110796,136.400735 86,116.995774 86,96 C86,43.5329488 128.532949,1 181,1 Z M181.766113,177.424805 C166.540616,177.424805 166.540616,177.424805 166.386823,202.1424 L166.385254,202.649414 L166.385254,245.100586 L124.241699,245.100586 C99.0170898,245.100586 99.0170898,245.100586 99.0170898,260.173828 C99.0170898,274.484305 99.0170898,274.484305 123.729538,274.630331 L124.241699,274.631836 L166.385254,274.631836 L166.385254,316.775391 C166.385254,342 166.385254,342 181.766113,342 C196.07659,342 196.07659,342 196.222616,317.287552 L196.224121,316.775391 L196.224121,274.631836 L238.367676,274.631836 C263.592285,274.631836 263.592285,274.631836 263.592285,260.173828 C263.592285,245.254395 263.592285,245.254395 238.879837,245.102155 L238.367676,245.100586 L196.224121,245.100586 L196.224121,202.649414 C196.224121,177.424805 196.224121,177.424805 181.766113,177.424805 Z"
      viewBox="0 0 362 362"
      {...props}
    />
  );
}
