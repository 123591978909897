import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { Accordion, AccordionDetails, AccordionSummary, Chip, emphasize, Typography } from '@mui/material';
import Modal from '../../shared/Modal';
import Button from '../../shared/Button';
import { useLocales } from '../../../hooks';
import { withIsSavingLayout } from '../../../state/Layouts';
import { CheckCircle, Error, ExpandMore, Report, Warning } from '@mui/icons-material';
import { bigSize } from '../../shared/Modal/Modal';
import { withUiModuleImportInconsistenciesModal } from '../../../state/General/withExchange';
import { getLocalizedModuleField } from '../../../utils/dataExchange/uiModules';

const useStyles = makeStyles()((theme) => ({
  modal: bigSize,
  modalTitle: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    gap: theme.spacing(3)
  },
  modalContent: {
    padding: theme.spacing(2)
  },
  accordionHeader: {
    display: 'flex',
    gap: theme.spacing(3),
    alignItems: 'center'
  },
  accordionContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    background: emphasize(theme.palette.background.paper, 0.03)
  },
  accordionContentListProperties: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    placeItems: 'flex-center',
    listStyle: 'number',
    padding: 0,
    margin: theme.spacing(0, 0, 0, 5)
  },
  modalButtons: {
    display: 'flex',
    gap: theme.spacing(4)
  },
  layoutStats: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3)
  },
  layoutStat: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  propName: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  }
}));

export const testIds = {
  root: 'uimodule-import-inconsistencies-modal.root',
  closeAndFixBtn: 'uimodule-import-inconsistencies-modal.save-btn',
  numberOfErrors: 'uimodule-import-inconsistencies-modal.number-of-errors',
  numberOfWarnings: 'uimodule-import-inconsistencies-modal.number-of-warnings',
  numberOfCorrect: 'uimodule-import-inconsistencies-modal.number-of-correct',
  unspecifiedProperties: 'uimodule-import-inconsistencies-modal.unspecified-properties',
  unspecifiedPropertiesItem: 'uimodule-import-inconsistencies-modal.unspecified-properties-item',
  unexpectedProperties: 'uimodule-import-inconsistencies-modal.unexpected-properties',
  unexpectedPropertiesItem: 'uimodule-import-inconsistencies-modal.unexpected-properties-item',
  emptyProperties: 'uimodule-import-inconsistencies-modal.empty-properties',
  emptyPropertiesItem: 'uimodule-import-inconsistencies-modal.empty-properties-item',
  emptyPropertiesSubText: 'uimodule-import-inconsistencies-modal.empty-properties-subtext'
};

function UIModuleImportInconsistenciesModal(): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const [uiModuleImportInconsistencies, setUiModuleImportInconsistencies] = useRecoilState(
    withUiModuleImportInconsistenciesModal
  );

  const isSavingLayout = useRecoilValue(withIsSavingLayout);

  const unexpectedPropertiesCount = uiModuleImportInconsistencies.data?.propertiesUnexpected?.length || 0;
  const unspecifiedPropertiesCount = uiModuleImportInconsistencies.data?.requiredPropertiesUnspecified?.length || 0;

  const errorPropertiesCount = unexpectedPropertiesCount + unspecifiedPropertiesCount;
  const incompletePropertiesCount = uiModuleImportInconsistencies.data?.requiredPropertiesEmptied?.length || 0;
  const correctPropertiesCount = uiModuleImportInconsistencies.data?.propertiesMatchedCorrectly?.length || 0;

  const localizedModuleField = getLocalizedModuleField(uiModuleImportInconsistencies.uiModuleType);

  const closeModal = () => {
    if (isSavingLayout) return;
    setUiModuleImportInconsistencies({
      ...uiModuleImportInconsistencies,
      isOpen: false
    });
  };

  const getFriendlyPropertyName = (property: string) => {
    const friendlyPropertyName = t(`${localizedModuleField}.${property}`);

    const localizedName = friendlyPropertyName.includes('.') ? '' : friendlyPropertyName;

    return (
      <div className={classes.propName}>
        {localizedName && <b>{localizedName}</b>}
        <Chip size="small" color="default" label={<>{property}</>} />
      </div>
    );
  };

  return (
    <Modal
      open={uiModuleImportInconsistencies.isOpen}
      bodyClassName={classes.modal}
      headerLeft={
        <div className={classes.modalTitle}>
          <Report color="info" />
          <div>
            <Typography variant="body1" fontWeight={500}>
              {t('data_exchange.import_warning_modal.header_title')}
            </Typography>
            <Typography variant="body2">{t('data_exchange.import_warning_modal.header_subtitle')}</Typography>
          </div>
        </div>
      }
      footerRight={
        <div className={classes.layoutStats}>
          <div className={classes.layoutStat}>
            <Error fontSize="small" color="error" />{' '}
            <span data-testid={testIds.numberOfErrors}>{errorPropertiesCount}</span> {t('general.errors')}
          </div>
          <div className={classes.layoutStat}>
            <Warning fontSize="small" color="warning" />{' '}
            <span data-testid={testIds.numberOfWarnings}>{incompletePropertiesCount}</span> {t('general.warnings')}
          </div>
          <div className={classes.layoutStat}>
            <CheckCircle fontSize="small" color="success" />{' '}
            <span data-testid={testIds.numberOfCorrect}>{correctPropertiesCount}</span> {t('general.correct')}
          </div>
        </div>
      }
      footerLeft={
        <div className={classes.modalButtons}>
          <Button
            size="small"
            onClick={closeModal}
            color="grey"
            data-testid={testIds.closeAndFixBtn}
            disabled={isSavingLayout}
          >
            {t('data_exchange.import_warning_modal.close_button')}
          </Button>
        </div>
      }
      data-testid={testIds.root}
      onClose={closeModal}
    >
      <div className={classes.modalContent}>
        {unspecifiedPropertiesCount > 0 && (
          <Accordion data-testid={testIds.unspecifiedProperties}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <div className={classes.accordionHeader}>
                <Error fontSize="small" color="error" />
                <Typography variant="body1" fontWeight={500}>
                  {t('data_exchange.import_warning_modal.unspecified_properties')}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
              <ul className={classes.accordionContentListProperties}>
                {uiModuleImportInconsistencies.data?.requiredPropertiesUnspecified?.map((property, idx) => (
                  <li key={idx} data-testid={testIds.unspecifiedPropertiesItem}>
                    <Typography variant="body1">{getFriendlyPropertyName(property)}</Typography>
                  </li>
                ))}
              </ul>
            </AccordionDetails>
          </Accordion>
        )}
        {unexpectedPropertiesCount > 0 && (
          <Accordion data-testid={testIds.unexpectedProperties}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <div className={classes.accordionHeader}>
                <Error fontSize="small" color="error" />
                <Typography variant="body1" fontWeight={500}>
                  {t('data_exchange.import_warning_modal.unexpected_properties')}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
              <ul className={classes.accordionContentListProperties}>
                {uiModuleImportInconsistencies.data?.propertiesUnexpected?.map((property, idx) => (
                  <li key={idx} data-testid={testIds.unexpectedPropertiesItem}>
                    <Typography variant="body1">{getFriendlyPropertyName(property)}</Typography>
                  </li>
                ))}
              </ul>
            </AccordionDetails>
          </Accordion>
        )}
        {incompletePropertiesCount > 0 && (
          <Accordion data-testid={testIds.emptyProperties}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <div className={classes.accordionHeader}>
                <Warning fontSize="small" color="warning" />
                <Typography variant="body1" fontWeight={500}>
                  {t('data_exchange.import_warning_modal.empty_properties')}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
              <Typography variant="body2" data-testid={testIds.emptyPropertiesSubText}>
                {t('data_exchange.import_warning_modal.empty_required_properties_subtext')}
              </Typography>
              <ul className={classes.accordionContentListProperties}>
                {uiModuleImportInconsistencies.data?.requiredPropertiesEmptied?.map((property, idx) => (
                  <li key={idx} data-testid={testIds.emptyPropertiesItem}>
                    <Typography variant="body1">{getFriendlyPropertyName(property)}</Typography>
                  </li>
                ))}
              </ul>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    </Modal>
  );
}

export default UIModuleImportInconsistenciesModal;
