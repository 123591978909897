export enum FsUserInteractionType {
  UPDATED = 'UPDATED',
  BEING_WATCHED = 'BEING_WATCHED',
  BEING_EDITED = 'BEING_EDITED'
}

export enum FsEntityType {
  LAYOUT = 'CMS_LAYOUT',
  COLLECTION = 'CMS_COLLECTION'
}

export interface FsUserInfo {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  photoURL?: string;
  userInteractionType: FsUserInteractionType;
  createdDate: string;
}
