/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiPaginatedResponseOfSportResponse } from '../model';
// @ts-ignore
import { ApiPaginatedResponseOfTournamentResponse } from '../model';
// @ts-ignore
import { ApiResponseOfIsDeletableResponse } from '../model';
// @ts-ignore
import { ApiResponseOfSportResponse } from '../model';
// @ts-ignore
import { ApiResponseOfSportResponseArray } from '../model';
// @ts-ignore
import { SportBody } from '../model';
/**
 * SportsApi - axios parameter creator
 * @export
 */
export const SportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SportBody} sportBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (sportBody: SportBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sportBody' is not null or undefined
            assertParamExists('create', 'sportBody', sportBody)
            const localVarPath = `/api/v1/sports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sportBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sportId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (sportId: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sportId' is not null or undefined
            assertParamExists('getById', 'sportId', sportId)
            const localVarPath = `/api/v1/sports/{sportId}`
                .replace(`{${"sportId"}}`, encodeURIComponent(String(sportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} sportIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIds: async (sportIds: Array<string>, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sportIds' is not null or undefined
            assertParamExists('getByIds', 'sportIds', sportIds)
            const localVarPath = `/api/v1/sports/getByIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sportIds) {
                localVarQueryParameter['sportIds'] = sportIds;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated: async (limit?: number, page?: number, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sportId 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTournamentsBySportId: async (sportId: string, limit?: number, page?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sportId' is not null or undefined
            assertParamExists('getTournamentsBySportId', 'sportId', sportId)
            const localVarPath = `/api/v1/sports/{sportId}/tournaments`
                .replace(`{${"sportId"}}`, encodeURIComponent(String(sportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isDeletable: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('isDeletable', 'id', id)
            const localVarPath = `/api/v1/sports/{id}/isDeletable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/sports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sportId 
         * @param {SportBody} sportBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (sportId: string, sportBody: SportBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sportId' is not null or undefined
            assertParamExists('update', 'sportId', sportId)
            // verify required parameter 'sportBody' is not null or undefined
            assertParamExists('update', 'sportBody', sportBody)
            const localVarPath = `/api/v1/sports/{sportId}`
                .replace(`{${"sportId"}}`, encodeURIComponent(String(sportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sportBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SportsApi - functional programming interface
 * @export
 */
export const SportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SportBody} sportBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(sportBody: SportBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfSportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(sportBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sportId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(sportId: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfSportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(sportId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} sportIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIds(sportIds: Array<string>, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfSportResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIds(sportIds, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginated(limit?: number, page?: number, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfSportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginated(limit, page, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sportId 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTournamentsBySportId(sportId: string, limit?: number, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfTournamentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTournamentsBySportId(sportId, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isDeletable(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfIsDeletableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isDeletable(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sportId 
         * @param {SportBody} sportBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(sportId: string, sportBody: SportBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfSportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(sportId, sportBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SportsApi - factory interface
 * @export
 */
export const SportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SportsApiFp(configuration)
    return {
        /**
         * 
         * @param {SportBody} sportBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(sportBody: SportBody, options?: any): AxiosPromise<ApiResponseOfSportResponse> {
            return localVarFp.create(sportBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sportId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(sportId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfSportResponse> {
            return localVarFp.getById(sportId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} sportIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIds(sportIds: Array<string>, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfSportResponseArray> {
            return localVarFp.getByIds(sportIds, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated(limit?: number, page?: number, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfSportResponse> {
            return localVarFp.getPaginated(limit, page, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sportId 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTournamentsBySportId(sportId: string, limit?: number, page?: number, options?: any): AxiosPromise<ApiPaginatedResponseOfTournamentResponse> {
            return localVarFp.getTournamentsBySportId(sportId, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isDeletable(id: string, options?: any): AxiosPromise<ApiResponseOfIsDeletableResponse> {
            return localVarFp.isDeletable(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sportId 
         * @param {SportBody} sportBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(sportId: string, sportBody: SportBody, options?: any): AxiosPromise<ApiResponseOfSportResponse> {
            return localVarFp.update(sportId, sportBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SportsApi - interface
 * @export
 * @interface SportsApi
 */
export interface SportsApiInterface {
    /**
     * 
     * @param {SportBody} sportBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportsApiInterface
     */
    create(sportBody: SportBody, options?: any): AxiosPromise<ApiResponseOfSportResponse>;

    /**
     * 
     * @param {string} sportId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportsApiInterface
     */
    getById(sportId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfSportResponse>;

    /**
     * 
     * @param {Array<string>} sportIds 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportsApiInterface
     */
    getByIds(sportIds: Array<string>, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfSportResponseArray>;

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportsApiInterface
     */
    getPaginated(limit?: number, page?: number, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfSportResponse>;

    /**
     * 
     * @param {string} sportId 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportsApiInterface
     */
    getTournamentsBySportId(sportId: string, limit?: number, page?: number, options?: any): AxiosPromise<ApiPaginatedResponseOfTournamentResponse>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportsApiInterface
     */
    isDeletable(id: string, options?: any): AxiosPromise<ApiResponseOfIsDeletableResponse>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportsApiInterface
     */
    remove(id: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {string} sportId 
     * @param {SportBody} sportBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportsApiInterface
     */
    update(sportId: string, sportBody: SportBody, options?: any): AxiosPromise<ApiResponseOfSportResponse>;

}

/**
 * SportsApi - object-oriented interface
 * @export
 * @class SportsApi
 * @extends {BaseAPI}
 */
export class SportsApi extends BaseAPI implements SportsApiInterface {
    /**
     * 
     * @param {SportBody} sportBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportsApi
     */
    public create(sportBody: SportBody, options?: any) {
        return SportsApiFp(this.configuration).create(sportBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sportId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportsApi
     */
    public getById(sportId: string, includeDeleted?: boolean, options?: any) {
        return SportsApiFp(this.configuration).getById(sportId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} sportIds 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportsApi
     */
    public getByIds(sportIds: Array<string>, includeDeleted?: boolean, options?: any) {
        return SportsApiFp(this.configuration).getByIds(sportIds, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportsApi
     */
    public getPaginated(limit?: number, page?: number, includeDeleted?: boolean, options?: any) {
        return SportsApiFp(this.configuration).getPaginated(limit, page, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sportId 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportsApi
     */
    public getTournamentsBySportId(sportId: string, limit?: number, page?: number, options?: any) {
        return SportsApiFp(this.configuration).getTournamentsBySportId(sportId, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportsApi
     */
    public isDeletable(id: string, options?: any) {
        return SportsApiFp(this.configuration).isDeletable(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportsApi
     */
    public remove(id: string, options?: any) {
        return SportsApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sportId 
     * @param {SportBody} sportBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportsApi
     */
    public update(sportId: string, sportBody: SportBody, options?: any) {
        return SportsApiFp(this.configuration).update(sportId, sportBody, options).then((request) => request(this.axios, this.basePath));
    }
}
