import React from 'react';
import { StatsigProvider } from 'statsig-react';
import { useAuth } from '../../hooks';
import { isEmpty } from 'lodash-es';

const statSigEnvironment = process.env.REACT_APP_STATSIG_TIER as 'production' | 'staging' | 'development' | undefined;

export function StatSigProvider({ children }: { children: React.ReactNode | React.ReactNode[] }): JSX.Element {
  const { currentUser } = useAuth();
  if (isEmpty(process.env.REACT_APP_STATSIG_SECRET_KEY)) {
    return children as React.ReactNode | React.ReactNode[] as JSX.Element;
  }

  return (
    <StatsigProvider
      sdkKey={`${process.env.REACT_APP_STATSIG_SECRET_KEY}`}
      user={{
        userID: currentUser?.email
      }}
      options={{ environment: { tier: statSigEnvironment } }}
      waitForInitialization={true}
    >
      {children}
    </StatsigProvider>
  );
}
