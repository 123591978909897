import React, { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CopyUiModuleBody } from '../../../../../API';
import { useLocales } from '../../../../../hooks';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { FormBody } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';

export const testIds = {
  copyFormBody: 'ui-module-copy-form.validation-form',
  textInput: 'ui-module-copy-form.text-input'
};

const CopyForm = forwardRef<UIModuleTypeForm<CopyUiModuleBody>>(() => {
  const { control } = useFormContext<CopyUiModuleBody>();
  const { t } = useLocales();

  return (
    <FormBody>
      <div data-testid={testIds.copyFormBody}>
        <Controller
          control={control}
          name="translationKey"
          render={({ field: { value, onChange } }) => (
            <LokaliseAutocomplete
              name="translationKey"
              label={t('layouts.translationKey')}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </div>
    </FormBody>
  );
});

CopyForm.displayName = 'CopyForm';

export default CopyForm;
