/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { EmbeddedSpinPremia } from './embedded-spin-premia';
import { EntitlementType } from './entitlement-type';
import { LocalizedFieldBody } from './localized-field-body';
import { TargetPlatform } from './target-platform';
import { UiModuleExperimentGroup } from './ui-module-experiment-group';

/**
 * 
 * @export
 * @interface AccountSuccessUiModuleBody
 */
export interface AccountSuccessUiModuleBody {
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    moduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    moduleType: AccountSuccessUiModuleBodyModuleTypeEnum;
    /**
     * 
     * @type {Array<TargetPlatform>}
     * @memberof AccountSuccessUiModuleBody
     */
    targetPlatform?: Array<TargetPlatform>;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof AccountSuccessUiModuleBody
     */
    caption?: LocalizedFieldBody;
    /**
     * 
     * @type {Array<EntitlementType>}
     * @memberof AccountSuccessUiModuleBody
     */
    entitlements?: Array<EntitlementType>;
    /**
     * 
     * @type {Array<UiModuleExperimentGroup>}
     * @memberof AccountSuccessUiModuleBody
     */
    experiments?: Array<UiModuleExperimentGroup> | null;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof AccountSuccessUiModuleBody
     */
    title: LocalizedFieldBody;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    landscapeAccountSuccessImage: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    portraitAccountSuccessImage: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    desktopAccountSuccessImage?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    tabletAccountSuccessImage?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    mobileAccountSuccessImage?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    ctvAccountSuccessImage?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    svodHeader: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    svodSubHeader: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    avodHeader: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    avodSubHeader: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    optInNotificationsCta?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    downloadAndOpenAppCta?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    continueWebCta?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    subscribeCta?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    notNowCta?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    legalDisclaimer?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    partnerHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    partnerSubHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    partnerCta?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSuccessUiModuleBody
     */
    partnerLogo?: string;
    /**
     * 
     * @type {EmbeddedSpinPremia}
     * @memberof AccountSuccessUiModuleBody
     */
    spinPremia?: EmbeddedSpinPremia;
}

/**
    * @export
    * @enum {string}
    */
export enum AccountSuccessUiModuleBodyModuleTypeEnum {
    ACCOUNT_SUCCESS = 'ACCOUNT_SUCCESS'
}



