/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable tss-unused-classes/unused-classes */
import { checkboxClasses, outlinedInputClasses } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';
import { themeColors } from '../theme';

const greyColor = grey[500];

export const previewDetailsDialog = (props: { width: number; padding?: string }) =>
  makeStyles()((theme) => ({
    container: {
      background: '#090C17',
      display: 'flex',
      position: 'relative',
      width: props.width,
      height: '100%',
      color: theme.palette.common.white,
      overflow: 'hidden',
      padding: props?.padding,
      ['& * strong']: {
        color: themeColors.orange,
        fontWeight: 'normal'
      }
    },
    offerContainer: {
      background: '#21252E',
      padding: theme.spacing(3)
    },
    cssLabel: {
      color: greyColor
    },
    cssFocused: {
      color: `${greyColor} !important`
    },
    textField: {
      [`& .${outlinedInputClasses.root}`]: {
        color: theme.palette.common.white,
        ['& fieldset']: {
          borderColor: greyColor
        },
        ['&:hover fieldset']: {
          borderColor: greyColor
        },
        ['&.Mui-focused fieldset']: {
          borderColor: greyColor
        }
      }
    },
    checkbox: {
      color: greyColor,
      [`&.${checkboxClasses.checked}`]: {
        color: greyColor
      }
    },
    root: {
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'column',
      height: '100%',
      ['& * strong']: {
        color: themeColors.orange
      }
    },
    tabs: {
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    scroller: {
      display: 'flex',
      justifyContent: 'center',
      minHeight: '100%'
    },
    separator: {
      width: 1,
      background: 'rgba(255,255,255,.5)',
      height: 20
    },
    logo: {
      height: 22
    }
  }));
