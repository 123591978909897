import React, { PropsWithChildren, createContext, useEffect, useRef, useState } from 'react';
import { noop, values } from 'lodash';
import { isUserMac } from '../../../utils/userPlatform';

type HistoryId = string;

type HistoryManagerAction = 'undo' | 'redo' | 'save' | 'publish';
export type GlobalHistoryManagerListenerFunction = (action: HistoryManagerAction) => Promise<void>;

type ManagerType = {
  callback: GlobalHistoryManagerListenerFunction;
  priority: number;
};

type GlobalHistoryManagerContextType = {
  pushState: (id: HistoryId) => void;
  undo: (id: HistoryId) => void;
  redo: (id: HistoryId) => void;
  reset: (id: HistoryId) => void;
  addListener: (id: HistoryId, listener: GlobalHistoryManagerListenerFunction) => void;
  globalUndo: () => void;
  globalRedo: () => void;
  block: () => void;
  unblock: () => void;
};

export const GlobalHistoryManagerContext = createContext<GlobalHistoryManagerContextType>({
  undo: noop,
  redo: noop,
  pushState: noop,
  reset: noop,
  addListener: noop,
  globalUndo: noop,
  globalRedo: noop,
  block: noop,
  unblock: noop
});

export function GlobalHistoryManager({ children }: PropsWithChildren): React.ReactElement {
  const [undoState, setUndoState] = useState<Array<HistoryId>>([]);
  const [redoState, setRedoState] = useState<Array<HistoryId>>([]);
  const isBlocking = useRef(0);

  const managers = useRef<Record<HistoryId, ManagerType>>({});

  const keydown = async (event: KeyboardEvent) => {
    if (isBlocking.current) return;
    const cmdCtrl = isUserMac ? event.metaKey : event.ctrlKey;

    if (/z/i.test(event.key) && cmdCtrl) {
      event.preventDefault();
      if (!event.shiftKey) {
        globalUndo();
      } else {
        globalRedo();
      }
    } else if (event.key === 'y' && cmdCtrl) {
      event.preventDefault();
      globalRedo();
    } else if (event.key === 's' && cmdCtrl) {
      event.preventDefault();
      globalSave();
    } else if (event.key === 'p' && cmdCtrl) {
      event.preventDefault();
      globalPublish();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keydown);
    return () => window.removeEventListener('keydown', keydown);
  }, [undoState, redoState]);

  const pushState = (id: HistoryId) => setUndoState((prevUndoState) => [...prevUndoState, id]);

  const undo = (id: HistoryId) => {
    setUndoState((prevUndoState) => {
      const idx = prevUndoState.findLastIndex((i) => i === id);
      const undo = prevUndoState.splice(idx, 1)[0];
      if (undo) {
        setRedoState((prevRedoState) => [undo as HistoryId, ...prevRedoState]);
        return prevUndoState;
      }
      return prevUndoState;
    });
  };

  const redo = (id: HistoryId) => {
    setRedoState((prevRedoState) => {
      const idx = prevRedoState.findIndex((i) => i === id);
      const redo = prevRedoState.splice(idx, 1)[0];
      if (redo) {
        setUndoState((prevUndoState) => [...prevUndoState, redo as HistoryId]);
        return prevRedoState;
      }
      return prevRedoState;
    });
  };

  const reset = (id: HistoryId) => {
    setUndoState((prevUndoState) => prevUndoState.filter((undo) => undo !== id));
    setRedoState((prevRedoState) => prevRedoState.filter((redo) => redo !== id));
  };

  const addListener = (id: HistoryId, callback: GlobalHistoryManagerListenerFunction, priority = 0) => {
    managers.current = { ...managers.current, [id]: { callback, priority } };
  };

  const globalUndo = () => {
    try {
      const last = undoState[undoState.length - 1];
      if (last) {
        managers.current[last]?.callback('undo');
      }
    } catch {}
  };

  const globalRedo = () => {
    try {
      const first = redoState[0];
      if (first) {
        managers.current[first]?.callback('redo');
      }
    } catch {}
  };

  const block = () => {
    isBlocking.current++;
  };

  const unblock = () => {
    isBlocking.current--;
  };

  const callSortedAction = (action: HistoryManagerAction) =>
    values(managers.current)
      .sort((a, b) => b.priority - a.priority)
      .forEach((m) => m.callback(action));

  const globalSave = () => callSortedAction('save');
  const globalPublish = () => callSortedAction('publish');

  return (
    <GlobalHistoryManagerContext.Provider
      value={{
        undo,
        redo,
        pushState,
        reset,
        addListener,
        globalUndo,
        globalRedo,
        block,
        unblock
      }}
    >
      {children}
    </GlobalHistoryManagerContext.Provider>
  );
}
