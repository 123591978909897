import * as React from 'react';
import { TreeItem, TreeItemProps, useTreeItem, TreeItemContentProps } from '@mui/x-tree-view/TreeItem';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { useLocales } from '../../../hooks';
import { copyTextToClipboard } from '../../../utils/clipboard';

interface CustomTreeItemProps extends TreeItemProps {
  value?: string;
  disableTooltip?: boolean;
}

const CustomContent = React.forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
  const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon, onMouseDown } = props;

  const { disabled, expanded, selected, focused, handleExpansion, handleSelection } = useTreeItem(nodeId);

  const contentRef = React.useRef(null);
  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (onMouseDown) {
      onMouseDown(event);
    }
  };

  const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleSelection(event);
  };

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled
      })}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography ref={contentRef} onClick={handleSelectionClick} component="div" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
});

export const CustomTreeItem = (props: CustomTreeItemProps): JSX.Element => {
  const { value, disableTooltip, ...restProps } = props;

  const { t } = useLocales();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isCopied, setIsCopied] = React.useState<boolean>(false);

  const handleDoubleClick = async (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();
    setIsCopied(true);
    if (value) {
      setIsOpen(true);
      await copyTextToClipboard(value);
      setTimeout(() => {
        setIsOpen(false);
      }, 2000);
      setTimeout(() => {
        setIsCopied(false);
      }, 2100);
    }
  };

  const handleOnMouseOver = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    setIsOpen(true);
  };

  const handleOnMouseOut = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    setIsOpen(false);
  };

  if (value) {
    return (
      <Tooltip
        open={isOpen && !disableTooltip}
        title={isCopied ? t('general.copied') : t('general.double_click_copy_to_clipboard')}
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
        disableFocusListener
        disableTouchListener
        arrow
      >
        <TreeItem ContentComponent={CustomContent} onDoubleClick={handleDoubleClick} {...restProps} />
      </Tooltip>
    );
  }

  return <TreeItem ContentComponent={CustomContent} {...restProps} />;
};
