import React, { useEffect, useState } from 'react';
import { Collapse, InputAdornment, InputLabel, List, MenuItem, Skeleton, Stack, Typography } from '@mui/material';
import TextField from '../../shared/TextField';
import { AddOutlined, Search } from '@mui/icons-material';
import Button from '../../shared/Button';
import ShadowScroller from '../../shared/ShadowScroller';
import { HPCListItem } from '../HPCListItem';
import { collectionsHeaderHeight } from '../../../utils/consts/collectionsConsts';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../../hooks';
import { useData } from '../../../data-layer';
import {
  DocumentLocale,
  HeroPresetCollectionResponse,
  PaginationMeta,
  SortDirection,
  VersionedDocumentStatus
} from '../../../API';
import Pagination from '../../shared/Pagination';
import Repeat from '../../shared/Repeat';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import NoMediaItems from '../../shared/NoMediaItems';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import { GetPaginatedHeroPresetCollectionsQuery } from '../../../API/model/get-paginated-hero-preset-collections-query';
import SearchFilters, { FilterBadge, WithFilters } from '../../shared/SearchFilters';
import PaginationResults from '../../shared/Pagination/PaginationResults';
import AdvancedSearch from '../../shared/AdvancedSearch';
import FormControl from '../../shared/FormControl';
import { ALL, IncludeAll, transformAll } from '../../../utils/types/genericTypes';
import { LocaleKeys } from '../../../locales/i18n';
import { useSearchFilters } from '../../../hooks/General/useSearchFilters';
import { HPC_FILTER_HEIGHT } from '../HPC';
import { PermissionsLevel } from '../../../utils/types/permissionsTypes';
import Select from '../../shared/Select';
import { usePermissionsGuard } from '../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../state/theme';

export type ExtendedHPCQuery = GetPaginatedHeroPresetCollectionsQuery & { sort: SortTypes };
export type HPCQuery = WithFilters<ExtendedHPCQuery, HPCFilterOptions>;

export enum HPCFilterOptions {
  STATUS = 'status'
}

export enum SortTypes {
  SORT_RECENTLY_PUBLISHED = 'SORT_RECENTLY_PUBLISHED',
  SORT_TITLE_ALPHABETICAL = 'SORT_TITLE_ALPHABETICAL'
}

const DEFAULT_SORT_TYPE = SortTypes.SORT_RECENTLY_PUBLISHED;

type SortOptionType = {
  field: string;
  direction: SortDirection;
  localizationKey: LocaleKeys;
  localize?: (lang: DocumentLocale) => string;
};

export const testIds = {
  root: 'hpc-browser.root',
  newButton: 'hpc-browser.new-collection',
  searchButton: 'hpc-browser.search-button',
  searchInput: 'hpc-browser.search-input',
  filter: (type: HPCFilterOptions): string => `hpc-browser.filter.${type}`,
  removeFilter: (type: HPCFilterOptions): string => `hpc-browser.filter.remove.${type}`,
  filterOptions: (type: HPCFilterOptions, param: string): string => `hpc-browser.filter.${type}.${param}`
};

const useStyles = makeStyles()((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  topbar: {
    display: 'flex',
    alignItems: 'center',
    height: collectionsHeaderHeight,
    background: theme.palette.background.paper,
    padding: theme.spacing(3, 4),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  loading: {
    padding: theme.spacing(2),
    gap: theme.spacing(2)
  },
  searchContainer: {
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  textField: {
    '&.MuiTextField-root': {
      marginBottom: '0 !important'
    }
  },
  grow: {
    flexGrow: 1
  },
  filter: {
    minWidth: 200
  },
  bottomSection: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.secondary,
    padding: theme.spacing(3, 4)
  },
  empty: {
    position: 'absolute',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(12),
    inset: 0,
    pointerEvents: 'none'
  }
}));

const filterParams: Record<HPCFilterOptions, (keyof HPCQuery)[]> = {
  [HPCFilterOptions.STATUS]: ['status']
};

const statusFilterOptions = [ALL, ...Object.values(VersionedDocumentStatus)] as [IncludeAll<VersionedDocumentStatus>];

const SortOptions: Record<SortTypes, SortOptionType> = {
  [SortTypes.SORT_RECENTLY_PUBLISHED]: {
    field: 'createdDate',
    direction: SortDirection.DESC,
    localizationKey: 'general.sort.recently_published'
  },
  [SortTypes.SORT_TITLE_ALPHABETICAL]: {
    field: 'name',
    direction: SortDirection.ASC,
    localizationKey: 'general.sort.title'
  }
};

export function HPCBrowser(): React.ReactElement {
  const { canSave } = usePermissionsGuard({
    homepageOption: HomepageOptions.HPC
  });
  const {
    heroPresetCollections: {
      state: { withSearchParams },
      hook: { getPaginated, new: newCollection }
    }
  } = useData();

  const { classes, cx } = useStyles();
  const { t, currentLang } = useLocales();
  const { hasAnyPermission } = usePermissions();

  const formMethods = useForm<HPCQuery>();
  const { control, handleSubmit, reset, setValue } = formMethods;

  const [isLoading, setIsLoading] = useState(false);
  const [meta, setMeta] = useState<PaginationMeta>();
  const [collections, setCollections] = useState<HeroPresetCollectionResponse[]>([]);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchFilters(withSearchParams, filterParams, ['name', 'sort']);

  const actions: Record<string, string> = Object.values(HPCFilterOptions).reduce(
    (r, v) => ({ ...r, [v]: t(`hpc.filters.${v}.name`) }),
    {}
  );

  useEffect(() => {
    reset(searchParams);
    if (!collections.length) {
      onSubmit();
    }
  }, []);

  const handleNew = () => {
    newCollection();
  };

  const handleSearch = async (data: HPCQuery) => {
    setIsLoading(true);
    const { _filters, ...searchData } = data;
    const sort = SortOptions[searchData.sort || DEFAULT_SORT_TYPE];
    const response = await getPaginated({
      ...searchData,
      sortBy: sort.localize
        ? sort.localize(currentLang as DocumentLocale)
        : sort.field || SortOptions[DEFAULT_SORT_TYPE].field,
      sortDirection: sort.direction,
      status: transformAll(data.status)
    });
    setIsLoading(false);
    if (response) {
      setCollections(response.results);
      const params = { ...data, limit: response.meta.limit, page: response.meta.page, _filters };
      setMeta(response.meta);
      setSearchParams(params);
      reset(params);
    }
  };

  const handleNewSearch = async (data: HPCQuery) => handleSearch({ ...data, page: 1 });

  const handleFilterRemove = (filter: HPCFilterOptions) => {
    for (const param of filterParams[filter]) {
      setValue(param, undefined);
    }
  };

  const onSubmit = (event?: React.FormEvent<HTMLElement>) => {
    event?.preventDefault();
    event?.stopPropagation();
    handleSubmit(handleSearch)();
  };

  const onSubmitNew = (event?: React.FormEvent<HTMLElement>) => {
    event?.preventDefault();
    event?.stopPropagation();
    handleSubmit(handleNewSearch)();
  };

  return (
    <form onSubmit={onSubmitNew} className={classes.form} data-testid={testIds.root}>
      <FormProvider {...formMethods}>
        <div className={classes.topbar}>
          <Typography variant="h5">{t('hpc.hero_preset_collections')}</Typography>
        </div>
        <div>
          <div className={classes.searchContainer}>
            <Stack gap={2}>
              <Stack gap={4} direction="row" alignItems="center">
                <Controller
                  control={control}
                  name="name"
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      fullWidth
                      label={t('collections.search_by_keywords')}
                      value={value}
                      className={classes.textField}
                      onChange={onChange}
                      clearable
                      onClear={onSubmitNew}
                      data-testid={testIds.searchInput}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
                <Controller
                  name="sort"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl className={classes.filter}>
                      <InputLabel id="contentSortBy">{t('pagination.sort_by')}</InputLabel>
                      <Select
                        label={t('pagination.sort_by')}
                        labelId="contentSortBy"
                        value={value ? SortTypes[value as SortTypes] : DEFAULT_SORT_TYPE}
                        onChange={onChange}
                      >
                        {Object.keys(SortOptions).map((option) => (
                          <MenuItem key={option} value={option}>
                            {t(SortOptions[option as SortTypes].localizationKey)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                <Button type="submit" data-testid={testIds.searchButton}>
                  {t('general.search')}
                </Button>
              </Stack>
              <Collapse in={isAdvancedSearchOpen}>
                <SearchFilters
                  actions={actions}
                  onRemove={handleFilterRemove}
                  render={(FilterWrap) => (
                    <>
                      <FilterWrap name={HPCFilterOptions.STATUS}>
                        <Controller
                          name="status"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <FormControl className={classes.filter}>
                              <InputLabel id="statusFilter">{t('hpc.filters.status.name')}</InputLabel>
                              <Select
                                data-testid={testIds.filter(HPCFilterOptions.STATUS)}
                                labelId="statusFilter"
                                label={t('hpc.filters.status.name')}
                                value={value ?? ALL}
                                onChange={onChange}
                              >
                                {statusFilterOptions.map((op) => (
                                  <MenuItem
                                    data-testid={testIds.filterOptions(HPCFilterOptions.STATUS, op)}
                                    key={op}
                                    value={op}
                                  >
                                    {t(`hpc.filters.status.options.${op.toLowerCase()}` as LocaleKeys)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </FilterWrap>
                    </>
                  )}
                />
              </Collapse>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ height: HPC_FILTER_HEIGHT }}
              >
                <PaginationResults list={collections} meta={meta} isLoading={isLoading} />
                <Stack gap={3} direction="row" alignItems="center">
                  <FilterBadge filters={searchParams._filters} />
                  <AdvancedSearch open={isAdvancedSearchOpen} onChange={setIsAdvancedSearchOpen} />
                </Stack>
              </Stack>
            </Stack>
          </div>
        </div>
        <div className={classes.grow}>
          <ShadowScroller loading={isLoading}>
            <List className={cx(classes.list, { [classes.loading]: isLoading })} disablePadding={!isLoading}>
              {isLoading ? (
                <Repeat n={20}>
                  <Skeleton height={40} animation="wave" />
                </Repeat>
              ) : collections.length ? (
                collections.map((collection) => (
                  <HPCListItem key={collection.entityId} collectionId={collection.entityId} />
                ))
              ) : (
                <div className={classes.empty}>
                  <NoMediaItems
                    header={t('general.no_collection_items.header')}
                    message={t('general.no_collection_items.message')}
                  />
                </div>
              )}
            </List>
          </ShadowScroller>
        </div>
        <div className={classes.bottomSection}>
          <Button
            endIcon={<AddOutlined />}
            onClick={handleNew}
            disabled={!hasAnyPermission(PermissionsLevel.UPSERT) || !canSave}
            size="medium"
            data-testid={testIds.newButton}
          >
            {t('collections.new_collection')}
          </Button>
          <Pagination
            limitOptions={[20, 50]}
            queryMetaData={meta}
            onPageChange={(newPage: number) => {
              reset();
              setValue('page', newPage);
              onSubmit();
            }}
            onResultsChange={(newLimit: number) => {
              reset();
              setValue('page', 1);
              setValue('limit', newLimit);
              onSubmit();
            }}
          />
        </div>
      </FormProvider>
    </form>
  );
}
