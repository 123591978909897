/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiPaginatedResponseOfTeamResponse } from '../model';
// @ts-ignore
import { ApiResponseOfIsDeletableResponse } from '../model';
// @ts-ignore
import { ApiResponseOfTeamResponse } from '../model';
// @ts-ignore
import { TeamBody } from '../model';
/**
 * TeamsApi - axios parameter creator
 * @export
 */
export const TeamsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TeamBody} teamBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (teamBody: TeamBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamBody' is not null or undefined
            assertParamExists('create', 'teamBody', teamBody)
            const localVarPath = `/api/v1/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [tournamentId] 
         * @param {string} [search] 
         * @param {string} [sport] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated: async (limit?: number, page?: number, tournamentId?: string, search?: string, sport?: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (tournamentId !== undefined) {
                localVarQueryParameter['tournamentId'] = tournamentId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sport !== undefined) {
                localVarQueryParameter['sport'] = sport;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isDeletable: async (teamId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('isDeletable', 'teamId', teamId)
            const localVarPath = `/api/v1/teams/{teamId}/is-deletable`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (teamId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('remove', 'teamId', teamId)
            const localVarPath = `/api/v1/teams/{teamId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teamId 
         * @param {TeamBody} teamBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (teamId: string, teamBody: TeamBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('update', 'teamId', teamId)
            // verify required parameter 'teamBody' is not null or undefined
            assertParamExists('update', 'teamBody', teamBody)
            const localVarPath = `/api/v1/teams/{teamId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamsApi - functional programming interface
 * @export
 */
export const TeamsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TeamBody} teamBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(teamBody: TeamBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfTeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(teamBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [tournamentId] 
         * @param {string} [search] 
         * @param {string} [sport] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginated(limit?: number, page?: number, tournamentId?: string, search?: string, sport?: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfTeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginated(limit, page, tournamentId, search, sport, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isDeletable(teamId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfIsDeletableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isDeletable(teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(teamId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teamId 
         * @param {TeamBody} teamBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(teamId: string, teamBody: TeamBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfTeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(teamId, teamBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamsApi - factory interface
 * @export
 */
export const TeamsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamsApiFp(configuration)
    return {
        /**
         * 
         * @param {TeamBody} teamBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(teamBody: TeamBody, options?: any): AxiosPromise<ApiResponseOfTeamResponse> {
            return localVarFp.create(teamBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [tournamentId] 
         * @param {string} [search] 
         * @param {string} [sport] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated(limit?: number, page?: number, tournamentId?: string, search?: string, sport?: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfTeamResponse> {
            return localVarFp.getPaginated(limit, page, tournamentId, search, sport, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isDeletable(teamId: string, options?: any): AxiosPromise<ApiResponseOfIsDeletableResponse> {
            return localVarFp.isDeletable(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(teamId: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teamId 
         * @param {TeamBody} teamBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(teamId: string, teamBody: TeamBody, options?: any): AxiosPromise<ApiResponseOfTeamResponse> {
            return localVarFp.update(teamId, teamBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamsApi - interface
 * @export
 * @interface TeamsApi
 */
export interface TeamsApiInterface {
    /**
     * 
     * @param {TeamBody} teamBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    create(teamBody: TeamBody, options?: any): AxiosPromise<ApiResponseOfTeamResponse>;

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [tournamentId] 
     * @param {string} [search] 
     * @param {string} [sport] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    getPaginated(limit?: number, page?: number, tournamentId?: string, search?: string, sport?: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfTeamResponse>;

    /**
     * 
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    isDeletable(teamId: string, options?: any): AxiosPromise<ApiResponseOfIsDeletableResponse>;

    /**
     * 
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    remove(teamId: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {string} teamId 
     * @param {TeamBody} teamBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    update(teamId: string, teamBody: TeamBody, options?: any): AxiosPromise<ApiResponseOfTeamResponse>;

}

/**
 * TeamsApi - object-oriented interface
 * @export
 * @class TeamsApi
 * @extends {BaseAPI}
 */
export class TeamsApi extends BaseAPI implements TeamsApiInterface {
    /**
     * 
     * @param {TeamBody} teamBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    public create(teamBody: TeamBody, options?: any) {
        return TeamsApiFp(this.configuration).create(teamBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [tournamentId] 
     * @param {string} [search] 
     * @param {string} [sport] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    public getPaginated(limit?: number, page?: number, tournamentId?: string, search?: string, sport?: string, includeDeleted?: boolean, options?: any) {
        return TeamsApiFp(this.configuration).getPaginated(limit, page, tournamentId, search, sport, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    public isDeletable(teamId: string, options?: any) {
        return TeamsApiFp(this.configuration).isDeletable(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    public remove(teamId: string, options?: any) {
        return TeamsApiFp(this.configuration).remove(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teamId 
     * @param {TeamBody} teamBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    public update(teamId: string, teamBody: TeamBody, options?: any) {
        return TeamsApiFp(this.configuration).update(teamId, teamBody, options).then((request) => request(this.axios, this.basePath));
    }
}
