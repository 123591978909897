import { DateTime, DateTimeUnit } from 'luxon';
import { SmartQueryOperatorSymbol, SmartQueryBody } from '../API';
import { SmartQueryBodyValue } from '../components/Collections/CollectionQueryBuilder/CollectionQueryBuilder';
import { DateTimeFormatOptions } from './types/DateTimeFormatOptions';

export enum UnitOfTime {
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTH = 'months'
}

export enum TimeOrder {
  BEFORE = 'before',
  AFTER = 'after'
}

export enum ReferenceDate {
  TODAY = 'today',
  THIS_WEEK = 'this_week',
  THIS_MONTH = 'this_month'
}

export enum DateLabel {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  TOMORROW = 'tomorrow',
  A_WEEK_AGO = 'a_week_ago',
  A_MONTH_AGO = 'a_month_ago',
  A_WEEK_FROM_NOW = 'a_week_from_now',
  A_MONTH_FROM_NOW = 'a_month_from_now',
  START_OF_LAST_WEEK = 'start_of_last_week',
  START_OF_LAST_MONTH = 'start_of_last_month',
  START_OF_THIS_WEEK = 'start_of_this_week',
  START_OF_THIS_MONTH = 'start_of_this_month',
  START_OF_NEXT_WEEK = 'start_of_next_week',
  START_OF_NEXT_MONTH = 'start_of_next_month'
}

export const localeDateOptions: DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit'
};

export const getOperatorSymbol = (operator: string): string | undefined => {
  return {
    [SmartQueryOperatorSymbol.LTE]: '≤',
    [SmartQueryOperatorSymbol.GTE]: '≥',
    [SmartQueryOperatorSymbol.LT]: '<',
    [SmartQueryOperatorSymbol.GT]: '>',
    [SmartQueryOperatorSymbol.EQ]: '=',
    [SmartQueryOperatorSymbol.NEQ]: '≠',
    [SmartQueryOperatorSymbol.CONTAINS]: '⊂',
    [SmartQueryOperatorSymbol.NOT_CONTAINS]: '⊄',
    [SmartQueryOperatorSymbol.ANY_OF]: '∈',
    [SmartQueryOperatorSymbol.AND]: 'and',
    [SmartQueryOperatorSymbol.OR]: 'or'
  }[operator];
};

export const dateOperatorLabel = (operator: string): string | undefined => {
  return {
    [SmartQueryOperatorSymbol.LTE]: 'query_builder.operators.before_or_equal',
    [SmartQueryOperatorSymbol.GTE]: 'query_builder.operators.after_or_equal',
    [SmartQueryOperatorSymbol.LT]: 'query_builder.operators.before',
    [SmartQueryOperatorSymbol.GT]: 'query_builder.operators.after'
  }[operator];
};

export const addKeysToQueryObject = (queryObj: SmartQueryBody): SmartQueryBody => {
  // Assumes the first condition is an AND, and all following conditions should be nested in that AND
  queryObj.conditions.forEach((condition, i) => {
    if (i > 0) {
      condition.key = `0.${i - 1}`;
    }
  });
  return queryObj;
};

export const getSmartQueryDateTimeTokens = (value: string): Array<string> | undefined => {
  const match = /^(\d+)\s*(days|weeks|months)\s*(before|after)\s*(today|this_week|this_month)$/.exec(
    value.trim().toLowerCase()
  );

  if (!match) return undefined;
  return match.slice(1);
};

export const parseSmartQueryDateTimeField = (value: SmartQueryBodyValue): string | undefined => {
  if (Array.isArray(value) || typeof value === 'number') return undefined;
  const tokens = getSmartQueryDateTimeTokens(value);
  if (!tokens) return undefined;

  const [period, unitOfTime, order, referenceDate] = tokens;
  const multiplier = order === 'before' ? -1 : 1;
  const referenceDates = {
    [ReferenceDate.TODAY]: 'day',
    [ReferenceDate.THIS_WEEK]: 'week',
    [ReferenceDate.THIS_MONTH]: 'month'
  } as Record<string, DateTimeUnit>;

  return (
    DateTime.now()
      .startOf(referenceDates[referenceDate as ReferenceDate])
      .plus({ [unitOfTime]: parseInt(period) * multiplier })
      .toISO() || ''
  );
};

export const valueIsDynamicDate = (value: SmartQueryBodyValue | undefined): boolean => {
  if (!value || Array.isArray(value) || typeof value === 'number') return false;
  return !!parseSmartQueryDateTimeField(value);
};

export const dynamicDateLegibleName = (value: string): DateLabel | undefined => {
  const tokens = getSmartQueryDateTimeTokens(value);
  if (!tokens) return undefined;

  const [period, , , referenceDate] = tokens;
  if (period === '0') return referenceDate as DateLabel;
  const legibleNames = {
    '1 months before today': DateLabel.A_MONTH_AGO,
    '1 months after today': DateLabel.A_MONTH_FROM_NOW,
    '1 weeks before today': DateLabel.A_WEEK_AGO,
    '1 weeks after today': DateLabel.A_WEEK_FROM_NOW,
    '1 months before this_month': DateLabel.START_OF_LAST_MONTH,
    '1 weeks before this_week': DateLabel.START_OF_LAST_WEEK,
    '1 months after this_month': DateLabel.START_OF_NEXT_MONTH,
    '1 weeks after this_week': DateLabel.START_OF_NEXT_WEEK,
    '0 months before this_month': DateLabel.START_OF_THIS_MONTH,
    '0 weeks before this_week': DateLabel.START_OF_THIS_WEEK,
    '0 days before today': DateLabel.TODAY,
    '1 days before today': DateLabel.YESTERDAY,
    '1 days after today': DateLabel.TOMORROW
  } as Record<string, DateLabel>;
  if (legibleNames[value]) return legibleNames[value];
};
