import React from 'react';
import { InputLabel, Select, MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useLocales, useStatsig } from '../../../../hooks';
import { SetterOrUpdater } from 'recoil';
import FormControl from '../../../shared/FormControl';
import { StatsigGates } from '../../../../utils/consts/statsigGates';
import { without } from 'lodash-es';
import { HeroModuleItemType } from '../../../../API';

export interface IContentTypeSelectorProps<T> {
  options: T;
  value: keyof T | undefined;
  onChange: SetterOrUpdater<keyof T>;
  callback: (value: HeroModuleItemType) => void;
}

const useStyles = makeStyles()(() => ({
  selector: {
    width: 260
  }
}));

export const testIds = {
  contentTypeSelector: 'content-type-selector',
  contentTypeSelectorItem: 'content-type-selector.menu-item'
};

export function ContentTypeSelector<T extends Record<string, string>>({
  options,
  value,
  onChange,
  callback
}: IContentTypeSelectorProps<T>): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { CheckGate } = useStatsig();

  const areSportEventsInHero = CheckGate(StatsigGates.sportEventsInHero);
  const isPagePathInHero = CheckGate(StatsigGates.pagePathInHero);

  let contentTypeOptions = Object.values(options) as HeroModuleItemType[];

  if (!areSportEventsInHero) {
    contentTypeOptions = without(contentTypeOptions, HeroModuleItemType.EVENTS);
  }
  if (!isPagePathInHero) {
    contentTypeOptions = without(contentTypeOptions, HeroModuleItemType.PAGE);
  }

  return (
    <FormControl>
      <InputLabel id="contentTypeSelector">{t('layouts.content_type')}</InputLabel>
      <Select
        label={t('layouts.treatment')}
        className={classes.selector}
        labelId="contentTypeSelector"
        value={value}
        onChange={({ target: { value } }) => {
          onChange(value);
          callback(value as HeroModuleItemType);
        }}
        data-testid={testIds.contentTypeSelector}
      >
        {contentTypeOptions.map((value) => (
          <MenuItem key={value} value={value} data-testid={testIds.contentTypeSelectorItem} data-treatment-id={value}>
            {t(`general.${value.toLowerCase()}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
