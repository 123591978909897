import React, { useEffect, useMemo } from 'react';
import { Allotment } from 'allotment';
import { makeStyles } from 'tss-react/mui';
import { Slide } from '@mui/material';
import HPCManager from './HPCManager/HPCManager';
import { HPCBrowser } from './HPCBrowser';
import { PresetSearch } from './PresetSearch';
import { useData } from '../../data-layer';
import { useRecoilValue } from 'recoil';
import { HPCForm } from './HPCForm';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from '../../Routes';
import { useSplitPane } from '../../hooks/General/useSplitPane';

export const HPC_FILTER_HEIGHT = 31;

export const testIds = {
  root: 'hpc.root'
};

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%'
  },
  leftSideContainer: {
    display: 'flex',
    position: 'absolute',
    inset: 0
  }
}));

export function HPC(): React.ReactElement {
  const {
    heroPresetCollections: {
      state: { withSelectedId, withSplitPane },
      hook: { getById, setSelected }
    }
  } = useData();

  const navigate = useNavigate();
  const selectedId = useRecoilValue(withSelectedId);
  const { sizes, onChange: onSplitPaneChange } = useSplitPane(withSplitPane);
  const { classes } = useStyles();
  const { id: collectionId } = useParams<{ id: string | undefined }>();
  const toggle = useMemo(() => !!collectionId, [collectionId]);

  useEffect(() => {
    setSelected(collectionId);
  }, [collectionId]);

  useEffect(() => {
    if (!collectionId) {
      setSelected(undefined);
      return;
    }
    fetchAndSetCollection(collectionId);
  }, [collectionId]);

  const fetchAndSetCollection = async (id: string) => {
    try {
      const foundCollection = await getById(id);
      if (foundCollection) {
        setSelected(foundCollection.entityId);
        return;
      }
      navigate(AppRoutes.heroPresetCollections(), { replace: true });
    } catch (err) {
      navigate(AppRoutes[404], { replace: true });
    }
  };

  return (
    <div className={classes.root} data-testid={testIds.root}>
      <Allotment minSize={550} defaultSizes={sizes} onChange={onSplitPaneChange}>
        <Allotment.Pane>
          <Slide in={!toggle} direction="left" appear={false} mountOnEnter unmountOnExit>
            <div className={classes.leftSideContainer}>
              <HPCBrowser />
            </div>
          </Slide>
          <Slide in={toggle} direction="right" mountOnEnter unmountOnExit>
            <div className={classes.leftSideContainer}>
              <HPCManager collectionId={selectedId} />
            </div>
          </Slide>
        </Allotment.Pane>
        <Allotment.Pane>
          <PresetSearch />
        </Allotment.Pane>
      </Allotment>
      <HPCForm />
    </div>
  );
}
