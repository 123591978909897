import React from 'react';
import { Box, CircularProgress, Skeleton, SxProps, Theme } from '@mui/material';
import { BrokenImage } from '@mui/icons-material';
import { useImage } from '../../../hooks';

function DefaultFallback({ className }: { className?: string }): JSX.Element {
  return (
    <Box className={className} sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
      <BrokenImage />
    </Box>
  );
}

type ImageProps = {
  src: string;
  className?: string;
  fallback?: React.ReactElement;
  backgroundSize?: string;
  sx?: SxProps<Theme>;
  useLoadingSpinner?: boolean;
  spinnerSize?: number;
  draggable?: boolean;
  onLoad?: (event: Event) => unknown;
  onError?: (event: Event) => unknown;
};

const ImageSkeleton = ({ className, sx }: { className?: string; sx?: SxProps<Theme> }): React.ReactElement => (
  <Skeleton className={className} sx={sx} animation="wave" />
);

const Spinner = ({ size }: { size?: number }): React.ReactElement => (
  <CircularProgress size={size || 32} color="inherit" />
);

function Image({
  src: _src,
  useLoadingSpinner,
  spinnerSize,
  className,
  fallback = <DefaultFallback />,
  draggable,
  sx,
  onLoad,
  onError
}: ImageProps): JSX.Element {
  const { src, error, isLoading } = useImage({ src: _src, onLoad, onError });

  const LoadingComponent = useLoadingSpinner ? Spinner : ImageSkeleton;

  return (
    <>
      {isLoading && !error && <LoadingComponent className={className} sx={sx} size={spinnerSize} />}
      {error && React.cloneElement(fallback, { className: `${className} ${fallback.props.className}` })}
      {!isLoading && !error && src && (
        <Box component="img" className={className} sx={sx} src={src} draggable={draggable} />
      )}
    </>
  );
}

export default React.memo(Image);
