/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiPaginatedResponseOfVllCategoryResponse } from '../model';
// @ts-ignore
import { ApiResponseOfVllCategoryResponse } from '../model';
// @ts-ignore
import { ApiResponseOfVllCategoryResponseArray } from '../model';
// @ts-ignore
import { SortDirection } from '../model';
// @ts-ignore
import { VersionedDocumentStatus } from '../model';
// @ts-ignore
import { VllCategoryBody } from '../model';
/**
 * CategoriesApi - axios parameter creator
 * @export
 */
export const CategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} categoryIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestPublishedRevisionsByIds: async (categoryIds: Array<string>, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryIds' is not null or undefined
            assertParamExists('getLatestPublishedRevisionsByIds', 'categoryIds', categoryIds)
            const localVarPath = `/api/v1/vll/categories/revisions/latest/published`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (categoryIds) {
                localVarQueryParameter['categoryIds'] = categoryIds;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} entityId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestRevision: async (entityId: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getLatestRevision', 'entityId', entityId)
            const localVarPath = `/api/v1/vll/categories/{entityId}/revisions/latest`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} categoryIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestRevisionsByIds: async (categoryIds: Array<string>, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryIds' is not null or undefined
            assertParamExists('getLatestRevisionsByIds', 'categoryIds', categoryIds)
            const localVarPath = `/api/v1/vll/categories/revisions/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (categoryIds) {
                localVarQueryParameter['categoryIds'] = categoryIds;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated: async (limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/vll/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (authorId !== undefined) {
                localVarQueryParameter['authorId'] = authorId;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} entityId 
         * @param {number} revisionId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevision: async (entityId: string, revisionId: number, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getRevision', 'entityId', entityId)
            // verify required parameter 'revisionId' is not null or undefined
            assertParamExists('getRevision', 'revisionId', revisionId)
            const localVarPath = `/api/v1/vll/categories/{entityId}/revisions/{revisionId}`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"revisionId"}}`, encodeURIComponent(String(revisionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} entityId 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevisions: async (entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getRevisions', 'entityId', entityId)
            const localVarPath = `/api/v1/vll/categories/{entityId}/revisions`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (authorId !== undefined) {
                localVarQueryParameter['authorId'] = authorId;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publish: async (entityId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('publish', 'entityId', entityId)
            const localVarPath = `/api/v1/vll/categories/{entityId}/publish`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} entityId 
         * @param {VllCategoryBody} vllCategoryBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (entityId: string, vllCategoryBody: VllCategoryBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('update', 'entityId', entityId)
            // verify required parameter 'vllCategoryBody' is not null or undefined
            assertParamExists('update', 'vllCategoryBody', vllCategoryBody)
            const localVarPath = `/api/v1/vll/categories/{entityId}/revisions/latest`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vllCategoryBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<string>} categoryIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestPublishedRevisionsByIds(categoryIds: Array<string>, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfVllCategoryResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestPublishedRevisionsByIds(categoryIds, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} entityId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestRevision(entityId: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfVllCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestRevision(entityId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} categoryIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestRevisionsByIds(categoryIds: Array<string>, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfVllCategoryResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestRevisionsByIds(categoryIds, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfVllCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginated(limit, page, sortBy, sortDirection, status, authorId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} entityId 
         * @param {number} revisionId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRevision(entityId: string, revisionId: number, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfVllCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRevision(entityId, revisionId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} entityId 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRevisions(entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfVllCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRevisions(entityId, limit, page, sortBy, sortDirection, status, authorId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publish(entityId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfVllCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publish(entityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} entityId 
         * @param {VllCategoryBody} vllCategoryBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(entityId: string, vllCategoryBody: VllCategoryBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfVllCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(entityId, vllCategoryBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoriesApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<string>} categoryIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestPublishedRevisionsByIds(categoryIds: Array<string>, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfVllCategoryResponseArray> {
            return localVarFp.getLatestPublishedRevisionsByIds(categoryIds, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} entityId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestRevision(entityId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfVllCategoryResponse> {
            return localVarFp.getLatestRevision(entityId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} categoryIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestRevisionsByIds(categoryIds: Array<string>, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfVllCategoryResponseArray> {
            return localVarFp.getLatestRevisionsByIds(categoryIds, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfVllCategoryResponse> {
            return localVarFp.getPaginated(limit, page, sortBy, sortDirection, status, authorId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} entityId 
         * @param {number} revisionId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevision(entityId: string, revisionId: number, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfVllCategoryResponse> {
            return localVarFp.getRevision(entityId, revisionId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} entityId 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevisions(entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfVllCategoryResponse> {
            return localVarFp.getRevisions(entityId, limit, page, sortBy, sortDirection, status, authorId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publish(entityId: string, options?: any): AxiosPromise<ApiResponseOfVllCategoryResponse> {
            return localVarFp.publish(entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} entityId 
         * @param {VllCategoryBody} vllCategoryBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(entityId: string, vllCategoryBody: VllCategoryBody, options?: any): AxiosPromise<ApiResponseOfVllCategoryResponse> {
            return localVarFp.update(entityId, vllCategoryBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoriesApi - interface
 * @export
 * @interface CategoriesApi
 */
export interface CategoriesApiInterface {
    /**
     * 
     * @param {Array<string>} categoryIds 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    getLatestPublishedRevisionsByIds(categoryIds: Array<string>, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfVllCategoryResponseArray>;

    /**
     * 
     * @param {string} entityId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    getLatestRevision(entityId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfVllCategoryResponse>;

    /**
     * 
     * @param {Array<string>} categoryIds 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    getLatestRevisionsByIds(categoryIds: Array<string>, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfVllCategoryResponseArray>;

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {VersionedDocumentStatus} [status] 
     * @param {string} [authorId] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfVllCategoryResponse>;

    /**
     * 
     * @param {string} entityId 
     * @param {number} revisionId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    getRevision(entityId: string, revisionId: number, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfVllCategoryResponse>;

    /**
     * 
     * @param {string} entityId 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {VersionedDocumentStatus} [status] 
     * @param {string} [authorId] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    getRevisions(entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfVllCategoryResponse>;

    /**
     * 
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    publish(entityId: string, options?: any): AxiosPromise<ApiResponseOfVllCategoryResponse>;

    /**
     * 
     * @param {string} entityId 
     * @param {VllCategoryBody} vllCategoryBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    update(entityId: string, vllCategoryBody: VllCategoryBody, options?: any): AxiosPromise<ApiResponseOfVllCategoryResponse>;

}

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI implements CategoriesApiInterface {
    /**
     * 
     * @param {Array<string>} categoryIds 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public getLatestPublishedRevisionsByIds(categoryIds: Array<string>, includeDeleted?: boolean, options?: any) {
        return CategoriesApiFp(this.configuration).getLatestPublishedRevisionsByIds(categoryIds, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} entityId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public getLatestRevision(entityId: string, includeDeleted?: boolean, options?: any) {
        return CategoriesApiFp(this.configuration).getLatestRevision(entityId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} categoryIds 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public getLatestRevisionsByIds(categoryIds: Array<string>, includeDeleted?: boolean, options?: any) {
        return CategoriesApiFp(this.configuration).getLatestRevisionsByIds(categoryIds, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {VersionedDocumentStatus} [status] 
     * @param {string} [authorId] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options?: any) {
        return CategoriesApiFp(this.configuration).getPaginated(limit, page, sortBy, sortDirection, status, authorId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} entityId 
     * @param {number} revisionId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public getRevision(entityId: string, revisionId: number, includeDeleted?: boolean, options?: any) {
        return CategoriesApiFp(this.configuration).getRevision(entityId, revisionId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} entityId 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {VersionedDocumentStatus} [status] 
     * @param {string} [authorId] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public getRevisions(entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options?: any) {
        return CategoriesApiFp(this.configuration).getRevisions(entityId, limit, page, sortBy, sortDirection, status, authorId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public publish(entityId: string, options?: any) {
        return CategoriesApiFp(this.configuration).publish(entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} entityId 
     * @param {VllCategoryBody} vllCategoryBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public update(entityId: string, vllCategoryBody: VllCategoryBody, options?: any) {
        return CategoriesApiFp(this.configuration).update(entityId, vllCategoryBody, options).then((request) => request(this.axios, this.basePath));
    }
}
