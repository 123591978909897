import React from 'react';
import { SportsHeroUiModuleBody } from '../../../../../API';
import Localized from '../../../../shared/Localized';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { SportsHeroPreview } from '../../ContentPreview/SportsHeroPreview';

export type SportsHeroListItemProps = IBaseUIModuleListItemProps<SportsHeroUiModuleBody>;

export const testIds = {
  preview: 'sports-hero-list-item.preview'
};

const SportsHeroListItem = ({ uiModule, ...props }: SportsHeroListItemProps): JSX.Element => {
  return (
    <UIModuleListItem
      uiModule={uiModule}
      topLineTitle={<Localized prop={uiModule.title} />}
      renderPreview={(showPreview) => (
        <div data-testid={testIds.preview}>
          <SportsHeroPreview matchHeroContents={uiModule.matchHeroContents} showing={showPreview} />
        </div>
      )}
      {...props}
    />
  );
};

export default SportsHeroListItem;
