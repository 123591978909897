/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { EntitlementType } from './entitlement-type';
import { LocalizedFieldBody } from './localized-field-body';
import { SharedConfirmationBody } from './shared-confirmation-body';
import { SharedModalBody } from './shared-modal-body';
import { SpinPremiaUiModuleBackgroundBody } from './spin-premia-ui-module-background-body';
import { TargetPlatform } from './target-platform';
import { UiModuleExperimentGroup } from './ui-module-experiment-group';

/**
 * 
 * @export
 * @interface SpinPremiaUiModuleBody
 */
export interface SpinPremiaUiModuleBody {
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    moduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    moduleType: SpinPremiaUiModuleBodyModuleTypeEnum;
    /**
     * 
     * @type {Array<TargetPlatform>}
     * @memberof SpinPremiaUiModuleBody
     */
    targetPlatform?: Array<TargetPlatform>;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof SpinPremiaUiModuleBody
     */
    caption?: LocalizedFieldBody;
    /**
     * 
     * @type {Array<EntitlementType>}
     * @memberof SpinPremiaUiModuleBody
     */
    entitlements?: Array<EntitlementType>;
    /**
     * 
     * @type {Array<UiModuleExperimentGroup>}
     * @memberof SpinPremiaUiModuleBody
     */
    experiments?: Array<UiModuleExperimentGroup> | null;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    vixLogo: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    spinLogo: string;
    /**
     * 
     * @type {SpinPremiaUiModuleBackgroundBody}
     * @memberof SpinPremiaUiModuleBody
     */
    background?: SpinPremiaUiModuleBackgroundBody;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    planPickerHeader: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    planPickerSubHeader?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SpinPremiaUiModuleBody
     */
    planTypeToggle?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    planTypeOpt1?: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    planTypeOpt2?: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    planName?: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    planCostPoint?: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    planDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    planValueProp?: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    planValuePropSeeMoreCta?: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    planValuePropSeeLessCta?: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    planCta?: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    legalCopy: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    otp1Header: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    otp1SubHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    opt1CountryCode: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    opt1MobileHint: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    opt1Cta: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    otp2Header: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    otp2SubHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    opt2EditNumber: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    opt2ExpirationNotice: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    opt2ResendCode: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    summaryHeader: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    summarySubHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    summaryPlanName: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    summaryPlanDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    summaryPlanSku: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    summaryPlanPoints: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    summaryPlanTotal: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    summaryReminderHeader: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    summaryStartDate: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    summaryEndDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof SpinPremiaUiModuleBody
     */
    checkbox?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    summaryLegalCopy: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    summaryCta1: string;
    /**
     * 
     * @type {string}
     * @memberof SpinPremiaUiModuleBody
     */
    summaryCta2: string;
    /**
     * 
     * @type {SharedModalBody}
     * @memberof SpinPremiaUiModuleBody
     */
    usedAccountError: SharedModalBody;
    /**
     * 
     * @type {SharedModalBody}
     * @memberof SpinPremiaUiModuleBody
     */
    unregisteredNumberError: SharedModalBody;
    /**
     * 
     * @type {SharedModalBody}
     * @memberof SpinPremiaUiModuleBody
     */
    fundsError: SharedModalBody;
    /**
     * 
     * @type {SharedModalBody}
     * @memberof SpinPremiaUiModuleBody
     */
    failedError: SharedModalBody;
    /**
     * 
     * @type {SharedModalBody}
     * @memberof SpinPremiaUiModuleBody
     */
    unlinkAccount: SharedModalBody;
    /**
     * 
     * @type {SharedConfirmationBody}
     * @memberof SpinPremiaUiModuleBody
     */
    linkedAccountConfirmation: SharedConfirmationBody;
    /**
     * 
     * @type {SharedConfirmationBody}
     * @memberof SpinPremiaUiModuleBody
     */
    unlinkedAccountConfirmation: SharedConfirmationBody;
}

/**
    * @export
    * @enum {string}
    */
export enum SpinPremiaUiModuleBodyModuleTypeEnum {
    SPIN_PREMIA = 'SPIN_PREMIA'
}



