import React, { useEffect, useMemo, useRef } from 'react';
import { emphasize, ListItem, ListItemText, Stack, typographyClasses } from '@mui/material';
import { HeroModuleItemType } from '../../../API';
import { makeStyles } from 'tss-react/mui';
import { useData } from '../../../data-layer';
import { useRecoilState, useRecoilValue } from 'recoil';
import { HeroThumbnail } from '../../Layouts/UIModuleListItem/ContentPreview/Thumbnails/HeroThumbnail';
import { DisplayAsOptions, ThumbnailOrientation } from '../../../utils/types/genericTypes';
import { IdBadge } from '../../shared/IdBadge';
import { getPresetAspectRatio } from '../../../utils/styleUtils';
import { DragHandle } from '@mui/icons-material';
import { MediaButtons, MediaButtonsProps } from '../../Collections/CollectionsPanel/Media/MediaButtons';
import { scrollIntoViewIfNeeded } from '../../../utils/scrollIntoViewIfNeeded';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import { ViewContentButton } from '../../shared/ViewContentButon';
import {
  AllHeroPresetTypes,
  getContentIdFromHeroPreset,
  getContentTypeFromHeroPreset
} from '../../../utils/uiModuleUtils';

export const testIds = {
  root: 'preset-list-item.root'
};

export const PRESET_LIST_ITEM_THUMBNAIL_HEIGHT = 100;
const FOUND_CLASS = 'found';

const useStyles = makeStyles<{ displayAs: DisplayAsOptions; pointer: boolean }, 'name' | 'list'>()(
  (theme, { displayAs, pointer }, classes) => ({
    root: {
      background: theme.palette.background.default,
      cursor: pointer ? 'pointer' : 'unset',
      [`&:last-child > .${classes.list}`]: {
        borderBottom: 'none'
      }
    },
    image: {
      background: emphasize(theme.palette.background.paper, 0.1),
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden'
    },
    overlay: {
      position: 'absolute',
      inset: 0,
      display: 'flex',
      alignItems: 'end',
      [`&:hover > .${classes.name}`]: {
        display: 'unset'
      }
    },
    name: {
      display: 'none',
      fontSize: '0.9rem',
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      maxHeight: '100%',
      whiteSpace: 'break-spaces',
      wordBreak: 'break-all',
      background: 'rgb(0 0 0 / 70%)',
      backdropFilter: 'blur(2px)',
      color: 'white',
      width: '100%'
    },
    list: {
      display: 'flex',
      padding: theme.spacing(2),
      gap: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    ratioed: {
      aspectRatio: getPresetAspectRatio(displayAs)
    },
    grabHandle: {
      cursor: 'grab'
    },
    listDetails: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      flexGrow: 1,
      overflow: 'hidden'
    },
    presetName: {
      wordBreak: 'break-all',
      [`& .${typographyClasses.root}`]: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }
    },
    landscapeThumbnailContainer: {
      height: PRESET_LIST_ITEM_THUMBNAIL_HEIGHT,
      aspectRatio: getPresetAspectRatio(DisplayAsOptions.LANDSCAPE)
    },
    portraitThumbnailContainer: {
      height: PRESET_LIST_ITEM_THUMBNAIL_HEIGHT,
      aspectRatio: getPresetAspectRatio(DisplayAsOptions.PORTRAIT)
    },
    disabled: {
      opacity: 0.5,
      filter: 'grayscale(1)'
    },
    mediaButtons: {
      position: 'absolute',
      top: 0,
      left: 0,
      flexDirection: 'column-reverse',
      opacity: 1
    },
    styleFound: {
      position: 'absolute',
      inset: 0,
      transition: 'box-shadow 0.5s ease',
      pointerEvents: 'none',
      [`&.${FOUND_CLASS}`]: {
        boxShadow: `inset 0 0 0 5px ${theme.palette.primary.main}`
      }
    }
  })
);

type PresetListItemProps = {
  presetId: string;
  isEditing?: boolean;
  canDelete?: boolean;
  canEdit?: boolean;
  displayAs?: DisplayAsOptions;
  previewMode?: boolean;
  isGridPreset?: boolean;
};

export function PresetListItem({
  presetId,
  isEditing,
  canDelete,
  canEdit = true,
  displayAs: displayAsOverride,
  previewMode,
  isGridPreset
}: PresetListItemProps): React.ReactElement {
  const {
    heroPresetCollections: {
      state: { withDisplayAs, withSelected, withSelectedPresets, withScrollTo }
    },
    presets: {
      state: { withRecordById },
      hook: { queueIdToFetch, edit }
    }
  } = useData();
  const ref = useRef<HTMLDivElement>(null);
  const { hasPermissions } = usePermissions();
  const [scrollTo, setScrollTo] = useRecoilState(withScrollTo);
  const preset = useRecoilValue(withRecordById(presetId));
  const displayAsRecoil = useRecoilValue(withDisplayAs);
  const { classes, cx } = useStyles({ displayAs: displayAsOverride ?? displayAsRecoil, pointer: !!isEditing });
  const selectedHPC = useRecoilValue(withSelected);
  const [selectedPresets, setSelectedPresets] = useRecoilState(withSelectedPresets);
  const isUsed = useMemo(() => selectedPresets?.includes(presetId), [selectedHPC, selectedPresets]);
  const isDisabled = useMemo(() => !isEditing && isUsed && !previewMode, [isEditing, isUsed, previewMode]);

  const { UPSERT: canUpsert } = hasPermissions(selectedHPC?.ownerPermissionsGroup);

  const displayAs = useMemo(() => displayAsOverride ?? displayAsRecoil, [displayAsOverride, displayAsRecoil]);

  useEffect(() => {
    if (!preset) {
      queueIdToFetch(presetId);
    }
  }, [presetId]);

  useEffect(() => {
    if (scrollTo === presetId && ref.current && isEditing) {
      if (ref.current) {
        scrollIntoViewIfNeeded(ref.current);
        ref.current.classList.add(FOUND_CLASS);
      }
      setScrollTo(undefined);
      setTimeout(() => ref.current?.classList.remove(FOUND_CLASS), 3000);
    }
  }, [scrollTo]);

  if (!preset) {
    return <></>;
  }

  const onSelect = () => {
    if (canEdit) {
      edit(presetId);
    }
  };

  const onAdd = () => {
    if (selectedPresets && !isUsed) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setSelectedPresets((prev) => [...prev!, presetId]);
    }
  };

  const onDelete = () => {
    if (selectedPresets && isUsed) {
      setSelectedPresets((prev) => prev?.filter((id) => id !== presetId));
    }
  };

  const onSearch = () => {
    setScrollTo(presetId);
  };

  const presetData = preset.data as AllHeroPresetTypes;
  const contentType = getContentTypeFromHeroPreset(presetData);
  const contentId = getContentIdFromHeroPreset(presetData);

  const orientation =
    displayAs === DisplayAsOptions.PORTRAIT ? ThumbnailOrientation.PORTRAIT : ThumbnailOrientation.LANDSCAPE;

  const mediaButtonsProps: MediaButtonsProps = {
    isEditing,
    onAdd: canUpsert ? onAdd : undefined,
    onDelete: canDelete ? onDelete : undefined,
    onSearch,
    collectionSelected: !!selectedPresets,
    inCollection: isUsed,
    previewMode
  };

  const found = <div className={classes.styleFound} ref={ref} />;
  const List = (): React.ReactElement => (
    <ListItem className={classes.list}>
      {isEditing && (
        <div className={cx(classes.grabHandle, { [classes.disabled]: isDisabled })}>
          <DragHandle />
        </div>
      )}
      <div className={cx(classes.listDetails, { [classes.disabled]: isDisabled })}>
        <ListItemText primary={preset.name} className={classes.presetName} />
        <Stack gap={2} direction="row" alignItems="center">
          <IdBadge id={contentId} />
          {contentType === HeroModuleItemType.VOD && (
            <ViewContentButton variant="text" color="formControlColor" contentId={contentId} />
          )}
        </Stack>
      </div>
      <div className={classes.landscapeThumbnailContainer}>
        <HeroThumbnail
          heroContentItem={presetData}
          orientation={ThumbnailOrientation.LANDSCAPE}
          disabled={isDisabled}
        />
      </div>
      <div className={classes.portraitThumbnailContainer}>
        <HeroThumbnail heroContentItem={presetData} orientation={ThumbnailOrientation.PORTRAIT} disabled={isDisabled} />
      </div>
      <MediaButtons {...mediaButtonsProps} />
      {found}
    </ListItem>
  );

  const Image = (): React.ReactElement => (
    <ListItem disablePadding className={cx(classes.image, classes.ratioed)}>
      <HeroThumbnail heroContentItem={presetData} orientation={orientation} disabled={isDisabled} />
      <div className={classes.overlay}>
        <div className={classes.name}>{preset.name}</div>
      </div>
      <MediaButtons className={classes.mediaButtons} {...mediaButtonsProps} size="small" backdrop />
      {found}
    </ListItem>
  );

  return (
    <div
      className={cx(classes.root, { sortableFiltered: isDisabled })}
      data-testid={testIds.root}
      data-presetid={`${isGridPreset ? 'grid' : 'list'}-${presetId}`}
      onClick={onSelect}
    >
      {displayAs === DisplayAsOptions.LIST ? List() : Image()}
    </div>
  );
}
