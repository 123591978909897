/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiResponseOfPaymentPlanResponse } from '../model';
// @ts-ignore
import { ApiResponseOfPaymentPlanResponseArray } from '../model';
// @ts-ignore
import { PaymentPlanBody } from '../model';
/**
 * PaymentPlansApi - axios parameter creator
 * @export
 */
export const PaymentPlansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/paymentPlans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} paymentPlanCode 
         * @param {PaymentPlanBody} paymentPlanBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (paymentPlanCode: string, paymentPlanBody: PaymentPlanBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentPlanCode' is not null or undefined
            assertParamExists('update', 'paymentPlanCode', paymentPlanCode)
            // verify required parameter 'paymentPlanBody' is not null or undefined
            assertParamExists('update', 'paymentPlanBody', paymentPlanBody)
            const localVarPath = `/api/v1/paymentPlans/{paymentPlanCode}`
                .replace(`{${"paymentPlanCode"}}`, encodeURIComponent(String(paymentPlanCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentPlanBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentPlansApi - functional programming interface
 * @export
 */
export const PaymentPlansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentPlansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfPaymentPlanResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} paymentPlanCode 
         * @param {PaymentPlanBody} paymentPlanBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(paymentPlanCode: string, paymentPlanBody: PaymentPlanBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfPaymentPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(paymentPlanCode, paymentPlanBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentPlansApi - factory interface
 * @export
 */
export const PaymentPlansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentPlansApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: any): AxiosPromise<ApiResponseOfPaymentPlanResponseArray> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} paymentPlanCode 
         * @param {PaymentPlanBody} paymentPlanBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(paymentPlanCode: string, paymentPlanBody: PaymentPlanBody, options?: any): AxiosPromise<ApiResponseOfPaymentPlanResponse> {
            return localVarFp.update(paymentPlanCode, paymentPlanBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentPlansApi - interface
 * @export
 * @interface PaymentPlansApi
 */
export interface PaymentPlansApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlansApiInterface
     */
    getAll(options?: any): AxiosPromise<ApiResponseOfPaymentPlanResponseArray>;

    /**
     * 
     * @param {string} paymentPlanCode 
     * @param {PaymentPlanBody} paymentPlanBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlansApiInterface
     */
    update(paymentPlanCode: string, paymentPlanBody: PaymentPlanBody, options?: any): AxiosPromise<ApiResponseOfPaymentPlanResponse>;

}

/**
 * PaymentPlansApi - object-oriented interface
 * @export
 * @class PaymentPlansApi
 * @extends {BaseAPI}
 */
export class PaymentPlansApi extends BaseAPI implements PaymentPlansApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlansApi
     */
    public getAll(options?: any) {
        return PaymentPlansApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} paymentPlanCode 
     * @param {PaymentPlanBody} paymentPlanBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlansApi
     */
    public update(paymentPlanCode: string, paymentPlanBody: PaymentPlanBody, options?: any) {
        return PaymentPlansApiFp(this.configuration).update(paymentPlanCode, paymentPlanBody, options).then((request) => request(this.axios, this.basePath));
    }
}
