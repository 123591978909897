/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum ErrorType {
    API_ERROR = 'ApiError',
    BAD_REQUEST_ERROR = 'BadRequestError',
    UNAUTHORIZED_ERROR = 'UnauthorizedError',
    INSUFFICIENT_PERMISSIONS_ERROR = 'InsufficientPermissionsError',
    INSUFFICIENT_COUNTRY_ACCESS_ERROR = 'InsufficientCountryAccessError',
    FORBIDDEN_ERROR = 'ForbiddenError',
    NOT_FOUND_ERROR = 'NotFoundError',
    REQUEST_TIMEOUT_ERROR = 'RequestTimeoutError',
    CONFLICT_ERROR = 'ConflictError',
    UNPROCESSABLE_ENTITY_ERROR = 'UnprocessableEntityError',
    INTERNAL_SERVER_ERROR = 'InternalServerError',
    NOT_IMPLEMENTED_ERROR = 'NotImplementedError',
    BAD_GATEWAY_ERROR = 'BadGatewayError',
    EXTERNAL_API_ERROR = 'ExternalApiError',
    CONTENT_SEARCH_API_ERROR = 'ContentSearchApiError',
    CACHE_MANAGER_ERROR = 'CacheManagerError',
    SLACK_API_ERROR = 'SlackApiError',
    PUB_SUB_ERROR = 'PubSubError',
    LOKALISE_API_ERROR = 'LokaliseApiError',
    STATSIG_API_ERROR = 'StatsigApiError',
    RECOMMENDATIONS_API_ERROR = 'RecommendationsApiError',
    PROMOTION_API_ERROR = 'PromotionApiError',
    COUPON_API_ERROR = 'CouponApiError',
    PAYMENT_PLAN_API_ERROR = 'PaymentPlanApiError',
    EXPERIENCE_API_ERROR = 'ExperienceApiError',
    OFFER_MAPPING_API_ERROR = 'OfferMappingApiError',
    CONTENT_SERVICE_API_ERROR = 'ContentServiceApiError',
    VALIDATION_ERROR = 'ValidationError',
    DELETE_CHANNEL_ERROR = 'DeleteChannelError',
    DELETE_TEAM_ERROR = 'DeleteTeamError',
    DELETE_TOURNAMENT_ERROR = 'DeleteTournamentError',
    DELETE_TOURNAMENT_WITH_TEAMS_ERROR = 'DeleteTournamentWithTeamsError',
    COLLECTION_ASSOCIATED_ERROR = 'CollectionAssociatedError',
    IMPORT_ENTITY_ERROR = 'ImportEntityError'
}



