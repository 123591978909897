import { useSetRecoilState } from 'recoil';
import { AppStoresResponse, OfferMappingApi, OfferMappingBody, OfferMappingResponse } from '../../../../API';
import {
  CacheManager,
  DataManagerHelpers,
  DataManagerHook,
  DataManagerHookReturnType,
  IDataManagerHookProps
} from '../../../DataManager';

export interface OfferMappingsHookReturnType extends DataManagerHookReturnType<OfferMappingResponse, OfferMappingBody> {
  getAppStores: () => Promise<AppStoresResponse | null>;
  getBySourceAndOfferCode: (source: string, offerCode: string) => Promise<OfferMappingResponse | null>;
}

export function OfferMappingsHook(
  params: IDataManagerHookProps<OfferMappingResponse, OfferMappingBody>
): OfferMappingsHookReturnType {
  const dataManagerHook = DataManagerHook<OfferMappingResponse, OfferMappingBody>(params);
  const api = params.useApiHook() as OfferMappingApi;
  const setCache = useSetRecoilState(params.state.withDataCache);
  const { addRecordToCache } = CacheManager(params.idField, setCache);
  const { handleApiError } = DataManagerHelpers({ name: params.name, toString });

  const getAppStores = async () => {
    try {
      const response = await api.getAppStores();
      return response.data.body;
    } catch (err) {
      handleApiError(err, 'get_app_stores');
      return null;
    }
  };

  const getBySourceAndOfferCode = async (source: string, offerCode: string) => {
    try {
      const response = await api.getBySourceAndOfferCode(source, offerCode);
      const offerMapping = response.data.body;
      addRecordToCache(offerMapping);
      return offerMapping;
    } catch (err) {
      handleApiError(err, 'get_by_source_and_offer_code');
      return null;
    }
  };

  return {
    ...dataManagerHook,
    getAppStores,
    getBySourceAndOfferCode
  };
}
