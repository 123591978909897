import { RefObject } from 'react';
import { FieldErrors, FieldValues, UseFormHandleSubmit } from 'react-hook-form';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { SubmitErrorHandler } from 'react-hook-form/dist/types/form';
export const markAsRequired = (label: string, isRequired = true): string => (isRequired ? `${label} *` : label);

export const allRequired = (fields: unknown[]): boolean =>
  fields.reduce((a, b) => !!a && !!b) === fields.reduce((a, b) => !!a || !!b);

export const isDateValid = (start: string | undefined, end: string | undefined): boolean =>
  start || end ? new Date(start as string) <= new Date(end as string) : true;

export const fieldErrorsToArray = (errors: FieldErrors): string[] => {
  return Object.keys(errors)
    .map((key) => errors[key]?.message as string)
    .filter(Boolean);
};

export type OnSubmitType<T> = (data: T, shouldPublish?: boolean) => void;

export const handleSaveDraft = <T extends FieldValues>(
  handleSubmit: UseFormHandleSubmit<T>,
  onSubmit: OnSubmitType<T>,
  onError?: SubmitErrorHandler<T>
): (() => void) => handleSubmit((data) => onSubmit(data), onError);

export const handleSavePublish = <T extends FieldValues>(
  handleSubmit: UseFormHandleSubmit<T>,
  onSubmit: OnSubmitType<T>,
  onError?: SubmitErrorHandler<T>
): (() => void) => handleSubmit((data) => onSubmit(data, true), onError);

export const isFormValid = (formRef: RefObject<ValidatorForm>): Promise<boolean> | undefined => {
  return formRef?.current?.isFormValid(false);
};
