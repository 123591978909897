import { DateTime, DateTimeUnit } from 'luxon';
import { SetterOrUpdater } from 'recoil';
import axios from 'axios';

export const toLuxonDate = (date: string | Date | undefined): DateTime => {
  if (!date) return DateTime.invalid('received undefined');
  return typeof date === 'string' ? DateTime.fromISO(date) : DateTime.fromJSDate(date);
};

export const setGlobalTimeDiff = async (setDiff: SetterOrUpdater<number>): Promise<void> => {
  try {
    const response = await axios.get('https://worldtimeapi.org/api/ip');
    const currentGlobalTime = DateTime.fromISO(response.data.utc_datetime).toMillis();
    setDiff(Date.now() - currentGlobalTime);
  } catch (err) {
    console.error(err);
  }
};

export const getSecondsFormatted = (diff: number): string => {
  let seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;
  const prefix0 = (num: number) => (num < 10 ? `0${num}` : num);
  return `${minutes}:${prefix0(seconds)}`;
};

// Use with useInterval, and no, "Infinity" doesn't work :)
export const largeNumber = 2147483647; // any arbitrary large number will do

export const getISODateStringToEndOfDay = (date: string | number | undefined): string | undefined => {
  if (!date) {
    return undefined;
  }
  return (
    DateTime.fromMillis(typeof date === 'number' ? date : new Date(date).getTime())
      .endOf('day')
      .toISO({ includeOffset: false }) || ''
  );
};

export const toStartOfDayISO = (date: string | undefined): string | undefined => {
  return date ? date.split('T')[0] + 'T00:00:00.000Z' : date;
};

export const toEndOfDayISO = (date: string | undefined): string | undefined => {
  return date ? date.split('T')[0] + 'T23:59:59.999Z' : date;
};

export const isSameDateTime = (date1?: string, date2?: string, unit: DateTimeUnit = 'minute'): boolean => {
  if (!date1 || !date2) {
    return false;
  }
  return DateTime.fromISO(date1).hasSame(DateTime.fromISO(date2), unit);
};

export const dayOfTheWeekLocalized = (dayNumber: number, locale: string): string => {
  // Pick a random Sunday and add the number of days to it
  return DateTime.fromISO('2022-09-04T12:00:00.000Z').setLocale(locale).plus({ days: dayNumber }).toFormat('cccc');
};

export const isValidDate = (value: unknown | undefined | null, textValue?: string): boolean => {
  if (!value && !textValue) return true;
  return value !== undefined && toLuxonDate(value as string | Date).isValid;
};

export const isBefore = (a: unknown | undefined | null, b: unknown | undefined): boolean => {
  if (!a || !b) return false;
  return toLuxonDate(a as string | Date) < toLuxonDate(b as string | Date);
};

export const isAfter = (a: unknown | undefined | null, b: unknown | undefined): boolean => {
  if (!a || !b) return false;
  return toLuxonDate(a as string | Date) > toLuxonDate(b as string | Date);
};

export const toMinutes = (seconds: number): number => {
  return seconds / 60;
};

export const convertDate = (stringifiedDate: string): string => {
  if (isNaN(Date.parse(stringifiedDate))) {
    const [h, m] = stringifiedDate.slice(0, -1).split(':');

    if (h && m) {
      const date = new Date().setUTCHours(parseInt(h), parseInt(m));

      return convertDate(new Date(date).toISOString());
    }

    return 'Invalid Date: ' + stringifiedDate;
  }

  return `${new Date(stringifiedDate).toLocaleTimeString('en-US', { hour12: false, timeZone: 'UTC' })}Z`;
};
