/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BackgroundBody } from './background-body';
import { EntitlementType } from './entitlement-type';
import { LocalizedFieldBody } from './localized-field-body';
import { OutboundUiModuleBodySection2ProviderLogoLogoBody } from './outbound-ui-module-body-section2-provider-logo-logo-body';
import { OutboundUiModuleBodySection7ErrorMessageBody } from './outbound-ui-module-body-section7-error-message-body';
import { TargetPlatform } from './target-platform';
import { UiModuleExperimentGroup } from './ui-module-experiment-group';

/**
 * 
 * @export
 * @interface OutboundUiModuleBody
 */
export interface OutboundUiModuleBody {
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    moduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    moduleType: OutboundUiModuleBodyModuleTypeEnum;
    /**
     * 
     * @type {Array<TargetPlatform>}
     * @memberof OutboundUiModuleBody
     */
    targetPlatform?: Array<TargetPlatform>;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof OutboundUiModuleBody
     */
    caption?: LocalizedFieldBody;
    /**
     * 
     * @type {Array<EntitlementType>}
     * @memberof OutboundUiModuleBody
     */
    entitlements?: Array<EntitlementType>;
    /**
     * 
     * @type {Array<UiModuleExperimentGroup>}
     * @memberof OutboundUiModuleBody
     */
    experiments?: Array<UiModuleExperimentGroup> | null;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof OutboundUiModuleBody
     */
    title: LocalizedFieldBody;
    /**
     * 
     * @type {BackgroundBody}
     * @memberof OutboundUiModuleBody
     */
    section1Background?: BackgroundBody;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section1Logo?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section1HeaderGeneric?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section1HeaderWithProviderName?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section1SubHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section1CtaGeneric?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section1CtaProviderName?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section1CtaSignIn?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section1CtaHome?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section2Header?: string;
    /**
     * 
     * @type {Array<OutboundUiModuleBodySection2ProviderLogoLogoBody>}
     * @memberof OutboundUiModuleBody
     */
    section2ProviderLogo?: Array<OutboundUiModuleBodySection2ProviderLogoLogoBody>;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section3Header?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section3HeaderAlternative?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section3SubHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section3SubHeaderAlternative?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section3MailHint?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section3PasswordHint?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section3LegalDisclaimer?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section3CtaCreateAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section3CtaSkip?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section3CtaSignIn?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section3ErrorEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section3ErrorPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section4Header?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section4SubHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section4Body?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section4CtaLinkAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section4CtaCreateAccount?: string;
    /**
     * 
     * @type {BackgroundBody}
     * @memberof OutboundUiModuleBody
     */
    section5Background?: BackgroundBody;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section5Header?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section5Body?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section5CtaWatchNow?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section5CtaDownloadApp?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section5CtaContinueBrowser?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section5CtaNotifications?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section6Header?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section6MainHint?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section6PasswordHint?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section6CtaSignIn?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section6CtaCreateAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section6CtaForgotPassword?: string;
    /**
     * 
     * @type {Array<OutboundUiModuleBodySection7ErrorMessageBody>}
     * @memberof OutboundUiModuleBody
     */
    section7ErrorMessages?: Array<OutboundUiModuleBodySection7ErrorMessageBody>;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section8HeaderRoku?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section8HeaderCtv?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section8Step1Roku?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section8Step1UrlRoku?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section8Step2Roku?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section8CodeLength?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section9Header?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section9Step1?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section9Step2?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section9Step3?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section9CtaActivate?: string;
    /**
     * 
     * @type {BackgroundBody}
     * @memberof OutboundUiModuleBody
     */
    section10Background?: BackgroundBody;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section10Header?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section10Body?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section10CtaContinue?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section11Header?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section11CtaWatchNow?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section11ErrorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section12Header?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section12HeaderAvod?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section12Body?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section12BodyAvod?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section12CtaContinueWeb?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundUiModuleBody
     */
    section12CtaDownloadApp?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OutboundUiModuleBodyModuleTypeEnum {
    OUTBOUND = 'OUTBOUND'
}



