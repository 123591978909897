import { useCallback, useEffect } from 'react';

export function useFind(fn: () => unknown): void {
  const keydown = useCallback((event: KeyboardEvent) => {
    if (/f$/i.test(event.key) && (event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      fn();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', keydown);
    return () => window.removeEventListener('keydown', keydown);
  }, []);
}
