import { atom, RecoilState } from 'recoil';
import { DataPresetBody, DataPresetResponse, DataPresetType } from '../../../API';
import { DataManagerState, DataManagerStateFullReturnType } from '../../DataManager';

export type DefaultPresetsType = Record<DataPresetType, DataPresetResponse[]>;

export const DEFAULT_PRESETS: DefaultPresetsType = {} as DefaultPresetsType;
// iterate over the DataPresetType values to populate the hash with empty arrays
for (const key in DataPresetType) {
  DEFAULT_PRESETS[key as DataPresetType] = [];
}
export interface PresetsStateReturnType extends DataManagerStateFullReturnType<DataPresetResponse, DataPresetBody> {
  withDefaultPresets: RecoilState<DefaultPresetsType>;
  withLastCreatedPreset: RecoilState<DataPresetResponse | undefined>;
}

export function PresetsState(name: string): PresetsStateReturnType {
  const genericState = DataManagerState<DataPresetResponse, DataPresetBody>(name);

  const withDefaultPresets = atom<DefaultPresetsType>({
    key: `${name}.default`,
    default: DEFAULT_PRESETS
  });

  const withLastCreatedPreset = atom<DataPresetResponse | undefined>({
    key: `${name}.lastCreatedPreset`,
    default: undefined
  });

  return {
    ...genericState,
    withDefaultPresets,
    withLastCreatedPreset
  };
}
