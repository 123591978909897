import React, { useEffect } from 'react';
import { useData } from '../../../data-layer';
import { useRecoilValue } from 'recoil';
import LinkWithIcon from '../../shared/LinkWithIcon';
import { Stack } from '@mui/material';
import { AppRoutes } from '../../../Routes';
import { DocumentLocale } from '../../../API';
import { isEntityDeleted } from '../../../utils/generalUtils';
import { DeletedBadge } from '../../shared/DeletedBadge';

export interface IHPCLinkProps {
  hpcId: string;
  'data-testid'?: string;
}

export function HPCLink({ hpcId, ...props }: IHPCLinkProps): React.ReactElement {
  const {
    heroPresetCollections: { hook: useHPCs, state: hpcsState }
  } = useData();

  const collection = useRecoilValue(hpcsState.withLatestPublishedRecordById(hpcId));

  useEffect(() => {
    if (!collection) {
      useHPCs.queueLatestPublishedIdToFetch(hpcId, true);
    }
  }, [hpcId]);

  const pathname = AppRoutes.heroPresetCollections(hpcId);

  return (
    <Stack direction="row" alignItems="center">
      {isEntityDeleted(collection) && <DeletedBadge />}
      <LinkWithIcon
        pathname={pathname}
        title={collection?.name || { [DocumentLocale.ES]: hpcId }}
        data-testid={props['data-testid']}
      />
    </Stack>
  );
}
