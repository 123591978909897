import { useTranslation } from 'react-i18next';
import { LocalizedFieldBody } from '../../API';
import { SupportedLanguage } from '../../locales/i18n';
import { localeFallback } from '../../utils/localeFallback';

export type UseLocalesReturnType = {
  onChangeLang: (newlang: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t: (key: any, options?: any) => string;
  currentLang: SupportedLanguage;
  allLanguages: SupportedLanguage[];
  localize: (field?: LocalizedFieldBody, fallback?: string) => string;
};

export const ALL_LANGUAGES = ['es', 'en', 'pt'] as SupportedLanguage[];

export function localeCompareFunction<T>(
  { localize }: UseLocalesReturnType,
  localizedField: keyof T
): (a: T, b: T) => number {
  return (a: T, b: T) => {
    const ax = localize(a[localizedField] as LocalizedFieldBody);
    const bx = localize(b[localizedField] as LocalizedFieldBody);
    if (ax === bx) return 0;
    if (!ax) return -1;
    if (!bx) return 1;
    return ax.localeCompare(bx);
  };
}

export function useLocales(): UseLocalesReturnType {
  const { i18n, t } = useTranslation();
  const currentLang = i18n.languages[0] as SupportedLanguage;

  // Cast the response from t() to a string, to we can use it in JSX templates
  const tWrapped = (...params: Parameters<typeof t>): string => {
    return t(...params) as string;
  };

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  const localize = (field?: LocalizedFieldBody, fallback = '') =>
    field ? localeFallback(currentLang, field) : fallback;

  return {
    onChangeLang: handleChangeLanguage,
    t: tWrapped,
    currentLang,
    allLanguages: ALL_LANGUAGES,
    localize
  };
}
