/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum TargetPlatform {
    FIRETABLET = 'firetablet',
    ANDROID = 'android',
    ANDROIDTV = 'androidtv',
    COMCAST = 'comcast',
    COMCASTTV = 'comcasttv',
    FIRETV = 'firetv',
    IOS = 'ios',
    LGTV = 'lgtv',
    ROKU = 'roku',
    SAMSUNG_GALAXY = 'samsung_galaxy',
    SAMSUNGTV = 'samsungtv',
    TIGOSTB = 'tigostb',
    TVOS = 'tvos',
    VIDAATV = 'vidaatv',
    VIZIOTV = 'viziotv',
    WEB = 'web',
    WEBTV = 'webtv'
}



