import React, { useEffect, useMemo, useRef } from 'react';
import { Skeleton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  AccountCreationUiModuleBodyModuleTypeEnum,
  AccountSuccessUiModuleBodyModuleTypeEnum,
  CollectionListUiModuleBodyModuleTypeEnum,
  CollectionSplashHeroUiModuleBodyModuleTypeEnum,
  ContentListUiModuleBodyModuleTypeEnum,
  ContinueWatchingUiModuleBodyModuleTypeEnum,
  CopyUiModuleBodyModuleTypeEnum,
  HeroUiModuleBodyModuleTypeEnum,
  InlinePageUiModuleBodyModuleTypeEnum,
  InlinePromoUiModuleBodyModuleTypeEnum,
  LandingPromoUiModuleBodyModuleTypeEnum,
  LinkingAccountUiModuleBodyModuleTypeEnum,
  LiveNewsUiModuleBodyModuleTypeEnum,
  LivePlusUiModuleBodyModuleTypeEnum,
  MenuItemUiModuleBodyModuleTypeEnum,
  MenuSectionUiModuleBody,
  MenuSectionUiModuleBodyModuleTypeEnum,
  MixedListUiModuleBodyModuleTypeEnum,
  OneBrandPromoUiModuleBodyModuleTypeEnum,
  OutboundUiModuleBodyModuleTypeEnum,
  PageListUiModuleBodyModuleTypeEnum,
  PlanSummaryUiModuleBodyModuleTypeEnum,
  PlaybackPaywallUiModuleBodyModuleTypeEnum,
  ProfileUiModuleBodyModuleTypeEnum,
  PromotionUiModuleBodyModuleTypeEnum,
  RecommendationsUiModuleBodyModuleTypeEnum,
  RegistrationWallUiModuleBodyModuleTypeEnum,
  SportsEventUiModuleBodyModuleTypeEnum,
  SportsHeroUiModuleBodyModuleTypeEnum,
  TdsGiftCardsUiModuleBodyModuleTypeEnum,
  TournamentListUiModuleBodyModuleTypeEnum,
  TournamentSplashHeroUiModuleBodyModuleTypeEnum,
  WatchListUiModuleBodyModuleTypeEnum
} from '../../../../API';
import { useLayoutView, useLocales, useStatsig } from '../../../../hooks';
import {
  UiModuleBody,
  withCopiedModules,
  withLayoutsType,
  withSearchFocusModuleId,
  withSelectedLayoutUIModules
} from '../../../../state/Layouts';
import Repeat from '../../../shared/Repeat';
import ShadowScroller from '../../../shared/ShadowScroller';
import AccountCreationListItem from '../../UIModuleListItem/ListItem/AccountCreationListItem';
import AccountSuccessListItem from '../../UIModuleListItem/ListItem/AccountSuccessListItem';
import ChannelsListItem from '../../UIModuleListItem/ListItem/ChannelsListItem';
import CollectionListListItem from '../../UIModuleListItem/ListItem/CollectionListListItem';
import CollectionSplashHeroListItem from '../../UIModuleListItem/ListItem/CollectionSplashHeroListItem';
import ContentListListItem from '../../UIModuleListItem/ListItem/ContentListListItem';
import ContinueWatchingListItem from '../../UIModuleListItem/ListItem/ContinueWatchingListItem';
import CopyListItem from '../../UIModuleListItem/ListItem/CopyListItem';
import DefaultModuleListItem from '../../UIModuleListItem/ListItem/DefaultModuleListItem';
import HeroListItem from '../../UIModuleListItem/ListItem/HeroListItem';
import InlinePromoListItem from '../../UIModuleListItem/ListItem/InlinePromoListItem';
import InlinePageListItem from '../../UIModuleListItem/ListItem/InlinePageListItem';
import LandingPromoListItem from '../../UIModuleListItem/ListItem/LandingPromoListItem';
import LinkingAccountListItem from '../../UIModuleListItem/ListItem/LinkingAccountListItem';
import LivePlusListItem from '../../UIModuleListItem/ListItem/LivePlusListItem';
import MenuListItem from '../../UIModuleListItem/ListItem/MenuListItem';
import PageListListItem from '../../UIModuleListItem/ListItem/PageListListItem';
import PlanSummaryListItem from '../../UIModuleListItem/ListItem/PlanSummaryListItem';
import PlaybackPaywallListItem from '../../UIModuleListItem/ListItem/PlaybackPaywallListItem';
import ProfileListItem from '../../UIModuleListItem/ListItem/ProfileListItem';
import PromotionListItem from '../../UIModuleListItem/ListItem/PromotionListItem';
import RecommendationsListItem from '../../UIModuleListItem/ListItem/RecommendationsListItem';
import RegWallListItem from '../../UIModuleListItem/ListItem/RegWallListItem';
import SportsEventListItem from '../../UIModuleListItem/ListItem/SportsEventListItem';
import SportsHeroListItem from '../../UIModuleListItem/ListItem/SportsHeroListItem';
import TournamentListListItem from '../../UIModuleListItem/ListItem/TournamentListListItem';
import TournamentSplashHeroItem from '../../UIModuleListItem/ListItem/TournamentSplashHeroListItem';
import OneBrandPromoListItem from '../../UIModuleListItem/ListItem/OneBrandPromoListItem';
import TdsGiftCardsListItem from '../../UIModuleListItem/ListItem/TdsGiftCardsListItem';
import WatchListListItem from '../../UIModuleListItem/ListItem/WatchListListItem';
import MixedListListItem from '../../UIModuleListItem/ListItem/MixedListListItem';
import { Sortable2 } from '../../../shared/Sortable2';
import { ScrollToIndex, TreeItem } from '../../../shared/SortableTree/SortableTreeTypes';
import { SortableTree } from '../../../shared/SortableTree';
import { ShadowList } from '../../../shared/Virtuoso';
import { VirtuosoHandle } from 'react-virtuoso';
import { withStatsigUiModulesConfig } from '../../../../state/Experiments';
import { useLocation } from 'react-router-dom';
import { OutboundListItem } from '../../UIModuleListItem/ListItem/OutboundListItem';

const useStyles = makeStyles()((theme) => ({
  skeletonModule: {
    borderTop: `1px dotted ${theme.palette.divider}`,
    padding: theme.spacing(4)
  },
  skeletonRow: {
    height: theme.spacing(6),
    marginBottom: theme.spacing(4)
  },
  uiModules: {
    flexGrow: 1
  },
  noUIModules: {
    padding: theme.spacing(4)
  }
}));

export type LayoutViewModulesProps = {
  isEditable: boolean;
  hasUpsertPermission?: boolean;
};

function LayoutViewModules({ isEditable, hasUpsertPermission }: LayoutViewModulesProps): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { shouldHideUiModule } = useLayoutView();
  const [uiModules, setUiModules] = useRecoilState(withSelectedLayoutUIModules);
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const sortableTreeRef = useRef<ScrollToIndex>(null);
  const searchFocusModuleId = useRecoilValue(withSearchFocusModuleId);
  const { getUiModulesConfig } = useStatsig();
  const statsigUiModulesConfig = useRecoilValue(withStatsigUiModulesConfig);
  const [, setCopiedModules] = useRecoilState(withCopiedModules);
  const layoutsType = useRecoilValue(withLayoutsType);
  const location = useLocation();

  useEffect(() => {
    if (!statsigUiModulesConfig) {
      getUiModulesConfig();
    }
  }, []);

  useEffect(() => {
    const currentLayout = location.pathname.split('/')[3];
    if (layoutsType !== currentLayout) setCopiedModules([]);
  }, [location]);

  useEffect(() => {
    if (!searchFocusModuleId) return;
    const index = uiModules?.findIndex((module) => module.moduleId === searchFocusModuleId) ?? -1;
    if (index >= 0) {
      virtuosoRef.current?.scrollToIndex(index);
      sortableTreeRef.current?.scrollToIndex(index);
    }
  }, [searchFocusModuleId]);

  const renderUIModuleListItem = (
    uiModule: UiModuleBody,
    index: number,
    isEditable: boolean,
    menuSectionIndex: number | null = null
  ) => {
    const baseProps = { key: uiModule.moduleId, index, isEditable, hidden: shouldHideUiModule(uiModule), uiModule };
    switch (uiModule.moduleType) {
      case HeroUiModuleBodyModuleTypeEnum.HERO:
        return <HeroListItem {...baseProps} uiModule={uiModule} />;
      case ContentListUiModuleBodyModuleTypeEnum.CONTENT_LIST:
        return <ContentListListItem {...baseProps} uiModule={uiModule} />;
      case CollectionListUiModuleBodyModuleTypeEnum.COLLECTION_LIST:
        return <CollectionListListItem {...baseProps} uiModule={uiModule} />;
      case CollectionSplashHeroUiModuleBodyModuleTypeEnum.COLLECTION_SPLASH_HERO:
        return <CollectionSplashHeroListItem {...baseProps} uiModule={uiModule} />;
      case TournamentSplashHeroUiModuleBodyModuleTypeEnum.TOURNAMENT_SPLASH_HERO:
        return <TournamentSplashHeroItem {...baseProps} uiModule={uiModule} />;
      case ContinueWatchingUiModuleBodyModuleTypeEnum.CONTINUE_WATCHING:
        return <ContinueWatchingListItem {...baseProps} uiModule={uiModule} />;
      case RecommendationsUiModuleBodyModuleTypeEnum.RECOMMENDATIONS:
        return <RecommendationsListItem {...baseProps} uiModule={uiModule} />;
      case PageListUiModuleBodyModuleTypeEnum.PAGE_LIST:
        return <PageListListItem {...baseProps} uiModule={uiModule} />;
      case SportsEventUiModuleBodyModuleTypeEnum.SPORTS_EVENT:
        return <SportsEventListItem {...baseProps} uiModule={uiModule} />;
      case LiveNewsUiModuleBodyModuleTypeEnum.LIVE_NEWS:
        return <ChannelsListItem {...baseProps} uiModule={uiModule} />;
      case SportsHeroUiModuleBodyModuleTypeEnum.SPORTS_HERO:
        return <SportsHeroListItem {...baseProps} uiModule={uiModule} />;
      case CopyUiModuleBodyModuleTypeEnum.COPY:
        return <CopyListItem {...baseProps} uiModule={uiModule} />;
      case PromotionUiModuleBodyModuleTypeEnum.PROMOTION:
        return <PromotionListItem {...baseProps} uiModule={uiModule} />;
      case LivePlusUiModuleBodyModuleTypeEnum.LIVE_PLUS:
        return <LivePlusListItem {...baseProps} uiModule={uiModule} />;
      case AccountSuccessUiModuleBodyModuleTypeEnum.ACCOUNT_SUCCESS:
        return <AccountSuccessListItem {...baseProps} uiModule={uiModule} />;
      case TournamentListUiModuleBodyModuleTypeEnum.TOURNAMENT_LIST:
        return <TournamentListListItem {...baseProps} uiModule={uiModule} />;
      case MenuItemUiModuleBodyModuleTypeEnum.MENU_ITEM:
      case MenuSectionUiModuleBodyModuleTypeEnum.MENU_SECTION:
        return <MenuListItem {...baseProps} uiModule={uiModule} menuSectionIndex={menuSectionIndex ?? -1} />;
      case InlinePromoUiModuleBodyModuleTypeEnum.INLINE_PROMO:
        return <InlinePromoListItem {...baseProps} uiModule={uiModule} />;
      case OneBrandPromoUiModuleBodyModuleTypeEnum.ONE_BRAND_PROMO:
        return <OneBrandPromoListItem {...baseProps} uiModule={uiModule} />;
      case ProfileUiModuleBodyModuleTypeEnum.PROFILE:
        return <ProfileListItem {...baseProps} uiModule={uiModule} />;
      case RegistrationWallUiModuleBodyModuleTypeEnum.REGISTRATION_WALL:
        return <RegWallListItem {...baseProps} uiModule={uiModule} />;
      case InlinePageUiModuleBodyModuleTypeEnum.INLINE_PAGE:
        return <InlinePageListItem {...baseProps} uiModule={uiModule} />;
      case PlaybackPaywallUiModuleBodyModuleTypeEnum.PLAYBACK_PAYWALL:
        return <PlaybackPaywallListItem {...baseProps} uiModule={uiModule} />;
      case AccountCreationUiModuleBodyModuleTypeEnum.ACCOUNT_CREATION:
        return <AccountCreationListItem {...baseProps} uiModule={uiModule} />;
      case PlanSummaryUiModuleBodyModuleTypeEnum.PLAN_SUMMARY:
        return <PlanSummaryListItem {...baseProps} uiModule={uiModule} />;
      case LandingPromoUiModuleBodyModuleTypeEnum.LANDING_PROMO:
        return <LandingPromoListItem {...baseProps} uiModule={uiModule} />;
      case LinkingAccountUiModuleBodyModuleTypeEnum.LINKING_ACCOUNT:
        return <LinkingAccountListItem {...baseProps} uiModule={uiModule} />;
      case TdsGiftCardsUiModuleBodyModuleTypeEnum.TDS_GIFT_CARDS:
        return <TdsGiftCardsListItem {...baseProps} uiModule={uiModule} />;
      case WatchListUiModuleBodyModuleTypeEnum.WATCH_LIST:
        return <WatchListListItem {...baseProps} uiModule={uiModule} />;
      case MixedListUiModuleBodyModuleTypeEnum.MIXED_LIST:
        return <MixedListListItem {...baseProps} uiModule={uiModule} />;
      case OutboundUiModuleBodyModuleTypeEnum.OUTBOUND:
        return <OutboundListItem {...baseProps} uiModule={uiModule} />;
      default:
        return <DefaultModuleListItem {...baseProps} uiModule={uiModule} />;
    }
  };

  const renderUIModuleListItemEditable = (uiModule: UiModuleBody, index?: number) => {
    return renderUIModuleListItem(uiModule, index ?? -1, true);
  };

  const renderMenuItem =
    (isEditable: boolean) => (uiModule: UiModuleBody, index: number, parentIndex: number | null) => {
      return renderUIModuleListItem(uiModule, index ?? -1, isEditable, parentIndex);
    };

  const convertMenuItemToTreeItem = (menuItem: UiModuleBody): TreeItem<UiModuleBody> => {
    const module = menuItem as MenuSectionUiModuleBody;
    return {
      id: String(menuItem.moduleId),
      object: module as UiModuleBody,
      children: module.menuItems?.map(convertMenuItemToTreeItem) || []
    };
  };

  const menuItemsTree = useMemo(() => uiModules?.map(convertMenuItemToTreeItem), [uiModules]);

  const convertTreeItemToMenuItem = (treeItems: TreeItem<UiModuleBody>[]) => {
    const newUiModulesList: UiModuleBody[] = [];
    treeItems.forEach((treeItem) => {
      const isMenuSection = !!treeItem.children.length;
      newUiModulesList.push({
        ...treeItem.object,
        moduleType: isMenuSection
          ? MenuSectionUiModuleBodyModuleTypeEnum.MENU_SECTION
          : MenuItemUiModuleBodyModuleTypeEnum.MENU_ITEM,
        menuItems: isMenuSection
          ? treeItem.children.map((childItem) => ({
              ...childItem.object,
              moduleType: MenuItemUiModuleBodyModuleTypeEnum.MENU_ITEM
            }))
          : undefined
      } as UiModuleBody);
    });
    setUiModules(newUiModulesList);
  };

  const hasMenuItems = !!uiModules?.find(
    (module) =>
      module.moduleType === MenuItemUiModuleBodyModuleTypeEnum.MENU_ITEM ||
      module.moduleType === MenuSectionUiModuleBodyModuleTypeEnum.MENU_SECTION
  );

  return (
    <div className={classes.uiModules}>
      {(isEditable || hasMenuItems) && (
        <ShadowScroller loading={!uiModules} horizontalScrolling={false}>
          {hasMenuItems && uiModules && (
            <SortableTree
              items={menuItemsTree as TreeItem<UiModuleBody>[]}
              // eslint-disable-next-line
              // @ts-ignore
              setItems={convertTreeItemToMenuItem}
              // eslint-disable-next-line
              // @ts-ignore
              renderItem={renderMenuItem(isEditable)}
              disabled={!isEditable}
              ref={sortableTreeRef}
            />
          )}
          {isEditable && !hasMenuItems && !!uiModules?.length && (
            <Sortable2
              list={uiModules}
              setList={setUiModules}
              idField="moduleId"
              renderListItem={renderUIModuleListItemEditable}
              disabled={!hasUpsertPermission}
              draggableBackgroundColor="default"
              virtualized
              virtuosoRef={virtuosoRef}
            />
          )}
          {uiModules && !uiModules.length && (
            <Typography variant="body2" className={classes.noUIModules} color="textSecondary">
              {t('layouts.no_ui_modules')}
            </Typography>
          )}
          {!uiModules && (
            <Repeat n={10}>
              <div className={classes.skeletonModule}>
                <Skeleton className={classes.skeletonRow} animation="wave" />
              </div>
            </Repeat>
          )}
        </ShadowScroller>
      )}
      {!isEditable && !hasMenuItems && uiModules?.length && (
        <ShadowList
          data={uiModules}
          itemContent={(i, uiModule) => renderUIModuleListItem(uiModule, i, false)}
          virtuosoRef={virtuosoRef}
        />
      )}
    </div>
  );
}

export default LayoutViewModules;
