import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CircularProgress, Tab, Tabs } from '@mui/material';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { makeStyles } from 'tss-react/mui';

import { VersionLog } from '../../../../API';
import { useHandleError, useLocales, useNotifications, useResetState, useTheme } from '../../../../hooks';
import { useVersionLogAPI } from '../../../../hooks/API/VersionLog/useVersionLogAPI';
import { withLogVersionBackend, withLogVersionFrontend } from '../../../../state/VersionLog';
import Button from '../../../shared/Button';
import Modal from '../../../shared/Modal';
import { VersionLogCommitItem } from './VersionLogCommitItem';
import { VersionLogTagItem } from './VersionLogTagItem';
import { ShadowList } from '../../../shared/Virtuoso';
import { LogoDev } from '@mui/icons-material';
import { copyTextToClipboard } from '../../../../utils/clipboard';
import { Environments, whichEnv } from '../../../../utils/whichEnv';
import IconButton from '../../../shared/IconButton';
import { HttpError } from '../../../../utils/parseError';

enum VersionLogViewType {
  CMS = 'CMS',
  EDITORIAL = 'EDITORIAL'
}

const useStyles = makeStyles()((theme) => ({
  modal: {
    width: '62vw',
    height: '62vh'
  },
  supportLink: {
    textDecoration: 'none'
  },
  tabText: {
    textTransform: 'none'
  },
  loadingSpinner: {
    textAlign: 'center',
    marginTop: theme.spacing(10)
  }
}));

export const testIds = {
  closeModal: 'version-log-close-modal',
  cmsTab: 'version-log-cms-tab',
  editorialTab: 'version-log-editorial-tab',
  versionLogModal: 'version-log-root'
};

export const getLatestVersion = (versionLog: VersionLog): string => {
  const env = whichEnv();
  if (env === Environments.DEVELOPMENT || !versionLog?.tags?.[env]?.[0]) return '';
  return 'v' + versionLog.tags[env][0].tag;
};

interface VersionLogListProps {
  versionLog: VersionLog;
  githubProject: string;
}

export interface VersionLogModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function VersionLogList({ versionLog, githubProject }: VersionLogListProps): React.ReactElement {
  const [expanded, setExpanded, reset] = useResetState<{ [key: string]: boolean }>({ 0: true });

  // reset the expanded state when changing version log
  useEffect(reset, [versionLog]);

  const handleExpand = (index: number, state: boolean) => {
    setExpanded((e) => ({ ...e, [index]: state }));
  };

  const env = whichEnv();

  if (env === 'de') {
    return (
      <ShadowList
        data={versionLog.tags.de.filter((commitSha) => !!versionLog.commits[commitSha])}
        itemContent={(_, commit) => (
          <VersionLogCommitItem key={commit} commit={versionLog.commits[commit]} githubProject={githubProject} />
        )}
      />
    );
  }

  return (
    <ShadowList
      data={versionLog.tags[env]}
      itemContent={(index, tag) => (
        <VersionLogTagItem
          key={tag.tag}
          tag={tag}
          versionLog={versionLog}
          githubProject={githubProject}
          onExpand={(state) => handleExpand(index, state)}
          defaultIsOpen={expanded[index]}
        />
      )}
    />
  );
}

VersionLogList.displayName = 'VersionLogList';

function VersionLogModal({ isOpen, setIsOpen }: VersionLogModalProps): JSX.Element {
  const { classes } = useStyles();
  const supportLink = process.env.REACT_APP_SLACK_SUPPORT_LINK || null;
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const closeVersionLog = () => setIsOpen(false);
  const { handleError } = useHandleError();
  const versionLogFrontend = useRecoilValue(withLogVersionFrontend);
  const [versionLogBackend, setVersionLogBackend] = useRecoilState(withLogVersionBackend);
  const versionLogApi = useVersionLogAPI();
  const [selectedTab, setSelectedTab] = useState<VersionLogViewType>(VersionLogViewType.CMS);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { notifyInfo } = useNotifications();

  useEffect(() => {
    void getEditorialVersionLog();
  }, []);

  const getEditorialVersionLog = async () => {
    setIsLoading(true);
    try {
      const response = await versionLogApi.getVersionLog();
      setVersionLogBackend(response.data.body);
    } catch (err) {
      handleError(
        err,
        t('errors.general_api_error', {
          action: 'GET',
          entity: 'VersionLog',
          statusCode: (err as HttpError)?.response?.status
        })
      );
    }
    setIsLoading(false);
  };

  const copyDebugLog = async () => {
    if (!versionLogFrontend.debugLog) return;
    await copyTextToClipboard(versionLogFrontend.debugLog);
    notifyInfo('Copied debug log to clipboard');
  };

  return (
    <Modal
      bodyClassName={classes.modal}
      open={isOpen}
      headerLeft={
        <Tabs
          value={selectedTab}
          textColor={formControlColor}
          indicatorColor={formControlColor}
          onChange={(event, newValue) => setSelectedTab(newValue)}
        >
          <Tab
            label={<span className={classes.tabText}>CMS {getLatestVersion(versionLogFrontend)}</span>}
            value={VersionLogViewType.CMS}
            data-testid={testIds.cmsTab}
          />
          <Tab
            label={<span className={classes.tabText}>Editorial {getLatestVersion(versionLogBackend)}</span>}
            value={VersionLogViewType.EDITORIAL}
            data-testid={testIds.editorialTab}
          />
        </Tabs>
      }
      headerRight={
        <IconButton onClick={copyDebugLog}>
          <LogoDev />
        </IconButton>
      }
      footerLeft={
        supportLink && (
          <a href={supportLink} target="_blank" rel="noreferrer" className={classes.supportLink}>
            <Button size="small" startIcon={<LiveHelpIcon />}>
              {t('topbar.support')}
            </Button>
          </a>
        )
      }
      footerRight={
        <Button size="small" onClick={closeVersionLog} color="grey" data-testid={testIds.closeModal}>
          {t('general.close')}
        </Button>
      }
      data-testid={testIds.versionLogModal}
      onClose={closeVersionLog}
    >
      <>
        {selectedTab === VersionLogViewType.CMS && (
          <VersionLogList versionLog={versionLogFrontend} githubProject="client-cms-web" />
        )}
        {selectedTab === VersionLogViewType.EDITORIAL && !isLoading && versionLogBackend.tags && (
          <VersionLogList versionLog={versionLogBackend} githubProject="service-editorial" />
        )}
        {isLoading && (
          <div className={classes.loadingSpinner}>
            <CircularProgress size={50} color="inherit" />
          </div>
        )}
      </>
    </Modal>
  );
}

export default VersionLogModal;
