import { localAtom } from '../localStorageState';
import { HistoryManagerStateType } from '../../components/shared/HistoryManager';

export type HistoryStateObject = {
  originalState: HistoryManagerStateType | undefined;
  editHistory: HistoryManagerStateType[];
};

export const withHistoryManager = localAtom({
  key: 'historyManager.changes',
  default: {
    isClean: true,
    state: {} as HistoryStateObject | undefined
  }
});
