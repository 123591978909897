import {
  AiSearchUiModuleBody,
  AiSearchUiModuleBodyModuleTypeEnum,
  DocumentLocale,
  EntitlementType
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankAiSearchModule: NewModuleFunction<AiSearchUiModuleBody> = (module) => ({
  moduleType: AiSearchUiModuleBodyModuleTypeEnum.AI_SEARCH,
  moduleId: generateId(),
  title: {
    [DocumentLocale.ES]: ''
  },
  entitlements: [EntitlementType.ANON, EntitlementType.FREE, EntitlementType.SUB],
  searchTitle: '',
  searchSubTitle: '',
  searchPlaceholder: '',
  suggestions: [
    {
      id: generateId(),
      suggestionText: ''
    }
  ],
  ...module
});
