import React from 'react';
import { UserResponse } from '../../../API';
import { useData } from '../../../data-layer';
import { withUsersSplitPane } from '../../../state/General';
import { DataGrid, DataGridColDef } from '../../shared/DataGrid';

export interface IUsersGridProps {
  users: UserResponse[] | undefined;
  columns: DataGridColDef<UserResponse>[];
}

export const testIds = {
  loadingFrame: 'users.loading-frame'
};

export default function UsersGrid({ users, columns }: IUsersGridProps): JSX.Element {
  const {
    users: { hook: useUsers }
  } = useData();

  return (
    <DataGrid
      rows={users}
      columns={columns}
      onRowClick={({ email }) => useUsers.edit(email)}
      withColumnSizes={withUsersSplitPane}
      rowKey="email"
    />
  );
}
