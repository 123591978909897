/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { EntitlementType } from './entitlement-type';
import { LocalizedFieldBody } from './localized-field-body';
import { TargetPlatform } from './target-platform';
import { UiModuleExperimentGroup } from './ui-module-experiment-group';

/**
 * 
 * @export
 * @interface PlanSummaryUiModuleBody
 */
export interface PlanSummaryUiModuleBody {
    /**
     * 
     * @type {string}
     * @memberof PlanSummaryUiModuleBody
     */
    moduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanSummaryUiModuleBody
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanSummaryUiModuleBody
     */
    moduleType: PlanSummaryUiModuleBodyModuleTypeEnum;
    /**
     * 
     * @type {Array<TargetPlatform>}
     * @memberof PlanSummaryUiModuleBody
     */
    targetPlatform?: Array<TargetPlatform>;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof PlanSummaryUiModuleBody
     */
    caption?: LocalizedFieldBody;
    /**
     * 
     * @type {Array<EntitlementType>}
     * @memberof PlanSummaryUiModuleBody
     */
    entitlements?: Array<EntitlementType>;
    /**
     * 
     * @type {Array<UiModuleExperimentGroup>}
     * @memberof PlanSummaryUiModuleBody
     */
    experiments?: Array<UiModuleExperimentGroup> | null;
    /**
     * 
     * @type {string}
     * @memberof PlanSummaryUiModuleBody
     */
    planName: string;
    /**
     * 
     * @type {string}
     * @memberof PlanSummaryUiModuleBody
     */
    priceFirstCharge: string;
    /**
     * 
     * @type {string}
     * @memberof PlanSummaryUiModuleBody
     */
    planCost: string;
    /**
     * 
     * @type {string}
     * @memberof PlanSummaryUiModuleBody
     */
    totalAmount: string;
    /**
     * 
     * @type {string}
     * @memberof PlanSummaryUiModuleBody
     */
    totalSummaryTitle: string;
    /**
     * 
     * @type {string}
     * @memberof PlanSummaryUiModuleBody
     */
    legalDisclaimer: string;
    /**
     * 
     * @type {string}
     * @memberof PlanSummaryUiModuleBody
     */
    signUpCta: string;
    /**
     * 
     * @type {string}
     * @memberof PlanSummaryUiModuleBody
     */
    notNowCta: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlanSummaryUiModuleBody
     */
    checkboxConfirmation: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlanSummaryUiModuleBody
     */
    header?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanSummaryUiModuleBody
     */
    offerDetails?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanSummaryUiModuleBody
     */
    taxDisclaimer?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanSummaryUiModuleBody
     */
    taxAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanSummaryUiModuleBody
     */
    taxTitle?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PlanSummaryUiModuleBodyModuleTypeEnum {
    PLAN_SUMMARY = 'PLAN_SUMMARY'
}



