/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { EntitlementType } from './entitlement-type';
import { LocalizedFieldBody } from './localized-field-body';
import { PromotionUiModuleUpsellImagesBody } from './promotion-ui-module-upsell-images-body';
import { TargetPlatform } from './target-platform';
import { UiModuleExperimentGroup } from './ui-module-experiment-group';

/**
 * 
 * @export
 * @interface PromotionUiModuleBody
 */
export interface PromotionUiModuleBody {
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    moduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    moduleType: PromotionUiModuleBodyModuleTypeEnum;
    /**
     * 
     * @type {Array<TargetPlatform>}
     * @memberof PromotionUiModuleBody
     */
    targetPlatform?: Array<TargetPlatform>;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof PromotionUiModuleBody
     */
    caption?: LocalizedFieldBody;
    /**
     * 
     * @type {Array<EntitlementType>}
     * @memberof PromotionUiModuleBody
     */
    entitlements?: Array<EntitlementType>;
    /**
     * 
     * @type {Array<UiModuleExperimentGroup>}
     * @memberof PromotionUiModuleBody
     */
    experiments?: Array<UiModuleExperimentGroup> | null;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof PromotionUiModuleBody
     */
    title: LocalizedFieldBody;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    portraitUpsellImage?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    landscapeUpsellImage?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    desktopUpsellImage?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    mobileUpsellImage?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    ctvUpsellImage?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    tabletUpsellImage?: string;
    /**
     * 
     * @type {Array<PromotionUiModuleUpsellImagesBody>}
     * @memberof PromotionUiModuleBody
     */
    upsellImages?: Array<PromotionUiModuleUpsellImagesBody>;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    upsellHeader: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    upsellSubheader: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    upsellLead: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    upsellCallToAction: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    redeemCouponCallToAction?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    valuePropsHeader: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    valuePropsSubheader: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    valueProps: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    valuePropsDisclaimer: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    valuePropsCallToAction: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    welcomeHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    welcomeSubheader?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    loginCallToAction?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    moreCallToAction?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    returnCallToAction: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionUiModuleBody
     */
    autoRotateSeconds?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    planPickerHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    planTileTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    planTileDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    planTilePrice?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionUiModuleBody
     */
    planTileBadge?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PromotionUiModuleBody
     */
    planTileIsHighlighted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PromotionUiModuleBody
     */
    planTilePriority?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PromotionUiModuleBody
     */
    isDefault?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum PromotionUiModuleBodyModuleTypeEnum {
    PROMOTION = 'PROMOTION'
}



