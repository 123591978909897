import {
  DocumentLocale,
  EntitlementType,
  LandingPromoUiModuleBody,
  LandingPromoUiModuleBodyModuleTypeEnum
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankLandingPromoModule: NewModuleFunction<LandingPromoUiModuleBody> = (module) => ({
  moduleType: LandingPromoUiModuleBodyModuleTypeEnum.LANDING_PROMO,
  moduleId: generateId(),
  title: {
    [DocumentLocale.ES]: ''
  },
  entitlements: [EntitlementType.ANON, EntitlementType.FREE, EntitlementType.SUB],
  vixLogo: '',
  ...module
});
