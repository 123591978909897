import { DataExchangeResponse, OperationIds } from '../../../API';

import { CreateDataManager, DataManagerReturnType, DataManagerStateFullReturnType } from '../../DataManager';
import { useDataExchangeAPI } from '../../../hooks/API/DataExchange/useDataExchangeAPI';
import { DataExchangeHook, DataExchangeHookReturnType } from './DataExchangeHook';

export type DataExchangesDataManagerType = {
  state: DataManagerStateFullReturnType<DataExchangeResponse>;
  hook: DataExchangeHookReturnType;
};

export interface DataExchangesDataManagerReturnType extends DataManagerReturnType<DataExchangeResponse> {
  state: DataExchangesDataManagerType['state'];
  hook: () => DataExchangesDataManagerType['hook'];
}

export const DataExchangesDataManager = CreateDataManager<
  DataExchangeResponse,
  DataExchangeResponse,
  DataExchangesDataManagerReturnType
>({
  name: 'DataExchanges',
  idField: 'id',
  useApiHook: useDataExchangeAPI,
  isVersionedEntity: true,
  toString: () => 'DataExchangesDataManager',
  operationIdGetAll: OperationIds.GET_ALL,
  hookInitializer: DataExchangeHook
});
