import React from 'react';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { TreeView } from '@mui/x-tree-view';
import { makeStyles } from 'tss-react/mui';
import generateId from '../../../utils/generateId';
import { getAssetUrl } from '../../../utils/assetHelpers';
import { CustomTreeItem } from '../CustomTreeItem';
import { CollectionLink } from '../../Collections/CollectionLink';
import { HPCLink } from '../../HPC/HPCLink';
import { COLLECTION_ID_PARAM } from '../../../utils/consts/uiModules';
import { useLocales } from '../../../hooks';
import { Typography } from '@mui/material';
import { isAsset, isCollectionLink, isDuration, isHeroPresetCollection, isVixImage } from '../../../utils/regex';
import { SportsEventUiModuleBodyModuleTypeEnum } from '../../../API';
import { Duration } from 'luxon';

export type ObjectTreeViewProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any;
  title: string;
  className?: string;
  imagePreviewHeight?: number;
  defaultExpanded?: string[];
};

type RenderTreeItemParams = {
  nodeId: string;
  label: React.ReactNode;
  children?: React.ReactNode;
  value?: string;
  disableTooltip?: boolean;
};

const defaultImagePreviewHeight = 40;

const useStyles = makeStyles()(() => ({
  imagePreview: {}
}));

const rootId = 'root';

export function ObjectTreeView({
  object,
  title,
  className,
  imagePreviewHeight = defaultImagePreviewHeight,
  defaultExpanded = [rootId]
}: ObjectTreeViewProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();

  const renderValue = (label: string, value: string) => {
    if (/^undefined$/.test(value) || value.length === 0) {
      return (
        <Typography color="textSecondary" variant="body2" component="span">
          {t('epg.channels.none')}
        </Typography>
      );
    }
    if (isAsset(value)) {
      return <img className={classes.imagePreview} src={`${getAssetUrl(value)}?tr=h-${imagePreviewHeight}`} />;
    }
    if (isVixImage(value)) {
      return <img src={`${value}?tr=h-${imagePreviewHeight}`} />;
    }
    if (
      isCollectionLink(label) &&
      value !== COLLECTION_ID_PARAM &&
      object.moduleType !== SportsEventUiModuleBodyModuleTypeEnum.SPORTS_EVENT
    ) {
      return <CollectionLink collectionId={value} />;
    }
    if (isHeroPresetCollection(label)) {
      return <HPCLink hpcId={value} />;
    }
    if (isDuration(label)) {
      return Duration.fromObject({ seconds: parseInt(value) }).toFormat("hh'h' mm'm' ss's'");
    }
    return <span>{value}</span>;
  };

  const renderNode = (label: string, value: string) => {
    if (isDuration(label) && !['MOVIE', 'EPISODE', 'CLIP'].includes(object.contentType)) return;
    return (
      <>
        <b>{label}:</b> {renderValue(label, value)}
      </>
    );
  };

  const renderTreeItem = ({ nodeId, label, value, children, disableTooltip }: RenderTreeItemParams): JSX.Element => {
    return (
      <CustomTreeItem nodeId={nodeId} key={nodeId} label={label} value={value} disableTooltip={disableTooltip}>
        {children}
      </CustomTreeItem>
    );
  };

  const shouldRenderTooltip = (label: string) => {
    if (isCollectionLink(label) || isHeroPresetCollection(label)) {
      return false;
    }
    return true;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderTree = (object: any, label: string, nodeId: string): JSX.Element => {
    switch (typeof object) {
      case 'object':
        if (object instanceof Array) {
          return renderTreeItem({
            nodeId,
            label: <b>{label}</b>,
            children: <>{object.map((element, index) => renderTree(element, String(index), generateId()))}</>
          });
        }
        if (object instanceof Date) {
          return renderTreeItem({ nodeId, label: renderNode(label, object.toISOString()) });
        }
        if (object === null) {
          return renderTreeItem({ nodeId, label: renderNode(label, 'null') });
        }
        // else we have an object
        return renderTreeItem({
          nodeId,
          label: <b>{label}</b>,
          children: <>{Object.keys(object).map((key) => renderTree(object[key], key, generateId()))}</>
        });
      default: {
        const disableTooltip = !shouldRenderTooltip(label);
        return renderTreeItem({
          nodeId,
          label: renderNode(label, String(object)),
          value: String(object),
          disableTooltip
        });
      }
    }
  };

  return (
    <TreeView
      className={className}
      defaultCollapseIcon={<ExpandMore />}
      defaultExpanded={defaultExpanded}
      defaultExpandIcon={<ChevronRight />}
    >
      {renderTree(object, title, rootId)}
    </TreeView>
  );
}
