import React, { useMemo } from 'react';
import { Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ConfigResponse, MediaResponse } from '../../../API';
import { getAssetUrl, getImageBucketPath } from '../../../utils/assetHelpers';
import Localized from '../../shared/Localized';
import { useRecoilValue } from 'recoil';
import { withAllSettings } from '../../../state/Config';
import { useTimezones } from '../../../hooks';
import { useLocales } from '../../../hooks';
import StyledPremiumBadge from '../../shared/StyledPremiumBadge';

export interface IMatchThumbnailProps {
  matchData: MediaResponse | undefined;
  customBackground?: string;
  'data-testid'?: string;
}

const useStyles = makeStyles()((theme) => ({
  img: {
    width: '100%',
    height: '100%',
    borderRadius: theme.shape.borderRadius
  },
  thumbnailTooltip: {
    textAlign: 'center'
  },
  thumbnailWrapper: {
    position: 'relative'
  }
}));

function generateImageUrl(matchData: MediaResponse | undefined, config: ConfigResponse | undefined) {
  const leagueCard = matchData?.tournament?.cardBackgroundImage || config?.sports.defaultTournamentBackgroundImage;
  if (!leagueCard) return;

  const leagueBackground = getAssetUrl(leagueCard);
  const homeTeamLogo = matchData?.homeTeam?.logo || config?.sports.defaultTeamLogoImage;
  const awayTeamLogo = matchData?.awayTeam?.logo || config?.sports.defaultTeamLogoImage;
  const bucket = getImageBucketPath();

  let url = `${leagueBackground}?tr=w-360,h-204`;

  if (homeTeamLogo) {
    url += `:l-image,i-${bucket}/${homeTeamLogo},h-137,w-118,c-at_max,t-false,cm-pad_resize,lx-49,ly-34,l-end`;
  }
  if (awayTeamLogo) {
    url += `:l-image,i-${bucket}/${awayTeamLogo},h-137,w-118,c-at_max,t-false,cm-pad_resize,lx-N49,ly-34,l-end`;
  }

  return url;
}

export function MatchThumbnail({ matchData, customBackground, ...props }: IMatchThumbnailProps): React.ReactElement {
  const { classes } = useStyles();
  const { timestamp } = useTimezones();
  const { t } = useLocales();

  const config = useRecoilValue(withAllSettings);
  const imageUrl = useMemo(() => {
    return customBackground ?? generateImageUrl(matchData, config);
  }, [matchData, config, customBackground]);

  const startDate = (matchData: MediaResponse) => {
    if (matchData.sportsEvent?.startDate) return <div>{timestamp(matchData.sportsEvent?.startDate)}</div>;
    if (matchData.kickoffDate) return <div>{timestamp(matchData.kickoffDate)}</div>;
    return;
  };

  return (
    <Tooltip
      title={
        <div className={classes.thumbnailTooltip}>
          {matchData ? (
            <>
              {matchData.title && (
                <div>
                  <Localized prop={matchData.title} />
                </div>
              )}
              {matchData && startDate(matchData)}
            </>
          ) : (
            <div>{t('errors.layouts.match_data_not_available')}</div>
          )}
        </div>
      }
    >
      <div className={classes.thumbnailWrapper}>
        <img className={classes.img} src={imageUrl} data-testid={props['data-testid']} />
        {matchData?.isPremium && <StyledPremiumBadge />}
      </div>
    </Tooltip>
  );
}
