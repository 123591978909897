import { useContext, useEffect } from 'react';
import {
  GlobalHistoryManagerContext,
  GlobalHistoryManagerListenerFunction
} from '../../components/shared/HistoryManager/GlobalHistoryManager';

type UseGlobalHistoryManager = {
  pushState: () => void;
  undo: () => void;
  redo: () => void;
  reset: () => void;
  block: () => void;
  unblock: () => void;
};

export function useGlobalHistoryManager(
  id: string,
  cb?: GlobalHistoryManagerListenerFunction
): UseGlobalHistoryManager {
  const { pushState, undo, redo, reset, addListener, block, unblock } = useContext(GlobalHistoryManagerContext);

  useEffect(() => {
    if (cb) {
      addListener(id, cb);
    }
  }, [cb]);

  return {
    pushState: () => pushState(id),
    reset: () => reset(id),
    undo: () => undo(id),
    redo: () => redo(id),
    block: () => block(),
    unblock: () => unblock()
  };
}
