import React from 'react';
import { ChevronLeft, Expand } from '@mui/icons-material';
import { useRecoilState } from 'recoil';
import { useData } from '../../../data-layer';
import { useLocales, useTheme } from '../../../hooks';
import Button from '../../shared/Button';

export const testIds = {
  expandButton: 'CollectionsExpand.expandButton'
};

function CollectionExpand(): JSX.Element {
  const {
    collections: {
      state: { withCollectionsExpanded }
    }
  } = useData();
  const { formControlColor } = useTheme();
  const { t } = useLocales();
  const [isExpanded, setIsExpanded] = useRecoilState(withCollectionsExpanded);

  const toggleExpanded = () => setIsExpanded((value) => !value);

  return (
    <Button
      data-testid={testIds.expandButton}
      size="small"
      color={formControlColor}
      variant="outlined"
      onClick={toggleExpanded}
      startIcon={isExpanded ? <ChevronLeft /> : <Expand sx={{ transform: 'rotate(90deg)' }} />}
      sx={{ minWidth: 'unset' }}
    >
      {isExpanded ? t('collections.show_both_panels') : t('collections.expand_section')}
    </Button>
  );
}

export default CollectionExpand;
