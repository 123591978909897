import { Typography } from '@mui/material';
import React from 'react';

export interface IEditorNotesProps {
  object?: WithEditorNotes;
}

type WithEditorNotes = {
  editorNotes?: string;
};

export function EditorNotes({ object }: IEditorNotesProps): React.ReactElement {
  return (
    <>
      {!!object?.editorNotes && (
        <Typography component="span" variant="body2" color="textSecondary">
          “{object.editorNotes}”
        </Typography>
      )}
    </>
  );
}
