import React, { useEffect, useRef, useState } from 'react';
import { AddOutlined, Face, Search } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { useRecoilValue } from 'recoil';
import { useData } from '../../data-layer';
import { useFind, useLocales, useTextFilter, useTheme } from '../../hooks';
import Button from '../shared/Button';
import UserForm from './UserForm';
import UsersGrid from './UsersGrid';
import { InputAdornment, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { PermissionsGroupId, UserResponse } from '../../API';
import FormControl from '../shared/FormControl';
import { ALL } from '../../utils/types/genericTypes';
import { DataGridColDef } from '../shared/DataGrid';
import { PermissionsGroupBadge } from '../shared/PermissionsGroupBadge';
import CountryBadgesMerged from '../shared/CountryBadgesMerged';
import TextField from '../shared/TextField';
import { UserAvatar } from './UserAvatar';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    gap: theme.spacing(4),
    backgroundColor: theme.palette.background.paper
  },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(4)
  },
  newUserButton: {
    minWidth: 160
  },
  textFilter: {
    width: 360,
    marginBottom: '0 !important'
  },
  groupFilter: {
    width: 220
  },
  groupBadge: {
    display: 'inline-block'
  }
}));

export const testIds = {
  newUserButton: 'users.new-user-button',
  textFilter: 'users.text-filter',
  groupFilterSelect: 'users.group-filter-select',
  groupFilterSelectItem: 'users.group-filter-select-item'
};

export default function Users(): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const {
    users: { state: usersState, hook: useUsers }
  } = useData();
  const users = useRecoilValue(usersState.withAllRecords);
  const [groupFilter, setGroupFilter] = useState<PermissionsGroupId | string>(ALL);
  const [filteredUsers, setFilteredUsers] = useState<UserResponse[] | undefined>(filterUsers(users));
  const searchRef = useRef<HTMLInputElement>();

  const {
    onFilterInputChange,
    clearFilter,
    filteredResults: filterByText
  } = useTextFilter(['firstName', 'lastName', 'email']);

  useEffect(() => {
    getUsers();
  }, []);

  useFind(() => searchRef.current?.focus());

  useEffect(() => {
    setFilteredUsers(filterUsers(users));
  }, [users, groupFilter]);

  const getUsers = async () => {
    await useUsers.getAll();
  };

  function filterUsers(users: UserResponse[] | undefined) {
    if (!users) return undefined;
    if (groupFilter === ALL) return users;
    return users.filter((user) => user.permissionsGroups?.find((group) => group.group === groupFilter));
  }

  const gridColumns: DataGridColDef<UserResponse>[] = [
    {
      key: 'avatar',
      cellRenderer: (user) => <UserAvatar user={user} />,
      label: <Face sx={{ display: 'flex' }} />,
      baseWidth: '4%',
      minWidth: 60
    },
    {
      key: 'firstName',
      cellRenderer: (user) => user.firstName,
      label: t('users.first_name'),
      sortFunction: (a, b) => a.firstName.localeCompare(b.firstName),
      baseWidth: '14%'
    },
    {
      key: 'lastName',
      cellRenderer: (user) => user.lastName,
      label: t('users.last_name'),
      sortFunction: (a, b) => a.lastName.localeCompare(b.lastName),
      baseWidth: '14%'
    },
    {
      key: 'email',
      cellRenderer: (user) => user.email,
      label: t('users.email'),
      sortFunction: (a, b) => a.email.localeCompare(b.email),
      baseWidth: '24%'
    },
    {
      key: 'roles',
      cellRenderer: (user) =>
        user.permissionsGroups
          ?.filter((group) => groupFilter === ALL || group.group === groupFilter)
          .map((group) => (
            <Stack key={group.group} direction="row" gap={2} alignItems="center" sx={{ my: 1 }}>
              <PermissionsGroupBadge value={group.group} className={classes.groupBadge} />
              <Typography component="span" color="textSecondary" variant="caption">
                {group.role}
              </Typography>
              {group.countries && <CountryBadgesMerged countryCodes={group.countries} />}
            </Stack>
          )),
      label: t('permissions.permissions_groups'),
      baseWidth: '44%'
    }
  ];

  const twiceFilteredUsers = filteredUsers ? filterByText(filteredUsers) : undefined;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.topSection}>
          <h1>{t('users.manage_users')}</h1>
          <Stack direction="row" gap={4} alignItems="center">
            <TextField
              debounced
              clearable
              inputRef={searchRef}
              label={t('users.filter_by_text')}
              className={classes.textFilter}
              onChange={onFilterInputChange}
              onClear={clearFilter}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
              data-testid={testIds.textFilter}
            />
            <FormControl className={classes.groupFilter}>
              <InputLabel>{t('users.filter_by_group')}</InputLabel>
              <Select
                label={t('users.filter_by_group')}
                value={groupFilter}
                color={formControlColor}
                onChange={({ target: { value: group } }) => setGroupFilter(group)}
                SelectDisplayProps={{ style: { textTransform: 'capitalize' } }}
                data-testid={testIds.groupFilterSelect}
              >
                <MenuItem value={ALL} data-testid={testIds.groupFilterSelectItem} data-value={ALL}>
                  {t('filters.all')}
                </MenuItem>
                {Object.values(PermissionsGroupId).map((permissionsGroup) => (
                  <MenuItem
                    key={permissionsGroup}
                    value={permissionsGroup}
                    data-testid={testIds.groupFilterSelectItem}
                    data-value={permissionsGroup}
                  >
                    {permissionsGroup}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div>
              <Button
                className={classes.newUserButton}
                endIcon={<AddOutlined />}
                disabled={!users}
                onClick={() => useUsers.new()}
                data-testid={testIds.newUserButton}
              >
                {t('users.new_user')}
              </Button>
            </div>
          </Stack>
        </div>
        <UsersGrid users={twiceFilteredUsers} columns={gridColumns} />
      </div>
      <UserForm />
    </>
  );
}
