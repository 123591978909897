import {
  DocumentLocale,
  TournamentSplashHeroUiModuleBody,
  TournamentSplashHeroUiModuleBodyModuleTypeEnum
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankTournamentSplashHeroModule: NewModuleFunction<TournamentSplashHeroUiModuleBody> = (module) => ({
  title: {
    [DocumentLocale.ES]: ''
  },
  moduleType: TournamentSplashHeroUiModuleBodyModuleTypeEnum.TOURNAMENT_SPLASH_HERO,
  moduleId: generateId(),
  tournamentId: '',
  tournamentReferenceId: '',
  ...module
});
