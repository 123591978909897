import React, { useEffect } from 'react';
import MetadataManager from './MetadataManager';
import { useStatsig } from '../../hooks';
import { StatsigGates } from '../../utils/consts/statsigGates';
import { appRouteBasePath } from '../../Routes';

export default function MetadataManagerPage(): JSX.Element | null {
  const { CheckGate } = useStatsig();
  const isReverseChronActive = CheckGate(StatsigGates.reverseChronologicalActive);

  useEffect(() => {
    if (!isReverseChronActive) {
      window.location.href = appRouteBasePath;
    }
  }, []);

  return isReverseChronActive ? <MetadataManager /> : null;
}
