import { atom, RecoilState } from 'recoil';
import { PromotionBody, PromotionResponse, PromotionTypeResponse } from '../../../../API';
import { DataManagerState, DataManagerStateFullReturnType } from '../../../DataManager';

export interface PromotionsStateFullReturnType
  extends DataManagerStateFullReturnType<PromotionResponse, PromotionBody> {
  withPromotionTypes: RecoilState<PromotionTypeResponse[]>;
}

export function PromotionsState(name: string): PromotionsStateFullReturnType {
  const genericState = DataManagerState<PromotionResponse, PromotionBody>(name);

  const withPromotionTypes = atom<PromotionTypeResponse[]>({
    key: `${name}.promotionTypes`,
    default: []
  });

  return {
    ...genericState,
    withPromotionTypes
  };
}
