import React, { forwardRef, useEffect, useState } from 'react';
import { FormControlLabel, InputLabel, MenuItem, Switch, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import {
  MediaResponse,
  SportsEventUiModuleBody,
  SportsEventUIModuleContentTreatment,
  UiModuleSportsEventType
} from '../../../../../API';
import { useData } from '../../../../../data-layer';
import { useLocales, useMatches, useTheme } from '../../../../../hooks';
import FormControl from '../../../../shared/FormControl';
import { TransferList } from '../../../../shared/TransferList';
import { MatchListItem } from '../../../../Sports/MatchListItem';
import { FormBody, StyledSelect } from '../../styles';
import TreatmentPicker from '../../TreatmentPicker';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { SportsEventFilters } from './SportsEventFilters';
import { SportOptionsMultiselect, testIds as somTestIds } from './SportsOptionsMultiselect';
import { withSelectedLayoutCountries } from '../../../../../state/Layouts';
import { isDynamicLeaguePage, LEAGUE_ID_PARAM } from '../../../../../utils/consts/uiModules';

const useStyles = makeStyles()((theme) => ({
  inlineFields: {
    display: 'flex',
    gap: theme.spacing(6),
    alignItems: 'center'
  },
  matchListItem: {
    padding: theme.spacing(1, 0)
  },
  curatedContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    flexDirection: 'column'
  },
  sportsEventFilterContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    '& > *': {
      flexGrow: 1,
      flexBasis: 1
    }
  }
}));

export const testIds = {
  formBody: 'sports-event-form.form-body',
  eventTypeSelect: 'sports-event-form.event-type-select',
  eventTypeItem: 'sports-event-form.event-type-item',
  ...somTestIds
};

const SportsEventForm = forwardRef<UIModuleTypeForm<SportsEventUiModuleBody>>(() => {
  const { classes } = useStyles();
  const { control, watch, setValue } = useFormContext<SportsEventUiModuleBody>();
  const { t, localize } = useLocales();
  const { formControlColor } = useTheme();
  const {
    sports: {
      state: { withAllRecords: withAllSports },
      hook: { getAll: getAllSports }
    },
    tournaments: {
      state: { withAllRecords: withAllTournaments },
      hook: { getAll: getAllTournaments }
    },
    pages: {
      state: { withSelectedId: withSelectedPagePath }
    }
  } = useData();

  const [sportsEventType, contents, sportIds, leagueIds] = watch([
    'sportsEventType',
    'contents',
    'sportIds',
    'leagueIds'
  ]);

  const currentPagePath = useRecoilValue(withSelectedPagePath);
  const shouldShowDynamicLeague = isDynamicLeaguePage(currentPagePath);

  const [selectedSportFilter, setSelectedSportFilter] = useState('');
  const [selectedLeagueFilter, setSelectedLeagueFilter] = useState('');

  const [availableMatches, setAvailableMatches] = useState([] as MediaResponse[]);
  const [selectedMatches, setSelectedMatches] = useState([] as MediaResponse[]);
  const { getMatches, getMatchesByIds } = useMatches();
  const [isLoading, setIsLoading] = useState(false);

  const sports = useRecoilValue(withAllSports);
  const leagues = useRecoilValue(withAllTournaments);
  const leagueIdParamIsSelected = leagueIds?.[0] === LEAGUE_ID_PARAM;
  const countries = useRecoilValue(withSelectedLayoutCountries);

  // Initialize sports and tourneys on mount
  useEffect(() => {
    if (!sports) getAllSports();
    if (!leagues) getAllTournaments();
  }, []);

  useEffect(() => {
    if (sportsEventType === UiModuleSportsEventType.CURATED) {
      fetchMatches(false);
    }
  }, [selectedSportFilter, selectedLeagueFilter]);

  // Fetching the sports events
  useEffect(() => {
    if (sportsEventType === UiModuleSportsEventType.CURATED) {
      fetchMatches(true);
    } else {
      updateContent(undefined);
    }
  }, [sportsEventType]);

  const fetchMatches = async (filterUnavailableMatches: boolean) => {
    setIsLoading(true);
    const matches = await getMatches(UiModuleSportsEventType.LIVE_AND_UPCOMING, {
      countries: countries,
      leagueIds: selectedLeagueFilter,
      sportIds: selectedSportFilter
    });
    setAvailableMatches(matches || []);
    if (filterUnavailableMatches) {
      await populateSelectedMatches(matches);
    }
    setIsLoading(false);
  };

  const populateSelectedMatches = async (matches: MediaResponse[] | undefined) => {
    if (!matches || !contents || !contents?.filter(Boolean)) return;
    const matchesHash: Record<string, MediaResponse> = matches.reduce(
      (acc, match) => ({ ...acc, [match.contentId]: match }),
      {}
    );
    // Filter for any matches that aren't fetched already
    const matchIdsToFetch = contents.filter((id) => !matchesHash[id]);
    if (matchIdsToFetch.length) {
      const fetchedMatches = await getMatchesByIds(matchIdsToFetch);
      if (fetchedMatches) {
        fetchedMatches.forEach((match) => (matchesHash[match.contentId] = match));
      }
    }
    setSelectedMatches(contents.map((id) => matchesHash[id]).filter(Boolean));
  };

  const setSelectedMatchesFromTransferList = (matches: MediaResponse[]) => {
    setSelectedMatches(matches);
    updateContent(matches);
  };

  const updateContent = (matches: MediaResponse[] | undefined) => {
    setValue('contents', matches && matches.map((match) => match.contentId));
  };

  const handleSwitchLeagueIdParam = (value: boolean) => {
    setValue('sportIds', []);
    setValue('leagueIds', value ? [LEAGUE_ID_PARAM] : []);
  };

  return (
    <FormBody data-testid={testIds.formBody}>
      <div className={classes.inlineFields}>
        <FormControl>
          <InputLabel id="typeSelector">{t('layouts.sports_event_type')}</InputLabel>
          <Controller
            control={control}
            name="sportsEventType"
            render={({ field: { value, onChange } }) => (
              <StyledSelect
                label={t('layouts.sports_event_type')}
                labelId="typeSelector"
                value={value}
                color={formControlColor}
                onChange={onChange}
                data-testid={testIds.eventTypeSelect}
              >
                {Object.values(UiModuleSportsEventType).map((value) => (
                  <MenuItem key={value} value={value} data-testid={testIds.eventTypeItem} data-type={value}>
                    {value}
                  </MenuItem>
                ))}
              </StyledSelect>
            )}
          />
        </FormControl>
        <Controller
          control={control}
          name="contentTreatment"
          render={({ field: { value, onChange } }) => (
            <TreatmentPicker
              options={SportsEventUIModuleContentTreatment}
              value={value}
              onChange={(value) => onChange(value as SportsEventUIModuleContentTreatment)}
            />
          )}
        />
      </div>
      {sportsEventType !== UiModuleSportsEventType.CURATED && (
        <>
          {shouldShowDynamicLeague && (
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={leagueIdParamIsSelected}
                    color={formControlColor}
                    onChange={(_, val: boolean) => handleSwitchLeagueIdParam(val)}
                  />
                }
                label={t('layouts.use_dynamic_league_id_param')}
              />
            </FormControl>
          )}
          {!leagueIdParamIsSelected && (
            <SportOptionsMultiselect
              sports={sportIds}
              leagues={leagueIds}
              onSportsChange={(sports) => setValue('sportIds', sports)}
              onLeaguesChange={(leagues) => setValue('leagueIds', leagues)}
            />
          )}
        </>
      )}
      {sportsEventType === UiModuleSportsEventType.CURATED && (
        <div className={classes.curatedContainer}>
          <div className={classes.sportsEventFilterContainer}>
            <div>
              <Typography variant="h6">{t('matches.add_matches')}</Typography>
              <Typography variant="body2">{t('matches.select_and_sort_matches')}</Typography>
            </div>
            <SportsEventFilters onSportIdChange={setSelectedSportFilter} onLeagueIdChange={setSelectedLeagueFilter} />
          </div>
          <TransferList
            leftTitle={
              <Typography variant="body2" color="textSecondary">
                {t('matches.selected_matches')}
              </Typography>
            }
            rightTitle={
              <Typography variant="body2" color="textSecondary">
                {t('matches.available_matches')}
              </Typography>
            }
            leftList={selectedMatches}
            setLeftList={setSelectedMatchesFromTransferList}
            rightList={availableMatches}
            isLoading={isLoading}
            skeletonNumber={30}
            keyExtractor={(match) => match.contentId}
            renderChild={(match) => (
              <MatchListItem className={classes.matchListItem} match={match} showLogos={false} asList />
            )}
            searchFn={(text, match) => localize(match.title).toLowerCase().search(text.toLowerCase()) !== -1}
          />
        </div>
      )}
    </FormBody>
  );
});

SportsEventForm.displayName = 'SportsEventForm';

export default SportsEventForm;
