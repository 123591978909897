import React, { forwardRef, useRef } from 'react';
import { AppBar, Button, lighten, Popover, Toolbar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useLayoutsRoute, useLocales, useStatsig } from '../../../hooks';
import { AppRoutes } from '../../../Routes';
import { themeColors } from '../../../theme';
import { VixCmsLogo } from '../../shared/VixCmsLogo';
import LanguagePopover from './LanguagePopover';
import NavBarMenu from './NavBarMenu';
import Notifications from './NotificationDrawer';
import TimezonePopover from './TimezonePopover';
import { LayoutType } from '../../../API';
import { startCase } from 'lodash-es';
import { usePermissionsGuard } from '../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../state/theme';
import { StatsigGates } from '../../../utils/consts/statsigGates';

export const navBarHeight = 58;
const popoverButtonHeight = 50;
const selectedNavFlareHeight = 4;

const useStyles = makeStyles<void, 'navButton' | 'navButtonActive'>()((theme, params, classes) => ({
  leftSide: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    gap: theme.spacing(4)
  },
  rightSide: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  toolbar: {
    [theme.breakpoints.up(0)]: {
      height: navBarHeight,
      minHeight: navBarHeight
    },
    background: themeColors.navbar,
    color: theme.palette.getContrastText(themeColors.navbar)
  },
  navButtonContainer: {
    height: '100%'
  },
  navButton: {
    height: '100%',
    borderRadius: 0,
    color: theme.palette.getContrastText(themeColors.navbar),
    padding: theme.spacing(0, 3),
    position: 'relative',
    overflow: 'hidden',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      height: selectedNavFlareHeight,
      left: 0,
      right: 0,
      borderTopRightRadius: selectedNavFlareHeight,
      borderTopLeftRadius: selectedNavFlareHeight,
      backgroundColor: themeColors.orange,
      transform: `translateY(${selectedNavFlareHeight}px)`,
      transition: 'transform 0.3s ease-in-out'
    },
    '&.Mui-disabled': {
      color: themeColors.dark.buttonGrey
    }
  },
  navButtonActive: {
    color: lighten(themeColors.orange, 0.3),
    backgroundColor: 'rgba(255,255,255,0.03)',
    '&::after': {
      transform: 'translateY(0)'
    }
  },
  popoverWrapper: {
    pointerEvents: 'none',
    '& .MuiPaper-root': {
      backgroundColor: themeColors.navbar
    }
  },
  popoverContainer: {
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: 'auto',
    [`& .${classes.navButton}, & .${classes.navButtonActive}`]: {
      height: `${popoverButtonHeight}px !important`,
      textAlign: 'left',
      '&::after': {
        display: 'none'
      }
    }
  }
}));

export const testIds = {
  navBar: 'navbar.navbar',
  epgGroup: 'navbar.epg-link',
  epgProgramming: 'navbar.epg-programming',
  epgReport: 'navbar.epg-report',
  collectionsGroup: 'navbar.collections-link',
  collections: 'navbar.collections',
  hpcCollections: 'navbar.hpc-collections',
  vodCollections: 'navbar.vod-collections',
  layoutsLink: 'navbar.layouts-link',
  sportsLink: 'navbar.sports-link',
  paymentsLink: 'navbar.payments-link',
  metadataLink: 'navbar.metadata-link'
};

interface NavButtonProps {
  to: string;
  label: string;
  disabled?: boolean;
  'data-testid'?: string;
}

interface NavButtonGroupProps extends NavButtonProps {
  children: React.ReactNode;
}

function NavBar(): JSX.Element {
  const { classes, cx } = useStyles();
  const { t } = useLocales();
  const { getLayoutsRoute } = useLayoutsRoute();
  const { CheckGate } = useStatsig();
  const isReverseChronActive = CheckGate(StatsigGates.reverseChronologicalActive);

  const { canRead: canReadSports } = usePermissionsGuard({
    homepageOption: HomepageOptions.SPORTS
  });

  const { canRead: canReadMonetization } = usePermissionsGuard({
    homepageOption: HomepageOptions.MONETIZATION
  });

  const { canRead: canReadLayouts } = usePermissionsGuard({
    homepageOption: HomepageOptions.LAYOUTS
  });

  const { canRead: canReadCollections } = usePermissionsGuard({
    homepageOption: HomepageOptions.COLLECTIONS
  });

  const { canRead: canReadEpg } = usePermissionsGuard({
    homepageOption: HomepageOptions.EPG
  });

  const { canRead: canReadHpc } = usePermissionsGuard({
    homepageOption: HomepageOptions.HPC
  });

  const { canRead: canReadMetadata } = usePermissionsGuard({
    homepageOption: HomepageOptions.METADATA
  });

  const NavButton = ({ to, label, disabled, ...props }: NavButtonProps) => (
    <Button
      variant="text"
      color="inherit"
      className={classes.navButton}
      component={CustomNavLink}
      to={to}
      data-testid={props['data-testid']}
      disabled={disabled}
    >
      {label}
    </Button>
  );

  const NavButtonGroup = ({ to, label, disabled, children, ...props }: NavButtonGroupProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const closePopoverRef = useRef<NodeJS.Timeout>();

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      clearTimeout(closePopoverRef.current);
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      closePopoverRef.current = setTimeout(() => {
        setAnchorEl(null);
      }, 100);
    };

    const onPopoverMouseOver = () => {
      clearTimeout(closePopoverRef.current);
    };

    const onPopoverMouseOut = () => {
      handlePopoverClose();
    };

    const isPopoverOpen = Boolean(anchorEl);

    return (
      <>
        <div className={classes.navButtonContainer} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
          <NavButton to={to} data-testid={props['data-testid']} label={label} disabled={disabled} />
        </div>
        {!disabled && (
          <Popover
            open={isPopoverOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            onClose={handlePopoverClose}
            className={classes.popoverWrapper}
          >
            <div
              className={classes.popoverContainer}
              onMouseEnter={onPopoverMouseOver}
              onMouseLeave={onPopoverMouseOut}
              onClick={onPopoverMouseOut}
            >
              {children}
            </div>
          </Popover>
        )}
      </>
    );
  };

  const CustomNavLink = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => (
    <NavLink
      ref={ref}
      {...props}
      className={({ isActive }) => cx(props.className as string, { [classes.navButtonActive]: isActive })}
    >
      {props.children}
    </NavLink>
  ));
  CustomNavLink.displayName = 'CustomNavLink';

  return (
    <>
      <AppBar position="relative" elevation={0} data-testid={testIds.navBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.leftSide}>
            <VixCmsLogo />
            <NavButtonGroup to={AppRoutes.epgBase} data-testid={testIds.epgGroup} label="EPG" disabled={!canReadEpg}>
              <NavButton
                to={AppRoutes.epg()}
                data-testid={testIds.epgProgramming}
                disabled={!canReadEpg}
                label={t('epg.epg_programming')}
              />
              <NavButton
                to={AppRoutes.epgReport}
                data-testid={testIds.epgReport}
                disabled={!canReadEpg}
                label={t('epg.channel_grid')}
              />
            </NavButtonGroup>
            {isReverseChronActive && (
              <NavButton
                to={AppRoutes.metadataManager}
                data-testid={testIds.metadataLink}
                label={t('metadata.metadata')}
                disabled={!canReadMetadata}
              />
            )}
            <NavButtonGroup
              to={AppRoutes.collectionsBase}
              data-testid={testIds.collectionsGroup}
              label={t('collections.collections')}
              disabled={!canReadCollections}
            >
              <NavButton
                to={AppRoutes.collections}
                data-testid={testIds.collections}
                label={`${t('collections.mixed')}`}
                disabled={!canReadCollections}
              />
              <NavButton
                to={AppRoutes.heroPresetCollections()}
                data-testid={testIds.hpcCollections}
                label={t('hpc.hero_presets')}
                disabled={!canReadHpc}
              />
              <NavButton
                to={AppRoutes.vodCollections}
                data-testid={testIds.vodCollections}
                label={`${t('collections.vod_collections')}`}
                disabled={!canReadCollections}
              />
            </NavButtonGroup>
            <NavButtonGroup
              to={getLayoutsRoute()}
              data-testid={testIds.layoutsLink}
              disabled={!canReadLayouts}
              label={t('layouts.layouts')}
            >
              {Object.values(LayoutType).map((value) => (
                <NavButton
                  key={value}
                  to={getLayoutsRoute(value)}
                  label={`${startCase(value.toLowerCase())}s`.replace(/ss$/, 's')}
                  disabled={!canReadLayouts}
                />
              ))}
            </NavButtonGroup>
            <NavButton
              to={AppRoutes.sports}
              data-testid={testIds.sportsLink}
              label={t('sports.sports')}
              disabled={!canReadSports}
            />
            <NavButtonGroup
              to={AppRoutes.payments}
              data-testid={testIds.paymentsLink}
              label={t('payments.monetization')}
              disabled={!canReadMonetization}
            >
              <NavButton to={AppRoutes.paymentsPromotions} label={t('payments.promotions')} />
              <NavButton to={AppRoutes.paymentsPlans} label={t('payments.plans')} />
              <NavButton to={AppRoutes.paymentsCoupons} label={t('payments.coupons')} />
              <NavButton to={AppRoutes.paymentsOfferMappings} label={t('payments.offer_mappings')} />
              {/* Hiding this temporarily */}
              {/* <NavButton to={AppRoutes.paymentsDefaults} label={t('payments.default_plan_groups')} /> */}
            </NavButtonGroup>
          </div>
          <div className={classes.rightSide}>
            <TimezonePopover />
            <LanguagePopover />
            <Notifications />
            <NavBarMenu />
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default NavBar;
