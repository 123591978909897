import { capitalize } from 'lodash-es';
import React from 'react';
import { EntitlementType } from '../../../API';
import { useLocales } from '../../../hooks';

const totalNumEntitlements = Object.keys(EntitlementType).length;

export function EntitlementsShorthand({ entitlements }: { entitlements: EntitlementType[] }): React.ReactElement {
  const { t } = useLocales();

  if (entitlements.length === totalNumEntitlements) return <>{t('filters.all')}</>;

  return <>{entitlements.map((entitlement) => capitalize(entitlement.toLowerCase())).join(', ')}</>;
}
