import type { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import type { FlattenedItem, TreeItem } from './SortableTreeTypes';

function getDragDepth(offset: number, indentationWidth: number) {
  return Math.round(offset / indentationWidth);
}

export function getProjection(
  items: FlattenedItem<unknown>[],
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier,
  dragOffset: number,
  indentationWidth: number,
  maxTreeDepth: number
): { depth: number; maxDepth: number; minDepth: number; parentId: UniqueIdentifier | null } {
  const overItemIndex = items.findIndex(({ id }) => id === overId);
  const activeItemIndex = items.findIndex(({ id }) => id === activeId);
  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1];
  const nextItem = newItems[overItemIndex + 1];
  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const projectedDepth = activeItem.depth + dragDepth;
  const maxDepth = getMaxDepth({
    previousItem,
    maxTreeDepth
  });
  const minDepth = getMinDepth({ nextItem });
  let depth = projectedDepth;

  if (projectedDepth >= maxDepth) {
    depth = maxDepth;
  } else if (projectedDepth < minDepth) {
    depth = minDepth;
  }

  return { depth, maxDepth, minDepth, parentId: getParentId() };

  function getParentId() {
    if (depth === 0 || !previousItem) {
      return null;
    }

    if (depth === previousItem.depth) {
      return previousItem.parentId;
    }

    if (depth > previousItem.depth) {
      return previousItem.id;
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.parentId;

    return newParent ?? null;
  }
}

function getMaxDepth({ previousItem, maxTreeDepth }: { previousItem: FlattenedItem<unknown>; maxTreeDepth: number }) {
  if (previousItem) {
    return Math.min(maxTreeDepth, previousItem.depth + 1);
  }

  return 0;
}

function getMinDepth({ nextItem }: { nextItem: FlattenedItem<unknown> }) {
  if (nextItem) {
    return nextItem.depth;
  }

  return 0;
}

function flatten<T = unknown>(
  items: TreeItem<T>[],
  parentId: UniqueIdentifier | null = null,
  parentIndex: number | null = null,
  depth = 0
): FlattenedItem<T>[] {
  return items.reduce<FlattenedItem<T>[]>((acc, item, index) => {
    return [
      ...acc,
      { ...item, parentId, depth, index, parentIndex },
      ...flatten(item.children, item.id, index, depth + 1)
    ];
  }, []);
}

export function flattenTree<T = unknown>(items: TreeItem<T>[]): FlattenedItem<T>[] {
  return flatten(items);
}

function flattenToMaxDepth<T = unknown>(
  nodes: TreeItem<T>[],
  currentDepth: number,
  maxTreeDepth: number
): TreeItem<T>[] {
  const newNodes: TreeItem<T>[] = [];
  nodes.forEach((node) => {
    if (node.children.length) {
      if (currentDepth >= maxTreeDepth) {
        newNodes.push(
          ...flattenToMaxDepth([{ ...node, children: [] }, ...node.children], currentDepth + 1, maxTreeDepth)
        );
      } else {
        newNodes.push({
          ...node,
          children: flattenToMaxDepth(node.children, currentDepth + 1, maxTreeDepth)
        });
      }
    } else {
      newNodes.push(node);
    }
  });

  return newNodes;
}

export function buildTree<T = unknown>(flattenedItems: FlattenedItem<T>[], maxTreeDepth: number): TreeItem<T>[] {
  const root: TreeItem<T> = { id: 'root', children: [], object: {} as T };
  const nodes: Record<string, TreeItem<T>> = { [root.id]: root };
  const items = flattenedItems.map((item) => ({ ...item, children: [] }));

  for (const item of items) {
    const { id, children, object } = item;
    const parentId = item.parentId ?? root.id;
    const parent = nodes[parentId] ?? findItem(items, parentId);
    nodes[id] = { id, children, object };
    parent.children.push(item);
  }

  return flattenToMaxDepth(root.children, 0, maxTreeDepth);
}

export function findItem(items: TreeItem<unknown>[], itemId: UniqueIdentifier): TreeItem<unknown> | undefined {
  return items.find(({ id }) => id === itemId);
}

export function findItemDeep(items: TreeItem<unknown>[], itemId: UniqueIdentifier): TreeItem<unknown> | undefined {
  for (const item of items) {
    const { id, children } = item;

    if (id === itemId) {
      return item;
    }

    if (children.length) {
      const child = findItemDeep(children, itemId);

      if (child) {
        return child;
      }
    }
  }

  return undefined;
}

export function removeItem<T = unknown>(items: TreeItem<T>[], id: UniqueIdentifier): TreeItem<T>[] {
  const newItems = [];

  for (const item of items) {
    if (item.id === id) {
      continue;
    }

    if (item.children.length) {
      item.children = removeItem(item.children, id);
    }

    newItems.push(item);
  }

  return newItems;
}

export function setProperty<T extends keyof TreeItem<unknown>>(
  items: TreeItem<unknown>[],
  id: UniqueIdentifier,
  property: T,
  setter: (value: TreeItem<unknown>[T]) => TreeItem<unknown>[T]
): TreeItem<unknown>[] {
  for (const item of items) {
    if (item.id === id) {
      item[property] = setter(item[property]);
      continue;
    }

    if (item.children.length) {
      item.children = setProperty(item.children, id, property, setter);
    }
  }

  return [...items];
}

function countChildren(items: TreeItem<unknown>[], count = 0): number {
  return items.reduce((acc, { children }) => {
    if (children.length) {
      return countChildren(children, acc + 1);
    }

    return acc + 1;
  }, count);
}

export function getChildCount(items: TreeItem<unknown>[], id: UniqueIdentifier): number {
  const item = findItemDeep(items, id);

  return item ? countChildren(item.children) : 0;
}

export function removeChildrenOf<T = unknown>(items: FlattenedItem<T>[], ids: UniqueIdentifier[]): FlattenedItem<T>[] {
  const excludeParentIds = [...ids];

  return items.filter((item) => {
    if (item.parentId && excludeParentIds.includes(item.parentId)) {
      if (item.children.length) {
        excludeParentIds.push(item.id);
      }
      return false;
    }

    return true;
  });
}
