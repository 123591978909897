import React, { useState } from 'react';
import { emphasize } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Button from '../../../components/shared/Button';
import { useAuth, useLocales, useNotifications } from '../../../hooks';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider, UserCredential } from 'firebase/auth';
import firebaseConfig from '../../../common/config/firebaseConfig.json';
import Login from '..';

const useStyles = makeStyles()((theme) => ({
  loginButton: {
    color: `${theme.palette.getContrastText(theme.palette.background.paper)} !important`,
    padding: theme.spacing(4, 5),
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    '&:hover': {
      color: `${emphasize(theme.palette.getContrastText(theme.palette.background.paper), 0.3)} !important`
    }
  },
  p3Logo: {
    height: 16,
    marginRight: theme.spacing(2)
  }
}));

export const testIds = {
  loginButton: 'login.login-button'
};

export default function LoginForm(): JSX.Element {
  const { classes } = useStyles();
  const { login } = useAuth();
  const [isSubmitting, setSubmitting] = useState(false);
  const { notifyError } = useNotifications();
  const { t } = useLocales();

  const handleLoginRequest = async () => {
    setSubmitting(true);
    const app = initializeApp(firebaseConfig);
    const provider = new GoogleAuthProvider();
    const auth = getAuth(app);
    try {
      const signInResult: UserCredential = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(signInResult);
      handleLoginSuccess(signInResult.user.email || '', signInResult.user.photoURL || '', credential?.idToken);
    } catch (error: unknown) {
      handleLoginFailure([(error as Error).message]);
    }
  };

  const handleLoginSuccess = async (email: string, photoURL: string, token?: string) => {
    if (!token || !email) {
      handleLoginFailure();
      return;
    }

    await login({
      email,
      photoURL,
      token
    });
    setSubmitting(false);
  };

  const handleLoginFailure = (errorDetails?: string | string[]) => {
    notifyError(t('auth.login_error'), errorDetails);
    setSubmitting(false);
  };

  return (
    <Login>
      <Button
        fullWidth
        className={classes.loginButton}
        variant="text"
        loading={isSubmitting}
        disabled={isSubmitting}
        onClick={handleLoginRequest}
        data-testid={testIds.loginButton}
      >
        <img src="/assets/google-logo.svg" className={classes.p3Logo} />
        {t('auth.login_button_label')}
      </Button>
    </Login>
  );
}
