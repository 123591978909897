import React, { ReactElement, useState } from 'react';
import { useData } from '../../../../data-layer';
import { useLocales, useTheme } from '../../../../hooks';
import { Download } from '@mui/icons-material';
import Button from '../../../shared/Button';
import { IButtonProps } from '../../../shared/Button/Button';

export function EPGChannelsDownload({ variant, color }: Pick<IButtonProps, 'variant' | 'color'>): ReactElement {
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    channels: {
      hook: { exportChannelsToCSV }
    }
  } = useData();

  const handleExport = async () => {
    setIsLoading(true);
    await exportChannelsToCSV();
    setIsLoading(false);
  };

  return (
    <Button
      onClick={handleExport}
      loading={isLoading}
      size="small"
      variant={variant || 'text'}
      color={color || formControlColor}
      endIcon={<Download />}
    >
      {t('epg.channels.download_csv')}
    </Button>
  );
}
