import React from 'react';
import { useRecoilValue } from 'recoil';
import { useData } from '../../../data-layer';
import { useLayoutsRouter, useLocales } from '../../../hooks';
import { withLayoutSelectedCountries } from '../../../state/Layouts';
import CountryPickerLite from '../../shared/CountryPickerLite';

export function LayoutsCountryFilter({ className }: { className?: string }): React.ReactElement {
  const { t } = useLocales();
  const { updateLayoutsRoute } = useLayoutsRouter();
  const countryFilter = useRecoilValue(withLayoutSelectedCountries);

  const {
    pages: {
      state: { withIsFetching }
    }
  } = useData();
  const isFetchingPages = useRecoilValue(withIsFetching);

  const onCountryFilterChange = (countriesArray: string[]) => {
    const countries = countriesArray.join('-');
    updateLayoutsRoute({ countries });
  };

  return (
    <CountryPickerLite
      label={t('country_picker.filter_by_countries')}
      className={className}
      disabled={isFetchingPages}
      value={countryFilter}
      onChange={onCountryFilterChange}
      disableClearable
    />
  );
}
