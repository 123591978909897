import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Download } from '@mui/icons-material';
import Button from '../../shared/Button';
import Modal from '../../shared/Modal';
import { DatePicker } from '@mui/x-date-pickers';
import { useLayouts, useLocales, useTheme } from '../../../hooks';
import { DateTime } from 'luxon';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

export const testIds = {
  root: 'layouts-export-modal.root',
  date: 'layouts-export-modal.date',
  cancelButton: 'layouts-export-modal.cancel-button',
  downloadButton: 'layouts-export-modal.download-button'
};

type LayoutsExportModalProps = {
  open?: boolean;
  onClose?: () => unknown;
};

type LayoutsCsvForm = {
  date: DateTime;
};

// CSV generation is around 1 AM UTC, adjusting for potential generation time.
const MAX_DATE = DateTime.utc().startOf('day').plus({ day: -1, hour: 1 });

const useStyles = makeStyles()(() => ({
  modal: {
    width: 'auto',
    height: 'auto'
  }
}));

export function LayoutsExportModal({ open = false, onClose }: LayoutsExportModalProps): React.ReactElement {
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { exportLayoutsCsv } = useLayouts();
  const { classes } = useStyles();

  const { control, handleSubmit } = useForm<LayoutsCsvForm>({
    defaultValues: {
      date: MAX_DATE
    }
  });
  const [isDownloading, setIsDownloading] = useState(false);

  const onSubmit: SubmitHandler<LayoutsCsvForm> = async ({ date }) => {
    setIsDownloading(true);
    await exportLayoutsCsv(date.toISODate() || '');
    setIsDownloading(false);
  };

  const headerLeft = <Typography variant="h5">{t('layouts.download_layouts')}</Typography>;

  const footerLeft = (
    <Button color="grey" onClick={onClose} data-testid={testIds.cancelButton}>
      {t('general.cancel')}
    </Button>
  );

  const footerRight = (
    <Button
      onClick={handleSubmit(onSubmit)}
      loading={isDownloading}
      startIcon={<Download />}
      data-testid={testIds.downloadButton}
    >
      {t('general.download_as_csv')}
    </Button>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      headerLeft={headerLeft}
      footerLeft={footerLeft}
      footerRight={footerRight}
      bodyClassName={classes.modal}
    >
      <form onSubmit={handleSubmit(onSubmit)} data-testid={testIds.root}>
        <Stack gap={4} margin={4}>
          <Controller
            control={control}
            name="date"
            rules={{ required: t('general.field_is_required') }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <DatePicker
                label={t('date_picker.date_label')}
                value={value}
                onChange={(value) => onChange(value as DateTime)}
                format="yyyy-MM-dd"
                maxDate={MAX_DATE}
                slotProps={{
                  textField(props) {
                    return {
                      color: formControlColor,
                      required: true,
                      error: props.error || !!error,
                      helperText: error?.message,
                      'data-testid': testIds.date
                    };
                  }
                }}
              />
            )}
          />
        </Stack>
      </form>
    </Modal>
  );
}
