/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum MenuIconName {
    HOME = 'HOME',
    CHANNELS = 'CHANNELS',
    ON_DEMAND = 'ON_DEMAND',
    MOVIES = 'MOVIES',
    SERIES = 'SERIES',
    NEWS = 'NEWS',
    TROPHY = 'TROPHY',
    SETTINGS = 'SETTINGS',
    USER = 'USER',
    KEBAB = 'KEBAB',
    SEARCH = 'SEARCH',
    VIX_PLUS = 'VIX_PLUS',
    DOWNLOADS = 'DOWNLOADS',
    KIDS = 'KIDS',
    NOVELAS = 'NOVELAS',
    PLUS_SYMBOL = 'PLUS_SYMBOL',
    PREMIUM = 'PREMIUM',
    VIX = 'VIX'
}



