import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { CouponDiscountType, CouponResponse } from '../../../../API';
import { useLocales } from '../../../../hooks';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2)
  }
}));

export interface ICouponDiscountProps {
  coupon: CouponResponse;
}

export const testIds = {
  componentRoot: 'coupon-discount.component-root'
};

export default function CouponDiscount({ coupon }: ICouponDiscountProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();

  return (
    <>
      {coupon.discount && (
        <div className={classes.root} data-testid={testIds.componentRoot}>
          {coupon.discount.type === CouponDiscountType.PERCENT && <div>{coupon.discount.percent}%</div>}
          {coupon.discount.type === CouponDiscountType.FIXED && (
            <>
              {coupon.discount.currencies?.map((currencyObj, i) => (
                <React.Fragment key={i}>
                  {!!currencyObj.amount && (
                    <div>
                      {currencyObj.amount} {currencyObj.currency}
                    </div>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
          {coupon.discount.type === CouponDiscountType.FREE_TRIAL && coupon.discount.trial && (
            <div>
              {t('payments.free_trial_for_x', { x: `${coupon.discount.trial.length} ${coupon.discount.trial.unit}` })}
            </div>
          )}
        </div>
      )}
    </>
  );
}
