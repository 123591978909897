import React, { useEffect, useState } from 'react';
import { default as MaterialModal } from '@mui/material/Modal';
import { Box, ModalProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CSSObject } from 'tss-react';
import { useGlobalHistoryManager } from '../../../hooks/General/useGlobalHistoryManager';
import { stopPointerPropagation } from '../../../utils/generalUtils';

export interface IModalProps extends ModalProps {
  bodyClassName?: string;
  width?: string | number;
  height?: string | number;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  footerLeft?: React.ReactNode;
  footerRight?: React.ReactNode;
}

export const bigSize: CSSObject = {
  width: '75vw',
  height: '85vh'
};

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'absolute',
    minWidth: 420,
    width: '62vw',
    height: '62vh',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[15]
  },
  header: {
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius
  },
  footer: {
    borderBottomRightRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius
  },
  headerFooter: {
    padding: theme.spacing(4),
    gap: theme.spacing(4),
    background: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  body: {
    height: '100%',
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    overflow: 'auto'
  }
}));

export function Modal({
  open,
  bodyClassName,
  headerLeft,
  headerRight,
  footerLeft,
  footerRight,
  width,
  height,
  children,
  ...props
}: IModalProps): React.ReactElement {
  const { classes, cx } = useStyles();
  const { block, unblock } = useGlobalHistoryManager('modal');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const newIsOpen = !!open;
    if (newIsOpen === isOpen) return;
    setIsOpen(newIsOpen);
    if (newIsOpen) {
      block();
    } else {
      unblock();
    }
  }, [open]);

  return (
    <MaterialModal open={open} {...props} {...stopPointerPropagation}>
      <Box className={cx(classes.root, bodyClassName)} width={width} height={height}>
        <div className={`${classes.header} ${classes.headerFooter}`}>
          <div>{headerLeft}</div>
          <div>{headerRight}</div>
        </div>
        <div className={classes.body}>{children}</div>
        <div className={`${classes.footer} ${classes.headerFooter}`}>
          <div>{footerLeft}</div>
          <div>{footerRight}</div>
        </div>
      </Box>
    </MaterialModal>
  );
}
