import React from 'react';
import MaterialFormControl, { FormControlProps } from '@mui/material/FormControl';
import { useTheme } from '../../../hooks';

export interface IFormControlProps extends FormControlProps {
  className?: string;
  children: React.ReactNode;
}

export function FormControl({ className = '', children, ...props }: IFormControlProps): React.ReactElement {
  const { formControlColor } = useTheme();

  return (
    <MaterialFormControl className={className} color={formControlColor} {...props}>
      {children}
    </MaterialFormControl>
  );
}
