import React from 'react';
import { Chip, SxProps, Theme } from '@mui/material';
import { useLocales } from '../../../hooks';

export interface IDraftBadgeProps {
  className?: string;
  'data-testid'?: string;
  sx?: SxProps<Theme>;
}

export function DraftBadge(props: IDraftBadgeProps): React.ReactElement {
  const { t } = useLocales();

  return (
    <Chip
      className={props.className}
      size="small"
      color="secondary"
      label={t('general.draft')}
      data-testid={props['data-testid']}
      sx={{ height: 19, ...props.sx }}
    />
  );
}
