import React from 'react';
import { DescriptionOutlined, SportsSoccerOutlined } from '@mui/icons-material';
import { Tab, Tabs, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useLocales, useTheme } from '../../hooks';
import { AppRoutes } from '../../Routes';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  tab: {
    flexDirection: 'row',
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
    alignItems: 'center',
    minHeight: 54,
    '& > svg': {
      margin: '0 !important'
    }
  },
  topSection: {
    padding: theme.spacing(3, 4, 0),
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}));

export const testIds = {
  root: 'config-panel.root',
  tabs: 'config-panel.tabs',
  sportsTab: 'config-panel.sports-tab',
  metaTab: 'config-panel.meta-tab'
};

function Config(): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const location = useLocation();

  return (
    <div data-testid={testIds.root} className={classes.root}>
      <div className={classes.topSection}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {t('general.cms_configuration')}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t('general.cms_configuration_summary')}
        </Typography>
        <Tabs
          data-testid={testIds.tabs}
          value={location.pathname}
          indicatorColor={formControlColor}
          textColor={formControlColor}
        >
          <Tab
            className={classes.tab}
            data-testid={testIds.metaTab}
            label={t('app_settings.site_meta_settings')}
            icon={<DescriptionOutlined fontSize="small" />}
            to={AppRoutes.configSeo}
            value={AppRoutes.configSeo}
            component={Link}
          />
          <Tab
            className={classes.tab}
            data-testid={testIds.sportsTab}
            label={t('app_settings.sports')}
            icon={<SportsSoccerOutlined fontSize="small" />}
            component={Link}
            to={AppRoutes.configSports}
            value={AppRoutes.configSports}
          />
        </Tabs>
      </div>
      <Outlet />
    </div>
  );
}

export default Config;
