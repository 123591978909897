import { DateTime } from 'luxon';
import { CollectionAssetType, MediaResponse, PublishWindowsDefault } from '../API';
import { CollectionsPanelTabViewOptions } from '../data-layer';

const DATE_FORMAT = 'LL/dd/yy';

export enum LicenseStatus {
  EXPIRES = 'expires',
  STARTS = 'starts',
  EXPIRED = 'expired'
}

export type LicenseType = {
  status: LicenseStatus;
  image: string;
  time?: string;
};

export const checkLicense = (publishWindowsDefault: PublishWindowsDefault | undefined): LicenseType | undefined => {
  if (!publishWindowsDefault || arePublishWindowsDefaultValuesEmpty(publishWindowsDefault)) {
    return;
  }

  const { startDate, endDate } = publishWindowsDefault;

  const MAX_DAYS = 30;
  const MIN_DAYS = 0;

  const rightNow = DateTime.now();

  const isMediaAfterNow = startDate && rightNow < DateTime.fromISO(startDate);

  if (isMediaAfterNow) {
    return {
      time: DateTime.fromISO(startDate).toFormat(DATE_FORMAT),
      image: '/assets/movie_clock.svg',
      status: LicenseStatus.STARTS
    };
  }

  const hasExpired = endDate && rightNow >= DateTime.fromISO(endDate);

  if (hasExpired) {
    return {
      image: '/assets/movie_lock.svg',
      status: LicenseStatus.EXPIRED,
      time: DateTime.fromISO(endDate).toFormat(DATE_FORMAT)
    };
  }

  if (endDate) {
    const { days } = DateTime.fromISO(endDate).diffNow('days');
    const willExpireSoon = days >= MIN_DAYS && days <= MAX_DAYS;

    if (willExpireSoon) {
      return {
        time: DateTime.fromISO(endDate).toFormat(DATE_FORMAT),
        image: '/assets/movie_alert.svg',
        status: LicenseStatus.EXPIRES
      };
    }
  }
};

export const getMcpUrl = (media: MediaResponse): string => {
  const [endpoint, queryParams] = isSeries(media.contentId)
    ? ['editprogram', '']
    : ['editvideo', '?tab=media_metadata'];
  return `https://uni-mcp.mp.lura.live/cms/${endpoint}/${media.contentId.split(':').pop() + queryParams}`;
};

export const isChannel = (contentId: string): boolean => {
  return /^channel/.test(contentId);
};

export const isSeries = (contentId: string): boolean => {
  return /^series/.test(contentId);
};

export const isMatch = (contentId: string): boolean => {
  return /^transmission:matchid/.test(contentId);
};

export const isOneBrandPromo = (contentId: string): boolean => {
  return contentId.startsWith('/promo/');
};

export const shouldRenderContentAlerts = (content: MediaResponse | undefined): boolean => {
  return !!content && isSeries(content.contentId);
};

export const arePublishWindowsDefaultValuesEmpty = (publishWindowsDefault: PublishWindowsDefault): boolean => {
  const values = Object.values(publishWindowsDefault);
  return !values.some((value) => value !== null);
};

export const getAssetTypeByTab = (tab: CollectionsPanelTabViewOptions): CollectionAssetType | null => {
  switch (tab) {
    case CollectionsPanelTabViewOptions.CONTENT_SEARCH:
    case CollectionsPanelTabViewOptions.VOD_SEARCH:
      return CollectionAssetType.CONTENT;
    case CollectionsPanelTabViewOptions.LIVE_SEARCH:
      return CollectionAssetType.LIVE_EVENT;
    case CollectionsPanelTabViewOptions.CHANNEL_SEARCH:
      return CollectionAssetType.CHANNEL;
    default:
      return null;
  }
};
