import { DateTime } from 'luxon';
import { atom, RecoilState, RecoilValueReadOnly, selector, selectorFamily } from 'recoil';
import { WithFilters } from '../../components/shared/SearchFilters';
import { localAtom } from '../../state/localStorageState';

export type DataCacheRecord<R> = Record<string, CachedRecord<R>>;

export interface DataManagerStateFullReturnType<R, B = R> {
  withRecordById: (recordId: string | undefined) => RecoilValueReadOnly<R | undefined>;
  withLatestPublishedRecordById: (recordId: string | undefined) => RecoilValueReadOnly<R | undefined>;
  withDataCache: RecoilState<DataCacheRecord<R>>;
  withLatestPublishedCache: RecoilState<DataCacheRecord<R>>;
  withAllRecords: RecoilValueReadOnly<R[] | undefined>;
  withAllRecordsIncludingDeleted: RecoilValueReadOnly<R[] | undefined>;
  withAllRecordIds: RecoilState<CachedRecord<string[]> | undefined>;
  withAllRecordsById: RecoilValueReadOnly<Record<string, R>>;
  withRecordBucketsById: RecoilState<Record<string, CachedRecord<string[] | undefined>>>;
  withRecordBucket: (bucketId: string) => RecoilValueReadOnly<R[] | undefined>;
  withSelectedId: RecoilState<string>;
  withSelected: RecoilState<R | undefined>;
  withFormMetadata: RecoilState<FormMetadata<R, B>>;
  withIsFetching: RecoilState<boolean>;
  withIsSaving: RecoilState<boolean>;
  withIsPublishing: RecoilState<boolean>;
  withIsDeleting: RecoilState<boolean>;
  withIsValidatingDeletion: RecoilState<boolean>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  withSearchParams: RecoilState<WithFilters<SearchParams, any>>;
}

export type CachedRecord<R> = {
  lastUpdated: DateTime;
  object: R;
};

export type SearchParams = { limit?: number; page?: number } & Record<string, unknown>;

export type FormMetadata<R, B = R> = {
  isShowingForm: boolean;
  isNew?: boolean;
  isEditing?: boolean;
  isCloning?: boolean;
  record: R | B | undefined;
  cloningRecord?: R | B | undefined;
};

export function DataManagerState<R, B = R>(name: string): DataManagerStateFullReturnType<R, B> {
  const withRecordById = selectorFamily<R | undefined, string | undefined>({
    key: `${name}.recordCache`,
    get: (id) => {
      return ({ get }) => (id ? get(withDataCache)[id]?.object : undefined);
    }
  });

  const withLatestPublishedRecordById = selectorFamily<R | undefined, string | undefined>({
    key: `${name}.latestPublishedRecordById`,
    get: (id) => {
      return ({ get }) => (id ? get(withLatestPublishedCache)[id]?.object : undefined);
    }
  });

  const withDataCache = atom<Record<string, CachedRecord<R>>>({
    key: `${name}.dataCache`,
    default: {}
  });

  const withLatestPublishedCache = atom<Record<string, CachedRecord<R>>>({
    key: `${name}.latestPublishedCache`,
    default: {}
  });

  const withAllRecordIds = atom<CachedRecord<string[]> | undefined>({
    key: `${name}.allRecordIds`,
    default: undefined
  });

  const withAllRecords = selector<R[] | undefined>({
    key: `${name}.allRecords`,
    get: ({ get }) => {
      const allData = get(withAllRecordsIncludingDeleted);
      return allData?.filter((item) => !(item as { deletedAt?: string })?.deletedAt);
    }
  });

  const withAllRecordsIncludingDeleted = selector<R[] | undefined>({
    key: `${name}.allRecordsIncludingDeleted `,
    get: ({ get }) => {
      const allDataIds = get(withAllRecordIds);
      if (!allDataIds) return;
      const data = get(withDataCache);
      return allDataIds.object.map((id) => data[id].object);
    }
  });

  const withAllRecordsById = selector<Record<string, R>>({
    key: `${name}.allRecordsById`,
    get: ({ get }) => {
      const data = get(withDataCache);
      const recordsById: Record<string, R> = {};
      Object.keys(data).forEach((recordId) => {
        recordsById[recordId] = data[recordId].object;
      });
      return recordsById;
    }
  });

  // Hash of string bucketId to bucket --- A bucket is like "withAllRecordIds"
  const withRecordBucketsById = atom<Record<string, CachedRecord<string[] | undefined>>>({
    key: `${name}.recordBucketsById`,
    default: {}
  });

  const withRecordBucket = selectorFamily<R[] | undefined, string>({
    key: `${name}.recordBucket`,
    get: (bucketId) => {
      return ({ get }) => {
        const allDataIds = get(withRecordBucketsById)[bucketId];
        if (!allDataIds || !allDataIds.object) return;
        const data = get(withDataCache);
        // Filter records which have been deleted
        return allDataIds.object.filter((id) => data[id]).map((id) => data[id].object);
      };
    }
  });

  const withSelectedId = localAtom<string>({
    key: `${name}.selectedId`,
    default: ''
  });

  const withSelected = atom<R | undefined>({
    key: `${name}.selectedEntity`,
    default: undefined
  });

  const withFormMetadata = atom<FormMetadata<R, B>>({
    key: `${name}.formMetadata`,
    default: {
      isShowingForm: false,
      record: undefined
    }
  });

  const withIsFetching = atom<boolean>({
    key: `${name}.isFetching`,
    default: false
  });

  const withIsSaving = atom<boolean>({
    key: `${name}.isSaving`,
    default: false
  });

  const withIsPublishing = atom<boolean>({
    key: `${name}.isPublishing`,
    default: false
  });

  const withIsDeleting = atom<boolean>({
    key: `${name}.isDeleting`,
    default: false
  });

  const withIsValidatingDeletion = atom<boolean>({
    key: `${name}.isValidatingDeletion`,
    default: false
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const withSearchParams = localAtom<WithFilters<SearchParams, any>>({
    key: `${name}.searchParams`,
    default: {
      limit: 50,
      page: 1,
      _filters: []
    }
  });

  return {
    withRecordById,
    withLatestPublishedRecordById,
    withDataCache,
    withLatestPublishedCache,
    withAllRecordIds,
    withAllRecords,
    withAllRecordsIncludingDeleted,
    withAllRecordsById,
    withRecordBucketsById,
    withRecordBucket,
    withSelectedId,
    withSelected,
    withFormMetadata,
    withIsFetching,
    withIsSaving,
    withIsPublishing,
    withIsDeleting,
    withIsValidatingDeletion,
    withSearchParams
  };
}
