import { Stars } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useLocales } from '../../../../hooks';
import { themeColors } from '../../../../theme';

export interface IFeaturedCategoryBadgeProps {
  'data-testid'?: string;
}

const useStyles = makeStyles()(() => ({
  icon: {
    color: themeColors.orange
  }
}));

export function EPGFeaturedCategoryBadge(props: IFeaturedCategoryBadgeProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();

  return (
    <Tooltip title={t('category_featured')}>
      <Stars className={classes.icon} data-testid={props['data-testid']} />
    </Tooltip>
  );
}
