import { Permission, PermissionsGroupId, PermissionsGroupRole, Role } from '../../API';
import { useRecoilValue } from 'recoil';
import { withCurrentUser } from '../../state/auth';
import {
  getPermissionsWithDeleteAccess,
  getPermissionsWithPublishAccess,
  getPermissionsWithReadAccess,
  getPermissionsWithUpsertAccess,
  ROLE_PERMISSIONS
} from '../../utils/permissions';
import {
  getPermissionsGroupsWithDeleteAccess,
  getPermissionsGroupsWithPublishAccess,
  getPermissionsGroupsWithReadAccess,
  getPermissionsGroupsWithUpsertAccess
} from '../../utils/permissionsGroup';
import { useMemo } from 'react';
import { HomepageOptions } from '../../state/theme';
import { getPermissionsGroupsHomepageOption } from '../../utils/permissionsHomepageOption';
import { uniq } from 'lodash-es';

type ReturnType = {
  canDelete: boolean;
  canPublish: boolean;
  canRead: boolean;
  canSave: boolean;
  canManageUsers: boolean;
  isAdmin?: boolean;
};

interface PermissionsGuardProps {
  homepageOption: HomepageOptions;
}

export function usePermissionsGuard(props?: PermissionsGuardProps): ReturnType {
  const { homepageOption } = props || {};

  const currentUser = useRecoilValue(withCurrentUser);
  const userPermissions = currentUser?.roles.flatMap((role) => ROLE_PERMISSIONS[role]);
  const userPermissionsGroups = currentUser?.permissionsGroups?.flatMap((group) => group.permissions);
  const homepageOptionPermissionsGroups = getPermissionsGroupsHomepageOption(homepageOption);

  const isAdmin = useMemo(
    () =>
      currentUser?.roles.includes(Role.ADMIN) ||
      currentUser?.permissionsGroups?.some(
        (group) => group.group === PermissionsGroupId.ADMIN && group.role === PermissionsGroupRole.ADMIN
      ),
    [currentUser?.roles]
  );

  const canSave = useMemo(() => {
    if (isAdmin) {
      return true;
    }
    if (userPermissionsGroups?.length && homepageOptionPermissionsGroups.length) {
      const allPermissionsByHomepageOption = homepageOptionPermissionsGroups.reduce((acc, group) => {
        const permissionsGroupWithUpsert = getPermissionsGroupsWithUpsertAccess(group) || [];
        return [...acc, ...permissionsGroupWithUpsert];
      }, [] as Permission[]);
      const permissionsGroupWithUpsert = uniq(allPermissionsByHomepageOption);

      return userPermissionsGroups?.some((group: Permission) => permissionsGroupWithUpsert?.includes(group));
    }
    if (userPermissions?.length) {
      const permissionsWithUpsert = getPermissionsWithUpsertAccess();

      return userPermissions?.some((permission) => permissionsWithUpsert.includes(permission));
    }

    return false;
  }, [userPermissions, userPermissionsGroups, homepageOptionPermissionsGroups]);

  const canPublish = useMemo(() => {
    if (isAdmin) {
      return true;
    }
    if (userPermissionsGroups?.length && homepageOptionPermissionsGroups.length) {
      const allPermissionsByHomepageOption = homepageOptionPermissionsGroups.reduce((acc, group) => {
        const permissionsGroupWithPublish = getPermissionsGroupsWithPublishAccess(group) || [];
        return [...acc, ...permissionsGroupWithPublish];
      }, [] as Permission[]);
      const permissionsGroupWithPublish = uniq(allPermissionsByHomepageOption);

      return userPermissionsGroups?.some((group: Permission) => permissionsGroupWithPublish?.includes(group));
    }
    if (userPermissions?.length) {
      const permissionsWithPublish = getPermissionsWithPublishAccess();

      return userPermissions?.some((permission) => permissionsWithPublish.includes(permission));
    }

    return false;
  }, [userPermissions, userPermissionsGroups, homepageOptionPermissionsGroups]);

  const canDelete = useMemo(() => {
    if (isAdmin) {
      return true;
    }
    if (userPermissionsGroups?.length && homepageOptionPermissionsGroups.length) {
      const allPermissionsByHomepageOption = homepageOptionPermissionsGroups.reduce((acc, group) => {
        const permissionsGroupWithDelete = getPermissionsGroupsWithDeleteAccess(group) || [];
        return [...acc, ...permissionsGroupWithDelete];
      }, [] as Permission[]);
      const permissionsGroupWithDelete = uniq(allPermissionsByHomepageOption);

      return userPermissionsGroups?.some((group: Permission) => permissionsGroupWithDelete?.includes(group));
    }
    if (userPermissions?.length) {
      const permissionsWithDelete = getPermissionsWithDeleteAccess();

      return userPermissions?.some((permission) => permissionsWithDelete.includes(permission));
    }

    return false;
  }, [userPermissions, userPermissionsGroups, homepageOptionPermissionsGroups]);

  const canRead = useMemo(() => {
    if (isAdmin) {
      return true;
    }
    if (userPermissionsGroups?.length && homepageOptionPermissionsGroups.length) {
      const allPermissionsByHomepageOption = homepageOptionPermissionsGroups.reduce((acc, group) => {
        const permissionsGroupWithRead = getPermissionsGroupsWithReadAccess(group) || [];
        return [...acc, ...permissionsGroupWithRead];
      }, [] as Permission[]);
      const permissionsGroupWithRead = uniq(allPermissionsByHomepageOption);

      return userPermissionsGroups?.some((group: Permission) => permissionsGroupWithRead?.includes(group));
    }
    if (userPermissions?.length) {
      const permissionsWithRead = getPermissionsWithReadAccess();

      return userPermissions?.some((permission) => permissionsWithRead.includes(permission));
    }

    return false;
  }, [userPermissions, userPermissionsGroups, homepageOptionPermissionsGroups]);

  const canManageUsers =
    useMemo(() => {
      const userHasAdminRole = currentUser?.roles?.includes(Role.ADMIN);
      const hasAdminGroupWithRole = currentUser?.permissionsGroups?.some(
        (group) => group.group === PermissionsGroupId.ADMIN && group.role === PermissionsGroupRole.ADMIN
      );

      return userHasAdminRole || hasAdminGroupWithRole;
    }, [userPermissions, userPermissionsGroups]) || false;

  return { canDelete, canPublish, canRead, canSave, canManageUsers, isAdmin };
}
