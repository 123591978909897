import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { emphasize } from '@mui/system';
import { blue, green, orange, purple } from '@mui/material/colors';
import { useRecoilValue } from 'recoil';
import { SmartQueryBodyValue } from './CollectionQueryBuilder';
import { useLocales } from '../../../hooks';
import { useData } from '../../../data-layer';

const useStyles = makeStyles()((theme) => ({
  container: {
    backgroundColor: emphasize(theme.palette.background.paper, 0.1),
    padding: theme.spacing(1, 2),
    display: 'inline-block',
    borderRadius: theme.shape.borderRadius,
    fontFamily: 'monospace',
    cursor: 'pointer',
    '& > *': {
      pointerEvents: 'none'
    }
  },
  field: {
    color: theme.palette.text.secondary
  },
  operator: {
    color: green[500]
  },
  integerValue: {
    color: blue[600]
  },
  stringValue: {
    color: orange[500]
  },
  dateValue: {
    color: purple[400]
  }
}));

interface CollectionQueryNodeLabelProps {
  field?: string;
  operator: string;
  value?: SmartQueryBodyValue;
}

export const testIds = {
  field: 'collection-query-label.field',
  operator: 'collection-query-label.operator',
  value: 'collection-query-label.value'
};

const getDisplayedValue = (value?: SmartQueryBodyValue, type?: string): string => {
  if (Array.isArray(value)) return `${value.join(', ')}`;
  if (type === 'NUMBER') return `${value}`;
  if (type === 'DATETIME') return value && !isNaN(new Date(value).valueOf()) ? (value as string) : `'${value}'`;
  return `'${value}'`;
};

function CollectionQueryNodeLabel({ field, operator, value }: CollectionQueryNodeLabelProps): JSX.Element {
  const {
    collections: {
      state: { withCollectionQueryFields, withCollectionQueryOperators }
    }
  } = useData();

  const { classes } = useStyles();
  const { localize } = useLocales();
  const queryFields = useRecoilValue(withCollectionQueryFields);
  const queryOperators = useRecoilValue(withCollectionQueryOperators);

  const queryField = queryFields.find(({ symbol }) => symbol === field);
  const queryOperator = queryOperators.find(({ symbol }) => symbol === operator);
  const typeClasses: Record<string, string> = {
    STRING: classes.stringValue,
    NUMBER: classes.integerValue,
    DATETIME: classes.dateValue
  };
  const typeClassName = (queryField?.type && typeClasses[queryField.type]) || classes.stringValue;
  const displayedValue = getDisplayedValue(value, queryField?.type);
  const displayedOperator = localize(queryOperator?.name, operator);
  const displayedField = localize(queryField?.name, field);
  return (
    <div className={classes.container} data-tree-item-toggler>
      <span className={classes.field} data-testid={testIds.field}>
        {displayedField || '??'}
      </span>
      &nbsp;
      <span className={classes.operator} data-testid={testIds.operator}>
        {displayedOperator?.toLowerCase()}
      </span>
      &nbsp;
      <span className={typeClassName} data-testid={testIds.value}>
        {value ? displayedValue : '??'}
      </span>
    </div>
  );
}

export default CollectionQueryNodeLabel;
