import React, { useCallback, useEffect, useRef, useState } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { Checkbox, Chip, FormControlLabel, Stack, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { SketchPicker } from 'react-color';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useRecoilValue } from 'recoil';
import { DocumentLocale, VllChannelCountrySpecificationBody, VllChannelResponse } from '../../../API';
import { useData } from '../../../data-layer';
import { useConfirm, useLocales, useNotifications, useStatsig, useTheme } from '../../../hooks';
import { themeColors } from '../../../theme';
import { AssetTypes } from '../../../utils/assetTypes';
import { CustomValidators } from '../../../utils/customValidators';
import {
  handleSaveDraft,
  handleSavePublish,
  isFormValid as isFormValidHelper,
  markAsRequired
} from '../../../utils/formHelpers';
import AssetBrowser from '../../Assets/AssetBrowser';
import Button from '../../shared/Button';
import CountryExceptions from '../../shared/CountryExceptions';
import DateTimeRange from '../../shared/DateTimeRange';
import Drawer from '../../shared/Drawer';
import { IdBadge } from '../../shared/IdBadge';
import InputController from '../../shared/InputController';
import LocalizedInputCollection from '../../shared/LocalizedInputCollection';
import RevisionManager from '../../shared/RevisionManager';
import { isEmpty } from 'lodash-es';
import { MAX_CHANNEL_NUMBER, MIN_CHANNEL_NUMBER } from '../../../utils/channelsConstants';
import { AdTargeting } from '../../shared/AdTargeting';
import Delete from '@mui/icons-material/Delete';
import { StatsigGates } from '../../../utils/consts/statsigGates';
import { HelpText } from '../../shared/HelpText/HelpText';
import { Slug, slugClasses } from '../../shared/Slug';
import { UsagePolicyBadge } from '../../shared/UsagePolicyBadge';
import ObjectPreview from '../../shared/ObjectPreview';
import TextValidator from '../../shared/TextValidator';
import { ScheduleTimePicker } from '../../shared/ScheduleTime';
import { convertDate } from '../../../utils/dateHelpers';
import { usePermissionsGuard } from '../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../state/theme';

const useStyles = makeStyles()((theme) => ({
  formBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    gap: theme.spacing(4)
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  readOnlyHeader: {
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  textInput: {
    minWidth: 400,
    flexGrow: 1
  },
  footerButton: {
    minWidth: 120,
    marginRight: theme.spacing(4)
  },
  fieldContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    gridTemplateAreas: '"title logo" "portraitImage landscapeImage" "desc desc" "seo seo" "metadata metadata"',
    gap: theme.spacing(0, 4),
    '& > div:nth-of-type(5)': {
      gridArea: 'desc'
    },
    '& > div:nth-of-type(6)': {
      gridArea: 'seo'
    },
    '& > div:nth-of-type(7)': {
      gridArea: 'metadata'
    }
  },
  fieldContainerExceptions: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(0, 4),
    marginBottom: theme.spacing(2)
  },
  dates: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    '& > *': {
      minWidth: 300,
      marginRight: theme.spacing(7)
    }
  },
  color: {
    display: 'flex',
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: 2,
    marginRight: theme.spacing(2)
  },
  swatch: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    cursor: 'pointer'
  },
  popover: {
    position: 'absolute',
    zIndex: 2
  },
  cover: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  titleSlugContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    [`& * .${slugClasses.input}`]: {
      minWidth: 400
    }
  },
  backgroundColorField: {
    position: 'relative'
  },
  contentGroupItem: {
    marginRight: theme.spacing(1)
  },
  adTargeting: {
    marginTop: theme.spacing(1)
  },
  schedule: {
    marginTop: theme.spacing(2)
  },
  scheduledTimesHelpText: {
    margin: theme.spacing(2, 0, 3)
  }
}));

export const testIds = {
  root: 'channel-form.root',
  formBody: 'channel-form.body',
  channelNumberInput: 'channel-form.channel-number-input',
  cancelButton: 'channel-form.cancel-button',
  saveButton: 'channel-form.save-button',
  publishButton: 'channel-form.publish-button',
  deleteButton: 'channel-form.delete-button',
  channelMetaData: 'channel-form.channel-metadata',
  backgroundColorCheckbox: 'channel-form.background-color-checkbox',
  backgroundColorField: 'channel-form.background-color-field',
  backgroundColorValue: 'channel-form.background-color-value',
  adsKey: 'channel-form.ads-key',
  editorNotes: 'channel-form.editor-notes',
  confirmDialog: 'channel-form.confirm-dialog'
};

function EPGChannelForm(): JSX.Element {
  const { canSave, canPublish, canDelete } = usePermissionsGuard({
    homepageOption: HomepageOptions.EPG
  });
  const formRef = useRef<ValidatorForm>(null);
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { notifyError, notifySuccess } = useNotifications();
  const { confirm } = useConfirm();

  const {
    channels: {
      state: { withFormMetadata, withIsSaving, withIsPublishing, withIsDeleting },
      hook: {
        getRevisions: getChannelRevisions,
        closeForm,
        save: saveChannelDraft,
        saveAndPublish: saveAndPublishChannel,
        remove: removeChannel,
        addCreatedChannelToBuckets
      }
    },
    contentServiceChannels: {
      hook: { linkChannel, unlinkChannel }
    }
  } = useData();

  const formMetadata = useRecoilValue(withFormMetadata);
  const isSaving = useRecoilValue(withIsSaving);
  const isPublishing = useRecoilValue(withIsPublishing);
  const isDeleting = useRecoilValue(withIsDeleting);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [startTime, setStartTime] = useState<string | undefined>();
  const [endTime, setEndTime] = useState<string | undefined>();
  const [backgroundColor, setBackgroundColor] = useState<string | undefined>();

  const { handleSubmit, control, reset, getValues } = useForm<VllChannelResponse>();

  const channel = formMetadata.record as VllChannelResponse;
  const [revisionManagerChannel, setRevisionManagerChannel] = useState<VllChannelResponse | undefined>();
  const { isEditing, isShowingForm, isNew } = formMetadata;
  const { CheckGate } = useStatsig();

  const title = useWatch({ control, name: 'title' });

  useEffect(() => {
    setRevisionManagerChannel(channel);
    updateForm(channel);
  }, [channel]);

  useEffect(() => {
    if (revisionManagerChannel) {
      updateForm(revisionManagerChannel);
    }
  }, [revisionManagerChannel]);

  const updateForm = (channel: VllChannelResponse) => {
    reset(channel);
    setStartTime(channel?.startTime);
    setEndTime(channel?.endTime);
    setBackgroundColor(channel?.backgroundColor);
  };

  const isFormValid = async () => {
    if (!(await isFormValidHelper(formRef))) return false;
    const validStartAndEndTime = startTime !== '' && endTime !== '';
    const specifications = getValues('specifications') as VllChannelCountrySpecificationBody[];
    const validCountryExceptionsCountries = !specifications?.some(({ countryCodes }) => !countryCodes.length);
    let errorMsg;
    if (!validStartAndEndTime) errorMsg = t('errors.channels.start_and_end_time_not_defined');
    if (!validCountryExceptionsCountries) errorMsg = t('errors.channels.every_exception_needs_a_country');
    if (errorMsg) notifyError(errorMsg);
    return validStartAndEndTime && validCountryExceptionsCountries;
  };

  const transformDateTimes = (data: VllChannelResponse): VllChannelResponse => {
    return {
      ...data,
      scheduleTimes: data.scheduleTimes?.map((item) => ({
        ...item,
        startTime: convertDate(item.startTime),
        endTime: convertDate(item.endTime)
      }))
    };
  };

  const onSubmit = async (channel: VllChannelResponse, shouldPublish = false) => {
    if (!(await isFormValid())) return;

    const channelToSave: VllChannelResponse = transformDateTimes({ ...channel, startTime, endTime, backgroundColor });
    const response = shouldPublish ? await saveAndPublishChannel(channelToSave) : await saveChannelDraft(channelToSave);

    if (response && isNew) {
      await linkChannel(channelToSave.entityId);
      await addCreatedChannelToBuckets(channelToSave);
    }

    closeForm();
  };

  const handleOnClose = () => {
    if (!isSaving && !isPublishing) {
      closeForm();
    }
  };

  const parseChannelNumber = (channelNumber: string | undefined) => {
    const parsedNumber = Number(channelNumber);
    return isNaN(parsedNumber) || parsedNumber <= 0 || parsedNumber > MAX_CHANNEL_NUMBER ? null : Number(channelNumber);
  };

  const getChannelRevisionsCallback = useCallback(() => {
    return getChannelRevisions(channel.entityId);
  }, [getChannelRevisions, channel?.entityId]);

  const handleDelete = async () => {
    if (!channel) return;

    const result = await confirm({
      confirmColor: 'error',
      confirmText: t('general.confirm_delete'),
      body: t('epg.channels.delete_dialog'),
      'data-testid': testIds.confirmDialog
    });
    if (result) {
      const deleteResponse = await removeChannel(channel.entityId);
      if (deleteResponse) {
        notifySuccess(t('success.channels.delete'));
        unlinkChannel(channel.entityId);
      }
      closeForm();
    }
  };

  let channelNumberErrorMessages = Array.from({ length: 3 }, () =>
    t('general.field_needs_to_be_number_between', { min: MIN_CHANNEL_NUMBER, max: MAX_CHANNEL_NUMBER })
  );
  const channelNumberValidators: string[] = ['minNumber:0', 'maxNumber:9999', 'matchRegexp:^[0-9]+$'];
  let channelNumberFormLabel = t('general.channel_number');

  const isChannelNumberOptional = CheckGate(StatsigGates.optionalChannelNumber);
  if (!isChannelNumberOptional) {
    // add properties to make channelNumber optional
    channelNumberValidators.push('required');
    channelNumberFormLabel = markAsRequired(t('general.channel_number'));
    channelNumberErrorMessages = [...channelNumberErrorMessages, t('general.field_is_required')];
  }

  return (
    <Drawer
      data-testid={testIds.root}
      open={isShowingForm}
      formRef={formRef}
      onClose={handleOnClose}
      headerLeft={
        <div className={classes.headerLeft}>
          <Typography variant="h6">{t(isEditing ? 'edit_channel' : 'link_channel')}</Typography>
          {channel && <IdBadge id={channel.entityId} />}
        </div>
      }
      headerRight={
        channel && (
          <>
            <RevisionManager
              getRevisionsPromise={getChannelRevisionsCallback}
              state={revisionManagerChannel}
              onChange={setRevisionManagerChannel}
            />
            <Button
              color="error"
              endIcon={<Delete />}
              disabled={!canDelete || isDeleting || isNew}
              onClick={handleDelete}
              data-testid={testIds.deleteButton}
            >
              {t('general.delete')}
            </Button>
          </>
        )
      }
      footerLeft={
        <>
          <Button
            className={classes.footerButton}
            color="secondary"
            loading={isSaving}
            disabled={!canSave || isSaving || isPublishing || isDeleting}
            onClick={handleSaveDraft(handleSubmit, onSubmit)}
            data-testid={testIds.saveButton}
          >
            {t('general.saveAsDraft')}
          </Button>
          <Button
            className={classes.footerButton}
            loading={isPublishing}
            disabled={!canSave || !canPublish || isSaving || isPublishing || isDeleting}
            onClick={handleSavePublish(handleSubmit, onSubmit)}
            data-testid={testIds.publishButton}
          >
            {t('general.saveAndPublish')}
          </Button>
        </>
      }
      footerRight={
        <Button
          color="grey"
          className={classes.footerButton}
          disabled={isSaving || isPublishing || isDeleting}
          onClick={closeForm}
          data-testid={testIds.cancelButton}
        >
          {t('general.cancel')}
        </Button>
      }
    >
      {channel && (
        <div className={classes.formBody} data-testid={testIds.formBody}>
          <Stack className={classes.readOnlyHeader} direction="row" justifyContent="space-between" alignItems="center">
            {!isEmpty(channel.contentGroups) && (
              <div>
                <Stack direction="row" alignItems="center" gap={2}>
                  <Typography variant="h6">{t('general.contentGroups')}</Typography>
                  <Tooltip title={t('general.contentGroupTooltip')} placement="top" arrow>
                    <InfoOutlined />
                  </Tooltip>
                </Stack>
                <div>
                  {channel.contentGroups?.map((contentGroups, index) => (
                    <Chip
                      key={index}
                      className={classes.contentGroupItem}
                      label={contentGroups}
                      size="small"
                      color="primary"
                      variant="filled"
                    />
                  ))}
                </div>
              </div>
            )}
            {channel.usagePolicy && (
              <div>
                <Typography variant="h6">{t('usage_policy.usage_policy')}</Typography>
                <UsagePolicyBadge usagePolicy={channel.usagePolicy} />
              </div>
            )}
            <div>
              <ObjectPreview object={channel} title={t('general.channel_meta')} />
            </div>
          </Stack>
          <LocalizedInputCollection
            containerClassName={classes.fieldContainer}
            reset={reset}
            getValues={getValues}
            fields={[
              {
                component: (
                  <InputController
                    name="title"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextValidator
                        fullWidth
                        className={classes.textInput}
                        name="title"
                        color={formControlColor}
                        value={value}
                        onChange={onChange}
                        label={markAsRequired(t('general.title'))}
                        validators={[CustomValidators.requiredIfDefined]}
                        errorMessages={[t('general.field_is_required')]}
                      />
                    )}
                  />
                )
              },
              {
                component: (
                  <InputController
                    name="logo"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <AssetBrowser
                        label={t('general.logo')}
                        assetId={channel.entityId}
                        assetType={AssetTypes.channel.logo}
                        value={value as string}
                        onChange={onChange}
                        useDarkBackground
                      />
                    )}
                  />
                )
              },
              {
                component: (
                  <InputController
                    name="portraitImage"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <AssetBrowser
                        label={t('general.portrait_image')}
                        assetId={channel.entityId}
                        assetType={AssetTypes.channel.portrait}
                        value={value as string}
                        onChange={onChange}
                      />
                    )}
                  />
                )
              },
              {
                component: (
                  <InputController
                    name="landscapeImage"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <AssetBrowser
                        label={t('general.landscape_image')}
                        assetId={channel.entityId}
                        assetType={AssetTypes.channel.landscape}
                        value={value as string}
                        onChange={onChange}
                      />
                    )}
                  />
                )
              },
              {
                component: (
                  <InputController
                    name="description"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextValidator
                        fullWidth
                        className={classes.textInput}
                        color={formControlColor}
                        name="description"
                        label={markAsRequired(t('general.description'))}
                        value={value}
                        validators={[CustomValidators.requiredIfDefined]}
                        onChange={onChange}
                        errorMessages={[t('general.field_is_required')]}
                      />
                    )}
                  />
                )
              },
              {
                component: (
                  <InputController
                    name="seoDescription"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextValidator
                        fullWidth
                        className={classes.textInput}
                        color={formControlColor}
                        name="seoDescription"
                        label={t('general.seo_description')}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                )
              },
              {
                component: (
                  <InputController
                    name="metadata"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextValidator
                        fullWidth
                        className={classes.textInput}
                        name="metadata"
                        color={formControlColor}
                        value={value}
                        onChange={onChange}
                        label={t('general.channel_meta_format')}
                        helperText={t('general.channel_meta_message_format')}
                      />
                    )}
                  />
                )
              }
            ]}
          />
          <Typography variant="h6">{t('general.editor_notes')}</Typography>
          <Controller
            name="editorNotes"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextValidator
                className={classes.textInput}
                color={formControlColor}
                name="editorNotes"
                label={t('general.editor_notes')}
                value={value ?? ''}
                onChange={onChange}
                data-testid={testIds.editorNotes}
              />
            )}
          />
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <CountryExceptions<VllChannelResponse | any, VllChannelCountrySpecificationBody>
            control={control}
            attribute="specifications"
            countryIdsExtractor={(field) => field.countryCodes}
            createNewException={() => ({
              countryCodes: [],
              title: { es: '' },
              logo: { es: '' }
            })}
            renderField={(field, index) => (
              <LocalizedInputCollection
                containerClassName={classes.fieldContainerExceptions}
                reset={reset}
                getValues={getValues}
                fields={[
                  {
                    component: (
                      <InputController
                        name={`specifications[${index}].title`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextValidator
                            fullWidth
                            className={classes.textInput}
                            name={`specifications[${index}].title`}
                            color={formControlColor}
                            value={value}
                            onChange={onChange}
                            label={markAsRequired(t('general.title'))}
                            validators={[CustomValidators.requiredIfDefined]}
                            errorMessages={[t('general.field_is_required')]}
                          />
                        )}
                      />
                    )
                  },
                  {
                    component: (
                      <InputController
                        name={`specifications[${index}].logo`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <AssetBrowser
                            label={t('general.logo')}
                            assetId={channel.entityId}
                            assetType={AssetTypes.channel.logo}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    )
                  }
                ]}
              />
            )}
          />
          <Typography variant="h6">{t('general.channel_slug')}</Typography>
          <div className={classes.titleSlugContainer}>
            <Controller
              name="slug"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Slug value={value} onChange={onChange} slugify={title?.[DocumentLocale.ES]} />
              )}
            />
          </div>
          <FormControlLabel
            label={t('general.background_color')}
            control={
              <Checkbox
                checked={!!backgroundColor}
                color={formControlColor}
                onChange={({ target: { checked } }) => setBackgroundColor(checked ? themeColors.orange : undefined)}
                data-testid={testIds.backgroundColorCheckbox}
              />
            }
          />
          {backgroundColor && (
            <div className={classes.backgroundColorField} data-testid={testIds.backgroundColorField}>
              <div className={classes.swatch}>
                <span
                  className={classes.color}
                  style={{ backgroundColor: `${backgroundColor}` }}
                  onClick={() => setDisplayColorPicker(!displayColorPicker)}
                />
                <span data-testid={testIds.backgroundColorValue}>{backgroundColor}</span>
              </div>
              {displayColorPicker && (
                <div className={classes.popover}>
                  <div className={classes.cover} onClick={() => setDisplayColorPicker(false)} />
                  <SketchPicker color={backgroundColor} onChange={(color) => setBackgroundColor(color.hex)} />
                </div>
              )}
            </div>
          )}
          <div>
            <Typography variant="h6">{t('general.dates')}</Typography>
            <DateTimeRange
              className={classes.dates}
              startTimeState={[startTime, setStartTime]}
              endTimeState={[endTime, setEndTime]}
              minDate={channel.revision ? undefined : new Date().toISOString()}
            />
          </div>
          <Typography variant="h6">{t('epg.channels.ads_key')}</Typography>
          <Controller
            name="adsKey"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextValidator
                className={classes.textInput}
                color={formControlColor}
                name="adsKey"
                label={markAsRequired(t('epg.channels.ads_key'))}
                value={value ?? ''}
                validators={[CustomValidators.isAdsKeyValid, 'required']}
                errorMessages={[t('epg.channels.ads_key_is_invalid'), t('general.field_is_required')]}
                onChange={onChange}
                helperText={t('epg.channels.ads_key_sample_string')}
                data-testid={testIds.adsKey}
              />
            )}
          />
          <div>
            <Typography variant="h6">{t('general.channel_info')}</Typography>
            <Controller
              name="channelNumber"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextValidator
                  type="number"
                  name="channelNumber"
                  value={value ?? ''}
                  className={classes.textInput}
                  color={formControlColor}
                  onChange={({ target }) =>
                    onChange(parseChannelNumber((target as HTMLInputElement).value) || undefined)
                  }
                  validators={channelNumberValidators}
                  label={channelNumberFormLabel}
                  errorMessages={channelNumberErrorMessages}
                  data-testid={testIds.channelNumberInput}
                />
              )}
            />
          </div>
          <div>
            <Typography variant="h6">{t('ad_targeting.ad_targeting')}</Typography>
            <Controller
              name="adTargetingList"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AdTargeting className={classes.adTargeting} value={value} onChange={onChange} />
              )}
            />
          </div>
          <div>
            <Typography variant="h6">{t('scheduled_time.label')}</Typography>
            <HelpText className={classes.scheduledTimesHelpText}>{t('scheduled_time.helper')}</HelpText>
            <Controller
              name="scheduleTimes"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ScheduleTimePicker className={classes.schedule} value={value} onChange={onChange} />
              )}
            />
          </div>
        </div>
      )}
    </Drawer>
  );
}

export default EPGChannelForm;
