export const stopPropagation =
  (fn: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => unknown): React.MouseEventHandler<HTMLButtonElement> =>
  (event) => {
    event.stopPropagation();
    fn(event);
  };

export const stopPointerPropagation: { onPointerDown: React.PointerEventHandler } = {
  onPointerDown: (event) => event.stopPropagation()
};

export const isEntityDeleted = <T extends { deletedAt?: unknown }>(object: T | undefined): boolean =>
  !!object?.deletedAt;
