/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiPaginatedResponseOfUserResponse } from '../model';
// @ts-ignore
import { ApiResponseOfUserResponse } from '../model';
// @ts-ignore
import { ApiResponseOfUserResponseArray } from '../model';
// @ts-ignore
import { SortDirection } from '../model';
// @ts-ignore
import { UserBody } from '../model';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a **user** in the **Vix CMS** application.
         * @param {UserBody} userBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (userBody: UserBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userBody' is not null or undefined
            assertParamExists('create', 'userBody', userBody)
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a user by Email
         * @param {string} email 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (email: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('getById', 'email', email)
            const localVarPath = `/api/v1/users/getByEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a set of users by Emails
         * @param {Array<string>} emails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIds: async (emails: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'emails' is not null or undefined
            assertParamExists('getByIds', 'emails', emails)
            const localVarPath = `/api/v1/users/getByEmails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (emails) {
                localVarQueryParameter['emails'] = emails;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all the **users** from the **Vix CMS** application.
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated: async (limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a **user** from the **Vix CMS** application.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('remove', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the information of a **user** in the **Vix CMS** application.
         * @param {string} userId 
         * @param {UserBody} userBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (userId: string, userBody: UserBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('update', 'userId', userId)
            // verify required parameter 'userBody' is not null or undefined
            assertParamExists('update', 'userBody', userBody)
            const localVarPath = `/api/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a **user** in the **Vix CMS** application.
         * @param {UserBody} userBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(userBody: UserBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(userBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a user by Email
         * @param {string} email 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(email: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(email, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a set of users by Emails
         * @param {Array<string>} emails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIds(emails: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfUserResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIds(emails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all the **users** from the **Vix CMS** application.
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginated(limit, page, sortBy, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a **user** from the **Vix CMS** application.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the information of a **user** in the **Vix CMS** application.
         * @param {string} userId 
         * @param {UserBody} userBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(userId: string, userBody: UserBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(userId, userBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Creates a **user** in the **Vix CMS** application.
         * @param {UserBody} userBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(userBody: UserBody, options?: any): AxiosPromise<ApiResponseOfUserResponse> {
            return localVarFp.create(userBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a user by Email
         * @param {string} email 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(email: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfUserResponse> {
            return localVarFp.getById(email, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a set of users by Emails
         * @param {Array<string>} emails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIds(emails: Array<string>, options?: any): AxiosPromise<ApiResponseOfUserResponseArray> {
            return localVarFp.getByIds(emails, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all the **users** from the **Vix CMS** application.
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, options?: any): AxiosPromise<ApiPaginatedResponseOfUserResponse> {
            return localVarFp.getPaginated(limit, page, sortBy, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a **user** from the **Vix CMS** application.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the information of a **user** in the **Vix CMS** application.
         * @param {string} userId 
         * @param {UserBody} userBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(userId: string, userBody: UserBody, options?: any): AxiosPromise<ApiResponseOfUserResponse> {
            return localVarFp.update(userId, userBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - interface
 * @export
 * @interface UsersApi
 */
export interface UsersApiInterface {
    /**
     * Creates a **user** in the **Vix CMS** application.
     * @param {UserBody} userBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    create(userBody: UserBody, options?: any): AxiosPromise<ApiResponseOfUserResponse>;

    /**
     * Retrieves a user by Email
     * @param {string} email 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getById(email: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfUserResponse>;

    /**
     * Retrieves a set of users by Emails
     * @param {Array<string>} emails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getByIds(emails: Array<string>, options?: any): AxiosPromise<ApiResponseOfUserResponseArray>;

    /**
     * Retrieves all the **users** from the **Vix CMS** application.
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, options?: any): AxiosPromise<ApiPaginatedResponseOfUserResponse>;

    /**
     * Deletes a **user** from the **Vix CMS** application.
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    remove(userId: string, options?: any): AxiosPromise<void>;

    /**
     * Updates the information of a **user** in the **Vix CMS** application.
     * @param {string} userId 
     * @param {UserBody} userBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    update(userId: string, userBody: UserBody, options?: any): AxiosPromise<ApiResponseOfUserResponse>;

}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI implements UsersApiInterface {
    /**
     * Creates a **user** in the **Vix CMS** application.
     * @param {UserBody} userBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public create(userBody: UserBody, options?: any) {
        return UsersApiFp(this.configuration).create(userBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a user by Email
     * @param {string} email 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getById(email: string, includeDeleted?: boolean, options?: any) {
        return UsersApiFp(this.configuration).getById(email, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a set of users by Emails
     * @param {Array<string>} emails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getByIds(emails: Array<string>, options?: any) {
        return UsersApiFp(this.configuration).getByIds(emails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all the **users** from the **Vix CMS** application.
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, options?: any) {
        return UsersApiFp(this.configuration).getPaginated(limit, page, sortBy, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a **user** from the **Vix CMS** application.
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public remove(userId: string, options?: any) {
        return UsersApiFp(this.configuration).remove(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the information of a **user** in the **Vix CMS** application.
     * @param {string} userId 
     * @param {UserBody} userBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public update(userId: string, userBody: UserBody, options?: any) {
        return UsersApiFp(this.configuration).update(userId, userBody, options).then((request) => request(this.axios, this.basePath));
    }
}
