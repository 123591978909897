import {
  DocumentLocale,
  EntitlementType,
  GeoBlockUiModuleBody,
  GeoBlockUiModuleBodyModuleTypeEnum
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankGeoBlockModule: NewModuleFunction<GeoBlockUiModuleBody> = (module) => ({
  moduleType: GeoBlockUiModuleBodyModuleTypeEnum.GEO_BLOCK,
  moduleId: generateId(),
  title: {
    [DocumentLocale.ES]: ''
  },
  entitlements: [EntitlementType.ANON, EntitlementType.FREE, EntitlementType.SUB],
  ...module,
  body: '',
  legal: ''
});
