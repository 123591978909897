import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useLocales, useTheme } from '../../../../../hooks';
import Button from '../../../../shared/Button';
import { KeyboardArrowDown, KeyboardArrowUp, ViewColumnRounded } from '@mui/icons-material';

export interface IPreviewLinkProps {
  isShowingPreview: boolean;
  setIsShowingPreview: (value: boolean) => void;
  'data-testid'?: string;
}

const useStyles = makeStyles()(() => ({
  buttonContainer: {
    display: 'inline-flex',
    minWidth: 190
  }
}));

export function PreviewLink({
  isShowingPreview,
  setIsShowingPreview,
  ...props
}: IPreviewLinkProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  return (
    <div className={classes.buttonContainer}>
      <Button
        variant="text"
        size="small"
        color={formControlColor}
        startIcon={<ViewColumnRounded />}
        endIcon={isShowingPreview ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        onClick={() => setIsShowingPreview(!isShowingPreview)}
        data-testid={props['data-testid']}
      >
        {isShowingPreview ? t('layouts.hide_preview') : t('layouts.preview_contents')}
      </Button>
    </div>
  );
}
