import {
  DocumentLocale,
  EntitlementType,
  InlinePromoUiModuleBody,
  InlinePromoUiModuleBodyModuleTypeEnum
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankInlinePromoModule: NewModuleFunction<InlinePromoUiModuleBody> = (module) =>
  <InlinePromoUiModuleBody>{
    moduleType: InlinePromoUiModuleBodyModuleTypeEnum.INLINE_PROMO,
    moduleId: generateId(),
    title: {
      [DocumentLocale.ES]: ''
    },
    entitlements: [EntitlementType.ANON, EntitlementType.FREE, EntitlementType.SUB],
    landscapeFillImage: '',
    portraitFillImage: '',
    ctaUrlPath: '',
    ctaName: '',
    ...module
  };
