import { Autocomplete, Chip } from '@mui/material';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { CouponCodeState, CouponResponse } from '../../../../API';
import { useData } from '../../../../data-layer';
import { useLocales } from '../../../../hooks';
import { markAsRequired } from '../../../../utils/formHelpers';
import TextField from '../../../shared/TextField';
import CouponBadge from '../CouponBadge/CouponBadge';

const useStyles = makeStyles()(() => ({
  textField: {
    '&, & input': {
      cursor: 'pointer'
    },
    '& input': {
      minWidth: '0 !important'
    },
    '& .MuiInput-root': {
      paddingRight: '10px !important',
      minHeight: 40
    }
  }
}));

export interface ICouponPickerProps {
  value: string[] | undefined;
  onChange: (couponCodes: string[]) => void;
  allowMultiple?: boolean;
  disabled?: boolean;
  planCode?: string;
  required?: boolean;
  fieldError?: FieldError;
  filterByState?: Array<CouponCodeState>;
}

export const testIds = {
  autocomplete: 'coupon-picker.autocomplete',
  option: 'coupon-picker.option'
};

export default function CouponPicker({
  value,
  onChange,
  allowMultiple = false,
  disabled,
  planCode,
  required = false,
  fieldError,
  filterByState
}: ICouponPickerProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { coupons } = useData();

  const allCoupons = useRecoilValue(coupons.state.withAllRecords);
  const couponsById = useRecoilValue(coupons.state.withAllRecordsById);

  // If we have a planCode, only show coupons that consider this plan eligible
  const filteredCoupons = allCoupons?.filter((coupon) => {
    return (
      (filterByState ? coupon.state && filterByState.includes(coupon.state as CouponCodeState) : true) &&
      (planCode ? coupon.plans?.map(({ code }) => code).includes(planCode) : true)
    );
  });

  return (
    <>
      {filteredCoupons && (
        <Autocomplete
          multiple
          options={filteredCoupons}
          getOptionLabel={(coupon) => coupon.id + coupon.code + coupon.name} // Used for searching
          disableClearable
          blurOnSelect
          fullWidth
          renderOption={(props, coupon) => (
            <li data-testid={testIds.option} {...props}>
              {coupon.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.textField}
              label={markAsRequired(t('payments.coupons'), required)}
              fieldError={fieldError}
            />
          )}
          renderTags={(value: CouponResponse[], getTagProps) =>
            value?.map((coupon: CouponResponse, index: number) => {
              /* eslint-disable react/jsx-key */
              if (!coupon) {
                return <Chip label={t('payments.invalid_coupon')} {...getTagProps({ index })} />;
              }
              return <CouponBadge couponCode={coupon.code} {...getTagProps({ index })} />;
              /* eslint-enable react/jsx-key */
            })
          }
          value={value?.map((code) => couponsById[code])}
          onChange={(_, changedValues) => {
            if (allowMultiple) {
              onChange(changedValues.filter(Boolean).map(({ code }) => code));
            } else {
              const newSelectedCoupon = changedValues.filter((coupon) => coupon.code !== value?.[0])[0];
              onChange(newSelectedCoupon ? [newSelectedCoupon.code] : []);
            }
          }}
          isOptionEqualToValue={(option, value) => option.code === value?.code}
          disabled={disabled}
          data-testid={testIds.autocomplete}
        />
      )}
    </>
  );
}
