import React from 'react';
import { Box, Typography, TypographyProps } from '@mui/material';
import Select from '../../shared/Select';
import { withStyles } from 'tss-react/mui';

export const FormBody = withStyles(Box, (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6)
  }
}));

export const StyledSelect = withStyles(Select, () => ({
  root: {
    width: 260
  }
}));

// form field using withStyles
export const FormField = withStyles(Box, (theme) => ({
  root: {
    display: 'inline-flex',
    flexDirection: 'column',
    width: '50%',
    padding: theme.spacing(0, 6, 4, 0)
  }
}));

// eslint-disable-next-line react/display-name
export const SectionHeader = withStyles(
  (props: TypographyProps) => <Typography {...props} variant="h6" />,
  (theme) => ({
    root: {
      margin: theme.spacing(4, 0)
    }
  })
);

export const ItemContainer = withStyles(Box, (theme) => ({
  root: {
    borderBottom: `1px dotted ${theme.palette.divider}`,
    paddingTop: theme.spacing(3)
  }
}));
