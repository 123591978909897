import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragOverlay,
  DragMoveEvent,
  DragEndEvent,
  DragOverEvent,
  UniqueIdentifier
} from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { buildTree, flattenTree, getProjection, getChildCount, removeChildrenOf } from './sortableTreeHelpers';
import { FlattenedItem, ScrollTo, ScrollToIndex, SensorContext, TreeItem } from './SortableTreeTypes';
import { SortableTreeItem } from './SortableTreeItem';
import { cloneDeep } from 'lodash-es';

interface ISortableTreeProps<T> {
  items: TreeItem<T>[];
  setItems: (value: TreeItem<T>[]) => void;
  indentationWidth?: number;
  renderItem: (item: T, index: number, parentIndex: number | null) => React.ReactElement;
  maxTreeDepth?: number;
  disabled?: boolean;
}

/* eslint-disable react/display-name */
export const SortableTree = forwardRef(
  // eslint-disable-next-line comma-spacing
  <T,>(
    { items, setItems, renderItem, indentationWidth = 40, maxTreeDepth = 1, disabled = false }: ISortableTreeProps<T>,
    ref: React.Ref<ScrollToIndex>
  ) => {
    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
    const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
    const [offsetLeft, setOffsetLeft] = useState(0);
    const itemsRef = useRef<Array<ScrollTo | null>>([]);

    useImperativeHandle(ref, () => ({
      scrollToIndex: (index: number) => {
        itemsRef.current[index]?.scrollTo();
      }
    }));

    const flattenedItems = useMemo(() => {
      const flattenedTree = flattenTree(items);
      const collapsedItems = flattenedTree.reduce<string[]>(
        (acc, { children, collapsed, id }) => (collapsed && children.length ? [...acc, id as string] : acc),
        []
      );

      return removeChildrenOf(flattenedTree, activeId ? [activeId, ...collapsedItems] : collapsedItems);
    }, [activeId, items]);

    const projected =
      activeId && overId
        ? getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth, maxTreeDepth)
        : null;

    const sensorContext: SensorContext<T> = useRef({
      items: flattenedItems,
      offset: offsetLeft
    });

    const sensors = useSensors(
      useSensor(PointerSensor, {
        activationConstraint: {
          distance: 2
        }
      })
    );

    const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems]);

    const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null;

    useEffect(() => {
      sensorContext.current = {
        items: flattenedItems,
        offset: offsetLeft
      };
    }, [flattenedItems, offsetLeft]);

    function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
      setActiveId(activeId);
      setOverId(activeId);
    }

    function handleDragMove({ delta }: DragMoveEvent) {
      setOffsetLeft(delta.x);
    }

    function handleDragOver({ over }: DragOverEvent) {
      setOverId(over?.id ?? null);
    }

    function handleDragEnd({ active, over }: DragEndEvent) {
      resetState();

      if (projected && over) {
        const { depth, parentId } = projected;
        const clonedItems: FlattenedItem<T>[] = cloneDeep(flattenTree(items));
        const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
        const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
        const activeTreeItem = clonedItems[activeIndex];

        clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

        const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
        const newItems = buildTree(sortedItems, maxTreeDepth);

        setItems(newItems);
      }
    }

    function handleDragCancel() {
      resetState();
    }

    function resetState() {
      setOverId(null);
      setActiveId(null);
      setOffsetLeft(0);
    }

    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext items={sortedIds} strategy={verticalListSortingStrategy} disabled={disabled}>
          {flattenedItems.map(({ id, depth, object, parentIndex, index }) => (
            <SortableTreeItem
              key={`${id}-${depth}-${parentIndex}-${index}`}
              id={id}
              ref={(el) => (itemsRef.current[index] = el)}
              // eslint-disable-next-line
              // @ts-ignore
              renderItem={renderItem}
              item={object}
              depth={id === activeId && projected ? projected.depth : depth}
              index={index}
              parentIndex={parentIndex}
              indentationWidth={indentationWidth}
              disabled={disabled}
            />
          ))}
          {createPortal(
            <DragOverlay dropAnimation={null}>
              {activeId && activeItem ? (
                <SortableTreeItem
                  dragging
                  id={activeId}
                  // eslint-disable-next-line
                  // @ts-ignore
                  renderItem={renderItem}
                  item={activeItem.object}
                  depth={activeItem.depth}
                  index={activeItem.index}
                  parentIndex={activeItem.parentIndex}
                  childCount={getChildCount(items, activeId) + 1}
                  indentationWidth={indentationWidth}
                />
              ) : null}
            </DragOverlay>,
            document.body
          )}
        </SortableContext>
      </DndContext>
    );
  }
);
