import {
  DocumentLocale,
  HeroPresetCollectionBody,
  HeroPresetCollectionResponse,
  PermissionsGroupId
} from '../../../API';
import { useHeroPresetCollectionsAPI } from '../../../hooks/API/HPC/useHeroPresetCollectionsAPI';
import { CreateDataManager, DataManagerReturnType } from '../../DataManager';
import { HPCHook, HPCHookReturnType } from './HPCHook';
import { HPCState, HPCStateReturnType } from './HPCState';

export type HPCDataManagerType = {
  state: HPCStateReturnType;
  hook: HPCHookReturnType;
};

export interface HPCDataManagerReturnType
  extends DataManagerReturnType<HeroPresetCollectionResponse, HeroPresetCollectionBody> {
  state: HPCDataManagerType['state'];
  hook: () => HPCDataManagerType['hook'];
}

const generateNewHPC = (group: PermissionsGroupId): HeroPresetCollectionBody => ({
  name: {
    [DocumentLocale.ES]: ''
  },
  presets: [],
  editorNotes: '',
  ownerPermissionsGroup: group
});

export const HPCDataManager = CreateDataManager<
  HeroPresetCollectionResponse,
  HeroPresetCollectionBody,
  HPCDataManagerReturnType
>({
  name: 'hpc',
  idField: 'entityId',
  useApiHook: useHeroPresetCollectionsAPI,
  useUpdateForCreate: true,
  isVersionedEntity: true,
  stateInitializer: HPCState,
  hookInitializer: HPCHook,
  generateNew: (group) => generateNewHPC(group),
  toString: (record, locales) => locales.localize(record.name)
});
