import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../../Routes';
import { useMemo } from 'react';

export interface IUseRoute {
  isUnderMetadataRoute: boolean;
}

export function useRoute(): IUseRoute {
  const location = useLocation();

  const isUnderMetadataRoute = useMemo(() => {
    return location.pathname.includes(AppRoutes.metadataManager);
  }, [location.pathname]);

  return {
    isUnderMetadataRoute
  };
}
