import { TeamBody, TeamResponse } from '../../../../API';
import { DEFAULT_PAGINATION_LIMIT } from '../../../../utils/appDefaults';
import { DataManagerHook, DataManagerHookReturnType, IDataManagerHookProps } from '../../../DataManager';

export interface TeamsHookReturnType extends DataManagerHookReturnType<TeamResponse, TeamBody> {
  searchTeams: (term: string) => Promise<TeamResponse[] | undefined>;
}

export function TeamsHook(params: IDataManagerHookProps<TeamResponse, TeamBody>): TeamsHookReturnType {
  const dataManagerHook = DataManagerHook<TeamResponse, TeamBody>(params);

  const searchTeams = async (search: string) => {
    const response = await dataManagerHook.fetchPaginated?.(DEFAULT_PAGINATION_LIMIT, 1, [undefined, search]);
    return response?.results;
  };

  return {
    ...dataManagerHook,
    searchTeams
  };
}
