import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { AppRoutes } from '../../Routes';
import { HomepageOptions, withHomepage } from '../../state/theme';
import { useLayoutsRoute } from '../Layouts';

type UseHomepageReturnType = {
  homepageRoute: string;
  getRouteFromHomepageOption: (option: HomepageOptions) => string;
};

export function useHomepage(): UseHomepageReturnType {
  const { getLayoutsRoute } = useLayoutsRoute();

  const getRouteFromHomepageOption = (option: HomepageOptions): string => {
    switch (option) {
      case HomepageOptions.EPG:
        return AppRoutes.epg();
      case HomepageOptions.HPC:
        return AppRoutes.heroPresetCollections();
      case HomepageOptions.COLLECTIONS:
        return AppRoutes.collections;
      case HomepageOptions.VOD_COLLECTIONS:
        return AppRoutes.vodCollections;
      case HomepageOptions.LAYOUTS:
        return getLayoutsRoute();
      case HomepageOptions.SPORTS:
        return AppRoutes.sports;
      case HomepageOptions.WELCOME:
      default:
        return AppRoutes.welcome;
    }
  };

  const homepage = useRecoilValue(withHomepage);
  const homepageRoute = useMemo(() => getRouteFromHomepageOption(homepage), [homepage]);

  return { homepageRoute, getRouteFromHomepageOption };
}
