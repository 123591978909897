import React from 'react';
import { Box } from '@mui/material';
import { themeColors } from '../../../theme';
import { PremiumIcon } from './PremiumIcon';
import { BoxProps } from '@mui/system';
import { LicenseHeight } from '../../Collections/CollectionsPanel/Media/License';
import { withStyles } from 'tss-react/mui';

export const PremiumBadge = withStyles(
  (props: BoxProps & { height?: number }) => (
    <Box {...props}>
      <PremiumIcon />
    </Box>
  ),
  (theme, { height = LicenseHeight }) => ({
    root: {
      display: 'flex',
      width: 'fit-content',
      height,
      padding: Math.round(height * 0.25),
      color: 'white',
      borderRadius: theme.shape.borderRadius,
      background: themeColors.orangeGradient,
      ['& > svg']: {
        height: '100%',
        ['& > g > path']: {
          fill: 'white'
        }
      }
    }
  })
);
