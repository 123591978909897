import { useEffect } from 'react';

export const beforeunload = 'beforeunload';

const unloadListener = (evt: BeforeUnloadEvent) => {
  evt.returnValue = false;
};

export const useNavigationGuard = (isClean: boolean): void => {
  useEffect(() => {
    if (isClean) {
      window.removeEventListener(beforeunload, unloadListener);
    } else {
      window.addEventListener(beforeunload, unloadListener);
    }
  }, [isClean]);
};
