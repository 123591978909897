import { DocumentLocale, SportsHeroUiModuleBody, SportsHeroUiModuleBodyModuleTypeEnum } from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankSportsHeroModule: NewModuleFunction<SportsHeroUiModuleBody> = (module) => ({
  title: {
    [DocumentLocale.ES]: ''
  },
  moduleType: SportsHeroUiModuleBodyModuleTypeEnum.SPORTS_HERO,
  matchHeroContents: [
    {
      title: { es: '', en: '', pt: '' },
      matchId: ''
    }
  ],
  moduleId: generateId(),
  ...module
});
