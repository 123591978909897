import React from 'react';
import { Marker } from 'react-mark.js';
import { useRecoilValue } from 'recoil';
import { withLayoutModulesSearchTerm } from '../../../../state/Layouts';

export function UIModuleListItemMarker({ children }: { children: React.ReactNode }): React.ReactElement {
  const layoutModulesSearchTerm = useRecoilValue(withLayoutModulesSearchTerm);

  return <Marker mark={layoutModulesSearchTerm}>{children}</Marker>;
}
