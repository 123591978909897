import React from 'react';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { Page, PermissionsGroupId } from '../../../API';
import { useLayoutsRouter, useLocales, useTheme } from '../../../hooks';
import { DUMMY_LAYOUT_ID } from '../../../utils/appDefaults';
import { withLayoutsType, withLayoutsTypeLowercase } from '../../../state/Layouts';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import { useData } from '../../../data-layer';
import { pageToPathParam } from '../../../utils/layouts';

export const maxKeyValuePairs = 3;

export type LayoutsPageSelectorProps = {
  className?: string;
};

export const testIds = {
  autocomplete: 'layouts-page-selector.autocomplete',
  autocompleteOption: 'layouts-page-selector.autocomplete-option',
  textField: 'layouts-page-selector.text-field'
};

export function LayoutsPageSelector({ className }: LayoutsPageSelectorProps): React.ReactElement {
  const { formControlColor } = useTheme();
  const { t } = useLocales();
  const layoutsTypeLowercase = useRecoilValue(withLayoutsTypeLowercase);
  const layoutsType = useRecoilValue(withLayoutsType);
  const { hasReadPermission } = usePermissions();
  const { updateLayoutsRoute } = useLayoutsRouter();

  const {
    pages: {
      state: { withRecordBucket, withSelected }
    }
  } = useData();
  const pages = useRecoilValue(withRecordBucket(layoutsType));
  const selectedPage = useRecoilValue(withSelected);

  const onChange = (changedPage: Page) => {
    updateLayoutsRoute({
      pageUrl: pageToPathParam(changedPage),
      layoutId: DUMMY_LAYOUT_ID
    });
  };

  const noPageOption = { urlPath: t('layouts.no_page_selected'), ownerPermissionsGroup: PermissionsGroupId.ADMIN };
  const loadingOption = { urlPath: t('general.loading'), ownerPermissionsGroup: PermissionsGroupId.ADMIN };
  const dummyOptions: Page[] = [loadingOption];

  return (
    <Autocomplete
      className={className}
      options={pages || dummyOptions}
      getOptionLabel={(option) => option.urlPath}
      getOptionDisabled={(option) => !hasReadPermission(option.ownerPermissionsGroup)}
      groupBy={(option) => option.ownerPermissionsGroup || PermissionsGroupId.ADMIN}
      onChange={(_, changedPage) => onChange(changedPage as Page)}
      isOptionEqualToValue={(option, value) => option.urlPath === value.urlPath}
      renderOption={(props, page) => (
        <MenuItem
          {...props}
          data-testid={testIds.autocompleteOption}
          data-page={page.urlPath}
          disabled={page.urlPath === selectedPage?.urlPath}
        >
          {page.urlPath}
        </MenuItem>
      )}
      disabled={!pages}
      disableClearable
      blurOnSelect
      value={!pages ? loadingOption : selectedPage || noPageOption}
      data-testid={testIds.autocomplete}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t(`layouts.${layoutsTypeLowercase}`)}
          color={formControlColor}
          data-testid={testIds.textField}
        />
      )}
    />
  );
}
