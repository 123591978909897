export type AspectRatio = {
  name: string;
  description: string;
  factor: number;
};

export enum AspectRatiosEnum {
  a16x9 = '16 x 9',
  a9x16 = '9 x 16',
  a4x3 = '4 x 3',
  a3x4 = '3 x 4',
  a2x3 = '2 x 3',
  a3x2 = '2 x 3',
  a1x1 = '1 x 1'
}

export const getAspectRatioFactor = (aspectRatio: AspectRatiosEnum): number => {
  const parts = String(aspectRatio).split(' x ');
  return parseInt(parts[0]) / parseInt(parts[1]);
};

export const PreviewAspectRatios: AspectRatio[] = [
  {
    name: '16 x 9',
    description: 'Web desktop & CTV',
    factor: 16 / 9
  },
  {
    name: '4 x 1',
    description: 'Content list (Collection hero)',
    factor: 4
  },
  {
    name: '3 x 4',
    description: 'Portrait tablet',
    factor: 0.75
  },
  {
    name: '4 x 3',
    description: 'Landscape tablet',
    factor: 4 / 3
  },
  {
    name: '19.5 x 9',
    description: 'Mobile tall',
    factor: 19.5 / 9
  },
  {
    name: '9 x 16',
    description: 'Mobile short',
    factor: 9 / 16
  },
  {
    name: '1 x 1',
    description: 'Mobile Hero',
    factor: 1
  }
];
