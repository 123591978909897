import React from 'react';
import { Typography } from '@mui/material';
import { ApiErrorChecker, ApiErrorParser } from '.';
import {
  CollectionAssociatedErrorResponse,
  CollectionLayoutResponse,
  ErrorType,
  PageLayoutResponseV2
} from '../../API';
import { LayoutListLineItem } from '../../components/Layouts/LayoutListItem/LayoutListItem';

export const isCollectionAssociatedError: ApiErrorChecker = (error): error is CollectionAssociatedErrorResponse =>
  error.type === ErrorType.COLLECTION_ASSOCIATED_ERROR;

export const parseCollectionAssociatedError: ApiErrorParser<CollectionAssociatedErrorResponse> = (error, { t }) => {
  if (!isCollectionAssociatedError(error)) return;
  const layouts = error.associatedLayouts || [];
  const errorDetails = [
    <Typography fontWeight="bold" sx={{ marginBottom: 2 }} key={0}>
      {t('layouts.layouts')}:
    </Typography>
  ];
  const layoutByPath = layouts.reduce(
    (acc, layout) => ({ ...acc, [layout.urlPath]: [...(acc[layout.urlPath] || []), layout] }),
    {} as Record<string, CollectionLayoutResponse[]>
  );
  const layoutElements = Object.keys(layoutByPath).map((urlPath) => {
    const pathLayouts = layoutByPath[urlPath];
    return (
      <div key={urlPath}>
        <strong>
          {pathLayouts[0].layoutType}: {pathLayouts[0].urlPath}
        </strong>
        <div>
          {pathLayouts.map((l) => (
            <LayoutListLineItem
              key={l.id}
              layout={l as PageLayoutResponseV2}
              which={!l.startTime ? 'draftLayouts' : 'futureLayouts'}
              liveCountries={l.liveInCountries}
              linkToLayout
              disablePadding
            />
          ))}
        </div>
      </div>
    );
  });
  return [...errorDetails, ...layoutElements];
};
