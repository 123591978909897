/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiPaginatedResponseOfPage } from '../model';
// @ts-ignore
import { LayoutType } from '../model';
/**
 * PagesApi - axios parameter creator
 * @export
 */
export const PagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves all the **pages** available in the **Vix CMS** application and their `urlPath`.
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {LayoutType} [layoutType] 
         * @param {boolean} [isPaywallLayout] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated: async (limit?: number, page?: number, layoutType?: LayoutType, isPaywallLayout?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (layoutType !== undefined) {
                localVarQueryParameter['layoutType'] = layoutType;
            }

            if (isPaywallLayout !== undefined) {
                localVarQueryParameter['isPaywallLayout'] = isPaywallLayout;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} urlPath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (urlPath: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'urlPath' is not null or undefined
            assertParamExists('remove', 'urlPath', urlPath)
            const localVarPath = `/api/v1/pages/{urlPath}`
                .replace(`{${"urlPath"}}`, encodeURIComponent(String(urlPath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PagesApi - functional programming interface
 * @export
 */
export const PagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PagesApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves all the **pages** available in the **Vix CMS** application and their `urlPath`.
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {LayoutType} [layoutType] 
         * @param {boolean} [isPaywallLayout] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginated(limit?: number, page?: number, layoutType?: LayoutType, isPaywallLayout?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginated(limit, page, layoutType, isPaywallLayout, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} urlPath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(urlPath: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(urlPath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PagesApi - factory interface
 * @export
 */
export const PagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PagesApiFp(configuration)
    return {
        /**
         * Retrieves all the **pages** available in the **Vix CMS** application and their `urlPath`.
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {LayoutType} [layoutType] 
         * @param {boolean} [isPaywallLayout] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated(limit?: number, page?: number, layoutType?: LayoutType, isPaywallLayout?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfPage> {
            return localVarFp.getPaginated(limit, page, layoutType, isPaywallLayout, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} urlPath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(urlPath: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(urlPath, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PagesApi - interface
 * @export
 * @interface PagesApi
 */
export interface PagesApiInterface {
    /**
     * Retrieves all the **pages** available in the **Vix CMS** application and their `urlPath`.
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {LayoutType} [layoutType] 
     * @param {boolean} [isPaywallLayout] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApiInterface
     */
    getPaginated(limit?: number, page?: number, layoutType?: LayoutType, isPaywallLayout?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfPage>;

    /**
     * 
     * @param {string} urlPath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApiInterface
     */
    remove(urlPath: string, options?: any): AxiosPromise<void>;

}

/**
 * PagesApi - object-oriented interface
 * @export
 * @class PagesApi
 * @extends {BaseAPI}
 */
export class PagesApi extends BaseAPI implements PagesApiInterface {
    /**
     * Retrieves all the **pages** available in the **Vix CMS** application and their `urlPath`.
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {LayoutType} [layoutType] 
     * @param {boolean} [isPaywallLayout] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public getPaginated(limit?: number, page?: number, layoutType?: LayoutType, isPaywallLayout?: boolean, options?: any) {
        return PagesApiFp(this.configuration).getPaginated(limit, page, layoutType, isPaywallLayout, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} urlPath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public remove(urlPath: string, options?: any) {
        return PagesApiFp(this.configuration).remove(urlPath, options).then((request) => request(this.axios, this.basePath));
    }
}
