import {
  DocumentLocale,
  HeroModuleItemType,
  HeroUiModuleBody,
  HeroUiModuleBodyModuleTypeEnum,
  HeroUIModuleContentTreatment
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';
import { HeroUiModuleItem } from '../withLayouts';

export const blankHeroModule: NewModuleFunction<HeroUiModuleBody> = (module) => ({
  moduleId: generateId(),
  title: {
    [DocumentLocale.ES]: ''
  },
  moduleType: HeroUiModuleBodyModuleTypeEnum.HERO,
  contentTreatment: HeroUIModuleContentTreatment.HERO,
  scrollingTimeSeconds: 3,
  heroContents: [],
  ...module
});

export const blankHeroContentItem = (heroItemType: HeroModuleItemType): HeroUiModuleItem => ({
  heroItemType,
  contentId: '',
  landscapeFillImage: '',
  portraitFillImage: '',
  logoImage: '',
  portraitLogoImage: '',
  sponsorLogo: '',
  lokaliseTitle: '',
  description: '',
  ctaName: '',
  priceText: '',
  pagePath: ''
});
