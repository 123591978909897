import React from 'react';
import { SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PaginationMeta } from '../../../../API';
import { Theme } from '@mui/system';

type PaginationResultsProps = {
  meta?: PaginationMeta | undefined;
  isLoading?: boolean;
  list: unknown[] | undefined;
  sx?: SxProps<Theme>;
};

export function PaginationResults({ meta, isLoading, list, sx }: PaginationResultsProps): JSX.Element {
  const { t } = useTranslation();

  const resultsLabel = () => {
    if (isLoading) return t('general.searching');
    if (!list || list.length === 0) return t('no_results.results');
    if (meta?.totalDocs && meta.totalDocs > meta.limit && list) {
      const page = meta.page ?? 1;
      const start = (page - 1) * meta.limit;
      const end = (page - 1) * meta.limit + list.length;
      return t('showing_n_of_result', { start: start + 1, end, total: meta.totalDocs });
    }
    return t('showing_n_results', { n: list ? list.length : 0 });
  };

  return (
    <Typography variant="body2" color="textSecondary" sx={sx}>
      {resultsLabel()}
    </Typography>
  );
}
