import React, { useState } from 'react';
import { Virtuoso, VirtuosoHandle, VirtuosoProps } from 'react-virtuoso';
import ShadowScroller from '../../ShadowScroller';

type ShadowListProps = {
  loading?: boolean;
  LoadingComponent?: React.ReactElement;
  virtuosoRef?: React.Ref<VirtuosoHandle>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ShadowList<TData = any, TContext = any>({
  loading,
  LoadingComponent,
  virtuosoRef,
  ...props
}: VirtuosoProps<TData, TContext> & ShadowListProps): React.ReactElement {
  const [el, setEl] = useState<HTMLDivElement | null>(null);

  return (
    <ShadowScroller loading={loading} ref={setEl}>
      {loading && LoadingComponent}
      {!loading && <Virtuoso {...props} ref={virtuosoRef} customScrollParent={el || undefined} />}
    </ShadowScroller>
  );
}
