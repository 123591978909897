import { VersionedDocumentStatus, VllCategoryResponse } from '../../../API';
import { useCategoriesAPI } from '../../../hooks/API/EPG/useCategoriesAPI';
import generateId from '../../../utils/generateId';
import { CreateDataManager, DataManagerHookReturnType, DataManagerStateFullReturnType } from '../../DataManager';

export type CategoriesDataManagerType = {
  state: DataManagerStateFullReturnType<VllCategoryResponse, VllCategoryResponse>;
  hook: DataManagerHookReturnType<VllCategoryResponse, VllCategoryResponse>;
};

const generateNewCategory = (): VllCategoryResponse => {
  return {
    entityId: generateId(),
    categoryChannels: [],
    title: {
      es: ''
    },
    description: {
      es: ''
    },
    logo: {
      es: ''
    },
    authorId: '',
    status: VersionedDocumentStatus.DRAFT,
    revision: 0,
    createdDate: '',
    updatedDate: ''
  };
};

export const CategoriesDataManager = CreateDataManager<VllCategoryResponse, VllCategoryResponse>({
  name: 'categories',
  idField: 'entityId',
  isVersionedEntity: true,
  useApiHook: useCategoriesAPI,
  generateNew: generateNewCategory,
  useUpdateForCreate: true,
  toString: (record, locales) => locales.localize(record.title)
});
