import { DisplayAsOptions } from './types/genericTypes';

export function getMediaAspectRatio(displayAs?: DisplayAsOptions): string {
  switch (displayAs) {
    case 'portrait':
      return '2 / 3';
    case 'landscape':
      return '16 / 9';
    default:
      return 'unset';
  }
}

export function getMediaGridTemplateColumns(displayAs?: DisplayAsOptions): string {
  switch (displayAs) {
    case 'landscape':
      return 'repeat(auto-fill, minmax(150px, 1fr))';
    case 'portrait':
    default:
      return 'repeat(auto-fill, minmax(110px, 1fr))';
  }
}

export function getPresetAspectRatio(displayAs?: DisplayAsOptions): string {
  switch (displayAs) {
    case 'portrait':
      return '1 / 1';
    case 'landscape':
      return '16 / 9';
    default:
      return 'unset';
  }
}
