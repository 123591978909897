/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiPaginatedResponseOfFiltersResponse } from '../model';
// @ts-ignore
import { ApiResponseOfSuggestionResponse } from '../model';
/**
 * FiltersApi - axios parameter creator
 * @export
 */
export const FiltersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} index 
         * @param {string} field 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated: async (index: string, field: string, limit?: number, page?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'index' is not null or undefined
            assertParamExists('getPaginated', 'index', index)
            // verify required parameter 'field' is not null or undefined
            assertParamExists('getPaginated', 'field', field)
            const localVarPath = `/api/v1/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (index !== undefined) {
                localVarQueryParameter['index'] = index;
            }

            if (field !== undefined) {
                localVarQueryParameter['field'] = field;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} propertyName 
         * @param {string} text 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestions: async (propertyName: string, text: string, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyName' is not null or undefined
            assertParamExists('getSuggestions', 'propertyName', propertyName)
            // verify required parameter 'text' is not null or undefined
            assertParamExists('getSuggestions', 'text', text)
            const localVarPath = `/api/v1/filters/suggestions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (propertyName !== undefined) {
                localVarQueryParameter['propertyName'] = propertyName;
            }

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FiltersApi - functional programming interface
 * @export
 */
export const FiltersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FiltersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} index 
         * @param {string} field 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginated(index: string, field: string, limit?: number, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfFiltersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginated(index, field, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} propertyName 
         * @param {string} text 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSuggestions(propertyName: string, text: string, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfSuggestionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSuggestions(propertyName, text, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FiltersApi - factory interface
 * @export
 */
export const FiltersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FiltersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} index 
         * @param {string} field 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated(index: string, field: string, limit?: number, page?: number, options?: any): AxiosPromise<ApiPaginatedResponseOfFiltersResponse> {
            return localVarFp.getPaginated(index, field, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} propertyName 
         * @param {string} text 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestions(propertyName: string, text: string, limit?: number, options?: any): AxiosPromise<ApiResponseOfSuggestionResponse> {
            return localVarFp.getSuggestions(propertyName, text, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FiltersApi - interface
 * @export
 * @interface FiltersApi
 */
export interface FiltersApiInterface {
    /**
     * 
     * @param {string} index 
     * @param {string} field 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    getPaginated(index: string, field: string, limit?: number, page?: number, options?: any): AxiosPromise<ApiPaginatedResponseOfFiltersResponse>;

    /**
     * 
     * @param {string} propertyName 
     * @param {string} text 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    getSuggestions(propertyName: string, text: string, limit?: number, options?: any): AxiosPromise<ApiResponseOfSuggestionResponse>;

}

/**
 * FiltersApi - object-oriented interface
 * @export
 * @class FiltersApi
 * @extends {BaseAPI}
 */
export class FiltersApi extends BaseAPI implements FiltersApiInterface {
    /**
     * 
     * @param {string} index 
     * @param {string} field 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApi
     */
    public getPaginated(index: string, field: string, limit?: number, page?: number, options?: any) {
        return FiltersApiFp(this.configuration).getPaginated(index, field, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} propertyName 
     * @param {string} text 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApi
     */
    public getSuggestions(propertyName: string, text: string, limit?: number, options?: any) {
        return FiltersApiFp(this.configuration).getSuggestions(propertyName, text, limit, options).then((request) => request(this.axios, this.basePath));
    }
}
