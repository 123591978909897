import React, { useLayoutEffect, useRef, useState } from 'react';
import { Box, FormControlLabel, Grid, MenuItem, Select, Switch, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { useData } from '../../../../data-layer';
import { useLocales, useNotifications, useTheme } from '../../../../hooks';
import { getPromotionStatus } from '../../../../utils/paymentsHelpers';
import { PromotionStatusEnum } from '../../../../utils/types/paymentsTypes';
import Button from '../../../shared/Button';
import DateTimeRange from '../../../shared/DateTimeRange';
import Drawer from '../../../shared/Drawer';
import TextField from '../../../shared/TextField';
import PromotionStatus from '../PromoStatus/PromoStatus';
import { IdBadge } from '../../../shared/IdBadge';
import { PromoPlanItem } from './PromoPlanItem';
import { Cancel, PlayCircleFilled } from '@mui/icons-material';
import { PlanPromoPriceUnit, PromotionBody, PromotionResponse } from '../../../../API';
import { handleSaveDraft, handleSavePublish, markAsRequired } from '../../../../utils/formHelpers';
import { usePermissionsGuard } from '../../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../../state/theme';

const useStyles = makeStyles()((theme) => ({
  formBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    padding: theme.spacing(4)
  },
  nameInput: {
    width: 420
  },
  promoPlanContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    paddingTop: theme.spacing(4),
    '&:not(:last-of-type)': {
      paddingBottom: theme.spacing(2),
      borderBottom: `1px dotted ${theme.palette.divider}`
    }
  },
  footerButtons: {
    display: 'flex',
    gap: theme.spacing(4)
  },
  footerButton: {
    minWidth: 160
  }
}));

export const testIds = {
  formBody: 'promo-form.form-body',
  saveButton: 'promo-form.save-button',
  endPromotionButton: 'promo-form.end-promotion-button',
  startPromotionButton: 'promo-form.start-promotion-button',
  nameInput: 'promo-form.name-input',
  newPlanItemButton: 'promo-form.new-plan-item-button',
  promoTypes: 'promo-form.promo-types',
  promoTypesItem: 'promo-form.promo-types-item',
  promoAudiences: 'promo-form.promo-audiences',
  promoAudiencesItem: 'promo-form.promo-audiences-item',
  promoId: 'promo-form.promo-id'
};

export function PromoForm(): React.ReactElement {
  const { classes } = useStyles();
  const { promotions, audiences } = useData();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { notifySuccess, notifyError } = useNotifications();

  const promotionTypes = useRecoilValue(promotions.state.withPromotionTypes);
  const allAudiences = useRecoilValue(audiences.state.withAllRecords);
  const formMetadata = useRecoilValue(promotions.state.withFormMetadata);
  const isSaving = useRecoilValue(promotions.state.withIsSaving);
  const promotion = formMetadata.record;
  const formRef = useRef<ValidatorForm>(null);

  const promotionStatus = promotion ? getPromotionStatus(promotion as PromotionResponse) : undefined;
  const isReadOnly = promotionStatus !== PromotionStatusEnum.DRAFT;

  const methods = useForm<PromotionBody | PromotionResponse>();
  const { control, handleSubmit, reset } = methods;
  const { fields, remove, replace, append } = useFieldArray({ control, name: 'plans' });

  const [promotionType, setPromotionType] = useState<string | null>();
  const [promotionAudience, setPromotionAudience] = useState<string | null>();
  const [startDate, setStartDate] = useState<string | undefined>();
  const [endDate, setEndDate] = useState<string | undefined>();
  const [isStartingOrEnding, setIsStartingOrEnding] = useState(false);

  const { canSave, canPublish } = usePermissionsGuard({
    homepageOption: HomepageOptions.MONETIZATION
  });

  useLayoutEffect(() => {
    reset(promotion);
    setStartDate(promotion?.startDate);
    setEndDate(promotion?.endDate);
    setPromotionType(promotion?.type);
    setPromotionAudience(promotion?.audience);
    replace(promotion?.plans || []);
  }, [promotion]);

  const onClose = () => {
    promotions.hook.closeForm();
  };

  const onSubmit = async (promotion: PromotionBody | PromotionResponse, shouldStartPromotion?: boolean) => {
    const promotionToSave: PromotionBody = {
      ...promotion,
      startDate: startDate as string,
      endDate: endDate as string,
      type: promotionType as string,
      audience: promotionAudience as string
    };
    if (shouldStartPromotion && !promotionToSave.plans.length) {
      return notifyError(t('errors.payments.promotion_with_no_plans'));
    }
    const savedPromotion = await promotions.hook.save(promotionToSave);
    if (savedPromotion) {
      if (shouldStartPromotion) {
        setIsStartingOrEnding(true);
        const startedPromotion = await promotions.hook.startPromotion(savedPromotion);
        setIsStartingOrEnding(false);
        if (startedPromotion) onClose();
      } else {
        onClose();
      }
    }
  };

  const endPromotion = async () => {
    setIsStartingOrEnding(true);
    const endedPromotion = await promotions.hook.endPromotion(promotion as PromotionResponse);
    setIsStartingOrEnding(false);
    if (endedPromotion) {
      notifySuccess(t('payments.end_promotion_success'));
      onClose();
    }
  };

  const footerLeft = (
    <div className={classes.footerButtons}>
      {!isReadOnly && (
        <Button
          className={classes.footerButton}
          color="secondary"
          loading={isSaving || isStartingOrEnding}
          onClick={handleSaveDraft(handleSubmit, onSubmit)}
          disabled={!canSave}
          data-testid={testIds.saveButton}
        >
          {t('general.saveAsDraft')}
        </Button>
      )}
      {promotionStatus === PromotionStatusEnum.ACTIVE && (
        <Button
          className={classes.footerButton}
          color="error"
          disabled={isSaving || isStartingOrEnding || !canSave}
          loading={isSaving}
          onClick={endPromotion}
          startIcon={<Cancel />}
          data-testid={testIds.endPromotionButton}
        >
          {t('payments.end_promotion')}
        </Button>
      )}
      {promotionStatus === PromotionStatusEnum.DRAFT && (
        <Button
          className={classes.footerButton}
          color="primary"
          disabled={isSaving || isStartingOrEnding || !canPublish}
          loading={isStartingOrEnding}
          onClick={handleSavePublish(handleSubmit, onSubmit)}
          startIcon={<PlayCircleFilled />}
          data-testid={testIds.startPromotionButton}
        >
          {t('payments.start_promotion')}
        </Button>
      )}
    </div>
  );

  const footerRight = (
    <Button className={classes.footerButton} color="grey" onClick={onClose}>
      {t('general.cancel')}
    </Button>
  );

  const blankPlanItem = () => ({
    code: '',
    changes: {
      couponCodes: [],
      promoPriceUnit: PlanPromoPriceUnit.DAYS,
      promoPriceLength: 1,
      promoGroup: '',
      localizationTemplateId: ''
    }
  });

  const addNewPlanItem = () => {
    append(blankPlanItem());
  };

  return (
    <Drawer
      open={formMetadata.isShowingForm}
      onClose={onClose}
      headerLeft={
        <Stack direction="row" gap={4}>
          <Typography variant="h6">{t(`payments.${formMetadata.isEditing ? 'edit' : 'new'}_promotion`)}</Typography>
          {formMetadata.isEditing && promotion && (
            <IdBadge id={(promotion as PromotionResponse).id} data-testid={testIds.promoId} />
          )}
        </Stack>
      }
      footerLeft={footerLeft}
      footerRight={footerRight}
      formRef={formRef}
    >
      {promotion && (
        <div className={classes.formBody} data-testid={testIds.formBody}>
          <FormProvider {...methods}>
            <Controller
              control={control}
              name="name"
              rules={{ required: t('general.field_is_required') }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  className={classes.nameInput}
                  value={value}
                  onChange={onChange}
                  label={markAsRequired(t('payments.promotion_name'))}
                  disabled={isReadOnly}
                  fieldError={error}
                  data-testid={testIds.nameInput}
                />
              )}
            />
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="textSecondary">
                    {t('payments.promotion_type')}
                  </Typography>
                  <Select
                    onChange={(e) => setPromotionType(e.target.value as string)}
                    value={promotionType ?? ''}
                    sx={{ minWidth: '200px' }}
                    data-testid={testIds.promoTypes}
                  >
                    {promotionTypes?.map((promoType) => (
                      <MenuItem key={promoType.code} value={promoType.code || ''} data-testid={testIds.promoTypesItem}>
                        {promoType.description}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" color="textSecondary">
                    {t('payments.promotion_audiences')}
                  </Typography>
                  <Select
                    onChange={(e) => setPromotionAudience(e.target.value as string)}
                    value={promotionAudience ?? ''}
                    sx={{ minWidth: '200px' }}
                    label={t('payments.promotion_audiences')}
                    data-testid={testIds.promoAudiences}
                  >
                    {allAudiences?.map((audience) => (
                      <MenuItem key={audience.name} value={audience.name} data-testid={testIds.promoAudiencesItem}>
                        {audience.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name="isExperiment"
                    render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                        control={<Switch color={formControlColor} checked={value} onChange={onChange} />}
                        label={t('payments.promotion_is_experiment')}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
            <div>
              <Typography variant="body2" color="textSecondary">
                {t('payments.status')}
              </Typography>
              <PromotionStatus promotion={promotion as PromotionResponse} />
            </div>
            <div>
              <DateTimeRange
                startTimeState={[startDate, setStartDate]}
                endTimeState={[endDate, setEndDate]}
                disabled={isReadOnly}
                minDateRequired
                maxDateRequired
              />
            </div>
            <div>
              <Box sx={{ mt: 3 }}>
                <h3>{t('payments.plan_items')}</h3>
              </Box>
              {fields.map((field, i) => (
                <div key={field.id} className={classes.promoPlanContainer}>
                  <PromoPlanItem index={i} onDelete={() => remove(i)} disabled={isReadOnly} />
                </div>
              ))}

              {!isReadOnly && (
                <Box sx={{ mt: 4 }}>
                  <Button
                    color={formControlColor}
                    variant="outlined"
                    onClick={addNewPlanItem}
                    data-testid={testIds.newPlanItemButton}
                  >
                    {t('payments.add_plan_item')}
                  </Button>
                </Box>
              )}
            </div>
          </FormProvider>
        </div>
      )}
    </Drawer>
  );
}
