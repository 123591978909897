import React, { useState } from 'react';
import { ArrowDropUp, AutoFixHigh, LiveTv, MiscellaneousServices, SportsSoccer } from '@mui/icons-material';
import { Divider, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  AccountCreationUiModuleBodyModuleTypeEnum,
  AccountLoadingUiModuleBodyModuleTypeEnum,
  AccountSuccessUiModuleBodyModuleTypeEnum,
  AiSearchUiModuleBodyModuleTypeEnum,
  BecauseYouUiModuleBodyModuleTypeEnum,
  CollectionListUiModuleBodyModuleTypeEnum,
  CollectionSplashHeroUiModuleBodyModuleTypeEnum,
  ContentListUiModuleBodyModuleTypeEnum,
  ContinueWatchingUiModuleBodyModuleTypeEnum,
  CopyUiModuleBodyModuleTypeEnum,
  GeoBlockUiModuleBodyModuleTypeEnum,
  HeroUiModuleBodyModuleTypeEnum,
  InlinePageUiModuleBodyModuleTypeEnum,
  InlinePromoUiModuleBodyModuleTypeEnum,
  LandingPromoUiModuleBodyModuleTypeEnum,
  LayoutType,
  LinkingAccountUiModuleBodyModuleTypeEnum,
  LiveNewsUiModuleBodyModuleTypeEnum,
  LivePlusUiModuleBodyModuleTypeEnum,
  MenuItemUiModuleBodyModuleTypeEnum,
  MixedListUiModuleBodyModuleTypeEnum,
  NewPartnerLandingUiModuleBodyModuleTypeEnum,
  OneBrandPromoUiModuleBodyModuleTypeEnum,
  OutboundUiModuleBodyModuleTypeEnum,
  PageListUiModuleBodyModuleTypeEnum,
  PlanSummaryUiModuleBodyModuleTypeEnum,
  PlaybackPaywallUiModuleBodyModuleTypeEnum,
  ProfileUiModuleBodyModuleTypeEnum,
  PromotionUiModuleBodyModuleTypeEnum,
  RecommendationsUiModuleBodyModuleTypeEnum,
  RegistrationWallUiModuleBodyModuleTypeEnum,
  SpinPremiaUiModuleBodyModuleTypeEnum,
  SportsEventUiModuleBodyModuleTypeEnum,
  SportsHeroUiModuleBodyModuleTypeEnum,
  TargetPlatform,
  TdsGiftCardsUiModuleBodyModuleTypeEnum,
  TournamentListUiModuleBodyModuleTypeEnum,
  TournamentSplashHeroUiModuleBodyModuleTypeEnum,
  WatchListUiModuleBodyModuleTypeEnum,
  WhoIsWatchingUiModuleBodyModuleTypeEnum
} from '../../../../API';
import { useLocales } from '../../../../hooks';
import { LocaleKeys } from '../../../../locales/i18n';
import {
  withEntitlementsFilter,
  withLayoutErrors,
  withLayoutFormMetadata,
  withLayoutsType
} from '../../../../state/Layouts';
import { UiModules, UIModuleType } from '../../../../utils/layouts';
import Button from '../../../shared/Button';
import { withStatsigUiModulesConfig } from '../../../../state/Experiments';

const menuSectionHeader = 'menuSectionHeader';

const useStyles = makeStyles()((theme) => ({
  ctaButton: {
    minWidth: 180,
    ['&.Mui-disabled']: {
      borderColor: 'transparent !important'
    }
  },
  menuSection: {
    [`& .${menuSectionHeader}`]: {
      display: 'flex',
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiSvgIcon-root': {
        fontSize: '20px !important'
      },
      '& svg': {
        color: theme.palette.text.secondary
      }
    },
    [`&:not(:first-of-type) .${menuSectionHeader}`]: {
      paddingTop: theme.spacing(3)
    }
  }
}));

type UIModuleMenuProps = {
  disabled?: boolean;
  onMenuToggle?: (isOpen: boolean) => void;
  index?: number;
  'data-testid'?: string;
  'icon-data-testid'?: string;
};

export function UIModuleMenu({ disabled, onMenuToggle, index = -1, ...props }: UIModuleMenuProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const [menuItemElementRef, setMenuItemElementRef] = useState<HTMLElement>();

  const setFormMetadata = useSetRecoilState(withLayoutFormMetadata);
  const setLayoutErrors = useSetRecoilState(withLayoutErrors);
  const layoutsType = useRecoilValue(withLayoutsType);
  const entitlements = useRecoilValue(withEntitlementsFilter);
  const statsigUiModulesConfig = useRecoilValue(withStatsigUiModulesConfig);

  const createNewModule = (moduleType: UIModuleType) => {
    handleMenuClose();
    const createNewUIModule = UiModules[moduleType]?.createNewUIModule;
    if (createNewUIModule) {
      // Default to values from the user's selected entitlements filter (and all platforms)
      const uiModule = createNewUIModule({ entitlements, targetPlatform: Object.values(TargetPlatform) });
      setFormMetadata({ isNew: true, index, isShowingForm: true, record: uiModule });
      setLayoutErrors([]);
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuItemElementRef(event.currentTarget);
    onMenuToggle?.(true);
  };

  const handleMenuClose = () => {
    setMenuItemElementRef(undefined);
    onMenuToggle?.(false);
  };

  const isPage = layoutsType === LayoutType.PAGE;
  const isMenu = layoutsType === LayoutType.MENU;
  const isSpecialPage = layoutsType === LayoutType.SPECIAL;
  const isPromosPage = layoutsType === LayoutType.PROMOS;
  const isOutbound = layoutsType === LayoutType.OUTBOUND;

  type MenuItemSection = {
    title: LocaleKeys;
    icon: JSX.Element;
    items: [LocaleKeys, UIModuleType][];
  };

  const renderMenu = () => {
    // Content modules
    const contentModules: [LocaleKeys, UIModuleType][] = [];
    if (statsigUiModulesConfig?.hero.isEnabled) {
      contentModules.push(['layouts.ui_module_hero', HeroUiModuleBodyModuleTypeEnum.HERO]);
    }
    if (statsigUiModulesConfig?.contentList.isEnabled) {
      contentModules.push(['layouts.ui_module_content_list', ContentListUiModuleBodyModuleTypeEnum.CONTENT_LIST]);
    }
    if (statsigUiModulesConfig?.mixedList.isEnabled) {
      contentModules.push(['layouts.ui_module_mixed_list', MixedListUiModuleBodyModuleTypeEnum.MIXED_LIST]);
    }
    if (statsigUiModulesConfig?.liveNews.isEnabled) {
      contentModules.push(['layouts.ui_module_live_news', LiveNewsUiModuleBodyModuleTypeEnum.LIVE_NEWS]);
    }
    if (statsigUiModulesConfig?.collectionList.isEnabled) {
      contentModules.push([
        'layouts.ui_module_collection_list',
        CollectionListUiModuleBodyModuleTypeEnum.COLLECTION_LIST
      ]);
    }
    if (statsigUiModulesConfig?.collectionSplashHero.isEnabled) {
      contentModules.push([
        'layouts.ui_module_collection_splash_hero',
        CollectionSplashHeroUiModuleBodyModuleTypeEnum.COLLECTION_SPLASH_HERO
      ]);
    }
    if (statsigUiModulesConfig?.aiSearch.isEnabled) {
      contentModules.push(['layouts.ui_module_ai_search', AiSearchUiModuleBodyModuleTypeEnum.AI_SEARCH]);
    }

    // Sports modules
    const sportModules: [LocaleKeys, UIModuleType][] = [];
    if (statsigUiModulesConfig?.sportsEvent.isEnabled) {
      sportModules.push(['layouts.ui_module_sports_event', SportsEventUiModuleBodyModuleTypeEnum.SPORTS_EVENT]);
    }
    if (statsigUiModulesConfig?.sportsHero.isEnabled) {
      sportModules.push(['layouts.ui_module_sports_hero', SportsHeroUiModuleBodyModuleTypeEnum.SPORTS_HERO]);
    }
    if (statsigUiModulesConfig?.tournamentList.isEnabled) {
      sportModules.push([
        'layouts.ui_module_tournament_list',
        TournamentListUiModuleBodyModuleTypeEnum.TOURNAMENT_LIST
      ]);
    }
    if (statsigUiModulesConfig?.tournamentSplashHero.isEnabled) {
      sportModules.push([
        'layouts.ui_module_tournament_splash_hero',
        TournamentSplashHeroUiModuleBodyModuleTypeEnum.TOURNAMENT_SPLASH_HERO
      ]);
    }

    // Personalized modules
    const personalizedModules: [LocaleKeys, UIModuleType][] = [];
    if (statsigUiModulesConfig?.continueWatching.isEnabled) {
      personalizedModules.push([
        'layouts.ui_module_continue_watching',
        ContinueWatchingUiModuleBodyModuleTypeEnum.CONTINUE_WATCHING
      ]);
    }
    if (statsigUiModulesConfig?.becauseYou.isEnabled) {
      personalizedModules.push(['layouts.ui_module_because_you', BecauseYouUiModuleBodyModuleTypeEnum.BECAUSE_YOU]);
    }
    if (statsigUiModulesConfig?.recommendations.isEnabled) {
      personalizedModules.push([
        'layouts.ui_module_recommendations',
        RecommendationsUiModuleBodyModuleTypeEnum.RECOMMENDATIONS
      ]);
    }
    if (statsigUiModulesConfig?.watchList.isEnabled) {
      personalizedModules.push(['layouts.ui_module_watch_list', WatchListUiModuleBodyModuleTypeEnum.WATCH_LIST]);
    }

    // Misc modules
    const miscModules: [LocaleKeys, UIModuleType][] = [];
    if (statsigUiModulesConfig?.pageList.isEnabled) {
      miscModules.push(['layouts.ui_module_page_list', PageListUiModuleBodyModuleTypeEnum.PAGE_LIST]);
    }
    if (statsigUiModulesConfig?.inlinePromo.isEnabled) {
      miscModules.push(['layouts.ui_module_inline_promo', InlinePromoUiModuleBodyModuleTypeEnum.INLINE_PROMO]);
    }
    if (statsigUiModulesConfig?.inlinePage.isEnabled) {
      miscModules.push(['layouts.ui_module_inline_page', InlinePageUiModuleBodyModuleTypeEnum.INLINE_PAGE]);
    }

    // Construct the groupings
    const pageMenuItems: MenuItemSection[] = [];
    if (contentModules.length) {
      pageMenuItems.push({
        title: 'layouts.ui_module_groupings.content',
        icon: <LiveTv />,
        items: contentModules
      });
    }
    if (sportModules.length) {
      pageMenuItems.push({
        title: 'layouts.ui_module_groupings.sports',
        icon: <SportsSoccer />,
        items: sportModules
      });
    }
    if (personalizedModules.length) {
      pageMenuItems.push({
        title: 'layouts.ui_module_groupings.personalized',
        icon: <AutoFixHigh />,
        items: personalizedModules
      });
    }
    if (miscModules.length) {
      pageMenuItems.push({
        title: 'layouts.ui_module_groupings.misc',
        icon: <MiscellaneousServices />,
        items: miscModules
      });
    }

    return (
      <Menu
        id="split-button-menu"
        anchorEl={menuItemElementRef}
        open={Boolean(menuItemElementRef)}
        onClose={handleMenuClose}
        PaperProps={{ style: { transform: 'translateY(-40px)' } }}
      >
        {isPage &&
          pageMenuItems.map(({ title, icon, items }, index) => (
            <div className={classes.menuSection} key={title}>
              {index > 0 && <Divider />}
              <div className={menuSectionHeader}>
                {icon}
                <Typography variant="caption" color="textSecondary">
                  {t(title)}
                </Typography>
              </div>
              {items.map(([translationKey, uiModuleType]) => (
                <MenuItem
                  key={translationKey}
                  data-testid={translationKey}
                  onClick={() => createNewModule(uiModuleType)}
                >
                  {t(translationKey)}
                </MenuItem>
              ))}
            </div>
          ))}
        {isSpecialPage && (
          <div>
            {statsigUiModulesConfig?.copy.isEnabled && (
              <MenuItem onClick={() => createNewModule(CopyUiModuleBodyModuleTypeEnum.COPY)}>
                {t('layouts.new_ui_module_copy')}
              </MenuItem>
            )}
            {statsigUiModulesConfig?.livePlus.isEnabled && (
              <MenuItem onClick={() => createNewModule(LivePlusUiModuleBodyModuleTypeEnum.LIVE_PLUS)}>
                {t('layouts.new_ui_module_live_plus')}
              </MenuItem>
            )}
            {statsigUiModulesConfig?.accountSuccess.isEnabled && (
              <MenuItem onClick={() => createNewModule(AccountSuccessUiModuleBodyModuleTypeEnum.ACCOUNT_SUCCESS)}>
                {t('layouts.new_ui_module_account_success')}
              </MenuItem>
            )}
            {statsigUiModulesConfig?.profile.isEnabled && (
              <MenuItem onClick={() => createNewModule(ProfileUiModuleBodyModuleTypeEnum.PROFILE)}>
                {t('layouts.new_ui_module_profile')}
              </MenuItem>
            )}
            {statsigUiModulesConfig?.registrationWall.isEnabled && (
              <MenuItem onClick={() => createNewModule(RegistrationWallUiModuleBodyModuleTypeEnum.REGISTRATION_WALL)}>
                {t('layouts.new_ui_module_reg_wall')}
              </MenuItem>
            )}
            {statsigUiModulesConfig?.playbackPaywall.isEnabled && (
              <MenuItem onClick={() => createNewModule(PlaybackPaywallUiModuleBodyModuleTypeEnum.PLAYBACK_PAYWALL)}>
                {t('layouts.new_ui_module_playback_paywall')}
              </MenuItem>
            )}
            {statsigUiModulesConfig?.accountCreation.isEnabled && (
              <MenuItem onClick={() => createNewModule(AccountCreationUiModuleBodyModuleTypeEnum.ACCOUNT_CREATION)}>
                {t('layouts.new_ui_module_account_creation')}
              </MenuItem>
            )}
            {statsigUiModulesConfig?.linkingAccount.isEnabled && (
              <MenuItem onClick={() => createNewModule(LinkingAccountUiModuleBodyModuleTypeEnum.LINKING_ACCOUNT)}>
                {t('layouts.new_ui_module_linking_account')}
              </MenuItem>
            )}
            {statsigUiModulesConfig?.tdsGiftCards.isEnabled && (
              <MenuItem onClick={() => createNewModule(TdsGiftCardsUiModuleBodyModuleTypeEnum.TDS_GIFT_CARDS)}>
                {t('layouts.new_ui_module_tds_gift_cards')}
              </MenuItem>
            )}
            {statsigUiModulesConfig?.spinPremia.isEnabled && (
              <MenuItem onClick={() => createNewModule(SpinPremiaUiModuleBodyModuleTypeEnum.SPIN_PREMIA)}>
                {t('layouts.new_ui_module_spin_premia')}
              </MenuItem>
            )}
            {statsigUiModulesConfig?.newPartnerLanding.isEnabled && (
              <MenuItem
                onClick={() => createNewModule(NewPartnerLandingUiModuleBodyModuleTypeEnum.NEW_PARTNER_LANDING)}
              >
                {t('layouts.new_ui_module_new_partner_landing')}
              </MenuItem>
            )}
            {statsigUiModulesConfig?.whoIsWatching.isEnabled && (
              <MenuItem onClick={() => createNewModule(WhoIsWatchingUiModuleBodyModuleTypeEnum.WHO_IS_WATCHING)}>
                {t('layouts.new_ui_module_who_is_watching')}
              </MenuItem>
            )}
            {statsigUiModulesConfig?.accountLoading.isEnabled && (
              <MenuItem onClick={() => createNewModule(AccountLoadingUiModuleBodyModuleTypeEnum.ACCOUNT_LOADING)}>
                {t('layouts.new_ui_module_account_loading')}
              </MenuItem>
            )}
            {statsigUiModulesConfig?.geoBlock.isEnabled && (
              <MenuItem onClick={() => createNewModule(GeoBlockUiModuleBodyModuleTypeEnum.GEO_BLOCK)}>
                {t('layouts.new_ui_module_geo_block')}
              </MenuItem>
            )}
          </div>
        )}
        {isPromosPage && (
          <div>
            {statsigUiModulesConfig?.promotion.isEnabled && (
              <MenuItem onClick={() => createNewModule(PromotionUiModuleBodyModuleTypeEnum.PROMOTION)}>
                {t('layouts.new_ui_module_promotion')}
              </MenuItem>
            )}
            {statsigUiModulesConfig?.oneBrandPromo.isEnabled && (
              <MenuItem onClick={() => createNewModule(OneBrandPromoUiModuleBodyModuleTypeEnum.ONE_BRAND_PROMO)}>
                {t('layouts.new_ui_module_one_brand_promo')}
              </MenuItem>
            )}
            {statsigUiModulesConfig?.planSummary.isEnabled && (
              <MenuItem onClick={() => createNewModule(PlanSummaryUiModuleBodyModuleTypeEnum.PLAN_SUMMARY)}>
                {t('layouts.new_ui_module_plan_summary')}
              </MenuItem>
            )}
            {statsigUiModulesConfig?.landingPromo.isEnabled && (
              <MenuItem onClick={() => createNewModule(LandingPromoUiModuleBodyModuleTypeEnum.LANDING_PROMO)}>
                {t('layouts.new_ui_module_landing_promo')}
              </MenuItem>
            )}
          </div>
        )}
        {isOutbound && (
          <div>
            {statsigUiModulesConfig?.outbound.isEnabled && (
              <MenuItem onClick={() => createNewModule(OutboundUiModuleBodyModuleTypeEnum.OUTBOUND)}>
                {t('layouts.new_ui_module_outbound')}
              </MenuItem>
            )}
          </div>
        )}
      </Menu>
    );
  };

  if (isMenu)
    return (
      <Button
        className={classes.ctaButton}
        onClick={() => createNewModule(MenuItemUiModuleBodyModuleTypeEnum.MENU_ITEM)}
        data-testid={props['data-testid']}
        disabled={disabled}
      >
        {t('layouts.new_menu_item')}
      </Button>
    );

  return (
    <Stack direction="row" gap={4}>
      {isPage && (
        <Button
          className={classes.ctaButton}
          onClick={() => createNewModule(ContentListUiModuleBodyModuleTypeEnum.CONTENT_LIST)}
          data-testid={props['data-testid']}
          disabled={disabled}
        >
          {t('layouts.new_collection_module')}
        </Button>
      )}
      {isSpecialPage && (
        <Button
          className={classes.ctaButton}
          onClick={() => createNewModule(CopyUiModuleBodyModuleTypeEnum.COPY)}
          data-testid={props['data-testid']}
          disabled={disabled}
        >
          {t('layouts.new_ui_module_copy')}
        </Button>
      )}
      <Button
        onClick={handleMenuClick}
        data-testid={props['icon-data-testid']}
        endIcon={<ArrowDropUp />}
        disabled={disabled}
      >
        {t('layouts.choose_module')}
      </Button>
      {renderMenu()}
    </Stack>
  );
}
