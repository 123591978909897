/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '../../../../shared/TextField';
import { InputAdornment, FormControl, InputLabel, MenuItem, Collapse } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Search } from '@mui/icons-material';
import { capitalize } from 'lodash-es';
import Button from '../../../../shared/Button';
import SearchFilters, { FilterBadge, WithFilters } from '../../../../shared/SearchFilters';
import DateRange, { DateRangeValue } from '../../../../shared/DateRange';
import CountryPickerLite from '../../../../shared/CountryPickerLite';
import MultiSelect from '../../../../shared/MultiSelect';
import PaginationResults from '../../../../shared/Pagination/PaginationResults';
import AdvancedSearch, { testIds as advancedSearchTestIds } from '../../../../shared/AdvancedSearch';
import { useData } from '../../../../../data-layer';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLocales, useStatsig, useTheme } from '../../../../../hooks';
import {
  PaginationMeta,
  GetAllMediaQuery,
  GetAllMediaQuerySortBy,
  SmartContentCollectionQuerySortingDirection,
  SavedSearchResponse,
  ContentCollectionType
} from '../../../../../API';
import { makeStyles } from 'tss-react/mui';
import { LocaleKeys } from '../../../../../locales/i18n';
import { DateTime } from 'luxon';
import { ALL, IncludeAll } from '../../../../../utils/types/genericTypes';
import { useSearchFilters } from '../../../../../hooks/General/useSearchFilters';
import { SavedSearches } from '../SavedSearches/SavedSearches';
import ContentSearchButton from '../ContentSearchButton';
import { SuggestionsAutocomplete } from '../../../../shared/SuggestionsAutocomplete';
import Select from '../../../../shared/Select';
import { SeStatsigGates } from '../../../../../utils/consts/statsigGates';

interface IContentSearchFiltersProps {
  mediaList: string[];
  isLoading?: boolean;
  paginationMeta: PaginationMeta | undefined;
  isForLayout?: boolean;
  onSubmit: (evt?: React.FormEvent<HTMLElement>) => void;
  onExport: () => void;
}

export interface GetAllMediaQueryParams extends GetAllMediaQuery {
  publishWindowStartDate?: DateRangeValue;
  publishWindowEndDate?: DateRangeValue;
  ingestDate?: DateRangeValue;
}

export type ContentSearchWithFilters = WithFilters<GetAllMediaQueryParams, ContentSearchFilter>;

const ContentTypeAllButEpisodes = 'ALL_BUT_EPISODES';
const SortNone = 'SORT_NONE';

enum SortBy {
  SORT_PUBLISH_START = 'SORT_PUBLISH_START',
  SORT_TITLE_ALPHABETICAL = 'SORT_TITLE_ALPHABETICAL',
  SORT_RECENTLY_INGESTED_ASC = 'SORT_RECENTLY_INGESTED_ASC',
  SORT_RECENTLY_INGESTED_DESC = 'SORT_RECENTLY_INGESTED_DESC'
}

export enum IsPremiumFilter {
  PREMIUM = 'true',
  NON_PREMIUM = 'false'
}

export enum DownloadableFilter {
  ALLOW = 'true',
  BLOCK = 'false'
}

export enum ContentSearchFilter {
  BY_CATEGORY = 'BY_CATEGORY',
  BY_CONTENT_ID = 'BY_CONTENT_ID',
  BY_PUBLISH_WINDOW_START = 'BY_PUBLISH_WINDOW_START',
  BY_PUBLISH_WINDOW_END = 'BY_PUBLISH_WINDOW_END',
  BY_INGEST_DATE = 'BY_INGEST_DATE',
  BY_COUNTRY = 'BY_COUNTRY',
  BY_CONTENT_GROUPS = 'BY_CONTENT_GROUPS',
  BY_YEAR_RELEASED = 'BY_YEAR_RELEASED',
  BY_CONTENT_VERTICAL = 'BY_CONTENT_VERTICAL',
  BY_IS_PREMIUM = 'BY_IS_PREMIUM',
  BY_SUPPLIER = 'BY_SUPPLIER',
  BY_RATING = 'BY_RATING',
  BY_DOWNLOADABLE = 'BY_DOWNLOADABLE'
}

type SortField = keyof Pick<GetAllMediaQuerySortBy, 'publishWindowStartDate' | 'title' | 'ingestDate'>;
type SortValue = {
  field: SortField;
  direction: SmartContentCollectionQuerySortingDirection;
  localizationKey: LocaleKeys;
};

const SortOptions: Record<SortBy, SortValue> = {
  [SortBy.SORT_PUBLISH_START]: {
    field: 'publishWindowStartDate',
    direction: SmartContentCollectionQuerySortingDirection.ASC,
    localizationKey: 'general.sort.publish_window_start'
  },
  [SortBy.SORT_TITLE_ALPHABETICAL]: {
    field: 'title',
    direction: SmartContentCollectionQuerySortingDirection.ASC,
    localizationKey: 'general.sort.title'
  },
  [SortBy.SORT_RECENTLY_INGESTED_ASC]: {
    field: 'ingestDate',
    direction: SmartContentCollectionQuerySortingDirection.ASC,
    localizationKey: 'general.sort.recently_ingested_date_asc'
  },
  [SortBy.SORT_RECENTLY_INGESTED_DESC]: {
    field: 'ingestDate',
    direction: SmartContentCollectionQuerySortingDirection.DESC,
    localizationKey: 'general.sort.recently_ingested_date_desc'
  }
};

const useStyles = makeStyles()((theme) => ({
  topSection: {
    padding: theme.spacing(4, 4, 3),
    display: 'flex',
    flexDirection: 'column'
  },
  topSectionSearch: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4)
  },
  topSectionAdvanced: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
  topSectionAdvancedRight: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3)
  },
  contentTypeFilter: {
    minWidth: 135,
    marginBottom: theme.spacing(4)
  },
  advancedSearchContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  sortBySelector: {
    minWidth: 180,
    marginBottom: theme.spacing(4)
  },
  categorySelector: {
    minWidth: 200
  },
  contentGroupsSelector: {
    minWidth: 200
  },
  searchByYearReleased: {
    minWidth: 200
  },
  searchByContentId: {
    minWidth: 200
  },
  countryFilter: {
    minWidth: 300
  },
  contentGroupsFilter: {
    minWidth: 300
  },
  contentVerticalFilter: {
    minWidth: 250
  },
  ratingFilter: {
    minWidth: 300
  }
}));

export const testIds = {
  searchBar: 'content-search.search-bar',
  contentType: 'content-search.content-type',
  searchButton: 'content-search.search-button',
  sortBySelector: 'content-search.sort-by-selector',
  activeSearchFiltersCount: 'content-search.active-search-filters-count',
  filter: (filter: string): string => `content-search.filter.${filter}`,
  removeFilter: (filter: string): string => `content-search.remove-filter.${filter}`,
  openAdvancedSearch: advancedSearchTestIds.openButton,
  category: 'content-search.filter.category',
  isPremium: 'content-search.filter.isPremium',
  rating: 'content-search.filter.rating',
  downloadable: 'content-search.filter.downloadable'
};

const searchFilterParams: Record<ContentSearchFilter, (keyof GetAllMediaQueryParams)[]> = {
  [ContentSearchFilter.BY_CATEGORY]: ['categories'],
  [ContentSearchFilter.BY_CONTENT_ID]: ['contentId'],
  [ContentSearchFilter.BY_PUBLISH_WINDOW_START]: [
    'publishWindowStartDate',
    'publishWindowStartDateMinDynamic',
    'publishWindowStartDateMaxDynamic'
  ],
  [ContentSearchFilter.BY_PUBLISH_WINDOW_END]: [
    'publishWindowEndDate',
    'publishWindowEndDateMinDynamic',
    'publishWindowEndDateMaxDynamic'
  ],
  [ContentSearchFilter.BY_INGEST_DATE]: ['ingestDate', 'ingestDateMinDynamic', 'ingestDateMaxDynamic'],
  [ContentSearchFilter.BY_COUNTRY]: ['country'],
  [ContentSearchFilter.BY_CONTENT_GROUPS]: ['contentGroups'],
  [ContentSearchFilter.BY_YEAR_RELEASED]: ['yearReleased'],
  [ContentSearchFilter.BY_CONTENT_VERTICAL]: ['contentVertical'],
  [ContentSearchFilter.BY_IS_PREMIUM]: ['isPremium'],
  [ContentSearchFilter.BY_SUPPLIER]: ['supplier'],
  [ContentSearchFilter.BY_RATING]: ['rating'],
  [ContentSearchFilter.BY_DOWNLOADABLE]: ['downloadable']
};

const isPremiumOptions = [ALL, ...Object.values(IsPremiumFilter)] as [IncludeAll<IsPremiumFilter>];

const downloadableOptions = [ALL, ...Object.values(DownloadableFilter)] as [IncludeAll<DownloadableFilter>];

function ContentSearchFilters({
  onSubmit,
  onExport,
  mediaList,
  isLoading,
  paginationMeta,
  isForLayout
}: IContentSearchFiltersProps): JSX.Element {
  const {
    collections: {
      state: { withAllGenres }
    },
    media: {
      state: {
        withSearchParams,
        withContentGroups,
        withContentTypes,
        withContentTypesAllowed,
        withContentVertical,
        withRating
      }
    }
  } = useData();

  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { CheckGate } = useStatsig();

  const onLoadSavedSearch = (savedSearch: SavedSearchResponse) => {
    const savedSearchFilters: ContentSearchWithFilters = {
      ...(savedSearch.query as ContentSearchWithFilters),
      // Page and Limit should not be loaded from saved search
      // Reset back to page 1 and the current limit
      page: 1,
      limit: getValues('limit')
    };
    reset(savedSearchFilters);
    onSubmit();
  };

  const contentTypes = useRecoilValue(withContentTypes);
  const [contentTypesAllowed] = useRecoilState(withContentTypesAllowed);
  const contentGroups = useRecoilValue(withContentGroups);
  const contentVertical = useRecoilValue(withContentVertical);
  const rating = useRecoilValue(withRating);

  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const isReplaceSearchWithAdvancedSearch = CheckGate(SeStatsigGates.ReplaceSearchWithAdvancedSearch);

  const [recoilSearchParams] = useSearchFilters(withSearchParams, searchFilterParams, [
    'term',
    'contentType',
    'sortBy'
  ]);

  const genresList = useRecoilValue(withAllGenres); // TODO: data-layer convert
  const { control, setValue, getValues, reset } = useFormContext<ContentSearchWithFilters>();

  const searchFilters = Object.keys(ContentSearchFilter);

  const searchFilterActions = searchFilters.reduce(
    (actions, filter) => ({
      ...actions,
      [filter]: t(`collections.advanced_search.filters.${filter.toLowerCase()}` as LocaleKeys)
    }),
    {}
  ) as Record<ContentSearchFilter, string>;

  const getSortValueFromObject = (value: GetAllMediaQuerySortBy) => {
    for (const key of Object.values(SortBy)) {
      if (value[SortOptions[key].field] === SortOptions[key].direction) {
        return key;
      }
    }
  };

  const getContentTypeValue = (value: string[] | undefined) => {
    if (!value || !contentTypes.length) {
      const [firstContentTypeAllowed] = contentTypesAllowed || [];
      return [firstContentTypeAllowed?.toLowerCase() || ALL];
    }
    if (Array.isArray(value) && value.length > 1) {
      return [ContentTypeAllButEpisodes];
    }
    return value;
  };

  const handleRemoveSearchFilter = (filter: ContentSearchFilter) => {
    for (const param of searchFilterParams[filter as ContentSearchFilter]) {
      setValue(param, undefined);
    }
  };

  useEffect(() => {
    if (contentTypesAllowed?.length) {
      const [firstContentType] = contentTypesAllowed;
      setValue('contentType', [firstContentType.toLowerCase()]);
      onSubmit();
    }
  }, [contentTypesAllowed]);

  const isEpisodeContentTypeAllowed =
    contentTypesAllowed?.length > 0 ? contentTypesAllowed.includes(ContentCollectionType.EPISODE) : true;

  return (
    <form className={classes.topSection} onSubmit={onSubmit}>
      <div className={classes.topSectionSearch}>
        <Controller
          name="term"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              label={t('collections.search_by_keywords')}
              value={value || ''}
              clearable={true}
              onClear={() => {
                setValue('term', '');
                onSubmit();
              }}
              onChange={onChange}
              data-testid={testIds.searchBar}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
            />
          )}
        />
        <Controller
          name="contentType"
          control={control}
          render={({ field: { value } }) => (
            <FormControl color={formControlColor} className={classes.contentTypeFilter}>
              <InputLabel id="filter">{t('layouts.content_type')}</InputLabel>
              <Select
                label={t('layouts.content_type')}
                data-testid={testIds.contentType}
                labelId="filter"
                value={getContentTypeValue(value)}
                onChange={(evt) => {
                  if (evt.target.value === ALL) {
                    setValue('contentType', undefined);
                  } else if (evt.target.value === ContentTypeAllButEpisodes) {
                    setValue(
                      'contentType',
                      contentTypes.filter((contentType) => !/episode/i.test(contentType))
                    );
                  } else {
                    setValue('contentType', evt.target.value as unknown as string[]);
                  }
                }}
              >
                {!contentTypesAllowed?.length && <MenuItem value={ALL}>{t('collections.content_type.all')}</MenuItem>}
                {isEpisodeContentTypeAllowed && (
                  <MenuItem value={ContentTypeAllButEpisodes}>
                    {t('collections.content_type.all_but_episodes')}
                  </MenuItem>
                )}
                {Object.values(contentTypes).map((type) => (
                  <MenuItem key={type} value={type}>
                    {capitalize(type)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name="sortBy"
          control={control}
          render={({ field: { value } }) => (
            <FormControl color={formControlColor} className={classes.sortBySelector}>
              <InputLabel id="contentSortBy">{t('pagination.sort_by')}</InputLabel>
              <Select
                label={t('pagination.sort_by')}
                data-testid={testIds.sortBySelector}
                labelId="contentSortBy"
                value={value ? getSortValueFromObject(value) : SortNone}
                onChange={({ target: { value } }) => {
                  if (value === SortNone) {
                    setValue('sortBy', undefined);
                  } else if (SortOptions[value as SortBy]) {
                    setValue('sortBy', {
                      [SortOptions[value as SortBy].field]: SortOptions[value as SortBy].direction
                    });
                  }
                }}
              >
                <MenuItem value={SortNone}>{t('general.sort.none')}</MenuItem>
                {Object.keys(SortOptions).map((option) => (
                  <MenuItem key={option} value={option}>
                    {t(SortOptions[option as SortBy].localizationKey)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        <ContentSearchButton handleDropdownClick={onExport} loading={isLoading}>
          <Button type="submit" data-testid={testIds.searchButton} size="small">
            {t('general.search')}
          </Button>
        </ContentSearchButton>
      </div>
      {/* Advanced Search Section */}
      <Collapse in={isAdvancedSearchOpen}>
        <div className={classes.advancedSearchContainer}>
          <SearchFilters
            actions={searchFilterActions}
            onRemove={handleRemoveSearchFilter}
            render={(FilterWrap) => (
              <>
                <FilterWrap
                  name={ContentSearchFilter.BY_CATEGORY}
                  data-testid={testIds.removeFilter(ContentSearchFilter.BY_CATEGORY)}
                >
                  <Controller
                    name="categories"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl color={formControlColor} className={classes.categorySelector}>
                        {isReplaceSearchWithAdvancedSearch && (
                          <MultiSelect
                            className={classes.contentVerticalFilter}
                            label={t('collections.search_by_category')}
                            options={genresList}
                            value={value || []}
                            onChange={onChange}
                            renderLabel={(option) => capitalize(option)}
                          />
                        )}
                        {!isReplaceSearchWithAdvancedSearch && (
                          <>
                            <InputLabel id="contentCategorySelect">{t('collections.search_by_category')}</InputLabel>
                            <Select
                              label={t('collections.filter_by_content_type')}
                              data-testid={testIds.filter(ContentSearchFilter.BY_CATEGORY)}
                              labelId="contentCategorySelect"
                              value={value || ALL}
                              onChange={({ target: { value } }) => {
                                if (value === ALL) {
                                  setValue('categories', undefined);
                                } else {
                                  setValue('categories', [value]);
                                }
                              }}
                              SelectDisplayProps={{ style: { textTransform: 'capitalize' } }}
                            >
                              <MenuItem value={ALL}>{t('collections.content_type.all')}</MenuItem>
                              {genresList?.map((genre) => (
                                <MenuItem
                                  data-testid={testIds.category}
                                  key={genre}
                                  value={genre}
                                  sx={{ textTransform: 'capitalize' }}
                                >
                                  {genre}
                                </MenuItem>
                              ))}
                            </Select>
                          </>
                        )}
                      </FormControl>
                    )}
                  />
                </FilterWrap>
                <FilterWrap
                  name={ContentSearchFilter.BY_CONTENT_ID}
                  data-testid={testIds.removeFilter(ContentSearchFilter.BY_CONTENT_ID)}
                >
                  <Controller
                    name="contentId"
                    control={control}
                    render={({ field: { value } }) => (
                      <FormControl color={formControlColor} className={classes.searchByContentId}>
                        <TextField
                          label={t('collections.search_by_content_id')}
                          value={value}
                          onClear={() => {
                            setValue('contentId', undefined);
                            onSubmit();
                          }}
                          onChange={({ target: { value } }) => {
                            setValue('contentId', value);
                          }}
                          data-testid={testIds.filter(ContentSearchFilter.BY_CONTENT_ID)}
                        />
                      </FormControl>
                    )}
                  />
                </FilterWrap>
                <FilterWrap
                  name={ContentSearchFilter.BY_PUBLISH_WINDOW_START}
                  data-testid={testIds.removeFilter(ContentSearchFilter.BY_PUBLISH_WINDOW_START)}
                >
                  <Controller
                    name="publishWindowStartDate"
                    control={control}
                    render={({ field: { value } }) => (
                      <div data-testid={testIds.filter(ContentSearchFilter.BY_PUBLISH_WINDOW_START)}>
                        <DateRange
                          key={'searchByPublishWindowStart'}
                          label={t('collections.advanced_search.filters.by_publish_window_start')}
                          value={value as DateRangeValue}
                          onChange={(newValue) => setValue('publishWindowStartDate', newValue)}
                        />
                      </div>
                    )}
                  />
                </FilterWrap>

                <FilterWrap
                  name={ContentSearchFilter.BY_PUBLISH_WINDOW_END}
                  data-testid={testIds.removeFilter(ContentSearchFilter.BY_PUBLISH_WINDOW_END)}
                >
                  <Controller
                    name="publishWindowEndDate"
                    control={control}
                    render={({ field: { value } }) => (
                      <div data-testid={testIds.filter(ContentSearchFilter.BY_PUBLISH_WINDOW_END)}>
                        <DateRange
                          key={'searchByPublishWindowEnd'}
                          label={t('collections.advanced_search.filters.by_publish_window_end')}
                          value={value as DateRangeValue}
                          onChange={(newValue) => setValue('publishWindowEndDate', newValue)}
                        />
                      </div>
                    )}
                  />
                </FilterWrap>
                <FilterWrap
                  name={ContentSearchFilter.BY_INGEST_DATE}
                  data-testid={testIds.removeFilter(ContentSearchFilter.BY_INGEST_DATE)}
                >
                  <Controller
                    name="ingestDate"
                    control={control}
                    render={({ field: { value } }) => (
                      <div data-testid={testIds.filter(ContentSearchFilter.BY_INGEST_DATE)}>
                        <DateRange
                          key={'ingestDate'}
                          label={t('collections.advanced_search.filters.by_ingest_date')}
                          value={value as DateRangeValue}
                          onChange={(newValue) => setValue('ingestDate', newValue)}
                        />
                      </div>
                    )}
                  />
                </FilterWrap>
                <FilterWrap
                  name={ContentSearchFilter.BY_YEAR_RELEASED}
                  data-testid={testIds.removeFilter(ContentSearchFilter.BY_YEAR_RELEASED)}
                >
                  <Controller
                    name="yearReleased"
                    control={control}
                    render={({ field: { value } }) => (
                      <FormControl color={formControlColor} className={classes.searchByYearReleased}>
                        <DatePicker
                          views={['year']}
                          label={t('collections.advanced_search.filters.by_year_released')}
                          value={value ? DateTime.utc(value, 1, 1, 0, 0) : null}
                          onChange={(newValue: DateTime | null) => {
                            setValue('yearReleased', newValue?.year);
                          }}
                          slotProps={{
                            textField: {
                              'data-testid': testIds.filter(ContentSearchFilter.BY_YEAR_RELEASED),
                              color: formControlColor
                            }
                          }}
                        />
                      </FormControl>
                    )}
                  />
                </FilterWrap>
                <FilterWrap
                  name={ContentSearchFilter.BY_COUNTRY}
                  data-testid={testIds.removeFilter(ContentSearchFilter.BY_COUNTRY)}
                >
                  <div data-testid={testIds.filter(ContentSearchFilter.BY_COUNTRY)}>
                    <Controller
                      name="country"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <CountryPickerLite
                          className={classes.countryFilter}
                          label={t('country_picker.filter_by_country')}
                          value={value || []}
                          onChange={onChange}
                          disabled={isForLayout}
                        />
                      )}
                    />
                  </div>
                </FilterWrap>
                <FilterWrap
                  name={ContentSearchFilter.BY_CONTENT_GROUPS}
                  data-testid={testIds.removeFilter(ContentSearchFilter.BY_CONTENT_GROUPS)}
                >
                  <div data-testid={testIds.filter(ContentSearchFilter.BY_CONTENT_GROUPS)}>
                    <Controller
                      name="contentGroups"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControl color={formControlColor} className={classes.contentGroupsSelector}>
                          <MultiSelect
                            className={classes.contentGroupsFilter}
                            label={t('collections.filter_by_content_groups')}
                            options={contentGroups}
                            value={value || []}
                            onChange={onChange}
                          />
                        </FormControl>
                      )}
                    />
                  </div>
                </FilterWrap>
                <FilterWrap
                  name={ContentSearchFilter.BY_CONTENT_VERTICAL}
                  data-testid={testIds.removeFilter(ContentSearchFilter.BY_CONTENT_VERTICAL)}
                >
                  <div data-testid={testIds.filter(ContentSearchFilter.BY_CONTENT_VERTICAL)}>
                    <Controller
                      name="contentVertical"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControl color={formControlColor} className={classes.contentGroupsSelector}>
                          <MultiSelect
                            className={classes.contentVerticalFilter}
                            label={t('collections.filter_by_content_vertical')}
                            options={contentVertical}
                            value={value || []}
                            onChange={onChange}
                            renderLabel={(option) => capitalize(option)}
                          />
                        </FormControl>
                      )}
                    />
                  </div>
                </FilterWrap>
                <FilterWrap
                  name={ContentSearchFilter.BY_IS_PREMIUM}
                  data-testid={testIds.removeFilter(ContentSearchFilter.BY_IS_PREMIUM)}
                >
                  <Controller
                    name="isPremium"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl color={formControlColor} className={classes.categorySelector}>
                        <InputLabel id="isPremiumSelect">{t('collections.search_by_premium')}</InputLabel>
                        <Select
                          data-testid={testIds.filter(ContentSearchFilter.BY_IS_PREMIUM)}
                          labelId="isPremiumSelect"
                          label={t('collections.filter_by_is_premium')}
                          value={value ?? ALL}
                          onChange={onChange}
                          SelectDisplayProps={{ style: { textTransform: 'capitalize' } }}
                        >
                          {isPremiumOptions.map((option) => (
                            <MenuItem data-testid={testIds.isPremium} key={option} value={option}>
                              {t(`collections.is_premium.options.${option.toLowerCase()}` as LocaleKeys)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </FilterWrap>
                <FilterWrap
                  name={ContentSearchFilter.BY_DOWNLOADABLE}
                  data-testid={testIds.removeFilter(ContentSearchFilter.BY_DOWNLOADABLE)}
                >
                  <Controller
                    name="downloadable"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl color={formControlColor} className={classes.categorySelector}>
                        <InputLabel id="downloadableSelect">{t('collections.search_by_downloadable')}</InputLabel>
                        <Select
                          data-testid={testIds.filter(ContentSearchFilter.BY_DOWNLOADABLE)}
                          labelId="downloadableSelect"
                          label={t('collections.filter_by_downloadable')}
                          value={value ?? ALL}
                          onChange={onChange}
                          SelectDisplayProps={{ style: { textTransform: 'capitalize' } }}
                        >
                          {downloadableOptions.map((option) => (
                            <MenuItem data-testid={testIds.downloadable} key={option} value={option}>
                              {t(
                                `collections.advanced_search.downloadable.options.${option.toLowerCase()}` as LocaleKeys
                              )}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </FilterWrap>
                <FilterWrap
                  name={ContentSearchFilter.BY_SUPPLIER}
                  data-testid={testIds.removeFilter(ContentSearchFilter.BY_SUPPLIER)}
                >
                  <Controller
                    name="supplier"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormControl color={formControlColor} className={classes.searchByContentId}>
                        <SuggestionsAutocomplete
                          label={t('collections.search_by_supplier')}
                          propertyName="supplier.name"
                          value={value || ''}
                          onChange={onChange}
                          data-testid={testIds.filter(ContentSearchFilter.BY_SUPPLIER)}
                        />
                      </FormControl>
                    )}
                  />
                </FilterWrap>
                <FilterWrap
                  name={ContentSearchFilter.BY_RATING}
                  data-testid={testIds.removeFilter(ContentSearchFilter.BY_RATING)}
                >
                  <div data-testid={testIds.filter(ContentSearchFilter.BY_RATING)}>
                    <Controller
                      name="rating"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControl color={formControlColor} className={classes.contentGroupsSelector}>
                          <MultiSelect
                            className={classes.ratingFilter}
                            label={t('collections.filter_by_rating')}
                            options={rating}
                            value={value || []}
                            onChange={onChange}
                            renderLabel={(option) => option.toUpperCase()}
                          />
                        </FormControl>
                      )}
                    />
                  </div>
                </FilterWrap>
              </>
            )}
          />
        </div>
      </Collapse>
      <div className={classes.topSectionAdvanced}>
        <PaginationResults list={mediaList} meta={paginationMeta} isLoading={isLoading} />
        <div className={classes.topSectionAdvancedRight}>
          <FilterBadge filters={recoilSearchParams._filters} />
          <AdvancedSearch open={isAdvancedSearchOpen} onChange={setIsAdvancedSearchOpen} />
          <SavedSearches onLoad={onLoadSavedSearch} />
        </div>
      </div>
    </form>
  );
}

export default ContentSearchFilters;
