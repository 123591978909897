import React, { useMemo, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Close, Event } from '@mui/icons-material';
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import IconButton from '../IconButton';
import { useLocales, useTheme } from '../../../hooks';
import { DateTime } from 'luxon';
import { DatePicker } from '@mui/x-date-pickers';
import { InputAdornment } from '@mui/material';

export const testIds = {
  textField: 'text-field',
  closeButton: 'close-button'
};

const useStyles = makeStyles()(() => ({
  clearIcon: {
    margin: -5
  }
}));

export type ClearableDatePickerProps = {
  value: string | undefined;
  clearable?: boolean;
  onChange: (value: string | undefined) => unknown;
  minDate?: string;
  maxDate?: string;
  isIngestionDateFormat?: boolean;
  error?: boolean;
  helperText?: string;
};

const INGESTION_DATE_FORMAT = 'yyyy-MM-dd';

export function ClearableDatePicker({
  value,
  clearable = true,
  onChange,
  minDate,
  maxDate,
  isIngestionDateFormat = false,
  error,
  helperText,
  ...props
}: Omit<DatePickerProps<DateTime>, 'renderInput' | 'value' | 'onChange' | 'minDate' | 'maxDate'> &
  ClearableDatePickerProps): React.ReactElement {
  const ref = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const { formControlColor } = useTheme();
  const { classes } = useStyles();
  const { t } = useLocales();

  const date = useMemo(() => (value ? DateTime.fromISO(value) : null), [value]);

  const onClearClicked = () => {
    onChange(undefined);
  };

  const onChangeOverride = (date: DateTime | null) => {
    onChange(date?.toISODate() || undefined);
  };

  // Created separately because passing as undefined causes crashes for some reason
  const ingestionProps: Partial<DatePickerProps<DateTime>> = {
    format: INGESTION_DATE_FORMAT
  };

  const clearButton = (
    <IconButton
      className={classes.clearIcon}
      data-testid={testIds.closeButton}
      onClick={onClearClicked}
      title={t('tooltips.clear')}
      size="small"
    >
      <Close />
    </IconButton>
  );

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <DatePicker
      ref={ref}
      value={date}
      minDate={minDate ? DateTime.fromISO(minDate) : undefined}
      maxDate={maxDate ? DateTime.fromISO(maxDate) : undefined}
      {...(isIngestionDateFormat ? ingestionProps : {})}
      onChange={(newValue) => onChangeOverride(newValue)}
      slots={{
        inputAdornment: (props) => (
          <InputAdornment {...props} position="end" sx={{ gap: 2 }}>
            {clearable && value && clearButton}
            <IconButton onClick={onOpen}>
              <Event />
            </IconButton>
          </InputAdornment>
        )
      }}
      slotProps={{
        textField: {
          color: formControlColor,
          error,
          helperText,
          'data-testid': testIds.textField
        }
      }}
      open={open}
      onClose={onClose}
      {...props}
    />
  );
}
