import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../hooks';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper
  },
  welcomeContainer: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1
  }
}));

export const testIds = {
  saveButton: 'config.save-button',
  validatorForm: 'config.validator-form'
};

export default function Welcome(): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.welcomeContainer}>
          <h1>{t('general.welcome_message_headline')}</h1>
          <p>{t('general.welcome_message_summary')}</p>
        </div>
      </div>
    </>
  );
}
