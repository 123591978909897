/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum SmartQueryFieldSymbol {
    CONTRIBUTORS_NAME = 'contributors.name',
    ALLOWED_COUNTRY = 'allowedCountry',
    START_DATE = 'startDate',
    GENRE = 'genre',
    TEAM_ID = 'teamId',
    END_DATE = 'endDate',
    TRANSMISSION_STATUS = 'transmissionStatus',
    YEAR_RELEASED = 'yearReleased',
    TITLE = 'title',
    SPORT_ID = 'sportId',
    SUPPLIER = 'supplier',
    LEAGUE_ID = 'leagueId',
    MATCH_ID = 'matchId',
    CONTENT_TYPE = 'contentType',
    DATE_MODIFIED = 'dateModified',
    DATE_CREATED = 'dateCreated',
    CONTENT_GROUPS = 'contentGroups',
    RATING = 'rating',
    CONTENT_VERTICAL = 'contentVertical',
    ID = 'id',
    KEYWORDS = 'keywords',
    PUBLISH_WINDOW_START_DATE = 'publishWindowStartDate',
    PUBLISH_WINDOW_END_DATE = 'publishWindowEndDate',
    PUBLISH_WINDOW_COUNTRY = 'publishWindowCountry',
    ACTOR = 'actor',
    DIRECTOR = 'director',
    IS_PREMIUM = 'isPremium',
    TERM = 'term',
    DOWNLOADABLE = 'downloadable'
}



