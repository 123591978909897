import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ContentPreview } from '..';
import { InlinePromoUiModuleBody } from '../../../../../API';
import { useLokalise } from '../../../../../hooks/General/useLokalise';
import { getAssetUrl } from '../../../../../utils/assetHelpers';
import { DisplayAsOptions } from '../../../../../utils/types/genericTypes';
import { VixOrangeButton } from '../shared/SharedButtons';

export const useStyles = makeStyles()((theme) => ({
  backgroundImage: {
    margin: theme.spacing(0, 4, 4),
    width: 750,
    height: 150,
    backgroundSize: 'cover'
  },
  ctaContainer: {
    width: '80%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  ctaContainerInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: 70,
    color: 'white'
  }
}));

export interface IInlinePromoPreviewProps {
  showing: boolean;
  module: InlinePromoUiModuleBody;
}

export const testIds = {
  background: 'inline-promo-preview.background',
  cta: 'inline-promo-preview.cta'
};

export function InlinePromoPreview({ showing, module }: IInlinePromoPreviewProps): React.ReactElement {
  const { classes } = useStyles();
  const { lt } = useLokalise();
  const backgroundImageUrl = module.landscapeFillImage ? getAssetUrl(module.landscapeFillImage) : '';

  return (
    <ContentPreview showing={showing} displayAs={DisplayAsOptions.LANDSCAPE}>
      <div
        className={classes.backgroundImage}
        style={{ backgroundImage: `url(${backgroundImageUrl})` }}
        data-testid={testIds.background}
      >
        <div className={classes.ctaContainer}>
          <div className={classes.ctaContainerInner}>
            <VixOrangeButton data-testid={testIds.cta}>{lt(module.ctaName)}</VixOrangeButton>
            {module.priceText && <Typography variant="body2">{lt(module.priceText)}</Typography>}
          </div>
        </div>
      </div>
    </ContentPreview>
  );
}
