import { Autocomplete } from '@mui/material';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { PaymentPlanResponse } from '../../../../API';
import { useData } from '../../../../data-layer';
import { useLocales } from '../../../../hooks';
import { markAsRequired } from '../../../../utils/formHelpers';
import TextField from '../../../shared/TextField';
import PlanBadge from '../PlanBadge/PlanBadge';

const useStyles = makeStyles()(() => ({
  textField: {
    '&, & input': {
      cursor: 'pointer'
    },
    '& input': {
      minWidth: '0 !important'
    },
    '& .MuiInput-root': {
      paddingRight: '10px !important',
      minHeight: 40
    }
  }
}));

export interface IPlanPickerProps {
  value: string;
  onChange: (planCode: string) => void;
  disabled?: boolean;
  required?: boolean;
  fieldError?: FieldError;
}

export const testIds = {
  autocomplete: 'plan-picker.autocomplete',
  option: 'plan-picker.option'
};

export default function PlanPicker({
  value,
  onChange,
  disabled,
  required = false,
  fieldError
}: IPlanPickerProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { plans } = useData();

  const allPlans = useRecoilValue(plans.state.withAllRecords);
  const plansById = useRecoilValue(plans.state.withAllRecordsById);

  return (
    <>
      {allPlans && (
        <Autocomplete
          multiple
          options={allPlans}
          getOptionLabel={(plan) => plan.code + plan.name} // Used for searching
          disableClearable
          blurOnSelect
          fullWidth
          renderOption={(props, plan) => (
            <li data-testid={testIds.option} {...props}>
              {plan.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.textField}
              label={markAsRequired(t('payments.plan'), required)}
              fieldError={fieldError}
            />
          )}
          renderTags={(value: PaymentPlanResponse[], getTagProps) =>
            value.map((plan: PaymentPlanResponse, index: number) => (
              // eslint-disable-next-line react/jsx-key
              <PlanBadge planCode={plan.code} {...getTagProps({ index })} />
            ))
          }
          value={plansById[value] ? [plansById[value]] : []}
          onChange={(_, changedValues) => {
            const newSelectedPlan = changedValues.filter((plan) => plan.code !== value)[0];
            onChange(newSelectedPlan?.code || '');
          }}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          disabled={disabled}
          data-testid={testIds.autocomplete}
        />
      )}
    </>
  );
}
