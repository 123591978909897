/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { EntitlementType } from './entitlement-type';
import { LocalizedFieldBody } from './localized-field-body';
import { TargetPlatform } from './target-platform';
import { UiModuleExperimentGroup } from './ui-module-experiment-group';
import { WhoIsWatchingUiModuleBodyCtaMain } from './who-is-watching-ui-module-body-cta-main';

/**
 * 
 * @export
 * @interface WhoIsWatchingUiModuleBody
 */
export interface WhoIsWatchingUiModuleBody {
    /**
     * 
     * @type {string}
     * @memberof WhoIsWatchingUiModuleBody
     */
    moduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof WhoIsWatchingUiModuleBody
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof WhoIsWatchingUiModuleBody
     */
    moduleType: WhoIsWatchingUiModuleBodyModuleTypeEnum;
    /**
     * 
     * @type {Array<TargetPlatform>}
     * @memberof WhoIsWatchingUiModuleBody
     */
    targetPlatform?: Array<TargetPlatform>;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof WhoIsWatchingUiModuleBody
     */
    caption?: LocalizedFieldBody;
    /**
     * 
     * @type {Array<EntitlementType>}
     * @memberof WhoIsWatchingUiModuleBody
     */
    entitlements?: Array<EntitlementType>;
    /**
     * 
     * @type {Array<UiModuleExperimentGroup>}
     * @memberof WhoIsWatchingUiModuleBody
     */
    experiments?: Array<UiModuleExperimentGroup> | null;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof WhoIsWatchingUiModuleBody
     */
    title: LocalizedFieldBody;
    /**
     * 
     * @type {string}
     * @memberof WhoIsWatchingUiModuleBody
     */
    headerMain: string;
    /**
     * 
     * @type {string}
     * @memberof WhoIsWatchingUiModuleBody
     */
    subHeaderMain: string;
    /**
     * 
     * @type {Array<WhoIsWatchingUiModuleBodyCtaMain>}
     * @memberof WhoIsWatchingUiModuleBody
     */
    ctaMain: Array<WhoIsWatchingUiModuleBodyCtaMain>;
}

/**
    * @export
    * @enum {string}
    */
export enum WhoIsWatchingUiModuleBodyModuleTypeEnum {
    WHO_IS_WATCHING = 'WHO_IS_WATCHING'
}



