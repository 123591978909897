import React from 'react';
import { CollectionListUiModuleBody } from '../../../../../API';
import Localized from '../../../../shared/Localized';
import { CollectionListPreview } from '../../ContentPreview/CollectionListPreview';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';

export type CollectionListListItemProps = IBaseUIModuleListItemProps<CollectionListUiModuleBody>;

export const testIds = {
  treatment: 'collection-list-list-item.treatment',
  preview: 'collection-list-list-item.preview'
};

const CollectionListListItem = ({ uiModule, ...props }: CollectionListListItemProps): JSX.Element => {
  return (
    <UIModuleListItem
      uiModule={uiModule}
      topLineTitle={<Localized prop={uiModule.title} />}
      renderPreview={(showPreview) => (
        <CollectionListPreview collectionIds={uiModule.collections.map((v) => v.id)} showing={showPreview} />
      )}
      {...props}
    />
  );
};

export default CollectionListListItem;
