import React from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import MaterialIconButton, { IconButtonProps } from '@mui/material/IconButton';

const IconButton = ({
  disabled = false,
  onClick,
  children,
  title,
  placement,
  ...props
}: IconButtonProps & Partial<Pick<TooltipProps, 'title' | 'placement'>>): JSX.Element => {
  const iconButton = (
    <MaterialIconButton disabled={disabled} onClick={onClick} {...props}>
      {children}
    </MaterialIconButton>
  );

  if (!title || disabled) return <>{iconButton}</>;

  const defaultPlacement = 'top';
  const tooltipPlacement = placement ? { placement } : { placement: defaultPlacement as 'top' };
  const tooltipProps = { ...tooltipPlacement, title };
  return (
    <Tooltip {...tooltipProps} arrow>
      {iconButton}
    </Tooltip>
  );
};

export default IconButton;
