import { useRecoilState, useSetRecoilState } from 'recoil';
import { DataPresetResponse, DataPresetBody, DataPresetType, GetPaginatedDataPresetsQuery } from '../../../API';
import { Paginated } from '../../../hooks';
import { CacheManager, DataManagerHook, DataManagerHookReturnType, IDataManagerHookProps } from '../../DataManager';
import { PresetsStateReturnType } from './PresetsState';
import { DataPresetAPI } from '../../../hooks/API/Presets/PresetsAPI';

export interface PresetsHookReturnType extends DataManagerHookReturnType<DataPresetResponse, DataPresetBody> {
  searchPresets: (data: GetPaginatedDataPresetsQuery) => Promise<Paginated<DataPresetResponse> | undefined>;
  getPresetsByType: (type: DataPresetType, term?: string) => Promise<Paginated<DataPresetResponse> | undefined>;
  getPresetByHash: (hash: string) => Promise<DataPresetResponse | undefined>;
  createPreset: (preset: DataPresetResponse | DataPresetBody) => Promise<DataPresetResponse | undefined>;
  updatePreset: (id: string, preset: DataPresetResponse) => Promise<DataPresetResponse | undefined>;
  removePreset: (id: string) => Promise<boolean>;
}

export const ALL_DATA_PRESET_HEROES = [
  DataPresetType.HERO,
  DataPresetType.HERO_ITEM_VOD,
  DataPresetType.HERO_ITEM_CHANNEL,
  DataPresetType.HERO_ITEM_EVENTS,
  DataPresetType.HERO_ITEM_COLLECTION_LINK,
  DataPresetType.HERO_ITEM_PAGE,
  DataPresetType.HERO_ITEM_PROMOTION
];

export function PresetsHook(params: IDataManagerHookProps<DataPresetResponse, DataPresetBody>): PresetsHookReturnType {
  const dataManagerHook = DataManagerHook<DataPresetResponse, DataPresetBody>(params);
  const [cache, setCache] = useRecoilState(params.state.withDataCache);
  const setLastCreatedPreset = useSetRecoilState((params.state as PresetsStateReturnType).withLastCreatedPreset);
  const { addRecordToCache, addRecordsToCache } = CacheManager(params.idField, setCache);

  const setDefaultPresets = useSetRecoilState((params.state as PresetsStateReturnType).withDefaultPresets);

  const api = params.useApiHook() as DataPresetAPI;

  const searchPresets = async ({
    limit = 20,
    page = 1,
    name,
    contentId,
    type,
    includeDeleted
  }: GetPaginatedDataPresetsQuery): Promise<Paginated<DataPresetResponse> | undefined> => {
    try {
      const {
        data: { body }
      } = await api.getPaginated(limit, page, type, name, contentId, includeDeleted);
      addRecordsToCache(body.results);
      return body;
    } catch (error) {
      return undefined;
    }
  };

  const getPresetsByType = async (type: DataPresetType, term = '') => {
    try {
      return dataManagerHook.fetchPaginated?.(20, 1, [[type], term]);
    } catch (error) {
      return undefined;
    }
  };

  const getPresetByHash = async (hash: string) => {
    try {
      const preset = Object.values(cache).find(
        (cachedPreset) => cachedPreset.object.hash === hash && cachedPreset.object
      );
      if (preset) {
        return preset.object;
      }
      const { data } = await api.getByHash(hash);
      addRecordToCache(data.body);
      return data.body;
    } catch (error) {
      return undefined;
    }
  };

  const createPreset = async (record: DataPresetResponse | DataPresetBody) => {
    const response = await dataManagerHook.create(record);
    if (response) {
      setLastCreatedPreset(response);
      setDefaultPresets((prev) => ({ ...prev, [response.type]: [response, ...prev[response.type]] }));
    }
    return response;
  };

  const updatePreset = async (id: string, record: DataPresetResponse) => {
    const response = await dataManagerHook.update(id, record);
    if (response) {
      setDefaultPresets((prev) => ({
        ...prev,
        [response.type]: prev[response.type].map((preset) => (preset.id === response.id ? response : preset))
      }));
    }
    return response;
  };

  const removePreset = async (id: string) => {
    const response = await dataManagerHook.remove(id);
    const { object: preset } = cache[id];
    if (response && preset) {
      setDefaultPresets((prev) => ({
        ...prev,
        [preset.type]: prev[preset.type].filter((preset) => preset.id !== preset.id)
      }));
    }
    return response;
  };

  return {
    ...dataManagerHook,
    searchPresets,
    getPresetsByType,
    getPresetByHash,
    createPreset,
    updatePreset,
    removePreset
  };
}
