/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BackgroundBody } from './background-body';
import { EntitlementType } from './entitlement-type';
import { LocalizedFieldBody } from './localized-field-body';
import { SharedModalBody } from './shared-modal-body';
import { TargetPlatform } from './target-platform';
import { TdsGiftCardsUiModuleCodeHintBody } from './tds-gift-cards-ui-module-code-hint-body';
import { TdsGiftCardsUiModuleTopUpDoneBody } from './tds-gift-cards-ui-module-top-up-done-body';
import { UiModuleExperimentGroup } from './ui-module-experiment-group';

/**
 * 
 * @export
 * @interface TdsGiftCardsUiModuleBody
 */
export interface TdsGiftCardsUiModuleBody {
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    moduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    moduleType: TdsGiftCardsUiModuleBodyModuleTypeEnum;
    /**
     * 
     * @type {Array<TargetPlatform>}
     * @memberof TdsGiftCardsUiModuleBody
     */
    targetPlatform?: Array<TargetPlatform>;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof TdsGiftCardsUiModuleBody
     */
    caption?: LocalizedFieldBody;
    /**
     * 
     * @type {Array<EntitlementType>}
     * @memberof TdsGiftCardsUiModuleBody
     */
    entitlements?: Array<EntitlementType>;
    /**
     * 
     * @type {Array<UiModuleExperimentGroup>}
     * @memberof TdsGiftCardsUiModuleBody
     */
    experiments?: Array<UiModuleExperimentGroup> | null;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof TdsGiftCardsUiModuleBody
     */
    title: LocalizedFieldBody;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    partnerLogo?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    logo: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     * @deprecated
     */
    backgroundPortraitFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     * @deprecated
     */
    backgroundLandscapeFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     * @deprecated
     */
    backgroundMobileFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     * @deprecated
     */
    backgroundCtvFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     * @deprecated
     */
    backgroundDesktopFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     * @deprecated
     */
    backgroundTabletFillImage?: string;
    /**
     * 
     * @type {BackgroundBody}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepOneBackground?: BackgroundBody;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepOneHeading: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepOneSubheader?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepOneEmail: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepOnePassword: string;
    /**
     * 
     * @type {boolean}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepOneCheckbox?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepOneLegalCopy?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepOneCta: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepOneSignIn: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepTwoHeading: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepTwoSubheader?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepTwoHowToHelper?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepTwoHowToImage?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepTwoHowToHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepTwoHowToSteps?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepTwoHowToCtaOne?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepTwoHowToCtaTwo?: string;
    /**
     * 
     * @type {Array<TdsGiftCardsUiModuleCodeHintBody>}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepTwoCodeHints?: Array<TdsGiftCardsUiModuleCodeHintBody>;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepTwoCta: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeAHeading: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeASubheader?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeAPlanHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeAPlanName?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeAPlanDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeAPlanPrice?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeAPlanTotalLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeAPlanTotalPrice?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeAReminderHeading?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeAReminderOneStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeAReminderTwoEndDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeACheckbox?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeALegalCopy: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeACtaOne: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeACtaTwo: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeBHeadingTopUp: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeBSubheaderTopUp?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeBPlanHeaderTopUp?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeBPlanNameTopUp?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeBPlanDescriptionTopUp?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeBPlanPriceTopUp?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeBPlanTotalLabelTopUp?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeBPlanTotalPriceTopUp?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeBReminderHeadingTopUp?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeBReminderOneStartDateTopUp?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeBReminderTwoEndDateTopUp?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeBCheckboxTopUp?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeBLegalCopyTopUp: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeBCtaOneTopUp: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeBCtaTwoTopUp: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeCHeadingDtc: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeCSubheaderDtc?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeCPlanHeaderDtc?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeCPlanNameDtc?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeCPlanDescriptionDtc?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeCPlanPriceDtc?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeCPlanTotalLabelDtc?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeCPlanTotalPriceDtc?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeCBalanceDtc?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeCReminderHeadingDtc?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeCReminderOneStartDateDtc?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeCReminderTwoEndDateDtc?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeCReminderThreeDtc?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeCCheckboxDtc?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeCLegalCopyDtc: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeCCtaOneDtc: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeCCtaTwoDtc: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepThreeCBalanceDtcHeading?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     * @deprecated
     */
    stepFourBackgroundPortraitFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     * @deprecated
     */
    stepFourBackgroundLandscapeFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     * @deprecated
     */
    stepFourBackgroundMobileFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     * @deprecated
     */
    stepFourBackgroundCtvFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     * @deprecated
     */
    stepFourBackgroundDesktopFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     * @deprecated
     */
    stepFourBackgroundTabletFillImage?: string;
    /**
     * 
     * @type {BackgroundBody}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepFourBackground?: BackgroundBody;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepFourHeading: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepFourSubheader?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepFourCheckbox?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepFourLegalCopy?: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepFourCtaOne: string;
    /**
     * 
     * @type {string}
     * @memberof TdsGiftCardsUiModuleBody
     */
    stepFourCtaTwo: string;
    /**
     * 
     * @type {SharedModalBody}
     * @memberof TdsGiftCardsUiModuleBody
     */
    notSupportedPaymentMethodError: SharedModalBody;
    /**
     * 
     * @type {SharedModalBody}
     * @memberof TdsGiftCardsUiModuleBody
     */
    unableToRedeemError: SharedModalBody;
    /**
     * 
     * @type {SharedModalBody}
     * @memberof TdsGiftCardsUiModuleBody
     */
    usedAccountError: SharedModalBody;
    /**
     * 
     * @type {TdsGiftCardsUiModuleTopUpDoneBody}
     * @memberof TdsGiftCardsUiModuleBody
     */
    topUpDone: TdsGiftCardsUiModuleTopUpDoneBody;
}

/**
    * @export
    * @enum {string}
    */
export enum TdsGiftCardsUiModuleBodyModuleTypeEnum {
    TDS_GIFT_CARDS = 'TDS_GIFT_CARDS'
}



