import { atom, RecoilState } from 'recoil';
import { MediaResponse } from '../../../API';
import { DataManagerState, DataManagerStateFullReturnType } from '../../DataManager';
import { LiveSearchFormProps } from '../../../components/Collections/LiveSearch';
import { localAtom } from '../../../state/localStorageState';

export interface MediaStateFullReturnType extends DataManagerStateFullReturnType<MediaResponse, MediaResponse> {
  withSearchResultsIds: RecoilState<string[]>;
  withContentTypes: RecoilState<string[]>;
  withContentTypesAllowed: RecoilState<string[]>;
  withContentGroups: RecoilState<string[]>;
  withContentVertical: RecoilState<string[]>;
  withRating: RecoilState<string[]>;
  withLiveSearchFilter: RecoilState<LiveSearchFormProps>;
}

export function MediaState(name: string): MediaStateFullReturnType {
  const genericState = DataManagerState<MediaResponse, MediaResponse>(name);

  const withSearchResultsIds = atom<string[]>({
    key: `${name}.searchResultsIds`,
    default: []
  });

  const withContentTypes = atom<string[]>({
    key: `${name}.contentTypes`,
    default: []
  });

  const withContentTypesAllowed = atom<string[]>({
    key: `${name}.contentTypesAllowed`,
    default: []
  });

  const withContentGroups = atom<string[]>({
    key: `${name}.contentGroups`,
    default: []
  });

  const withContentVertical = atom<string[]>({
    key: 'contentVerticals.all',
    default: []
  });

  const withRating = atom<string[]>({
    key: `${name}.ratings`,
    default: []
  });

  const withLiveSearchFilter = localAtom<LiveSearchFormProps>({
    key: `${name}.liveSearchFilter`,
    default: {}
  });

  return {
    ...genericState,
    withSearchResultsIds,
    withContentTypes,
    withContentTypesAllowed,
    withContentGroups,
    withContentVertical,
    withRating,
    withLiveSearchFilter
  };
}
