import React from 'react';
import { takeWhile } from 'lodash-es';
import { Routes, Route, Navigate } from 'react-router-dom';
import FourOhFour from './components/404';
import { AssetsManager } from './components/Assets/AssetsManager';
import Collections from './components/Collections';
import Config from './components/Config';
import FallBackAssets from './components/Config/FallBackAssets';
import Seo from './components/Config/Seo';
import EPG from './components/EPG';
import EPGReport from './components/EPGReport';
import Layout from './components/Layout/Layout';
import Layouts from './components/Layouts';
import AutomationForm from './components/Login/AutomationForm';
import LoginForm from './components/Login/LoginForm';
import SportsManager from './components/Sports/SportsManager';
import Users from './components/Users';
import Welcome from './components/Welcome';
import { HPC } from './components/HPC';
import { LayoutsPageParams, useHomepage } from './hooks';
import { LayoutType } from './API';
import Payments from './components/Payments/Payments';
import { Plans } from './components/Payments/Plans';
import { Promotions } from './components/Payments/Promotions';
import { Coupons } from './components/Payments/Coupons';
import { DefaultPlanGroups } from './components/Payments/DefaultPlanGroups';
import { OfferMappings } from './components/Payments/OfferMappings';
import MetadataManager from './components/MetadataManager';

export const appRouteBasePath = '/app';

const path = (...args: Array<string | undefined>): string => takeWhile(args).join('/');

export const AppRoutes = {
  login: '/login',
  automatedTestingLogin: '/automation/login',
  404: `${appRouteBasePath}/404`,
  welcome: `${appRouteBasePath}/welcome`,
  epgBase: path(appRouteBasePath, 'epg'),
  epg: (categoryBundleId?: string, categoryId?: string): string =>
    path(appRouteBasePath, 'epg', 'program', categoryBundleId, categoryId),
  epgReport: path(appRouteBasePath, 'epg', 'report'),
  collectionsBase: path(appRouteBasePath, 'collections'),
  collections: path(appRouteBasePath, 'collections', 'mixed'),
  vodCollections: path(appRouteBasePath, 'collections', 'vod'),
  collection: (id: string): string => path(appRouteBasePath, 'collections', 'mixed', id),
  vodCollection: (id: string): string => path(appRouteBasePath, 'collections', 'vod', id),
  configuration: `${appRouteBasePath}/configuration`,
  configSports: `${appRouteBasePath}/configuration/sports`,
  configSeo: `${appRouteBasePath}/configuration/metadata`,
  sports: `${appRouteBasePath}/sports`,
  users: `${appRouteBasePath}/users`,
  layouts: (params: LayoutsPageParams): string =>
    path(appRouteBasePath, 'layouts', params.type, params.countries, params.layoutId, params.pageUrl),
  assets: (type?: string): string => path(appRouteBasePath, 'assets', type),
  heroPresetCollections: (collectionId?: string): string =>
    path(appRouteBasePath, 'collections', 'heroes', collectionId),
  payments: path(appRouteBasePath, 'monetization'),
  paymentsPlans: path(appRouteBasePath, 'monetization', 'plans'),
  paymentsCoupons: path(appRouteBasePath, 'monetization', 'coupons'),
  paymentsPromotions: path(appRouteBasePath, 'monetization', 'promotions'),
  paymentsDefaults: path(appRouteBasePath, 'monetization', 'defaults'),
  paymentsOfferMappings: path(appRouteBasePath, 'monetization', 'offer-mappings'),
  metadataManager: `${appRouteBasePath}/metadata`
};

export function AppRouter(): JSX.Element {
  const { homepageRoute } = useHomepage();
  const showAutomationLogin = process.env.REACT_APP_SHOW_AUTOMATION_LOGIN_FORM === 'true';

  return (
    <Routes>
      <Route path={AppRoutes.login} element={<LoginForm />} />
      {showAutomationLogin && <Route path={AppRoutes.automatedTestingLogin} element={<AutomationForm />} />}
      <Route path={appRouteBasePath} element={<Layout />}>
        <Route path={AppRoutes.welcome} element={<Welcome />} />
        <Route path={AppRoutes.users} element={<Users />} />
        <Route path={AppRoutes.epgBase} element={<Navigate to={AppRoutes.epg()} replace />} />
        <Route path={AppRoutes.epg()} element={<EPG />} />
        <Route path={AppRoutes.epg(':categoryBundleId')} element={<EPG />} />
        <Route path={AppRoutes.epg(':categoryBundleId', ':categoryId')} element={<EPG />} />
        <Route path={AppRoutes.collectionsBase} element={<Navigate to={AppRoutes.collections} replace />} />
        <Route path={AppRoutes.epgReport} element={<EPGReport />} />
        <Route path={AppRoutes.collections} element={<Collections asMixedType />} />
        <Route path={AppRoutes.vodCollections} element={<Collections />} />
        <Route path={AppRoutes.collection(':id')} element={<Collections asMixedType />} />
        <Route path={AppRoutes.vodCollection(':id')} element={<Collections />} />
        <Route
          path={AppRoutes.layouts({
            type: ':type' as LayoutType,
            countries: ':countries',
            layoutId: ':layoutId',
            pageUrl: ':pageUrl'
          })}
          element={<Layouts />}
        />
        <Route path={AppRoutes.sports} element={<SportsManager />} />
        <Route path={AppRoutes.configuration} element={<Config />}>
          <Route path={AppRoutes.configSeo} element={<Seo />} />
          <Route path={AppRoutes.configSports} element={<FallBackAssets />} />
        </Route>
        <Route path={AppRoutes.assets()} element={<AssetsManager />} />
        <Route path={AppRoutes.assets(':assetType')} element={<AssetsManager />} />
        <Route path={AppRoutes.heroPresetCollections()} element={<HPC />} />
        <Route path={AppRoutes.heroPresetCollections(':id')} element={<HPC />} />
        <Route path={AppRoutes.payments} element={<Payments />}>
          <Route index element={<Navigate to={AppRoutes.paymentsPromotions} replace />} />
          <Route path={AppRoutes.paymentsPromotions} element={<Promotions />} />
          <Route path={AppRoutes.paymentsPlans} element={<Plans />} />
          <Route path={AppRoutes.paymentsCoupons} element={<Coupons />} />
          <Route path={AppRoutes.paymentsDefaults} element={<DefaultPlanGroups />} />
          <Route path={AppRoutes.paymentsOfferMappings} element={<OfferMappings />} />
        </Route>

        <Route path={AppRoutes.metadataManager} element={<MetadataManager />} />

        <Route path="" element={<Navigate to={homepageRoute} replace />} />
        <Route path="*" element={<FourOhFour />} />
      </Route>
      <Route index element={<Navigate to={homepageRoute} replace />} />
      <Route path="*" element={<FourOhFour />} />
    </Routes>
  );
}
