import React, { useEffect, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../../hooks';
import { Skeleton, Typography } from '@mui/material';
import AssetBrowser from '../../Assets/AssetBrowser';
import ShadowScroller from '../../shared/ShadowScroller';
import { useConfig } from '../../../hooks/Config';
import { withAllSettings } from '../../../state/Config';
import { useRecoilValue } from 'recoil';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Button } from '../../shared/Button/Button';
import { AssetTypes, AssetTypesEnum } from '../../../utils/assetTypes';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ConfigResponse } from '../../../API';
import Repeat from '../../shared/Repeat';
import { usePermissionsGuard } from '../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../state/theme';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  defaultAssetsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4)
  },
  headerText: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: '100%',
    marginBottom: theme.spacing(5)
  },
  formField: {
    width: 500,
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
    justifyContent: 'flex-start'
  },
  frameMain: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1
  },
  frameBottom: {
    padding: theme.spacing(4),
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper
  },
  footerButton: {
    minWidth: 200
  },
  skeletonRow: {
    margin: theme.spacing(4),
    height: theme.spacing(6)
  }
}));

export const testIds = {
  assetContainer: 'fallback-assets.asset-container',
  root: 'fallback-assets.root',
  saveButton: 'fallback-assets.save-button',
  validatorForm: 'fallback-assets.validator-form'
};

export default function FallBackAssets(): JSX.Element {
  const formRef = useRef<ValidatorForm>(null);
  const { classes } = useStyles();
  const { t } = useLocales();
  const { updateCmsConfig, fetchCmsConfig } = useConfig();
  const fallBackSettings = useRecoilValue(withAllSettings);
  const { canSave: hasSportsUpsertPermission } = usePermissionsGuard({
    homepageOption: HomepageOptions.SPORTS
  });

  useEffect(() => {
    fetchCmsConfig();
  }, []);

  const { handleSubmit, control, reset } = useForm<ConfigResponse>({
    defaultValues: fallBackSettings
  });

  useEffect(() => {
    reset(fallBackSettings);
  }, [fallBackSettings]);

  const onSubmit: SubmitHandler<ConfigResponse> = (appFallbackAssets) => {
    updateCmsConfig(appFallbackAssets);
  };

  const renderFormLabel = (label: string) => {
    return (
      <Typography component="div" color="textSecondary" variant="body2">
        {label}
      </Typography>
    );
  };
  // eslint-disable-next-line
  const renderAssetBrowser = (id: string, type: AssetTypesEnum, name: any) => (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <AssetBrowser value={value} assetId={id} assetType={type} onChange={onChange} />
      )}
    />
  );

  return (
    <div className={classes.root} data-testid={testIds.root}>
      <ValidatorForm
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        data-testid={testIds.validatorForm}
        className={classes.root}
      >
        <div className={classes.frameMain}>
          <ShadowScroller>
            {!fallBackSettings && (
              <Repeat n={6}>
                <Skeleton className={classes.skeletonRow} animation="wave" />
              </Repeat>
            )}
            {fallBackSettings && (
              <div className={classes.defaultAssetsContainer} data-testid={testIds.assetContainer}>
                <Typography className={classes.headerText} variant="h6">
                  {t('sports.default_assets_title')}
                </Typography>
                <div className={classes.formField}>
                  {renderFormLabel(t('sports.tournament_default_bg_image'))}
                  {renderAssetBrowser(
                    'id',
                    AssetTypes.sportsDefaultAssets.tournamentBg,
                    'sports.defaultTournamentBackgroundImage'
                  )}
                </div>
                <div className={classes.formField}>
                  {renderFormLabel(t('sports.tournament_default_splash_image'))}
                  {renderAssetBrowser(
                    'id',
                    AssetTypes.sportsDefaultAssets.tournamentSplash,
                    'sports.defaultTournamentSplashBackgroundImage'
                  )}
                </div>
                <div className={classes.formField}>
                  {renderFormLabel(t('sports.tournament_default_logo'))}
                  {renderAssetBrowser(
                    'id',
                    AssetTypes.sportsDefaultAssets.tournamentLogo,
                    'sports.defaultTournamentLogoImage'
                  )}
                </div>
                <div className={classes.formField}>
                  {renderFormLabel(t('sports.team_default_logo'))}
                  {renderAssetBrowser('id', AssetTypes.sportsDefaultAssets.teamLogo, 'sports.defaultTeamLogoImage')}
                </div>
              </div>
            )}
          </ShadowScroller>
        </div>
        <div className={classes.frameBottom}>
          <Button
            type="submit"
            className={classes.footerButton}
            data-testid={testIds.saveButton}
            disabled={!hasSportsUpsertPermission}
          >
            {t('general.save')}
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
}
