import { CopyUiModuleBody, CopyUiModuleBodyModuleTypeEnum } from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankCopyModule: NewModuleFunction<CopyUiModuleBody> = (module) => ({
  moduleType: CopyUiModuleBodyModuleTypeEnum.COPY,
  translationKey: '',
  moduleId: generateId(),
  ...module
});
