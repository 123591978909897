import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PercentCrop, PixelCrop } from 'react-image-crop';
import { FocusMode } from '../../../../API';
import Image from '../../../shared/Image';
import { CheckCircle, Warning } from '@mui/icons-material';
import { alpha, Stack, Tooltip } from '@mui/material';
import { checkAsset, shortenImageMimeType } from '../../../../utils/assetHelpers';
import { AssetGuideline, AssetTypesEnum } from '../../../../utils/assetTypes';
import { AssetGuidelinesBreakdown } from '../../AssetGuidelinesBreakdown';
import { AssetGuidelines } from '../../../../utils/assetGuidelines';

export interface IImageCropperProps {
  className?: string;
  'data-testid'?: string;
  src: string;
  fileType?: string;
  assetType: AssetTypesEnum;
  focusMode: FocusMode;
  aspectRatio?: number;
  maxWidth: number;
  maxHeight: number;
  initialX?: number;
  initialY?: number;
  onXPositionChange?: (value: number | undefined) => void;
  onYPositionChange?: (value: number | undefined) => void;
  showingPreview: boolean;
  setShowingPreview: (value: boolean) => void;
}

type PartialCrop = Pick<PercentCrop, 'unit'> & Partial<Omit<PercentCrop, 'unit'>>;

const crosshairColorLight = 'rgba(255,255,255,0.6)';
const crosshairColorDark = 'rgba(0,0,0,0.6)';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: 'rgba(0,0,0,0.5)',
    flexDirection: 'column',
    borderRadius: theme.shape.borderRadius,
    position: 'relative'
  },
  dimensions: {
    position: 'absolute',
    top: 0,
    right: 0,
    background: alpha(theme.palette.background.paper, 0.9),
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    border: `1px solid ${alpha(theme.palette.divider, 0.07)}`,
    padding: theme.spacing(1),
    fontSize: '0.8em',
    zIndex: 1
  },
  imageType: {
    color: theme.palette.text.secondary
  },
  rootInner: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    '& .ReactCrop__child-wrapper': {
      backgroundImage: 'url("/assets/transparency.png")'
    }
  },
  focusLine: {
    position: 'absolute',
    top: 0,
    left: 0,
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 1,
      left: 1
    },
    '&.y': {
      borderBottom: `1px dotted ${crosshairColorLight}`,
      '&::after': {
        borderBottom: `1px dotted ${crosshairColorDark}`
      }
    },
    '&.x': {
      borderRight: `1px dotted ${crosshairColorLight}`,
      '&::after': {
        borderRight: `1px dotted ${crosshairColorDark}`
      }
    }
  }
}));

export function ImageCropper({
  src,
  fileType,
  assetType,
  aspectRatio,
  focusMode,
  maxWidth,
  maxHeight,
  initialX,
  initialY,
  onXPositionChange,
  onYPositionChange,
  showingPreview,
  setShowingPreview,
  ...props
}: IImageCropperProps): React.ReactElement {
  const { classes } = useStyles();
  const [crop, setCrop] = useState<Crop>();
  const [image, setImage] = useState<HTMLImageElement>();
  const [positionX, setPositionX] = useState<number | undefined>();
  const [positionY, setPositionY] = useState<number | undefined>();
  const [containerFlexDirection, setContainerFlexDirection] = useState<'row' | 'column'>('row');

  useEffect(() => {
    reinitializeCrop();
  }, [focusMode, aspectRatio, image]);

  useEffect(() => {
    if (!image) return;
    const containerAspectRatio = maxWidth / maxHeight;
    const imageAspectRatio = getImageAspectRatio(image);
    if (containerFlexDirection === 'column' && imageAspectRatio < containerAspectRatio)
      setContainerFlexDirection('row');
    if (containerFlexDirection === 'row' && imageAspectRatio > containerAspectRatio)
      setContainerFlexDirection('column');
  }, [image, maxWidth, maxHeight]);

  const onImageLoad = (evt: Event) => {
    setImage(evt.target as HTMLImageElement);
  };

  const boundValue = (value: number, min: number, max: number) => Math.max(Math.min(value, max), min);

  const percentToAbsolute = (percent: number | undefined, absoluteSize: number | undefined) => {
    if (percent === undefined || !absoluteSize) return undefined;
    return Math.round((percent / 100) * absoluteSize);
  };

  const percentYToAbsolute = (yPercent: number | undefined) => {
    return percentToAbsolute(yPercent, image?.naturalHeight);
  };

  const percentXToAbsolute = (xPercent: number | undefined) => {
    return percentToAbsolute(xPercent, image?.naturalWidth);
  };

  const updateCoordinateX = (xPercent: number | undefined) => {
    setPositionX(xPercent);
    onXPositionChange?.(percentXToAbsolute(xPercent));
  };

  const updateCoordinateY = (yPercent: number | undefined) => {
    setPositionY(yPercent);
    onYPositionChange?.(percentYToAbsolute(yPercent));
  };

  const reinitializeCrop = () => {
    if (!focusMode || !image) return;

    switch (focusMode) {
      case FocusMode.AUTO:
      case FocusMode.FACE:
        setCrop(undefined);
        updateCoordinateX(undefined);
        updateCoordinateY(undefined);
        return;
      case FocusMode.CUSTOM:
        setShowingPreview(false);
        return setCalculatedCrop();
      default:
        setShowingPreview(false);
        return setPositionalCrop();
    }
  };

  const getImageAspectRatio = (image: HTMLImageElement) => image.naturalWidth / image.naturalHeight;

  const aspectRatioDimensions = useMemo(() => {
    if (!image || !aspectRatio) return { width: 0, height: 0 };
    const imageAspectRatio = getImageAspectRatio(image);
    if (aspectRatio > imageAspectRatio) {
      return {
        width: 100,
        height: (100 * imageAspectRatio) / aspectRatio
      };
    }
    return {
      height: 100,
      width: (100 * aspectRatio) / imageAspectRatio
    };
  }, [image, aspectRatio]);

  const getInitialXPercentage = () => {
    if (!initialX || !image) return 50;
    return (initialX * 100) / image.naturalWidth;
  };

  const getInitialYPercentage = () => {
    if (!initialY || !image) return 50;
    return (initialY * 100) / image.naturalHeight;
  };

  const setCalculatedCrop = () => {
    if (!aspectRatio || !image) return;
    const { width, height } = image;
    const partialCrop: PartialCrop = { unit: '%', width: 100 };

    const newPositionX = positionX ?? getInitialXPercentage();
    partialCrop.x = boundValue(newPositionX - aspectRatioDimensions.width / 2, 0, 100 - aspectRatioDimensions.width);
    const newPositionY = positionY ?? getInitialYPercentage();
    partialCrop.y = boundValue(newPositionY - aspectRatioDimensions.height / 2, 0, 100 - aspectRatioDimensions.height);
    updateCoordinateX(newPositionX);
    updateCoordinateY(newPositionY);
    setCrop(makeAspectCrop(partialCrop, aspectRatio, width, height));
  };

  const setPositionalCrop = () => {
    if (!aspectRatio || !image) return;
    const { width, height } = image;
    const partialCrop: PartialCrop = { unit: '%', width: 100 };

    updateCoordinateX(undefined);
    updateCoordinateY(undefined);

    if (aspectRatioDimensions.width === 100) {
      // aspectRatio > imageAspectRatio (wider, shorter)
      switch (focusMode) {
        case FocusMode.BOTTOM_LEFT:
        case FocusMode.BOTTOM:
        case FocusMode.BOTTOM_RIGHT:
          return setCrop(
            makeAspectCrop({ ...partialCrop, y: 100 - aspectRatioDimensions.height }, aspectRatio, width, height)
          );
        case FocusMode.TOP_LEFT:
        case FocusMode.TOP:
        case FocusMode.TOP_RIGHT:
          return setCrop(makeAspectCrop({ ...partialCrop, y: 0 }, aspectRatio, width, height));
        default:
          return setCrop(centerCrop(makeAspectCrop(partialCrop, aspectRatio, width, height), width, height));
      }
    } else {
      // aspectRatio <= imageAspectRatio (narrower, taller)
      switch (focusMode) {
        case FocusMode.BOTTOM_LEFT:
        case FocusMode.LEFT:
        case FocusMode.TOP_LEFT:
          return setCrop(makeAspectCrop({ ...partialCrop, x: 0 }, aspectRatio, width, height));
        case FocusMode.BOTTOM_RIGHT:
        case FocusMode.RIGHT:
        case FocusMode.TOP_RIGHT:
          return setCrop(
            makeAspectCrop({ ...partialCrop, x: 100 - aspectRatioDimensions.width }, aspectRatio, width, height)
          );
        default:
          return setCrop(centerCrop(makeAspectCrop(partialCrop, aspectRatio, width, height), width, height));
      }
    }
  };

  const onCropChange = (pixelCrop: PixelCrop, percentageCrop: PercentCrop) => {
    if (!image) return;
    setCrop(pixelCrop);

    if (percentageCrop.width === 100) {
      // When scrubbing up and down
      const yPercent = percentageCrop.y + percentageCrop.height / 2;
      updateCoordinateY(yPercent);
    }
    if (percentageCrop.height === 100) {
      // When scrubbing horizontally
      const xPercent = percentageCrop.x + percentageCrop.width / 2;
      updateCoordinateX(xPercent);
    }
  };

  const previewDimensions = useMemo(() => {
    if (!image) return { width: 0, height: 0 };
    return {
      width: Math.round((image.naturalWidth * aspectRatioDimensions.width) / 100),
      height: Math.round((image.naturalHeight * aspectRatioDimensions.height) / 100)
    };
  }, [image, aspectRatio]);

  const getPreviewUrl = (imageBaseUrl: string) => {
    return imageBaseUrl + `?tr=w-${previewDimensions.width},h-${previewDimensions.height},${focusMode}`;
  };

  const fileTypeProper = shortenImageMimeType(fileType);
  const guidelinesCheckResult = image
    ? checkAsset(assetType, image.naturalWidth, image.naturalHeight, fileTypeProper)
    : undefined;

  return (
    <div className={classes.root} data-testid={props['data-testid']}>
      {!showingPreview && image && (
        <Stack className={classes.dimensions} direction="row" alignItems="center" gap={2}>
          {guidelinesCheckResult && AssetGuidelines[assetType] && (
            <Tooltip
              arrow
              placement="left"
              title={
                <AssetGuidelinesBreakdown
                  guidelines={AssetGuidelines[assetType] as AssetGuideline}
                  results={guidelinesCheckResult}
                  type={assetType}
                />
              }
            >
              {guidelinesCheckResult.adheres ? <CheckCircle color="success" /> : <Warning color="warning" />}
            </Tooltip>
          )}
          <div>
            {image.naturalWidth} × {image.naturalHeight}
          </div>
          <div className={classes.imageType}>{fileTypeProper}</div>
        </Stack>
      )}
      <div className={classes.rootInner}>
        {!showingPreview && (
          <ReactCrop style={{ maxWidth, maxHeight }} crop={crop} onChange={onCropChange} locked>
            <Image useLoadingSpinner spinnerSize={50} src={src} onLoad={onImageLoad} />
            {positionX !== undefined && (
              <div className={`${classes.focusLine} x`} style={{ width: `${positionX}%`, height: '100%' }} />
            )}
            {positionY !== undefined && (
              <div className={`${classes.focusLine} y`} style={{ height: `${positionY}%`, width: '100%' }} />
            )}
          </ReactCrop>
        )}
        {showingPreview && (
          <Image
            src={getPreviewUrl(src)}
            backgroundSize="contain"
            sx={{
              backgroundImage: 'url("/assets/transparency.png")',
              width: '100%',
              maxWidth: (maxWidth * aspectRatioDimensions.width) / 100,
              height: '100%',
              maxHeight: (maxHeight * aspectRatioDimensions.height) / 100,
              aspectRatio: (aspectRatio || 1) + '',
              objectFit: 'contain'
            }}
          />
        )}
      </div>
    </div>
  );
}
