import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Timezone } from '../../../../state/Timezone';
import { toLuxonDate } from '../../../../utils/dateHelpers';

export type DateTimeTzSize = 'small' | 'medium';
const useStyles = makeStyles<{ size: DateTimeTzSize }>()((theme, { size }) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(2, 0, 1),
    justifyContent: 'space-between',
    maxWidth: 300
  },
  timezone: {
    minWidth: 80
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: size === 'small' ? theme.typography.htmlFontSize * 0.65 : theme.typography.caption.fontSize
  },
  disabledText: {
    color: theme.palette.text.disabled,
    fontSize: size === 'small' ? theme.typography.caption.fontSize : theme.typography.body1.fontSize
  }
}));

function formatTimeWithOffset(date: Date | string, offset: number): string {
  // create date within local time
  const luxonDate = toLuxonDate(date);
  // create a date with offset time
  const offsetDate = toLuxonDate(date).plus({ minutes: offset * 60 - luxonDate.offset });

  // get an int expression of local/offset date
  const localInt = parseInt(luxonDate.toFormat('yyyyMMdd'));
  const offsetInt = parseInt(offsetDate.toFormat('yyyyMMdd'));

  // formatting time
  const formatted = offsetDate.toFormat('t');

  // print +1 and -1 based on day difference
  if (offsetInt > localInt) return `${formatted} (+1)`;
  if (localInt > offsetInt) return `${formatted} (-1)`;

  return formatted;
}

export const DateTimeTz = ({
  className,
  disabled = false,
  size = 'medium',
  timezones,
  time
}: {
  className?: string;
  disabled?: boolean;
  size?: DateTimeTzSize;
  timezones: Timezone[];
  time?: Date | string;
}): React.ReactElement => {
  const { classes, cx } = useStyles({ size });

  try {
    return (
      <div className={cx(classes.root, className)}>
        {timezones
          .filter((timezone) => !timezone.isLocal)
          .map((tz) => (
            <div key={tz.label} className={classes.timezone}>
              <Typography className={classes.label} variant="caption">
                {tz.label}
              </Typography>
              <Typography className={cx({ [classes.disabledText]: disabled })}>
                {time ? formatTimeWithOffset(time, tz.offset) : '–'}
              </Typography>
            </div>
          ))}
      </div>
    );
  } catch {
    return <></>;
  }
};
