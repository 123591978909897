import React, { forwardRef } from 'react';
import { GeoBlockUiModuleBody } from '../../../../../API';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocales } from '../../../../../hooks';
import { FormBody, FormField } from '../../styles';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { LocaleKeys } from '../../../../../locales/i18n';

export const testIds = {
  formBody: 'geo-block.form-body',
  formField: (name: keyof GeoBlockUiModuleBody): string => `geo-block.form-field.${name}`
};

const GeoBlockForm = forwardRef<UIModuleTypeForm<GeoBlockUiModuleBody>>(() => {
  const { control } = useFormContext<GeoBlockUiModuleBody>();
  const { t } = useLocales();

  const LokaliseField = (name: keyof GeoBlockUiModuleBody, required = false): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.geo_block.fields.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  return (
    <FormBody data-testid={testIds.formBody}>
      <div>{LokaliseField('body', true)}</div>
      <div>{LokaliseField('legal', true)}</div>
    </FormBody>
  );
});

GeoBlockForm.displayName = 'GeoBlockForm';

export default GeoBlockForm;
