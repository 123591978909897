/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { EmbeddedSpinPremia } from './embedded-spin-premia';
import { EntitlementType } from './entitlement-type';
import { LocalizedFieldBody } from './localized-field-body';
import { OneBrandPromoUiModuleBodyPaymentMethodOptionBody } from './one-brand-promo-ui-module-body-payment-method-option-body';
import { OneBrandPromoUiModuleBodyPlanCardPartnerLogoBody } from './one-brand-promo-ui-module-body-plan-card-partner-logo-body';
import { TargetPlatform } from './target-platform';
import { UiModuleExperimentGroup } from './ui-module-experiment-group';

/**
 * 
 * @export
 * @interface OneBrandPromoUiModuleBody
 */
export interface OneBrandPromoUiModuleBody {
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    moduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    moduleType: OneBrandPromoUiModuleBodyModuleTypeEnum;
    /**
     * 
     * @type {Array<TargetPlatform>}
     * @memberof OneBrandPromoUiModuleBody
     */
    targetPlatform?: Array<TargetPlatform>;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof OneBrandPromoUiModuleBody
     */
    caption?: LocalizedFieldBody;
    /**
     * 
     * @type {Array<EntitlementType>}
     * @memberof OneBrandPromoUiModuleBody
     */
    entitlements?: Array<EntitlementType>;
    /**
     * 
     * @type {Array<UiModuleExperimentGroup>}
     * @memberof OneBrandPromoUiModuleBody
     */
    experiments?: Array<UiModuleExperimentGroup> | null;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof OneBrandPromoUiModuleBody
     */
    title: LocalizedFieldBody;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    portraitFillImage: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    landscapeFillImage: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    mobileFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    ctvFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    desktopFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    tabletFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    popupHeader: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    popupSubheader: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    popupText?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    popupCtaText: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    popupAnonSignInCtaText?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    popupAuthSignInCtaText?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    popupValuePropositions?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    popupLegalDisclosure?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    popupGiftCardCta?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    fullHeader: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    fullSubheader: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    fullValuePropositions?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    fullCtaText: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    fullText?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    fullAnonSignInCtaText?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    fullAuthSignInCtaText?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    fullLegalDisclosure?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    fullGiftCardCta?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planPickerHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planPickerSubheader?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planPickerValuePropositionHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planPickerValuePropositions?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planPickerLegalDisclosure?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planPickerFreeAccountCtaText?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planPickerInstructions?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planPickerPartnerDisclaimer?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planPickerPlanName?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planPickerPlanDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planPickerPlanPrice?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planPickerPlanSavings?: string;
    /**
     * 
     * @type {number}
     * @memberof OneBrandPromoUiModuleBody
     */
    planPickerPlanPriority?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OneBrandPromoUiModuleBody
     */
    isPlanPickerPlanHighlighted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OneBrandPromoUiModuleBody
     */
    isPlanPickerPlanDefault?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planPickerPriceTag?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planPickerCurrentPriceTag?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    paymentMethodHeader?: string;
    /**
     * 
     * @type {Array<OneBrandPromoUiModuleBodyPaymentMethodOptionBody>}
     * @memberof OneBrandPromoUiModuleBody
     */
    paymentMethodOptions?: Array<OneBrandPromoUiModuleBodyPaymentMethodOptionBody>;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planSelectionHeader?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OneBrandPromoUiModuleBody
     */
    planSelectionToggle?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planSelectionToggleOpt1?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planSelectionToggleOpt2?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OneBrandPromoUiModuleBody
     */
    isPlanCardBundlePlan?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planCardVixLogo?: string;
    /**
     * 
     * @type {Array<OneBrandPromoUiModuleBodyPlanCardPartnerLogoBody>}
     * @memberof OneBrandPromoUiModuleBody
     */
    planCardPartnerLogos?: Array<OneBrandPromoUiModuleBodyPlanCardPartnerLogoBody>;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planCardChoosePlanCta?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planCardMoreBenefitsCta?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OneBrandPromoUiModuleBody
     */
    isAMonthlyPlan?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planCardTag?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    collapseMoreBenefitsCta?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planCardLegalDisclosure?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planCardLegalDisclosureDetails?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planCardLegalDisclosureBody?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planCardTier?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planSwitchingHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    planSwitchingSubheader?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    ctaAcceptChange?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    headerFreeCard?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    subheaderFreeCard?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    listOfLostBenefits?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    ctaCancelPremium?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    ctaKeepSubscription?: string;
    /**
     * 
     * @type {EmbeddedSpinPremia}
     * @memberof OneBrandPromoUiModuleBody
     */
    spinPremia?: EmbeddedSpinPremia;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    giftCardPortraitFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    giftCardLandscapeFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    giftCardMobileFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    giftCardCtvFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    giftCardDesktopFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    giftCardTabletFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    giftCardBalanceReminderHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    giftCardBalanceReminderSubtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    giftCardBalanceReminderOne?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    giftCardBalanceReminderTwo?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    giftCardBalanceReminderCtaOne?: string;
    /**
     * 
     * @type {string}
     * @memberof OneBrandPromoUiModuleBody
     */
    giftCardBalanceReminderCtaTwo?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OneBrandPromoUiModuleBodyModuleTypeEnum {
    ONE_BRAND_PROMO = 'ONE_BRAND_PROMO'
}



