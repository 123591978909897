import React, { useEffect, useState } from 'react';
import { ChevronRight, KeyboardArrowDown } from '@mui/icons-material';
import { Collapse, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { VersionLogTag, VersionLog } from '../../../../API';
import { useLocales, useTheme, useTimezones } from '../../../../hooks';
import Button from '../../../shared/Button';
import { VersionLogCommitItem } from './VersionLogCommitItem';

const useStyles = makeStyles()((theme) => ({
  tagItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(4)
  },
  topLine: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  commitsContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius
  }
}));

interface VersionLogTagItemProps {
  versionLog: VersionLog;
  tag: VersionLogTag;
  githubProject: string;
  defaultIsOpen?: boolean;
  onExpand?: (state: boolean) => unknown;
}

export const testIds = {
  tagItem: (tag: string): string => `tagitem-${tag}`,
  toggleTagItem: (tag: string): string => `tagitem-toggle-${tag}`
};

export const VersionLogTagItem = ({
  versionLog,
  tag,
  githubProject,
  defaultIsOpen = false,
  onExpand
}: VersionLogTagItemProps): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { timestamp } = useTimezones();
  const { formControlColor } = useTheme();
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const toggleIsOpen = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  useEffect(() => {
    onExpand && onExpand(isOpen);
  }, [isOpen]);

  return (
    <div className={classes.tagItem}>
      <div className={classes.topLine}>
        <Typography variant="body1">v{tag.tag}</Typography>
        <Typography variant="body2" color="textSecondary">
          {t('version_log.released_on')} {`${timestamp(tag.date)}`}
        </Typography>
      </div>
      <Button
        variant="text"
        color={formControlColor}
        size="small"
        onClick={toggleIsOpen}
        startIcon={isOpen ? <KeyboardArrowDown /> : <ChevronRight />}
        data-testid={testIds.toggleTagItem(tag.tag)}
      >
        {t(`version_log.${isOpen ? 'hide' : 'show'}_${tag.commits.length === 1 ? '1' : 'n'}_commit`, {
          n: tag.commits.length
        })}
      </Button>
      <Collapse
        in={isOpen}
        className={classes.commitsContainer}
        unmountOnExit
        mountOnEnter
        data-testid={testIds.tagItem(tag.tag)}
      >
        {tag.commits.map((commit) => (
          <VersionLogCommitItem key={commit} commit={versionLog.commits[commit]} githubProject={githubProject} />
        ))}
      </Collapse>
    </div>
  );
};
