import { atom, RecoilState, RecoilValueReadOnly, selector, selectorFamily } from 'recoil';
import { DataManagerState, DataManagerStateFullReturnType } from '../../DataManager';
import {
  SmartQueryFieldContentType,
  SortDirection,
  EntitlementType,
  ContentCollectionResponse,
  ContentCollectionBody,
  SmartQueryBody,
  SmartQueryFieldResponse,
  SmartQueryOperatorResponse,
  SmartQuerySortingResponse,
  CollectionAsset
} from '../../../API';
import {
  CollectionSearchParams,
  ExtendedGetCollectionParams
} from '../../../components/Collections/CollectionsPanel/CollectionsBrowser';
import { localAtom } from '../../../state/localStorageState';
import { DEFAULT_PAGINATION_LIMIT } from '../../../utils/appDefaults';
// eslint-disable-next-line max-len
import { ContentSearchFilter } from '../../../components/Collections/CollectionsPanel/ContentSearch/ContentSearchFilters';
import { validCountryCodeFilter, validEnumArrayFilter, validEnumFilter } from '../../../utils/atomHelpers';
import { DisplayAsOptions } from '../../../utils/types/genericTypes';

export enum CollectionsPanelTabViewOptions {
  CONTENT_SEARCH,
  LIVE_SEARCH,
  CHANNEL_SEARCH,
  QUERY_BUILDER,
  BROWSE_COLLECTONS,
  VOD_SEARCH
}

export type SavedSearchParams = { vodCollection?: CollectionSearchParams; mixedCollection?: CollectionSearchParams };

export interface CollectionsStateFullReturnType
  extends DataManagerStateFullReturnType<ContentCollectionResponse, ContentCollectionBody> {
  withCollectionContentTypes: RecoilState<SmartQueryFieldContentType[] | undefined>;
  withCollectionQueryFields: RecoilState<SmartQueryFieldResponse[]>;
  withCollectionQueryOperators: RecoilState<SmartQueryOperatorResponse[]>;
  withCollectionQuerySortings: RecoilState<SmartQuerySortingResponse[]>;
  withDisplayAs: RecoilState<DisplayAsOptions>;
  withCollectionsPanelTabView: RecoilState<CollectionsPanelTabViewOptions>;
  withScrollTo: RecoilState<string | undefined>;
  withCollectionSearchParams: RecoilState<ExtendedGetCollectionParams>;
  withActiveContentSearchParams: RecoilState<ContentSearchFilter[]>;
  withSelectedCollectionContent: RecoilState<string[] | undefined>;
  withSelectedCollectionAsset: RecoilState<CollectionAsset[] | undefined>;
  withPinnedCollectionContent: RecoilState<string[] | null | undefined>;
  withCollectionsExpanded: RecoilState<boolean>;
  withCollectionCountryFilter: RecoilState<string[]>;
  withSelectedCollectionQuery: RecoilState<SmartQueryBody | undefined>;
  withCollectionQuery: (entityId: string | undefined) => RecoilValueReadOnly<SmartQueryBody | undefined>;
  withAllGenres: RecoilState<string[]>;
  withEntitlementsFilter: RecoilState<EntitlementType[]>;
  withAssociatedLayoutsCollection: RecoilState<ContentCollectionResponse | undefined>;
  withSplitPaneSizes: RecoilState<number[]>;
  withMixedCollectionDisplay: RecoilState<boolean>;
  withLastSavedSearchParams: RecoilState<SavedSearchParams>;
}

export function CollectionsState(name: string): CollectionsStateFullReturnType {
  const genericState = DataManagerState<ContentCollectionResponse, ContentCollectionBody>(name);

  const withDisplayAs = localAtom<DisplayAsOptions>({
    key: `${name}.displayAs`,
    default: DisplayAsOptions.PORTRAIT,
    filter: validEnumFilter(DisplayAsOptions)
  });

  const withCollectionContentTypes = atom<SmartQueryFieldContentType[] | undefined>({
    key: `${name}.collections.collectionContentType`,
    default: undefined
  });

  const withCollectionQueryFields = atom<SmartQueryFieldResponse[]>({
    key: `${name}.collectionQueryFields`,
    default: []
  });

  const withCollectionQueryOperators = atom<SmartQueryOperatorResponse[]>({
    key: `${name}.collectionQueryOperators`,
    default: []
  });

  const withCollectionQuerySortings = atom<SmartQuerySortingResponse[]>({
    key: `${name}.collectionQuerySortings`,
    default: []
  });

  const withCollectionsPanelTabView = atom<CollectionsPanelTabViewOptions>({
    key: `${name}.collectionsPanelTabView`,
    default: CollectionsPanelTabViewOptions.CONTENT_SEARCH
  });

  const withScrollTo = atom<string | undefined>({
    key: `${name}.scrollTo`,
    default: undefined
  });

  const withCollectionSearchParams = localAtom<ExtendedGetCollectionParams>({
    key: `${name}.collectionSearchParams`,
    default: {
      limit: DEFAULT_PAGINATION_LIMIT,
      sortBy: 'createdDate',
      sortDirection: SortDirection.DESC
    }
  });

  const withActiveContentSearchParams = localAtom<ContentSearchFilter[]>({
    key: `${name}.activeContentSearchParams`,
    default: [],
    filter: validEnumArrayFilter(ContentSearchFilter)
  });

  const withSelectedCollectionContent = selector<string[] | undefined>({
    key: `${name}.selectedCollectionContent`,
    get: ({ get }) => {
      const collection = get(genericState.withSelected);
      if (collection) return collection.contents;
    },
    set: ({ set }, newValue) => {
      set(
        genericState.withSelected,
        (collection) =>
          ({
            ...collection,
            contents: newValue as string[]
          }) as ContentCollectionResponse
      );
    }
  });

  const withSelectedCollectionAsset = selector<CollectionAsset[] | undefined>({
    key: `${name}.selectedCollectionAsset`,
    get: ({ get }) => {
      const collection = get(genericState.withSelected);
      if (collection) return collection.assets;
    },
    set: ({ set }, newValue) => {
      set(
        genericState.withSelected,
        (collection) =>
          ({
            ...collection,
            assets: newValue as CollectionAsset[],
            contents: (newValue as CollectionAsset[]).map(({ assetId }) => assetId) as string[]
          }) as ContentCollectionResponse
      );
    }
  });

  const withPinnedCollectionContent = selector<string[] | null | undefined>({
    key: `${name}.pinnedCollectionContent`,
    get: ({ get }) => {
      const collection = get(genericState.withSelected);
      if (collection) return collection.pinnedContents;
    },
    set: ({ set }, newValue) => {
      set(
        genericState.withSelected,
        (collection) =>
          ({
            ...collection,
            pinnedContents: newValue as string[]
          }) as ContentCollectionResponse
      );
    }
  });

  const withSelectedCollectionQuery = selector<SmartQueryBody | undefined>({
    key: `${name}.selectedCollectionQuery`,
    get: ({ get }) => get(genericState.withSelected)?.query,
    set: ({ set }, newValue) => {
      set(
        genericState.withSelected,
        (collection) =>
          ({
            ...collection,
            query: newValue as SmartQueryBody
          }) as ContentCollectionResponse
      );
    }
  });

  const withCollectionQuery = selectorFamily<SmartQueryBody | undefined, string | undefined>({
    key: `${name}.collectionQuery`,
    get:
      (entityId) =>
      ({ get }) => {
        if (!entityId) {
          return undefined;
        }
        const selectedId = get(genericState.withSelectedId);
        if (selectedId === entityId) {
          return get(withSelectedCollectionQuery);
        }
        return get(genericState.withAllRecordsById)[entityId]?.query;
      }
  });

  const withCollectionsExpanded = atom<boolean>({
    key: `${name}.collectionsExpanded`,
    default: false
  });

  const withCollectionCountryFilter = localAtom<string[]>({
    key: `${name}.collectionCountryFilter`,
    default: [],
    filter: validCountryCodeFilter
  });

  const withEntitlementsFilter = atom<EntitlementType[]>({
    key: `${name}.entitlements`,
    default: [EntitlementType.ANON, EntitlementType.FREE, EntitlementType.SUB]
  });

  const withAllGenres = atom<string[]>({
    key: 'genres.all',
    default: []
  });

  const withAssociatedLayoutsCollection = atom<ContentCollectionResponse | undefined>({
    key: `${name}.showingAssociatedLayoutsModal`,
    default: undefined
  });

  const withSplitPaneSizes = localAtom<number[]>({
    key: `${name}.splitPanePosition`,
    default: []
  });

  const withMixedCollectionDisplay = atom<boolean>({
    key: `${name}.mixedCollectionDisplay`,
    default: false
  });

  const withLastSavedSearchParams = localAtom<SavedSearchParams>({
    key: `${name}.lastSavedSearch`,
    default: {}
  });

  return {
    ...genericState,
    withDisplayAs,
    withCollectionContentTypes,
    withCollectionQueryFields,
    withCollectionQueryOperators,
    withCollectionQuerySortings,
    withCollectionsPanelTabView,
    withScrollTo,
    withCollectionSearchParams,
    withActiveContentSearchParams,
    withSelectedCollectionContent,
    withSelectedCollectionAsset,
    withPinnedCollectionContent,
    withSelectedCollectionQuery,
    withCollectionQuery,
    withCollectionsExpanded,
    withCollectionCountryFilter,
    withAllGenres,
    withEntitlementsFilter,
    withAssociatedLayoutsCollection,
    withSplitPaneSizes,
    withMixedCollectionDisplay,
    withLastSavedSearchParams
  };
}
