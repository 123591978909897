import React, { useEffect } from 'react';
import { useLocales, useTheme } from '../../../../hooks';
import { Autocomplete, Skeleton, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { VersionedDocumentStatus, VllCategoriesBundleResponse } from '../../../../API';
import { useRecoilValue } from 'recoil';
import CountryBadgesMerged from '../../../shared/CountryBadgesMerged';
import DraftBadge from '../../../shared/DraftBadge';
import { useNavigate, useParams } from 'react-router-dom';
import { useData } from '../../../../data-layer';
import { AppRoutes } from '../../../../Routes';

const useStyles = makeStyles()((theme) => ({
  autoComplete: {},
  regionOption: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  skeletonLoader: {
    flexGrow: 1,
    marginTop: 12,
    height: 28
  },
  textField: {
    '&, & input': {
      cursor: 'pointer'
    },
    '& input': {
      minWidth: '0 !important'
    },
    '& .MuiInput-root': {
      paddingRight: '10px !important'
    }
  }
}));

export const testIds = {
  autocomplete: 'epg-region-selector.autocomplete',
  textField: 'epg-region-selector.text-field',
  regionOption: 'epg-region-selector.region-option',
  draftBadge: 'epg-region-selector.draft-badge',
  countriesBadge: 'epg-region-selector.countries-badge'
};

export function EPGRegionSelector(): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const navigate = useNavigate();
  const {
    categoryBundles: {
      hook: { setSelected: setSelectedCategoryBundleId },
      state: {
        withAllRecords: withAllCategoryBundles,
        withSelectedCategoryExists,
        withSelected: withSelectedCategoryBundle,
        withSelectedId: withSelectedCategoryBundleId
      }
    },
    categories: {
      hook: { setSelected: setSelectedCategoryId },
      state: { withSelected: withSelectedCategory, withSelectedId: withSelectedCategoryId, withRecordById }
    }
  } = useData();
  const categoryBundles = useRecoilValue(withAllCategoryBundles);
  const selectedCategoryBundle = useRecoilValue(withSelectedCategoryBundle);
  const selectedCategoryBundleId = useRecoilValue(withSelectedCategoryBundleId);
  const selectedCategory = useRecoilValue(withSelectedCategory);
  const selectedCategoryId = useRecoilValue(withSelectedCategoryId);

  const { categoryBundleId, categoryId } = useParams<{
    categoryBundleId: string | undefined;
    categoryId: string | undefined;
  }>();
  const selectedCategoryExists = useRecoilValue(withSelectedCategoryExists(categoryId ?? ''));
  const category = useRecoilValue(withRecordById(categoryId));

  useEffect(() => {
    if (!categoryBundles || !categoryBundles.length) return;
    if (!categoryBundleId) {
      const categoryBundle = categoryBundles.find((bundle) => bundle.entityId === selectedCategoryBundleId);
      if (categoryBundle) {
        let newCategoryId;
        if (selectedCategoryId && categoryBundle.categories.find((catId) => catId === selectedCategoryId)) {
          newCategoryId = selectedCategoryId;
        } else {
          newCategoryId = categoryBundle.categories[0];
        }
        navigate(AppRoutes.epg(selectedCategoryBundleId, newCategoryId));
      } else {
        navigate(AppRoutes.epg(categoryBundles[0].entityId, categoryBundles[0].categories[0]));
      }
      return;
    }
    if (categoryBundles.find((bundle) => bundle.entityId === categoryBundleId)) {
      setSelectedCategoryBundleId(categoryBundleId);
    }
  }, [categoryBundles]);

  useEffect(() => {
    if (!categoryBundles) return;
    if (selectedCategoryBundle?.entityId !== categoryBundleId) {
      setSelectedCategoryBundleId(categoryBundleId);
    }
    if (selectedCategory?.entityId !== categoryId) {
      setSelectedCategoryId(categoryId);
    }
  }, [categoryBundleId, categoryId]);

  useEffect(() => {
    if (!selectedCategoryBundle) return;
    if (category && (!selectedCategory || selectedCategory.entityId !== categoryId) && selectedCategoryExists) {
      setSelectedCategoryId(category?.entityId);
    } else if (selectedCategory?.entityId === categoryId && !selectedCategoryExists) {
      setSelectedCategoryId(selectedCategoryBundle.categories[0]);
    }
  }, [selectedCategoryBundle, categoryId, selectedCategory, selectedCategoryExists, category]);

  const onCategoryBundleChange = (values: VllCategoriesBundleResponse[]) => {
    if (!categoryBundles) return;
    const categoryBundle = values.filter((bundle) => selectedCategoryBundle?.entityId !== bundle.entityId)[0];
    const newCategoryBundleId = categoryBundle?.entityId;
    if (!newCategoryBundleId) return;
    const newCategoryId = categoryBundle.categories[0];
    return navigate(AppRoutes.epg(newCategoryBundleId, newCategoryId));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderRegionOption = (bundle: VllCategoriesBundleResponse, props: any) => {
    // eslint-disable-next-line react/prop-types
    const newProps = { ...props, className: `${classes.regionOption} ${props.className}` };
    delete newProps.onDelete;

    return (
      <div className={classes.regionOption} {...newProps}>
        {bundle.regionName}
        {bundle.countries.length > 0 && (
          <CountryBadgesMerged countryCodes={bundle.countries} data-testid={testIds.countriesBadge} />
        )}
        {bundle.status === VersionedDocumentStatus.DRAFT && <DraftBadge data-testid={testIds.draftBadge} />}
      </div>
    );
  };

  return (
    <>
      {!(categoryBundles && selectedCategoryBundle) && <Skeleton className={classes.skeletonLoader} animation="wave" />}
      {categoryBundles && selectedCategoryBundle && (
        <Autocomplete
          className={classes.autoComplete}
          multiple
          options={categoryBundles}
          disabled={!categoryBundles}
          getOptionLabel={(bundle) => bundle.regionName + bundle.entityId} // Used for searching
          disableClearable
          blurOnSelect
          fullWidth
          color={formControlColor}
          renderOption={(props, bundle) => (
            <li {...props}>{renderRegionOption(bundle, { 'data-testid': testIds.regionOption })}</li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.textField}
              color={formControlColor}
              label={t('region')}
              data-testid={testIds.textField}
            />
          )}
          renderTags={(value: VllCategoriesBundleResponse[], getTagProps) =>
            value.map((bundle: VllCategoriesBundleResponse, index: number) =>
              renderRegionOption(bundle, { ...getTagProps({ index }) })
            )
          }
          value={[selectedCategoryBundle]}
          onChange={(_, changedValues) => onCategoryBundleChange(changedValues)}
          isOptionEqualToValue={(option, value) => option.entityId === value.entityId}
          data-testid={testIds.autocomplete}
        />
      )}
    </>
  );
}
