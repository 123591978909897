import React from 'react';
import { Chip, Tooltip, Typography } from '@mui/material';
import { useLocales } from '../../../../hooks';
import { TargetPlatform } from '../../../../API';
import { Theme } from '@mui/system';
import { difference } from 'lodash-es';
import { Cancel, CheckCircle, Devices } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

export interface IPlatformBadgeProps {
  platforms: TargetPlatform[] | undefined;
  allPlatforms?: TargetPlatform[] | undefined;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  badge: {
    height: 24
  },
  iconContainer: {
    display: 'flex'
  },
  tooltipContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: theme.spacing(4)
  },
  tooltipPlatformRow: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    whiteSpace: 'nowrap'
  }
}));

const allPlatformsDefault = Object.values(TargetPlatform);

export function PlatformBadge({
  platforms,
  allPlatforms = allPlatformsDefault
}: IPlatformBadgeProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();

  const ChipLabel = () => {
    if (!platforms || platforms.length === allPlatforms.length) {
      return <>{t('platforms.all_platforms')}</>;
    }
    if (platforms.length <= 2) {
      return <>{platforms.map(valueToString).join(', ')}</>;
    }
    if (platforms.length >= allPlatforms.length - 1) {
      return <>{t('platforms.all_but_x', { x: valueToString(difference(allPlatforms, platforms)[0]) })}</>;
    }
    return <>{t('platforms.n_platforms', { n: platforms.length })}</>;
  };

  const valueToString = (value: TargetPlatform) => t(`platforms.platform_name.${value}`);

  return (
    <Tooltip
      title={
        <div className={classes.tooltipContainer}>
          {allPlatforms.map((platform) => (
            <div className={classes.tooltipPlatformRow} key={platform}>
              {!platforms?.includes(platform as TargetPlatform) && <Cancel color={'error'} />}
              {platforms?.includes(platform as TargetPlatform) && <CheckCircle color={'success'} />}
              {valueToString(platform)}
            </div>
          ))}
        </div>
      }
      arrow
      placement="top"
    >
      <div className={classes.container}>
        <Typography className={classes.iconContainer} component="div" color="textSecondary">
          <Devices fontSize="small" />
        </Typography>
        <Chip className={classes.badge} label={<ChipLabel />} />
      </div>
    </Tooltip>
  );
}
