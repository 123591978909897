import React from 'react';
import { List, ListItem } from '@mui/material';
import { ApiErrorParser } from '.';
import { ApiErrorResponse, DeleteChannelErrorResponse, ErrorType } from '../../API';
import { AppRoutes } from '../../Routes';
import LinkWithIcon from '../../components/shared/LinkWithIcon';

export const isDeleteChannelError = (error?: ApiErrorResponse): error is DeleteChannelErrorResponse =>
  (error as DeleteChannelErrorResponse).type === ErrorType.DELETE_CHANNEL_ERROR;

export const parseDeleteChannelError: ApiErrorParser<DeleteChannelErrorResponse> = (error, { t }, details) => {
  if (!isDeleteChannelError(error)) return;
  const errorDetails = (
    <div>
      {t('general.channel')}: {(details as string[])[0]}
    </div>
  );
  const categories = error.associateCategoryBundles.map((bundle) => (
    <div key={bundle.regionId}>
      <div>
        {t('region')}: <strong>{bundle.regionName}</strong>
      </div>
      <List sx={{ listStyleType: 'disc', pl: 4 }}>
        {bundle.categories.map((category) => (
          <ListItem key={category.id} sx={{ display: 'list-item', p: 0 }}>
            <LinkWithIcon pathname={AppRoutes.epg(bundle.regionId, category.id)} title={category.title} />
          </ListItem>
        ))}
      </List>
    </div>
  ));
  return [errorDetails, ...categories];
};
