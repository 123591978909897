/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HealthCheckResponse } from '../model';
/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Provides online health status information of the **Vix CMS** application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/healthcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This intentionally throws an error. Useful to test new-relic metrics.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerFail: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/healthcheck/fail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * Provides online health status information of the **Vix CMS** application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthCheckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This intentionally throws an error. Useful to test new-relic metrics.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerFail(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerFail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * Provides online health status information of the **Vix CMS** application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: any): AxiosPromise<HealthCheckResponse> {
            return localVarFp.get(options).then((request) => request(axios, basePath));
        },
        /**
         * This intentionally throws an error. Useful to test new-relic metrics.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerFail(options?: any): AxiosPromise<void> {
            return localVarFp.healthControllerFail(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - interface
 * @export
 * @interface HealthApi
 */
export interface HealthApiInterface {
    /**
     * Provides online health status information of the **Vix CMS** application.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApiInterface
     */
    get(options?: any): AxiosPromise<HealthCheckResponse>;

    /**
     * This intentionally throws an error. Useful to test new-relic metrics.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApiInterface
     */
    healthControllerFail(options?: any): AxiosPromise<void>;

}

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI implements HealthApiInterface {
    /**
     * Provides online health status information of the **Vix CMS** application.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public get(options?: any) {
        return HealthApiFp(this.configuration).get(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This intentionally throws an error. Useful to test new-relic metrics.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public healthControllerFail(options?: any) {
        return HealthApiFp(this.configuration).healthControllerFail(options).then((request) => request(this.axios, this.basePath));
    }
}
