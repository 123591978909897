import React from 'react';

interface RepeatProps {
  children?: React.ReactNode;
  n: number;
}

function Repeat({ children, n }: RepeatProps): JSX.Element {
  if (!children) return <></>;
  return (
    <>
      {[...Array(n)].map((_, i) => (
        <React.Fragment key={i}>{children}</React.Fragment>
      ))}
    </>
  );
}

export default Repeat;
