import { DocumentLocale, TournamentListUiModuleBody, TournamentListUiModuleBodyModuleTypeEnum } from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankTournamentListModule: NewModuleFunction<TournamentListUiModuleBody> = (module) => ({
  title: {
    [DocumentLocale.ES]: ''
  },
  moduleType: TournamentListUiModuleBodyModuleTypeEnum.TOURNAMENT_LIST,
  templateUrlPath: '',
  tournamentIds: [],
  tournamentReferenceIds: [],
  moduleId: generateId(),
  ...module
});
