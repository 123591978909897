import { AxiosError } from 'axios';
import { isArray } from 'lodash-es';
import { ApiResponseBody } from '../API';
import { SupportedLanguage } from '../locales/i18n';

export type HttpError = AxiosError<{ message?: Record<SupportedLanguage, string> | Array<string>; statusCode: number }>;
export const SERVICE_UNAVAILABLE = { message: 'Service unavailable', statusCode: 503 };

export function parseError(error: HttpError, lang: SupportedLanguage, fallback: string): string {
  if (error.response?.status === SERVICE_UNAVAILABLE.statusCode) return SERVICE_UNAVAILABLE.message;
  if (error.response?.status === 500) return fallback;
  const message = error.response?.data?.message;
  return (message && (Array.isArray(message) ? message[0] : message[lang])) || error.message || fallback;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function isAxiosServerError(error: any): error is HttpError {
  return !!error && 'isAxiosError' in error && error.isAxiosError;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function isApiErrorResponse(response: any): response is ApiResponseBody {
  return !!response && 'errors' in response && isArray(response.errors);
}
