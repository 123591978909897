import React, { useEffect, useState } from 'react';
import MaterialDrawer from '@mui/material/Drawer';
import { DrawerProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ShadowScroller from '../ShadowScroller';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useLocales, useNotifications } from '../../../hooks';
import { useGlobalHistoryManager } from '../../../hooks/General/useGlobalHistoryManager';

export interface IDrawerProps extends DrawerProps {
  className?: string;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  footerLeft?: React.ReactNode;
  footerRight?: React.ReactNode;
  onSubmit?: React.FormEventHandler;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFormError?: (errors: any[]) => void;
  formRef?: React.LegacyRef<ValidatorForm>;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '62vw',
    minWidth: 980,
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  headerFooter: {
    padding: theme.spacing(4),
    background: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  body: {
    flexGrow: 1,
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`,
    borderLeftWidth: 0,
    borderRightWidth: 0
  }
}));

export function Drawer({
  anchor = 'right',
  className,
  headerLeft,
  headerRight,
  footerLeft,
  footerRight,
  onSubmit,
  onFormError,
  formRef,
  children,
  open,
  ...props
}: IDrawerProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { notifyError } = useNotifications();
  const { block, unblock } = useGlobalHistoryManager('drawer');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const newIsOpen = !!open;
    if (newIsOpen === isOpen) return;
    setIsOpen(newIsOpen);
    if (newIsOpen) {
      block();
    } else {
      unblock();
    }
  }, [open]);

  const defaultErrorHandler = () => {
    notifyError(t('general.form_error'));
  };

  const wrapContents = (contents: React.ReactNode) => {
    return formRef ? (
      <ValidatorForm
        className={classes.root}
        ref={formRef}
        onSubmit={(...a) => onSubmit?.(...a)}
        onError={onFormError || defaultErrorHandler}
        instantValidate={false}
      >
        {contents}
      </ValidatorForm>
    ) : (
      <div className={classes.root}>{contents}</div>
    );
  };

  return (
    <MaterialDrawer anchor={anchor} className={className} open={open} {...props}>
      {wrapContents(
        <>
          <div className={classes.headerFooter}>
            <div>{headerLeft}</div>
            <div>{headerRight}</div>
          </div>
          <div className={classes.body}>
            <ShadowScroller>{children}</ShadowScroller>
          </div>
          <div className={classes.headerFooter}>
            <div>{footerLeft}</div>
            <div>{footerRight}</div>
          </div>
        </>
      )}
    </MaterialDrawer>
  );
}
