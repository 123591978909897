import React, { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocales, useTheme } from '../../../../../hooks';
import { LocaleKeys } from '../../../../../locales/i18n';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { FormBody, SectionHeader } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { PlanSummaryUiModuleBody } from '../../../../../API';
import { Box, FormControlLabel, Switch } from '@mui/material';

export const testIds = {
  formBody: 'plan_summary.form-body',
  formField: (name: keyof PlanSummaryUiModuleBody): string => `plan_summary.form-field.${name}`
};

const PlanSummaryForm = forwardRef<UIModuleTypeForm<PlanSummaryUiModuleBody>>(() => {
  const { control } = useFormContext<PlanSummaryUiModuleBody>();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const LokaliseField = (name: keyof PlanSummaryUiModuleBody, required = false): React.ReactElement => (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <LokaliseAutocomplete
          label={t(`layouts.plan_summary.fields.${name}` as LocaleKeys)}
          name={name}
          value={value as string}
          onChange={onChange}
          required={required}
          data-testid={testIds.formField(name)}
        />
      )}
    />
  );

  return (
    <FormBody data-testid={testIds.formBody}>
      <SectionHeader>{t('layouts.plan_summary.headers.plan_summary_screen')}</SectionHeader>
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 4 }}>
        {LokaliseField('header')}
        {LokaliseField('planName', true)}
        {LokaliseField('priceFirstCharge', true)}
        {LokaliseField('offerDetails')}
        {LokaliseField('taxDisclaimer')}
        {LokaliseField('taxTitle')}
        {LokaliseField('planCost', true)}
        {LokaliseField('taxAmount')}
        {LokaliseField('totalAmount', true)}
        {LokaliseField('totalSummaryTitle', true)}
        {LokaliseField('signUpCta', true)}
        {LokaliseField('notNowCta', true)}
        {LokaliseField('legalDisclaimer', true)}
        <Controller
          control={control}
          name="checkboxConfirmation"
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              label={t('layouts.plan_summary.fields.checkboxConfirmation')}
              control={<Switch color={formControlColor} checked={value} onChange={onChange} />}
            />
          )}
        />
      </Box>
    </FormBody>
  );
});

PlanSummaryForm.displayName = 'PlanSummaryForm';

export default PlanSummaryForm;
