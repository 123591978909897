import { Audience, OperationIds } from '../../../../API';

import {
  CreateDataManager,
  DataManagerHookReturnType,
  DataManagerReturnType,
  DataManagerStateFullReturnType
} from '../../../DataManager';
import { useExperienceAPI } from '../../../../hooks/API/Experience/useExperienceAPI';

export type AudiencesDataManagerType = {
  state: DataManagerStateFullReturnType<Audience>;
  hook: DataManagerHookReturnType<Audience>;
};

export interface AudiencesDataManagerReturnType extends DataManagerReturnType<Audience> {
  state: AudiencesDataManagerType['state'];
  hook: () => AudiencesDataManagerType['hook'];
}

export const AudiencesDataManager = CreateDataManager<Audience, Audience, AudiencesDataManagerReturnType>({
  name: 'audiences',
  idField: 'hash',
  useApiHook: useExperienceAPI,
  isVersionedEntity: true,
  toString: () => 'AudiencesDataManager',
  operationIdGetAll: OperationIds.GET_ALL
});
