import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useLocales, useTheme } from '../../hooks';
import { Tab, Tabs } from '@mui/material';
import { PlanForm } from './Plans/PlanForm';
import { PromoForm } from './Promotions/PromoForm';
import { OfferMappingForm } from './OfferMappings/OfferMappingForm';
import { useData } from '../../data-layer';
import { AppRoutes } from '../../Routes';
import { Link, Outlet } from 'react-router-dom';
import { CloudSyncTwoTone, MonetizationOn, Sell, WorkspacePremium } from '@mui/icons-material';
import { LayoutType } from '../../API';

const tabHeight = 64;

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 4),
    display: 'flex',
    gap: theme.spacing(6),
    alignItems: 'center'
  },
  tab: {
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'center',
    minHeight: tabHeight,
    padding: theme.spacing(0, 5),
    '& > svg': {
      margin: '0 !important'
    }
  }
}));

export const testIds = {
  tabs: 'payments.tabs',
  tabPlans: 'payments.tabPlans',
  tabPromotions: 'payments.tabsPromotion',
  tabCoupons: 'payments.tabCoupons',
  tabDefaults: 'payments.tabDefaults',
  tabOfferMappings: 'payments.tabOfferMappings'
};

export default function Payments(): JSX.Element {
  const { t } = useLocales();
  const { plans, promotions, coupons, pages, audiences, offerMappings } = useData();
  const { classes } = useStyles();
  const { formControlColor } = useTheme();

  useEffect(() => {
    plans.hook.getAll();
    coupons.hook.getAll();
    promotions.hook.getAll();
    pages.hook.getBucket(LayoutType.PROMOS);
    audiences.hook.getAll();
    offerMappings.hook.getAll();
  }, []);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          <h2>{t('payments.monetization')}</h2>

          <Tabs
            data-testid={testIds.tabs}
            value={location.pathname}
            indicatorColor={formControlColor}
            textColor={formControlColor}
          >
            <Tab
              className={classes.tab}
              data-testid={testIds.tabPromotions}
              label={t('payments.promotions')}
              icon={<WorkspacePremium fontSize="medium" />}
              component={Link}
              to={AppRoutes.paymentsPromotions}
              value={AppRoutes.paymentsPromotions}
            />
            <Tab
              className={classes.tab}
              data-testid={testIds.tabPlans}
              label={t('payments.plans')}
              icon={<MonetizationOn fontSize="medium" />}
              to={AppRoutes.paymentsPlans}
              value={AppRoutes.paymentsPlans}
              component={Link}
            />
            <Tab
              className={classes.tab}
              data-testid={testIds.tabCoupons}
              label={t('payments.coupons')}
              icon={<Sell fontSize="medium" />}
              component={Link}
              to={AppRoutes.paymentsCoupons}
              value={AppRoutes.paymentsCoupons}
            />
            <Tab
              className={classes.tab}
              data-testid={testIds.tabOfferMappings}
              label={t('payments.offer_mappings')}
              icon={<CloudSyncTwoTone fontSize="medium" />}
              component={Link}
              to={AppRoutes.paymentsOfferMappings}
              value={AppRoutes.paymentsOfferMappings}
            />
            {/* Hiding this tab until we need it */}
            {/* <Tab
              className={classes.tab}
              data-testid={testIds.tabDefaults}
              label={t('payments.default_plan_groups')}
              icon={<Devices fontSize="medium" />}
              component={Link}
              to={AppRoutes.paymentsDefaults}
              value={AppRoutes.paymentsDefaults}
            /> */}
          </Tabs>
        </div>
        <Outlet />
      </div>
      <PlanForm />
      <PromoForm />
      <OfferMappingForm />
    </>
  );
}
