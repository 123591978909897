import React from 'react';
import { TdsGiftCardsUiModuleBody } from '../../../../../API';
import Localized from '../../../../shared/Localized';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';

export type TdsGiftCardsListItemProps = IBaseUIModuleListItemProps<TdsGiftCardsUiModuleBody>;

export const testIds = {
  title: 'tds-gift-cards-list-item.title'
};

export function TdsGiftCardsListItem({ uiModule, ...props }: TdsGiftCardsListItemProps): JSX.Element {
  return (
    <>
      <UIModuleListItem
        uiModule={uiModule}
        topLineTitle={<Localized prop={uiModule.title} data-testid={testIds.title} />}
        {...props}
      />
    </>
  );
}
