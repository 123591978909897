export const StatsigGates = {
  sportEventsInHero: 'fe-cms_sport_events_in_hero',
  pagePathInHero: 'fe-cms_page_path_in_hero',
  optionalChannelNumber: 'fe-cms_optional_channel_number',
  playbackPaywallModule: 'fe-cms-playback-active',
  heroCTVImgFillActive: 'fe-cms-hero-ctv-img-fill-active',
  heroMobileImgFillActive: 'fe-cms-hero-mobile-img-fill-active',
  linkingAccountModule: 'fe-cms-linking-account-active',
  pinCollectionContentsActive: 'se-cms-pin-collection-contents-active',
  reverseChronologicalActive: 'fe-cms-reverse-chronological-active'
};

export const SeStatsigGates = {
  HeroContentsScheduleActive: 'se-hero-contents-schedule-active',
  ReplaceSearchWithAdvancedSearch: 'se-replace-search-with-advanced-search'
};
