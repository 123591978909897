import { isApiErrorResponse, isAxiosServerError, parseError, SERVICE_UNAVAILABLE } from '../../utils/parseError';
import { debounce } from 'lodash-es';
import { SnackbarKey } from 'notistack';
import { NotificationDetails, useNotifications } from './useNotifications';
import { useLocales } from './useLocales';
import { ApiErrors } from '../../utils/apiErrors';
import { ErrorType } from '../../API';

export type ErrorDetails = NotificationDetails;

export type UseHandleErrorReturnType = {
  handleError: (err: unknown, fallback: string, details?: ErrorDetails) => void;
};

export const isForbiddenError = (error: unknown): boolean =>
  isAxiosServerError(error) && error?.response?.status === 403;

const notify = (notifyError: (message: string) => SnackbarKey, message: string) => notifyError(message);
const notifyErrorDebounced = debounce(notify, 500);

export function useHandleError(): UseHandleErrorReturnType {
  const { notifyError } = useNotifications();
  const locales = useLocales();
  const { currentLang, t } = locales;

  const handleError = (error: unknown, fallback: string, details?: ErrorDetails) => {
    if (isAxiosServerError(error)) {
      const data = error?.response?.data;
      const debugInfo = {
        response: {
          status: error?.response?.status,
          statusText: error?.response?.statusText,
          data
        },
        request: {
          url: error?.config?.url,
          method: error?.config?.method,
          params: error?.config?.params,
          data: error?.config?.data
        }
      };

      if (isApiErrorResponse(data)) {
        data.errors?.forEach((error) => {
          const errorMessage = error.message[currentLang];
          const errorDetails = ApiErrors[error.type as ErrorType]?.(error, locales, details) || details;
          notifyError(errorMessage, errorDetails, undefined, debugInfo);
        });
      } else {
        const message = parseError(error, currentLang, fallback);
        if (message === SERVICE_UNAVAILABLE.message) {
          notifyErrorDebounced(notifyError, t('errors.service_unavailable'));
          return;
        }
        notifyError(message, details, undefined, debugInfo);
      }
    }
  };

  return { handleError };
}
