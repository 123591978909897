/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { EntitlementType } from './entitlement-type';
import { HeroUIModuleContentTreatment } from './hero-uimodule-content-treatment';
import { HeroUiModuleChannelItem } from './hero-ui-module-channel-item';
import { HeroUiModuleClipsItem } from './hero-ui-module-clips-item';
import { HeroUiModuleCollectionLinkItem } from './hero-ui-module-collection-link-item';
import { HeroUiModuleEventsItem } from './hero-ui-module-events-item';
import { HeroUiModulePageItem } from './hero-ui-module-page-item';
import { HeroUiModulePromotionItem } from './hero-ui-module-promotion-item';
import { HeroUiModuleVodItem } from './hero-ui-module-vod-item';
import { LocalizedFieldBody } from './localized-field-body';
import { TargetPlatform } from './target-platform';
import { UiModuleExperimentGroup } from './ui-module-experiment-group';

/**
 * 
 * @export
 * @interface HeroUiModuleBody
 */
export interface HeroUiModuleBody {
    /**
     * 
     * @type {string}
     * @memberof HeroUiModuleBody
     */
    moduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof HeroUiModuleBody
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof HeroUiModuleBody
     */
    moduleType: HeroUiModuleBodyModuleTypeEnum;
    /**
     * 
     * @type {Array<TargetPlatform>}
     * @memberof HeroUiModuleBody
     */
    targetPlatform?: Array<TargetPlatform>;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof HeroUiModuleBody
     */
    caption?: LocalizedFieldBody;
    /**
     * 
     * @type {Array<EntitlementType>}
     * @memberof HeroUiModuleBody
     */
    entitlements?: Array<EntitlementType>;
    /**
     * 
     * @type {Array<UiModuleExperimentGroup>}
     * @memberof HeroUiModuleBody
     */
    experiments?: Array<UiModuleExperimentGroup> | null;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof HeroUiModuleBody
     */
    title: LocalizedFieldBody;
    /**
     * 
     * @type {HeroUIModuleContentTreatment}
     * @memberof HeroUiModuleBody
     */
    contentTreatment: HeroUIModuleContentTreatment;
    /**
     * 
     * @type {string}
     * @memberof HeroUiModuleBody
     */
    heroPresetCollection?: string;
    /**
     * 
     * @type {number}
     * @memberof HeroUiModuleBody
     */
    scrollingTimeSeconds?: number;
    /**
     * 
     * @type {Array<HeroUiModuleVodItem | HeroUiModuleChannelItem | HeroUiModuleEventsItem | HeroUiModulePageItem | HeroUiModuleCollectionLinkItem | HeroUiModulePromotionItem | HeroUiModuleClipsItem>}
     * @memberof HeroUiModuleBody
     */
    heroContents: Array<HeroUiModuleVodItem | HeroUiModuleChannelItem | HeroUiModuleEventsItem | HeroUiModulePageItem | HeroUiModuleCollectionLinkItem | HeroUiModulePromotionItem | HeroUiModuleClipsItem>;
}

/**
    * @export
    * @enum {string}
    */
export enum HeroUiModuleBodyModuleTypeEnum {
    HERO = 'HERO'
}



