import React, { useState } from 'react';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { LinkingAccountUiModuleBody } from '../../../../../API';
import Button from '../../../../shared/Button';
import { StayPrimaryPortrait } from '@mui/icons-material';
import { useLocales, useTheme } from '../../../../../hooks';
import { LinkingAccountPreview } from '../../ContentPreview/LinkingAccountPreview';

export interface LinkingAccountListItemProps extends IBaseUIModuleListItemProps {
  uiModule: LinkingAccountUiModuleBody;
}

export const testIds = {
  button: 'linking-account-list-item.preview-button'
};

export function LinkingAccountListItem({ uiModule, ...props }: LinkingAccountListItemProps): React.ReactElement {
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const [open, setOpen] = useState(false);

  const onPreviewButtonClick = () => setOpen(true);

  return (
    <>
      <UIModuleListItem
        uiModule={uiModule}
        topLineTitle={t('layouts.ui_module_linking_account')}
        collectionLine={[
          <Button
            key="preview"
            variant="text"
            size="small"
            startIcon={<StayPrimaryPortrait />}
            color={formControlColor}
            onClick={onPreviewButtonClick}
            data-testid={testIds.button}
          >
            {t('layouts.preview_contents')}
          </Button>
        ]}
        {...props}
      />
      <LinkingAccountPreview module={uiModule} open={open} setOpen={setOpen} />
    </>
  );
}
