import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Box, Skeleton, Tab, Tabs, Typography } from '@mui/material';
import { useLocales, useTheme } from '../../../hooks';
import { Drawer } from '../Drawer/Drawer';
import { AppRegistration, Bookmarks, Info, Launch } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { useData } from '../../../data-layer';
import AssociatedCollections from './AssociatedCollections';
import { IdBadge } from '../IdBadge';
import { ObjectTreeView } from '../ObjectTreeView';
import Button from '../Button';
import { getMcpUrl, shouldRenderContentAlerts } from '../../../utils/mediaUtilities';
import Localized from '../Localized';
import ShadowScroller from '../ShadowScroller';
import { HeroPresets } from './HeroPresets';
import { ContentAlerts, testIds as contentAlertsTestIds } from './ContentAlerts';
import { makeStyles } from 'tss-react/mui';
import { ReverseChronological } from './ReverseChronological';
import { usePermissionsGuard } from '../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../state/theme';
import { useRoute } from '../../../hooks/General/useRoute';

export const testIds = {
  root: 'content-drawer.root',
  closeBtn: 'content-drawer.close-btn',
  saveAndPublishBtn: 'content-drawer.save-and-publish-btn',
  contentAlertsTestIds
};

const useStyles = makeStyles()({
  tabs: {
    minHeight: 54,
    height: 54
  },
  container: {
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  tabPanel: {
    padding: 0,
    height: '100%'
  }
});

enum ContentDrawerTab {
  INFO = 'info',
  COLLECTIONS = 'collections',
  HERO_PRESETS = 'hero-presets',
  ENRICH = 'enrich'
}

export default function ContentDrawer(): JSX.Element {
  const {
    media: {
      state: { withRecordById, withFormMetadata },
      hook: { closeForm }
    }
  } = useData();

  const { classes } = useStyles();
  const [tab, setTab] = useState(ContentDrawerTab.INFO);
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const formMetaData = useRecoilValue(withFormMetadata);
  const contentId = formMetaData.record?.contentId;
  const content = useRecoilValue(withRecordById(contentId));

  const { isAdmin } = usePermissionsGuard({
    homepageOption: HomepageOptions.METADATA
  });
  const { isUnderMetadataRoute } = useRoute();

  const handleClose = () => {
    closeForm();
  };

  const handleMcpClick = () => {
    if (!content) return;
    const mcpContentUrl = getMcpUrl(content);
    if (mcpContentUrl) {
      window.open(mcpContentUrl, '_blank');
    }
  };

  return (
    <Drawer
      open={formMetaData.isShowingForm}
      onClose={handleClose}
      headerLeft={
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4, alignItems: 'center' }}>
          <Typography component="div" variant="h6">
            {content ? (
              <Localized prop={content.title} />
            ) : (
              <Skeleton animation="wave" width={200} sx={{ marginLeft: 4, height: 24 }} />
            )}
          </Typography>
          <IdBadge id={content?.contentId ?? ''} />
          <Button color={formControlColor} variant="text" endIcon={<Launch />} onClick={handleMcpClick}>
            {t('content.drawer.mcp_link')}
          </Button>
        </Box>
      }
      headerRight={contentId && shouldRenderContentAlerts(content) && <ContentAlerts contentId={contentId} />}
      footerRight={
        <Button color="grey" onClick={handleClose} data-testid={testIds.closeBtn}>
          {t('general.cancel')}
        </Button>
      }
    >
      <div className={classes.container} data-testid={testIds.root}>
        <TabContext value={tab}>
          <Tabs
            value={tab}
            onChange={(_, newTab) => setTab(newTab)}
            indicatorColor={formControlColor}
            textColor={formControlColor}
            className={classes.tabs}
          >
            <Tab
              label={t('content.drawer.metadata')}
              value={ContentDrawerTab.INFO}
              icon={<Info fontSize="small" />}
              iconPosition="start"
              className={classes.tabs}
            />
            <Tab
              label={t('content.drawer.associated_collections')}
              value={ContentDrawerTab.COLLECTIONS}
              icon={<AppRegistration fontSize="small" />}
              iconPosition="start"
              className={classes.tabs}
            />
            <Tab
              label={t('content.drawer.hero_presets')}
              value={ContentDrawerTab.HERO_PRESETS}
              icon={<Bookmarks fontSize="small" />}
              iconPosition="start"
              className={classes.tabs}
            />
            {isAdmin && isUnderMetadataRoute && (
              <Tab label={t('content.drawer.enrich')} value={ContentDrawerTab.ENRICH} className={classes.tabs} />
            )}
          </Tabs>
          <TabPanel className={classes.tabPanel} value={ContentDrawerTab.INFO}>
            <ShadowScroller>
              <Box sx={{ padding: 3 }}>
                <ObjectTreeView title={t('general.content')} object={content} imagePreviewHeight={69} />
              </Box>
            </ShadowScroller>
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={ContentDrawerTab.COLLECTIONS}>
            {content && <AssociatedCollections contentId={content.contentId} />}
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={ContentDrawerTab.HERO_PRESETS}>
            <ShadowScroller>{content && <HeroPresets contentId={content.contentId} />}</ShadowScroller>
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={ContentDrawerTab.ENRICH}>
            <ShadowScroller>{content && <ReverseChronological contentId={contentId} />}</ShadowScroller>
          </TabPanel>
        </TabContext>
      </div>
    </Drawer>
  );
}
