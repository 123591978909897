import { SavedSearchBody, SavedSearchResponse } from '../../../API';
// eslint-disable-next-line max-len
import { ContentSearchWithFilters } from '../../../components/Collections/CollectionsPanel/ContentSearch/ContentSearchFilters';
import { useSavedSearchesAPI } from '../../../hooks/API/SavedSearches/useSavedSearchesAPI';
import {
  CreateDataManager,
  DataManagerHookReturnType,
  DataManagerReturnType,
  DataManagerStateFullReturnType
} from '../../DataManager';

export type SavedSearchesHookReturnType = DataManagerHookReturnType<SavedSearchResponse, SavedSearchBody>;
export type SavedSearchStateReturnType = DataManagerStateFullReturnType<SavedSearchResponse, SavedSearchBody>;

export type SavedSearchesDataManagerType = {
  state: SavedSearchStateReturnType;
  hook: SavedSearchesHookReturnType;
};

export interface SavedSearchesDataManagerReturnType
  extends DataManagerReturnType<SavedSearchResponse, SavedSearchBody> {
  state: SavedSearchesDataManagerType['state'];
  hook: () => SavedSearchesDataManagerType['hook'];
}

export const SavedSearchesDataManager = CreateDataManager<
  SavedSearchResponse,
  SavedSearchBody,
  SavedSearchesDataManagerReturnType
>({
  name: 'savedSearches',
  idField: 'id',
  useApiHook: useSavedSearchesAPI,
  sortFunctionInitializer() {
    return (a, b) => {
      return (a.name ?? '').localeCompare(b.name ?? '');
    };
  },
  generateNew: (query: ContentSearchWithFilters = {} as ContentSearchWithFilters) => ({
    id: '',
    query,
    name: ''
  }),
  toString: (savedSearch) => savedSearch.name ?? ''
});
