import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { PageLayoutResponseV2 } from '../../../../API';
import { withTimelineTracks, withNumTimelineTracks, withLiveLayouts } from '../../../../state/Layouts';
import { TimelineTracks } from '../../../../utils/timelines';
import { themeColors } from '../../../../theme';
import CountryBadgesMerged from '../../../shared/CountryBadgesMerged';
import { useResponsive } from '../../../../hooks/General/useResponsive';

const listItemHeight = 66;
const timelineSeparation = 16;
const timelineMarkerRadius = 5.5;
const timelinePadding = 20;

const liveColor = themeColors.orange;
const timelineColor = '#000';
const triangleOffset = 3;
const triangleSize = 7;
const useStyles = makeStyles<void, 'tooltipContainer'>()((_theme, _params, classes) => ({
  timeline: {
    display: 'table-cell',
    minHeight: listItemHeight,
    position: 'relative',
    height: '100%',
    '&:hover': {
      [`& .${classes.tooltipContainer}`]: {
        visibility: 'visible'
      }
    }
  },
  canvas: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  tooltipContainer: {
    position: 'absolute',
    bottom: 1,
    visibility: 'hidden',
    zIndex: 10
  },
  tooltipWrapper: {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: triangleSize,
      height: triangleSize,
      backgroundColor: timelineColor,
      left: -triangleOffset,
      transform: 'rotate(45deg)',
      top: `calc(50% - ${triangleOffset}px)`
    }
  },
  tooltip: {
    display: 'inline-flex'
  },
  tooltipCountries: {
    position: 'relative',
    zIndex: 1,
    border: `1px solid ${timelineColor}`,
    borderRadius: 99
  }
}));

export const testIds = {
  canvas: 'layout-list-item-graph.canvas',
  tooltip: 'layout-list-item-graph.tooltip'
};

export interface ILayoutListItemGraphProps {
  layout: PageLayoutResponseV2;
}

function getXPosition(index: number) {
  return timelinePadding + timelineSeparation * index - 0.5;
}

function drawTimeline(
  ctx: CanvasRenderingContext2D,
  tracks: TimelineTracks,
  timelineWidth: number,
  isLive: boolean,
  height: number
) {
  ctx.clearRect(0, 0, timelineWidth, listItemHeight);
  tracks.tracks.forEach((track, i) => {
    if (track.countries.length) {
      drawLine(ctx, i, !!track.starting, height);
    }
  });
  tracks.merges.forEach((merge) => {
    drawCurve(ctx, merge[0], merge[1], height);
  });
  tracks.splits.forEach((split) => {
    drawCurve(ctx, split[0], split[1], height);
  });
  drawCircleMarker(ctx, tracks.currentIndex, height);
  if (isLive) {
    drawLiveCircle(ctx, tracks.currentIndex, height);
  }
}

export function drawLine(ctx: CanvasRenderingContext2D, index: number, starting: boolean, height: number): void {
  ctx.beginPath();
  const x = getXPosition(index);
  ctx.moveTo(x, starting ? height / 2 : 0);
  ctx.lineTo(x, height);
  ctx.strokeStyle = timelineColor;
  ctx.stroke();
}

function drawCurve(ctx: CanvasRenderingContext2D, fromIndex: number, toIndex: number, height: number) {
  const x1 = getXPosition(fromIndex);
  const x2 = getXPosition(toIndex);
  const y1 = 0;
  const y2 = height / 2;
  const controlPointLength = Math.floor(height / 4);
  ctx.beginPath();
  ctx.moveTo(x1, y1);
  ctx.bezierCurveTo(x1, y1 + controlPointLength, x2, y2 - controlPointLength, x2, y2);
  ctx.strokeStyle = timelineColor;
  ctx.stroke();
}

function drawCircleMarker(ctx: CanvasRenderingContext2D, index: number, height: number) {
  ctx.beginPath();
  ctx.arc(getXPosition(index), height / 2 - 0.5, timelineMarkerRadius, 0, 2 * Math.PI, false);
  ctx.fillStyle = timelineColor;
  ctx.fill();
}

function drawLiveCircle(ctx: CanvasRenderingContext2D, index: number, height: number) {
  ctx.beginPath();
  ctx.arc(getXPosition(index), height / 2 - 0.5, timelineMarkerRadius - 1, 0, 2 * Math.PI, false);
  ctx.fillStyle = liveColor;
  ctx.fill();
}

function LayoutListItemGraph({ layout }: ILayoutListItemGraphProps): JSX.Element {
  const { classes } = useStyles();
  const canvas = React.useRef<HTMLCanvasElement>(null);
  const timelineTracks = useRecoilValue(withTimelineTracks);
  const numTimelineTracks = useRecoilValue(withNumTimelineTracks);
  const liveLayouts = useRecoilValue(withLiveLayouts);
  const { ref: container, width: canvasWidth, height: canvasHeight } = useResponsive(undefined, listItemHeight + 2);

  useEffect(() => {
    canvasDrawTimeline();
  }, [canvas, timelineTracks, canvasWidth, canvasHeight]);

  const canvasDrawTimeline = () => {
    if (canvas.current && timelineTracks) {
      const tracks = timelineTracks[layout.id];
      const ctx = canvas.current.getContext('2d');
      if (!ctx) return;
      drawTimeline(ctx, tracks, getTimelineWidth(), !!liveLayouts && !!liveLayouts[layout.id], canvasHeight);
    }
  };

  const getTimelineWidth = () => {
    return timelinePadding * 2 + (numTimelineTracks - 1) * timelineSeparation;
  };

  return (
    <div className={classes.timeline} ref={container} style={{ width: `${getTimelineWidth()}px` }}>
      <canvas
        className={classes.canvas}
        ref={canvas}
        width={`${canvasWidth}px`}
        height={`${canvasHeight}px`}
        data-testid={testIds.canvas}
        style={{ width: `${canvasWidth}px`, height: `${canvasHeight}px` }}
      ></canvas>
      {timelineTracks && (
        <div className={classes.tooltipContainer}>
          {timelineTracks[layout.id].tracks.map((track, i) => (
            <div key={i} className={classes.tooltipWrapper} style={{ left: getXPosition(i) + triangleOffset + 2 }}>
              <div className={classes.tooltip} data-testid={testIds.tooltip}>
                <div className={classes.tooltipCountries}>
                  <CountryBadgesMerged countryCodes={track.countries} />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default LayoutListItemGraph;
