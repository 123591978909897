import { atom, selector } from 'recoil';
import { UserResponse, PermissionsGroupId, Permission, UserPermissionsGroupBody } from '../../API';
import { PermissionsLevel, PermissionsMatrix } from '../../utils/types/permissionsTypes';

export enum JwtVersion {
  V1 = 'v1',
  V2 = 'v2'
}

type JwtClaims = {
  jwtVersion?: JwtVersion;
};

export type DecodedJwt = {
  claims?: JwtClaims;
};

export const withCurrentUser = atom<UserResponse | null>({
  key: 'auth.currentUser',
  default: null
});

export const withJwtVersion = atom<JwtVersion | undefined>({
  key: 'auth.jwtVersion',
  default: undefined
});

const getPermissionsMatrixWithFullAccess = () => {
  const matrix: PermissionsMatrix = {} as PermissionsMatrix;
  Object.values(PermissionsGroupId).forEach((permissionsGroupId) => {
    matrix[permissionsGroupId] = {
      [PermissionsLevel.UPSERT]: true,
      [PermissionsLevel.PUBLISH]: true,
      [PermissionsLevel.DELETE]: true,
      [PermissionsLevel.READ]: true
    };
  });
  return matrix;
};
const permissionsMatrixWithFullAccess: PermissionsMatrix = getPermissionsMatrixWithFullAccess();

const hasPermissions = (
  permissionsGroup: UserPermissionsGroupBody | undefined,
  permissionsLevel: PermissionsLevel,
  permissionsGroupId: PermissionsGroupId
) => {
  if (permissionsGroupId === PermissionsGroupId.ADMIN) return true;
  return Boolean(
    permissionsGroup?.permissions.includes(Permission.ADMIN) ||
      permissionsGroup?.permissions.includes(`${permissionsGroup.group}_ADMIN` as Permission) ||
      permissionsGroup?.permissions.includes(`${permissionsGroup.group}_${permissionsLevel}` as Permission)
  );
};

export const withCurrentUserPermissionsByGroup = selector<PermissionsMatrix>({
  key: 'auth.currentUserPermissionsByGroup',
  get: ({ get }) => {
    const jwtVersion = get(withJwtVersion);
    if (jwtVersion !== JwtVersion.V2) {
      return permissionsMatrixWithFullAccess;
    }
    const matrix: PermissionsMatrix = {} as PermissionsMatrix;
    const currentUserPermissionsGroups = get(withCurrentUser)?.permissionsGroups;
    Object.values(PermissionsGroupId).forEach((permissionsGroupId) => {
      const permissionsGroup = currentUserPermissionsGroups?.find(
        (userPermissions) => userPermissions.group === permissionsGroupId
      );
      matrix[permissionsGroupId] = {
        [PermissionsLevel.UPSERT]: hasPermissions(permissionsGroup, PermissionsLevel.UPSERT, permissionsGroupId),
        [PermissionsLevel.PUBLISH]: hasPermissions(permissionsGroup, PermissionsLevel.PUBLISH, permissionsGroupId),
        [PermissionsLevel.DELETE]: hasPermissions(permissionsGroup, PermissionsLevel.DELETE, permissionsGroupId),
        [PermissionsLevel.READ]: hasPermissions(permissionsGroup, PermissionsLevel.READ, permissionsGroupId)
      };
    });
    return matrix;
  }
});
