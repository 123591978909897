import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Chip, CircularProgress, InputAdornment, MenuItem, TextField } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useData } from '../../../../../../data-layer';
import Localized from '../../../../../shared/Localized';
import Image from '../../../../../shared/Image';
import { getAssetUrl } from '../../../../../../utils/assetHelpers';
import { makeStyles } from 'tss-react/mui';
import { useLocales, useTheme } from '../../../../../../hooks';
import FormControl from '../../../../../shared/FormControl';
import { RecordOfType } from '../../../../../../utils/types/genericTypes';

const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(4)
  },
  leagueMenuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  leagueImage: {
    width: 18
  },
  input: {
    '.MuiAutocomplete-input': {
      minWidth: 'auto !important'
    }
  }
}));

export const testIds = {
  sportsSelect: 'sports-event-form.sports-select',
  sportSelectTextField: 'sports-event-form.sport-select-text-field',
  sportsSelectItem: 'sports-event-form.sports-select-item'
};

interface SportOptionsMultiselect {
  sports: string[] | undefined;
  leagues: string[] | undefined;
  onSportsChange: (sports: string[]) => void;
  onLeaguesChange: (leagues: string[]) => void;
}

export function SportOptionsMultiselect({
  sports = [],
  leagues = [],
  onSportsChange,
  onLeaguesChange
}: SportOptionsMultiselect): React.ReactElement {
  const {
    sports: {
      state: { withAllRecords: withAllSports },
      hook: { getAll: getAllSports }
    },
    tournaments: {
      state: { withAllRecords: withAllTournaments, withTournamentsBySport },
      hook: { getAll: getAllTournaments }
    },
    collections: {
      state: { withMixedCollectionDisplay }
    }
  } = useData();

  const { classes, cx } = useStyles();
  const { formControlColor } = useTheme();
  const { t, localize } = useLocales();

  const allSports = useRecoilValue(withAllSports);
  const allLeagues = useRecoilValue(withAllTournaments);

  // TODO: Remove this logic when the LiveSearch pagination gets reworked
  const asMixedType = useRecoilValue(withMixedCollectionDisplay);

  const [sportIds, setSportIds] = useState<string[]>(sports);
  const [leagueIds, setLeagueIds] = useState<string[]>(leagues);

  const leaguesForSport = useRecoilValue(
    withTournamentsBySport(allSports?.filter((sport) => sportIds.includes(sport.sportId)).map((sport) => sport.id))
  );

  // Initialize sports and tourneys on mount
  useEffect(() => {
    if (!allSports) getAllSports();
    if (!allLeagues) getAllTournaments();
  }, []);

  useEffect(() => {
    const hasChanged = sports.some((sport) => !sportIds.includes(sport));
    if (hasChanged) {
      setSportIds(sports);
    }
  }, [sports]);

  useEffect(() => {
    const hasChanged = leagues.some((league) => !leagueIds.includes(league));
    if (hasChanged) {
      setLeagueIds(leagues);
    }
  }, [leagues]);

  useEffect(() => onSportsChange(sportIds), [sportIds]);
  useEffect(() => onLeaguesChange(leagueIds), [leagueIds]);

  const sportsBySportId = useMemo<RecordOfType<typeof allSports>>(
    () => (allSports ? allSports.reduce((current, sport) => ({ ...current, [sport.sportId]: sport }), {}) : {}),
    [allSports]
  );

  const sportsById = useMemo<RecordOfType<typeof allSports>>(
    () => (allSports ? allSports.reduce((current, sport) => ({ ...current, [sport.id]: sport }), {}) : {}),
    [allSports]
  );

  const leaguesById = useMemo<RecordOfType<typeof allLeagues>>(
    () =>
      allLeagues ? allLeagues.reduce((current, league) => ({ ...current, [league.tournamentId]: league }), {}) : {},
    [allLeagues]
  );

  // when the sports change, filter out any leagues that aren't part of the subset of leagues for selected sport(s)
  useEffect(() => {
    if (!allLeagues || !leaguesForSport) return;
    setLeagueIds((prev) => prev.filter((id) => leaguesForSport?.find((league) => league.tournamentId === id)));
  }, [leaguesForSport]);

  const leaguesSortedBySport = useMemo(() => {
    if (!allSports || !allLeagues) {
      return;
    }

    // sorts by the sport as the leagues are already sorted
    return [...(leaguesForSport || [])].sort((a, b) => {
      if (!a || !b) return 0;
      const sportA = sportsById[a.sport];
      const sportB = sportsById[b.sport];
      if (!sportA || !sportB) return 0;
      const sportAName = localize(sportA.name);
      const sportBName = localize(sportB.name);
      return sportAName.localeCompare(sportBName);
    });
  }, [leaguesForSport]);

  const handleSportChange = (event: React.SyntheticEvent<Element, Event>, value: typeof allSports) => {
    setSportIds(value?.map((sport) => (asMixedType ? sport.id : sport.sportId)) || []);
  };

  return (
    <div className={classes.container}>
      <FormControl>
        <Autocomplete
          multiple
          disabled={!allSports}
          disableCloseOnSelect
          data-testid={testIds.sportsSelect}
          value={sportIds.map((id) => (asMixedType ? sportsById[id] : sportsBySportId[id]))}
          onChange={handleSportChange}
          size="small"
          getOptionLabel={(option) => localize(option?.name)}
          options={allSports || []}
          renderOption={(props, option) => (
            <MenuItem {...props} data-testid={testIds.sportsSelectItem}>
              <Localized prop={option.name} />
            </MenuItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label={t('sports.sport')}
              className={classes.input}
              color={formControlColor}
              InputProps={{
                ...params.InputProps,
                startAdornment: !sportIds.length ? (
                  <InputAdornment position="start">
                    <Chip label={t('layouts.all_sports')} size="small" />
                  </InputAdornment>
                ) : (
                  params.InputProps.startAdornment
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {!allSports ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </InputAdornment>
                )
              }}
            />
          )}
        />
      </FormControl>
      <FormControl>
        <Autocomplete
          multiple
          disabled={!leaguesSortedBySport}
          disableCloseOnSelect
          limitTags={leaguesSortedBySport && 5}
          size="small"
          value={leagueIds.map((id) => leaguesById[id])}
          onChange={(event, value) => setLeagueIds(value.map((v) => v.tournamentId))}
          options={leaguesSortedBySport || []}
          getOptionLabel={(option) => localize(option?.name) || '...'}
          groupBy={(option) => localize(sportsById[option.sport]?.name)}
          renderOption={(props, option) => (
            <MenuItem {...props} className={cx(props.className, classes.leagueMenuItem)}>
              {option.logoImage && <Image src={getAssetUrl(option.logoImage)} className={classes.leagueImage} />}
              <Localized prop={option.name} />
            </MenuItem>
          )}
          renderTags={(value, getTagProps) =>
            value.map(
              (option, index) =>
                option && (
                  <Chip
                    {...getTagProps({ index })}
                    key={index}
                    label={
                      <div className={classes.leagueMenuItem}>
                        {option.logoImage && <Image src={getAssetUrl(option.logoImage)} sx={{ height: 12 }} />}
                        <Localized prop={option.name} />
                      </div>
                    }
                    size="small"
                  />
                )
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label={t('sports.league')}
              color={formControlColor}
              className={classes.input}
              InputProps={{
                ...params.InputProps,
                startAdornment: !leagueIds.length ? (
                  <InputAdornment position="start">
                    <Chip label={t('sports.all_leagues')} size="small" />
                  </InputAdornment>
                ) : (
                  params.InputProps.startAdornment
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {!leaguesSortedBySport ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </InputAdornment>
                )
              }}
            />
          )}
        />
      </FormControl>
    </div>
  );
}
