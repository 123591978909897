import { LokaliseKey } from '../../../API';
import { useLokaliseAPI } from '../../../hooks/API/Lokalise/useLokaliseAPI';
import { CreateDataManager, DataManagerReturnType, DataManagerStateFullReturnType } from '../../DataManager';
import { LokaliseHook, LokaliseHookReturnType } from './LokaliseHook';

export type LokaliseDataManagerType = {
  state: DataManagerStateFullReturnType<LokaliseKey, LokaliseKey>;
  hook: LokaliseHookReturnType;
};

export interface LokaliseDataManagerReturnType extends DataManagerReturnType<LokaliseKey> {
  state: LokaliseDataManagerType['state'];
  hook: () => LokaliseDataManagerType['hook'];
}

export const LokaliseDataManager = CreateDataManager<LokaliseKey, LokaliseKey, LokaliseDataManagerReturnType>({
  name: 'lokalise',
  idField: 'key',
  hookInitializer: LokaliseHook,
  useApiHook: useLokaliseAPI,
  toString: (lokaliseKey) => lokaliseKey.key
});
