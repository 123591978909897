import React, { useEffect, useState } from 'react';
import { CircularProgress, Tab, Tabs, Switch, FormControlLabel, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useHandleError, useLocales, useStatsig, useTheme } from '../../../../hooks';
import Button from '../../../shared/Button';
import Modal from '../../../shared/Modal';
import { StatsigGates } from '../../../../utils/consts/statsigGates';
import { useSetRecoilState } from 'recoil';
import { withDebugMenuFeatureFlags } from '../../../../state/DebugMenu';
import { useStatsigAPI } from '../../../../hooks/API/Statsig/useStatsigAPI';
import { HttpError } from '../../../../utils/parseError';
import { StatsigGateValue } from '../../../../API';

enum DebugMenuViewType {
  FEATURE_FLAGS = 'FEATURE_FLAGS'
}

const useStyles = makeStyles()((theme) => ({
  modal: {
    width: '62vw',
    height: '62vh'
  },
  tabText: {
    textTransform: 'none'
  },
  featureFlags: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    gap: theme.spacing(4),
    height: '100%',
    overflow: 'auto'
  },
  featureFlagToggle: {
    '&:hover': {
      background: theme.palette.divider
    }
  },
  loadingSpinner: {
    textAlign: 'center',
    marginTop: theme.spacing(10)
  }
}));

export const testIds = {
  closeModal: 'debug-menu-close-modal',
  featureFlagTab: 'debug-menu-feature-flag-tab',
  featureFlagToggle: 'debug-menu-flag-toggle',
  debugMenuModal: 'debug-menu-root'
};

export interface DebugMenuModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function FeatureFlagList(): React.ReactElement {
  const { classes } = useStyles();
  const { CheckGate } = useStatsig();
  const setFeatureFlags = useSetRecoilState(withDebugMenuFeatureFlags);
  const statsigApi = useStatsigAPI();
  const [isLoading, setIsLoading] = useState(true);
  const { handleError } = useHandleError();
  const [seStatsigGates, setSeStatsigGates] = useState<StatsigGateValue[]>([]);
  const { t } = useLocales();

  useEffect(() => {
    void getStatsigGates();
  }, []);

  const getStatsigGates = async () => {
    setIsLoading(true);
    try {
      const response = await statsigApi.getAll();
      setSeStatsigGates(response.data.body);
    } catch (err) {
      handleError(
        err,
        t('errors.general_api_error', {
          action: 'GET',
          entity: 'Statsig',
          statusCode: (err as HttpError)?.response?.status
        })
      );
    }
    setIsLoading(false);
  };

  const handleToggle = (flagName: string, state: boolean) => {
    setFeatureFlags((flags) => {
      return {
        ...flags,
        [flagName]: state
      };
    });
  };

  return (
    <div className={classes.featureFlags}>
      <Typography variant="h5">{t('debug_menu.cms')}</Typography>
      {Object.values(StatsigGates).map((flagName) => (
        <FormControlLabel
          key={flagName}
          className={classes.featureFlagToggle}
          control={
            <Switch
              key={flagName}
              checked={CheckGate(flagName)}
              color="secondary"
              onChange={(evt: React.ChangeEvent<HTMLInputElement>, val: boolean) => handleToggle(flagName, val)}
              data-testid={testIds.featureFlagToggle}
            />
          }
          label={flagName}
        />
      ))}
      <Typography variant="h5">{t('debug_menu.service_editorial')}</Typography>
      {!isLoading &&
        seStatsigGates.map((gate) => (
          <FormControlLabel
            key={gate.key}
            className={classes.featureFlagToggle}
            control={
              <Switch
                key={gate.key}
                checked={gate.enabled}
                color="secondary"
                data-testid={testIds.featureFlagToggle}
                disabled
              />
            }
            label={gate.key}
          />
        ))}
      {isLoading && (
        <div className={classes.loadingSpinner}>
          <CircularProgress size={50} color="inherit" />
        </div>
      )}
    </div>
  );
}

FeatureFlagList.displayName = 'FeatureFlagList';

function DebugLogModal({ isOpen, setIsOpen }: DebugMenuModalProps): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const closeDebugMenu = () => setIsOpen(false);
  const [selectedTab, setSelectedTab] = useState<DebugMenuViewType>(DebugMenuViewType.FEATURE_FLAGS);

  return (
    <Modal
      bodyClassName={classes.modal}
      open={isOpen}
      headerLeft={
        <Tabs
          value={selectedTab}
          textColor={formControlColor}
          indicatorColor={formControlColor}
          onChange={(event, newValue) => setSelectedTab(newValue)}
        >
          <Tab
            label={<span className={classes.tabText}>{t('debug_menu.feature_flags')}</span>}
            value={DebugMenuViewType.FEATURE_FLAGS}
            data-testid={testIds.featureFlagTab}
          />
        </Tabs>
      }
      footerRight={
        <Button size="small" onClick={closeDebugMenu} color="grey" data-testid={testIds.closeModal}>
          {t('general.close')}
        </Button>
      }
      data-testid={testIds.debugMenuModal}
      onClose={closeDebugMenu}
    >
      <>{selectedTab === DebugMenuViewType.FEATURE_FLAGS && <FeatureFlagList />}</>
    </Modal>
  );
}

export default DebugLogModal;
