import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ArrowBack, Edit } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { collectionsHeaderHeight } from '../../../utils/consts/collectionsConsts';
import IconButton from '../../shared/IconButton';
import Localized from '../../shared/Localized';
import { List, Skeleton, Typography } from '@mui/material';
import { PresetListItem } from '../PresetListItem';
import { getMediaGridTemplateColumns } from '../../../utils/styleUtils';
import { useData } from '../../../data-layer';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import HistoryManager from '../../shared/HistoryManager';
import RevisionManager from '../../shared/RevisionManager';
import { Stack } from '@mui/system';
import { UserBadge } from '../../shared/UserBadge';
import ShadowScroller from '../../shared/ShadowScroller';
import { DisplayAsOptions } from '../../../utils/types/genericTypes';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../Routes';
import { VersionedDocumentStatus } from '../../../API';
import DraftBadge from '../../shared/DraftBadge';
import NoMediaItems from '../../shared/NoMediaItems';
import { useConfirm, useLocales } from '../../../hooks';
import { Sortable, SortableGroups } from '../../shared/Sortable';
import { PresetsSkeleton } from '../PresetsSkeleton';
import { EditorNotes } from '../../shared/EditorNotes';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import { withHistoryManager } from '../../../state/General/withHistoryManager';
import { usePermissionsGuard } from '../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../state/theme';

export const HISTORY_MANAGER_ID = 'hpc';

export const testIds = {
  root: 'hpc-manager.root',
  title: 'hpc-manager.title',
  draft: 'hpc-manager.draft',
  backButton: 'hpc-manager.back-button',
  editButton: 'hpc-manager.edit-button',
  presetListLeft: 'hpc-manager.preset-list-left'
};

const useStyles = makeStyles<{ displayAs: DisplayAsOptions }>()((theme, { displayAs }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  topbar: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: collectionsHeaderHeight,
    background: theme.palette.background.paper,
    padding: theme.spacing(3, 4, 3, 1),
    gap: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  history: {
    width: 'min-content'
  },
  list: {
    minHeight: '100%',
    height: '100%',
    overflowX: 'hidden'
  },
  grid: {
    display: 'grid',
    minHeight: '100%',
    gridTemplateColumns: getMediaGridTemplateColumns(displayAs),
    gridTemplateRows: 'repeat(auto-fit, minmax(0, max-content))',
    gridGap: theme.spacing(2),
    padding: theme.spacing(2)
  },
  empty: {
    position: 'absolute',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(12),
    inset: 0,
    pointerEvents: 'none'
  }
}));

type HPCManagerProps = {
  collectionId: string;
  onBack?: () => unknown;
};

export default function HPCManager({ collectionId, onBack }: HPCManagerProps): React.ReactElement {
  const {
    canSave,
    canDelete,
    canPublish: canPublishGuard
  } = usePermissionsGuard({
    homepageOption: HomepageOptions.HPC
  });
  const {
    heroPresetCollections: {
      state: { withDisplayAs, withRecordById, withSelected, withSelectedPresets },
      hook: { edit, getRevisions, queueIdToFetch, update, publish }
    }
  } = useData();

  // The selected collection from recoil
  const [selectedCollection, setSelectedCollection] = useRecoilState(withSelected);
  const setSelectedPresets = useSetRecoilState(withSelectedPresets);
  // Determine if the collection passed is the selected one
  const isSelected = useMemo(() => selectedCollection?.entityId === collectionId, [collectionId, selectedCollection]);

  // The collection provided in props
  const collection = useRecoilValue(isSelected ? withSelected : withRecordById(collectionId));
  const [presets, setPresets] = useState<string[]>([]);
  const isLoading = useMemo(() => !collection, [collection]);

  const { t } = useLocales();
  const navigate = useNavigate();
  const displayAs = useRecoilValue(withDisplayAs);
  const { classes, cx } = useStyles({ displayAs });
  const { hasPermissions } = usePermissions();
  const { UPSERT: canUpsert, PUBLISH: canPublish } = hasPermissions(collection?.ownerPermissionsGroup);

  const { isClean: isHistoryManagerClean } = useRecoilValue(withHistoryManager);
  const { confirmUnsavedChanges } = useConfirm();

  const isList = displayAs === DisplayAsOptions.LIST;

  useEffect(() => {
    if (!collection && collectionId) {
      queueIdToFetch(collectionId);
    }
  }, [collectionId]);

  useEffect(() => {
    if (!collection) return;
    setPresets(collection.presets);
  }, [collection]);

  const handleOnBack = () => {
    navigate(AppRoutes.heroPresetCollections());
    onBack?.();
  };

  const handleOnEdit = async () => {
    const shallContinue = isHistoryManagerClean || (await confirmUnsavedChanges());
    if (shallContinue) {
      edit(collectionId);
    }
  };

  const handleOnSave = async () => {
    if (collection) {
      await update(collection.entityId, collection);
    }
  };

  const handleOnPublish = async () => {
    if (collection) {
      await publish(collection.entityId);
    }
  };

  const getRevisionsPromise = useCallback(
    async (limit?: number, page?: number) => {
      if (collection && isSelected) {
        return getRevisions(collection.entityId, limit, page);
      }
    },
    [collection, getRevisions]
  );

  const onSetList = (newPresets: string[]) => {
    setPresets(newPresets);
    if (isSelected) {
      setSelectedPresets(newPresets);
    }
  };

  return (
    <div className={classes.container} data-testid={testIds.root}>
      <div className={classes.topbar}>
        <IconButton onClick={handleOnBack} data-testid={testIds.backButton}>
          <ArrowBack />
        </IconButton>
        {collection ? (
          <Stack spacing={3} direction="row" alignItems="center" overflow="hidden">
            <Typography className={classes.title} variant="h6" data-testid={testIds.title}>
              <Localized prop={collection.name} />
            </Typography>
            {collection.status === VersionedDocumentStatus.DRAFT && <DraftBadge data-testid={testIds.draft} />}
          </Stack>
        ) : (
          <Skeleton width={300} />
        )}
        <IconButton onClick={handleOnEdit} disabled={!canUpsert || !canSave} data-testid={testIds.editButton}>
          <Edit />
        </IconButton>
        {isSelected && (
          <RevisionManager
            getRevisionsPromise={getRevisionsPromise}
            state={collection}
            onChange={setSelectedCollection}
          />
        )}
      </div>
      <div className={classes.details}>
        <Stack direction="column" gap={2} alignItems="start">
          <UserBadge record={collection} useEntityCreatedDate />
          <EditorNotes object={collection} />
        </Stack>
        {isSelected && (
          <div className={classes.history}>
            <HistoryManager
              id={HISTORY_MANAGER_ID}
              state={collection}
              onChange={setSelectedCollection}
              saveCallback={handleOnSave}
              publishCallback={handleOnPublish}
              hasUpsertPermission={canSave && canUpsert}
              hasPublishPermission={canPublishGuard && canPublish && !!collection?.presets.length}
            />
          </div>
        )}
      </div>
      <ShadowScroller loading={isLoading}>
        {isLoading ? (
          <PresetsSkeleton />
        ) : (
          <List className={classes.list} disablePadding data-testid={testIds.presetListLeft}>
            {!presets.length && (
              <div className={classes.empty}>
                <NoMediaItems
                  header={t('general.no_media_items.header')}
                  message={t('general.no_media_items.message')}
                />
              </div>
            )}
            <Sortable
              className={cx({ [classes.grid]: !isList, [classes.list]: isList })}
              group={{
                name: SortableGroups.presets,
                pull: canUpsert && canSave ? 'clone' : undefined,
                put: canUpsert && canSave
              }}
              list={presets}
              disabled={!canUpsert || !canSave}
              setList={onSetList}
              animation={200}
              sort={canUpsert && canSave}
              ghostClass="sortableGhost"
              filter=".sortableFiltered"
              isStringList
            >
              {presets.map((preset) => (
                <PresetListItem
                  key={preset}
                  presetId={preset}
                  isEditing
                  canDelete={canDelete && canUpsert}
                  isGridPreset
                />
              ))}
            </Sortable>
          </List>
        )}
      </ShadowScroller>
    </div>
  );
}
