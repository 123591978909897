import React from 'react';
import { Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  assetResult: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  assetDetails: {
    width: 0,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    justifyContent: 'space-around',
    alignSelf: 'stretch',
    '& > p': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }
  }
}));

export function AssetSkeleton({ imageWidth }: { imageWidth: number }): JSX.Element {
  const { classes } = useStyles();

  return (
    <div className={classes.assetResult}>
      <Skeleton
        variant="rectangular"
        sx={{ width: imageWidth, height: imageWidth, borderRadius: 1 }}
        animation="wave"
      />
      <div className={classes.assetDetails}>
        <Skeleton sx={{ width: 280, height: 14 }} animation="wave" />
        <Skeleton sx={{ width: 220, height: 12 }} animation="wave" />
      </div>
    </div>
  );
}
