import React, { useState } from 'react';
import { useInterval, useLocales } from '../../../../../hooks';
import { PromotionUiModuleBody } from '../../../../../API';
import Modal from '../../../../shared/Modal';
import { Box } from '@mui/material';
import Button from '../../../../shared/Button';
import { getAssetUrl } from '../../../../../utils/assetHelpers';
import { themeColors } from '../../../../../theme';
import { largeNumber } from '../../../../../utils/dateHelpers';
import { useLokalise } from '../../../../../hooks/General/useLokalise';
import { VixOrangeButton } from '../shared/SharedButtons';

export interface IPromotionPreviewProps {
  promotionModule: PromotionUiModuleBody;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export const testIds = {
  root: 'promotion-preview-root'
};

export function PromotionPreview({ promotionModule, isOpen, setIsOpen }: IPromotionPreviewProps): React.ReactElement {
  const { t } = useLocales();
  const { lt } = useLokalise();
  const [portraitImageIndex, setPortraitImageIndex] = useState(0);
  const autoRotateTime = promotionModule.autoRotateSeconds ? promotionModule.autoRotateSeconds * 1000 : 6000;
  const portraitImage = promotionModule.upsellImages?.[portraitImageIndex].portraitUpsellImage as string;

  const rotateImage = () => {
    if (!promotionModule.upsellImages) return;
    setPortraitImageIndex((portraitImageIndex + 1) % promotionModule.upsellImages.length);
  };

  useInterval(rotateImage, isOpen ? autoRotateTime : largeNumber);

  const onModalClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onModalClose}
      headerLeft={t('layouts.promotion.preview')}
      footerLeft={
        <Button color="grey" onClick={onModalClose} sx={{ width: 140 }}>
          {t('general.close')}
        </Button>
      }
    >
      {/* lol @ this requirement */}
      <Box data-testid={testIds.root} sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <Box>
          <Box
            onClick={rotateImage}
            sx={{
              width: 600,
              backgroundColor: 'black',
              position: 'relative',
              color: 'white',
              strong: {
                color: themeColors.orange
              }
            }}
          >
            {/* Background Image */}
            <Box
              component="img"
              src={getAssetUrl(portraitImage)}
              sx={{
                width: '100%',
                position: 'absolute'
              }}
            />
            <Box sx={{ position: 'relative' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                <Box sx={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                  {promotionModule.upsellImages?.map((v, i) => (
                    <Box
                      key={i}
                      sx={{
                        width: 10,
                        height: 10,
                        borderRadius: 5,
                        background: i === portraitImageIndex ? 'white' : 'transparent',
                        border: '1px solid white'
                      }}
                    />
                  ))}
                </Box>
              </Box>
              {/* Vix Logo */}
              <Box component="img" src="/assets/vix-plus-white.svg" sx={{ margin: '40px 30px' }} />
              <Box
                sx={{
                  marginTop: '350px',
                  padding: '0 18%',
                  background: 'linear-gradient(to bottom, transparent 0%, rgba(0,0,0,0.7) 20%, rgba(0,0,0,1) 30%)'
                }}
              >
                {/* Upsell Header */}
                <Box sx={{ paddingTop: '25px', fontSize: '30px', fontWeight: 'bold' }}>
                  <span dangerouslySetInnerHTML={{ __html: lt(promotionModule.upsellHeader) }} />
                </Box>
                {/* Upsell Subheader */}
                <Box sx={{ marginTop: '20px', color: 'rgba(255,255,255,0.8)' }}>
                  <span dangerouslySetInnerHTML={{ __html: lt(promotionModule.upsellSubheader) }} />
                </Box>
                {/* Upsell Lead */}
                <Box sx={{ marginTop: '20px', color: 'rgba(255,255,255,0.8)' }}>
                  <span dangerouslySetInnerHTML={{ __html: lt(promotionModule.upsellLead) }} />
                </Box>
                {/* Upsell CTA */}
                <Box sx={{ marginTop: '20px' }}>
                  <VixOrangeButton>{lt(promotionModule.upsellCallToAction)}</VixOrangeButton>
                </Box>
                {/* Redeem Coupon CTA */}
                <Box sx={{ marginTop: '20px' }}>
                  <VixOrangeButton>{lt(promotionModule.redeemCouponCallToAction || '')}</VixOrangeButton>
                </Box>
              </Box>
              <Box sx={{ margin: '160px 18% 0', backgroundColor: '#151723', borderRadius: '5px', padding: '20px' }}>
                {/* Value Props Header */}
                <Box sx={{ fontSize: '24px' }}>
                  <span dangerouslySetInnerHTML={{ __html: lt(promotionModule.valuePropsHeader) }} />
                </Box>
                {/* Value Props Subheader */}
                <Box
                  sx={{
                    color: 'rgba(255,255,255,0.8)',
                    marginTop: '10px'
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: lt(promotionModule.valuePropsSubheader) }} />
                </Box>
                {/* Value Props */}
                <Box
                  sx={{
                    color: 'rgba(255,255,255,0.8)',
                    marginTop: '30px',
                    ul: { paddingInlineStart: '15px' },
                    li: { marginTop: '10px' },
                    'li:not(:last-of-type)': {
                      paddingBottom: '10px',
                      borderBottom: '1px solid rgba(255,255,255,0.1)'
                    }
                  }}
                >
                  <ul dangerouslySetInnerHTML={{ __html: lt(promotionModule.valueProps) }} />
                </Box>
                {/* Value Props CTA */}
              </Box>
              <Box
                sx={{
                  marginTop: '30px',
                  padding: '0 18%'
                }}
              >
                <Box>
                  <VixOrangeButton>{lt(promotionModule.upsellCallToAction)}</VixOrangeButton>
                </Box>
                <Box sx={{ fontSize: '14px', color: 'rgba(255,255,255,0.5)', padding: '30px 0 60px' }}>
                  <span dangerouslySetInnerHTML={{ __html: lt(promotionModule.valuePropsDisclaimer) }} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
