import { getAspectRatioFactor } from './aspectRatios';
import { AssetGuidelines } from './assetGuidelines';
import { AssetFileTypesEnum, AssetGuidelineCheckResult, AssetTypesEnum } from './assetTypes';

const getBaseUrl = (): string => {
  return `${process.env.REACT_APP_ASSETS_BASE_PUBLIC_URL}${process.env.REACT_APP_ASSETS_PATH_PUBLIC_URL}`;
};

export const getAssetUrl = (filePath: string): string => {
  return getBaseUrl() + '/' + filePath;
};

export const getImageBucketPath = (): string => {
  try {
    return new URL(getBaseUrl()).pathname.replace(/^\/*/, '');
  } catch (err) {
    return ''; // To fix tests
  }
};

export const shortenImageMimeType = (type?: string): AssetFileTypesEnum | undefined => {
  if (!type) return;
  if (/png/.test(type)) return AssetFileTypesEnum.PNG;
  if (/jpeg|jpg/.test(type)) return AssetFileTypesEnum.JPG;
  if (/svg/.test(type)) return AssetFileTypesEnum.SVG;
  if (/webp/.test(type)) return AssetFileTypesEnum.WEBP;
};

export const checkAsset = (
  assetType: AssetTypesEnum,
  width: number,
  height: number,
  fileType: AssetFileTypesEnum | undefined
): AssetGuidelineCheckResult => {
  const guidelines = AssetGuidelines[assetType];
  const result = {
    adheres: true,
    adheresFileType: true,
    adheresHeight: true,
    adheresWidth: true,
    adheresAspectRatio: true
  };
  if (guidelines?.preferredFileTypes) {
    result.adheresFileType = !!fileType && guidelines.preferredFileTypes.includes(fileType);
  }
  if (guidelines?.minWidth) {
    result.adheresWidth = width >= guidelines.minWidth;
  }
  if (guidelines?.minHeight) {
    result.adheresHeight = height >= guidelines.minHeight;
  }
  if (guidelines?.preferredAspectRatio) {
    const aspectRatio = width / height;
    const tolerance = 0.05; // 5% tolerance? idk whatever
    result.adheresAspectRatio =
      Math.abs(1 - aspectRatio / getAspectRatioFactor(guidelines.preferredAspectRatio)) < tolerance;
  }
  result.adheres = result.adheresFileType && result.adheresAspectRatio && result.adheresHeight && result.adheresWidth;
  return result;
};
