/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { EntitlementType } from './entitlement-type';
import { LandingPromoUiModuleBackgroundBody } from './landing-promo-ui-module-background-body';
import { LandingPromoUiModuleFAQBody } from './landing-promo-ui-module-faqbody';
import { LandingPromoUiModuleLogoBody } from './landing-promo-ui-module-logo-body';
import { LandingPromoUiModuleStepBody } from './landing-promo-ui-module-step-body';
import { LocalizedFieldBody } from './localized-field-body';
import { TargetPlatform } from './target-platform';
import { UiModuleExperimentGroup } from './ui-module-experiment-group';

/**
 * 
 * @export
 * @interface LandingPromoUiModuleBody
 */
export interface LandingPromoUiModuleBody {
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    moduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    moduleType: LandingPromoUiModuleBodyModuleTypeEnum;
    /**
     * 
     * @type {Array<TargetPlatform>}
     * @memberof LandingPromoUiModuleBody
     */
    targetPlatform?: Array<TargetPlatform>;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof LandingPromoUiModuleBody
     */
    caption?: LocalizedFieldBody;
    /**
     * 
     * @type {Array<EntitlementType>}
     * @memberof LandingPromoUiModuleBody
     */
    entitlements?: Array<EntitlementType>;
    /**
     * 
     * @type {Array<UiModuleExperimentGroup>}
     * @memberof LandingPromoUiModuleBody
     */
    experiments?: Array<UiModuleExperimentGroup> | null;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof LandingPromoUiModuleBody
     */
    title: LocalizedFieldBody;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    vixLogo: string;
    /**
     * 
     * @type {Array<LandingPromoUiModuleLogoBody>}
     * @memberof LandingPromoUiModuleBody
     */
    bundleLogo?: Array<LandingPromoUiModuleLogoBody>;
    /**
     * 
     * @type {Array<LandingPromoUiModuleBackgroundBody>}
     * @memberof LandingPromoUiModuleBody
     */
    background?: Array<LandingPromoUiModuleBackgroundBody>;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    bundleHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    bundleSubheader?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    legalDisclaimer?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    seeOtherPlansCTA?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    planHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    planSubheader?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    planCTA?: string;
    /**
     * 
     * @type {Array<LandingPromoUiModuleLogoBody>}
     * @memberof LandingPromoUiModuleBody
     */
    planPartnerLogo?: Array<LandingPromoUiModuleLogoBody>;
    /**
     * 
     * @type {Array<LandingPromoUiModuleLogoBody>}
     * @memberof LandingPromoUiModuleBody
     */
    planBrandLogos?: Array<LandingPromoUiModuleLogoBody>;
    /**
     * 
     * @type {Array<LandingPromoUiModuleBackgroundBody>}
     * @memberof LandingPromoUiModuleBody
     */
    planBrandTiles?: Array<LandingPromoUiModuleBackgroundBody>;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    bundlePlanHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    bundlePlanSubheader?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    bundlePlanName?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    bundlePlanCost?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    bundlePlanDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    bundlePlanPriceTag?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    bundlePlanValueProps?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    bundlePlanSeeMoreCTA?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    bundlePlanCTA?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    bundlePlanDisclaimer?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    stepTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    startCTA?: string;
    /**
     * 
     * @type {Array<LandingPromoUiModuleStepBody>}
     * @memberof LandingPromoUiModuleBody
     */
    steps?: Array<LandingPromoUiModuleStepBody>;
    /**
     * 
     * @type {string}
     * @memberof LandingPromoUiModuleBody
     */
    FAQTitle?: string;
    /**
     * 
     * @type {Array<LandingPromoUiModuleFAQBody>}
     * @memberof LandingPromoUiModuleBody
     */
    FAQ?: Array<LandingPromoUiModuleFAQBody>;
}

/**
    * @export
    * @enum {string}
    */
export enum LandingPromoUiModuleBodyModuleTypeEnum {
    LANDING_PROMO = 'LANDING_PROMO'
}



