import { PaymentPlanResponse } from '../../../../API';
import { usePlansAPI } from '../../../../hooks/API/Payments/usePlansAPI';
import { CreateDataManager, DataManagerHookReturnType, DataManagerReturnType } from '../../../DataManager';
import { PlansState, PlansStateFullReturnType } from './PlansState';

export type PlansDataManagerType = {
  state: PlansStateFullReturnType;
  hook: DataManagerHookReturnType<PaymentPlanResponse, PaymentPlanResponse>;
};

export interface PlansDataManagerReturnType extends DataManagerReturnType<PaymentPlanResponse, PaymentPlanResponse> {
  state: PlansStateFullReturnType;
  hook: () => DataManagerHookReturnType<PaymentPlanResponse, PaymentPlanResponse>;
}

export const PlansDataManager = CreateDataManager<PaymentPlanResponse, PaymentPlanResponse, PlansDataManagerReturnType>(
  {
    name: 'plans',
    idField: 'code',
    useApiHook: usePlansAPI,
    stateInitializer: PlansState,
    // Function used to generate a plan string for success & error handling
    toString: (plan: PaymentPlanResponse) => `${plan.name} (${plan.code})`
  }
);
