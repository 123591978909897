import {
  ContentListUiModuleBody,
  ContentListUiModuleBodyModuleTypeEnum,
  ContentListUIModuleContentTreatment,
  DocumentLocale
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankContentListModule: NewModuleFunction<ContentListUiModuleBody> = (module) => ({
  title: {
    [DocumentLocale.ES]: ''
  },
  isPlaylist: false,
  moduleType: ContentListUiModuleBodyModuleTypeEnum.CONTENT_LIST,
  contentTreatment: ContentListUIModuleContentTreatment.PORTRAIT,
  contentListId: '',
  moduleId: generateId(),
  useCollectionTitle: false,
  ...module
});
