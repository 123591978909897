import {
  DocumentLocale,
  EntitlementType,
  PromotionUiModuleBody,
  PromotionUiModuleBodyModuleTypeEnum
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankPromotionModule: NewModuleFunction<PromotionUiModuleBody> = (module) =>
  <PromotionUiModuleBody>{
    moduleType: PromotionUiModuleBodyModuleTypeEnum.PROMOTION,
    moduleId: generateId(),
    title: {
      [DocumentLocale.ES]: ''
    },
    upsellImages: [
      {
        id: generateId(),
        landscapeUpsellImage: '',
        portraitUpsellImage: '',
        desktopUpsellImage: '',
        mobileUpsellImage: '',
        ctvUpsellImage: '',
        tabletUpsellImage: ''
      }
    ],
    autoRotateSeconds: 6,
    entitlements: [EntitlementType.ANON, EntitlementType.FREE],
    ...module
  };
