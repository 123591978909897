import React from 'react';
import Modal from '../../../../shared/Modal';
import { PlanSummaryUiModuleBody } from '../../../../../API';
import { useLocales, useLokalise } from '../../../../../hooks';
import { Box, Checkbox, FormControlLabel, Stack, Typography, Divider } from '@mui/material';
import { TextButton, VixOrangeButton } from '../shared/SharedButtons';
import Button from '../../../../shared/Button';
import TextField from '../../../../shared/TextField';
import { previewDetailsDialog } from '../../../../../styles/previewDetails';

type PlanSummaryPreviewProps = {
  module: PlanSummaryUiModuleBody;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const testIds = {
  root: 'plan-summary-preview.root',
  container: 'plan-summary-preview.container'
};

export function PlanSummaryPreview({ module, open, setOpen }: PlanSummaryPreviewProps): React.ReactElement {
  const { classes } = previewDetailsDialog({ width: 420, padding: '0 20px' })();
  const { t } = useLocales();
  const { lt } = useLokalise();

  const onModalClose = () => setOpen(false);

  const disclaimer = (
    <Typography variant="caption">
      <span dangerouslySetInnerHTML={{ __html: lt(module.legalDisclaimer) }} />
    </Typography>
  );

  return (
    <Modal
      open={open}
      onClose={onModalClose}
      headerLeft={t('layouts.plan_summary.preview')}
      footerLeft={
        <Button color="grey" onClick={onModalClose} sx={{ width: 140 }}>
          {t('general.close')}
        </Button>
      }
    >
      <Box data-testid={testIds.root} sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <Box data-testid={testIds.container} className={classes.container}>
          <Stack gap="16px" width="100%">
            <Stack gap="16px" marginTop="8px">
              {/* Summary Header */}
              <Typography variant="h4" alignSelf="start">
                <span dangerouslySetInnerHTML={{ __html: lt(module.header) }} />
              </Typography>
              <Divider />
            </Stack>
            <Box className={classes.offerContainer}>
              <Stack gap="16px" width="100%">
                <Stack gap="2px">
                  {/* Summary Plan Name */}
                  <Typography variant="body1" alignSelf="start">
                    <span dangerouslySetInnerHTML={{ __html: lt(module.planName) }} />
                  </Typography>
                  {/* Price First Charge */}
                  <Typography variant="body1" alignSelf="start">
                    <span dangerouslySetInnerHTML={{ __html: lt(module.priceFirstCharge) }} />
                  </Typography>
                  {/* Offer Details */}
                  <Typography variant="body1" alignSelf="start">
                    <span dangerouslySetInnerHTML={{ __html: lt(module.offerDetails) }} />
                  </Typography>
                  {/* Tax Disclaimer */}
                  <Typography variant="caption" alignSelf="start" color="gray">
                    <span dangerouslySetInnerHTML={{ __html: lt(module.taxDisclaimer) }} />
                  </Typography>
                </Stack>
                <Divider />
                <Stack direction="row" justifyContent="space-between" width="100%" gap="8px">
                  <Stack sx={{ marginTop: '10px' }}>
                    <Typography variant="caption" alignSelf="start" color="gray">
                      <span dangerouslySetInnerHTML={{ __html: lt(module.planName) }} />
                    </Typography>
                    {/* Tax Title */}
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      label={lt(module.taxTitle)}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                    {/* Total Summary Title */}
                    <Typography variant="subtitle2" alignSelf="start">
                      <span dangerouslySetInnerHTML={{ __html: lt(module.totalSummaryTitle) }} />
                    </Typography>
                  </Stack>
                  <Stack sx={{ marginTop: '10px' }}>
                    {/* Plan Cost */}
                    <Typography variant="caption" alignSelf="start" color="gray">
                      <span dangerouslySetInnerHTML={{ __html: lt(module.planCost) }} />
                    </Typography>
                    {/* Tax Amount */}
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      label={lt(module.taxAmount)}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                    {/* Total Amount */}
                    <Typography variant="subtitle2" alignSelf="start">
                      <span dangerouslySetInnerHTML={{ __html: lt(module.totalAmount) }} />
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
            {/* Legal Disclaimer */}
            <Box>
              {module.checkboxConfirmation ? (
                <FormControlLabel
                  value="formOptInLabel"
                  control={<Checkbox className={classes.checkbox} />}
                  label={disclaimer}
                  labelPlacement="end"
                />
              ) : (
                disclaimer
              )}
            </Box>
            <Stack gap="16px" margin="4px 0">
              {/* Sign-up Call To Action */}
              <VixOrangeButton>{lt(module.signUpCta)}</VixOrangeButton>
              {/* Not Now Call To Action */}
              <TextButton>{lt(module.notNowCta)}</TextButton>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
