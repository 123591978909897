import {
  GetPaginatedHeroPresetCollectionsQuery,
  HeroPresetCollectionApi,
  HeroPresetCollectionBody,
  HeroPresetCollectionResponse,
  SortDirection
} from '../../../API';
import { Paginated } from '../../../hooks';
import { DEFAULT_PAGINATION_LIMIT } from '../../../utils/appDefaults';
import {
  DataManagerHelpers,
  DataManagerHook,
  DataManagerHookReturnType,
  IDataManagerHookProps
} from '../../DataManager';

export interface HPCHookReturnType
  extends DataManagerHookReturnType<HeroPresetCollectionResponse, HeroPresetCollectionBody> {
  getPaginated: (
    params: GetPaginatedHeroPresetCollectionsQuery
  ) => Promise<Paginated<HeroPresetCollectionResponse> | undefined>;
  getRevisions: (
    entityId: string,
    limit?: number,
    page?: number
  ) => Promise<Paginated<HeroPresetCollectionResponse> | undefined>;
}

type HookParams = IDataManagerHookProps<HeroPresetCollectionResponse, HeroPresetCollectionBody>;

export function HPCHook(params: HookParams): HPCHookReturnType {
  const api = params.useApiHook() as HeroPresetCollectionApi;
  const dataManagerHook = DataManagerHook<HeroPresetCollectionResponse, HeroPresetCollectionBody>(params);
  const { handleApiError } = DataManagerHelpers({ name: params.name, toString });

  const getPaginated = async ({
    limit = DEFAULT_PAGINATION_LIMIT,
    page = 1,
    sortBy = 'createdDate',
    sortDirection = SortDirection.DESC,
    ...params
  }: GetPaginatedHeroPresetCollectionsQuery) => {
    if (!dataManagerHook.fetchPaginated) return;
    return await dataManagerHook.fetchPaginated(limit, page, [
      sortBy,
      sortDirection,
      params.status,
      params.authorId,
      params.name,
      params.isLatestPublishedRevision
    ]);
  };

  const getRevisions = async (entityId: string, limit?: number, page?: number) => {
    try {
      const response = await api.getRevisions(entityId, limit, page);
      return response.data.body;
    } catch (error) {
      console.error(error);
      handleApiError(error, 'get_revisions', entityId);
    }
  };

  return {
    ...dataManagerHook,
    getPaginated,
    getRevisions
  };
}
