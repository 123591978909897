import { Search } from '@mui/icons-material';
import { SelectChangeEvent } from '@mui/material';
import { InputAdornment, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { PaymentPlanResponse, PermissionsGroupId, TargetPlatform } from '../../../../API';
import { useData } from '../../../../data-layer';
import { textFilterToRegExp, useLocales } from '../../../../hooks';
import { withPlansSplitPane } from '../../../../state/General';
import { withPlansFilters } from '../../../../state/Payments/withPaymentsFilters';
import { ALL } from '../../../../utils/types/genericTypes';
import CountryBadge from '../../../shared/CountryBadge';
import CountryPickerLite from '../../../shared/CountryPickerLite';
import { DataGrid, DataGridColDef } from '../../../shared/DataGrid';
import FormControl from '../../../shared/FormControl';
import { IdBadge } from '../../../shared/IdBadge';
import TextField from '../../../shared/TextField';
import CouponBadge from '../../Coupons/CouponBadge/CouponBadge';
import { usePermissions } from '../../../../hooks/Permissions/usePermissions';
import { AppRoutes } from '../../../../Routes';
import { useNavigate } from 'react-router-dom';
import { usePermissionsGuard } from '../../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../../state/theme';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  filters: {
    display: 'flex',
    gap: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  textFilter: {
    width: 400,
    marginBottom: '0 !important'
  },
  planGroupFilter: {
    width: 250
  },
  countryFilter: {
    width: 350
  },
  gridContainer: {
    display: 'flex',
    flexGrow: 1
  },
  planPriceContainer: {
    display: 'flex',
    gap: theme.spacing(1)
  },
  couponBadgeContainer: {
    display: 'inline-block',
    margin: theme.spacing(0, 2, 2, 0)
  },
  couponBadgesContainer: {
    marginBottom: `calc(0px - ${theme.spacing(2)})`
  }
}));

export const testIds = {
  textFilter: 'plans-grid.text-filter',
  planGroupFilter: 'plans-grid.plan-group-filter',
  planGroupFilterOption: 'plans-grid.plan-group-filter-option',
  deviceFilter: 'plans-grid.device-filter',
  deviceFilterOption: 'plans-grid.device-filter-option',
  countryFilter: 'plans-grid.country-filter'
};

export function PlansGrid(): JSX.Element {
  const { t } = useLocales();
  const navigate = useNavigate();
  const { cx, classes } = useStyles();
  const {
    plans: {
      state: { withAllRecords, withPlanGroups },
      hook: { edit }
    }
  } = useData();
  const allPlans = useRecoilValue(withAllRecords);
  const planGroups = useRecoilValue(withPlanGroups);

  const { hasReadPermission } = usePermissions();
  const { canRead } = usePermissionsGuard({
    homepageOption: HomepageOptions.MONETIZATION
  });
  const hasMonetizationReadPermissions = hasReadPermission(PermissionsGroupId.MONETIZATION) || canRead;

  const [filters, setFilters] = useRecoilState(withPlansFilters);
  const [filteredPlans, setFilteredPlans] = useState<PaymentPlanResponse[] | undefined>();

  useEffect(() => {
    updateFilteredPlans();
  }, [allPlans, filters]);

  useEffect(() => {
    if (!hasMonetizationReadPermissions) {
      navigate(AppRoutes.welcome);
    }
  }, [hasMonetizationReadPermissions]);

  const columns: DataGridColDef<PaymentPlanResponse>[] = [
    {
      key: 'name',
      cellRenderer: (plan) => plan.name,
      label: t('payments.name'),
      sortFunction: (a, b) => a.name.localeCompare(b.name),
      baseWidth: '14%'
    },
    {
      key: 'sku',
      cellRenderer: (plan) => <IdBadge id={plan.code} />,
      label: 'SKU',
      sortFunction: (a, b) => a.code.localeCompare(b.code),
      baseWidth: '14%'
    },
    {
      key: 'promoGroup',
      cellRenderer: (plan) => plan.promoGroup,
      label: t('payments.promo_group'),
      sortFunction: (a, b) => {
        if (!a.promoGroup) return -1;
        if (!b.promoGroup) return 1;
        return a.promoGroup.localeCompare(b.promoGroup);
      },
      baseWidth: '10%'
    },
    {
      key: 'localizationTemplateId',
      cellRenderer: (plan) => plan.localizationTemplateId,
      label: t('payments.template_id'),
      sortFunction: (a, b) => a.localizationTemplateId.localeCompare(b.localizationTemplateId),
      baseWidth: '10%'
    },
    {
      key: 'price',
      cellRenderer: (plan) => (
        <div className={classes.planPriceContainer}>
          <div>{plan.price}</div>
          <div>{plan.currency}</div>
        </div>
      ),
      label: t('payments.base_price'),
      baseWidth: '8%'
    },
    {
      key: 'billing',
      cellRenderer: (plan) => plan.billingPeriodLength + ' ' + plan.billingPeriodUnit,
      label: t('payments.billing_period'),
      baseWidth: '8%'
    },
    {
      key: 'device',
      cellRenderer: (plan) => t(`platforms.platform_name.${plan.device}`),
      label: t('payments.device'),
      sortFunction: (a, b) => a.device.localeCompare(b.device),
      baseWidth: '8%'
    },
    {
      key: 'country',
      cellRenderer: (plan) => <CountryBadge countryCode={plan.country.toUpperCase()} />,
      label: t('general.country'),
      sortFunction: (a, b) => a.country.localeCompare(b.country),
      baseWidth: '8%'
    },
    {
      key: 'coupons',
      cellRenderer: (plan) => (
        <div className={cx({ [classes.couponBadgesContainer]: !!plan.coupons?.length })}>
          {plan.coupons?.map((coupon, i) => (
            <div key={i} className={classes.couponBadgeContainer}>
              <CouponBadge couponCode={coupon} />
            </div>
          ))}
        </div>
      ),
      label: t('payments.coupons'),
      baseWidth: '20%'
    }
  ];

  const onPlanClick = (plan: PaymentPlanResponse) => {
    edit(plan.code);
  };

  const onTextFilterChanged: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
    setFilters({
      ...filters,
      text: evt.target.value
    });
  };

  const onPromoGroupFilterChanged = (evt: SelectChangeEvent<string>) => {
    setFilters({
      ...filters,
      promoGroup: evt.target.value
    });
  };

  const onDeviceFilterChanged = (evt: SelectChangeEvent<string>) => {
    setFilters({
      ...filters,
      device: evt.target.value
    });
  };

  const onCountryFilterChanged = (countries: string[]) => {
    setFilters({
      ...filters,
      countries
    });
  };

  const updateFilteredPlans = () => {
    if (!allPlans) return;
    const newFilteredPlans = allPlans.filter((plan) => {
      if (filters.text) {
        const textFilterRgx = textFilterToRegExp(filters.text);
        if (!textFilterRgx.test(plan.code) && !textFilterRgx.test(plan.name)) {
          return false;
        }
      }
      if (filters.promoGroup !== ALL && plan.promoGroup !== filters.promoGroup) {
        return false;
      }
      if (filters.device !== ALL && plan.device !== filters.device) {
        return false;
      }
      if (filters.countries.length && !filters.countries.includes(plan.country.toUpperCase())) {
        return false;
      }
      return true;
    });
    setFilteredPlans(newFilteredPlans);
  };

  return (
    <div className={classes.root}>
      <div className={classes.filters}>
        <TextField
          clearable
          label={t('payments.filter_by_keywords')}
          className={classes.textFilter}
          value={filters.text}
          onChange={onTextFilterChanged}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
          data-testid={testIds.textFilter}
        />
        <FormControl className={classes.planGroupFilter}>
          <InputLabel shrink variant="standard">
            {t('payments.filter_by_promo_group')}
          </InputLabel>
          <Select value={filters.promoGroup} onChange={onPromoGroupFilterChanged} data-testid={testIds.planGroupFilter}>
            <MenuItem value={ALL} data-testid={testIds.planGroupFilterOption}>
              {t('filters.all')}
            </MenuItem>
            {planGroups?.map((planGroup) => (
              <MenuItem key={planGroup} value={planGroup} data-testid={testIds.planGroupFilterOption}>
                {planGroup}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.planGroupFilter}>
          <InputLabel shrink variant="standard">
            {t('payments.filter_by_device')}
          </InputLabel>
          <Select value={filters.device} onChange={onDeviceFilterChanged} data-testid={testIds.deviceFilter}>
            <MenuItem value={ALL} data-testid={testIds.deviceFilterOption}>
              {t('filters.all')}
            </MenuItem>
            {Object.values(TargetPlatform).map((device) => (
              <MenuItem key={device} value={device} data-testid={testIds.deviceFilterOption}>
                {t(`platforms.platform_name.${device}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <CountryPickerLite
          className={classes.countryFilter}
          label={t('payments.filter_by_country')}
          value={filters.countries}
          onChange={onCountryFilterChanged}
          data-testid={testIds.countryFilter}
        />
      </div>
      <div className={classes.gridContainer}>
        <DataGrid
          rows={filteredPlans}
          columns={columns}
          onRowClick={(plan) => onPlanClick(plan)}
          withColumnSizes={withPlansSplitPane}
          useVirtualization
          rowKey="code"
        />
      </div>
    </div>
  );
}
