/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum LayoutStatus {
    DRAFT = 'DRAFT',
    SCHEDULED = 'SCHEDULED',
    LIVE = 'LIVE',
    EXPIRED = 'EXPIRED',
    DELETED = 'DELETED'
}



