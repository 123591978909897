import { selector } from 'recoil';
import { defaultPrimary, defaultSecondary, SpacingEnum, ThemeOptions } from '../../theme';
import { localAtom } from '../localStorageState';

export enum HomepageOptions {
  WELCOME = 'welcome',
  EPG = 'epg',
  HPC = 'hpc',
  COLLECTIONS = 'collections',
  VOD_COLLECTIONS = 'vod_collections',
  LAYOUTS = 'layouts',
  SPORTS = 'sports',
  MONETIZATION = 'monetization',
  METADATA = 'metadata'
}

export const withThemeOptions = localAtom<ThemeOptions>({
  key: 'theme.themeOptions',
  default: {
    darkMode: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
    spacing: SpacingEnum.SPACIOUS,
    colors: {
      primary: defaultPrimary,
      secondary: defaultSecondary
    }
  }
});

export const withIsDarkMode = selector<boolean>({
  key: 'theme.isDarkMode',
  get: ({ get }) => get(withThemeOptions).darkMode,
  set: ({ get, set }, darkMode) => {
    set(withThemeOptions, { ...get(withThemeOptions), darkMode: darkMode as boolean });
  }
});

export const withThemeSpacing = selector<SpacingEnum>({
  key: 'theme.spacing',
  get: ({ get }) => get(withThemeOptions).spacing,
  set: ({ get, set }, spacing) => {
    set(withThemeOptions, { ...get(withThemeOptions), spacing: spacing as SpacingEnum });
  }
});

export const withThemeColorPrimary = selector<string>({
  key: 'theme.colorPrimary',
  get: ({ get }) => get(withThemeOptions).colors.primary,
  set: ({ get, set }, color) => {
    const themeOptions = get(withThemeOptions);
    const themeColors = themeOptions.colors;
    set(withThemeOptions, { ...themeOptions, colors: { ...themeColors, primary: color as string } });
  }
});

export const withThemeColorSecondary = selector<string>({
  key: 'theme.colorSecondary',
  get: ({ get }) => get(withThemeOptions).colors.secondary,
  set: ({ get, set }, color) => {
    const themeOptions = get(withThemeOptions);
    const themeColors = themeOptions.colors;
    set(withThemeOptions, { ...themeOptions, colors: { ...themeColors, secondary: color as string } });
  }
});

export const withHomepage = localAtom<HomepageOptions>({
  key: 'theme.homepage',
  default: HomepageOptions.WELCOME
});
