import { useEffect } from 'react';
import { difference, pick, without } from 'lodash-es';
import { WithFilters } from '../../components/shared/SearchFilters';
import { RecoilState, SetterOrUpdater, useRecoilState } from 'recoil';

export function useSearchFilters<
  TFull extends WithFilters<unknown, TEnum[keyof TEnum]>,
  TEnum extends Record<string, string>,
  TFilters extends Record<keyof TEnum, string[]>
>(atom: RecoilState<TFull>, filters: TFilters, allowList: Array<keyof TFull>): [TFull, SetterOrUpdater<TFull>] {
  const [searchParams, setSearchParams] = useRecoilState(atom);

  useEffect(() => {
    const invalid = difference(searchParams._filters, Object.keys(filters));
    if (invalid.length) {
      const validParams = Object.values(filters).flat();
      const newParams = pick(searchParams, [...validParams, 'limit', 'page', ...(allowList as string[])]);
      setSearchParams({ ...newParams, _filters: without(searchParams._filters, ...invalid) } as TFull);
    }
  }, [searchParams]);

  return [searchParams, setSearchParams];
}
