import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  FormControlLabel,
  LinearProgress,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useLocales, useStatsig, useTheme } from '../../../hooks';
import { makeStyles } from 'tss-react/mui';
import { useRecoilValue } from 'recoil';
import { withExperiments } from '../../../state/Experiments';
import { StatsigExperiment, UiModuleExperimentGroup } from '../../../API';
import { Checkbox } from '@mui/material';
import { Troubleshoot } from '@mui/icons-material';

export interface ExperimentsPickerProps {
  value: Array<UiModuleExperimentGroup> | null | undefined;
  onChange: (value: Array<UiModuleExperimentGroup> | null | undefined) => void;
  hasFormError: boolean;
}

const useStyles = makeStyles()((theme) => ({
  textField: {
    width: 420,
    marginBottom: '0px !important'
  },
  experimentsContainer: {
    marginTop: theme.spacing(6)
  },
  experimentGroupContainer: {
    margin: theme.spacing(2, 4, 2, 0),
    display: 'inline-flex',
    flexDirection: 'column',
    width: 350
  },
  paramsTooltipHeader: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold'
  },
  disabled: {
    opacity: 0.5
  }
}));

export function ExperimentsPicker({ value, onChange, hasFormError }: ExperimentsPickerProps): JSX.Element {
  const { cx, classes } = useStyles();
  const { t } = useLocales();
  const experiments = useRecoilValue(withExperiments);
  const { getExperiments } = useStatsig();
  const { formControlColor } = useTheme();
  const [selectedExperiment, setSelectedExperiment] = useState<StatsigExperiment | undefined>();

  useEffect(() => {
    if (!experiments) {
      getExperiments();
    }
  }, []);

  useLayoutEffect(() => {
    if (experiments && value?.length) {
      const newSelectedExperiment = experiments.find((exp) => exp.id === value[0].experimentId);
      setSelectedExperiment(newSelectedExperiment);
    }
  }, [value, experiments]);

  return (
    <Box className={classes.experimentsContainer}>
      <Stack direction="row" gap={2} alignItems="center" mb={4}>
        <FormControlLabel
          control={
            <Switch
              color={formControlColor}
              checked={!!value}
              onChange={({ target: { checked } }) => {
                if (!checked) {
                  onChange(null);
                } else {
                  onChange([]);
                }
              }}
            />
          }
          label={<div>{t('experiments.control_module_visibility')}</div>}
        />
        {experiments && value && (
          <Autocomplete
            value={selectedExperiment}
            options={experiments}
            getOptionLabel={(experiment) => experiment.id}
            onChange={(_, newValue) => {
              setSelectedExperiment(newValue);
              onChange([]);
            }}
            renderInput={(params) => {
              params.inputProps.value = selectedExperiment?.id || '';
              return (
                <TextField
                  placeholder={t('experiments.choose_experiment')}
                  className={classes.textField}
                  color={formControlColor}
                  {...params}
                />
              );
            }}
            renderOption={(props, experiment) => (
              <li {...props} key={experiment.id}>
                <div>
                  <Typography>{experiment.id}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {experiment.description}
                  </Typography>
                </div>
              </li>
            )}
            disableClearable
            autoComplete
          />
        )}
      </Stack>
      {value && !selectedExperiment && hasFormError && (
        <Typography color="error" variant="body2">
          {t('experiments.validation_message')}
        </Typography>
      )}
      {value && selectedExperiment && (
        <>
          <Stack direction="row" gap={2}>
            <Typography color="textSecondary" variant="body2">
              {t('experiments.show_module_for_groups')}
            </Typography>
            {hasFormError && (
              <Typography color="error" variant="body2">
                {t('experiments.validation_message')}
              </Typography>
            )}
          </Stack>
          {selectedExperiment.groups.map((group) => {
            const isGroupInValue = !!value.find(
              (expGroup) => expGroup.experimentId === selectedExperiment?.id && expGroup.groupId === group.id
            );
            return (
              <div key={group.id} className={classes.experimentGroupContainer}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color={formControlColor}
                      checked={isGroupInValue}
                      onChange={({ target: { checked } }) => {
                        if (!checked) {
                          onChange(value.filter((expGroup) => expGroup.groupId !== group.id));
                        } else {
                          onChange([...value, { groupId: group.id, experimentId: selectedExperiment.id }]);
                        }
                      }}
                    />
                  }
                  label={
                    <Stack direction="row" gap={2}>
                      <span>{group.name}</span>
                      <Typography component="span" color="textSecondary">
                        ({group.size}%)
                      </Typography>
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          <Stack>
                            <Typography className={classes.paramsTooltipHeader}>
                              {t('experiments.parameters')}
                            </Typography>
                            <div>{JSON.stringify(group.parameterValues, null, 2)}</div>
                          </Stack>
                        }
                      >
                        <Troubleshoot />
                      </Tooltip>
                    </Stack>
                  }
                />
                <LinearProgress
                  className={cx({ [classes.disabled]: !isGroupInValue })}
                  color={isGroupInValue ? formControlColor : 'inherit'}
                  variant="determinate"
                  value={group.size}
                />
              </div>
            );
          })}
        </>
      )}
    </Box>
  );
}
