/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum SmartQuerySortingSymbol {
    SCHEDULED_START_AT = 'scheduledStartAt',
    TRANSMISSION_TITLE_KEYWORD = 'transmissionTitle.keyword',
    YEAR_RELEASED = 'yearReleased',
    START_DATE = 'startDate',
    TITLE = 'title',
    DATE_MODIFIED = 'dateModified',
    DATE_CREATED = 'dateCreated',
    PUBLISH_WINDOW_START_DATE = 'publishWindowStartDate',
    INGEST_DATE = 'ingestDate'
}



