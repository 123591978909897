import {
  AccountSuccessUiModuleBody,
  AccountSuccessUiModuleBodyModuleTypeEnum,
  DocumentLocale,
  EntitlementType
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction, DynamicModuleFunction } from '../../../utils/layouts';

export const blankAccountSuccessModule: NewModuleFunction<AccountSuccessUiModuleBody> = (module) =>
  <AccountSuccessUiModuleBody>{
    moduleType: AccountSuccessUiModuleBodyModuleTypeEnum.ACCOUNT_SUCCESS,
    moduleId: generateId(),
    title: {
      [DocumentLocale.ES]: ''
    },
    entitlements: [EntitlementType.ANON, EntitlementType.FREE, EntitlementType.SUB],
    vixLogo: '',
    portraitAccountSuccessImage: '',
    avodHeader: '',
    avodSubHeader: '',
    svodHeader: '',
    svodSubHeader: '',
    spinPremia: {
      id: generateId()
    },
    ...module
  };

export const setDynamicFieldsOnAccountSuccessModule: DynamicModuleFunction<AccountSuccessUiModuleBody> = (module) =>
  <AccountSuccessUiModuleBody>{
    ...module,
    ...(!module.spinPremia?.id && {
      spinPremia: {
        ...module?.spinPremia,
        id: generateId()
      }
    })
  };
