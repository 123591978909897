import React, { useCallback } from 'react';
import ShadowScroller from '../../../../shared/ShadowScroller';
import { List, Skeleton } from '@mui/material';
import { Sortable, SortableGroups } from '../../../../shared/Sortable';
import { noop } from 'lodash-es';
import Media from '../../Media';
import Repeat from '../../../../shared/Repeat';
import { useRecoilValue } from 'recoil';
import { useData } from '../../../../../data-layer';
import { makeStyles } from 'tss-react/mui';
import { getMediaGridTemplateColumns, getMediaAspectRatio } from '../../../../../utils/styleUtils';
import { usePermissions } from '../../../../../hooks/Permissions/usePermissions';
import { DisplayAsOptions } from '../../../../../utils/types/genericTypes';

const useStyles = makeStyles<{ displayAs: DisplayAsOptions }>()((theme, { displayAs }) => ({
  searchResults: {
    flexGrow: 1,
    padding: 0
  },
  searchResultsInner: {
    display: 'grid',
    minHeight: '100%',
    overflow: 'hidden',
    padding: theme.spacing(0, 2),
    gridGap: theme.spacing(2),
    gridTemplateColumns: getMediaGridTemplateColumns(displayAs),
    gridTemplateRows: 'repeat(auto-fit, minmax(0, max-content))'
  },
  skeletonWrapper: {
    paddingRight: displayAs === 'list' ? 0 : 11
  },
  listSkeleton: {
    display: 'flex',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    flexDirection: 'column',
    '& > *': {
      height: 50
    }
  },
  skeletonMedia: {
    width: '100%',
    aspectRatio: getMediaAspectRatio(displayAs)
  }
}));

export interface IContentSearchResultsProps {
  isContentPicker?: boolean;
  isLoading?: boolean;
  mediaList?: string[];
  onContentPicked?: (contentId: string) => void;
}

export const testIds = {
  root: 'content-search-results.root',
  list: 'content-search-results.list',
  media: 'content-search-results.media',
  loading: 'content-search-results.loading'
};

export const NUMBER_OF_LOADING_TILES = 40;

function ContentSearchResults({
  isLoading,
  isContentPicker,
  mediaList = [],
  onContentPicked
}: IContentSearchResultsProps): JSX.Element {
  const {
    collections: {
      state: { withDisplayAs, withSelectedCollectionContent, withSelected }
    }
  } = useData();

  const displayAs = useRecoilValue(withDisplayAs);
  const { classes, cx } = useStyles({ displayAs });
  const selectedCollectionContent = useRecoilValue(withSelectedCollectionContent);
  const selectedCollection = useRecoilValue(withSelected);
  const { hasUpsertPermission } = usePermissions();
  const hasCollectionUpsertPermission = hasUpsertPermission(selectedCollection?.ownerPermissionsGroup);

  const checkInCollection = useCallback(
    (id: string) => selectedCollectionContent?.includes(id),
    [selectedCollectionContent]
  );

  return (
    <div className={classes.searchResults} data-testid={testIds.root}>
      <ShadowScroller loading={isLoading} horizontalScrolling={false}>
        <List disablePadding={displayAs === DisplayAsOptions.LIST} data-testid={testIds.list}>
          {!isLoading && (
            <Sortable
              className={cx({ [classes.searchResultsInner]: displayAs !== DisplayAsOptions.LIST })}
              disabled={isContentPicker || !hasCollectionUpsertPermission}
              group={{ name: SortableGroups.collections, pull: 'clone', put: false }}
              list={mediaList ?? []}
              sort={false}
              setList={noop}
              animation={100}
              ghostClass="sortableGhost"
              dragClass="sortableDragPaper"
              filter=".sortableFiltered"
              isStringList
            >
              {mediaList.map((contentId) => (
                <Media
                  key={contentId}
                  mediaId={contentId}
                  previewMode={isContentPicker}
                  onContentPicked={onContentPicked}
                  inCollection={checkInCollection(contentId)}
                  collectionForKids={selectedCollection?.kids}
                  canAddDelete={hasCollectionUpsertPermission}
                  data-testid={testIds.media}
                  disablePortal
                />
              ))}
            </Sortable>
          )}
          {isLoading && (
            <div className={classes.skeletonWrapper}>
              <div className={displayAs === DisplayAsOptions.LIST ? classes.listSkeleton : classes.searchResultsInner}>
                <Repeat n={NUMBER_OF_LOADING_TILES}>
                  <Skeleton animation="wave" className={classes.skeletonMedia} data-testid={testIds.loading} />
                </Repeat>
              </div>
            </div>
          )}
        </List>
      </ShadowScroller>
    </div>
  );
}
export default ContentSearchResults;
