import React from 'react';
import { Avatar, Chip } from '@mui/material';
import { ChipProps } from '@mui/material/Chip';
import { makeStyles } from 'tss-react/mui';
import { themeColors } from '../../../theme';

export interface ICountryBadgeProps extends ChipProps {
  countryCode: string;
}

const useStyles = makeStyles()((theme) => ({
  chip: {
    backgroundColor: themeColors[theme.palette.mode].countryBadgeColor
  }
}));

export const testIds = {
  chip: 'country-badge.chip',
  avatar: 'country-bage.avatar'
};

export default function CountryBadge({ countryCode, ...props }: ICountryBadgeProps): React.ReactElement {
  const { classes } = useStyles();

  return (
    <Chip
      className={classes.chip}
      avatar={<Avatar src={`/assets/flags/${countryCode.toLowerCase()}.png`} data-testid={testIds.avatar} />}
      size="small"
      label={countryCode}
      data-testid={testIds.chip}
      {...props}
    />
  );
}
