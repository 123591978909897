import { RecoilValueReadOnly, selector, selectorFamily } from 'recoil';
import { TournamentBody, TournamentResponse } from '../../../../API';
import { DataManagerState, DataManagerStateFullReturnType } from '../../../DataManager/DataManagerState';

export interface TournamentsStateFullReturnType
  extends DataManagerStateFullReturnType<TournamentResponse, TournamentBody> {
  withTournamentsBySport: (
    param: string | string[] | undefined
  ) => RecoilValueReadOnly<TournamentResponse[] | undefined>;
  withTournamentByTournamentId: (param: string | undefined) => RecoilValueReadOnly<TournamentResponse | undefined>;
  withTournamentsByTournamentId: RecoilValueReadOnly<Record<string, TournamentResponse>>;
}

export function TournamentsState(name: string): TournamentsStateFullReturnType {
  const genericState = DataManagerState<TournamentResponse, TournamentBody>(name);

  const withTournamentsBySport = selectorFamily<TournamentResponse[] | undefined, string | string[] | undefined>({
    key: `${name}.tournamentsBySport`,
    get: (id) => {
      return ({ get }) => {
        if (!id || (Array.isArray(id) && !id.length)) return get(genericState.withAllRecords);
        if (Array.isArray(id)) {
          return get(genericState.withAllRecords)?.filter((t) => id.includes(t.sport));
        }
        return get(genericState.withAllRecords)?.filter((t) => t.sport === id);
      };
    }
  });

  const withTournamentByTournamentId = selectorFamily<TournamentResponse | undefined, string | undefined>({
    key: `${name}.tournamentByTournamentId`,
    get: (id) => {
      return ({ get }) => (id ? get(withTournamentsByTournamentId)[id] : undefined);
    }
  });

  const withTournamentsByTournamentId = selector<Record<string, TournamentResponse>>({
    key: `${name}.tournamentsByTournamentId`,
    get: ({ get }) => {
      const data = get(genericState.withDataCache);
      const recordsById: Record<string, TournamentResponse> = {};
      Object.keys(data).forEach((recordId) => {
        recordsById[data[recordId].object.tournamentId] = data[recordId].object;
      });
      return recordsById;
    }
  });

  return {
    ...genericState,
    withTournamentsBySport,
    withTournamentByTournamentId,
    withTournamentsByTournamentId
  };
}
