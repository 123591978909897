import React from 'react';
import { OutboundUiModuleBody } from '../../../../../API';
import Localized from '../../../../shared/Localized';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';

export type OutboundListItemProps = IBaseUIModuleListItemProps<OutboundUiModuleBody>;

export const testIds = {
  button: 'outbound-list-item.button',
  title: 'outbound-list-item.title'
};

export function OutboundListItem({ uiModule, ...props }: OutboundListItemProps): JSX.Element {
  return (
    <>
      <UIModuleListItem
        uiModule={uiModule}
        topLineTitle={<Localized prop={uiModule.title} data-testid={testIds.title} />}
        {...props}
      />
    </>
  );
}
