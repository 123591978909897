import React from 'react';
import { useLocales } from '../../../hooks';
import { makeStyles } from 'tss-react/mui';
import { AssetGuideline, AssetGuidelineCheckResult, AssetTypesEnum } from '../../../utils/assetTypes';
import { CheckCircle, Warning } from '@mui/icons-material';

export interface AssetGuidelinesBreakdownProps {
  guidelines: AssetGuideline;
  results: AssetGuidelineCheckResult;
  type: AssetTypesEnum;
}

const useStyles = makeStyles()((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'center'
  },
  guidelinesLabel: {
    fontWeight: 'bold'
  },
  header: {
    marginBottom: theme.spacing(4),
    textAlign: 'left'
  }
}));

function Icon({ adheres }: { adheres: boolean }): JSX.Element {
  return adheres ? <CheckCircle color="success" /> : <Warning color="warning" />;
}

export function AssetGuidelinesBreakdown({ guidelines, results, type }: AssetGuidelinesBreakdownProps): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();

  return (
    <div>
      <div className={classes.header}>
        {results.adheres ? t('assets.guidelines.adheres_text') : t('assets.guidelines.does_not_adhere_text', { type })}
      </div>
      {guidelines.preferredFileTypes && (
        <div className={classes.row}>
          <Icon adheres={results.adheresFileType} />
          <span className={classes.guidelinesLabel}>{t('assets.guidelines.preferred_file_types')}:</span>
          {guidelines.preferredFileTypes.join(', ')}
        </div>
      )}
      {guidelines.preferredAspectRatio && (
        <div className={classes.row}>
          <Icon adheres={results.adheresAspectRatio} />
          <span className={classes.guidelinesLabel}>{t('assets.guidelines.preferred_aspect_ratio')}:</span>
          {guidelines.preferredAspectRatio}
        </div>
      )}
      {guidelines.minWidth && (
        <div className={classes.row}>
          <Icon adheres={results.adheresWidth} />
          <span className={classes.guidelinesLabel}>{t('assets.guidelines.recommended_width')}:</span>
          {guidelines.minWidth}px
        </div>
      )}
      {guidelines.minHeight && (
        <div className={classes.row}>
          <Icon adheres={results.adheresHeight} />
          <span className={classes.guidelinesLabel}>{t('assets.guidelines.recommended_height')}:</span>
          {guidelines.minHeight}px
        </div>
      )}
    </div>
  );
}
