import React, { memo } from 'react';
import { makeStyles } from 'tss-react/mui';
import NavBar from './NavBar/';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flexGrow: 1
  }
}));

function MainLayout(): JSX.Element {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <NavBar />
      <div className={classes.content}>
        <Outlet />
      </div>
    </div>
  );
}

export default memo(MainLayout);
