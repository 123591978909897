import React from 'react';
import { ButtonGroup, Button, Tooltip, TooltipProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CropLandscape, CropPortrait, List } from '@mui/icons-material';
import { RecoilState, useRecoilState } from 'recoil';
import { useLocales } from '../../../hooks';
import { DisplayAsOptions } from '../../../utils/types/genericTypes';

export const useStyles = makeStyles()((theme) => ({
  displayAs: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius
  },
  displayAsButton: {
    backgroundColor: theme.palette.background.default,
    borderColor: `${theme.palette.divider} !important`,
    '&:not(:hover)': {
      color: theme.palette.getContrastText(theme.palette.background.default)
    }
  },
  displayAsButtonSelected: {
    backgroundColor: theme.palette.primary.main
  }
}));

export const testIds = {
  buttonGroup: 'display-as.button-group',
  listViewButton: 'display-as.list-view-button',
  portraitViewButton: 'display-as.portrait-view-button',
  landscapeViewButton: 'display-as.landscape-view-button'
};

type DisplayAsProps = {
  state: RecoilState<DisplayAsOptions>;
  disabled?: boolean;
};

export function DisplayAs({ state: withDisplayAs, disabled }: DisplayAsProps): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const [displayAs, setDisplayAs] = useRecoilState(withDisplayAs);
  const tooltipProps = { placement: 'top' } as Partial<Pick<TooltipProps, 'placement'>>;

  const getButtonClass = (which: DisplayAsOptions) =>
    displayAs === which ? classes.displayAsButtonSelected : classes.displayAsButton;

  return (
    <div className={classes.displayAs}>
      <ButtonGroup disableElevation variant="contained" data-testid={testIds.buttonGroup}>
        <Tooltip {...tooltipProps} title={t('general.display_as_list')} arrow>
          <Button
            disabled={disabled}
            size="small"
            className={getButtonClass(DisplayAsOptions.LIST)}
            onClick={() => setDisplayAs(DisplayAsOptions.LIST)}
            data-testid={testIds.listViewButton}
          >
            <List />
          </Button>
        </Tooltip>
        <Tooltip {...tooltipProps} title={t('general.display_as_portrait')} arrow>
          <Button
            disabled={disabled}
            size="small"
            className={getButtonClass(DisplayAsOptions.PORTRAIT)}
            onClick={() => setDisplayAs(DisplayAsOptions.PORTRAIT)}
            data-testid={testIds.portraitViewButton}
          >
            <CropPortrait />
          </Button>
        </Tooltip>
        <Tooltip {...tooltipProps} title={t('general.display_as_landscape')} arrow>
          <Button
            disabled={disabled}
            size="small"
            className={getButtonClass(DisplayAsOptions.LANDSCAPE)}
            onClick={() => setDisplayAs(DisplayAsOptions.LANDSCAPE)}
            data-testid={testIds.landscapeViewButton}
          >
            <CropLandscape />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </div>
  );
}
