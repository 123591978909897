import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Typography } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { CollectionListUiModuleBody, ContentCollectionType } from '../../../../../API';
import { useLocales, useNotifications } from '../../../../../hooks';
import { FormBody } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { makeStyles } from 'tss-react/mui';
import { Stack } from '@mui/system';
import Button from '../../../../shared/Button';
import { CollectionPicker, collectionPickerClasses } from '../../CollectionPicker';
import { Add } from '@mui/icons-material';
import { CollectionListFormListItem, collectionListFormListItemClasses } from './CollectionListFormListItem';
import { Sortable } from '../../../../shared/Sortable';
import { CollectionPages } from '../../../../shared/CollectionPages';

export const testIds = {
  formBody: 'collection-list-form.form-body',
  addButton: 'collection-list-form.add-button'
};

const useStyles = makeStyles()((theme) => ({
  collectionsContainer: {
    overflow: 'hidden',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    [`& .${collectionListFormListItemClasses.root}:last-child`]: {
      borderBottom: 'none'
    }
  },
  stack: {
    [`& .${collectionPickerClasses.root}`]: {
      flexGrow: 1
    }
  }
}));

const CollectionListForm = forwardRef<UIModuleTypeForm<CollectionListUiModuleBody>>((_, ref) => {
  const { control, watch } = useFormContext<CollectionListUiModuleBody>();
  const { append, fields, remove, replace } = useFieldArray({ control, name: 'collections', keyName: '_id' });
  const { notifyError } = useNotifications();
  const { classes } = useStyles();
  const { t } = useLocales();

  const collectionTypes = Object.values(ContentCollectionType).filter(
    (type) => type !== ContentCollectionType.MIXED_LIST
  );

  useImperativeHandle(ref, () => ({
    isValid(module) {
      if (!module.collections.length) {
        notifyError(t('errors.layouts.no_empty_collections'));
        return false;
      }

      return true;
    }
  }));

  const [collection, setCollection] = useState<string>();
  const entitlements = watch('entitlements');

  const onAdd = () => {
    if (collection) {
      append({ id: collection });
      setCollection('');
    }
  };

  return (
    <FormBody data-testid={testIds.formBody}>
      <Controller
        control={control}
        name="templateUrlPath"
        rules={{ required: t('general.field_is_required') }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <CollectionPages
            label={t('layouts.template_url_path')}
            required
            value={value}
            onChange={(evt) => onChange(evt.target.value)}
            error={!!error}
            helperText={error?.message}
          />
        )}
      />
      <Stack gap={4}>
        <Typography variant="h6">{t('collections.collections')}</Typography>
        {!!fields.length && (
          <div className={classes.collectionsContainer}>
            <Sortable
              list={fields}
              setList={replace}
              animation={100}
              ghostClass="sortableGhost"
              filter=".sortableFiltered"
            >
              {fields.map((field, index) => (
                <CollectionListFormListItem key={field._id} value={field.id} onRemove={() => remove(index)} />
              ))}
            </Sortable>
          </div>
        )}
        <Stack direction="row" gap={2} alignItems="center" className={classes.stack}>
          <CollectionPicker
            label={t('content.drawer.search_collections')}
            value={collection}
            onChange={setCollection}
            entitlements={entitlements}
            hideEntitlements
            filters={{ includeCardImage: true, collectionTypes }}
          />
          <Button startIcon={<Add />} onClick={onAdd} data-testid={testIds.addButton}>
            {t('general.add')}
          </Button>
        </Stack>
      </Stack>
    </FormBody>
  );
});

CollectionListForm.displayName = 'CollectionListForm';

export default CollectionListForm;
