import { atom } from 'recoil';
import { VersionLog } from '../../API/model/version-log';
import versionLogFrontend from '../../version-log.json';

export const withLogVersionFrontend = atom<VersionLog>({
  key: 'logVersion.frontend',
  default: versionLogFrontend
});

export const withLogVersionBackend = atom<VersionLog>({
  key: 'logVersion.backend',
  default: {} as VersionLog
});
