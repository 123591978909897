import { Role, UserBody, UserResponse } from '../../../API';
import { useUsersAPI } from '../../../hooks/API/Users/useUsersAPI';
import { CreateDataManager, DataManagerHookReturnType, DataManagerStateFullReturnType } from '../../DataManager';

export type UsersDataManagerType = {
  state: DataManagerStateFullReturnType<UserResponse, UserBody>;
  hook: DataManagerHookReturnType<UserResponse, UserBody>;
};

export const generateNewUser = (): UserBody => ({
  firstName: '',
  lastName: '',
  email: '',
  roles: [Role.USER]
});

export const UsersDataManager = CreateDataManager<UserResponse, UserBody>({
  name: 'users',
  idField: 'email',
  useApiHook: useUsersAPI,
  // Function to generate a new user
  generateNew: generateNewUser,
  // Function used to generate a user string for success & error handling
  toString: (user: UserBody | UserResponse) => `${user.firstName} ${user.lastName} (${user.email})`
});
