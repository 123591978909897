/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { EntitlementType } from './entitlement-type';
import { LocalizedFieldBody } from './localized-field-body';
import { TargetPlatform } from './target-platform';
import { UiModuleExperimentGroup } from './ui-module-experiment-group';

/**
 * 
 * @export
 * @interface LinkingAccountUiModuleBody
 */
export interface LinkingAccountUiModuleBody {
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    moduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    moduleType: LinkingAccountUiModuleBodyModuleTypeEnum;
    /**
     * 
     * @type {Array<TargetPlatform>}
     * @memberof LinkingAccountUiModuleBody
     */
    targetPlatform?: Array<TargetPlatform>;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof LinkingAccountUiModuleBody
     */
    caption?: LocalizedFieldBody;
    /**
     * 
     * @type {Array<EntitlementType>}
     * @memberof LinkingAccountUiModuleBody
     */
    entitlements?: Array<EntitlementType>;
    /**
     * 
     * @type {Array<UiModuleExperimentGroup>}
     * @memberof LinkingAccountUiModuleBody
     */
    experiments?: Array<UiModuleExperimentGroup> | null;
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    vixLogo: string;
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    partnerLogo: string;
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    background: string;
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    avodHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    avodSubHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    createAccountCta?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    signInCta?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    linkExistingAccountCta?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    createNewAccountCta?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    loggedInHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    loggedInSubHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    loggedInLinkingCta?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    loggedInCreateNewAccountCta?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    svodHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    svodSubHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    svodCreateAccountCta?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkingAccountUiModuleBody
     */
    notNowCta?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum LinkingAccountUiModuleBodyModuleTypeEnum {
    LINKING_ACCOUNT = 'LINKING_ACCOUNT'
}



