import React from 'react';
import { Link, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Button from '../../components/shared/Button';
import { useLocales, useTheme } from '../../hooks';
import versionLog from '../../version-log.json';
import { VixCmsLogo } from '../shared/VixCmsLogo';
import { getLatestVersion } from '../Layout/NavBar/VersionLog/VersionLog';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    background: 'linear-gradient(-45deg, #CB2372 0%, #FF561E 50%, #CB2372 100%)', // TU colors
    position: 'relative',
    textAlign: 'center'
  },
  darkOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)'
  },
  contentContainer: {
    position: 'relative',
    zIndex: 1,
    minWidth: 400,
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4]
  },
  contentTop: {
    padding: theme.spacing(5, 0, 6)
  },
  contentBottom: {
    background: theme.palette.background.paper,
    borderBottomRightRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderTop: `1px solid ${theme.palette.divider}`
  },
  heading: {
    margin: theme.spacing(3, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& path': {
      fill: `${theme.palette.background.default} !important`
    }
  },
  tuLogo: {
    height: 36
  },
  topBottom: {
    flexGrow: 1,
    flexBasis: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  supportLinkWrapper: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  supportLinkContainer: {
    background: theme.palette.background.paper,
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTop: `1px solid ${theme.palette.divider}`
  },
  supportLink: {
    textDecoration: 'none'
  },
  supportLinkButton: {
    color: `${theme.palette.getContrastText(theme.palette.background.paper)} !important`,
    padding: theme.spacing(2, 4)
  },
  p3Logo: {
    height: 16,
    marginRight: theme.spacing(2)
  }
}));

export const testIds = {
  loginButton: 'login.login-button'
};

export default function LoginPage({ children }: { children?: React.ReactNode }): JSX.Element {
  const { classes } = useStyles();
  const { isDarkMode } = useTheme();
  const { t } = useLocales();

  const supportLink = process.env.REACT_APP_SLACK_SUPPORT_LINK;

  return (
    <div className={classes.root}>
      <div className={isDarkMode ? classes.darkOverlay : ''} />
      <div className={classes.contentContainer}>
        <div className={classes.topBottom}></div>
        <div className={classes.content}>
          <div className={classes.contentTop}>
            <div className={classes.heading}>
              <VixCmsLogo />
              <Typography variant="subtitle1" color="textSecondary">
                {getLatestVersion(versionLog)}
              </Typography>
            </div>
            <div className={classes.heading}>
              <Typography variant="subtitle1" color="textSecondary" sx={{ mr: 1 }}>
                {t('general.powered_by')}
              </Typography>
              <img className={classes.tuLogo} src="/assets/tu-logo.svg" />
            </div>
          </div>
          <div className={classes.contentBottom}>{children}</div>
        </div>

        <div className={classes.topBottom}>
          <div className={classes.supportLinkWrapper}>
            <div className={classes.supportLinkContainer}>
              <Link target="_blank" rel="noreferrer" className={classes.supportLink} href={supportLink}>
                <Button variant="text" className={classes.supportLinkButton}>
                  <img src="/assets/slack-logo.svg" className={classes.p3Logo} />
                  {t('auth.support_message')}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
