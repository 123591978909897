import React from 'react';
import { Cancel, CheckCircle, Visibility } from '@mui/icons-material';
import { Chip, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { EntitlementType } from '../../../API';
import { useLocales } from '../../../hooks';
import { useEntitlements } from '../../../hooks/General/useEntitlements';
import { AnonIcon, FreeIcon, SubIcon } from './Icons';
import { BadgeSize } from '../../../utils/types/genericTypes';
import { allEntitlementTypes } from '../../EPG/EPGCategories/EPGCategoryOrderings/EPGCategoryOrderingForm';
export interface IEntitlementsVisibilityBadgeProps {
  'data-testid'?: string;
  entitlements: EntitlementType[];
  size?: BadgeSize;
}

const useStyles = makeStyles<{ size: BadgeSize }>()((theme, props) => {
  const isSmall = props.size === 'small';
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1)
    },
    badge: {
      height: isSmall ? 20 : 24
    },
    badgeLabel: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2)
    },
    iconContainer: {
      width: isSmall ? 15 : 18,
      display: 'flex'
    },
    visibilityIconContainer: {
      display: 'flex'
    },
    visibilityIcon: {
      fontSize: isSmall ? 17 : 20
    },
    tooltipTitle: {
      fontWeight: 'bold',
      fontSize: '1.1em',
      marginBottom: '5px'
    },
    tooltipRow: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      marginRight: theme.spacing(4),
      whiteSpace: 'nowrap'
    }
  };
});

export function EntitlementsVisibilityBadge({
  entitlements,
  size = 'medium',
  ...props
}: IEntitlementsVisibilityBadgeProps): React.ReactElement {
  const { classes } = useStyles({ size });
  const { t } = useLocales();
  const { localizeEntitlement } = useEntitlements();

  return (
    <Tooltip
      title={
        <div>
          <div className={classes.tooltipTitle}>{t('general.user_access_level')}</div>
          {allEntitlementTypes.map((entitlement) => {
            const hasEntitlement = entitlements.includes(entitlement);
            return (
              <div className={classes.tooltipRow} key={entitlement}>
                {!hasEntitlement && <Cancel color={'error'} />}
                {hasEntitlement && <CheckCircle color={'success'} />}
                {localizeEntitlement(entitlement)}
              </div>
            );
          })}
        </div>
      }
      arrow
      placement="top"
    >
      <div className={classes.container}>
        <Typography className={classes.visibilityIconContainer} component="div" color="textSecondary">
          <Visibility className={classes.visibilityIcon} />
        </Typography>
        <Chip
          className={classes.badge}
          label={
            <div className={classes.badgeLabel}>
              <div className={classes.iconContainer}>
                <AnonIcon disabled={!entitlements.includes(EntitlementType.ANON)} />
              </div>
              <div className={classes.iconContainer}>
                <FreeIcon disabled={!entitlements.includes(EntitlementType.FREE)} />
              </div>
              <div className={classes.iconContainer}>
                <SubIcon disabled={!entitlements.includes(EntitlementType.SUB)} />
              </div>
            </div>
          }
          data-testid={props['data-testid']}
        />
      </div>
    </Tooltip>
  );
}
