import React, { useEffect, useRef, useState } from 'react';
import { useLocales, useTheme } from '../../../hooks';
import Button from '../../shared/Button';
import ShadowScroller from '../../shared/ShadowScroller';
import { AssetUploader } from '../AssetUploader';
import { AssetResponse } from '../../../API';
import { ListItemButton, Popover, Tooltip, Typography } from '@mui/material';
import { ImageSearch } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import Repeat from '../../shared/Repeat';
import { AspectRatio } from '../../../utils/aspectRatios';
import { Accept } from 'react-dropzone';
import { useData } from '../../../data-layer';
import { useRecoilValue } from 'recoil';
import { ShadowList } from '../../shared/Virtuoso';
import { AssetCard, testIds as assetCardTestIds } from './AssetCard';
import { AssetSkeleton } from './AssetSkeleton';
import { wildcard } from '../../../utils/regex';
import TextField from '../../shared/TextField';
import { markAsRequired } from '../../../utils/formHelpers';
import { AssetTypesEnum } from '../../../utils/assetTypes';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  elementRoot: {
    display: 'flex'
  },
  formLabelError: {
    color: theme.palette.error.main
  },
  root: {
    position: 'relative',
    width: '100%'
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    height: '100%',
    minHeight: 44
  },
  popoverContainer: {
    background: theme.palette.background.paper,
    height: '45vh',
    minHeight: 300,
    width: 400,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3, 2, 2),
    display: 'flex',
    flexDirection: 'column'
  },
  assetRow: {
    padding: theme.spacing(1, 2)
  },
  assetsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2)
  }
}));

const ASSET_CARD_SIZE = 42;

export const testIds = {
  assetBrowserRoot: 'AssetBrowser-root',
  assetBrowserBrowseButton: 'AssetBrowser-browseBtn',
  assetBrowserFilter: 'AssetBrowser-filter',
  assetBrowserUploadButton: 'AssetBrowser-uploadBtn',
  listItem: 'AssetBrowser-listItem',
  loader: 'AssetBrowser-loader',
  ...assetCardTestIds
};

type AssetBrowserProps = {
  assetId?: string;
  assetType: AssetTypesEnum;
  label?: string;
  required?: boolean;
  value?: string | undefined;
  hasError?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  acceptedFileTypes?: Accept;
  useDarkBackground?: boolean;
  aspectRatios?: AspectRatio[];
  'data-testid'?: string;
};

function AssetBrowserComponent({
  assetType,
  assetId = assetType,
  label,
  required,
  value,
  hasError,
  onChange,
  acceptedFileTypes,
  useDarkBackground,
  aspectRatios,
  ...props
}: AssetBrowserProps): JSX.Element {
  const { cx, classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const {
    assets: {
      state: { withRecordBucket, withAssetByFilePath },
      hook: { getBucket, getByFilePath }
    }
  } = useData();

  const assets = useRecoilValue(withRecordBucket(assetType));
  const asset = useRecoilValue(withAssetByFilePath(value));

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAsset, setIsLoadingAsset] = useState(false);

  const [filteredAssets, setFilteredAssets] = useState<AssetResponse[]>(assets || []);
  const [open, setOpen] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [invalid, setInvalid] = useState(false);
  const popoverAnchorRef = useRef(null);
  const [editingAsset, setEditingAsset] = useState<AssetResponse | undefined>();
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    loadAssets();
  }, [assetType]);

  useEffect(() => {
    if (!asset) {
      loadAsset();
    }
  }, [value]);

  const loadAsset = async () => {
    if (value) {
      setIsLoadingAsset(true);
      const fetched = await getByFilePath(value);
      setInvalid(!fetched);
      setIsLoadingAsset(false);
    }
  };

  const loadAssets = async () => {
    if (!assets) {
      setIsLoading(true);
      await getBucket?.(assetType);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (assets) {
      setFilteredAssets(assets);
    }
  }, [assets]);

  useEffect(() => {
    updateFilteredAssets(assets || []);
  }, [filter]);

  const updateFilteredAssets = (assetsList: AssetResponse[]) => {
    if (!filter) return setFilteredAssets(assetsList);
    setFilteredAssets(assetsList.filter((asset) => RegExp(wildcard(filter), 'i').test(asset.assetName)));
  };

  const handleBrowseClick = () => {
    const isOpen = !open;
    setOpen(isOpen);
    if (isOpen) {
      // Focus the search input when opening the browser
      setTimeout(() => {
        searchInputRef.current?.focus();
      });
    }
  };

  const handleUploadClick = () => setIsUploadOpen(true);

  const handleAssetFormClose = () => {
    setIsUploadOpen(false);
    setEditingAsset(undefined);
  };

  const fireOnChange = (value: string | undefined) => {
    // mock out a fake change event to trigger the LocalizedInputCollection onChange event
    if (onChange) {
      onChange({ target: { value } } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const selectAsset = (asset: AssetResponse) => {
    fireOnChange(asset.filePath);
  };

  const handleAssetSelect = (asset: AssetResponse) => {
    selectAsset(asset);
    setOpen(false);
  };

  const handleAssetUpload = (asset: AssetResponse, isEditing: boolean) => {
    setEditingAsset(undefined);
    if (!isEditing) {
      selectAsset(asset);
      setOpen(false);
    }
  };

  const handleClear = () => {
    fireOnChange('');
  };

  return (
    <>
      <div data-testid={props['data-testid']} className={classes.container}>
        {label && (
          <Typography
            component="div"
            color="textSecondary"
            variant="body2"
            className={cx({ [classes.formLabelError]: hasError })}
          >
            {required ? markAsRequired(label) : label}
          </Typography>
        )}
        <div data-testid={testIds.assetBrowserRoot} className={classes.elementRoot}>
          <div className={classes.root}>
            <div className={classes.inline}>
              {isLoadingAsset && <AssetSkeleton data-testid={testIds.loader} imageWidth={ASSET_CARD_SIZE} />}
              {!isLoadingAsset && (
                <AssetCard
                  data-selected-card
                  asset={asset}
                  imageWidth={ASSET_CARD_SIZE}
                  clearable
                  hasError={hasError}
                  onClear={handleClear}
                  invalid={invalid}
                  useDarkBackground={useDarkBackground}
                  onEdit={setEditingAsset}
                />
              )}
              <Tooltip title={t('assets.browse')} placement="top" arrow>
                <div ref={popoverAnchorRef}>
                  <Button
                    data-testid={testIds.assetBrowserBrowseButton}
                    color="grey"
                    onClick={handleBrowseClick}
                    loading={isLoading}
                  >
                    <ImageSearch />
                  </Button>
                </div>
              </Tooltip>
            </div>
            <Popover
              open={open}
              onClose={() => setOpen(false)}
              anchorEl={popoverAnchorRef.current}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <div className={classes.popoverContainer}>
                <TextField
                  data-testid={testIds.assetBrowserFilter}
                  variant="outlined"
                  value={filter}
                  clearable
                  onChange={({ target: { value } }) => setFilter(value)}
                  placeholder={t('general.search')}
                  color={formControlColor}
                  fullWidth
                  inputRef={searchInputRef}
                />
                {isLoading ? (
                  <ShadowScroller loading>
                    <div className={classes.assetsContainer}>
                      <Repeat n={10}>
                        <AssetSkeleton imageWidth={ASSET_CARD_SIZE} />
                      </Repeat>
                    </div>
                  </ShadowScroller>
                ) : (
                  <ShadowList
                    data={filteredAssets}
                    itemContent={(index, data) => (
                      <ListItemButton
                        color="secondary"
                        key={data.id}
                        className={classes.assetRow}
                        onClick={() => handleAssetSelect(data)}
                        data-testid={testIds.listItem}
                        data-filepath={data.filePath}
                      >
                        <AssetCard
                          data-card-index={index}
                          imageWidth={ASSET_CARD_SIZE}
                          asset={data}
                          useDarkBackground={useDarkBackground}
                          onEdit={setEditingAsset}
                        />
                      </ListItemButton>
                    )}
                  />
                )}
                <Button
                  data-testid={testIds.assetBrowserUploadButton}
                  style={{ marginTop: 10 }}
                  onClick={handleUploadClick}
                  loading={isLoading}
                >
                  {t('assets.new_asset')}
                </Button>
              </div>
            </Popover>
          </div>
        </div>
      </div>

      <AssetUploader
        assetId={assetId}
        assetType={assetType}
        open={isUploadOpen}
        editingAsset={editingAsset}
        onAssetUpload={handleAssetUpload}
        acceptedFileTypes={acceptedFileTypes}
        onClose={handleAssetFormClose}
        aspectRatios={aspectRatios}
      />
    </>
  );
}

export const AssetBrowser = React.memo(AssetBrowserComponent);
