import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../../../../hooks';
import { ChildCare } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { ContentCollectionResponse } from '../../../../../API';

export interface IKidsBadgeProps {
  collection: ContentCollectionResponse;
}

const useStyles = makeStyles()((theme) => ({
  badge: {
    display: 'flex'
  },
  header: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '1.1em'
  }
}));

export const testIds = {
  badge: 'auto-rotate-badge.badge'
};

export function KidsBadge({ collection }: IKidsBadgeProps): React.ReactElement {
  const { t } = useLocales();
  const { classes } = useStyles();

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        <>
          <Typography className={classes.header}>{t('collections.kids.enabled')}</Typography>
        </>
      }
    >
      <div className={classes.badge}>{collection.kids && <ChildCare data-testid={testIds.badge} />}</div>
    </Tooltip>
  );
}
