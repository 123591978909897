import { debounce } from 'lodash-es';
import { RecoilState, useRecoilState } from 'recoil';

type UseSplitPaneReturnType = {
  sizes: number[];
  onChange: (sizez: number[]) => unknown;
};

export function useSplitPane(atom: RecoilState<number[]>): UseSplitPaneReturnType {
  const [sizes, setSizes] = useRecoilState(atom);
  const onChange = debounce(setSizes, 150);
  return { sizes, onChange };
}
