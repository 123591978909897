import { useSetRecoilState } from 'recoil';
import { PromotionBody, PromotionResponse, PromotionsApi, PromotionTypeResponse } from '../../../../API';
import {
  CacheManager,
  DataManagerHelpers,
  DataManagerHook,
  DataManagerHookReturnType,
  IDataManagerHookProps
} from '../../../DataManager';

export interface PromotionsHookReturnType extends DataManagerHookReturnType<PromotionResponse, PromotionBody> {
  startPromotion: (promotion: PromotionResponse) => Promise<boolean>;
  endPromotion: (promotion: PromotionResponse) => Promise<boolean>;
  getPromotionTypes: () => Promise<PromotionTypeResponse[]>;
}

export function PromotionsHook(
  params: IDataManagerHookProps<PromotionResponse, PromotionBody>
): PromotionsHookReturnType {
  const dataManagerHook = DataManagerHook<PromotionResponse, PromotionBody>(params);
  const api = params.useApiHook() as PromotionsApi;
  const setCache = useSetRecoilState(params.state.withDataCache);
  const { addRecordToCache } = CacheManager(params.idField, setCache);
  const { handleApiError } = DataManagerHelpers({ name: params.name, toString });

  const startPromotion = async (promotion: PromotionResponse) => {
    try {
      const response = await api.startPromotion(promotion.id);
      addRecordToCache(response.data.body);
      return true;
    } catch (err) {
      handleApiError(err, 'start_promotion');
      return false;
    }
  };

  const endPromotion = async (promotion: PromotionResponse) => {
    try {
      const response = await api.endPromotion(promotion.id);
      addRecordToCache(response.data.body);
      return true;
    } catch (err) {
      handleApiError(err, 'end_promotion');
      return false;
    }
  };

  const getPromotionTypes = async () => {
    try {
      const response = await api.getPromotionTypes();
      return response.data.body;
    } catch (err) {
      handleApiError(err, 'get_promotion_types');
      return [];
    }
  };

  return {
    ...dataManagerHook,
    startPromotion,
    endPromotion,
    getPromotionTypes
  };
}
