import React, { useEffect } from 'react';
import { useData } from '../../../data-layer';
import { useRecoilValue } from 'recoil';
import LinkWithIcon from '../../shared/LinkWithIcon';
import { AppRoutes } from '../../../Routes';
import { ContentCollectionType, DocumentLocale } from '../../../API';
import { Stack } from '@mui/material';
import { DeletedBadge } from '../../shared/DeletedBadge';
import { isEntityDeleted } from '../../../utils/generalUtils';

export interface ICollectionLinkProps {
  collectionId: string;
  'data-testid'?: string;
}

export function CollectionLink({ collectionId, ...props }: ICollectionLinkProps): React.ReactElement {
  const {
    collections: { hook: useCollections, state: collectionsState }
  } = useData();

  const collection = useRecoilValue(collectionsState.withLatestPublishedRecordById(collectionId));

  useEffect(() => {
    if (!collection) {
      useCollections.queueLatestPublishedIdToFetch(collectionId, true);
    }
  }, [collectionId]);

  const pathname =
    collection?.collectionType === ContentCollectionType.MIXED_LIST
      ? AppRoutes.collection(collectionId)
      : AppRoutes.vodCollection(collectionId);

  return (
    <Stack direction="row" alignItems="center">
      {isEntityDeleted(collection) && <DeletedBadge />}
      <LinkWithIcon
        pathname={pathname}
        title={collection?.title || { [DocumentLocale.ES]: collectionId }}
        data-testid={props['data-testid']}
      />
    </Stack>
  );
}
