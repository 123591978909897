import { CouponResponse } from '../../../../API';
import { useCouponsAPI } from '../../../../hooks/API/Payments/useCouponsAPI';
import { CreateDataManager, DataManagerHookReturnType, DataManagerStateFullReturnType } from '../../../DataManager';

export type CouponsDataManagerType = {
  state: DataManagerStateFullReturnType<CouponResponse, CouponResponse>;
  hook: DataManagerHookReturnType<CouponResponse, CouponResponse>;
};

export const CouponsDataManager = CreateDataManager<CouponResponse, CouponResponse>({
  name: 'coupons',
  idField: 'code',
  useApiHook: useCouponsAPI,
  // Function used to generate a coupon string for success & error handling
  toString: (coupon: CouponResponse) => `${coupon.name} (${coupon.code})`
});
