/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum CouponCodeState {
    REDEEMABLE = 'redeemable',
    MAXED_OUT = 'maxed_out',
    EXPIRED = 'expired',
    INACTIVE = 'inactive',
    INVALID_FOR_PLAN = 'invalid_for_plan',
    COUPON_NOT_FOUND = 'coupon_not_found',
    INVALID_WITH_MULTIPLE_CURRENCIES = 'invalid_with_multiple_currencies',
    INVALID_DIFFERENT_CURRENCY_THAN_PLAN = 'invalid_different_currency_than_plan'
}



