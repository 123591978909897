import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useLocales, useNotifications, useTheme } from '../../hooks';
import { FormControlLabel, InputLabel, MenuItem, Select, Switch, Typography } from '@mui/material';
import { Button } from '../shared/Button/Button';
import { clearLocalStorage } from '../../state/localStorageState';
import { Delete } from '@mui/icons-material';
import Drawer from '../shared/Drawer';
import { useRecoilState } from 'recoil';
import {
  HomepageOptions,
  withHomepage,
  withIsDarkMode,
  withThemeColorPrimary,
  withThemeColorSecondary,
  withThemeSpacing
} from '../../state/theme';
import FormControl from '../shared/FormControl';
import { primaryColors, secondaryColors, SpacingEnum } from '../../theme';
import { ColorPicker } from '../shared/ColorPicker';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4)
  },
  sectionHeader: {
    marginTop: theme.spacing(4)
  },
  displaySettings: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6)
  },
  colorPicker: {
    width: '100% !important',
    maxWidth: 'fit-content',
    flexGrow: 1
  },
  colorPickerLabel: {
    marginBottom: theme.spacing(1)
  },
  homepage: {
    width: 220
  }
}));

export const testIds = {
  appSettingsModal: 'navbarmenu-app-settings-modal',
  darkModeToggle: 'navbarmenu-dark-mode-toggle',
  closeButton: 'app-settings.close-button',
  clearLocalStorageButton: 'app-settings.clear-local-storage-button',
  primaryColorPicker: 'app-settings.primary-color-picker',
  secondaryColorPicker: 'app-settings.secondary-color-picker',
  themeSpacingSelector: 'app-settings.theme-spacing-selector',
  themeSpacingSelectorOption: 'app-settings.theme-spacing-selector-option'
};

export interface IAppSettingsProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function AppSettings({ isOpen, setIsOpen }: IAppSettingsProps): JSX.Element {
  const { classes } = useStyles();
  const { formControlColor } = useTheme();
  const { t } = useLocales();
  const { notifySuccess } = useNotifications();

  const [isDarkMode, setIsDarkMode] = useRecoilState(withIsDarkMode);
  const [primaryColor, setPrimaryColor] = useRecoilState(withThemeColorPrimary);
  const [secondaryColor, setSecondaryColor] = useRecoilState(withThemeColorSecondary);
  const [spacing, setSpacing] = useRecoilState(withThemeSpacing);
  const [homepage, setHomepage] = useRecoilState(withHomepage);

  const onClearLocalStorage = () => {
    clearLocalStorage();
    notifySuccess(t('local_storage.successfully_cleared'));
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Drawer
      open={isOpen}
      ModalProps={{ BackdropProps: { invisible: true } }}
      headerLeft={<Typography variant="h6">{t('app_settings.title')}</Typography>}
      footerLeft={
        <Button color="grey" onClick={onClose} data-testid={testIds.closeButton}>
          {t('general.close')}
        </Button>
      }
      onClose={onClose}
      data-testid={testIds.appSettingsModal}
    >
      <div className={classes.root}>
        <div className={classes.displaySettings}>
          <h3>{t('app_settings.display_settings')}</h3>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={!!isDarkMode}
                  color="secondary"
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>, val: boolean) => setIsDarkMode(val)}
                  data-testid={testIds.darkModeToggle}
                />
              }
              label={t('app_settings.dark_mode')}
            />
          </div>
          <div>
            <Typography className={classes.colorPickerLabel} color="textSecondary" variant="body2">
              {t('app_settings.primary_color')}
            </Typography>
            <ColorPicker
              className={classes.colorPicker}
              colors={primaryColors}
              value={primaryColor}
              onChange={(color) => setPrimaryColor(color.hex)}
              data-testid={testIds.primaryColorPicker}
            />
          </div>
          <div>
            <Typography className={classes.colorPickerLabel} color="textSecondary" variant="body2">
              {t('app_settings.secondary_color')}
            </Typography>
            <ColorPicker
              className={classes.colorPicker}
              colors={secondaryColors}
              value={secondaryColor}
              onChange={(color) => setSecondaryColor(color.hex)}
              data-testid={testIds.secondaryColorPicker}
            />
          </div>
          <div>
            <FormControl>
              <InputLabel id="spacingSelector">{t('app_settings.spacing.label')}</InputLabel>
              <Select
                label={t('app_settings.spacing.label')}
                labelId="spacingSelector"
                value={spacing}
                onChange={({ target: { value } }) => setSpacing(value as SpacingEnum)}
                data-testid={testIds.themeSpacingSelector}
              >
                {Object.values(SpacingEnum).map((value) => (
                  <MenuItem
                    key={value}
                    value={value}
                    data-testid={testIds.themeSpacingSelectorOption}
                    data-spacing={value}
                  >
                    {t(`app_settings.spacing.${value}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.homepage}>
              <InputLabel id="homepage">{t('general.homepage')}</InputLabel>
              <Select
                labelId="homepage"
                value={homepage}
                defaultValue={HomepageOptions.WELCOME}
                onChange={({ target: { value } }) => setHomepage(value as HomepageOptions)}
              >
                <MenuItem value={HomepageOptions.WELCOME}>{t('general.welcome')}</MenuItem>
                <MenuItem value={HomepageOptions.EPG}>EPG</MenuItem>
                <MenuItem value={HomepageOptions.COLLECTIONS}>{t('collections.mixed')}</MenuItem>
                <MenuItem value={HomepageOptions.VOD_COLLECTIONS}>{t('collections.vod_collections')}</MenuItem>
                <MenuItem value={HomepageOptions.HPC}>{t('hpc.hero_preset_collection')}</MenuItem>
                <MenuItem value={HomepageOptions.LAYOUTS}>{t('layouts.layouts')}</MenuItem>
                <MenuItem value={HomepageOptions.SPORTS}>{t('sports.sports')}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <h3 className={classes.sectionHeader}>{t('app_settings.data_settings')}</h3>
          <div>
            <Button
              variant="text"
              color={formControlColor}
              onClick={onClearLocalStorage}
              startIcon={<Delete />}
              data-testid={testIds.clearLocalStorageButton}
            >
              {t('local_storage.clear_local_storage')}
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
