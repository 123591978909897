import { useEffect } from 'react';
import { Timezone, withCurrentTimezone } from '../../state/Timezone';
import { useRecoilState } from 'recoil';
import { DateTime } from 'luxon';
import { useLocales } from './useLocales';

type ReturnType = {
  onChangeTimezone: (newTz: Timezone) => void;
  currentTimezone: Timezone;
  getTimezonesList: (isDst?: boolean) => Timezone[];
  timestamp: (date: string, format?: string) => string;
};

let isInitialized = false;
const localUtcOffset = DateTime.now().offset / 60;
const utcString = `LOCAL (GMT${localUtcOffset < 0 ? '' : '+'}${localUtcOffset})`;

export const standardTimezone = [
  { label: 'Local', offset: localUtcOffset, description: utcString, isLocal: true },
  { label: 'GMT', offset: 0, description: 'GMT' },
  { label: 'EST', offset: -5, description: 'EST (GMT-5)' },
  { label: 'PST', offset: -8, description: 'PST (GMT-8)' }
] as Timezone[];

export const dayLightStandardTimezone = [
  { label: 'Local', offset: localUtcOffset, description: utcString, isLocal: true },
  { label: 'GMT', offset: 0, description: 'GMT' },
  { label: 'EDT', offset: -4, description: 'EDT (GMT-4)' },
  { label: 'PDT', offset: -7, description: 'PDT (GMT-7)' }
] as Timezone[];

export function useTimezones(): ReturnType {
  const [currentTimezone, setCurrentTimezone] = useRecoilState(withCurrentTimezone);
  const isDst = DateTime.now().isInDST;
  const { currentLang } = useLocales();

  const getTimezonesList = () => {
    return isDst ? dayLightStandardTimezone : standardTimezone;
  };

  const handleChangeTimezoneLabel = (newTimezone: Timezone) => {
    setCurrentTimezone(newTimezone);
  };

  const timestamp = (date: string, format = 'EEE, ff') => {
    const currentOffset = getCurrentTimezone().offset;
    return DateTime.fromISO(date)
      .setLocale(currentLang)
      .plus({ hours: 0 - localUtcOffset + currentOffset })
      .toFormat(format);
  };

  const getCurrentTimezone = (): Timezone => {
    const currentTimezoneList = getTimezonesList();
    const currentTz = currentTimezoneList.find((tz) => tz.label === currentTimezone.label) || currentTimezoneList[0];
    return currentTz;
  };

  useEffect(() => {
    if (!isInitialized) {
      const currentTimezoneList = getTimezonesList();
      if (currentTimezone) {
        const Timezone = currentTimezoneList.find((tz) => tz.label === currentTimezone.label) || currentTimezoneList[0];
        setCurrentTimezone(Timezone);
      } else {
        // Default client timezone
        setCurrentTimezone(currentTimezoneList[0]);
      }
      isInitialized = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimezone]);

  return {
    onChangeTimezone: handleChangeTimezoneLabel,
    currentTimezone,
    getTimezonesList,
    timestamp
  };
}
