import React from 'react';
import { AppRegistration, FilterAlt } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { ContentCollectionSortingType } from '../../../../../API';
import { useLocales } from '../../../../../hooks';

export interface SortingTypeProps {
  sortingType: string;
}

export const testIds = {
  curatedFirst: 'collections.sorting.curated_first',
  queriedFirst: 'collections.sorting.queried_first'
};

export const SortingType = ({ sortingType }: SortingTypeProps): JSX.Element => {
  const { t } = useLocales();
  return (
    <Tooltip arrow placement="top" title={t(`collections.sorting.${sortingType.toLowerCase()}`)}>
      {sortingType === ContentCollectionSortingType.CURATED_FIRST ? (
        <AppRegistration data-testid={testIds.curatedFirst} />
      ) : (
        <FilterAlt data-testid={testIds.queriedFirst} />
      )}
    </Tooltip>
  );
};
