import React from 'react';
import { Avatar, Box, SxProps, Theme } from '@mui/material';
import { UserResponse } from '../../../API';
import { FsUserInfo } from '../../../utils/types/fsUserInfo';

const stringToColor = (value: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < value.length; i += 1) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const getInitials = (user?: UserResponse | FsUserInfo): string => {
  if (!user) return '..';
  return `${user.firstName.slice(0, 1)}${user.lastName.slice(0, 1)}`.toUpperCase();
};

export interface IUserAvatarProps {
  user: UserResponse | FsUserInfo;
  sx?: SxProps<Theme>;
  'data-testid'?: string;
}

const USER_AVATAR_SIZE = 32;

export function UserAvatar({ user, sx, ...props }: IUserAvatarProps): React.ReactElement {
  const userColor = stringToColor(`${user.firstName} ${user.lastName}`);

  return (
    <Avatar
      alt={`${user.firstName} ${user.lastName}`}
      src={user.photoURL}
      imgProps={{ referrerPolicy: 'no-referrer' }}
      sx={{
        bgcolor: !user.photoURL ? userColor : '',
        width: USER_AVATAR_SIZE,
        height: USER_AVATAR_SIZE,
        ...sx
      }}
      data-testid={props['data-testid']}
    >
      {!user.photoURL && (
        <Box
          sx={(theme) => ({
            fontSize: USER_AVATAR_SIZE * 0.5,
            lineHeight: USER_AVATAR_SIZE,
            color: theme.palette.getContrastText(userColor)
          })}
        >
          {getInitials(user)}
        </Box>
      )}
    </Avatar>
  );
}
