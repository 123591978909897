import { useData } from '../../data-layer';
import { useRecoilValue } from 'recoil';
import { useCallback } from 'react';

type ReturnType = {
  isContentIdInActiveCollection: (id: string) => boolean | undefined;
};

export function useSelectedCollectionContent(): ReturnType {
  const {
    collections: {
      state: { withSelectedCollectionContent }
    }
  } = useData();

  const selectedCollectionContent = useRecoilValue(withSelectedCollectionContent);

  const isContentIdInActiveCollection = useCallback(
    (id: string) => selectedCollectionContent?.includes(id),
    [selectedCollectionContent]
  );

  return {
    isContentIdInActiveCollection
  };
}
