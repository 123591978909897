import React, { useState } from 'react';
import { ProfileUiModuleBody } from '../../../../../API';
import { Box, FormControlLabel, Stack, Switch, Typography, Tab, Link } from '@mui/material';
import Modal from '../../../../shared/Modal';
import { themeColors } from '../../../../../theme';
import { makeStyles } from 'tss-react/mui';
import { useLocales, useLokalise, useTheme } from '../../../../../hooks';
import Button from '../../../../shared/Button';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ShadowScroller from '../../../../shared/ShadowScroller';
import { ArrowBackIos } from '@mui/icons-material';
import { TextButton, VixOrangeButton } from '../shared/SharedButtons';
import { getAssetUrl } from '../../../../../utils/assetHelpers';

type ProfilePreviewProps = {
  module: ProfileUiModuleBody;
  open: boolean;
  setOpen: (open: boolean) => unknown;
};

enum ProfilePromoTabs {
  Profile = 'profile',
  MiCuenta = 'mi_cuenta',
  MiSuscripcion = 'mi_suscripcion',
  MisDatos = 'mis_datos'
}

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    height: '100%',
    ['& * strong']: {
      color: themeColors.orange,
      fontWeight: 'inherit'
    }
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  scroller: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100%'
  },
  container: {
    display: 'flex',
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    width: 450,
    color: 'white',
    overflow: 'hidden',
    margin: 'auto',
    minHeight: '100%'
  },
  containerBody: {
    width: '100%',
    padding: '30px 40px'
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    textAlign: 'left',
    li: {
      '&::marker': {
        content: '"✓ "',
        color: themeColors.orange
      }
    }
  },
  box: {
    background: '#22242F',
    padding: 24,
    borderRadius: 16,
    width: '100%'
  },
  subtitle: {
    fontSize: 13,
    color: 'rgba(255, 255, 255, .5)'
  },
  link: {
    color: theme.palette.common.white
  }
}));

export function ProfilePreview({ module, open, setOpen }: ProfilePreviewProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { lt } = useLokalise();

  const [tab, setTab] = useState<ProfilePromoTabs>(ProfilePromoTabs.Profile);

  const [isSVOD, setIsSVOD] = useState(false);
  const [reactivateAccount, setReactivateAccount] = useState(false);

  const onClose = () => setOpen(false);
  const handleTabChange = (event: React.SyntheticEvent, newTab: ProfilePromoTabs) => setTab(newTab);

  const isMisDatos = tab === ProfilePromoTabs.MisDatos;
  const useReactivateToggle = tab === ProfilePromoTabs.Profile || tab === ProfilePromoTabs.MiSuscripcion;
  const backgroundImage = getAssetUrl(module.portraitFillImage);

  const headerRight = (
    <Stack>
      <FormControlLabel
        control={
          <Switch
            color={formControlColor}
            size="small"
            checked={isMisDatos || isSVOD}
            disabled={isMisDatos}
            onChange={({ target: { checked } }) => setIsSVOD(checked)}
          />
        }
        label={t('layouts.profile.svod_user')}
      />
      <FormControlLabel
        control={
          <Switch
            color={formControlColor}
            size="small"
            checked={!isSVOD && useReactivateToggle && reactivateAccount}
            disabled={isSVOD || !useReactivateToggle}
            onChange={({ target: { checked } }) => setReactivateAccount(checked)}
          />
        }
        label={t('layouts.profile.reactivate_account')}
      />
    </Stack>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      headerLeft={t('layouts.profile.preview')}
      headerRight={headerRight}
      footerLeft={
        <Button color="grey" onClick={onClose} sx={{ width: 140 }}>
          {t('general.close')}
        </Button>
      }
    >
      <Box className={classes.root}>
        <TabContext value={tab}>
          <TabList
            indicatorColor={formControlColor}
            textColor={formControlColor}
            onChange={handleTabChange}
            centered
            className={classes.tabs}
          >
            {Object.values(ProfilePromoTabs).map((tab) => (
              <Tab key={tab} label={t(`layouts.profile.headers.${tab}`)} value={tab} />
            ))}
          </TabList>
          <ShadowScroller>
            <div className={classes.scroller}>
              <TabPanel value={ProfilePromoTabs.Profile}>
                <Box className={classes.container} sx={{ background: `url('${backgroundImage}')` }}>
                  <Box className={classes.containerBody}>
                    <Stack gap="48px">
                      <Stack direction="column" gap="16px">
                        <Typography variant="h4" fontWeight="bold">
                          <span dangerouslySetInnerHTML={{ __html: lt(module.header) }} />
                        </Typography>
                        <Typography variant="body1" fontWeight="bold">
                          <span dangerouslySetInnerHTML={{ __html: lt(module.subheader) }} />
                        </Typography>
                      </Stack>
                      <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                        <span
                          className={classes.listContainer}
                          dangerouslySetInnerHTML={{ __html: lt(module.benefit) }}
                        />
                      </Typography>
                      <Stack direction="column" gap="20px">
                        <VixOrangeButton>
                          {lt(reactivateAccount ? module.reactivateCta : module.subscribeCta)}
                        </VixOrangeButton>
                        <TextButton>{lt(isSVOD ? module.signInCta : module.signUpCta)}</TextButton>
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              </TabPanel>

              <TabPanel value={ProfilePromoTabs.MiCuenta}>
                <Box className={classes.container} sx={{ background: `url('${backgroundImage}')` }}>
                  <Stack gap="48px" padding="16px" width="100%">
                    <ArrowBackIos />
                    <Typography variant="h4">Mi cuenta</Typography>
                    <Stack gap="16px">
                      <Stack className={classes.box} gap="24px">
                        <Typography variant="h6">Mi suscripción</Typography>
                        <Box>
                          <Typography className={classes.subtitle}>Mi plan</Typography>
                          <Typography variant="h6">{isSVOD ? 'Vix Premium' : 'Vix Gratis'}</Typography>
                        </Box>
                        <Box>
                          <Typography className={classes.subtitle}>Estado</Typography>
                          <Stack gap="12px">
                            <Typography variant="h6">
                              <span dangerouslySetInnerHTML={{ __html: lt(module.bundleReminderPrimaryOpt) }} />
                            </Typography>
                            <Typography className={classes.subtitle}>
                              <span dangerouslySetInnerHTML={{ __html: lt(module.bundleReminderSecondaryOpt) }} />
                            </Typography>
                            <Link href="#" className={classes.link}>
                              <Typography className={classes.tabs}>
                                <span dangerouslySetInnerHTML={{ __html: lt(module.bundleCta) }} />
                              </Typography>
                            </Link>
                          </Stack>
                        </Box>
                        {!isSVOD && (
                          <Stack gap="12px">
                            <Typography variant="body1">
                              <span dangerouslySetInnerHTML={{ __html: lt(module.header) }} />
                            </Typography>
                            <VixOrangeButton>{lt(module.subscribeCta)}</VixOrangeButton>
                          </Stack>
                        )}
                      </Stack>

                      <Stack className={classes.box} gap="24px">
                        <Typography variant="h6">Mi datos</Typography>
                        <Box>
                          <Typography className={classes.subtitle}>Correo</Typography>
                          <Typography variant="h6">sample@example.com</Typography>
                        </Box>
                        <Stack>
                          <Typography className={classes.subtitle}>Administra tu cuenta</Typography>
                          <Typography>
                            <span dangerouslySetInnerHTML={{ __html: lt(module.adminMiCuenta) }} />
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>

                    <Stack gap="24px" alignItems="center" marginBottom="24px">
                      <Typography variant="body1">Eliminar mi cuenta</Typography>
                      {!isSVOD && <Typography variant="body1">Restaurar suscripción</Typography>}
                    </Stack>
                  </Stack>
                </Box>
              </TabPanel>

              <TabPanel value={ProfilePromoTabs.MiSuscripcion}>
                <Box className={classes.container} sx={{ background: `url('${backgroundImage}')` }}>
                  <Stack gap="48px" padding="32px 64px" width="100%">
                    <Typography variant="h5">Mi suscripción</Typography>

                    <Stack>
                      <Typography className={classes.subtitle}>Mi plan</Typography>
                      <Typography variant="h6" lineHeight="initial">
                        {isSVOD ? 'Vix Premium' : 'Vix Gratis'}
                      </Typography>
                    </Stack>

                    {!isSVOD && (
                      <Stack gap="8px">
                        <Typography variant="h5" lineHeight="initial">
                          <span dangerouslySetInnerHTML={{ __html: lt(module.header) }} />
                        </Typography>
                        <VixOrangeButton>
                          {lt(reactivateAccount ? module.reactivateCta : module.subscribeCta)}
                        </VixOrangeButton>
                      </Stack>
                    )}

                    {isSVOD && (
                      <Stack>
                        <Typography className={classes.subtitle}>Administra tu cuenta</Typography>
                        <Typography variant="h6" lineHeight="initial">
                          <span dangerouslySetInnerHTML={{ __html: lt(module.adminMiCuenta) }} />
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                </Box>
              </TabPanel>

              <TabPanel value={ProfilePromoTabs.MisDatos}>
                <Box className={classes.container} sx={{ background: `url('${backgroundImage}')` }}>
                  <Stack gap="48px" padding="32px 64px" width="100%">
                    <Typography variant="h5">Mi suscripción</Typography>

                    <Stack>
                      <Typography className={classes.subtitle}>Correo</Typography>
                      <Typography variant="h6" lineHeight="initial">
                        sample@example.com
                      </Typography>
                    </Stack>

                    <Stack>
                      <Typography className={classes.subtitle}>Administra tu cuenta</Typography>
                      <Typography variant="h6" lineHeight="initial">
                        <span dangerouslySetInnerHTML={{ __html: lt(module.adminMiCuenta) }} />
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              </TabPanel>
            </div>
          </ShadowScroller>
        </TabContext>
      </Box>
    </Modal>
  );
}
