import {
  DocumentLocale,
  LiveNewsUiModuleBody,
  LiveNewsUiModuleBodyModuleTypeEnum,
  LiveNewsUIModuleContentTreatment,
  VllChannelType
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';
import { ALL, IncludeAll, TrinaryBoolean } from '../../../utils/types/genericTypes';
import { localAtom } from '../../localStorageState';

export const blankLiveNewsModule: NewModuleFunction<LiveNewsUiModuleBody> = (module) => ({
  title: {
    [DocumentLocale.ES]: ''
  },
  moduleType: LiveNewsUiModuleBodyModuleTypeEnum.LIVE_NEWS,
  contentTreatment: LiveNewsUIModuleContentTreatment.LANDSCAPE,
  liveNewsContents: [],
  moduleId: generateId(),
  ...module
});

export const withChannelTypeFilter = localAtom<IncludeAll<VllChannelType>>({
  key: 'uiModules.channelTypeFilter',
  default: ALL
});

export const withChannelLiveFilter = localAtom<TrinaryBoolean>({
  key: 'uiModules.channelLiveFilter',
  default: ALL
});

export const withChannelPremiumFilter = localAtom<TrinaryBoolean>({
  key: 'uiModules.channelPremiumFilter',
  default: ALL
});
