/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiPaginatedResponseOfVllCategoriesBundleResponse } from '../model';
// @ts-ignore
import { ApiResponseOfVllCategoriesBundleResponse } from '../model';
// @ts-ignore
import { SortDirection } from '../model';
// @ts-ignore
import { VersionedDocumentStatus } from '../model';
// @ts-ignore
import { VllCategoriesBundleBody } from '../model';
/**
 * CategoryBundlesApi - axios parameter creator
 * @export
 */
export const CategoryBundlesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the contents of the latest version from a specific **category bundle** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestRevision: async (entityId: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getLatestRevision', 'entityId', entityId)
            const localVarPath = `/api/v1/vll/categoryBundles/{entityId}/revisions/latest`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all the **category bundles** available per region in the **Vix CMS** application.
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated: async (limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/vll/categoryBundles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (authorId !== undefined) {
                localVarQueryParameter['authorId'] = authorId;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the contents of a previous version of a specific **category bundle** from the **Vix CMS** application by providing an `entityId` and a `revisionId`.
         * @param {string} entityId 
         * @param {number} revisionId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevision: async (entityId: string, revisionId: number, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getRevision', 'entityId', entityId)
            // verify required parameter 'revisionId' is not null or undefined
            assertParamExists('getRevision', 'revisionId', revisionId)
            const localVarPath = `/api/v1/vll/categoryBundles/{entityId}/revisions/{revisionId}`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"revisionId"}}`, encodeURIComponent(String(revisionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all the previous versions of a specific **category bundle** from the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevisions: async (entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getRevisions', 'entityId', entityId)
            const localVarPath = `/api/v1/vll/categoryBundles/{entityId}/revisions`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (authorId !== undefined) {
                localVarQueryParameter['authorId'] = authorId;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Changes the `status` of a **category bundle** from `DRAFT` to `PUBLISHED` in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publish: async (entityId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('publish', 'entityId', entityId)
            const localVarPath = `/api/v1/vll/categoryBundles/{entityId}/publish`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Permanently deletes a **category bundle** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (entityId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('remove', 'entityId', entityId)
            const localVarPath = `/api/v1/vll/categoryBundles/{entityId}`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the latest version of a specific **category bundle** from the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {VllCategoriesBundleBody} vllCategoriesBundleBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (entityId: string, vllCategoriesBundleBody: VllCategoriesBundleBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('update', 'entityId', entityId)
            // verify required parameter 'vllCategoriesBundleBody' is not null or undefined
            assertParamExists('update', 'vllCategoriesBundleBody', vllCategoriesBundleBody)
            const localVarPath = `/api/v1/vll/categoryBundles/{entityId}/revisions/latest`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vllCategoriesBundleBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryBundlesApi - functional programming interface
 * @export
 */
export const CategoryBundlesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoryBundlesApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the contents of the latest version from a specific **category bundle** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestRevision(entityId: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfVllCategoriesBundleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestRevision(entityId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all the **category bundles** available per region in the **Vix CMS** application.
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfVllCategoriesBundleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginated(limit, page, sortBy, sortDirection, status, authorId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the contents of a previous version of a specific **category bundle** from the **Vix CMS** application by providing an `entityId` and a `revisionId`.
         * @param {string} entityId 
         * @param {number} revisionId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRevision(entityId: string, revisionId: number, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfVllCategoriesBundleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRevision(entityId, revisionId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all the previous versions of a specific **category bundle** from the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRevisions(entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfVllCategoriesBundleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRevisions(entityId, limit, page, sortBy, sortDirection, status, authorId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Changes the `status` of a **category bundle** from `DRAFT` to `PUBLISHED` in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publish(entityId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfVllCategoriesBundleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publish(entityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Permanently deletes a **category bundle** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(entityId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(entityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the latest version of a specific **category bundle** from the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {VllCategoriesBundleBody} vllCategoriesBundleBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(entityId: string, vllCategoriesBundleBody: VllCategoriesBundleBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfVllCategoriesBundleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(entityId, vllCategoriesBundleBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoryBundlesApi - factory interface
 * @export
 */
export const CategoryBundlesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoryBundlesApiFp(configuration)
    return {
        /**
         * Retrieves the contents of the latest version from a specific **category bundle** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestRevision(entityId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfVllCategoriesBundleResponse> {
            return localVarFp.getLatestRevision(entityId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all the **category bundles** available per region in the **Vix CMS** application.
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfVllCategoriesBundleResponse> {
            return localVarFp.getPaginated(limit, page, sortBy, sortDirection, status, authorId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the contents of a previous version of a specific **category bundle** from the **Vix CMS** application by providing an `entityId` and a `revisionId`.
         * @param {string} entityId 
         * @param {number} revisionId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevision(entityId: string, revisionId: number, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfVllCategoriesBundleResponse> {
            return localVarFp.getRevision(entityId, revisionId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all the previous versions of a specific **category bundle** from the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevisions(entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfVllCategoriesBundleResponse> {
            return localVarFp.getRevisions(entityId, limit, page, sortBy, sortDirection, status, authorId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * Changes the `status` of a **category bundle** from `DRAFT` to `PUBLISHED` in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publish(entityId: string, options?: any): AxiosPromise<ApiResponseOfVllCategoriesBundleResponse> {
            return localVarFp.publish(entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * Permanently deletes a **category bundle** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(entityId: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the latest version of a specific **category bundle** from the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {VllCategoriesBundleBody} vllCategoriesBundleBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(entityId: string, vllCategoriesBundleBody: VllCategoriesBundleBody, options?: any): AxiosPromise<ApiResponseOfVllCategoriesBundleResponse> {
            return localVarFp.update(entityId, vllCategoriesBundleBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoryBundlesApi - interface
 * @export
 * @interface CategoryBundlesApi
 */
export interface CategoryBundlesApiInterface {
    /**
     * Retrieves the contents of the latest version from a specific **category bundle** in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryBundlesApiInterface
     */
    getLatestRevision(entityId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfVllCategoriesBundleResponse>;

    /**
     * Retrieves all the **category bundles** available per region in the **Vix CMS** application.
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {VersionedDocumentStatus} [status] 
     * @param {string} [authorId] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryBundlesApiInterface
     */
    getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfVllCategoriesBundleResponse>;

    /**
     * Retrieves the contents of a previous version of a specific **category bundle** from the **Vix CMS** application by providing an `entityId` and a `revisionId`.
     * @param {string} entityId 
     * @param {number} revisionId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryBundlesApiInterface
     */
    getRevision(entityId: string, revisionId: number, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfVllCategoriesBundleResponse>;

    /**
     * Retrieves all the previous versions of a specific **category bundle** from the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {VersionedDocumentStatus} [status] 
     * @param {string} [authorId] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryBundlesApiInterface
     */
    getRevisions(entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfVllCategoriesBundleResponse>;

    /**
     * Changes the `status` of a **category bundle** from `DRAFT` to `PUBLISHED` in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryBundlesApiInterface
     */
    publish(entityId: string, options?: any): AxiosPromise<ApiResponseOfVllCategoriesBundleResponse>;

    /**
     * Permanently deletes a **category bundle** in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryBundlesApiInterface
     */
    remove(entityId: string, options?: any): AxiosPromise<void>;

    /**
     * Updates the latest version of a specific **category bundle** from the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {VllCategoriesBundleBody} vllCategoriesBundleBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryBundlesApiInterface
     */
    update(entityId: string, vllCategoriesBundleBody: VllCategoriesBundleBody, options?: any): AxiosPromise<ApiResponseOfVllCategoriesBundleResponse>;

}

/**
 * CategoryBundlesApi - object-oriented interface
 * @export
 * @class CategoryBundlesApi
 * @extends {BaseAPI}
 */
export class CategoryBundlesApi extends BaseAPI implements CategoryBundlesApiInterface {
    /**
     * Retrieves the contents of the latest version from a specific **category bundle** in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryBundlesApi
     */
    public getLatestRevision(entityId: string, includeDeleted?: boolean, options?: any) {
        return CategoryBundlesApiFp(this.configuration).getLatestRevision(entityId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all the **category bundles** available per region in the **Vix CMS** application.
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {VersionedDocumentStatus} [status] 
     * @param {string} [authorId] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryBundlesApi
     */
    public getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options?: any) {
        return CategoryBundlesApiFp(this.configuration).getPaginated(limit, page, sortBy, sortDirection, status, authorId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the contents of a previous version of a specific **category bundle** from the **Vix CMS** application by providing an `entityId` and a `revisionId`.
     * @param {string} entityId 
     * @param {number} revisionId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryBundlesApi
     */
    public getRevision(entityId: string, revisionId: number, includeDeleted?: boolean, options?: any) {
        return CategoryBundlesApiFp(this.configuration).getRevision(entityId, revisionId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all the previous versions of a specific **category bundle** from the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {VersionedDocumentStatus} [status] 
     * @param {string} [authorId] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryBundlesApi
     */
    public getRevisions(entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, includeDeleted?: boolean, options?: any) {
        return CategoryBundlesApiFp(this.configuration).getRevisions(entityId, limit, page, sortBy, sortDirection, status, authorId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Changes the `status` of a **category bundle** from `DRAFT` to `PUBLISHED` in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryBundlesApi
     */
    public publish(entityId: string, options?: any) {
        return CategoryBundlesApiFp(this.configuration).publish(entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Permanently deletes a **category bundle** in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryBundlesApi
     */
    public remove(entityId: string, options?: any) {
        return CategoryBundlesApiFp(this.configuration).remove(entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the latest version of a specific **category bundle** from the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {VllCategoriesBundleBody} vllCategoriesBundleBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryBundlesApi
     */
    public update(entityId: string, vllCategoriesBundleBody: VllCategoriesBundleBody, options?: any) {
        return CategoryBundlesApiFp(this.configuration).update(entityId, vllCategoriesBundleBody, options).then((request) => request(this.axios, this.basePath));
    }
}
