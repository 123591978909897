/* eslint-disable @typescript-eslint/no-explicit-any */
import { omit } from 'lodash-es';
import React from 'react';
import { ItemInterface, ReactSortable, ReactSortableProps, Sortable as SortableType } from 'react-sortablejs';
import { isEqual } from 'lodash-es';

// react-sortablejs version 6.1.1 does not support React 18
// It will raise an error about Children not being defined in the props
// However, react-sortablejs version 6.1.4 currently has a bug that prevents it from being built with typescript
// https://github.com/SortableJS/react-sortablejs/issues/244
// This is a temporary workaround to get react-sortablejs to build without complaining about missing props
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ReactSortableWithChildren: any = ReactSortable;

export type { SortableEvent } from 'react-sortablejs';

export const SortableGroups = {
  channelCollection: 'channelCollection',
  collections: 'collections',
  presets: 'presets',
  uiModules: 'uiModules'
};

export interface ISortableProps extends ReactSortableProps<any> {
  setList: (newState: any[]) => void;
  children?: React.ReactNode;
  isStringList?: boolean;
}

export const Sortable = ({
  list,
  setList,
  children,
  disabled,
  isStringList = false,
  ...props
}: ISortableProps): JSX.Element => {
  const handleSetList = (newState: ItemInterface[], sortable: SortableType | null) => {
    if (!sortable || disabled) return;
    const newList = isStringList ? newState.map((x) => x.value) : newState.map((x) => omit(x, 'chosen', 'selected'));
    if (!isEqual(newList, list)) {
      setList(newList);
    }
  };

  const listMap = (list: any[]) => {
    if (isStringList) {
      return list.map((x) => ({ value: x, chosen: true }));
    }
    return list.map((x) => ({ ...x, chosen: true }));
  };

  return (
    <ReactSortableWithChildren list={listMap(list)} setList={handleSetList} {...props}>
      {children}
    </ReactSortableWithChildren>
  );
};
