import { AssetBody, AssetResponse } from '../../../API';
import { useAssetsAPI } from '../../../hooks/API/Assets/useAssetsAPI';
import { CreateDataManager, DataManagerReturnType } from '../../DataManager';
import { AssetsHook, AssetsHookReturnType } from './AssetsHook';
import { AssetsState, AssetStateReturnType } from './AssetsState';

export type AssetsDataManagerType = {
  state: AssetStateReturnType;
  hook: AssetsHookReturnType;
};

export interface AssetsDataManagerReturnType extends DataManagerReturnType<AssetResponse, AssetBody> {
  state: AssetsDataManagerType['state'];
  hook: () => AssetsDataManagerType['hook'];
}

export const assetSortFunction = (a: AssetResponse, b: AssetResponse): number => {
  if (!a.createdDate || !b.createdDate) return 0;
  if (a.createdDate < b.createdDate) {
    return -1;
  }
  if (a.createdDate > b.createdDate) {
    return 1;
  }
  return 0;
};

export const AssetsDataManager = CreateDataManager<AssetResponse, AssetBody, AssetsDataManagerReturnType>({
  name: 'assets',
  idField: 'id',
  stateInitializer: AssetsState,
  hookInitializer: AssetsHook,
  useApiHook: useAssetsAPI,
  paginationLimit: 500,
  bucketIdToQueryParams(id) {
    return [id];
  },
  getRecordBucketIds(record) {
    return [(record as AssetResponse).assetType];
  },
  sortFunctionInitializer: () => assetSortFunction,
  toString: (asset) => asset.assetName
});
