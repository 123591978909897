import React, { useEffect, useMemo } from 'react';
import { Avatar, Chip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../../hooks';
import { useRecoilValue } from 'recoil';
import { getAssetUrl } from '../../../utils/assetHelpers';
import { LEAGUE_ID_ALL, LEAGUE_ID_PARAM } from '../../../utils/consts/uiModules';
import { withPreviewLeagueId } from '../../../state/Layouts';
import { useData } from '../../../data-layer';

export interface ILeagueBadgeProps {
  className?: string;
  'data-testid'?: string;
  leagueId: string | undefined;
  useTournamentId?: boolean;
}

const useStyles = makeStyles()(() => ({
  badge: {
    maxWidth: 200
  }
}));

const assetSize = 20;

export const testIds = {
  root: 'league-badge.root'
};

export function LeagueBadge({
  className,
  leagueId,
  'data-testid': dataTestId,
  useTournamentId = false
}: ILeagueBadgeProps): React.ReactElement {
  const { classes } = useStyles();
  const { t, localize } = useLocales();
  const previewLeagueId = useRecoilValue(withPreviewLeagueId);
  const hasDynamicLeagueId = leagueId === LEAGUE_ID_PARAM;

  const {
    tournaments: {
      hook: { queueIdToFetch, queueTournamentIdToFetch },
      state: { withRecordById, withTournamentByTournamentId }
    }
  } = useData();

  const tournamentById = useRecoilValue(withRecordById(leagueId));
  const tournamentByTournamentId = useRecoilValue(
    withTournamentByTournamentId(hasDynamicLeagueId ? previewLeagueId : leagueId)
  );

  const tournament = useMemo(
    () => (useTournamentId || hasDynamicLeagueId ? tournamentByTournamentId : tournamentById),
    [tournamentById, tournamentByTournamentId, hasDynamicLeagueId]
  );

  useEffect(() => {
    if (!tournament && leagueId && leagueId !== LEAGUE_ID_PARAM) {
      if (useTournamentId) {
        queueTournamentIdToFetch(leagueId);
      } else {
        queueIdToFetch(leagueId);
      }
    }
  }, [leagueId]);

  const getProps = () => {
    if (!leagueId || (hasDynamicLeagueId && previewLeagueId === LEAGUE_ID_ALL))
      return {
        label: t('sports.all_leagues')
      };
    if (!tournament)
      return {
        label: leagueId === LEAGUE_ID_PARAM ? t('layouts.league_id_param') : `[${leagueId}]`
      };
    return {
      label: localize(tournament.name),
      avatar: tournament.logoImage ? (
        <Avatar src={`${getAssetUrl(tournament.logoImage)}?tr=h-${assetSize * 2}`} />
      ) : undefined
    };
  };

  return (
    <Chip
      className={`${classes.badge} ${className}`}
      size="small"
      {...getProps()}
      data-testid={dataTestId ?? testIds.root}
    />
  );
}
