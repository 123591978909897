import {
  GetAllContentCollectionQuery,
  SortDirection,
  CollectionLayoutsResponse,
  SmartQueryOperatorSymbol,
  ContentCollectionsApi,
  ContentCollectionResponse,
  ContentCollectionBody,
  SmartQueryParametersResponse,
  SmartQueryBody
} from '../../../API';
import { Paginated, useLocales } from '../../../hooks';
import { useSmartQueryAPI } from '../../../hooks/API/SmartQuery/useSmartQueryAPI';
import { DEFAULT_PAGINATION_LIMIT } from '../../../utils/appDefaults';
import {
  DataManagerHook,
  DataManagerHookReturnType,
  IDataManagerHookProps,
  DataManagerHelpers
} from '../../DataManager';
import { saveAsFile } from '../../../utils/saveAsFile';

export interface CollectionsHookReturnType
  extends DataManagerHookReturnType<ContentCollectionResponse, ContentCollectionBody> {
  getPaginated: (params: GetAllContentCollectionQuery) => Promise<Paginated<ContentCollectionResponse> | undefined>;
  getRevisions: (
    entityId: string,
    limit?: number,
    page?: number
  ) => Promise<Paginated<ContentCollectionResponse> | undefined>;
  removeContentFromCollection: (collectionId: string, contentId: string) => Promise<boolean>;
  removeContentFromCollections: (contentId: string) => Promise<boolean>;
  getSmartQueryParameters: (contentTypes?: string[]) => Promise<SmartQueryParametersResponse | undefined>;
  getCollectionLayoutsById: (entity: string) => Promise<CollectionLayoutsResponse>;
  getGenres: () => Promise<string[]>;
  exportContentsCsv: (entity: string) => Promise<void>;
  allLeafNodesValuesAreDefined: (collectionQuery: SmartQueryBody | undefined) => boolean;
}

export function CollectionsHook(
  params: IDataManagerHookProps<ContentCollectionResponse, ContentCollectionBody>
): CollectionsHookReturnType {
  const dataManagerHook = DataManagerHook<ContentCollectionResponse, ContentCollectionBody>(params);

  const api = params.useApiHook() as ContentCollectionsApi;
  const smartQueryApi = useSmartQueryAPI();

  const { t } = useLocales();
  const { handleSuccess, handleApiError } = DataManagerHelpers({ name: params.name, toString });

  const getPaginated = async ({
    limit = DEFAULT_PAGINATION_LIMIT,
    page = 1,
    sortBy = 'createdDate',
    sortDirection = SortDirection.DESC,
    ...params
  }: GetAllContentCollectionQuery) => {
    if (!dataManagerHook.fetchPaginated) return;
    return await dataManagerHook.fetchPaginated(limit, page, [
      sortBy,
      sortDirection,
      params.status,
      params.authorId,
      params.search,
      params.genreFilter,
      params.contentId,
      params.usedFilter,
      params.forSimilars,
      params.entitlements,
      params.includeCardImage,
      params.isLatestPublishedRevision,
      params.includeDeleted,
      params.hasAutoRotate,
      params.hasSmartQuery,
      params.collectionTypes,
      params.hasAssetsTheseTypes
    ]);
  };

  const getRevisions = async (
    entityId: string,
    limit?: number,
    page?: number
  ): Promise<Paginated<ContentCollectionResponse> | undefined> => {
    const response = await api.getRevisions(entityId, limit, page);
    return response.data.body as Paginated<ContentCollectionResponse>;
  };

  const removeContentFromCollection = async (collectionId: string, contentId: string) => {
    try {
      await api.removeContentFromCollection(collectionId, contentId);
      handleSuccess('delete_content', contentId, { n: 1 });
      return true;
    } catch (err) {
      console.error(err);
      handleApiError(err, 'delete_content', [contentId, err as string]);
    }
    return false;
  };

  const removeContentFromCollections = async (contentId: string) => {
    try {
      const response = await api.removeContentFromCollections(contentId);
      handleSuccess('delete_content', contentId, { n: String(response.data.body.length) });
      return true;
    } catch (err) {
      console.error(err);
      handleApiError(err, 'delete_content', contentId);
    }
    return false;
  };

  const getSmartQueryParameters = async (contentTypes?: string[]) => {
    try {
      const { data } = await smartQueryApi.getParameters(contentTypes);
      return data.body;
    } catch (err) {
      console.error(err);
      handleApiError(
        err,
        'get_smart_query_parameters',
        contentTypes && [`${t('collections.collection_types')}:`, ...contentTypes]
      );
    }
  };

  const getCollectionLayoutsById = async (entityId: string): Promise<CollectionLayoutsResponse> => {
    const { data } = await api.getCollectionLayouts(entityId);
    return data.body;
  };

  const getGenres = async (): Promise<string[]> => {
    try {
      const { data } = await api.getGenres();
      return data.body;
    } catch (err) {
      console.error(err);
      handleApiError(err, t('errors.collections.get_genres'));
      return [];
    }
  };

  const exportContentsCsv = async (entityId: string): Promise<void> => {
    try {
      const response = await api.exportContentsCsv(entityId);
      saveAsFile(response);
      handleSuccess(t('export_content_csv'));
    } catch (err) {
      console.error(err);
      handleApiError(err, t('errors.collections.export_content_csv'));
    }
  };

  const allLeafNodesValuesAreDefined = (collectionQuery: SmartQueryBody | undefined): boolean => {
    if (!collectionQuery) return true;
    return collectionQuery?.conditions?.every(
      ({ operator, value }) =>
        operator === SmartQueryOperatorSymbol.AND ||
        operator === SmartQueryOperatorSymbol.OR ||
        !(['', null, undefined] as unknown[]).includes(value)
    );
  };

  return {
    ...dataManagerHook,
    getPaginated,
    getRevisions,
    removeContentFromCollection,
    removeContentFromCollections,
    getSmartQueryParameters,
    getCollectionLayoutsById,
    getGenres,
    exportContentsCsv,
    allLeafNodesValuesAreDefined
  };
}
