import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { CopyUiModuleBody } from '../../../../../API';
import { useLocales } from '../../../../../hooks';
import Button from '../../../../shared/Button';
import Modal from '../../../../shared/Modal';
import { useLokalise } from '../../../../../hooks/General/useLokalise';

export interface ICopyPreviewProps {
  copyModule: CopyUiModuleBody;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export const testIds = {
  copyContainer: 'copy-preview.copy-container',
  loaderContainer: 'copy-preview.loader-container'
};

export function CopyPreview({ copyModule, isOpen, setIsOpen }: ICopyPreviewProps): React.ReactElement {
  const { t } = useLocales();
  const { lt } = useLokalise();
  const translatedText = lt(copyModule.translationKey);
  const invalidKeyError = !translatedText;

  const onModalClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onModalClose}
      headerLeft={t('layouts.copy_module_preview')}
      footerLeft={
        <Button color="grey" onClick={onModalClose} sx={{ width: 140 }}>
          {t('general.close')}
        </Button>
      }
    >
      <>
        {(translatedText || invalidKeyError) && (
          <Box
            sx={{ display: 'flex', justifyContent: 'space-around', height: '100%' }}
            data-testid={testIds.copyContainer}
          >
            <Box>
              <Box
                sx={{
                  width: 600,
                  backgroundColor: 'black',
                  position: 'relative',
                  color: 'white',
                  minHeight: '100%',
                  p: 6,
                  '& p': {
                    my: 4
                  }
                }}
              >
                {!invalidKeyError && <div dangerouslySetInnerHTML={{ __html: translatedText || '' }} />}
                {invalidKeyError && (
                  <div>{t('errors.layouts.invalid_lokalise_key', { key: copyModule.translationKey })}</div>
                )}
              </Box>
            </Box>
          </Box>
        )}
        {!(translatedText || invalidKeyError) && (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-around'
            }}
            data-testid={testIds.loaderContainer}
          >
            <CircularProgress />
          </Box>
        )}
      </>
    </Modal>
  );
}
