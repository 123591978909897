import React, { forwardRef, useImperativeHandle } from 'react';
import { useLocales, useNotifications } from '../../../../../hooks';
import { InputLabel, MenuItem, Typography } from '@mui/material';
import FormControl from '../../../../shared/FormControl';
import { DocumentLocale, PageListUiModuleBody, UiModulePageListTreatment } from '../../../../../API';
import { Sortable } from '../../../../shared/Sortable';
import PageListContentItem from './PageListContentItem';
import Button from '../../../../shared/Button';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { FormBody, ItemContainer, StyledSelect } from '../../styles';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

export const testIds = {
  formBody: 'page-list-form.form-body',
  addPageListItem: 'page-list-form.add-page-list-item',
  pageListItemContainer: 'page-list-form.page-list-item-container'
};

const PageListForm = forwardRef<UIModuleTypeForm<PageListUiModuleBody>>((_, ref) => {
  const { control } = useFormContext<PageListUiModuleBody>();
  const { append, fields, remove, replace } = useFieldArray({ control, name: 'pages' });
  const { t } = useLocales();
  const { notifyError } = useNotifications();

  useImperativeHandle(ref, () => ({
    isValid(uiModule) {
      let valid = true;
      if (valid && checkHasNoPages(uiModule)) {
        notifyError(t('errors.layouts.no_empty_page_list_module'));
        valid = false;
      }
      if (valid && checkHasEmptyUrlPaths(uiModule)) {
        notifyError(t('errors.layouts.page_list_module_invalid_paths'));
        valid = false;
      }
      return valid;
    }
  }));

  const checkHasNoPages = (uiModule: PageListUiModuleBody) => {
    if (!uiModule) return false;
    return !uiModule.pages.length;
  };

  const checkHasEmptyUrlPaths = (uiModule: PageListUiModuleBody) => {
    if (!uiModule) return false;
    for (const page of uiModule.pages) {
      if (!page.urlPath) {
        return true;
      }
    }
    return false;
  };

  const addNewContentItem = () => {
    append({ name: { [DocumentLocale.ES]: '' }, urlPath: '' });
  };

  const onContentItemDelete = (index: number) => {
    remove(index);
  };

  return (
    <FormBody>
      <div data-testid={testIds.formBody}>
        <FormControl>
          <InputLabel id="pageListTreatmentSelector">{t('layouts.page_list.fields.treatment')}</InputLabel>
          <Controller
            control={control}
            name="pageListTreatment"
            render={({ field: { value, onChange } }) => (
              <StyledSelect
                label={t('layouts.page_list.fields.treatment')}
                labelId="pageListTreatmentSelector"
                value={value}
                onChange={onChange}
              >
                {Object.values(UiModulePageListTreatment).map((value) => (
                  <MenuItem key={value} value={value} data-treatment-id={value}>
                    {value}
                  </MenuItem>
                ))}
              </StyledSelect>
            )}
          />
        </FormControl>
      </div>

      <div>
        <Typography variant="h6">{t('layouts.page_list.fields.items')}</Typography>
        <Sortable
          list={fields}
          setList={replace}
          animation={100}
          ghostClass="sortableGhost"
          dragClass="sortableDragDefault"
        >
          {fields.map((v, i) => (
            <ItemContainer key={v.id} data-testid={testIds.pageListItemContainer}>
              <PageListContentItem index={i} onDelete={() => onContentItemDelete(i)} />
            </ItemContainer>
          ))}
        </Sortable>
      </div>
      <div>
        <Button color="grey" onClick={addNewContentItem} data-testid={testIds.addPageListItem}>
          {t('layouts.page_list.actions.add_item')}
        </Button>
      </div>
    </FormBody>
  );
});

PageListForm.displayName = 'PageListForm';

export default PageListForm;
