import { useSetRecoilState } from 'recoil';
import { ChannelsApi, VllChannelResponse } from '../../../../API';
import { useLocales } from '../../../../hooks';
import {
  CachedRecord,
  DataManagerHelpers,
  DataManagerHook,
  DataManagerHookReturnType,
  IDataManagerHookProps,
  useGetRecoilState
} from '../../../DataManager';
import { saveAsFile } from '../../../../utils/saveAsFile';

export interface ChannelsHookReturnType extends DataManagerHookReturnType<VllChannelResponse, VllChannelResponse> {
  addCreatedChannelToBuckets: (record: VllChannelResponse) => Promise<void>;
  exportChannelsToCSV: (maxResults?: number | undefined) => Promise<void>;
}

export function ChannelsHook(
  params: IDataManagerHookProps<VllChannelResponse, VllChannelResponse>
): ChannelsHookReturnType {
  const dataManagerHook = DataManagerHook<VllChannelResponse, VllChannelResponse>(params);
  const api = params.useApiHook() as ChannelsApi;
  const { handleApiError } = DataManagerHelpers({ name: params.name, toString });
  const { t } = useLocales();

  const getBuckets = useGetRecoilState(params.state.withRecordBucketsById);
  const setBuckets = useSetRecoilState(params.state.withRecordBucketsById);

  // Adds newly linked channel to all available buckets
  const addCreatedChannelToBuckets = async (record: VllChannelResponse) => {
    const buckets = { ...(await getBuckets()) };
    for (const bucketId in buckets) {
      if (buckets[bucketId] && buckets[bucketId].object) {
        buckets[bucketId] = {
          lastUpdated: buckets[bucketId].lastUpdated,
          object: await dataManagerHook.addRecordToIdsList(record, buckets[bucketId] as CachedRecord<string[]>)
        };
      }
    }
    setBuckets(buckets);
  };

  const exportChannelsToCSV = async (maxResults: number | undefined) => {
    try {
      const response = await api.exportCsv(maxResults);
      saveAsFile(response);
    } catch (err) {
      handleApiError(err, 'export_channels_to_csv', `${t('query_builder.max_results')}: ${maxResults}`);
    }
  };

  return {
    ...dataManagerHook,
    addCreatedChannelToBuckets,
    exportChannelsToCSV
  };
}
