/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiResponseOfNotificationSubscription } from '../model';
// @ts-ignore
import { ApiResponseOfNotificationSubscriptionResponse } from '../model';
// @ts-ignore
import { NotificationSubscriptionResponse } from '../model';
/**
 * NotificationSubscriptionApi - axios parameter creator
 * @export
 */
export const NotificationSubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (contentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('create', 'contentId', contentId)
            const localVarPath = `/api/v1/notificationSubscription/{contentId}`
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the info whether the notification is enabled for given id.
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (contentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('getById', 'contentId', contentId)
            const localVarPath = `/api/v1/notificationSubscription/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentId !== undefined) {
                localVarQueryParameter['contentId'] = contentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (contentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('remove', 'contentId', contentId)
            const localVarPath = `/api/v1/notificationSubscription/{contentId}`
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationSubscriptionApi - functional programming interface
 * @export
 */
export const NotificationSubscriptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationSubscriptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(contentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfNotificationSubscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the info whether the notification is enabled for given id.
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(contentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfNotificationSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(contentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationSubscriptionApi - factory interface
 * @export
 */
export const NotificationSubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationSubscriptionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(contentId: string, options?: any): AxiosPromise<ApiResponseOfNotificationSubscription> {
            return localVarFp.create(contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the info whether the notification is enabled for given id.
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(contentId: string, options?: any): AxiosPromise<ApiResponseOfNotificationSubscriptionResponse> {
            return localVarFp.getById(contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(contentId: string, options?: any): AxiosPromise<NotificationSubscriptionResponse> {
            return localVarFp.remove(contentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationSubscriptionApi - interface
 * @export
 * @interface NotificationSubscriptionApi
 */
export interface NotificationSubscriptionApiInterface {
    /**
     * 
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationSubscriptionApiInterface
     */
    create(contentId: string, options?: any): AxiosPromise<ApiResponseOfNotificationSubscription>;

    /**
     * Retrieves the info whether the notification is enabled for given id.
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationSubscriptionApiInterface
     */
    getById(contentId: string, options?: any): AxiosPromise<ApiResponseOfNotificationSubscriptionResponse>;

    /**
     * 
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationSubscriptionApiInterface
     */
    remove(contentId: string, options?: any): AxiosPromise<NotificationSubscriptionResponse>;

}

/**
 * NotificationSubscriptionApi - object-oriented interface
 * @export
 * @class NotificationSubscriptionApi
 * @extends {BaseAPI}
 */
export class NotificationSubscriptionApi extends BaseAPI implements NotificationSubscriptionApiInterface {
    /**
     * 
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationSubscriptionApi
     */
    public create(contentId: string, options?: any) {
        return NotificationSubscriptionApiFp(this.configuration).create(contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the info whether the notification is enabled for given id.
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationSubscriptionApi
     */
    public getById(contentId: string, options?: any) {
        return NotificationSubscriptionApiFp(this.configuration).getById(contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationSubscriptionApi
     */
    public remove(contentId: string, options?: any) {
        return NotificationSubscriptionApiFp(this.configuration).remove(contentId, options).then((request) => request(this.axios, this.basePath));
    }
}
