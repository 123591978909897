/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiPaginatedResponseOfPromotionResponse } from '../model';
// @ts-ignore
import { ApiResponseOfPromotionResponse } from '../model';
// @ts-ignore
import { ApiResponseOfPromotionResponseArray } from '../model';
// @ts-ignore
import { ApiResponseOfPromotionTypeResponseArray } from '../model';
// @ts-ignore
import { PromotionBody } from '../model';
// @ts-ignore
import { SortDirection } from '../model';
/**
 * PromotionsApi - axios parameter creator
 * @export
 */
export const PromotionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PromotionBody} promotionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (promotionBody: PromotionBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'promotionBody' is not null or undefined
            assertParamExists('create', 'promotionBody', promotionBody)
            const localVarPath = `/api/v1/promotions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promotionBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endPromotion: async (promotionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'promotionId' is not null or undefined
            assertParamExists('endPromotion', 'promotionId', promotionId)
            const localVarPath = `/api/v1/promotions/{promotionId}/end`
                .replace(`{${"promotionId"}}`, encodeURIComponent(String(promotionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/promotions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (promotionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'promotionId' is not null or undefined
            assertParamExists('getById', 'promotionId', promotionId)
            const localVarPath = `/api/v1/promotions/{promotionId}`
                .replace(`{${"promotionId"}}`, encodeURIComponent(String(promotionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated: async (limit?: number, page?: number, sortDirection?: SortDirection, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/promotions/paginated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/promotions/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startPromotion: async (promotionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'promotionId' is not null or undefined
            assertParamExists('startPromotion', 'promotionId', promotionId)
            const localVarPath = `/api/v1/promotions/{promotionId}/start`
                .replace(`{${"promotionId"}}`, encodeURIComponent(String(promotionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {PromotionBody} promotionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (promotionId: string, promotionBody: PromotionBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'promotionId' is not null or undefined
            assertParamExists('update', 'promotionId', promotionId)
            // verify required parameter 'promotionBody' is not null or undefined
            assertParamExists('update', 'promotionBody', promotionBody)
            const localVarPath = `/api/v1/promotions/{promotionId}`
                .replace(`{${"promotionId"}}`, encodeURIComponent(String(promotionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promotionBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionsApi - functional programming interface
 * @export
 */
export const PromotionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromotionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PromotionBody} promotionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(promotionBody: PromotionBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfPromotionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(promotionBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endPromotion(promotionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfPromotionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.endPromotion(promotionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfPromotionResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(promotionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfPromotionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(promotionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginated(limit?: number, page?: number, sortDirection?: SortDirection, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfPromotionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginated(limit, page, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromotionTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfPromotionTypeResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromotionTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startPromotion(promotionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfPromotionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startPromotion(promotionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {PromotionBody} promotionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(promotionId: string, promotionBody: PromotionBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfPromotionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(promotionId, promotionBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromotionsApi - factory interface
 * @export
 */
export const PromotionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromotionsApiFp(configuration)
    return {
        /**
         * 
         * @param {PromotionBody} promotionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(promotionBody: PromotionBody, options?: any): AxiosPromise<ApiResponseOfPromotionResponse> {
            return localVarFp.create(promotionBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endPromotion(promotionId: string, options?: any): AxiosPromise<ApiResponseOfPromotionResponse> {
            return localVarFp.endPromotion(promotionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: any): AxiosPromise<ApiResponseOfPromotionResponseArray> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(promotionId: string, options?: any): AxiosPromise<ApiResponseOfPromotionResponse> {
            return localVarFp.getById(promotionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated(limit?: number, page?: number, sortDirection?: SortDirection, options?: any): AxiosPromise<ApiPaginatedResponseOfPromotionResponse> {
            return localVarFp.getPaginated(limit, page, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionTypes(options?: any): AxiosPromise<ApiResponseOfPromotionTypeResponseArray> {
            return localVarFp.getPromotionTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startPromotion(promotionId: string, options?: any): AxiosPromise<ApiResponseOfPromotionResponse> {
            return localVarFp.startPromotion(promotionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {PromotionBody} promotionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(promotionId: string, promotionBody: PromotionBody, options?: any): AxiosPromise<ApiResponseOfPromotionResponse> {
            return localVarFp.update(promotionId, promotionBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotionsApi - interface
 * @export
 * @interface PromotionsApi
 */
export interface PromotionsApiInterface {
    /**
     * 
     * @param {PromotionBody} promotionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApiInterface
     */
    create(promotionBody: PromotionBody, options?: any): AxiosPromise<ApiResponseOfPromotionResponse>;

    /**
     * 
     * @param {string} promotionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApiInterface
     */
    endPromotion(promotionId: string, options?: any): AxiosPromise<ApiResponseOfPromotionResponse>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApiInterface
     */
    getAll(options?: any): AxiosPromise<ApiResponseOfPromotionResponseArray>;

    /**
     * 
     * @param {string} promotionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApiInterface
     */
    getById(promotionId: string, options?: any): AxiosPromise<ApiResponseOfPromotionResponse>;

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApiInterface
     */
    getPaginated(limit?: number, page?: number, sortDirection?: SortDirection, options?: any): AxiosPromise<ApiPaginatedResponseOfPromotionResponse>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApiInterface
     */
    getPromotionTypes(options?: any): AxiosPromise<ApiResponseOfPromotionTypeResponseArray>;

    /**
     * 
     * @param {string} promotionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApiInterface
     */
    startPromotion(promotionId: string, options?: any): AxiosPromise<ApiResponseOfPromotionResponse>;

    /**
     * 
     * @param {string} promotionId 
     * @param {PromotionBody} promotionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApiInterface
     */
    update(promotionId: string, promotionBody: PromotionBody, options?: any): AxiosPromise<ApiResponseOfPromotionResponse>;

}

/**
 * PromotionsApi - object-oriented interface
 * @export
 * @class PromotionsApi
 * @extends {BaseAPI}
 */
export class PromotionsApi extends BaseAPI implements PromotionsApiInterface {
    /**
     * 
     * @param {PromotionBody} promotionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public create(promotionBody: PromotionBody, options?: any) {
        return PromotionsApiFp(this.configuration).create(promotionBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} promotionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public endPromotion(promotionId: string, options?: any) {
        return PromotionsApiFp(this.configuration).endPromotion(promotionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public getAll(options?: any) {
        return PromotionsApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} promotionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public getById(promotionId: string, options?: any) {
        return PromotionsApiFp(this.configuration).getById(promotionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public getPaginated(limit?: number, page?: number, sortDirection?: SortDirection, options?: any) {
        return PromotionsApiFp(this.configuration).getPaginated(limit, page, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public getPromotionTypes(options?: any) {
        return PromotionsApiFp(this.configuration).getPromotionTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} promotionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public startPromotion(promotionId: string, options?: any) {
        return PromotionsApiFp(this.configuration).startPromotion(promotionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} promotionId 
     * @param {PromotionBody} promotionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public update(promotionId: string, promotionBody: PromotionBody, options?: any) {
        return PromotionsApiFp(this.configuration).update(promotionId, promotionBody, options).then((request) => request(this.axios, this.basePath));
    }
}
