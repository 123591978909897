import {
  deleteField,
  doc,
  Firestore,
  getDoc,
  getFirestore,
  onSnapshot,
  setDoc,
  Unsubscribe,
  updateDoc
} from 'firebase/firestore';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { withConcurrentLayoutEditors, withSelectedLayoutId } from '../../state/Layouts';
import { FsEntityType, FsUserInfo, FsUserInteractionType } from '../../utils/types/fsUserInfo';
import { beforeunload, useAuth, useConfirm, useLocales } from '../General';
import firebaseConfig from '../../common/config/firebaseConfig.json';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { usePrevious } from '../General/usePrevious';

type ReturnType = {
  registerLayoutToConnectedUsers: (entityId: string, userInteractionType: FsUserInteractionType) => Promise<void>;
  deregisterLayoutFromConnectedUsers: (entityId: string) => Promise<void>;
  setHistoryManageIsClean: Dispatch<SetStateAction<boolean>>;
  setIsSaved: Dispatch<SetStateAction<boolean>>;
  currentLayoutEditors: FsUserInfo[];
};

interface UnsubscribeMap {
  [key: string]: Unsubscribe;
}

interface FsConnectedUsers {
  [key: string]: FsUserInfo;
}

export const MAX_UPDATE_STATUS_WINDOW = 1 * 60 * 1000;
// Users inactive for more than 1 hour will be removed from list.
const MAX_INACTIVE_TIME = 60 * 60 * 1000;
let firestoreDb: Firestore;

export function useFirestore({
  fetchEntityCallback
}: {
  fetchEntityCallback?: (entityId: string) => unknown;
}): ReturnType {
  const { currentUser } = useAuth();
  const { confirm } = useConfirm();
  const { t } = useLocales();
  const [unsubscribeRefs, setUnsubscribeRefs] = useState<UnsubscribeMap>({});
  const [historyManageIsClean, setHistoryManageIsClean] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const [currentLayoutEditors, setCurrentLayoutEditors] = useRecoilState(withConcurrentLayoutEditors);
  const selectedLayoutId = useRecoilValue(withSelectedLayoutId);
  const previousHistoryManageIsClean = usePrevious(historyManageIsClean);

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        firestoreDb = getFirestore(auth.app);
      }
    });
  }, []);

  useEffect(() => {
    if (previousHistoryManageIsClean && !historyManageIsClean && isSaved) {
      setIsSaved(false);
    }
  }, [selectedLayoutId, historyManageIsClean]);

  useEffect(() => {
    let userInteractionType = historyManageIsClean
      ? FsUserInteractionType.BEING_WATCHED
      : FsUserInteractionType.BEING_EDITED;
    if (isSaved) {
      userInteractionType = FsUserInteractionType.UPDATED;
    }
    const unloadCallback = () => {
      if (selectedLayoutId) {
        deregisterLayoutFromConnectedUsers(selectedLayoutId).catch((e) => console.error(e));
      }
    };
    if (selectedLayoutId) {
      registerLayoutToConnectedUsers(selectedLayoutId, userInteractionType).catch((e) => console.error(e));
      window.addEventListener(beforeunload, unloadCallback);
    }

    return () => {
      if (selectedLayoutId) {
        deregisterLayoutFromConnectedUsers(selectedLayoutId).catch((e) => console.error(e));
      }
      window.removeEventListener(beforeunload, unloadCallback);
    };
  }, [selectedLayoutId, historyManageIsClean, isSaved]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const waitForDb = (callback: any, ...args: any) => {
    setTimeout(() => {
      callback(...args);
    }, 1000);
  };

  const setCurrentLayoutEditorsWithoutCurrentUser = (users: FsUserInfo[]) => {
    setCurrentLayoutEditors(
      users.filter((user) => user.id !== currentUser?.id).sort((a, b) => (a.email > b.email ? 1 : -1))
    );
  };

  const getLayoutPath = (entityId: string) => {
    return doc(firestoreDb, FsEntityType.LAYOUT, entityId);
  };

  const registerForLayoutLiveUpdates = (entityId: string) => {
    if (!firestoreDb) {
      return;
    }

    const unsub = onSnapshot(getLayoutPath(entityId), (doc) => {
      if (entityId === selectedLayoutId) {
        const data = doc.exists() ? (doc.data() as FsConnectedUsers) : {};
        setCurrentLayoutEditorsWithoutCurrentUser(Object.values(data));
        handleUpdatedLayoutEvents(entityId, Object.values(data)).catch((e) => console.error(e));
      }
    });
    unsubscribeRefs[entityId] = unsub;
    setUnsubscribeRefs({ ...unsubscribeRefs });
  };

  const handleUpdatedLayoutEvents = async (entityId: string, users: FsUserInfo[]) => {
    if (entityId !== selectedLayoutId || !fetchEntityCallback) {
      return;
    }
    const usersWithUpdateEvents = users.filter((user) => isLatestUpdateEvent(user, currentUser?.email));
    if (usersWithUpdateEvents.length > 0) {
      if (historyManageIsClean) {
        fetchEntityCallback(entityId);
        return;
      }

      const isConfirmed = await confirm({
        confirmColor: 'error',
        body: (
          <div>
            <div>{t('confirm.fetch_layout')}</div>
            <div style={{ fontSize: '15px', marginTop: '15px', marginBottom: '-15px', color: 'red' }}>
              {t('confirm.fetch_layout_warning')}
            </div>
          </div>
        )
      });
      if (isConfirmed) {
        fetchEntityCallback(entityId);
      }
    }
  };

  const registerLayoutToConnectedUsers = async (entityId: string, userInteractionType: FsUserInteractionType) => {
    if (!currentUser) {
      return;
    }
    if (!firestoreDb) {
      waitForDb(registerLayoutToConnectedUsers, entityId, userInteractionType);
      return;
    }
    registerForLayoutLiveUpdates(entityId);

    const userInfo: FsUserInfo = {
      id: currentUser.id,
      userInteractionType,
      email: currentUser.email,
      photoURL: currentUser.photoURL || '',
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      createdDate: new Date().toISOString()
    };

    await setDoc(getLayoutPath(entityId), { [currentUser.id]: userInfo }, { merge: true });
    await fetchConnectedUsersForLayout(entityId);
  };

  const fetchConnectedUsersForLayout = async (entityId: string): Promise<FsUserInfo[]> => {
    if (!firestoreDb) {
      return [];
    }
    const docSnap = await getDoc(getLayoutPath(entityId));
    const data = docSnap.exists() ? (Object.values(docSnap.data() as FsConnectedUsers) as FsUserInfo[]) : [];

    setCurrentLayoutEditorsWithoutCurrentUser(data);
    await removeInactiveUsers(entityId, data);
    return data;
  };

  const removeInactiveUsers = async (entityId: string, users: FsUserInfo[]) => {
    const inactiveUsers = users.filter(
      (user) => new Date(user.createdDate).getTime() + MAX_INACTIVE_TIME < new Date().getTime()
    );
    if (inactiveUsers.length) {
      const dataToDelete = inactiveUsers.reduce((a, b) => ({ ...a, [b.id]: deleteField() }), {});
      await updateDoc(getLayoutPath(entityId), dataToDelete);
    }
  };

  const deregisterForLayoutLiveUpdates = (entityId: string) => {
    const unsubscribeRef = unsubscribeRefs[entityId];
    if (unsubscribeRef) {
      unsubscribeRef();
      setUnsubscribeRefs({ ...unsubscribeRef, [entityId]: undefined });
    }
  };

  const deregisterLayoutFromConnectedUsers = async (entityId: string) => {
    deregisterForLayoutLiveUpdates(entityId);

    if (!currentUser || !firestoreDb) {
      return;
    }

    const dataToDelete = {
      [currentUser.id]: deleteField()
    };
    await updateDoc(getLayoutPath(entityId), dataToDelete);
  };

  return {
    currentLayoutEditors,
    registerLayoutToConnectedUsers,
    deregisterLayoutFromConnectedUsers,
    setHistoryManageIsClean,
    setIsSaved
  };
}

export const isLatestUpdateEvent = (user: FsUserInfo, currentUserEmail?: string): boolean => {
  return (
    user.userInteractionType === FsUserInteractionType.UPDATED &&
    user.email !== currentUserEmail &&
    new Date(user.createdDate).getTime() + MAX_UPDATE_STATUS_WINDOW > new Date().getTime()
  );
};
