import { PermissionsGroupId } from '../../API';

export enum PermissionsLevel {
  UPSERT = 'UPSERT',
  PUBLISH = 'PUBLISH',
  READ = 'READ',
  DELETE = 'DELETE'
}

export type PermissionsMatrix = Record<PermissionsGroupId, Record<PermissionsLevel, boolean>>;
