import { Skeleton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useLocales, useTheme } from '../../../hooks';
import { useLayouts } from '../../../hooks/Layouts';
import {
  FUTURE_LAYOUTS,
  PAST_LAYOUTS,
  withAllLayouts,
  withHasMorePastLayouts,
  withLoadingPastLayouts
} from '../../../state/Layouts';
import { withGlobalTimeDiff } from '../../../state/Timezone';
import { themeColors } from '../../../theme';
import Button from '../../shared/Button';
import Repeat from '../../shared/Repeat';
import ShadowScroller from '../../shared/ShadowScroller';
import LayoutListItem from '../LayoutListItem';
import LayoutsTimelineExtender from './LayoutsTimelineExtender';
import { largeNumber } from '../../../utils/dateHelpers';

const useStyles = makeStyles()((theme) => ({
  loadPastLayouts: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    zIndex: 1
  },
  presentDivider: {
    height: 0,
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 2,
      top: -1,
      opacity: 0.5,
      backgroundColor: themeColors.orange,
      zIndex: 1
    }
  },
  noFutureLayouts: {
    padding: theme.spacing(4),
    textAlign: 'center'
  },
  skeletonRow: {
    margin: theme.spacing(2, 4),
    height: theme.spacing(6)
  }
}));

export const testIds = {
  componentRoot: 'layouts-timeline.root',
  presentDivider: 'layouts-timeline.present-divider',
  loadOlderButton: 'layouts-timeline.load-older'
};

function LayoutsTimeline(): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { refreshLayout, getOlderLayouts } = useLayouts();
  const allLayouts = useRecoilValue(withAllLayouts);
  const loadingPastLayouts = useRecoilValue(withLoadingPastLayouts);
  const hasMorePastLayouts = useRecoilValue(withHasMorePastLayouts);
  const globalTimeDiff = useRecoilValue(withGlobalTimeDiff);

  useEffect(() => {
    const diff = getNextLayoutLiveDiff();
    if (diff < 0) return;
    const refreshLayoutId = setTimeout(
      () => {
        refreshLayout(getSoonestLayout()?.id);
      },
      Math.min(diff + 500, largeNumber)
    ); // Padded to be safe

    return () => {
      clearTimeout(refreshLayoutId);
    };
  }, [allLayouts]);

  const getSoonestLayout = () => {
    return allLayouts?.[FUTURE_LAYOUTS][0];
  };

  const getNextLayoutLiveDiff = () => {
    const soonestLayout = getSoonestLayout();
    if (!soonestLayout) return -1;
    return DateTime.fromISO(soonestLayout.startTime as string).toMillis() - Date.now() + globalTimeDiff;
  };

  return (
    <ShadowScroller paper={true} loading={!allLayouts} data-testid={testIds.componentRoot} horizontalScrolling={false}>
      {hasMorePastLayouts && (
        <div className={classes.loadPastLayouts}>
          <Button
            variant="text"
            color={formControlColor}
            disabled={!allLayouts}
            loading={loadingPastLayouts}
            onClick={getOlderLayouts}
            fullWidth
            data-testid={testIds.loadOlderButton}
          >
            {t('layouts.load_older')}
          </Button>
        </div>
      )}
      {allLayouts && (
        <>
          {allLayouts[PAST_LAYOUTS].map((layout, index) => {
            return <LayoutListItem key={layout.id} layout={layout} index={index} which={PAST_LAYOUTS} />;
          })}
          <div className={classes.presentDivider} data-testid={testIds.presentDivider} />
          {allLayouts[FUTURE_LAYOUTS].map((layout, index) => {
            return <LayoutListItem key={layout.id} layout={layout} index={index} which={FUTURE_LAYOUTS} />;
          })}
          {allLayouts[FUTURE_LAYOUTS].length === 0 && (
            <Typography className={classes.noFutureLayouts} variant="body2" color="textSecondary">
              {t('layouts.no_future_layouts')}
            </Typography>
          )}
          <LayoutsTimelineExtender />
        </>
      )}
      {!allLayouts && (
        <Repeat n={5}>
          <Skeleton className={classes.skeletonRow} animation="wave" />
        </Repeat>
      )}
    </ShadowScroller>
  );
}

export default LayoutsTimeline;
