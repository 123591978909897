import React, { forwardRef, useImperativeHandle } from 'react';
import { Typography } from '@mui/material';
import { useLocales, useNotifications } from '../../../../../hooks';
import Button from '../../../../shared/Button';
import { Sortable } from '../../../../shared/Sortable';
import { FormBody, ItemContainer } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';
import SportsHeroMatchItem from './SportsHeroMatchItem';
import { SportsHeroUiModuleBody } from '../../../../../API';
import { useFieldArray, useFormContext } from 'react-hook-form';

export const testIds = {
  validatorForm: 'sports-hero-form.validator-form'
};

const SportsHeroForm = forwardRef<UIModuleTypeForm<SportsHeroUiModuleBody>>((_, ref) => {
  const { control } = useFormContext<SportsHeroUiModuleBody>();
  const { append, remove, fields, replace } = useFieldArray({ control, name: 'matchHeroContents' });
  const { t } = useLocales();

  const { notifyError } = useNotifications();

  useImperativeHandle(ref, () => ({
    isValid(uiModule) {
      if (isMatchesEmpty(uiModule)) {
        notifyError(t('errors.layouts.no_empty_hero_matches'));
        return false;
      }
      return true;
    }
  }));

  const isMatchesEmpty = (uiModule: SportsHeroUiModuleBody) => {
    for (const matches of uiModule.matchHeroContents) {
      if (!matches.matchId) {
        return true;
      }
    }
    return false;
  };

  const addNewSportMatchItem = () => {
    append({ matchId: '', title: { es: '', en: '', pt: '' } });
  };

  return (
    <FormBody>
      <div>
        <Typography variant="h6">{t('layouts.hero_matches_list')}</Typography>
        <Sortable
          list={fields}
          setList={(newList) => replace(newList)}
          animation={100}
          ghostClass="sortableGhost"
          dragClass="sortableDragDefault"
        >
          {fields.map((match, i) => (
            <ItemContainer key={match.id}>
              <SportsHeroMatchItem index={i} onRemove={() => remove(i)} />
            </ItemContainer>
          ))}
        </Sortable>
      </div>
      <div>
        <Button color="grey" onClick={() => addNewSportMatchItem()}>
          {t('layouts.hero_new_match_item')}
        </Button>
      </div>
    </FormBody>
  );
});

SportsHeroForm.displayName = 'SportsHeroForm';

export default SportsHeroForm;
