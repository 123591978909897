import React, { FC, useEffect, useState } from 'react';
import { InputLabel, MenuItem, Select } from '@mui/material';
import FormControl from '../FormControl';
import { useLocales } from '../../../hooks';
import { DateTime } from 'luxon';
import { getISODateStringToEndOfDay } from '../../../utils/dateHelpers';
import HybridDatePicker from '../HybridDatePicker';
import { DynamicDateWithFallback } from '../../../API';
import { makeStyles } from 'tss-react/mui';

export enum DateRangeOption {
  TO,
  FROM,
  BETWEEN,
  EXACTLY
}

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
    flexWrap: 'wrap'
  },
  select: {
    overflow: 'auto',
    maxWidth: 'unset',
    minWidth: 100
  }
}));

export const testIds = {
  root: 'date-range.root',
  pickerTypeSelect: 'date-range.pick-type-select',
  option: (option: DateRangeOption): string => `date-range.option.${option}`,
  startDatePicker: 'date-range.start-date-picker',
  endDatePicker: 'date-range.end-date-picker'
};

export type DateRangeValue = { startDate?: DynamicDateWithFallback; endDate?: DynamicDateWithFallback };

export interface DateRangeProps {
  label: string;
  value: DateRangeValue;
  onChange: (value: DateRangeValue) => void;
}

export const DateRange: FC<React.PropsWithChildren<DateRangeProps>> = ({ value: dateValue = {}, onChange, label }) => {
  const getOptionFromDateRange = (value: DateRangeValue) => {
    if (value.startDate?.dynamicDate && value.endDate?.dynamicDate) {
      return DateRangeOption.BETWEEN;
    }
    if (value.startDate?.isoDate && value.endDate?.isoDate) {
      const startDate = getISODateStringToEndOfDay(value.startDate?.isoDate);
      const endDate = getISODateStringToEndOfDay(value.endDate?.isoDate);
      if (startDate && endDate && DateTime.fromISO(startDate).hasSame(DateTime.fromISO(endDate), 'day')) {
        return DateRangeOption.EXACTLY;
      }
      return DateRangeOption.BETWEEN;
    }
    if (value.startDate?.isoDate || value.startDate?.dynamicDate) {
      return DateRangeOption.FROM;
    }
    return DateRangeOption.TO;
  };

  const [dateOption, setDateOption] = useState(getOptionFromDateRange(dateValue));

  const { classes } = useStyles();
  const { t } = useLocales();

  useEffect(() => {
    let { startDate, endDate } = dateValue;
    if ((!startDate?.dynamicDate && !startDate?.isoDate) || dateOption === DateRangeOption.EXACTLY) {
      startDate = {
        isoDate: getISODateStringToEndOfDay(new Date(startDate?.isoDate || endDate?.isoDate || new Date()).getTime())
      };
    }
    if (!endDate?.dynamicDate && !endDate?.isoDate) {
      endDate = {
        isoDate: getISODateStringToEndOfDay(new Date(endDate?.isoDate || startDate?.isoDate || new Date()).getTime())
      };
    }

    switch (dateOption) {
      case DateRangeOption.FROM:
        handleOnChange({ startDate });
        break;
      case DateRangeOption.TO:
        handleOnChange({ endDate });
        break;
      case DateRangeOption.EXACTLY:
        handleOnChange({
          startDate,
          endDate: startDate
        });
        break;
      case DateRangeOption.BETWEEN:
        handleOnChange({
          startDate,
          endDate
        });
        break;
    }
  }, [dateOption]);

  const handleOnChange = (value: DateRangeValue) => {
    if (value.startDate?.dynamicDate && value.endDate?.dynamicDate) {
      onChange(value);
      return;
    }

    // Make sure that date doesn't have time with it, it should be start of the day
    const startDate = getISODateStringToEndOfDay(value.startDate?.isoDate);
    const endDate =
      dateOption === DateRangeOption.EXACTLY ? startDate : getISODateStringToEndOfDay(value.endDate?.isoDate);

    if (dateOption !== DateRangeOption.EXACTLY && (value.startDate?.dynamicDate || value.endDate?.dynamicDate)) {
      onChange({
        startDate: value.startDate?.dynamicDate ? value.startDate : { isoDate: startDate },
        endDate: value.endDate?.dynamicDate ? value.endDate : { isoDate: endDate }
      });
      return;
    }

    if (dateOption === DateRangeOption.BETWEEN && startDate && endDate && startDate > endDate) {
      // Don't allow to set startDate > endDate
      return;
    }
    onChange({ startDate: { isoDate: startDate }, endDate: { isoDate: endDate } });
  };

  const minDate =
    ![DateRangeOption.FROM, DateRangeOption.EXACTLY].includes(dateOption) && dateValue.startDate?.isoDate
      ? DateTime.fromISO(dateValue.startDate?.isoDate)
      : undefined;
  const maxDate =
    ![DateRangeOption.TO, DateRangeOption.EXACTLY].includes(dateOption) && dateValue.endDate?.isoDate
      ? DateTime.fromISO(dateValue.endDate?.isoDate)
      : undefined;

  const showStartDate = [DateRangeOption.FROM, DateRangeOption.BETWEEN, DateRangeOption.EXACTLY].includes(dateOption);
  const showEndDate = [DateRangeOption.TO, DateRangeOption.BETWEEN].includes(dateOption);

  return (
    <div className={classes.container} data-testid={testIds.root}>
      <FormControl data-testid={testIds.pickerTypeSelect}>
        <InputLabel sx={{ overflow: 'visible' }}>{label}</InputLabel>
        <Select
          className={classes.select}
          value={dateOption}
          onChange={({ target: { value } }) => {
            setDateOption(value as DateRangeOption);
          }}
        >
          <MenuItem data-testid={testIds.option(DateRangeOption.TO)} value={DateRangeOption.TO}>
            {t('date_picker.to')}
          </MenuItem>
          <MenuItem data-testid={testIds.option(DateRangeOption.FROM)} value={DateRangeOption.FROM}>
            {t('date_picker.from')}
          </MenuItem>
          <MenuItem data-testid={testIds.option(DateRangeOption.BETWEEN)} value={DateRangeOption.BETWEEN}>
            {t('date_picker.between')}
          </MenuItem>
          <MenuItem data-testid={testIds.option(DateRangeOption.EXACTLY)} value={DateRangeOption.EXACTLY}>
            {t('date_picker.exactly')}
          </MenuItem>
        </Select>
      </FormControl>
      {showStartDate && (
        <HybridDatePicker
          key="startDate"
          value={dateValue.startDate}
          allowDynamicPicker={dateOption !== DateRangeOption.EXACTLY}
          onChange={(newValue) =>
            handleOnChange({
              ...dateValue,
              startDate: newValue
            })
          }
          maxDate={maxDate}
          data-testid={testIds.startDatePicker}
        />
      )}
      {showEndDate && (
        <HybridDatePicker
          key="endDate"
          value={dateValue.endDate}
          onChange={(newValue) =>
            handleOnChange({
              ...dateValue,
              endDate: newValue
            })
          }
          minDate={minDate}
          data-testid={testIds.endDatePicker}
        />
      )}
    </div>
  );
};
