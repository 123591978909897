import React, { forwardRef } from 'react';
import { AccountLoadingUiModuleBody } from '../../../../../API';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocales } from '../../../../../hooks';
import { FormBody, FormField } from '../../styles';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { LocaleKeys } from '../../../../../locales/i18n';

export const testIds = {
  formBody: 'account-loading.form-body',
  formField: (name: keyof AccountLoadingUiModuleBody): string => `account-loading.form-field.${name}`
};

const AccountLoadingForm = forwardRef<UIModuleTypeForm<AccountLoadingUiModuleBody>>(() => {
  const { control } = useFormContext<AccountLoadingUiModuleBody>();
  const { t } = useLocales();

  const LokaliseField = (name: keyof AccountLoadingUiModuleBody, required = false): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.account_loading.fields.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  return (
    <FormBody data-testid={testIds.formBody}>
      <div>{LokaliseField('accountLoading', true)}</div>
    </FormBody>
  );
});

AccountLoadingForm.displayName = 'AccountLoadingForm';

export default AccountLoadingForm;
