import { DocumentLocale, SportBody, SportResponse } from '../../../API';
import { UseLocalesReturnType } from '../../../hooks';
import { useSportsAPI } from '../../../hooks/API/Sports/useSportsAPI';
import { CreateDataManager, DataManagerHookReturnType, DataManagerStateFullReturnType } from '../../DataManager';

export type SportsDataManagerType = {
  state: DataManagerStateFullReturnType<SportResponse, SportBody>;
  hook: DataManagerHookReturnType<SportResponse, SportBody>;
};

const generateNewSport = (): SportBody => ({
  sportId: '',
  name: {
    [DocumentLocale.ES]: ''
  }
});

export const SportsDataManager = CreateDataManager<SportResponse, SportBody>({
  name: 'sports',
  idField: 'id',
  useApiHook: useSportsAPI,
  generateNew: generateNewSport,
  // Function used to generate a sport string for success & error handling
  toString: (sport: SportBody | SportResponse, locales: UseLocalesReturnType) =>
    `${locales.localize(sport.name)} (${sport.sportId})`
});
