import React, { useEffect } from 'react';
import { Delete, DragHandle, OpenInNew } from '@mui/icons-material';
import { Chip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { useData } from '../../../../../../data-layer';
import { useLocales } from '../../../../../../hooks';
import AssetImage from '../../../../../shared/AssetImage';
import IconButton from '../../../../../shared/IconButton';
import ObjectPreview from '../../../../../shared/ObjectPreview';
import { AppRoutes } from '../../../../../../Routes';
import { ContentCollectionType } from '../../../../../../API';

export const testIds = {
  root: 'collection-list-form-list-item.root',
  image: 'collection-list-form-list-item.image',
  title: 'collection-list-form-list-item.title',
  description: 'collection-list-form-list-item.description',
  curatedLabel: 'collection-list-form-list-item.curated-label',
  smartQueryLabel: 'collection-list-form-list-item.smart-query-label',
  deleteButton: 'collection-list-form-list-item.delete-button'
};

const useStyles = makeStyles()((theme) => ({
  root: {
    background: theme.palette.background.default,
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  drag: {
    cursor: 'grab'
  },
  image: {
    aspectRatio: '16 / 9',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius
  },
  spacer: {
    flexGrow: 1
  },
  details: {
    overflow: 'hidden'
  },
  typography: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

type CollectionListFormListItemProps = {
  value: string;
  onRemove: () => unknown;
};

export const collectionListFormListItemClasses = {
  root: 'CollectionListPreview-root'
};

export function CollectionListFormListItem({ value, onRemove }: CollectionListFormListItemProps): React.ReactElement {
  const {
    collections: {
      state: { withRecordById },
      hook: { queueIdToFetch }
    }
  } = useData();

  const { t, localize } = useLocales();
  const { classes, cx } = useStyles();
  const collection = useRecoilValue(withRecordById(value));

  useEffect(() => {
    if (!collection && value) {
      queueIdToFetch(value);
    }
  }, [value]);

  const openLink = () => {
    if (collection?.collectionType === ContentCollectionType.MIXED_LIST) {
      window.open(AppRoutes.collection(collection.entityId), '_blank');
    } else if (collection) {
      window.open(AppRoutes.vodCollection(collection.entityId), '_blank');
    }
  };

  if (!collection) return <></>;

  return (
    <Stack
      direction="row"
      gap={4}
      alignItems="center"
      className={cx(classes.root, collectionListFormListItemClasses.root)}
      data-testid={testIds.root}
    >
      <DragHandle className={classes.drag} />
      <AssetImage path={collection.cardLandscapeImage} className={classes.image} data-testid={testIds.image} />
      <Stack className={classes.details}>
        <Typography variant="body1" className={classes.typography} data-testid={testIds.title}>
          {localize(collection.title)}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.typography}
          data-testid={testIds.description}
        >
          {localize(collection.description)}
        </Typography>
      </Stack>
      <Chip
        label={t('collections.curated_contents', { count: collection.contents.length })}
        size="small"
        data-testid={testIds.curatedLabel}
      />
      {collection.query && (
        <Chip label={t('collections.smart_query_configured')} size="small" data-testid={testIds.smartQueryLabel} />
      )}
      <div className={classes.spacer} />
      <IconButton title={t('collections.open_collection')} onClick={openLink}>
        <OpenInNew />
      </IconButton>
      <ObjectPreview title={t('collections.collection')} object={collection} />
      <IconButton title={t('general.delete')} onClick={onRemove} data-testid={testIds.deleteButton}>
        <Delete />
      </IconButton>
    </Stack>
  );
}
