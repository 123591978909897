import React from 'react';
import { alpha, AvatarGroup, Badge, Tooltip } from '@mui/material';
import { FsUserInfo, FsUserInteractionType } from '../../../utils/types/fsUserInfo';
import { makeStyles } from 'tss-react/mui';
import { Check, Edit } from '@mui/icons-material';
import { UserAvatar } from '../../Users/UserAvatar';

const useStyles = makeStyles()((theme) => ({
  editBadge: {
    background: alpha(theme.palette.background.default, 0.6),
    padding: 3,
    borderRadius: 99,
    width: 18,
    height: 18
  }
}));

function ConcurrentEditors({ users }: { users: FsUserInfo[] }): JSX.Element {
  const { classes } = useStyles();

  const UserBadgeContent = ({ interactionType }: { interactionType: FsUserInteractionType }) => {
    switch (interactionType) {
      case FsUserInteractionType.BEING_EDITED:
        return <Edit className={classes.editBadge} />;
      case FsUserInteractionType.UPDATED:
        return <Check className={classes.editBadge} />;
      default:
        return null;
    }
  };

  return (
    <AvatarGroup max={6}>
      {users.map((user) => (
        <Tooltip title={`${user.firstName} ${user.lastName}`} key={user.email}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={<UserBadgeContent interactionType={user.userInteractionType} />}
          >
            <UserAvatar user={user} />
          </Badge>
        </Tooltip>
      ))}
    </AvatarGroup>
  );
}

export default ConcurrentEditors;
