import React from 'react';
import { useLocales } from '../../../hooks';
import { UsagePolicyBody } from '../../../API';
import CountryBadgesMerged from '../CountryBadgesMerged';
import { Stack } from '@mui/material';

export interface IUsagePolicyBadgeProps {
  usagePolicy: UsagePolicyBody;
}

export function UsagePolicyBadge({ usagePolicy }: IUsagePolicyBadgeProps): JSX.Element {
  const { t } = useLocales();

  return (
    <Stack direction="row" gap={2}>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <span>{t(`usage_policy.usage_policy_${(usagePolicy as any).default.toLowerCase()}`)}</span>
      {!!usagePolicy.exceptionCountryCodes && <CountryBadgesMerged countryCodes={usagePolicy.exceptionCountryCodes} />}
    </Stack>
  );
}
