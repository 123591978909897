import React, { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  ContinueWatchingUiModuleBody,
  ContinueWatchingUIModuleContentTreatment,
  ContinueWatchingUIModuleContentType
} from '../../../../../API';
import ContinueWatchingContentTypePicker from '../../ContinueWatchingContentTypePicker';
import { FormBody } from '../../styles';
import TreatmentPicker from '../../TreatmentPicker';
import { UIModuleTypeForm } from '../../UIModuleForm';

export const testIds = {
  formBody: 'continue-watching-form.form-body'
};

const ContinueWatchingForm = forwardRef<UIModuleTypeForm<ContinueWatchingUiModuleBody>>(() => {
  const { control } = useFormContext<ContinueWatchingUiModuleBody>();

  return (
    <FormBody data-testid={testIds.formBody}>
      <div>
        <Controller
          control={control}
          name="contentTreatment"
          render={({ field: { value, onChange } }) => (
            <TreatmentPicker
              options={ContinueWatchingUIModuleContentTreatment}
              value={value}
              onChange={(value) => onChange(value as ContinueWatchingUIModuleContentTreatment)}
            />
          )}
        />
      </div>
      <div>
        <Controller
          control={control}
          name="contentType"
          render={({ field: { value, onChange } }) => (
            <ContinueWatchingContentTypePicker
              options={ContinueWatchingUIModuleContentType}
              value={value}
              onChange={(value) => onChange(value as ContinueWatchingUIModuleContentType)}
            />
          )}
        />
      </div>
    </FormBody>
  );
});

ContinueWatchingForm.displayName = 'ContinueWatchingForm';

export default ContinueWatchingForm;
