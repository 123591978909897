import React, { forwardRef } from 'react';
import {
  LandingPromoUiModuleBackgroundBody,
  LandingPromoUiModuleBody,
  LandingPromoUiModuleFAQBody,
  LandingPromoUiModuleLogoBody,
  LandingPromoUiModuleStepBody
} from '../../../../../API';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { Controller, FieldError, useFieldArray, useFormContext } from 'react-hook-form';
import { useLocales, useTheme } from '../../../../../hooks';
import { FormBody, FormField, SectionHeader } from '../../styles';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { markAsRequired } from '../../../../../utils/formHelpers';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { LocaleKeys } from '../../../../../locales/i18n';
import generateId from '../../../../../utils/generateId';
import { Sortable } from '../../../../shared/Sortable';
import { Stack, Typography } from '@mui/material';
import IconButton from '../../../../shared/IconButton';
import { Add, Delete } from '@mui/icons-material';
import Button from '../../../../shared/Button';
import { Box } from '@mui/system';

export const testIds = {
  formBody: 'landing-promo.form-body',
  formField: (
    name:
      | keyof LandingPromoUiModuleBody
      | keyof LandingPromoUiModuleLogoBody
      | keyof LandingPromoUiModuleBackgroundBody
      | keyof LandingPromoUiModuleStepBody
      | keyof LandingPromoUiModuleFAQBody
  ): string => `landing-promo.form-field.${name}`,
  bundleLogo: 'landing-promo.bundle-logo',
  bundleLogoAddButton: 'landing-promo.bundle-logo-add-button',
  bundleLogoRemoveButton: 'landing-promo.bundle-logo-remove-button',
  background: 'landing-promo.background',
  backgroundAddButton: 'landing-promo.background-add-button',
  backgroundRemoveButton: 'landing-promo.background-remove-button',
  planPartnerLogo: 'landing-promo.plan-partner-logo',
  planPartnerLogoAddButton: 'landing-promo.plan-partner-logo-add-button',
  planPartnerLogoRemoveButton: 'landing-promo.plan-partner-logo-remove-button',
  planBrandLogos: 'landing-promo.plan-brand-logos',
  planBrandLogosAddButton: 'landing-promo.plan-brand-logos-add-button',
  planBrandLogosRemoveButton: 'landing-promo.plan-brand-logos-remove-button',
  planBrandTiles: 'landing-promo.plan-brand-tiles',
  planBrandTilesAddButton: 'landing-promo.plan-brand-tiles-add-button',
  planBrandTilesRemoveButton: 'landing-promo.plan-brand-tiles-remove-button',
  steps: 'landing-promo.steps',
  stepsAddButton: 'landing-promo.steps-add-button',
  stepsRemoveButton: 'landing-promo.steps-remove-button',
  FAQ: 'landing-promo.faq',
  FAQAddButton: 'landing-promo.faq-add-button',
  FAQRemoveButton: 'landing-promo.faq-remove-button'
};

type LandingPromoFieldProps = {
  name: keyof LandingPromoUiModuleBody;
  required?: boolean;
  'data-testid'?: string;
  label: string;
};

interface LandingPromoLogoFieldProps extends Omit<LandingPromoFieldProps, 'name'> {
  name: keyof LandingPromoUiModuleLogoBody;
  assetType: AssetTypesEnum;
  position: number;
}

interface LandingPromoBackgroundProps extends Omit<LandingPromoFieldProps, 'name'> {
  name: keyof LandingPromoUiModuleBackgroundBody;
  assetType: AssetTypesEnum;
  position: number;
}

const LandingPromoForm = forwardRef<UIModuleTypeForm<LandingPromoUiModuleBody>>(() => {
  const { control, watch } = useFormContext<LandingPromoUiModuleBody>();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const {
    append: appendBundleLogo,
    remove: removeBundleLogo,
    fields: fieldsBundleLogo,
    replace: replaceBundleLogoMethod
  } = useFieldArray({ control, name: 'bundleLogo' });
  const {
    append: appendBackground,
    remove: removeBackground,
    fields: fieldsBackground,
    replace: replaceBackground
  } = useFieldArray({ control, name: 'background' });
  const {
    append: appendPlanPartnerLogo,
    remove: removePlanPartnerLogo,
    fields: fieldsPlanPartnerLogo,
    replace: replacePlanPartnerLogo
  } = useFieldArray({
    control,
    name: 'planPartnerLogo'
  });
  const {
    append: appendPlanBrandLogos,
    remove: removePlanBrandLogos,
    fields: fieldsPlanBrandLogos,
    replace: replacePlanBrandLogos
  } = useFieldArray({
    control,
    name: 'planBrandLogos'
  });
  const {
    append: appendPlanBrandTiles,
    remove: removePlanBrandTiles,
    fields: fieldsPlanBrandTiles,
    replace: replacePlanBrandTiles
  } = useFieldArray({
    control,
    name: 'planBrandTiles'
  });
  const {
    append: appendSteps,
    remove: removeSteps,
    fields: fieldsSteps,
    replace: replaceSteps
  } = useFieldArray({
    control,
    name: 'steps'
  });
  const {
    append: appendFAQ,
    remove: removeFAQ,
    fields: fieldsFAQ,
    replace: replaceFAQ
  } = useFieldArray({
    control,
    name: 'FAQ'
  });

  const moduleId = watch('moduleId');

  const renderAssetPicker = (
    name:
      | keyof LandingPromoUiModuleBody
      | keyof LandingPromoUiModuleLogoBody
      | keyof LandingPromoUiModuleBackgroundBody,
    label: string,
    assetType: AssetTypesEnum,
    moduleId: string,
    required: boolean,
    value: string,
    onChange: (event: string | React.ChangeEvent<Element>) => void,
    error: FieldError | undefined
  ) => (
    <FormField>
      <AssetBrowser
        label={markAsRequired(t(`layouts.landing_promo.fields.${label}`), required)}
        assetId={moduleId}
        assetType={assetType}
        value={value}
        data-testid={testIds.formField(name)}
        hasError={!!error}
        onChange={onChange}
      />
    </FormField>
  );

  const AssetPicker = ({
    name,
    label,
    assetType,
    required = false
  }: LandingPromoFieldProps & { assetType: AssetTypesEnum }): React.ReactElement => (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) =>
        renderAssetPicker(name, label, assetType, moduleId || '', required, value as string, onChange, error)
      }
    />
  );

  const AssetPickerBundleLogo = ({
    name,
    label,
    assetType,
    position,
    required = false
  }: LandingPromoLogoFieldProps): React.ReactElement => (
    <Controller
      control={control}
      name={`bundleLogo.${position}.${name}`}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) =>
        renderAssetPicker(name, label, assetType, moduleId || '', required, value as string, onChange, error)
      }
    />
  );

  const AssetPickerBackground = ({
    name,
    label,
    assetType,
    position,
    required = false
  }: LandingPromoBackgroundProps): React.ReactElement => (
    <Controller
      control={control}
      name={`background.${position}.${name}`}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) =>
        renderAssetPicker(name, label, assetType, moduleId || '', required, value as string, onChange, error)
      }
    />
  );

  const AssetPickerPlanPartnerLogo = ({
    name,
    label,
    assetType,
    position,
    required = false
  }: LandingPromoLogoFieldProps): React.ReactElement => (
    <Controller
      control={control}
      name={`planPartnerLogo.${position}.${name}`}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) =>
        renderAssetPicker(name, label, assetType, moduleId || '', required, value as string, onChange, error)
      }
    />
  );

  const AssetPickerPlanBrandLogos = ({
    name,
    label,
    assetType,
    position,
    required = false
  }: LandingPromoLogoFieldProps): React.ReactElement => (
    <Controller
      control={control}
      name={`planBrandLogos.${position}.${name}`}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) =>
        renderAssetPicker(name, label, assetType, moduleId || '', required, value as string, onChange, error)
      }
    />
  );

  const AssetPickerPlanBrandTiles = ({
    name,
    label,
    assetType,
    position,
    required = false
  }: LandingPromoBackgroundProps): React.ReactElement => (
    <Controller
      control={control}
      name={`planBrandTiles.${position}.${name}`}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) =>
        renderAssetPicker(name, label, assetType, moduleId || '', required, value as string, onChange, error)
      }
    />
  );

  const LokaliseField = (name: keyof LandingPromoUiModuleBody, required = false): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.landing_promo.fields.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  const LokaliseFieldStep = (
    name: keyof LandingPromoUiModuleStepBody,
    position: number,
    required = false
  ): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={`steps.${position}.${name}`}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.landing_promo.fields.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  const LokaliseFieldFAQ = (
    name: keyof LandingPromoUiModuleFAQBody,
    position: number,
    required = false
  ): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={`FAQ.${position}.${name}`}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.landing_promo.fields.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  const onAddBundleLogo = () => {
    appendBundleLogo({
      id: generateId(),
      logo: ''
    });
  };

  const onAddBackground = () => {
    appendBackground({
      id: generateId(),
      portraitFillImage: '',
      landscapeFillImage: '',
      mobileFillImage: '',
      ctvFillImage: '',
      desktopFillImage: '',
      tabletFillImage: ''
    });
  };

  const onAddPlanPartnerLogo = () => {
    appendPlanPartnerLogo({
      id: generateId(),
      logo: ''
    });
  };

  const onAddPlanBrandLogos = () => {
    appendPlanBrandLogos({
      id: generateId(),
      logo: ''
    });
  };

  const onAddPlanBrandTiles = () => {
    appendPlanBrandTiles({
      id: generateId(),
      portraitFillImage: '',
      landscapeFillImage: '',
      mobileFillImage: '',
      ctvFillImage: '',
      desktopFillImage: '',
      tabletFillImage: ''
    });
  };

  const onAddStep = () => {
    appendSteps({
      id: generateId(),
      step: ''
    });
  };

  const onAddFAQ = () => {
    appendFAQ({
      id: generateId(),
      question: '',
      answer: ''
    });
  };

  return (
    <FormBody data-testid={testIds.formBody}>
      <div>
        <SectionHeader>{t('layouts.landing_promo.headers.header_section')}</SectionHeader>
        <AssetPicker name="vixLogo" label="vixLogo" assetType={AssetTypes.landingPromo.ctv} required />
        <Box marginBottom={5}>
          <Sortable
            list={fieldsBundleLogo}
            setList={replaceBundleLogoMethod}
            ghostClass="sortableGhost"
            dragClass="sortableDragDefault"
          >
            {fieldsBundleLogo.map((logo, i) => (
              <Stack
                key={logo.id}
                data-testid={testIds.bundleLogo}
                gap={6}
                marginBottom={5}
                direction="row"
                alignItems="center"
              >
                <AssetPickerBundleLogo
                  name="logo"
                  label="bundleLogo"
                  assetType={AssetTypes.landingPromo.ctv}
                  position={i}
                />
                <IconButton
                  title={t('layouts.landing_promo.actions.remove_bundle_logo')}
                  onClick={() => removeBundleLogo(i)}
                  sx={{ mt: 4 }}
                  data-testid={testIds.bundleLogoRemoveButton}
                >
                  <Delete />
                </IconButton>
              </Stack>
            ))}
          </Sortable>
          <Button
            startIcon={<Add />}
            color={formControlColor}
            variant="outlined"
            onClick={onAddBundleLogo}
            data-testid={testIds.bundleLogoAddButton}
          >
            {t('layouts.landing_promo.actions.add_bundle_logo')}
          </Button>
        </Box>
        <Box marginBottom={5}>
          <Sortable
            list={fieldsBackground}
            setList={replaceBackground}
            ghostClass="sortableGhost"
            dragClass="sortableDragDefault"
          >
            {fieldsBackground.map((image, i) => (
              <>
                <Typography variant="body2" color="textSecondary" marginBottom={3}>
                  {t('layouts.landing_promo.fields.backgroundImages')}
                </Typography>
                <Stack
                  key={image.id}
                  data-testid={testIds.background}
                  gap={6}
                  marginBottom={5}
                  marginLeft={3}
                  direction="row"
                  alignItems="center"
                >
                  <Box>
                    <AssetPickerBackground
                      name="landscapeFillImage"
                      label="landscapeFillImage"
                      assetType={AssetTypes.landingPromo.landscape}
                      position={i}
                    />
                    <AssetPickerBackground
                      name="portraitFillImage"
                      label="portraitFillImage"
                      assetType={AssetTypes.landingPromo.portrait}
                      position={i}
                    />
                    <AssetPickerBackground
                      name="desktopFillImage"
                      label="desktopFillImage"
                      assetType={AssetTypes.landingPromo.desktop}
                      position={i}
                    />
                    <AssetPickerBackground
                      name="mobileFillImage"
                      label="mobileFillImage"
                      assetType={AssetTypes.landingPromo.mobile}
                      position={i}
                    />
                    <AssetPickerBackground
                      name="ctvFillImage"
                      label="ctvFillImage"
                      assetType={AssetTypes.landingPromo.ctv}
                      position={i}
                    />
                    <AssetPickerBackground
                      name="tabletFillImage"
                      label="tabletFillImage"
                      assetType={AssetTypes.landingPromo.tablet}
                      position={i}
                    />
                  </Box>
                  <IconButton
                    title={t('layouts.landing_promo.actions.remove_background_button')}
                    onClick={() => removeBackground(i)}
                    sx={{ mt: 4 }}
                    data-testid={testIds.backgroundRemoveButton}
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              </>
            ))}
          </Sortable>
          <Button
            startIcon={<Add />}
            color={formControlColor}
            variant="outlined"
            onClick={onAddBackground}
            data-testid={testIds.backgroundAddButton}
          >
            {t('layouts.landing_promo.actions.add_background')}
          </Button>
        </Box>
        {LokaliseField('bundleHeader', true)}
        {LokaliseField('bundleSubheader')}
        {LokaliseField('legalDisclaimer')}
        {LokaliseField('seeOtherPlansCTA')}
      </div>
      <div>
        <SectionHeader>{t('layouts.landing_promo.headers.plan_description_section')}</SectionHeader>
        {LokaliseField('planHeader')}
        {LokaliseField('planSubheader')}
        {LokaliseField('planCTA')}
        <Box marginBottom={5}>
          <Sortable
            list={fieldsPlanPartnerLogo}
            setList={replacePlanPartnerLogo}
            ghostClass="sortableGhost"
            dragClass="sortableDragDefault"
          >
            {fieldsPlanPartnerLogo.map((logo, i) => (
              <Stack
                key={logo.id}
                data-testid={testIds.planBrandLogos}
                gap={6}
                marginBottom={5}
                direction="row"
                alignItems="center"
              >
                <AssetPickerPlanPartnerLogo
                  name="logo"
                  label="planPartnerLogo"
                  assetType={AssetTypes.landingPromo.ctv}
                  position={i}
                />
                <IconButton
                  title={t('layouts.landing_promo.actions.remove_plan_partner_logo')}
                  onClick={() => removePlanPartnerLogo(i)}
                  sx={{ mt: 4 }}
                  data-testid={testIds.planPartnerLogoRemoveButton}
                >
                  <Delete />
                </IconButton>
              </Stack>
            ))}
          </Sortable>
          <Button
            startIcon={<Add />}
            color={formControlColor}
            variant="outlined"
            onClick={onAddPlanPartnerLogo}
            data-testid={testIds.planPartnerLogoAddButton}
          >
            {t('layouts.landing_promo.actions.add_plan_partner_logo')}
          </Button>
        </Box>
        <Box marginBottom={5}>
          <Sortable
            list={fieldsPlanBrandLogos}
            setList={replacePlanBrandLogos}
            ghostClass="sortableGhost"
            dragClass="sortableDragDefault"
          >
            {fieldsPlanBrandLogos.map((logo, i) => (
              <Stack
                key={logo.id}
                data-testid={testIds.planBrandLogos}
                gap={6}
                marginBottom={5}
                direction="row"
                alignItems="center"
              >
                <AssetPickerPlanBrandLogos
                  name="logo"
                  label="planBrandLogo"
                  assetType={AssetTypes.landingPromo.ctv}
                  position={i}
                />
                <IconButton
                  title={t('layouts.landing_promo.actions.remove_plan_brand_logos')}
                  onClick={() => removePlanBrandLogos(i)}
                  sx={{ mt: 4 }}
                  data-testid={testIds.planBrandLogosRemoveButton}
                >
                  <Delete />
                </IconButton>
              </Stack>
            ))}
          </Sortable>
          <Button
            startIcon={<Add />}
            color={formControlColor}
            variant="outlined"
            onClick={onAddPlanBrandLogos}
            data-testid={testIds.planBrandLogosAddButton}
          >
            {t('layouts.landing_promo.actions.add_plan_brand_logos')}
          </Button>
        </Box>
        <Box marginBottom={5}>
          <Sortable
            list={fieldsPlanBrandTiles}
            setList={replacePlanBrandTiles}
            ghostClass="sortableGhost"
            dragClass="sortableDragDefault"
          >
            {fieldsPlanBrandTiles.map((image, i) => (
              <>
                <Typography variant="body2" color="textSecondary" marginBottom={3}>
                  {t('layouts.landing_promo.fields.planBrandTiles')}
                </Typography>
                <Stack
                  key={image.id}
                  data-testid={testIds.planBrandTiles}
                  gap={6}
                  marginBottom={5}
                  marginLeft={3}
                  direction="row"
                  alignItems="center"
                >
                  <Box>
                    <AssetPickerPlanBrandTiles
                      name="landscapeFillImage"
                      label="landscapeFillImage"
                      assetType={AssetTypes.landingPromo.landscape}
                      position={i}
                    />
                    <AssetPickerPlanBrandTiles
                      name="portraitFillImage"
                      label="portraitFillImage"
                      assetType={AssetTypes.landingPromo.portrait}
                      position={i}
                    />
                    <AssetPickerPlanBrandTiles
                      name="desktopFillImage"
                      label="desktopFillImage"
                      assetType={AssetTypes.landingPromo.desktop}
                      position={i}
                    />
                    <AssetPickerPlanBrandTiles
                      name="mobileFillImage"
                      label="mobileFillImage"
                      assetType={AssetTypes.landingPromo.mobile}
                      position={i}
                    />
                    <AssetPickerPlanBrandTiles
                      name="ctvFillImage"
                      label="ctvFillImage"
                      assetType={AssetTypes.landingPromo.ctv}
                      position={i}
                    />
                    <AssetPickerPlanBrandTiles
                      name="tabletFillImage"
                      label="tabletFillImage"
                      assetType={AssetTypes.landingPromo.tablet}
                      position={i}
                    />
                  </Box>
                  <IconButton
                    title={t('layouts.landing_promo.actions.remove_plan_brand_tiles')}
                    onClick={() => removePlanBrandTiles(i)}
                    sx={{ mt: 4 }}
                    data-testid={testIds.planBrandTilesRemoveButton}
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              </>
            ))}
          </Sortable>
          <Button
            startIcon={<Add />}
            color={formControlColor}
            variant="outlined"
            onClick={onAddPlanBrandTiles}
            data-testid={testIds.planBrandTilesAddButton}
          >
            {t('layouts.landing_promo.actions.add_plan_brand_tiles')}
          </Button>
        </Box>
      </div>
      <div>
        <SectionHeader>{t('layouts.landing_promo.headers.bundle_plan_section')}</SectionHeader>
        {LokaliseField('bundlePlanHeader')}
        {LokaliseField('bundlePlanSubheader')}
        {LokaliseField('bundlePlanName')}
        {LokaliseField('bundlePlanCost')}
        {LokaliseField('bundlePlanDescription')}
        {LokaliseField('bundlePlanPriceTag')}
        {LokaliseField('bundlePlanValueProps')}
        {LokaliseField('bundlePlanSeeMoreCTA')}
        {LokaliseField('bundlePlanCTA')}
        {LokaliseField('bundlePlanDisclaimer')}
      </div>
      <div>
        <SectionHeader>{t('layouts.landing_promo.headers.how_it_works_section')}</SectionHeader>
        {LokaliseField('stepTitle')}
        {LokaliseField('startCTA')}
        <Box>
          <Sortable
            list={fieldsSteps}
            setList={replaceSteps}
            ghostClass="sortableGhost"
            dragClass="sortableDragDefault"
          >
            {fieldsSteps.map((step, i) => (
              <Stack
                key={step.id}
                data-testid={testIds.steps}
                gap={6}
                marginBottom={5}
                direction="row"
                alignItems="center"
              >
                {LokaliseFieldStep('step', i)}
                <IconButton
                  title={t('layouts.landing_promo.actions.remove_step')}
                  onClick={() => removeSteps(i)}
                  sx={{ mt: 4 }}
                  data-testid={testIds.stepsRemoveButton}
                >
                  <Delete />
                </IconButton>
              </Stack>
            ))}
          </Sortable>
          <Button
            startIcon={<Add />}
            color={formControlColor}
            variant="outlined"
            onClick={onAddStep}
            data-testid={testIds.stepsAddButton}
          >
            {t('layouts.landing_promo.actions.add_step')}
          </Button>
        </Box>
      </div>
      <div>
        <SectionHeader>{t('layouts.landing_promo.headers.FAQ_section')}</SectionHeader>
        {LokaliseField('FAQTitle')}
        <Box>
          <Sortable list={fieldsFAQ} setList={replaceFAQ} ghostClass="sortableGhost" dragClass="sortableDragDefault">
            {fieldsFAQ.map((faq, i) => (
              <Stack
                key={faq.id}
                data-testid={testIds.FAQ}
                gap={6}
                marginBottom={5}
                direction="row"
                alignItems="center"
              >
                {LokaliseFieldFAQ('question', i)}
                {LokaliseFieldFAQ('answer', i)}
                <IconButton
                  title={t('layouts.landing_promo.actions.remove_FAQ')}
                  onClick={() => removeFAQ(i)}
                  sx={{ mt: 4 }}
                  data-testid={testIds.FAQRemoveButton}
                >
                  <Delete />
                </IconButton>
              </Stack>
            ))}
          </Sortable>
          <Button
            startIcon={<Add />}
            color={formControlColor}
            variant="outlined"
            onClick={onAddFAQ}
            data-testid={testIds.FAQAddButton}
          >
            {t('layouts.landing_promo.actions.add_FAQ')}
          </Button>
        </Box>
      </div>
    </FormBody>
  );
});

LandingPromoForm.displayName = 'LandingPromoForm';

export default LandingPromoForm;
