import React, { useEffect, useRef } from 'react';
import { Checkbox, FormControlLabel, MenuItem, Typography } from '@mui/material';
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { useLocales, useTheme } from '../../../../hooks';
import Button from '../../../shared/Button';
import Drawer from '../../../shared/Drawer';
import TextField from '../../../shared/TextField';
import FormControl from '../../../shared/FormControl';
import { InputLabel } from '@mui/material';
import { IdBadge } from '../../../shared/IdBadge';
import { Stack } from '@mui/system';
import CountryPickerLite from '../../../shared/CountryPickerLite';
import { useData } from '../../../../data-layer';
import PromoGroupPicker from '../../Promotions/PromoGroupPicker/PromoGroupPicker';
import CouponPicker from '../../Coupons/CouponPicker/CouponPicker';
import {
  CouponCodeState,
  PaymentPlanResponse,
  PermissionsGroupId,
  PlanPromoPriceUnit,
  TargetPlatform
} from '../../../../API';
import TemplateIdPicker from '../../Promotions/TemplateIdPicker/TemplateIdPicker';
import { capitalize } from 'lodash-es';
import { NONE } from '../../../../utils/types/genericTypes';
import { usePermissions } from '../../../../hooks/Permissions/usePermissions';
import Select from '../../../shared/Select';
import { usePermissionsGuard } from '../../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../../state/theme';

const useStyles = makeStyles()((theme) => ({
  formBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    padding: theme.spacing(4)
  },
  grow: {
    flexGrow: 1
  },
  noWrap: {
    whiteSpace: 'nowrap'
  },
  templateId: {
    minWidth: 260
  },
  promoPriceLength: {
    minWidth: 160
  },
  promoPriceUnit: {
    minWidth: 160
  },
  deviceSelect: {
    width: 220
  },
  countryPicker: {
    width: 280
  },
  footerButton: {
    minWidth: 160
  }
}));

export const testIds = {
  formBody: 'plan-form.form-body',
  saveButton: 'plan-form.save-button',
  templateIdInput: 'plan-form.template-id-input',
  promoPriceUnitInput: 'plan-form.promo-price-unit-input',
  promoPriceLengthInput: 'plan-form.promo-price-length-input'
};

export function PlanForm(): React.ReactElement {
  const { classes } = useStyles();
  const { coupons, plans } = useData();
  const allCoupons = useRecoilValue(coupons.state.withAllRecords);

  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const formRef = useRef<ValidatorForm>(null);

  const formMetadata = useRecoilValue(plans.state.withFormMetadata);
  const plan = formMetadata.record;
  const isSaving = useRecoilValue(plans.state.withIsSaving);

  const { control, handleSubmit, reset } = useForm<PaymentPlanResponse>({
    defaultValues: plan
  });

  const { canSave } = usePermissionsGuard({
    homepageOption: HomepageOptions.MONETIZATION
  });
  const { hasUpsertPermission } = usePermissions();

  useEffect(() => {
    reset(plan);
  }, [plan]);

  const onClose = () => {
    if (!isSaving) {
      plans.hook.closeForm();
    }
  };

  const onSubmit: SubmitHandler<PaymentPlanResponse> = async (plan) => {
    const planToSave: PaymentPlanResponse = {
      ...plan,
      sourceSku: plan.sourceSku || null,
      promoPriceLength: plan.promoPriceLength || null,
      promoPriceUnit: (plan.promoPriceUnit as unknown) === NONE ? null : plan.promoPriceUnit
    } as unknown as PaymentPlanResponse; // TODO remove this typecasting after the codegen gets fixed
    const savedPlan = await plans.hook.save(planToSave);
    if (savedPlan) {
      onClose();
    }
  };

  const onError: SubmitErrorHandler<PaymentPlanResponse> = (errors, event) => {
    event?.preventDefault();
    event?.stopPropagation();
  };

  const footerLeft = (
    <Button
      className={classes.footerButton}
      type="submit"
      color="primary"
      onClick={handleSubmit(onSubmit, onError)}
      loading={isSaving}
      disabled={!hasUpsertPermission(PermissionsGroupId.MONETIZATION) || !canSave}
      data-testid={testIds.saveButton}
    >
      {t('payments.save_plan')}
    </Button>
  );

  const footerRight = (
    <Button className={classes.footerButton} color="grey" onClick={onClose} disabled={isSaving}>
      {t('general.cancel')}
    </Button>
  );

  return (
    <Drawer
      open={formMetadata.isShowingForm}
      onSubmit={handleSubmit(onSubmit, onError)}
      onClose={onClose}
      headerLeft={
        <Stack direction="row" gap={4}>
          <Typography variant="h6">{t('payments.edit_plan')}</Typography>
          {plan?.code && <IdBadge id={plan.code} />}
        </Stack>
      }
      footerLeft={footerLeft}
      footerRight={footerRight}
      formRef={formRef}
    >
      {plan && (
        <div className={classes.formBody} data-testid={testIds.formBody}>
          <Stack direction="row" gap={4}>
            <div className={classes.grow}>
              <Controller
                control={control}
                name="name"
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    value={value}
                    onChange={onChange}
                    label={t('payments.plan_name')}
                    error={!!error}
                    helperText={error?.message}
                    disabled
                  />
                )}
              />
            </div>
            <Controller
              control={control}
              name="device"
              render={({ field: { value, onChange } }) => (
                <FormControl className={classes.deviceSelect}>
                  <InputLabel shrink variant="standard">
                    {t('payments.device')}
                  </InputLabel>
                  <Select label="Devices" value={value} onChange={onChange} disabled>
                    {Object.values(TargetPlatform).map((device) => (
                      <MenuItem key={device} value={device}>
                        {t(`platforms.platform_name.${device}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="country"
              render={({ field: { value, onChange } }) => (
                <CountryPickerLite
                  className={classes.countryPicker}
                  label={t('general.country')}
                  value={[value.toUpperCase()]}
                  onChange={(value) => onChange(value[0])}
                  disabled
                  hidePresets
                />
              )}
            />
          </Stack>
          <Controller
            control={control}
            name="description"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                fullWidth
                value={value}
                onChange={onChange}
                label={t('general.description')}
                error={!!error}
                helperText={error?.message}
                disabled
              />
            )}
          />
          <Stack direction="row" gap={4}>
            <Controller
              control={control}
              name="price"
              render={({ field: { value, onChange } }) => (
                <TextField disabled value={value} onChange={onChange} label={t('payments.price')} />
              )}
            />
            <Controller
              control={control}
              name="currency"
              render={({ field: { value, onChange } }) => (
                <TextField disabled value={value} onChange={onChange} label={t('payments.currency')} />
              )}
            />
            <Controller
              control={control}
              name="billingPeriodUnit"
              render={({ field: { value, onChange } }) => (
                <TextField disabled value={value} onChange={onChange} label={t('payments.billing_period_unit')} />
              )}
            />
            <Controller
              control={control}
              name="billingPeriodLength"
              render={({ field: { value, onChange } }) => (
                <TextField disabled value={value} onChange={onChange} label={t('payments.billing_period_length')} />
              )}
            />
            <Controller
              control={control}
              name="enabledForNewSubscriptions"
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  className={classes.noWrap}
                  label={t('payments.enable_for_new_subscribers')}
                  control={<Checkbox checked={!!value} color={formControlColor} onChange={onChange} disabled />}
                />
              )}
            />
          </Stack>

          <Controller
            control={control}
            name="sourceSku"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                fullWidth
                value={value || null}
                onChange={onChange}
                label={t('payments.promo_source_sku')}
                error={!!error}
                helperText={error?.message}
                clearable
              />
            )}
          />
          <Stack direction="row" gap={4}>
            <Controller
              control={control}
              name="promoPriceLength"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  className={classes.promoPriceLength}
                  label={t('payments.promo_price_length')}
                  isNumber
                  min={1}
                  data-testid={testIds.promoPriceLengthInput}
                  clearable
                />
              )}
            />
            <Controller
              control={control}
              name="promoPriceUnit"
              render={({ field: { value, onChange } }) => (
                <FormControl className={classes.promoPriceUnit}>
                  <InputLabel shrink>{t('payments.promo_price_unit')}</InputLabel>
                  <Select
                    label={t('payments.promo_price_unit')}
                    value={value || NONE}
                    onChange={onChange}
                    data-testid={testIds.promoPriceUnitInput}
                  >
                    <MenuItem value={NONE}>{t('filters.none')}</MenuItem>
                    {Object.values(PlanPromoPriceUnit).map((unit) => (
                      <MenuItem key={unit} value={unit}>
                        {capitalize(unit)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="promoGroup"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <PromoGroupPicker value={value || ''} onChange={onChange} required fieldError={error} />
              )}
            />
            <Controller
              control={control}
              name="localizationTemplateId"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TemplateIdPicker
                  className={classes.templateId}
                  value={value}
                  onChange={onChange}
                  required
                  fieldError={error}
                />
              )}
            />
          </Stack>

          {allCoupons && (
            <Controller
              control={control}
              name="coupons"
              render={({ field: { value, onChange } }) => (
                <CouponPicker
                  planCode={plan.code}
                  value={value}
                  onChange={onChange}
                  allowMultiple
                  filterByState={[CouponCodeState.REDEEMABLE]}
                />
              )}
            />
          )}
        </div>
      )}
    </Drawer>
  );
}
