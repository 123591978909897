import React from 'react';
import { EntitlementType } from '../../../API';
import { AnonIcon, FreeIcon, SubIcon } from './Icons';

export function EntitlementsIcon({ entitlement }: { entitlement: EntitlementType }): React.ReactElement {
  switch (entitlement) {
    case EntitlementType.ANON:
      return <AnonIcon />;
    case EntitlementType.FREE:
      return <FreeIcon />;
    case EntitlementType.SUB:
      return <SubIcon />;
    default:
      return <></>;
  }
}
