import { Page, PermissionsGroupId } from '../../../API';
import { usePagesAPI } from '../../../hooks/API/Pages/usePagesAPI';
import { CreateDataManager, DataManagerReturnType, DataManagerStateFullReturnType } from '../../DataManager';
import { PagesHook, PagesHookReturnType } from './PagesHook';

export type PagesDataManagerType = {
  state: DataManagerStateFullReturnType<Page, Page>;
  hook: PagesHookReturnType;
};
export interface PagesDataManagerReturnType extends DataManagerReturnType<Page, Page> {
  state: DataManagerStateFullReturnType<Page, Page>;
  hook: () => PagesHookReturnType;
}

export const generateNew = (ownerPermissionsGroup: PermissionsGroupId = PermissionsGroupId.ADMIN): Page => ({
  urlPath: '',
  ownerPermissionsGroup
});

export const PagesDataManager = CreateDataManager<Page, Page, PagesDataManagerReturnType>({
  name: 'pages',
  idField: 'urlPath',
  useApiHook: usePagesAPI,
  // Function to generate a new page
  generateNew,
  // Function used to generate a page string for success & error handling
  toString: (page: Page) => page.urlPath,
  bucketIdToQueryParams: (bucketId: string) => [bucketId],
  // Curried function to initialize a sort function
  sortFunctionInitializer: () => (a: Page, b: Page) => {
    if (!a.ownerPermissionsGroup || !b.ownerPermissionsGroup) return -1;
    return a.ownerPermissionsGroup.localeCompare(b.ownerPermissionsGroup) || a.urlPath.localeCompare(b.urlPath);
  },
  hookInitializer: PagesHook
});
