import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useMemo, useState } from 'react';
import ShadowScroller from '../ShadowScroller';
import Repeat from '../Repeat';
import { alpha, Box, Skeleton } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { noop } from 'lodash-es';
import { Allotment } from 'allotment';
import { useSplitPane } from '../../../hooks/General/useSplitPane';
import { RecoilState } from 'recoil';
import { ShadowList } from '../Virtuoso';

export type DataGridColDef<T> = {
  key: string;
  label: React.ReactNode;
  align?: 'left' | 'right' | 'center';
  cellRenderer?: (row: T) => React.ReactNode;
  sortable?: boolean;
  baseWidth?: string | number;
  minWidth?: number;
  maxWidth?: number;
  sortFunction?: (a: T, b: T) => number;
};

export type DataGridProps<T> = {
  rows?: T[];
  columns: DataGridColDef<T>[];
  onRowClick?: (row: T) => void;
  withColumnSizes: RecoilState<number[]>;
  rowKey: keyof T;
  loading?: boolean;
  useVirtualization?: boolean;
};

const headerHeight = 45;

const useStyles = makeStyles<void, 'hoverSort'>()((theme, params, classes) => {
  const hoverColor = alpha(theme.palette.primary.main, theme.palette.mode === 'dark' ? 0.2 : 0.1);

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden'
    },
    headerRow: {
      background: theme.palette.background.default,
      fontWeight: 'bold',
      borderTopRightRadius: theme.shape.borderRadius,
      borderTopLeftRadius: theme.shape.borderRadius,
      overflowY: 'scroll',
      userSelect: 'none',
      borderBottom: `1px solid ${theme.palette.divider}`,
      height: headerHeight,
      '::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.background.default,
        boxShadow: 'none'
      }
    },
    gridBody: {
      flexGrow: 1
    },
    headerColumn: {
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
      height: headerHeight,
      padding: theme.spacing(0, 2)
    },
    headerColumnText: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    hoverSort: {
      opacity: 0,
      width: 0,
      transition: 'opacity 0.2s ease-in-out'
    },
    sortableHeaderColumn: {
      cursor: 'pointer',
      '&:hover': {
        background: hoverColor,
        [`& .${classes.hoverSort}`]: {
          width: 'auto',
          opacity: 1
        }
      }
    },
    row: {
      display: 'grid',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.divider}`,
      cursor: 'pointer',
      fontSize: '0.875rem',
      '&:hover': {
        background: hoverColor
      }
    },
    rowCell: {
      padding: theme.spacing(2),
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around'
    },
    skeletonRow: {
      height: theme.spacing(6),
      margin: theme.spacing(3)
    }
  };
});

export enum SortDirection {
  ASC,
  DESC
}

export type DataGridSort = {
  direction: SortDirection;
  column: string;
};

export const testIds = {
  root: 'data-grid.root',
  row: 'data-grid.row',
  headerColumn: 'data-grid.header-column',
  rowCell: 'data-grid.row-cell',
  sortAsc: 'data-grid.sort-asc',
  sortDesc: 'data-grid.sort-desc',
  loadingState: 'data-grid.loading-state'
};

export function DataGrid<T>({
  rows,
  columns,
  onRowClick,
  rowKey,
  withColumnSizes,
  loading = false,
  useVirtualization
}: DataGridProps<T>): React.ReactElement {
  const { cx, classes } = useStyles();
  const [sort, setSort] = useState<DataGridSort | null>(null);
  const [sortedRows, setSortedRows] = useState<T[] | undefined>(sortRows(rows));
  const { sizes, onChange: onSplitPaneChange } = useSplitPane(withColumnSizes);

  useEffect(() => {
    setSortedRows(sortRows(rows));
  }, [sort, rows]);

  function sortRows(rowsToSort: T[] | undefined) {
    if (!rowsToSort) return undefined;
    if (!sort) return rowsToSort;
    const sortFunction = columns.find((col) => col.key === sort.column)?.sortFunction;
    if (!sortFunction) return rowsToSort;
    const rowsCopy = [...rowsToSort];
    rowsCopy.sort((a, b) => {
      return sortFunction(a, b) * (sort.direction === SortDirection.ASC ? 1 : -1);
    });
    return rowsCopy;
  }

  const onHeaderClick = (column: string) => {
    if (sort?.column === column) {
      if (sort.direction === SortDirection.ASC) {
        setSort({
          column,
          direction: SortDirection.DESC
        });
      } else if (sort.direction === SortDirection.DESC) {
        setSort(null);
      }
    } else {
      setSort({
        column,
        direction: SortDirection.ASC
      });
    }
  };

  const Row: React.FC<{ row: T }> = ({ row }) => (
    <div
      className={classes.row}
      role="row"
      style={{ gridTemplateColumns: sizes.map((x) => `${x}px`).join(' ') }}
      onClick={() => (onRowClick || noop)(row)}
      data-testid={testIds.row}
      data-row={String(row[rowKey])}
    >
      {columns.map((column) => (
        <Box
          className={classes.rowCell}
          role="cell"
          key={column.key as string}
          data-testid={testIds.rowCell}
          data-column={column.key}
          sx={{
            alignItems: column.align === 'right' ? 'flex-end' : column.align === 'center' ? 'center' : 'flex-start'
          }}
        >
          {column.cellRenderer ? column.cellRenderer(row) : (row[column.key as keyof T] as string)}
        </Box>
      ))}
    </div>
  );

  const isLoading = useMemo(() => !sortedRows || loading, [loading, sortedRows]);

  const LoadingComponent = (
    <div data-testid={testIds.loadingState}>
      <Repeat n={25}>
        <Skeleton className={classes.skeletonRow} animation="wave" />
      </Repeat>
    </div>
  );

  return (
    <div role="grid" className={classes.root} data-testid={testIds.root}>
      <div className={classes.headerRow} role="row">
        <Allotment defaultSizes={sizes} onChange={onSplitPaneChange}>
          {columns.map((column) => (
            <Allotment.Pane
              key={column.key}
              preferredSize={column.baseWidth}
              minSize={column.minWidth}
              maxSize={column.maxWidth}
            >
              <Box
                className={cx(classes.headerColumn, { [classes.sortableHeaderColumn]: !!column.sortFunction })}
                onClick={column.sortFunction ? () => onHeaderClick(column.key) : noop}
                data-testid={testIds.headerColumn}
                sx={{
                  justifyContent:
                    column.align === 'right' ? 'flex-end' : column.align === 'center' ? 'center' : 'flex-start'
                }}
              >
                <span className={classes.headerColumnText}>{column.label}</span>
                {sort?.column === column.key && (
                  <>
                    {sort.direction === SortDirection.ASC && (
                      <ArrowUpward fontSize="small" data-testid={testIds.sortAsc} />
                    )}
                    {sort.direction === SortDirection.DESC && (
                      <ArrowDownward fontSize="small" data-testid={testIds.sortDesc} />
                    )}
                  </>
                )}
                {sort?.column !== column.key && column.sortFunction && (
                  <ArrowUpward className={classes.hoverSort} fontSize="small" color="disabled" />
                )}
              </Box>
            </Allotment.Pane>
          ))}
        </Allotment>
      </div>
      <div className={classes.gridBody}>
        {useVirtualization && (
          <ShadowList
            loading={isLoading}
            data={sortedRows}
            LoadingComponent={LoadingComponent}
            itemContent={(index, row) => <Row key={String(row[rowKey])} row={row} />}
          />
        )}
        {!useVirtualization && (
          <ShadowScroller loading={isLoading}>
            {isLoading && LoadingComponent}
            {sortedRows?.map((row, key) => <Row key={`${key}-${row[rowKey]}`} row={row} />)}
          </ShadowScroller>
        )}
      </div>
    </div>
  );
}
