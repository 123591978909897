import React from 'react';
import { Typography } from '@mui/material';
import { TournamentSplashHeroUiModuleBody } from '../../../../../API';
import { useLocales } from '../../../../../hooks';
import Localized from '../../../../shared/Localized';
import LeagueBadge from '../../../../Sports/LeagueBadge';
import { TournamentSplashHeroPreview } from '../../ContentPreview/TournamentSplashHeroPreview';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';

export type TournamentSplashHeroListItemProps = IBaseUIModuleListItemProps<TournamentSplashHeroUiModuleBody>;

export const testIds = {
  treatment: 'tournament-splash-hero-list-item.treatment',
  leagueId: 'tournament-splash-hero-list-item.league-id',
  preview: 'tournament-splash-hero-list-item.preview'
};

const TournamentSplashHeroListItem = ({ uiModule, ...props }: TournamentSplashHeroListItemProps): JSX.Element => {
  const { t } = useLocales();

  return (
    <UIModuleListItem
      uiModule={uiModule}
      topLineTitle={<Localized prop={uiModule.title} />}
      collectionLine={[
        <>
          <Typography variant="body2" color="textSecondary">
            {t('sports.league')}:{' '}
          </Typography>
          <LeagueBadge leagueId={uiModule.tournamentReferenceId} data-testid={testIds.leagueId} />
        </>
      ]}
      renderPreview={(showPreview) => (
        <div data-testid={testIds.preview}>
          <TournamentSplashHeroPreview tournamentId={uiModule.tournamentReferenceId as string} showing={showPreview} />
        </div>
      )}
      {...props}
    />
  );
};

export default TournamentSplashHeroListItem;
