import React, { useState, useMemo } from 'react';
import { Collapse, FormControlLabel, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Check, Close, ExpandLess, ExpandMore } from '@mui/icons-material';
import { AssetTypes } from '../../../../utils/assetTypes';
import AssetBrowser from '../../../Assets/AssetBrowser';
import { useLocales, useTheme } from '../../../../hooks';
import { URL_REGEX } from '../../../../utils/regex';
import TextValidator from '../../../shared/TextValidator';
import ClearableDateTimePicker from '../../../shared/ClearableDateTimePicker';
import { allRequired, isDateValid, markAsRequired } from '../../../../utils/formHelpers';
import AssetImage from '../../../shared/AssetImage';
import { Presets, PresetDataType } from '../../../shared/Presets';
import { DataPresetType, SponsorDataPresetDataBody } from '../../../../API';
import { Controller, useFormContext } from 'react-hook-form';
import { isAfter, isBefore } from '../../../../utils/dateHelpers';
import { DateTime } from 'luxon';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(2, 4),
    marginBottom: theme.spacing(4),
    border: `1px solid ${theme.palette.background.paper}`,
    borderRadius: 5
  },
  formField: {
    display: 'inline-flex',
    flexDirection: 'column',
    width: '50%',
    padding: theme.spacing(0, 6, 4, 0)
  },
  sponsorHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  sponsorHeaderDetails: {
    flexGrow: 1,
    display: 'flex'
  },
  sponsorCollapse: {
    paddingTop: theme.spacing(2)
  },
  trackingUrlField: {
    paddingBottom: 0
  }
}));

export type SponsorFields = {
  sponsorLogo?: string;
  trackingPixelUrl?: string;
  sponsorStartDate?: string;
  sponsorEndDate?: string;
};

export type SponsorSectionProps = {
  entityId: string;
  namePrefix?: string;
};

export function SponsorSection({ entityId, namePrefix }: SponsorSectionProps): JSX.Element {
  const { control, watch, setValue } = useFormContext();
  const { classes } = useStyles();
  const { t, currentLang } = useLocales();
  const { formControlColor } = useTheme();

  const getName = (sponsorField: keyof SponsorFields) => (namePrefix ? `${namePrefix}.${sponsorField}` : sponsorField);
  const setSponsorValue = (param: keyof SponsorFields, value: SponsorFields[keyof SponsorFields]) =>
    setValue(getName(param), value);

  const [sponsorLogo, trackingPixelUrl, sponsorStartDate, sponsorEndDate] = watch([
    getName('sponsorLogo'),
    getName('trackingPixelUrl'),
    getName('sponsorStartDate'),
    getName('sponsorEndDate')
  ]);

  const [sponsorVisible, setSponsorVisible] = useState(false);

  const sponsorDataPreset: SponsorDataPresetDataBody = useMemo(
    () => ({
      type: DataPresetType.SPONSOR,
      sponsorLogo,
      sponsorEndDate,
      sponsorStartDate,
      trackingPixelUrl
    }),
    [sponsorLogo, sponsorEndDate, sponsorStartDate, trackingPixelUrl]
  );

  const loadPresetData = (presetData: PresetDataType) => {
    const sponsorPresetData = presetData as SponsorFields;
    setSponsorValue('sponsorLogo', sponsorPresetData.sponsorLogo);
    setSponsorValue('trackingPixelUrl', sponsorPresetData.trackingPixelUrl);
    setSponsorValue('sponsorStartDate', sponsorPresetData.sponsorStartDate);
    setSponsorValue('sponsorEndDate', sponsorPresetData.sponsorEndDate);
  };

  const toggleSponsor = () => {
    setSponsorVisible(!sponsorVisible);
  };

  const renderFormLabel = (label: string, className?: string) => {
    return (
      <Typography component="div" color="textSecondary" variant="body2" className={className}>
        {label}
      </Typography>
    );
  };

  // if any of these are defined, they all must be defined
  const sponsorRequired = sponsorLogo || sponsorStartDate || sponsorEndDate;
  // required label based on sponsor required
  const required = (label: string) => (sponsorRequired ? markAsRequired(label) : label);

  const isValid =
    allRequired([sponsorLogo, sponsorStartDate, sponsorEndDate]) && isDateValid(sponsorStartDate, sponsorEndDate);

  const sponsorLabel = () => {
    if (isValid) {
      if (sponsorLogo) return <Typography>{t('layouts.sponsor.sponsor_configured')}</Typography>;
      return (
        <Typography style={{ fontStyle: 'italic' }} color="textSecondary">
          {t('layouts.sponsor.sponsor_blank')}
        </Typography>
      );
    }
    return (
      <Typography style={{ fontStyle: 'italic' }} color="textSecondary">
        {t('layouts.sponsor.sponsor_invalid')}
      </Typography>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.sponsorHeader} onClick={toggleSponsor}>
        <FormControlLabel label={sponsorLabel()} control={sponsorVisible ? <ExpandLess /> : <ExpandMore />} />
        <div className={classes.sponsorHeaderDetails}>
          {!sponsorVisible && isValid && <AssetImage path={sponsorLogo} />}
        </div>
        {isValid ? !!sponsorLogo && <Check color="success" /> : <Close color="error" />}
      </div>
      <Collapse in={sponsorVisible} timeout="auto">
        <div className={classes.sponsorCollapse}>
          <div className={classes.formField}>
            {renderFormLabel(required(t('layouts.sponsor.logo')))}
            <Controller
              control={control}
              name={getName('sponsorLogo')}
              rules={{ required: !isValid }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <AssetBrowser
                  assetId={`${entityId}-${AssetTypes.hero.sponsor}`}
                  assetType={AssetTypes.hero.sponsor}
                  value={value || ''}
                  hasError={!!error}
                  onChange={onChange}
                  acceptedFileTypes={{ 'image/png': ['.png'], 'image/svg+xml': ['.svg'] }}
                />
              )}
            />
          </div>
          <div className={classes.formField}>
            <Presets
              presetType={DataPresetType.SPONSOR}
              data={sponsorDataPreset}
              isValid={isValid}
              onChange={loadPresetData}
            />
          </div>
          <div className={classes.formField}>
            {renderFormLabel(required(t('layouts.sponsor.start_date')))}
            <Controller
              control={control}
              name={getName('sponsorStartDate')}
              rules={{
                validate: (value) => {
                  if (!sponsorLogo) return true;
                  if (!value) return t('date_picker.error_datetime');
                  if (isAfter(value, sponsorEndDate)) {
                    return t('date_picker.error_max_date', {
                      date: DateTime.fromISO(sponsorEndDate)
                        .setLocale(currentLang)
                        .toLocaleString(DateTime.DATETIME_SHORT)
                    });
                  }
                }
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <ClearableDateTimePicker
                  value={value}
                  onChange={onChange}
                  maxDateTime={sponsorEndDate}
                  fullWidth
                  showTimezones
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </div>
          <div className={classes.formField}>
            {renderFormLabel(required(t('layouts.sponsor.end_date')))}
            <Controller
              control={control}
              name={getName('sponsorEndDate')}
              rules={{
                validate: (value) => {
                  if (!sponsorLogo) return true;
                  if (!value) return t('date_picker.error_datetime');
                  if (isBefore(value, sponsorStartDate)) {
                    return t('date_picker.error_min_date', {
                      date: DateTime.fromISO(sponsorStartDate)
                        .setLocale(currentLang)
                        .toLocaleString(DateTime.DATETIME_SHORT)
                    });
                  }
                }
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <ClearableDateTimePicker
                  value={value}
                  onChange={onChange}
                  minDateTime={sponsorStartDate}
                  fullWidth
                  showTimezones
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </div>
          <div className={`${classes.formField} ${classes.trackingUrlField}`}>
            {renderFormLabel(t('layouts.sponsor.tracking_url'))}
            <Controller
              control={control}
              name={getName('trackingPixelUrl')}
              render={({ field: { value, onChange } }) => (
                <TextValidator
                  debounced={false}
                  fullWidth
                  color={formControlColor}
                  name="trackingPixelUrl"
                  value={value || ''}
                  validators={[`matchRegexp:${URL_REGEX}`]}
                  onChange={onChange}
                  errorMessages={[t('general.field_match_url')]}
                />
              )}
            />
          </div>
        </div>
      </Collapse>
    </div>
  );
}
