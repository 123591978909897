import { atom, selector } from 'recoil';
import { withAllLayouts, withLayoutSelectedCountries } from './withLayouts';
import {
  PageLayoutResponseV2,
  PageLayoutBodyV2,
  DocumentLocale,
  LayoutType,
  LayoutValidationResult,
  Page
} from '../../API';
import { DRAFT_LAYOUTS, FUTURE_LAYOUTS, PAST_LAYOUTS, withLayoutsType } from '.';
import { cloneDeep } from 'lodash-es';
import { COUNTRIES_BY_ID } from '../../utils/countryCodes';
import { DateTime } from 'luxon';
import { UIModuleFormMetadata } from '../../utils/layouts';

function blankLayout(urlPath: string, countries: string[], layoutType: LayoutType): PageLayoutBodyV2 {
  return {
    urlPath,
    layoutType,
    pageName: {
      [DocumentLocale.ES]: ''
    },
    seoDescription: {
      [DocumentLocale.ES]: ''
    },
    countries,
    editorNotes: '',
    uiModules: []
  };
}

function getDefaultStartTime() {
  return DateTime.now().startOf('day').plus({ day: 1 }).toISO() || ''; // Default to midnight of next day
}

function sanitizeLayoutCountries(layout: PageLayoutResponseV2) {
  return { ...layout, countries: layout.countries.filter((country) => COUNTRIES_BY_ID[country]?.supported) };
}

export const withShowLayoutForm = atom<boolean>({
  key: 'layouts.showLayoutForm',
  default: false
});

export const withLayout = atom<PageLayoutResponseV2 | PageLayoutBodyV2 | undefined>({
  key: 'layouts.layout',
  default: undefined
});

export const withIsNewLayout = atom<boolean>({
  key: 'layouts.isNewLayout',
  default: false
});

export const withIsCloningLayout = atom<boolean>({
  key: 'layouts.isCloningLayout',
  default: false
});

export const withIsSavingLayout = atom<boolean>({
  key: 'layouts.isSavingLayout',
  default: false
});

export const withLayoutWarnings = atom<LayoutValidationResult | undefined>({
  key: 'layouts.layoutWarnings',
  default: undefined
});

export const withShowLayoutWarnings = atom<boolean>({
  key: 'layouts.showLayoutWarnings',
  default: false
});

export const withShouldSubmitLayoutFormWithWarnings = atom<boolean>({
  key: 'layouts.shuldSubmitLayoutFormWithWarnings',
  default: false
});

export const withNewLayout = selector<Page>({
  key: 'layouts.newLayout',
  get: () => ({}) as Page, // unused
  set: ({ get, set }, page) => {
    set(withLayout, blankLayout((page as Page).urlPath, get(withLayoutSelectedCountries), get(withLayoutsType)));
    set(withIsNewLayout, true);
    set(withIsCloningLayout, false);
    set(withShowLayoutForm, true);
    set(withLayoutErrors, []);
  }
});

export type CloningLayout = { id: string; canPublish: boolean };

export const withSetCloningLayoutId = selector<CloningLayout>({
  key: 'layouts.setCloningLayoutId',
  get: () => ({}) as CloningLayout, // unused
  set: ({ set, get }, cloningLayout) => {
    const { id, canPublish } = cloningLayout as CloningLayout;
    const allLayouts = get(withAllLayouts);
    if (allLayouts) {
      const foundLayout =
        allLayouts[FUTURE_LAYOUTS].find((layout) => layout.id === id) ||
        allLayouts[PAST_LAYOUTS].find((layout) => layout.id === id) ||
        allLayouts[DRAFT_LAYOUTS].find((layout) => layout.id === id);
      if (!foundLayout) return;
      const layoutCopy = sanitizeLayoutCountries(cloneDeep(foundLayout)) as PageLayoutResponseV2;
      layoutCopy.startTime = canPublish ? getDefaultStartTime() : undefined;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (layoutCopy as any).id;
      set(withLayout, layoutCopy as PageLayoutBodyV2);
      set(withIsNewLayout, true);
      set(withIsCloningLayout, true);
      set(withShowLayoutForm, true);
    }
  }
});

export const withSetEditingLayoutId = selector<string>({
  key: 'layouts.setEditingLayoutId',
  get: () => '', // unused
  set: ({ set, get }, id) => {
    const allLayouts = get(withAllLayouts);
    if (allLayouts) {
      const layout =
        (allLayouts[FUTURE_LAYOUTS].find((layout) => layout.id === id) as PageLayoutResponseV2) ||
        (allLayouts[DRAFT_LAYOUTS].find((layout) => layout.id === id) as PageLayoutResponseV2);
      set(withLayout, cloneDeep(layout) as PageLayoutResponseV2);
      set(withIsNewLayout, false);
      set(withIsCloningLayout, false);
      set(withShowLayoutForm, true);
    }
  }
});

export const withLayoutFormMetadata = atom<UIModuleFormMetadata>({
  key: 'layouts.withLayoutFormMetadata',
  default: {} as UIModuleFormMetadata
});

export const withLayoutErrors = atom<string[]>({
  key: 'layouts.withLayoutErrors',
  default: []
});
