import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { themeColors } from '../../../../../theme';

export const VixOrangeButton: React.FC<ButtonProps> = ({ children, sx, ...props }) => (
  <Button
    sx={{
      '&, &:hover, &:active': {
        backgroundColor: themeColors.orange,
        color: 'white'
      },
      ...sx
    }}
    {...props}
  >
    {children}
  </Button>
);

export const TextButton: React.FC<ButtonProps> = ({ children, ...props }) => (
  <Button variant="text" color="inherit" fullWidth {...props}>
    {children}
  </Button>
);
