import { SxProps, Theme, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useData } from '../../../data-layer';
import { useLocales, useTimezones } from '../../../hooks';
import { UserAvatar } from '../../Users/UserAvatar';
import { isSameDateTime } from '../../../utils/dateHelpers';

type AuthoredRecord = {
  createdDate?: string;
  updatedDate?: string;
  authorId: string;
  createdBy?: string;
  entityCreatedDate?: string;
};

export interface IUserBadgeProps {
  record: AuthoredRecord | undefined;
  sx?: SxProps<Theme>;
  useEntityCreatedDate?: boolean;
  'data-testid'?: string;
}

const useStyles = makeStyles()(() => ({
  tooltipContainer: {
    textAlign: 'center'
  }
}));

export function UserBadge({ record, useEntityCreatedDate, ...props }: IUserBadgeProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { timestamp } = useTimezones();
  const {
    users: {
      hook: { queueIdToFetch },
      state: { withRecordById }
    }
  } = useData();
  const {
    authorId: updatedBy,
    createdBy: entityCreatedBy,
    updatedDate,
    createdDate: revisionCreatedDate,
    entityCreatedDate
  } = record || {};

  const createdBy = useEntityCreatedDate ? entityCreatedBy : updatedBy;

  const createdByUser = useRecoilValue(withRecordById(createdBy));
  const updatedByUser = useRecoilValue(withRecordById(updatedBy));

  const createdDate = useEntityCreatedDate ? entityCreatedDate : revisionCreatedDate;
  const avatarUser = updatedByUser || createdByUser;

  useEffect(() => {
    if (!createdByUser && createdBy) {
      queueIdToFetch(createdBy, true);
    }
    if (updatedBy && updatedBy !== createdBy && !updatedByUser) {
      queueIdToFetch(updatedBy, true);
    }
  }, [createdBy, updatedBy]);

  if (!avatarUser) {
    return <></>;
  }

  return (
    <Tooltip
      arrow
      title={
        <div>
          {createdByUser && record && (
            <div className={classes.tooltipContainer}>
              {createdDate && <div>{t('general.created_on', { date: timestamp(createdDate) })}</div>}
              <div>{t('general.by_user', { user: `${createdByUser.firstName} ${createdByUser.lastName}` })}</div>

              {updatedDate && (createdBy !== updatedBy || !isSameDateTime(updatedDate, createdDate)) && (
                <>
                  <div>{t('general.updated_on', { date: timestamp(updatedDate) })}</div>
                  {updatedByUser && (
                    <div>{t('general.by_user', { user: `${updatedByUser.firstName} ${updatedByUser.lastName}` })}</div>
                  )}
                </>
              )}
            </div>
          )}
          {!createdByUser && t('general.loading')}
        </div>
      }
    >
      <div>
        <UserAvatar user={avatarUser} {...props} />
      </div>
    </Tooltip>
  );
}
