/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiPaginatedResponseOfVllChannelResponse } from '../model';
// @ts-ignore
import { ApiResponseOfVllChannelResponse } from '../model';
// @ts-ignore
import { ApiResponseOfVllChannelResponseArray } from '../model';
// @ts-ignore
import { DeleteChannelErrorResponse } from '../model';
// @ts-ignore
import { SortDirection } from '../model';
// @ts-ignore
import { VersionedDocumentStatus } from '../model';
// @ts-ignore
import { VllChannelBody } from '../model';
/**
 * ChannelsApi - axios parameter creator
 * @export
 */
export const ChannelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [maxResults] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCsv: async (maxResults?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/vll/channels/exportCsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the latest version of a specific **channel** from the **Vix CMS** application.
         * @param {string} entityId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestRevision: async (entityId: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getLatestRevision', 'entityId', entityId)
            const localVarPath = `/api/v1/vll/channels/{entityId}/revisions/latest`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} channelIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestRevisionsByIds: async (channelIds: Array<string>, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelIds' is not null or undefined
            assertParamExists('getLatestRevisionsByIds', 'channelIds', channelIds)
            const localVarPath = `/api/v1/vll/channels/revisions/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (channelIds) {
                localVarQueryParameter['channelIds'] = channelIds;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all the **channels** available in the **Vix CMS** application.
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {Array<string>} [countries] 
         * @param {Array<string>} [allowedCountries] 
         * @param {boolean} [isLatestPublishedRevision] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated: async (limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, countries?: Array<string>, allowedCountries?: Array<string>, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/vll/channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (authorId !== undefined) {
                localVarQueryParameter['authorId'] = authorId;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (allowedCountries) {
                localVarQueryParameter['allowedCountries'] = allowedCountries;
            }

            if (isLatestPublishedRevision !== undefined) {
                localVarQueryParameter['isLatestPublishedRevision'] = isLatestPublishedRevision;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the contents of a specific version of a **channel** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {string} revisionId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevision: async (entityId: string, revisionId: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getRevision', 'entityId', entityId)
            // verify required parameter 'revisionId' is not null or undefined
            assertParamExists('getRevision', 'revisionId', revisionId)
            const localVarPath = `/api/v1/vll/channels/{entityId}/revisions/{revisionId}`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"revisionId"}}`, encodeURIComponent(String(revisionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all the versions of a specific **channel** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {Array<string>} [countries] 
         * @param {Array<string>} [allowedCountries] 
         * @param {boolean} [isLatestPublishedRevision] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevisions: async (entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, countries?: Array<string>, allowedCountries?: Array<string>, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getRevisions', 'entityId', entityId)
            const localVarPath = `/api/v1/vll/channels/{entityId}/revisions`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (authorId !== undefined) {
                localVarQueryParameter['authorId'] = authorId;
            }

            if (countries) {
                localVarQueryParameter['countries'] = countries;
            }

            if (allowedCountries) {
                localVarQueryParameter['allowedCountries'] = allowedCountries;
            }

            if (isLatestPublishedRevision !== undefined) {
                localVarQueryParameter['isLatestPublishedRevision'] = isLatestPublishedRevision;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Changes the status of the **channel** from `DRAFT` to `PUBLISHED` in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publish: async (entityId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('publish', 'entityId', entityId)
            const localVarPath = `/api/v1/vll/channels/{entityId}/publish`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (entityId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('remove', 'entityId', entityId)
            const localVarPath = `/api/v1/vll/channels/{entityId}`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} entityId 
         * @param {VllChannelBody} vllChannelBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (entityId: string, vllChannelBody: VllChannelBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('update', 'entityId', entityId)
            // verify required parameter 'vllChannelBody' is not null or undefined
            assertParamExists('update', 'vllChannelBody', vllChannelBody)
            const localVarPath = `/api/v1/vll/channels/{entityId}/revisions/latest`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vllChannelBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChannelsApi - functional programming interface
 * @export
 */
export const ChannelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChannelsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [maxResults] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportCsv(maxResults?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportCsv(maxResults, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the latest version of a specific **channel** from the **Vix CMS** application.
         * @param {string} entityId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestRevision(entityId: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfVllChannelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestRevision(entityId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} channelIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestRevisionsByIds(channelIds: Array<string>, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfVllChannelResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestRevisionsByIds(channelIds, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all the **channels** available in the **Vix CMS** application.
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {Array<string>} [countries] 
         * @param {Array<string>} [allowedCountries] 
         * @param {boolean} [isLatestPublishedRevision] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, countries?: Array<string>, allowedCountries?: Array<string>, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfVllChannelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginated(limit, page, sortBy, sortDirection, status, authorId, countries, allowedCountries, isLatestPublishedRevision, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the contents of a specific version of a **channel** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {string} revisionId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRevision(entityId: string, revisionId: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfVllChannelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRevision(entityId, revisionId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all the versions of a specific **channel** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {Array<string>} [countries] 
         * @param {Array<string>} [allowedCountries] 
         * @param {boolean} [isLatestPublishedRevision] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRevisions(entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, countries?: Array<string>, allowedCountries?: Array<string>, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfVllChannelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRevisions(entityId, limit, page, sortBy, sortDirection, status, authorId, countries, allowedCountries, isLatestPublishedRevision, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Changes the status of the **channel** from `DRAFT` to `PUBLISHED` in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publish(entityId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfVllChannelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publish(entityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(entityId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(entityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} entityId 
         * @param {VllChannelBody} vllChannelBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(entityId: string, vllChannelBody: VllChannelBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfVllChannelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(entityId, vllChannelBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChannelsApi - factory interface
 * @export
 */
export const ChannelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChannelsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [maxResults] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCsv(maxResults?: number, options?: any): AxiosPromise<void> {
            return localVarFp.exportCsv(maxResults, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the latest version of a specific **channel** from the **Vix CMS** application.
         * @param {string} entityId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestRevision(entityId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfVllChannelResponse> {
            return localVarFp.getLatestRevision(entityId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} channelIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestRevisionsByIds(channelIds: Array<string>, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfVllChannelResponseArray> {
            return localVarFp.getLatestRevisionsByIds(channelIds, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all the **channels** available in the **Vix CMS** application.
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {Array<string>} [countries] 
         * @param {Array<string>} [allowedCountries] 
         * @param {boolean} [isLatestPublishedRevision] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, countries?: Array<string>, allowedCountries?: Array<string>, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfVllChannelResponse> {
            return localVarFp.getPaginated(limit, page, sortBy, sortDirection, status, authorId, countries, allowedCountries, isLatestPublishedRevision, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the contents of a specific version of a **channel** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {string} revisionId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevision(entityId: string, revisionId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfVllChannelResponse> {
            return localVarFp.getRevision(entityId, revisionId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all the versions of a specific **channel** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {Array<string>} [countries] 
         * @param {Array<string>} [allowedCountries] 
         * @param {boolean} [isLatestPublishedRevision] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevisions(entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, countries?: Array<string>, allowedCountries?: Array<string>, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfVllChannelResponse> {
            return localVarFp.getRevisions(entityId, limit, page, sortBy, sortDirection, status, authorId, countries, allowedCountries, isLatestPublishedRevision, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * Changes the status of the **channel** from `DRAFT` to `PUBLISHED` in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publish(entityId: string, options?: any): AxiosPromise<ApiResponseOfVllChannelResponse> {
            return localVarFp.publish(entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(entityId: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} entityId 
         * @param {VllChannelBody} vllChannelBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(entityId: string, vllChannelBody: VllChannelBody, options?: any): AxiosPromise<ApiResponseOfVllChannelResponse> {
            return localVarFp.update(entityId, vllChannelBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChannelsApi - interface
 * @export
 * @interface ChannelsApi
 */
export interface ChannelsApiInterface {
    /**
     * 
     * @param {number} [maxResults] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApiInterface
     */
    exportCsv(maxResults?: number, options?: any): AxiosPromise<void>;

    /**
     * Retrieves the latest version of a specific **channel** from the **Vix CMS** application.
     * @param {string} entityId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApiInterface
     */
    getLatestRevision(entityId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfVllChannelResponse>;

    /**
     * 
     * @param {Array<string>} channelIds 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApiInterface
     */
    getLatestRevisionsByIds(channelIds: Array<string>, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfVllChannelResponseArray>;

    /**
     * Retrieves all the **channels** available in the **Vix CMS** application.
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {VersionedDocumentStatus} [status] 
     * @param {string} [authorId] 
     * @param {Array<string>} [countries] 
     * @param {Array<string>} [allowedCountries] 
     * @param {boolean} [isLatestPublishedRevision] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApiInterface
     */
    getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, countries?: Array<string>, allowedCountries?: Array<string>, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfVllChannelResponse>;

    /**
     * Retrieves the contents of a specific version of a **channel** in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {string} revisionId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApiInterface
     */
    getRevision(entityId: string, revisionId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfVllChannelResponse>;

    /**
     * Retrieves all the versions of a specific **channel** in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {VersionedDocumentStatus} [status] 
     * @param {string} [authorId] 
     * @param {Array<string>} [countries] 
     * @param {Array<string>} [allowedCountries] 
     * @param {boolean} [isLatestPublishedRevision] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApiInterface
     */
    getRevisions(entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, countries?: Array<string>, allowedCountries?: Array<string>, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfVllChannelResponse>;

    /**
     * Changes the status of the **channel** from `DRAFT` to `PUBLISHED` in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApiInterface
     */
    publish(entityId: string, options?: any): AxiosPromise<ApiResponseOfVllChannelResponse>;

    /**
     * 
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApiInterface
     */
    remove(entityId: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {string} entityId 
     * @param {VllChannelBody} vllChannelBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApiInterface
     */
    update(entityId: string, vllChannelBody: VllChannelBody, options?: any): AxiosPromise<ApiResponseOfVllChannelResponse>;

}

/**
 * ChannelsApi - object-oriented interface
 * @export
 * @class ChannelsApi
 * @extends {BaseAPI}
 */
export class ChannelsApi extends BaseAPI implements ChannelsApiInterface {
    /**
     * 
     * @param {number} [maxResults] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApi
     */
    public exportCsv(maxResults?: number, options?: any) {
        return ChannelsApiFp(this.configuration).exportCsv(maxResults, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the latest version of a specific **channel** from the **Vix CMS** application.
     * @param {string} entityId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApi
     */
    public getLatestRevision(entityId: string, includeDeleted?: boolean, options?: any) {
        return ChannelsApiFp(this.configuration).getLatestRevision(entityId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} channelIds 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApi
     */
    public getLatestRevisionsByIds(channelIds: Array<string>, includeDeleted?: boolean, options?: any) {
        return ChannelsApiFp(this.configuration).getLatestRevisionsByIds(channelIds, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all the **channels** available in the **Vix CMS** application.
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {VersionedDocumentStatus} [status] 
     * @param {string} [authorId] 
     * @param {Array<string>} [countries] 
     * @param {Array<string>} [allowedCountries] 
     * @param {boolean} [isLatestPublishedRevision] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApi
     */
    public getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, countries?: Array<string>, allowedCountries?: Array<string>, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, options?: any) {
        return ChannelsApiFp(this.configuration).getPaginated(limit, page, sortBy, sortDirection, status, authorId, countries, allowedCountries, isLatestPublishedRevision, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the contents of a specific version of a **channel** in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {string} revisionId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApi
     */
    public getRevision(entityId: string, revisionId: string, includeDeleted?: boolean, options?: any) {
        return ChannelsApiFp(this.configuration).getRevision(entityId, revisionId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all the versions of a specific **channel** in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {VersionedDocumentStatus} [status] 
     * @param {string} [authorId] 
     * @param {Array<string>} [countries] 
     * @param {Array<string>} [allowedCountries] 
     * @param {boolean} [isLatestPublishedRevision] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApi
     */
    public getRevisions(entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, countries?: Array<string>, allowedCountries?: Array<string>, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, options?: any) {
        return ChannelsApiFp(this.configuration).getRevisions(entityId, limit, page, sortBy, sortDirection, status, authorId, countries, allowedCountries, isLatestPublishedRevision, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Changes the status of the **channel** from `DRAFT` to `PUBLISHED` in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApi
     */
    public publish(entityId: string, options?: any) {
        return ChannelsApiFp(this.configuration).publish(entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApi
     */
    public remove(entityId: string, options?: any) {
        return ChannelsApiFp(this.configuration).remove(entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} entityId 
     * @param {VllChannelBody} vllChannelBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApi
     */
    public update(entityId: string, vllChannelBody: VllChannelBody, options?: any) {
        return ChannelsApiFp(this.configuration).update(entityId, vllChannelBody, options).then((request) => request(this.axios, this.basePath));
    }
}
