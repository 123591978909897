import React from 'react';
import { makeStyles } from 'tss-react/mui';

export interface ICustomIconProps {
  disabled?: boolean;
  pathString: string;
  viewBox: string;
  'data-testid'?: string;
}

const useStyles = makeStyles()((theme) => ({
  icon: {
    '& path': {
      fill: theme.palette.text.primary
    }
  },
  disabled: {
    '& path': {
      opacity: 0.5,
      fill: theme.palette.text.disabled
    }
  }
}));

export function CustomIcon({ viewBox, pathString, disabled = false, ...props }: ICustomIconProps): React.ReactElement {
  const { classes } = useStyles();

  return (
    <svg
      className={`${classes.icon} ${disabled ? classes.disabled : ''}`}
      viewBox={viewBox}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-testid={props['data-testid']}
    >
      <g stroke="none" fill="none" fillRule="evenodd">
        <path d={pathString}></path>
      </g>
    </svg>
  );
}
