import { McpChannelResponse, DocumentLocale, VllChannelResponse, VllChannelType } from '../../../../API';
import { useChannelsAPI } from '../../../../hooks/API/EPG/useChannelsAPI';
import { CreateDataManager, DataManagerReturnType } from '../../../DataManager';
import { ChannelsHook, ChannelsHookReturnType } from './ChannelsHook';
import { ChannelsState, ChannelsStateFullReturnType } from './ChannelsState';

export type ChannelsDataManagerType = {
  state: ChannelsStateFullReturnType;
  hook: ChannelsHookReturnType;
};

export interface ChannelsDataManagerReturnType extends DataManagerReturnType<VllChannelResponse, VllChannelResponse> {
  state: ChannelsStateFullReturnType;
  hook: () => ChannelsHookReturnType;
}

const generateNewChannel = (contentServiceChannel: McpChannelResponse): VllChannelResponse => {
  const blankChannel = {
    defaultLocale: DocumentLocale.ES,
    title: {
      es: ''
    },
    description: {
      es: ''
    },
    logo: {
      es: ''
    },
    portraitImage: {
      es: ''
    },
    landscapeImage: {
      es: ''
    },
    metadata: {
      es: ''
    },
    type: VllChannelType.VLL,
    isLive: false
  } as VllChannelResponse;
  const { title, description, id, isLive, type, keywords, isPremium, contentGroups, channelSource } =
    contentServiceChannel;
  return {
    ...blankChannel,
    title,
    description,
    entityId: id,
    isLive,
    type,
    keywords,
    isPremium,
    contentGroups,
    channelSource
  };
};

export function constructChannelsBucketId({
  layoutCountries,
  allowedCountries
}: {
  layoutCountries?: string[];
  allowedCountries?: string[];
}): string {
  let id = '';
  if (layoutCountries) id += layoutCountries.join('-');
  id += ';';
  if (allowedCountries) id += allowedCountries.join('-');
  return id;
}

// desconstruct the bucketId
function bucketIdToQueryParams(bucketId: string): Array<string[] | undefined> {
  return [
    undefined,
    undefined,
    undefined,
    undefined,
    ...bucketId.split(';').map((part) => (part ? part.split('-') : undefined))
  ];
}

export const ChannelsDataManager = CreateDataManager<
  VllChannelResponse,
  VllChannelResponse,
  ChannelsDataManagerReturnType
>({
  name: 'channels',
  idField: 'entityId',
  isVersionedEntity: true,
  useApiHook: useChannelsAPI,
  generateNew: generateNewChannel,
  useUpdateForCreate: true,
  bucketIdToQueryParams,
  hookInitializer: ChannelsHook,
  stateInitializer: ChannelsState,
  toString: (record, locales) => locales.localize(record.title)
});
