import React from 'react';
import { Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { withCurrentUser, withCurrentUserPermissionsByGroup } from '../../../state/auth';
import { PermissionsGroupId, Role } from '../../../API';
import { useTheme } from '../../../hooks';

export const testIds = {
  root: 'permissions-group-selector.root',
  option: 'permissions-group-selector.option'
};

type PermissionsGroupSelectorProps = {
  value?: PermissionsGroupId;
  required?: boolean;
  onChange: (groupId: PermissionsGroupId | undefined) => void;
  error?: boolean;
};

export const PERMISSIONS_GROUP_NONE_OPTION = 'NONE';

export function PermissionsGroupSelector({
  value,
  onChange,
  required,
  error
}: PermissionsGroupSelectorProps): JSX.Element {
  const { t } = useTranslation();
  const { formControlColor } = useTheme();
  const userPermissionsByGroup = useRecoilValue(withCurrentUserPermissionsByGroup);
  const currentUser = useRecoilValue(withCurrentUser);
  const permissionGroupIds = Object.values(PermissionsGroupId).filter((group) => userPermissionsByGroup[group]?.UPSERT);
  const currentUserPermissionsGroups = currentUser?.permissionsGroups?.map((group) => group.group);

  let permissionGroupIdsFiltered = permissionGroupIds;

  if (!currentUser?.roles?.includes(Role.ADMIN)) {
    permissionGroupIdsFiltered = permissionGroupIds.filter((group) => currentUserPermissionsGroups?.includes(group));
  }

  const handleOnChange = (value: string) => {
    if (permissionGroupIdsFiltered.includes(value as PermissionsGroupId)) {
      onChange(value as PermissionsGroupId);
      return;
    }
    onChange(undefined);
  };

  const selectedValue = permissionGroupIdsFiltered.includes(value as PermissionsGroupId)
    ? value
    : PERMISSIONS_GROUP_NONE_OPTION;

  return (
    <Select
      label={t('permissions.permissions_group')}
      value={selectedValue}
      onChange={({ target: { value } }) => handleOnChange(value)}
      data-testid={testIds.root}
      error={error}
      color={formControlColor}
    >
      <MenuItem value={PERMISSIONS_GROUP_NONE_OPTION} data-testid={testIds.option} disabled={required}>
        <em>{t('general.none')}</em>
      </MenuItem>
      {permissionGroupIdsFiltered.map((option) => (
        <MenuItem key={option} value={option} data-testid={testIds.option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}
