import { DocumentLocale, EntitlementType, ProfileUiModuleBody, ProfileUiModuleBodyModuleTypeEnum } from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction, DynamicModuleFunction } from '../../../utils/layouts';

export const blankProfileModule: NewModuleFunction<ProfileUiModuleBody> = (module) => ({
  moduleType: ProfileUiModuleBodyModuleTypeEnum.PROFILE,
  moduleId: generateId(),
  title: {
    [DocumentLocale.ES]: ''
  },
  entitlements: [EntitlementType.ANON, EntitlementType.FREE, EntitlementType.SUB],
  portraitFillImage: '',
  landscapeFillImage: '',
  header: '',
  subheader: '',
  spinPremia: {
    id: generateId()
  },
  ...module
});

export const setDynamicFieldsOnProfileModule: DynamicModuleFunction<ProfileUiModuleBody> = (module) =>
  <ProfileUiModuleBody>{
    ...module,
    ...(!module.spinPremia?.id && {
      spinPremia: {
        ...module?.spinPremia,
        id: generateId()
      }
    })
  };
