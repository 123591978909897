export const LEAGUE_ID_PARAM = ':leagueId';
export const COLLECTION_ID_PARAM = ':collectionId';
export const LEAGUE_ID_ALL = '(all leagues)';
export const isDynamicLeaguePage = (pagePath: string): boolean => pagePath.indexOf(LEAGUE_ID_PARAM) > 0;
export const isDynamicCollectionPage = (pagePath: string): boolean => pagePath.indexOf(COLLECTION_ID_PARAM) > 0;
export const stringToNumber = (value?: string): number | undefined => Number(value) || undefined;
export const stringToNumberValidated = (value: string | undefined, min = 0, max = 9999): number | null => {
  const parsedNumber = Number(value);
  return isNaN(parsedNumber) || parsedNumber < min || parsedNumber > max ? null : Number(value);
};
