import { useCallback, useEffect, useState } from 'react';

export interface UseImageParams {
  src: string;
  onLoad?: (event: Event) => unknown;
  onError?: (event: Event) => unknown;
}

export interface UseImageReturn {
  src: string;
  isLoading: boolean;
  error: boolean;
}

export function useImage({ src, onLoad: propOnLoad, onError: propOnError }: UseImageParams): UseImageReturn {
  const [image, setImage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const onLoad = useCallback(
    (event: Event) => {
      setImage(src);
      setIsLoading(false);
      setError(false);
      propOnLoad?.(event);
    },
    [src]
  );

  const onError = useCallback(
    (event: Event) => {
      setError(true);
      propOnError?.(event);
    },
    [src]
  );

  useEffect(() => {
    setImage('');
    setIsLoading(true);
    setError(false);

    const img = new Image();
    img.src = src;
    img.addEventListener('load', onLoad);
    img.addEventListener('error', onError);

    return () => {
      img.removeEventListener('load', onLoad);
      img.removeEventListener('error', onError);
    };
  }, [src, onLoad, onError]);

  return { src: image, isLoading, error };
}
