import { EntitlementType, PlaybackPaywallUiModuleBody, PlaybackPaywallUiModuleBodyModuleTypeEnum } from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankPlaybackPaywallModule: NewModuleFunction<PlaybackPaywallUiModuleBody> = (module) => ({
  moduleType: PlaybackPaywallUiModuleBodyModuleTypeEnum.PLAYBACK_PAYWALL,
  moduleId: generateId(),
  entitlements: [EntitlementType.ANON, EntitlementType.FREE, EntitlementType.SUB],
  postTrailerPrimaryText: '',
  postTrailerPrimaryCta: '',
  postEpisodePrimaryCta: '',
  ...module
});
