import React from 'react';
import { InputLabel, Select, MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../../../hooks';
import { SetterOrUpdater } from 'recoil';
import FormControl from '../../../shared/FormControl';

export interface ITreatmentPickerProps<T> {
  options: T;
  value: keyof T;
  onChange: SetterOrUpdater<keyof T>;
  disabled?: boolean;
}

const useStyles = makeStyles()(() => ({
  selector: {
    minWidth: 260
  }
}));

export const testIds = {
  treatmentSelector: 'treatment-selector',
  treatmentSelectorItem: 'treatment-selector.menu-item'
};

export function TreatmentPicker<T extends Record<string, string>>({
  options,
  value,
  onChange,
  disabled = false
}: ITreatmentPickerProps<T>): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();

  return (
    <FormControl>
      <InputLabel id="treatmentSelector">{t('layouts.treatment')}</InputLabel>
      <Select
        label={t('layouts.treatment')}
        className={classes.selector}
        labelId="treatmentSelector"
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
        data-testid={testIds.treatmentSelector}
        disabled={disabled}
      >
        {Object.values(options).map((value) => (
          <MenuItem key={value} value={value} data-testid={testIds.treatmentSelectorItem} data-treatment-id={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
