import React, { useEffect } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { SetterOrUpdater } from 'recoil';
import ClearableDateTimePicker from '../ClearableDateTimePicker';
import { useLocales } from '../../../hooks';
import { makeStyles } from 'tss-react/mui';
import { toLuxonDate } from '../../../utils/dateHelpers';

export const testIds = {
  startCheck: 'dtr.start-check',
  endCheck: 'dtr.end-check',
  startTime: 'dtr.start-time',
  endTime: 'dtr.end-time'
};

type StringStateType = [string | undefined, SetterOrUpdater<string | undefined>];

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(4)
  },
  datetime: {
    width: '50%'
  }
}));

export type DateTimeRangeProps = {
  className?: string;
  startTimeState: StringStateType;
  endTimeState: StringStateType;
  minDate?: string;
  maxDate?: string;
  onChange?: (startTime?: string, endTime?: string) => void;
  disabled?: boolean;
  minDateRequired?: boolean;
  maxDateRequired?: boolean;
};

function DateTimeRange({
  className,
  startTimeState,
  endTimeState,
  minDate,
  maxDate,
  onChange,
  disabled,
  minDateRequired = false,
  maxDateRequired = false
}: DateTimeRangeProps): JSX.Element {
  const [startTime, setStartTime] = startTimeState;
  const [endTime, setEndTime] = endTimeState;
  const { t } = useLocales();

  const { classes, cx } = useStyles();

  useEffect(() => {
    if (onChange) onChange(startTime, endTime);
  }, [startTime, endTime]);

  const hasStartTime = () => startTime !== undefined;
  const hasEndTime = () => endTime !== undefined;

  const getDefaultStartDate = () => toLuxonDate(new Date()).plus({ minute: 1 }).toISO() || '';
  const getDefaultEndDate = () =>
    toLuxonDate(startTime || new Date())
      .plus({ week: 1 })
      .toISO() || '';

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.datetime}>
        <FormControlLabel
          label={t('date_picker.start_date')}
          control={
            <Checkbox
              color="secondary"
              checked={hasStartTime() || minDateRequired}
              data-testid={testIds.startCheck}
              onChange={({ target: { checked } }) => setStartTime(checked ? getDefaultStartDate() : undefined)}
              disabled={disabled || minDateRequired}
            />
          }
        />
        {(hasStartTime() || minDateRequired) && (
          <div data-testid={testIds.startTime}>
            <ClearableDateTimePicker
              showTimezones={true}
              value={startTime}
              onChange={setStartTime}
              minDateTime={minDate}
              maxDateTime={endTime}
              data-testid={testIds.startTime}
              disabled={disabled}
              clearable={!minDateRequired}
            />
          </div>
        )}
      </div>
      <div className={classes.datetime}>
        <FormControlLabel
          label={t('date_picker.end_date')}
          control={
            <Checkbox
              color="secondary"
              checked={hasEndTime() || maxDateRequired}
              data-testid={testIds.endCheck}
              onChange={({ target: { checked } }) => setEndTime(checked ? getDefaultEndDate() : undefined)}
              disabled={disabled || maxDateRequired}
            />
          }
        />
        {(hasEndTime() || maxDateRequired) && (
          <div data-testid={testIds.endTime}>
            <ClearableDateTimePicker
              showTimezones={true}
              value={endTime}
              onChange={setEndTime}
              minDateTime={startTime}
              maxDateTime={maxDate}
              data-testid={testIds.endTime}
              disabled={disabled}
              clearable={!maxDateRequired}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default DateTimeRange;
