/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum OperationIds {
    GET_CHANNELS_BY_IDS = 'getChannelsByIds',
    GET_PAGINATED = 'getPaginated',
    GET_ALL = 'getAll',
    GET_BY_ID = 'getById',
    GET_BY_IDS = 'getByIds',
    GET_TRANSMISSIONS_BY_IDS = 'getTransmissionsByIds',
    CREATE = 'create',
    UPDATE = 'update',
    REMOVE = 'remove',
    PUBLISH = 'publish',
    GET_LATEST_REVISION = 'getLatestRevision',
    GET_LATEST_REVISIONS_BY_IDS = 'getLatestRevisionsByIds',
    GET_REVISIONS = 'getRevisions',
    GET_REVISION = 'getRevision',
    GET_LATEST_PUBLISHED_REVISION = 'getLatestPublishedRevision',
    GET_LATEST_PUBLISHED_REVISIONS_BY_IDS = 'getLatestPublishedRevisionsByIds',
    IS_DELETABLE = 'isDeletable',
    EXPORT_CSV = 'exportCsv',
    PATCH = 'patch',
    EXPORT_ENTITY = 'exportEntity',
    IMPORT_ENTITY = 'importEntity'
}



