import React from 'react';
import { Chip, Tooltip } from '@mui/material';
import { SportBadge } from '../SportBadge';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../../hooks';

const useStyles = makeStyles()((theme) => ({
  tooltip: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    gap: theme.spacing(1),
    '& > .MuiChip-root': {
      background: 'none'
    }
  }
}));

interface SportsBadgeProps {
  sportIds: string[] | undefined;
  'data-testid'?: string;
}

export function SportsBadge({ sportIds, 'data-testid': dataTestId }: SportsBadgeProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();

  if (!sportIds || sportIds.length <= 1) {
    return <SportBadge sportId={sportIds?.[0]} data-testid={dataTestId} />;
  }

  const leagues = sportIds.map((sportId, index) => (
    <SportBadge key={index} sportId={sportId} data-testid={dataTestId} />
  ));

  return (
    <div>
      <Tooltip title={<div className={classes.tooltip}>{leagues}</div>} arrow data-testid={dataTestId}>
        <Chip label={t('sports.n_sports', { n: sportIds.length })} size="small" data-testid={dataTestId} />
      </Tooltip>
    </div>
  );
}
