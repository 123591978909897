import { PageLayoutResponseV2 } from '../API';
import { FUTURE_LAYOUTS, Layouts, PAST_LAYOUTS } from '../state/Layouts';
import { cloneDeep } from 'lodash-es';

type TimelineTrack = {
  countries: string[];
  starting?: boolean;
};

export type TimelineTracks = {
  tracks: TimelineTrack[];
  currentIndex: number;
  splits: number[][];
  merges: number[][];
};

type ReturnType = {
  tracksById: Record<string, TimelineTracks>;
  maxLength: number;
};

export function createTimelineTracks(allLayouts: Layouts, selectedCountries: string[]): ReturnType {
  const newTimelineTracks: Record<string, TimelineTracks> = {};
  const allTimelineTracks: TimelineTracks[] = [];
  let prevTracks: TimelineTracks;
  const selectedCountriesHash: Record<string, boolean> = {};
  selectedCountries.forEach((country) => (selectedCountriesHash[country] = true));
  const addLayout = (layout: PageLayoutResponseV2) => {
    const tracks = getTimelineTracks(layout, prevTracks, selectedCountriesHash);
    allTimelineTracks.push(tracks);
    newTimelineTracks[layout.id] = tracks;
    prevTracks = tracks;
  };
  allLayouts[PAST_LAYOUTS].forEach(addLayout);
  allLayouts[FUTURE_LAYOUTS].forEach(addLayout);
  return {
    tracksById: newTimelineTracks,
    maxLength: Math.max(...allTimelineTracks.map((tracks) => tracks.tracks.length))
  };
}

function filterCountries(countries: string[], filter: Record<string, boolean>) {
  return countries.filter((country) => filter[country]);
}

function initializeTracks(layout: PageLayoutResponseV2, selectedCountries: Record<string, boolean>) {
  return {
    tracks: [
      {
        countries: filterCountries(layout.countries, selectedCountries),
        starting: true
      }
    ],
    currentIndex: 0,
    splits: [],
    merges: []
  };
}

function pushNewStartingTrack(tracks: TimelineTracks, filteredCountries: string[]) {
  tracks.tracks.push({
    countries: filteredCountries,
    starting: true
  });
}

function handleNewTrack(tracks: TimelineTracks, filteredCountries: string[], trackIndexSet: number[]) {
  if (trackIndexSet[0] === undefined && trackIndexSet.length === 1) {
    // All countries in this layout belong to a new track
    pushNewStartingTrack(tracks, filteredCountries);
    tracks.currentIndex = tracks.tracks.length - 1;
    return;
  }
  // Countries belong to an existing track
  let curTrackIndex = trackIndexSet[0];
  if (curTrackIndex === undefined) curTrackIndex = trackIndexSet[1];
  const remainingCountries = getRemainingCountries(tracks, filteredCountries, curTrackIndex);
  if (remainingCountries.length) {
    // Some countries are not in the new layout (split required)
    tracks.tracks[curTrackIndex].countries = remainingCountries;
    pushNewStartingTrack(tracks, filteredCountries);
    tracks.currentIndex = tracks.tracks.length - 1;
    tracks.splits.push([curTrackIndex, tracks.tracks.length - 1]);
  } else {
    // All countries are in both layouts, so just continue it (no split)
    tracks.tracks[curTrackIndex].countries = filteredCountries;
    tracks.currentIndex = curTrackIndex;
  }
}

function createMergesAndSplits(
  tracks: TimelineTracks,
  indexToMergeInto: number,
  splitIndices: number[],
  mergeIndices: number[]
) {
  splitIndices.forEach((index) => {
    if (index !== indexToMergeInto) tracks.splits.push([index, indexToMergeInto]);
  });
  mergeIndices.forEach((index) => {
    if (index !== indexToMergeInto) tracks.merges.push([index, indexToMergeInto]);
  });
}

function handleMergeSplit(tracks: TimelineTracks, filteredCountries: string[], trackIndexSet: number[]) {
  trackIndexSet.sort();
  let indexToMergeInto = -1;
  const splitIndices: number[] = [];
  const mergeIndices: number[] = [];
  trackIndexSet.forEach((index) => {
    if (index === undefined) return;
    const remainingCountries = getRemainingCountries(tracks, filteredCountries, index);
    tracks.tracks[index].countries = remainingCountries;
    if (remainingCountries.length) {
      splitIndices.push(index);
    } else {
      if (indexToMergeInto === -1) indexToMergeInto = index;
      mergeIndices.push(index);
    }
  });
  if (indexToMergeInto === -1) {
    // Could not find an index to merge into, must create a new track
    pushNewStartingTrack(tracks, filteredCountries);
    indexToMergeInto = tracks.tracks.length - 1;
  } else {
    // Merge into this index
    tracks.tracks[indexToMergeInto].countries = filteredCountries;
  }
  tracks.currentIndex = indexToMergeInto;
  createMergesAndSplits(tracks, indexToMergeInto, splitIndices, mergeIndices);
}

function getRemainingCountries(tracks: TimelineTracks, filteredCountries: string[], index: number) {
  const remainingCountries: string[] = [];
  tracks.tracks[index].countries.forEach((country) => {
    if (filteredCountries.indexOf(country) === -1) {
      remainingCountries.push(country);
    }
  });
  return remainingCountries;
}

function createTrackIndexByCountry(tracks: TimelineTracks): Record<string, number> {
  const trackIndexByCountry: Record<string, number> = {};
  tracks.tracks.forEach((track: TimelineTrack, index: number) => {
    track.countries.forEach((country: string) => {
      trackIndexByCountry[country] = index;
    });
    track.starting = false;
  });
  return trackIndexByCountry;
}

function eraseTrailingEmptySlots(tracks: TimelineTracks) {
  for (let i = tracks.tracks.length - 1; i >= 0; i--) {
    if (tracks.tracks[i].countries.length) break;
    tracks.tracks = tracks.tracks.slice(0, i);
  }
}

function needsNewTrack(trackIndexSet: number[]) {
  return (
    trackIndexSet.length === 1 ||
    (trackIndexSet.length === 2 && (trackIndexSet[0] === undefined || trackIndexSet[1] === undefined))
  );
}

function getTimelineTracks(
  layout: PageLayoutResponseV2,
  prevTracks: TimelineTracks,
  selectedCountries: Record<string, boolean>
): TimelineTracks {
  if (!prevTracks) {
    return initializeTracks(layout, selectedCountries);
  }
  const tracks = cloneDeep(prevTracks) as TimelineTracks;
  tracks.splits = [];
  tracks.merges = [];

  const trackIndexByCountry = createTrackIndexByCountry(tracks);
  const filteredCountries = filterCountries(layout.countries, selectedCountries);
  const trackIndexSet = [...new Set(filteredCountries.map((country) => trackIndexByCountry[country]))];
  if (needsNewTrack(trackIndexSet)) {
    // All countries in this layout have the same track in common (or belong to no current track)
    handleNewTrack(tracks, filteredCountries, trackIndexSet);
  } else {
    // Countries in this layout come from different tracks
    handleMergeSplit(tracks, filteredCountries, trackIndexSet);
  }
  eraseTrailingEmptySlots(tracks);
  return tracks;
}
