import React, { RefObject, forwardRef, useState } from 'react';
import { Accordion, AccordionProps, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../../hooks';
import { ExpandMore } from '@mui/icons-material';

export interface FoldableSectionProps extends Omit<AccordionProps, 'children'> {
  className?: string;
  containerClassName?: string;
  children?: React.ReactNode;
  summary?: React.ReactNode;
  expanded?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  accordionSummaryContent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  accordionContainer: {
    backgroundColor: theme.palette.background.paper,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    padding: 0
  }
}));

export const testIds = {
  root: 'foldable-section.root',
  summary: 'foldable-section.summary'
};

export const FoldableSection = forwardRef(
  (
    { className, containerClassName, children, summary, expanded: enlarge = false, ...props }: FoldableSectionProps,
    ref?
  ): React.ReactElement => {
    const { classes, cx } = useStyles();
    const { t } = useLocales();

    const [expanded, setExpanded] = useState(enlarge || false);

    return (
      <Accordion
        expanded={expanded}
        onChange={(_, newExpanded) => setExpanded(newExpanded)}
        className={className}
        data-testid={testIds.root}
        ref={ref as unknown as RefObject<HTMLDivElement>}
        {...props}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <div className={classes.accordionSummaryContent} data-testid={testIds.summary}>
            {summary || (
              <Typography variant="body1">{t(expanded ? 'general.show_less' : 'general.show_more')}</Typography>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails className={cx(classes.accordionContainer, containerClassName)}>{children}</AccordionDetails>
      </Accordion>
    );
  }
);
FoldableSection.displayName = 'FoldableSection';
