import { findIndex } from 'lodash';
import { ALLOWED_KID_RATINGS } from '../../utils/consts/media';
import { useRecoilValue } from 'recoil';
import { useData } from '../../data-layer';

export type useRatingsReturnType = {
  isSuitableForKids: () => boolean;
};

export function useRatings(mediaId: string): useRatingsReturnType {
  const {
    media: {
      state: { withRecordById }
    }
  } = useData();
  const media = useRecoilValue(withRecordById(mediaId));

  const isSuitableForKids = () => {
    const index = findIndex(media?.ratings, (rate) => ALLOWED_KID_RATINGS.includes(rate.ratingValue));
    return index !== -1;
  };

  return {
    isSuitableForKids
  };
}
