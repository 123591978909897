import { DateTime } from 'luxon';
import { AutoRotateBody, CollectionAsset, ContentCollectionResponse } from '../API';
import { findIndex } from 'lodash';

export function autoRotateGetCurrentIndex(collection?: ContentCollectionResponse, simDate?: Date): number {
  if (!collection?.autoRotate) return -1;

  const diffDate = simDate ? DateTime.fromJSDate(simDate) : DateTime.now();
  const startDate = DateTime.fromISO(collection.autoRotate.startDate);

  const numberOfRotations = Math.floor(
    Math.abs(startDate.diff(diffDate, 'days').days) / collection.autoRotate.numberOfRotationDays
  );

  return (numberOfRotations * collection.autoRotate.numberOfItems) % collection.contents.length;
}

// 🤖: Most humans don't like to start counting with 0
export function autoRotateGetCurrentIndexHuman(collection?: ContentCollectionResponse, simDate?: Date): number {
  return autoRotateGetCurrentIndex(collection, simDate) + 1;
}

export function autoRotateGetNextRotation(collection?: ContentCollectionResponse, simDate?: Date): string | null {
  if (!collection?.autoRotate) return null;

  const diffDate = simDate ? DateTime.fromJSDate(simDate) : DateTime.now();
  const diff = diffDate.diff(DateTime.fromISO(collection.autoRotate.startDate), 'milliseconds');
  const millisPerRotation = 86400000 * collection.autoRotate.numberOfRotationDays;
  const millisTilNextRotation = millisPerRotation - (diff.milliseconds % millisPerRotation);

  return diffDate.plus({ milliseconds: millisTilNextRotation }).toISO();
}

export const defaultAutoRotateParams = (): AutoRotateBody => ({
  numberOfItems: 3,
  numberOfRotationDays: 7,
  startDate: DateTime.now().toISO() as string
});

export const ensurePinnedElementsAtTheBeginning = (
  pinnedContents: string[] | null | undefined,
  contents: string[] | CollectionAsset[]
): string[] | CollectionAsset[] => {
  if (pinnedContents?.length) {
    if (typeof contents[0] === 'object') {
      return [
        ...pinnedContents.map(
          (contentId) => contents[findIndex(contents as CollectionAsset[], ['assetId', contentId])]
        ),
        ...(contents as CollectionAsset[]).filter((asset) => !pinnedContents.includes(asset.assetId))
      ] as CollectionAsset[];
    }
    return [...pinnedContents, ...(contents as string[]).filter((content) => !pinnedContents.includes(content))];
  }
  return contents;
};
