import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LinearProgress } from '@mui/material';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

// Components
import { useAuth, useHomepage, useLocales, useTheme } from './hooks';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { withSavingUserVisitedPage } from './state/Config';
import 'simplebar/dist/simplebar.min.css';
import { DataLayer } from './data-layer';
import { withGlobalTimeDiff } from './state/Timezone';
import { setGlobalTimeDiff } from './utils/dateHelpers';
import { AppConfirmDialog } from './components/shared/ConfirmDialog/AppConfirmDialog';
import ContentDrawer from './components/shared/ContentDrawer';
import { AppRouter, AppRoutes } from './Routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { StatSigProvider } from './components/StatSigProvider';
import { VixGlobalStyles } from './globalStyles';
import { newRelic } from './newRelic';
import { useDeduplication } from './hooks';
import { GlobalHistoryManager } from './components/shared/HistoryManager/GlobalHistoryManager';
import { PresetForm } from './components/shared/Presets/PresetForm';

// Add data-testid to the TextField to allow for the slot props for DatePickers to attach this field
declare module '@mui/material/TextField' {
  interface BaseTextFieldProps {
    ['data-testid']?: string;
  }
}

const ignoredUserVisitedRoutes = [AppRoutes.login, AppRoutes.automatedTestingLogin, AppRoutes.users];

function App(): JSX.Element {
  const { theme } = useTheme();
  const { currentLang } = useLocales();
  const { homepageRoute } = useHomepage();
  const [didCheckUserIn, setDidCheckUserIn] = useState(false);
  const { currentUser, checkAuthentication } = useAuth();
  const [userVisitedLink, setUserVisitedLink] = useRecoilState(withSavingUserVisitedPage);
  const location = useLocation();
  const setTimeDiffRecoil = useSetRecoilState(withGlobalTimeDiff);

  const { setupInterceptor, cleanupInterceptor } = useDeduplication();

  useEffect(() => {
    initialize();
    setGlobalTimeDiff(setTimeDiffRecoil);
    () => cleanupInterceptor();
  }, []);

  useEffect(() => {
    saveUserVisitedLocation(location.pathname);
    if (process.env.REACT_APP_NEW_RELIC_ENABLED) {
      newRelicBrowserAgent(location.pathname);
    }
  }, [location]);

  const initialize = async () => {
    await checkAuthentication();
    // interceptors NEED to come after the auth interceptor.
    setupInterceptor();
    setDidCheckUserIn(true);
  };

  function newRelicBrowserAgent(linkPath: string) {
    if (window.newrelic) {
      const newrelic = window.newrelic.interaction();
      newrelic.setName(linkPath);
      newrelic.save();
    }
  }

  function saveUserVisitedLocation(linkPath: string) {
    if (!ignoredUserVisitedRoutes.includes(linkPath)) {
      setUserVisitedLink(linkPath);
    }
  }

  if (!didCheckUserIn) {
    return <LinearProgress data-testid="user-check_progress-bar" />;
  }

  if (currentUser && (location.pathname === AppRoutes.login || location.pathname === AppRoutes.automatedTestingLogin)) {
    if (userVisitedLink) {
      return <Navigate to={userVisitedLink} replace />;
    }

    return <Navigate to={homepageRoute} replace />;
  }

  if (!currentUser && location.pathname !== AppRoutes.login) {
    if (location.pathname !== AppRoutes.automatedTestingLogin) {
      return <Navigate to={AppRoutes.login} replace />;
    }
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{process.env.REACT_APP_TITLE}</title>
          <script type="text/javascript">{newRelic}</script>
        </Helmet>
      </HelmetProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <VixGlobalStyles />
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={currentLang}>
            <DataLayer>
              <GlobalHistoryManager>
                <StatSigProvider>
                  <AppRouter />
                  <ContentDrawer />
                  <PresetForm />
                  <AppConfirmDialog />
                </StatSigProvider>
              </GlobalHistoryManager>
            </DataLayer>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

export default App;
