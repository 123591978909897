import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material';
import React, { forwardRef } from 'react';
import { Controller, Path, useFormContext } from 'react-hook-form';
import { AccountSuccessUiModuleBody } from '../../../../../API';
import { useLocales, useTheme } from '../../../../../hooks';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { markAsRequired } from '../../../../../utils/formHelpers';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { FormBody, FormField, SectionHeader } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { LocaleKeys } from '../../../../../locales/i18n';

export const testIds = {
  formBody: 'account-success-form.form-body',
  formField: (name: string): string => `account-success-form.${name}-field`
};

const AccountSuccessForm = forwardRef<UIModuleTypeForm<AccountSuccessUiModuleBody>>(() => {
  const { control } = useFormContext<AccountSuccessUiModuleBody>();
  const { t } = useLocales();

  type AccountSuccessProps = Path<AccountSuccessUiModuleBody>;

  const AccountSuccessTextValidator = (name: AccountSuccessProps, required = false): JSX.Element => {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.account_success.fields.${name}`)}
            name={name}
            value={value as string}
            data-testid={testIds.formField(name)}
            onChange={onChange}
            required={required}
          />
        )}
      />
    );
  };

  const CheckboxField = (name: AccountSuccessProps): JSX.Element => {
    const { formControlColor } = useTheme();

    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <FormField>
            <FormControlLabel
              label={t(`layouts.account_success.fields.${name}` as LocaleKeys)}
              control={
                <Checkbox
                  name={t(`layouts.account_success.fields.${name}`)}
                  checked={value as boolean}
                  color={formControlColor}
                  onChange={onChange}
                  data-testid={testIds.formField(name)}
                />
              }
            />
          </FormField>
        )}
      />
    );
  };

  const AccountSuccessAssetPicker = ({
    name,
    label,
    assetType,
    required = false
  }: {
    name: AccountSuccessProps;
    label: string;
    assetType: AssetTypesEnum;
    required?: boolean;
  }): JSX.Element => {
    const isRequired = (label: string) => (required ? markAsRequired(label) : label);
    return (
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <FormField>
              <AssetBrowser
                label={isRequired(label)}
                assetId={assetType}
                assetType={assetType}
                value={value as string}
                data-testid={testIds.formField(name)}
                hasError={!!error}
                onChange={onChange}
              />
            </FormField>
          );
        }}
      />
    );
  };

  return (
    <FormBody data-testid={testIds.formBody}>
      <Box>
        <SectionHeader>{t('layouts.account_success.headers.images')}</SectionHeader>
        <Stack direction="row" mt={6}>
          <AccountSuccessAssetPicker
            name="landscapeAccountSuccessImage"
            label={t('image_labels.landscape')}
            assetType={AssetTypes.accountSuccess.landscape}
            required
          />
          <AccountSuccessAssetPicker
            name="portraitAccountSuccessImage"
            label={t('image_labels.portrait')}
            assetType={AssetTypes.accountSuccess.portrait}
            required
          />
        </Stack>
        <Stack direction="row">
          <AccountSuccessAssetPicker
            name="desktopAccountSuccessImage"
            label={t('image_labels.desktop')}
            assetType={AssetTypes.accountSuccess.desktop}
          />
          <AccountSuccessAssetPicker
            name="tabletAccountSuccessImage"
            label={t('image_labels.tablet')}
            assetType={AssetTypes.accountSuccess.tablet}
          />
        </Stack>
        <Stack direction="row">
          <AccountSuccessAssetPicker
            name="mobileAccountSuccessImage"
            label={t('image_labels.mobile')}
            assetType={AssetTypes.accountSuccess.mobile}
          />
          <AccountSuccessAssetPicker
            name="ctvAccountSuccessImage"
            label={t('image_labels.ctv')}
            assetType={AssetTypes.accountSuccess.ctv}
          />
        </Stack>
      </Box>
      <Box>
        <SectionHeader>{t('layouts.account_success.headers.screen_keys')}</SectionHeader>
        <FormField>{AccountSuccessTextValidator('svodHeader', true)}</FormField>
        <FormField>{AccountSuccessTextValidator('svodSubHeader', true)}</FormField>
        <FormField>{AccountSuccessTextValidator('avodHeader', true)}</FormField>
        <FormField>{AccountSuccessTextValidator('avodSubHeader', true)}</FormField>
        <FormField>{AccountSuccessTextValidator('optInNotificationsCta')}</FormField>
        <FormField>{AccountSuccessTextValidator('downloadAndOpenAppCta')}</FormField>
        <FormField>{AccountSuccessTextValidator('continueWebCta')}</FormField>
        <FormField>{AccountSuccessTextValidator('subscribeCta')}</FormField>
        <FormField>{AccountSuccessTextValidator('notNowCta')}</FormField>
        <FormField>{AccountSuccessTextValidator('legalDisclaimer')}</FormField>
      </Box>
      <Box>
        <SectionHeader>{t('layouts.account_success.headers.partner_keys')}</SectionHeader>
        <FormField>{AccountSuccessTextValidator('partnerHeader')}</FormField>
        <FormField>{AccountSuccessTextValidator('partnerSubHeader')}</FormField>
        <FormField>{AccountSuccessTextValidator('partnerCta')}</FormField>
        <AccountSuccessAssetPicker
          name="partnerLogo"
          label={t('layouts.account_success.fields.partnerLogo')}
          assetType={AssetTypes.accountSuccess.logo}
        />
      </Box>
      <Box>
        <SectionHeader>{t('layouts.account_success.headers.spin_premia')}</SectionHeader>
        <FormField>{CheckboxField('spinPremia.checkbox')}</FormField>
        <div />
        <FormField>{AccountSuccessTextValidator('spinPremia.description')}</FormField>
        <FormField>{AccountSuccessTextValidator('spinPremia.cta')}</FormField>
      </Box>
    </FormBody>
  );
});

AccountSuccessForm.displayName = 'AccountSuccessForm';

export default AccountSuccessForm;
