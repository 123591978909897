/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiResponseOfLokaliseKeyArray } from '../model';
// @ts-ignore
import { ApiResponseOfStringArray } from '../model';
/**
 * LokaliseApi - axios parameter creator
 * @export
 */
export const LokaliseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/lokalise`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLokaliseKeys: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/lokalise/keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} keys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLokaliseTranslations: async (keys: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'keys' is not null or undefined
            assertParamExists('getLokaliseTranslations', 'keys', keys)
            const localVarPath = `/api/v1/lokalise/keys/translations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keys) {
                localVarQueryParameter['keys'] = keys;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchKeys: async (search: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('searchKeys', 'search', search)
            const localVarPath = `/api/v1/lokalise/searchKeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LokaliseApi - functional programming interface
 * @export
 */
export const LokaliseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LokaliseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfLokaliseKeyArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLokaliseKeys(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfStringArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLokaliseKeys(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} keys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLokaliseTranslations(keys: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfLokaliseKeyArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLokaliseTranslations(keys, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchKeys(search: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfLokaliseKeyArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchKeys(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LokaliseApi - factory interface
 * @export
 */
export const LokaliseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LokaliseApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: any): AxiosPromise<ApiResponseOfLokaliseKeyArray> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLokaliseKeys(options?: any): AxiosPromise<ApiResponseOfStringArray> {
            return localVarFp.getLokaliseKeys(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} keys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLokaliseTranslations(keys: Array<string>, options?: any): AxiosPromise<ApiResponseOfLokaliseKeyArray> {
            return localVarFp.getLokaliseTranslations(keys, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchKeys(search: string, options?: any): AxiosPromise<ApiResponseOfLokaliseKeyArray> {
            return localVarFp.searchKeys(search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LokaliseApi - interface
 * @export
 * @interface LokaliseApi
 */
export interface LokaliseApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LokaliseApiInterface
     */
    getAll(options?: any): AxiosPromise<ApiResponseOfLokaliseKeyArray>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LokaliseApiInterface
     */
    getLokaliseKeys(options?: any): AxiosPromise<ApiResponseOfStringArray>;

    /**
     * 
     * @param {Array<string>} keys 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LokaliseApiInterface
     */
    getLokaliseTranslations(keys: Array<string>, options?: any): AxiosPromise<ApiResponseOfLokaliseKeyArray>;

    /**
     * 
     * @param {string} search 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LokaliseApiInterface
     */
    searchKeys(search: string, options?: any): AxiosPromise<ApiResponseOfLokaliseKeyArray>;

}

/**
 * LokaliseApi - object-oriented interface
 * @export
 * @class LokaliseApi
 * @extends {BaseAPI}
 */
export class LokaliseApi extends BaseAPI implements LokaliseApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LokaliseApi
     */
    public getAll(options?: any) {
        return LokaliseApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LokaliseApi
     */
    public getLokaliseKeys(options?: any) {
        return LokaliseApiFp(this.configuration).getLokaliseKeys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} keys 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LokaliseApi
     */
    public getLokaliseTranslations(keys: Array<string>, options?: any) {
        return LokaliseApiFp(this.configuration).getLokaliseTranslations(keys, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} search 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LokaliseApi
     */
    public searchKeys(search: string, options?: any) {
        return LokaliseApiFp(this.configuration).searchKeys(search, options).then((request) => request(this.axios, this.basePath));
    }
}
