import {
  DocumentLocale,
  EntitlementType,
  AccountLoadingUiModuleBody,
  AccountLoadingUiModuleBodyModuleTypeEnum
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankAccountLoadingModule: NewModuleFunction<AccountLoadingUiModuleBody> = (module) => ({
  moduleType: AccountLoadingUiModuleBodyModuleTypeEnum.ACCOUNT_LOADING,
  moduleId: generateId(),
  title: {
    [DocumentLocale.ES]: ''
  },
  entitlements: [EntitlementType.ANON, EntitlementType.FREE, EntitlementType.SUB],
  ...module,
  accountLoading: ''
});
