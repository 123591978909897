import React from 'react';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { UiModuleBody } from '../../../../../state/Layouts';
import { toTitleCase } from '../../../../../utils/textUtils';

export type DefaultModuleListItemProps = IBaseUIModuleListItemProps<UiModuleBody>;

const DefaultModuleListItem = ({ uiModule, ...props }: DefaultModuleListItemProps): JSX.Element => {
  return <UIModuleListItem uiModule={uiModule} topLineTitle={toTitleCase(uiModule.moduleType)} {...props} />;
};

export default DefaultModuleListItem;
