import {
  DataExchangeApi,
  DataExchangeResponse,
  ExportEntityType,
  ImportBody,
  ImportEntityResponse,
  ValidateImportEntityResponse
} from '../../../API';
import { useLocales } from '../../../hooks';
import {
  DataManagerHook,
  DataManagerHookReturnType,
  IDataManagerHookProps,
  DataManagerHelpers
} from '../../DataManager';
import { saveAsFile } from '../../../utils/saveAsFile';

export interface DataExchangeHookReturnType extends DataManagerHookReturnType<DataExchangeResponse> {
  exportEntity: (moduleId: string, entityType: ExportEntityType) => Promise<void>;
  validateImportEntity: (query: ImportBody) => Promise<ValidateImportEntityResponse | null>;
  importEntity: (query: ImportBody) => Promise<ImportEntityResponse | null>;
}

export function DataExchangeHook(params: IDataManagerHookProps<DataExchangeResponse>): DataExchangeHookReturnType {
  const dataManagerHook = DataManagerHook<DataExchangeResponse>(params);

  const api = params.useApiHook() as DataExchangeApi;

  const { t } = useLocales();
  const { handleApiError } = DataManagerHelpers({ name: params.name, toString });

  const exportEntity = async (moduleId: string, entityType: ExportEntityType) => {
    try {
      const response = await api.exportEntity(moduleId, entityType);
      saveAsFile(response);
    } catch (err) {
      console.error(err);
      handleApiError(err, t('errors.data_exchange.export_entity'));
    }
  };

  const validateImportEntity = async (query: ImportBody): Promise<ValidateImportEntityResponse | null> => {
    try {
      const response = await api.validateImportEntity(query);
      return response.data.body;
    } catch (err) {
      console.error(err);
      handleApiError(err, t('errors.data_exchange.validate_import_entity'));
      return null;
    }
  };

  const importEntity = async (query: ImportBody): Promise<ValidateImportEntityResponse | null> => {
    try {
      const response = await api.importEntity(query);
      return response.data.body;
    } catch (err) {
      console.error(err);
      handleApiError(err, t('errors.data_exchange.import_entity'));
      return null;
    }
  };

  return {
    ...dataManagerHook,
    exportEntity,
    validateImportEntity,
    importEntity
  };
}
