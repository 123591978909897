import { atom, selector, selectorFamily } from 'recoil';
import {
  StatsigExperiment,
  StatsigUiModuleConfig,
  StatsigUiModulesConfig,
  StatsigUiModulesDefaultsConfig
} from '../../API';
import { UIModuleType } from '../../utils/layouts';
import { camelCase } from 'lodash';

export const withExperiments = atom<StatsigExperiment[] | undefined>({
  key: 'statsig.experiments',
  default: undefined
});

export const withStatsigUiModulesConfig = atom<StatsigUiModulesConfig | undefined>({
  key: 'statsig.ui-modules-config',
  default: undefined
});

export const withStatsigUiModulesDefaultConfig = selector<StatsigUiModulesDefaultsConfig | undefined>({
  key: 'statsig.ui-modules-defaults-config',
  get: ({ get }) => {
    const config = get(withStatsigUiModulesConfig);
    return config?.defaults;
  }
});

export const withUiModulesConfigByType = selectorFamily<StatsigUiModuleConfig | undefined, UIModuleType | undefined>({
  key: 'statsig.ui-modules-config-by-type',
  get: (moduleType) => {
    return ({ get }) => {
      const config = get(withStatsigUiModulesConfig);
      if (config && moduleType) {
        return (config as unknown as Record<string, StatsigUiModuleConfig>)[
          camelCase(moduleType)
        ] as StatsigUiModuleConfig;
      }
    };
  }
});
