import { localAtom } from '../localStorageState';

export const withEPGSplitPane = localAtom<number[]>({
  key: 'epg.splitPane',
  default: []
});

export const withUsersSplitPane = localAtom<number[]>({
  key: 'users.splitPane',
  default: []
});

export const withChannelGridSplitPlane = localAtom<number[]>({
  key: 'epg.channels.report.splitPane',
  default: []
});

export const withPlansSplitPane = localAtom<number[]>({
  key: 'plans.splitPane',
  default: []
});

export const withCouponsSplitPane = localAtom<number[]>({
  key: 'coupons.splitPane',
  default: []
});

export const withPromotionsSplitPane = localAtom<number[]>({
  key: 'promotions.splitPane',
  default: []
});
