import {
  DocumentLocale,
  PageListUiModuleBody,
  PageListUiModuleBodyModuleTypeEnum,
  UiModulePageListTreatment
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankPageListModule: NewModuleFunction<PageListUiModuleBody> = (module) => ({
  title: {
    [DocumentLocale.ES]: ''
  },
  moduleType: PageListUiModuleBodyModuleTypeEnum.PAGE_LIST,
  pageListTreatment: UiModulePageListTreatment.HORIZONTAL_BUTTONS,
  pages: [],
  moduleId: generateId(),
  ...module
});
