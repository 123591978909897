import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HistoryIcon from '@mui/icons-material/History';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Badge,
  Box,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  ListItemText,
  MenuItem,
  Popover,
  Tooltip
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { cloneDeep } from 'lodash-es';
import React, { DependencyList, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { VersionedDocumentBody, VersionedDocumentStatus } from '../../../API';
import { Paginated, useLocales } from '../../../hooks';
import { useLifecycle } from '../../../hooks/General/useLifecycle';
import { LocaleKeys } from '../../../locales/i18n';
import { UserBadge } from '../UserBadge';

const useStyles = makeStyles()((theme) => ({
  divider: {
    margin: '0 !important'
  },
  listItem: {
    display: 'flex',
    alignItems: 'center'
  },
  loadMore: {
    display: 'flex',
    justifyContent: 'center'
  },
  iconContainer: {
    marginRight: theme.spacing(2),
    display: 'flex'
  },
  checkIcon: {
    color: theme.palette.success.main
  },
  uncheckedIcon: {
    color: theme.palette.background.paper
  },
  itemLabel: {
    display: 'flex'
  },
  userBadge: {
    display: 'flex',
    marginRight: theme.spacing(3)
  },
  chip: {
    marginLeft: theme.spacing(2)
  }
}));

export const testIds = {
  button: 'revisionmanager-button',
  revisionListItem: 'revisionmanager-listitem',
  revisionLoadMore: 'revisionmanager-loadmore',
  spinner: 'revisionmanager-spinner'
};

interface RevisionManagerProps<Entity extends VersionedDocumentBody> {
  getRevisionsPromise: (limit?: number, page?: number) => Promise<Paginated<Entity> | undefined>;
  state: Entity | undefined;
  onChange: (newState: Entity | ((oldState: Entity | undefined) => Entity | undefined)) => void;
  dependencies?: DependencyList;
}

function RevisionManager<Entity extends VersionedDocumentBody>({
  getRevisionsPromise,
  state,
  onChange,
  dependencies = []
}: RevisionManagerProps<Entity>): JSX.Element | null {
  const { classes } = useStyles();
  const { t } = useLocales();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  // const [revisionBadgeContents, setRevisionBadgeContents] = useState<string | null>(null);
  const [revisions, setRevisions] = useState<Entity[] | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const { willUnmount } = useLifecycle();

  const fetchRevisions = useCallback(
    async (page: number) => {
      setLoading(true);
      let currentRevisions = revisions || [];
      // If we're loading the first page, then clear any previous revisions
      if (page === 1) {
        currentRevisions = [];
        setTotalPages(1);
      }
      const revs = await getRevisionsPromise(undefined, page);
      if (revs && !willUnmount.current) {
        setRevisions(currentRevisions.concat(revs.results as Entity[]));
        setTotalPages(revs.meta.totalPages);
      } else {
        setRevisions(null);
      }
      setCurrentPage(page);
      setLoading(false);
    },
    [getRevisionsPromise, setRevisions, setTotalPages, revisions, setLoading]
  );

  useEffect(() => {
    if (!state) {
      return setRevisions(null);
    }
    if (!revisions || (revisions.length && revisions[0].entityId !== state.entityId)) {
      // Fetch revisions if we don't have them yet, or if we have a new entity
      void fetchRevisions(1);
      return;
    }
    if (revisions && revisions.length && new Date(state.updatedDate) > new Date(revisions[0].updatedDate)) {
      // If updatedDate changes to a later date than that of our head revision, it means we are
      // overwriting the entity with a fresh value from the API response after saving/publishing
      const revisionsCopy = [...revisions];
      const monitoredRecoilStateCopy = cloneDeep(state);
      if (state.revision === revisions[0].revision) {
        // Replace the head revision with the current state
        // This would happen if the head revision was previously a Draft
        revisionsCopy[0] = monitoredRecoilStateCopy;
      } else {
        // Prepend the current state to become the new revision
        // Could be a draft or published
        revisionsCopy.unshift(monitoredRecoilStateCopy);
      }
      setRevisions(revisionsCopy);
    }
  }, [state, revisions, fetchRevisions, ...dependencies]);

  const revisionBadgeContents = useMemo<string | null>(
    () =>
      revisions && revisions.length && state && state.revision < revisions[0].revision
        ? String(state.revision - revisions[0].revision)
        : null,
    [state, revisions]
  );

  const handleRevisionClick = (revision: Entity) => {
    onChange(revision as Entity);
    setOpen(false);
  };

  const handleLoadMoreClick = useCallback(() => {
    if (loading) {
      return;
    }
    void fetchRevisions(currentPage + 1);
  }, [currentPage, fetchRevisions, loading]);

  return (
    <>
      <Badge badgeContent={revisionBadgeContents} color="secondary">
        <Tooltip title={t('general.revision_manager_tooltip')} arrow placement="left">
          <div ref={anchorRef}>
            <IconButton
              onClick={() => setOpen(true)}
              data-testid={testIds.button}
              disabled={revisions === null || revisions.length <= 0}
            >
              <HistoryIcon />
            </IconButton>
          </div>
        </Tooltip>
      </Badge>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {revisions &&
          revisions.map((r, idx) => {
            if (!r) return;
            const { revision, status } = r;
            return (
              <React.Fragment key={revision}>
                {idx !== 0 && <Divider className={classes.divider} />}
                <MenuItem
                  onClick={() => handleRevisionClick(r)}
                  data-testid={testIds.revisionListItem}
                  className={classes.listItem}
                >
                  <div className={classes.iconContainer}>
                    {state?.revision === revision ? (
                      <CheckCircleOutlineIcon className={classes.checkIcon} />
                    ) : (
                      <RadioButtonUncheckedIcon className={classes.uncheckedIcon} />
                    )}
                  </div>
                  <div className={classes.userBadge}>
                    <UserBadge record={r} sx={{ height: 30, width: 30 }} />
                  </div>
                  <div className={classes.itemLabel}>
                    <ListItemText>{t('general.revision', { n: revision })}</ListItemText>
                    <Chip
                      label={t(`general.${status.toLowerCase()}` as LocaleKeys)}
                      size="small"
                      className={classes.chip}
                      color={status === VersionedDocumentStatus.DRAFT ? 'secondary' : 'primary'}
                    />
                  </div>
                </MenuItem>
              </React.Fragment>
            );
          })}
        {revisions && currentPage < totalPages && (
          <>
            <Divider className={classes.divider} />
            <MenuItem
              onClick={handleLoadMoreClick}
              data-testid={testIds.revisionLoadMore}
              className={classes.listItem}
              disabled={loading}
            >
              {loading && (
                <Box sx={{ display: 'flex', alignItems: 'center', position: 'absolute', right: 0, left: 10 }}>
                  <CircularProgress size={20} color="inherit" data-testid={testIds.spinner} />
                </Box>
              )}
              <ListItemText className={classes.loadMore}>{t('general.revision_manager_load_more')}</ListItemText>
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
}

export default RevisionManager;
