import { useRecoilValue } from 'recoil';
import { LokaliseKey } from '../../../API';
import { DataManagerHook, DataManagerHookReturnType, IDataManagerHookProps } from '../../DataManager';
import { useEffect } from 'react';
import { SupportedLanguage } from '../../../locales/i18n';
import { USER_DATA_TOKEN } from '../../../hooks';

export interface LokaliseHookReturnType extends DataManagerHookReturnType<LokaliseKey> {
  searchKeys: (term: string, prefix?: string) => LokaliseKey[];
  getTranslation: (key?: string, language?: SupportedLanguage) => string;
}

export function LokaliseHook(params: IDataManagerHookProps<LokaliseKey, LokaliseKey>): LokaliseHookReturnType {
  // For now, we only want to show the spanish translations for lokalise
  const currentLang: SupportedLanguage = 'es';
  const dataManagerHook = DataManagerHook<LokaliseKey, LokaliseKey>(params);
  const lokaliseKeys = useRecoilValue(params.state.withAllRecordsById);
  const userToken = localStorage.getItem(USER_DATA_TOKEN);

  useEffect(() => {
    if (userToken) {
      dataManagerHook.getAll();
    }
  }, [userToken]);

  const searchKeys = (term: string, prefix?: string) => {
    term = term.toLowerCase();
    return Object.values(lokaliseKeys).filter((lokaliseKey) => {
      // If we have a prefix, we only want to show keys that start with that prefix
      if (prefix && !lokaliseKey.key.startsWith(prefix)) {
        return false;
      }
      // search key name and value
      return lokaliseKey.key.includes(term) || getTranslation(lokaliseKey.key).toLowerCase().includes(term);
    });
  };

  const getTranslation = (key?: string, language: SupportedLanguage = currentLang) => {
    return lokaliseKeys[key || '']?.translations.find((lt) => lt.language === language)?.translation || '';
  };

  return {
    ...dataManagerHook,
    searchKeys,
    getTranslation
  };
}
