import React from 'react';
import { Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { InfoOutlined } from '@mui/icons-material';
import { Box, BoxProps } from '@mui/system';

export const InformationClass = 'information';

const useStyles = makeStyles()((theme) => ({
  info: {
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
}));

export function InfoBadge({ className, ...props }: BoxProps): JSX.Element {
  const { classes, cx } = useStyles();

  return (
    <Box {...props} className={cx(classes.info, className, InformationClass)}>
      <Tooltip title={props.title} placement="top" arrow>
        <InfoOutlined />
      </Tooltip>
    </Box>
  );
}
