import React, { useRef, useState } from 'react';
import { Checkbox, FormControlLabel, Popover } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '../../../../hooks';
import { EntitlementType } from '../../../../API';
import { useEntitlements } from '../../../../hooks/General/useEntitlements';
import { EntitlementsVisibilityBadge } from '../EntitlementsVisibilityBadge';
import { EntitlementsIcon } from '../EntitlementsIcon';
import { BadgeSize } from '../../../../utils/types/genericTypes';

export interface IEntitlementsMultiSelectLiteProps {
  label?: string;
  className?: string;
  value: EntitlementType[];
  onChange: (values: EntitlementType[]) => void;
  size?: BadgeSize;
}

const useStyles = makeStyles()((theme) => ({
  badgeContainer: {
    '& *': {
      cursor: 'pointer'
    }
  },
  entitlementIconContainer: {
    width: 18,
    display: 'flex',
    marginRight: theme.spacing(2)
  },
  listItem: {
    padding: theme.spacing(1, 3)
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export const testIds = {
  badgeContainer: 'entitlements-multi-select-lite.badge-container',
  popover: 'entitlements-multi-select-lite.popover',
  checkbox: 'entitlements-multi-select-lite.checkbox'
};

const allEntitlements = Object.keys(EntitlementType);

export function EntitlementsMultiSelectLite({
  className,
  value,
  onChange,
  size = 'medium'
}: IEntitlementsMultiSelectLiteProps): React.ReactElement {
  const { cx, classes } = useStyles();
  const { formControlColor } = useTheme();
  const { localizeEntitlement } = useEntitlements();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onEntitlementsValueChange = (entitlement: EntitlementType, checked: boolean) => {
    if (checked) {
      onChange([...value, entitlement]);
    } else {
      onChange(value.filter((valuePart) => valuePart !== entitlement));
    }
  };

  return (
    <>
      <div
        ref={anchorRef}
        className={cx(className, classes.badgeContainer)}
        onClick={handleOpen}
        data-testid={testIds.badgeContainer}
      >
        <EntitlementsVisibilityBadge entitlements={value} size={size} />
      </div>

      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <div data-testid={testIds.popover}>
          {allEntitlements.map((entitlement) => (
            <div className={classes.listItem} key={entitlement}>
              <FormControlLabel
                control={
                  <Checkbox
                    color={formControlColor}
                    checked={!!value.find((valuePart) => valuePart === entitlement)}
                    onChange={({ target: { checked } }) =>
                      onEntitlementsValueChange(entitlement as EntitlementType, checked)
                    }
                    data-testid={testIds.checkbox}
                    data-entitlement={entitlement}
                  />
                }
                label={
                  <div className={classes.checkboxLabel}>
                    <div className={classes.entitlementIconContainer}>
                      <EntitlementsIcon entitlement={entitlement as EntitlementType} />
                    </div>
                    {localizeEntitlement(entitlement)}
                  </div>
                }
              />
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
}
