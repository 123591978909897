import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { debounce, intersection, cloneDeep, flatten } from 'lodash-es';
import {
  ToggleButtonGroup,
  ToggleButton,
  Autocomplete,
  TextField,
  Tooltip,
  InputBaseComponentProps,
  Chip
} from '@mui/material';
import { makeStyles, withStyles } from 'tss-react/mui';
import { emphasize } from '@mui/system';
import { AccountTreeOutlined, DateRange, DeleteOutline, EventAvailable, FilterAltOutlined } from '@mui/icons-material';
import { useLocales, useTheme } from '../../../hooks';
import IconButton from '../../shared/IconButton';
import {
  dateOperatorLabel,
  getOperatorSymbol,
  parseSmartQueryDateTimeField,
  valueIsDynamicDate
} from '../../../utils/queryBuilder';
import CollectionQueryDateDropdown from './CollectionQueryDateDropdown';
import { useData } from '../../../data-layer';
import {
  SmartQueryBody,
  SmartQueryConditionBody,
  SmartQueryEnumItem,
  SmartQueryFieldSymbol,
  SmartQueryOperatorSymbol,
  SmartQueryOperatorResponse
} from '../../../API';
import { LocaleKeys } from '../../../locales/i18n';
import { DateTime } from 'luxon';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import { isEntityDeleted } from '../../../utils/generalUtils';
import { SuggestionsAutocomplete } from '../../shared/SuggestionsAutocomplete';

export const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(2),
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4)
  },
  leftContent: {
    flex: 1
  },
  rightContent: {
    display: 'flex',
    gap: theme.spacing(1),
    borderColor: emphasize(theme.palette.background.paper, 0.1),
    borderLeft: '1px solid',
    paddingLeft: theme.spacing(2)
  },
  statement: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4)
  },
  statementField: {
    flex: 1,
    minWidth: 130
  },
  fieldChooser: {
    flex: 1,
    minWidth: 140
  },
  textField: {
    '&.MuiTextField-root': {
      marginBottom: '0 !important'
    }
  },
  matchText: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5em',
    color: theme.palette.text.secondary
  },
  toggleButtonContent: {
    padding: theme.spacing(1)
  }
}));

const StyledToggleButtonGroup = withStyles(ToggleButtonGroup, (theme) => ({
  root: {
    background: theme.palette.background.default,
    '& .MuiToggleButtonGroup-grouped': {
      margin: theme.spacing(1),
      border: 0,
      lineHeight: 1,
      padding: 0,
      '&.Mui-disabled': {
        border: 0
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      },
      '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius
      },
      '&:first-of-type': {
        borderRadius: theme.shape.borderRadius
      }
    }
  }
}));

interface CollectionQueryNodeFormProps {
  queryNodeKey: string;
  onAddQueryNode?: (key: string) => void;
  onRemoveQueryNode?: (key: string) => void;
}

export const testIds = {
  container: (key: string): string => `collection-query-node-form.container-${key}`,
  groupForm: (key: string): string => `collection-query-node-form.group-form-${key}`,
  ruleForm: (key: string): string => `collection-query-node-form.rule-form-${key}`,
  removeNodeButton: (key: string): string => `collection-query-node-form.remove-node-button-${key}`,
  addGroupNodeButton: (key: string): string => `collection-query-node-form.add-group-node-button-${key}`,
  addRuleNodeButton: (key: string): string => `collection-query-node-form.add-rule-node-button-${key}`,
  andButton: (key: string): string => `collection-query-node-form.and-button-${key}`,
  orButton: (key: string): string => `collection-query-node-form.or-button-${key}`,
  fieldSelect: (key: string): string => `collection-query-node-form.field-select-${key}`,
  valueInput: (key: string): string => `collection-query-node-form.field-input-${key}`,
  statementOperator: (key: string, operator: string): string =>
    `collection-query-node-form.statement-operator-toggle-${key}-${operator}`,
  dynamicDateSwitch: (key: string): string => `collection-query-node-form.dynamic-date-switch-${key}`
};

function CollectionQueryNodeForm({
  queryNodeKey,
  onAddQueryNode,
  onRemoveQueryNode
}: CollectionQueryNodeFormProps): JSX.Element {
  const {
    collections: {
      state: {
        withCollectionContentTypes,
        withCollectionQueryFields,
        withCollectionQueryOperators,
        withSelectedCollectionQuery,
        withSelected
      }
    }
  } = useData();

  const { classes, cx } = useStyles();
  const { t, localize } = useLocales();
  const { formControlColor } = useTheme();
  const queryFields = useRecoilValue(withCollectionQueryFields);
  const queryOperators = useRecoilValue(withCollectionQueryOperators);
  const [collectionQuery, setCollectionQuery] = useRecoilState(withSelectedCollectionQuery);
  const collectionContentTypes = useRecoilValue(withCollectionContentTypes);
  const queryNode = collectionQuery?.conditions.find(({ key }) => key === queryNodeKey);
  const [textFieldValueBuffer, setTextFieldValueBuffer] = useState(queryNode?.value);
  const [isDynamicDate, setIsDynamicDate] = useState(valueIsDynamicDate(queryNode?.value));
  const collection = useRecoilValue(withSelected);
  const { hasUpsertPermission } = usePermissions();
  const hasCollectionUpsertPermission = hasUpsertPermission(collection?.ownerPermissionsGroup);
  const isDeleted = isEntityDeleted(collection);

  const availableFields = queryFields.filter(
    ({ contentTypes }) =>
      !contentTypes ||
      !collectionContentTypes ||
      !contentTypes.length ||
      !!intersection(contentTypes, collectionContentTypes).length
  );
  const currentField = queryNode && availableFields.find(({ symbol }) => symbol === queryNode.field);
  const isConjunction =
    !!queryNode?.operator && [SmartQueryOperatorSymbol.AND, SmartQueryOperatorSymbol.OR].includes(queryNode?.operator);
  const isDateTime = currentField?.type === 'DATETIME';

  const editQueryNodeWith = (operation: (editableQueryNode: SmartQueryConditionBody) => void) => {
    if (!collectionQuery) return;
    const newCollectionQuery = cloneDeep(collectionQuery) as SmartQueryBody;
    const queryNodeToEdit = newCollectionQuery.conditions.find(({ key }) => key === queryNode?.key);
    if (queryNodeToEdit) {
      operation(queryNodeToEdit);
      setCollectionQuery(newCollectionQuery);
    }
  };

  useEffect(() => {
    setTextFieldValueBuffer(queryNode?.value);
  }, [queryNode?.value]);

  const toggleDynamicDate = () => {
    editQueryNodeWith((editableQueryNode) => {
      if (!queryNode?.value || typeof queryNode?.value === 'number') return;
      const dynamicDateValue = valueIsDynamicDate(queryNode.value);
      const parseDynamicDate = new Date(parseSmartQueryDateTimeField(queryNode.value) || Date.now()).toISOString();
      editableQueryNode.value = dynamicDateValue ? parseDynamicDate.split('T')[0] : '0 days before today';
      setTimeout(() => setIsDynamicDate(!dynamicDateValue), 0);
    });
  };

  const getNextNodeKey = (): string => {
    if (!queryNode) return '';
    // Children keys are those that have one less number separated by dots that parent
    // And at the same time start with parent's key e.g. 1.0.20 (3 numbers) is child of 1.0 (2 numbers)
    const nodeChildrenKeys = collectionQuery?.conditions
      .map<string>(({ key }) => key)
      .filter((key) => key.split('.').length === queryNode.key.split('.').length + 1 && key.startsWith(queryNode.key));
    // Compare the last part of they childen keys (separated by dots) and find the max number
    // E.g. for child keys 0.0.1 and 0.0.3 compare 1 and 3
    const maxChildrenKey = nodeChildrenKeys?.length
      ? Math.max(...nodeChildrenKeys.map<number>((key) => Number(key.split('.').slice(-1))))
      : 0;
    return `${queryNode?.key}.${maxChildrenKey + 1}`;
  };

  const canAddGroup = (): boolean => {
    if (isDeleted) return false;
    const key = getNextNodeKey();
    const groupLength = key.split('.').length;
    return groupLength < 3;
  };

  const handleAddGroupNode = () => {
    if (!collectionQuery) return;
    const newCollectionQuery = cloneDeep(collectionQuery) as SmartQueryBody;
    const key = getNextNodeKey();
    if (!canAddGroup()) return;
    newCollectionQuery.conditions.push({
      operator:
        queryNode?.operator === SmartQueryOperatorSymbol.AND
          ? SmartQueryOperatorSymbol.OR
          : SmartQueryOperatorSymbol.AND,
      key
    });
    newCollectionQuery.conditions.push({
      field: availableFields[0]?.symbol,
      operator: SmartQueryOperatorSymbol.EQ,
      value: '',
      key: `${key}.0`
    });
    setCollectionQuery(newCollectionQuery);
    onAddQueryNode?.(key);
    onAddQueryNode?.(`${key}.0`);
  };

  const handleAddRuleNode = () => {
    if (!collectionQuery) return;
    const newCollectionQuery = cloneDeep(collectionQuery) as SmartQueryBody;
    const key = getNextNodeKey();
    newCollectionQuery.conditions.push({
      field: availableFields[0]?.symbol,
      operator: SmartQueryOperatorSymbol.EQ,
      value: '',
      key
    });
    setCollectionQuery(newCollectionQuery);
    onAddQueryNode?.(key);
  };

  const removeQueryNode = (searchedKey: string) => {
    if (!collectionQuery) return;
    if (searchedKey.length > 1) {
      const newCollectionQuery = cloneDeep({
        ...collectionQuery,
        conditions: collectionQuery.conditions.filter(({ key }) => !key.startsWith(searchedKey))
      }) as SmartQueryBody;
      setCollectionQuery(newCollectionQuery);
      for (const { key } of collectionQuery.conditions) {
        if (key.startsWith(searchedKey)) onRemoveQueryNode?.(key);
      }
      removeParentIfEmpty(newCollectionQuery, searchedKey);
    } else {
      setCollectionQuery(undefined);
      onRemoveQueryNode?.(searchedKey);
    }
  };

  const removeParentIfEmpty = (newCollectionQuery: SmartQueryBody, searchedKey: string) => {
    if (!newCollectionQuery) return;
    const parent = newCollectionQuery.conditions.find(
      ({ key: parentKey }) =>
        parentKey.split('.').length === searchedKey.split('.').length - 1 && searchedKey.startsWith(parentKey)
    );
    if (parent) {
      const parentHasChildren = newCollectionQuery.conditions.find(
        ({ key }) => parent.key.split('.').length === key.split('.').length - 1 && key.startsWith(parent.key)
      );
      if (!parentHasChildren) removeQueryNode(parent.key);
    }
  };

  const handleRemoveQueryNode = () => {
    if (queryNode) removeQueryNode(queryNode.key);
  };

  const handleChangeOperator = (newOperator?: string) => {
    if (newOperator) {
      editQueryNodeWith((editableQueryNode) => {
        if (newOperator !== SmartQueryOperatorSymbol.ANY_OF && Array.isArray(editableQueryNode.value)) {
          editableQueryNode.value = editableQueryNode.value[0];
        }
        editableQueryNode.operator = newOperator as SmartQueryOperatorSymbol;
      });
    }
  };

  const handleChangeStatementValue = (newValue?: string | number | string[] | number[] | undefined) => {
    editQueryNodeWith((editableQueryNode) => {
      editableQueryNode.value = newValue;
    });
  };

  const handleChangeStatementValueDebounced = useCallback(debounce(handleChangeStatementValue, 800), [queryNode]);

  const findFieldSymbolLabel = (searchedValue: string): string => {
    const field = availableFields.find(({ symbol }) => symbol === searchedValue);
    return localize(field?.name);
  };

  const findEnumSymbols = (): string[] => {
    if (!queryNode) return [];
    if (currentField?.allowedValues?.[0]) {
      if (!['string', 'number'].includes(typeof currentField.allowedValues[0])) {
        return (currentField.allowedValues as SmartQueryEnumItem[]).map(({ symbol }) => symbol);
      }
      return currentField.allowedValues.map((value) => String(value));
    }
    return [];
  };

  const getAllowedOperators = (fieldKey: string) => {
    const field = availableFields.find(({ symbol }) => symbol === fieldKey);
    return field?.allowedOperators;
  };

  const isOperatorDisabled = (operatorSymbol: string, searchedFieldKey?: string): boolean => {
    if (!searchedFieldKey) return false;
    return !getAllowedOperators(searchedFieldKey)?.some(({ symbol }) => symbol === operatorSymbol);
  };

  const findOperatorSymbolLabel = (operator: SmartQueryOperatorResponse): string => {
    let operatorLabel: LocaleKeys | undefined;
    if (isDateTime) {
      operatorLabel = dateOperatorLabel(operator.symbol) as LocaleKeys;
    }
    return operatorLabel ? t(operatorLabel).toString() : localize(operator.name);
  };

  const handleChangeStatementField = (newFieldKey?: string) => {
    if (!queryNode) return;
    if (newFieldKey) {
      editQueryNodeWith((editableQueryNode) => {
        const newField = availableFields.find(({ symbol }) => symbol === newFieldKey);
        if (!newField) return;
        switch (newField.type) {
          case 'DATETIME':
            setIsDynamicDate(false);
            editableQueryNode.value = DateTime.now().toISODate() as string;
            break;
          case 'ENUM':
            if (newField.allowedValues?.[0]) {
              if (typeof newField.allowedValues[0] === 'number' || typeof newField.allowedValues[0] === 'string') {
                editableQueryNode.value = newField.allowedValues[0];
              } else {
                editableQueryNode.value = (newField.allowedValues[0] as SmartQueryEnumItem).symbol;
              }
            }
            break;
          case 'NUMBER':
            editableQueryNode.value =
              newField.symbol === SmartQueryFieldSymbol.YEAR_RELEASED.toString() ? new Date().getFullYear() : 1;
            setTextFieldValueBuffer(editableQueryNode.value);
            break;
          case 'STRING':
            editableQueryNode.value = '';
            setTextFieldValueBuffer(editableQueryNode.value);
            break;
        }
        if (isOperatorDisabled(queryNode.operator, newFieldKey)) {
          editableQueryNode.operator = getAllowedOperators(newFieldKey)?.[0].symbol || SmartQueryOperatorSymbol.EQ;
        }
        editableQueryNode.field = newFieldKey as SmartQueryFieldSymbol;
      });
    }
  };

  const renderValueTypeEditorComponent = (): JSX.Element => {
    if (!queryNode) return <></>;
    switch (currentField?.type) {
      case 'ENUM':
        return (
          <Autocomplete
            role="combobox"
            className={classes.statementField}
            value={
              queryNode.operator === 'any-of' ? (flatten([queryNode.value]) as string[]) : (queryNode.value as string)
            }
            options={findEnumSymbols()}
            multiple={queryNode.operator === 'any-of'}
            onChange={(_, newValue) => handleChangeStatementValueDebounced(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                color={formControlColor}
                className={classes.textField}
                variant="standard"
                data-testid={testIds.valueInput(queryNode.key)}
              />
            )}
            clearOnEscape
            disableClearable
            autoComplete
            color={formControlColor}
            disabled={!hasCollectionUpsertPermission || isDeleted}
          />
        );
      case 'DATETIME':
        return (
          <CollectionQueryDateDropdown
            className={classes.statementField}
            isDynamicDate={isDynamicDate}
            value={queryNode.value as string}
            onChange={handleChangeStatementValueDebounced}
            nodeKey={queryNode.key}
            operator={queryNode.operator}
            disabled={!hasCollectionUpsertPermission || isDeleted}
          />
        );
      case 'NUMBER':
      case 'STRING':
      default: {
        const isNumericInput = currentField?.type === 'NUMBER';
        const numericInputProps = (
          isNumericInput
            ? {
                type: 'number',
                inputMode: 'numeric',
                pattern: '[0-9]*',
                ...(currentField?.symbol === SmartQueryFieldSymbol.YEAR_RELEASED
                  ? { min: 1890, max: new Date().getFullYear() + 5 }
                  : {})
              }
            : {}
        ) as InputBaseComponentProps;
        if (currentField?.allowSuggestions) {
          return (
            <SuggestionsAutocomplete
              classNames={{ autocomplete: classes.statementField, textField: classes.textField }}
              propertyName={currentField.allowSuggestions.propertyName}
              value={textFieldValueBuffer as string}
              onChange={(newValue) => {
                handleChangeStatementValueDebounced(newValue);
                setTextFieldValueBuffer(newValue);
              }}
              disabled={isDeleted}
              data-testid={testIds.valueInput(queryNode.key)}
            />
          );
        }
        if (queryNode.operator === SmartQueryOperatorSymbol.ANY_OF) {
          const currentValue = typeof queryNode.value === 'string' ? [queryNode.value] : (queryNode.value as string[]);
          return (
            <Autocomplete
              multiple
              freeSolo
              role="combobox"
              className={classes.statementField}
              options={[]}
              value={currentValue}
              onChange={(_, newValue) => handleChangeStatementValueDebounced(newValue)}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => {
                  return <Chip {...getTagProps({ index })} key={index} label={option} />;
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  color={formControlColor}
                  className={classes.textField}
                  variant="standard"
                  data-testid={testIds.valueInput(queryNode.key)}
                />
              )}
              clearOnEscape
              disableClearable
              color={formControlColor}
              disabled={!hasCollectionUpsertPermission || isDeleted}
            />
          );
        }
        return (
          <TextField
            value={textFieldValueBuffer?.toString()}
            className={cx(classes.textField, classes.statementField)}
            color={formControlColor}
            variant="standard"
            inputProps={numericInputProps}
            onChange={(event) => {
              const newValue = event.target.value?.trimStart();
              const parsedNewValue = isNumericInput ? Number(newValue) : newValue;
              setTextFieldValueBuffer(parsedNewValue);
              handleChangeStatementValueDebounced(parsedNewValue);
            }}
            disabled={!hasCollectionUpsertPermission || isDeleted}
            data-testid={testIds.valueInput(queryNode.key)}
          />
        );
      }
    }
  };

  if (!queryNode) return <></>;
  return (
    <div className={classes.container} data-testid={testIds.container(queryNode.key)}>
      <div className={classes.leftContent}>
        {isConjunction && (
          <div className={classes.matchText} data-testid={testIds.groupForm(queryNode.key)}>
            {t('query_builder.match_rules_left')}
            <StyledToggleButtonGroup
              value={queryNode.operator}
              exclusive
              size="small"
              aria-label="query operator"
              disabled={!hasCollectionUpsertPermission || isDeleted}
              onChange={(_, newOperator) => handleChangeOperator(newOperator)}
            >
              <ToggleButton
                value={SmartQueryOperatorSymbol.AND}
                aria-label={t('query_builder.operators.all')}
                data-testid={testIds.andButton(queryNode.key)}
              >
                <div className={classes.toggleButtonContent}>{t('query_builder.operators.all')}</div>
              </ToggleButton>
              <ToggleButton
                value={SmartQueryOperatorSymbol.OR}
                aria-label={t('query_builder.operators.any')}
                data-testid={testIds.orButton(queryNode.key)}
              >
                <div className={classes.toggleButtonContent}>{t('query_builder.operators.any')}</div>
              </ToggleButton>
            </StyledToggleButtonGroup>
            {t('query_builder.match_rules_right')}:
          </div>
        )}
        {!isConjunction && (
          <div className={classes.statement} data-testid={testIds.ruleForm(queryNode.key)}>
            <div className={classes.fieldChooser}>
              <Autocomplete
                value={queryNode.field}
                options={availableFields.map(({ symbol }) => symbol)}
                getOptionLabel={findFieldSymbolLabel}
                onChange={(_, newField) => handleChangeStatementField(newField)}
                renderInput={(params) => (
                  <TextField {...params} className={classes.textField} color={formControlColor} variant="standard" />
                )}
                clearOnEscape
                disableClearable
                autoComplete
                data-testid={testIds.fieldSelect(queryNode.key)}
                color={formControlColor}
                disabled={!hasCollectionUpsertPermission || isDeleted}
              />
            </div>
            <StyledToggleButtonGroup
              value={queryNode.operator}
              exclusive
              size="large"
              aria-label="query operator"
              onChange={(_, newOperator) => handleChangeOperator(newOperator)}
              disabled={!hasCollectionUpsertPermission || isDeleted}
            >
              {queryOperators.map((operator: SmartQueryOperatorResponse) => {
                const toggleButtonLabel = findOperatorSymbolLabel(operator);
                const toggleButtonCaption = getOperatorSymbol(operator.symbol) || toggleButtonLabel;
                const disabled = isOperatorDisabled(operator.symbol, queryNode.field);
                return (
                  !disabled && (
                    <ToggleButton
                      key={operator.symbol}
                      value={operator.symbol}
                      disabled={disabled || !hasCollectionUpsertPermission}
                      aria-label={toggleButtonLabel}
                      data-testid={testIds.statementOperator(queryNode.key, operator.symbol)}
                    >
                      <Tooltip title={toggleButtonLabel} placement="top">
                        <div className={classes.toggleButtonContent}>{toggleButtonCaption}</div>
                      </Tooltip>
                    </ToggleButton>
                  )
                );
              })}
            </StyledToggleButtonGroup>

            {renderValueTypeEditorComponent()}
          </div>
        )}
      </div>
      <div className={classes.rightContent} style={{ alignItems: isConjunction ? 'center' : 'flex-start' }}>
        {isConjunction && (
          <>
            <IconButton
              onClick={handleAddRuleNode}
              title={t('query_builder.add_rule')}
              data-testid={testIds.addRuleNodeButton(queryNode.key)}
              disabled={!hasCollectionUpsertPermission || isDeleted}
            >
              <FilterAltOutlined fontSize="small" />
            </IconButton>

            <IconButton
              onClick={handleAddGroupNode}
              title={t('query_builder.add_group')}
              data-testid={testIds.addGroupNodeButton(queryNode.key)}
              disabled={!hasCollectionUpsertPermission || !canAddGroup()}
            >
              <AccountTreeOutlined fontSize="small" />
            </IconButton>
          </>
        )}
        {currentField?.type === 'DATETIME' && (
          <IconButton
            color={isDynamicDate ? 'success' : undefined}
            title={t(isDynamicDate ? 'query_builder.disable_dynamic_date' : 'query_builder.enable_dynamic_date')}
            onClick={() => toggleDynamicDate()}
            disabled={!hasCollectionUpsertPermission || isDeleted}
          >
            {isDynamicDate ? <EventAvailable fontSize="small" /> : <DateRange fontSize="small" />}
          </IconButton>
        )}
        <IconButton
          onClick={handleRemoveQueryNode}
          title={t('general.delete')}
          color="error"
          data-testid={testIds.removeNodeButton(queryNode.key)}
          disabled={!hasCollectionUpsertPermission || isDeleted}
        >
          <DeleteOutline fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
}

export default CollectionQueryNodeForm;
