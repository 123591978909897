import { DocumentLocale, OutboundUiModuleBody, OutboundUiModuleBodyModuleTypeEnum } from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankOutboundModule: NewModuleFunction<OutboundUiModuleBody> = (module) => ({
  title: {
    [DocumentLocale.ES]: ''
  },
  moduleType: OutboundUiModuleBodyModuleTypeEnum.OUTBOUND,
  moduleId: generateId(),
  ...module
});
