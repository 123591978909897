import { UiModuleBody, withEntitlementsFilter, withPlatformsFilter } from '../../state/Layouts';
import { useRecoilValue } from 'recoil';
import { MenuSectionUiModuleBodyModuleTypeEnum } from '../../API';
import { intersection } from 'lodash-es';

type ReturnType = {
  shouldHideUiModule: (uiModule: UiModuleBody) => boolean;
};

export function useLayoutView(): ReturnType {
  const entitlementsFilter = useRecoilValue(withEntitlementsFilter);
  const platformsFilter = useRecoilValue(withPlatformsFilter);

  const shouldHideUiModule = (uiModule: UiModuleBody) => {
    if (uiModule.moduleType === MenuSectionUiModuleBodyModuleTypeEnum.MENU_SECTION && !uiModule.menuItems?.length) {
      return false;
    }
    return (
      intersection(uiModule.entitlements, entitlementsFilter).length === 0 ||
      intersection(uiModule.targetPlatform, platformsFilter).length === 0
    );
  };

  return {
    shouldHideUiModule
  };
}
