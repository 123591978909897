import React, { forwardRef } from 'react';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { MenuIconName, MenuType, MenuSectionUiModuleBody } from '../../../../../API';
import { useLocales, useTheme } from '../../../../../hooks';
import { FormBody, FormField } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { PagesAutocomplete } from '../PagesAutocomplete';
import TextValidator from '../../../../shared/TextValidator';
import { NONE } from '../../../../../utils/types/genericTypes';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import Select from '../../../../shared/Select';

export const testIds = {
  formBody: 'menu-section-form.form-body',
  actionType: 'menu-section-form.action-type',
  actionTypeItem: 'menu-section-form.action-type-item',
  targetSelector: 'menu-section-form.target-selector',
  targetSelectorItem: 'menu-section-form.target-selector-item',
  keySelector: 'hero-message.key-selector',
  keyOption: 'hero-message.key-option'
};

const MenuSectionForm = forwardRef<UIModuleTypeForm<MenuSectionUiModuleBody>>(() => {
  const { control } = useFormContext<MenuSectionUiModuleBody>();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  return (
    <FormBody data-testid={testIds.formBody}>
      <FormField>
        <Controller
          control={control}
          name="titleLokaliseKey"
          render={({ field: { value, name, onChange } }) => (
            <LokaliseAutocomplete
              label={t('general.title')}
              name={name}
              value={value as string}
              data-testid={testIds.keySelector}
              onChange={onChange}
            />
          )}
        />
      </FormField>
      <FormField>
        <FormControl color={formControlColor} required>
          <Controller
            control={control}
            name="targetId"
            render={({ field: { value, onChange } }) => (
              <PagesAutocomplete
                value={value}
                onChange={onChange}
                disableClearable={false}
                renderInput={(params) => (
                  <TextValidator
                    value={value}
                    {...params}
                    name="targetId"
                    label={`${t('layouts.target')} (${t('general.optional')})`}
                    color={formControlColor}
                  />
                )}
              />
            )}
          />
        </FormControl>
      </FormField>
      <FormField>
        <FormControl color={formControlColor} required>
          <InputLabel id="iconNameSelector">{t('layouts.menu_item_icon_name')}</InputLabel>
          <Controller
            control={control}
            name="iconName"
            render={({ field: { value, onChange } }) => (
              <Select
                label={t('layouts.menu_item_icon_name')}
                labelId="iconNameSelector"
                value={value || NONE}
                onChange={onChange}
              >
                <MenuItem value={NONE}>
                  <em>{t('general.none')}</em>
                </MenuItem>
                {Object.values(MenuIconName).map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </FormField>
      <FormField>
        <FormControl color={formControlColor}>
          <InputLabel id="pageSelector">{t('layouts.menu_type')}</InputLabel>
          <Controller
            control={control}
            name="type"
            render={({ field: { value, onChange } }) => (
              <Select label={t('layouts.menu_type')} labelId="pageSelector" value={value || NONE} onChange={onChange}>
                <MenuItem value={NONE}>
                  <em>{t('general.none')}</em>
                </MenuItem>
                {Object.values(MenuType).map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </FormField>
    </FormBody>
  );
});

MenuSectionForm.displayName = 'MenuSectionForm';

export default MenuSectionForm;
