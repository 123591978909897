import React from 'react';
import { DataPresetBody, HeroModuleItemType, VersionedDocumentStatus } from '../../../API';
import { Stack, Tooltip, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import ContentPicker from '../../Layouts/UIModuleForm/ContentPicker';
import EPGChannelPicker from '../../Layouts/UIModuleForm/EPGChannelPicker';
import MatchPicker from '../../Layouts/UIModuleForm/MatchPicker';
import { PagesAutocomplete } from '../../Layouts/UIModuleForm/UIModules/PagesAutocomplete';
import TextField from '../TextField';
import { markAsRequired } from '../../../utils/formHelpers';
import { useLocales, useTheme } from '../../../hooks';
import { makeStyles } from 'tss-react/mui';
import CollectionPicker from '../../Layouts/UIModuleForm/CollectionPicker';
import { CollectionPages } from '../CollectionPages';
import { KeysOfUnion } from '../../../utils/types/genericTypes';
import { ContentTypeSelector } from '../../Layouts/UIModuleForm/ContentTypeSelector';
import { getPresetTypeFromHeroModuleType, isPromoPagePath } from '../../../utils/uiModuleUtils';
import { LokaliseAutocomplete } from '../LokaliseAutocomplete';
import AssetBrowser from '../../Assets/AssetBrowser';
import { AssetTypes } from '../../../utils/assetTypes';
import { ScheduleTimePicker } from '../ScheduleTime';
import { HelpText } from '../HelpText/HelpText';
import { shouldDisplayScheduleTimes } from '../../../utils/heroContentItemUtils';

const useStyles = makeStyles()((theme) => ({
  formGrid: {
    display: 'grid',
    gap: theme.spacing(6),
    gridTemplateColumns: 'repeat(2, 1fr)'
  },
  contentPicker: {
    flexGrow: 1,
    gridColumn: 'span 2'
  },
  errorLabel: {
    color: theme.palette.error.main
  },
  pageMenu: {
    marginBottom: '0 !important'
  },
  scheduleTimes: {
    flexGrow: 1,
    gridColumn: 'span 2'
  },
  schedule: {
    marginTop: theme.spacing(2)
  }
}));

type HeroItemFormFieldsProps = {
  isPreset?: boolean;
  children?: React.ReactNode;
};

type DataKeys = KeysOfUnion<DataPresetBody['data']>;

export function HeroItemFormFields({ isPreset, children }: HeroItemFormFieldsProps): React.ReactElement {
  const { formControlColor } = useTheme();
  const { t } = useLocales();
  const { classes, cx } = useStyles();
  const { control, setValue, watch, clearErrors } = useFormContext();

  const getName = (field: DataKeys) => (isPreset ? `data.${field}` : field) as DataKeys;

  const [heroItemType, title, logoImage, portraitLogoImage] = watch([
    getName('heroItemType'),
    getName('title'),
    getName('logoImage'),
    getName('portraitLogoImage')
  ]);

  const renderFormLabel = (label: string, hasError?: boolean) => (
    <Typography
      component="div"
      color="textSecondary"
      variant="body2"
      className={cx({ [classes.errorLabel]: hasError })}
    >
      {label}
    </Typography>
  );

  const onContentTypeChange = (value: HeroModuleItemType) => {
    if (isPreset) {
      const presetType = getPresetTypeFromHeroModuleType(value);
      setValue(getName('type'), presetType);
      setValue('type', presetType);
    }
    setValue(getName('heroItemType'), value);
    setValue(getName('contentId'), '');
    setValue(getName('channelId'), '');
    setValue(getName('collectionId'), '');
    setValue(getName('sportsEventId'), '');
    setValue(getName('pagePath'), '');
    clearErrors([
      getName('contentId'),
      getName('channelId'),
      getName('collectionId'),
      getName('sportsEventId'),
      getName('pagePath')
    ]);
    if (value === HeroModuleItemType.EVENTS || value === HeroModuleItemType.PROMOTION) {
      setValue(getName('logoImage'), '');
      setValue(getName('portraitLogoImage'), '');
      if (value === HeroModuleItemType.EVENTS) {
        setValue(getName('mobileFillImage'), '');
      }
    }
    // Strip out promo specific values when swapping off promo
    if (value !== HeroModuleItemType.PROMOTION) {
      setValue(getName('lokaliseTitle'), '');
      setValue(getName('description'), '');
      setValue(getName('ctaName'), '');
      setValue(getName('priceText'), '');
    }
    setValue(getName('scheduleTimes'), []);
  };

  return (
    <Stack gap={4}>
      <div className={classes.formGrid}>
        <Controller
          control={control}
          name={getName('heroItemType')}
          defaultValue={HeroModuleItemType.VOD}
          render={({ field: { value, onChange } }) => (
            <ContentTypeSelector
              options={HeroModuleItemType}
              value={value}
              onChange={onChange}
              callback={onContentTypeChange}
            />
          )}
        />
        <div>{children}</div>
        {heroItemType === HeroModuleItemType.VOD && (
          <Controller
            control={control}
            name={getName('contentId')}
            rules={{ required: t('general.field_is_required') }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.contentPicker}>
                {renderFormLabel(markAsRequired(t('layouts.content')), !!error)}
                <ContentPicker value={value} onChange={onChange} error={!!error} />
              </div>
            )}
          />
        )}
        {heroItemType === HeroModuleItemType.CHANNEL && (
          <Controller
            control={control}
            name={getName('channelId')}
            rules={{ required: t('general.field_is_required') }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.contentPicker}>
                {renderFormLabel(markAsRequired(t('layouts.content')), !!error)}
                <EPGChannelPicker value={value} onChange={onChange} error={!!error} />
              </div>
            )}
          />
        )}
        {heroItemType === HeroModuleItemType.EVENTS && (
          <Controller
            control={control}
            name={getName('sportsEventId')}
            rules={{ required: t('general.field_is_required') }}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <div className={classes.contentPicker}>
                {renderFormLabel(markAsRequired(t('layouts.content')), !!error)}
                <MatchPicker
                  title={title}
                  onChange={({ contentId: sportsEventId, title }) => {
                    onChange(sportsEventId);
                    setValue(getName('title'), title);
                  }}
                />
              </div>
            )}
          />
        )}
        {(heroItemType === HeroModuleItemType.PAGE || heroItemType === HeroModuleItemType.PROMOTION) && (
          <Controller
            control={control}
            name={getName('pagePath')}
            rules={{ required: t('general.field_is_required') }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div className={classes.contentPicker}>
                <PagesAutocomplete
                  value={value}
                  onChange={onChange}
                  filter={heroItemType === HeroModuleItemType.PROMOTION ? isPromoPagePath : () => true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.pageMenu}
                      label={markAsRequired(t('general.page'))}
                      color={formControlColor}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </div>
            )}
          />
        )}
        {heroItemType === HeroModuleItemType.COLLECTION_LINK && (
          <>
            <Controller
              control={control}
              name={getName('collectionId')}
              rules={{ required: t('general.field_is_required') }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <CollectionPicker
                  required
                  label={t('collections.collection')}
                  value={value}
                  onChange={onChange}
                  filters={{ status: VersionedDocumentStatus.PUBLISHED }}
                  hideEntitlements
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              control={control}
              name={getName('pagePath')}
              rules={{ required: t('general.field_is_required') }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <CollectionPages
                  label={t('general.page')}
                  required
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </>
        )}
        {heroItemType === HeroModuleItemType.CLIPS && (
          <>
            <Controller
              control={control}
              name={getName('collectionId')}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <CollectionPicker
                  label={t('collections.collection')}
                  value={value}
                  onChange={onChange}
                  filters={{ status: VersionedDocumentStatus.PUBLISHED }}
                  hideEntitlements
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              control={control}
              name={getName('pagePath')}
              rules={{ required: t('general.field_is_required') }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <div className={classes.contentPicker}>
                  <PagesAutocomplete
                    value={value}
                    onChange={onChange}
                    filter={heroItemType === HeroModuleItemType.PROMOTION ? isPromoPagePath : () => true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.pageMenu}
                        label={markAsRequired(t('general.page'))}
                        color={formControlColor}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </div>
              )}
            />
          </>
        )}
        {heroItemType === HeroModuleItemType.PAGE && (
          <Controller
            control={control}
            name={getName('ctaName')}
            render={({ field: { value, onChange } }) => (
              <LokaliseAutocomplete
                label={t('layouts.promotion.fields.ctaName')}
                name="ctaName"
                value={value as string}
                onChange={onChange}
                required
                validateLokaliseKey
              />
            )}
          />
        )}
        {heroItemType === HeroModuleItemType.PROMOTION && (
          <>
            <Controller
              control={control}
              name={getName('lokaliseTitle')}
              render={({ field: { value, onChange } }) => (
                <LokaliseAutocomplete
                  label={t('general.title')}
                  name="title"
                  value={value as string}
                  onChange={onChange}
                  required={false}
                />
              )}
            />
            <Controller
              control={control}
              name={getName('description')}
              render={({ field: { value, onChange } }) => (
                <LokaliseAutocomplete
                  label={t('general.description')}
                  name="description"
                  value={value as string}
                  onChange={onChange}
                  required={false}
                />
              )}
            />
            <Controller
              control={control}
              name={getName('ctaName')}
              render={({ field: { value, onChange } }) => (
                <LokaliseAutocomplete
                  label={t('layouts.promotion.fields.ctaName')}
                  name="ctaName"
                  value={value as string}
                  onChange={onChange}
                  required
                  validateLokaliseKey
                />
              )}
            />
            <Controller
              control={control}
              name={getName('priceText')}
              render={({ field: { value, onChange } }) => (
                <LokaliseAutocomplete
                  label={t('layouts.promotion.fields.planTilePrice')}
                  name="priceText"
                  value={value as string}
                  onChange={onChange}
                  required={false}
                />
              )}
            />
          </>
        )}
        <Controller
          control={control}
          name={getName('portraitLogoImage')}
          rules={{ validate: (value) => !!logoImage === !!value }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <AssetBrowser
              assetType={AssetTypes.hero.logoPortrait}
              value={value}
              onChange={onChange}
              label={t('layouts.hero_logo_landscape')}
              hasError={!!error}
            />
          )}
        />
        <Controller
          control={control}
          name={getName('logoImage')}
          rules={{ validate: (value) => !!portraitLogoImage === !!value }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <AssetBrowser
              assetType={AssetTypes.hero.logo}
              value={value}
              onChange={onChange}
              label={t('layouts.hero_logo_portrait')}
              hasError={!!error}
            />
          )}
        />
        <Controller
          control={control}
          name={getName('landscapeFillImage')}
          rules={{ required: true }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <AssetBrowser
              assetType={AssetTypes.hero.landscape}
              value={value}
              onChange={onChange}
              label={markAsRequired(t('layouts.landscape_fill_image'))}
              hasError={!!error}
            />
          )}
        />
        <Controller
          control={control}
          name={getName('portraitFillImage')}
          rules={{ required: true }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <AssetBrowser
              assetType={AssetTypes.hero.portrait}
              value={value}
              onChange={onChange}
              label={markAsRequired(t('layouts.portrait_fill_image'))}
              hasError={!!error}
            />
          )}
        />
        <Controller
          control={control}
          name={getName('mobileFillImage')}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <AssetBrowser
              assetType={AssetTypes.hero.mobile}
              value={value}
              onChange={onChange}
              label={t('layouts.mobile_fill_image')}
              hasError={!!error}
            />
          )}
        />
        <Controller
          control={control}
          name={getName('ctvFillImage')}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <AssetBrowser
              assetType={AssetTypes.hero.ctv}
              value={value}
              onChange={onChange}
              label={t('layouts.ctv_fill_image')}
              hasError={!!error}
            />
          )}
        />
        {shouldDisplayScheduleTimes(heroItemType) && (
          <Controller
            control={control}
            name={getName('scheduleTimes')}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.scheduleTimes}>
                <Tooltip title={<HelpText>{t('scheduled_time.helper')}</HelpText>} placement="top-start">
                  {renderFormLabel(t('scheduled_time.label'), !!error)}
                </Tooltip>
                <ScheduleTimePicker className={classes.schedule} value={value} onChange={onChange} />
              </div>
            )}
          />
        )}
      </div>
    </Stack>
  );
}
