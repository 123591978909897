import React from 'react';
import { TournamentListUiModuleBody } from '../../../../../API';
import Localized from '../../../../shared/Localized';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { TournamentListPreview } from '../../ContentPreview/TournamentListPreview';

export type TournamentListListItemProps = IBaseUIModuleListItemProps<TournamentListUiModuleBody>;

const TournamentListListItem = ({ uiModule, ...props }: TournamentListListItemProps): JSX.Element => {
  return (
    <UIModuleListItem
      uiModule={uiModule}
      topLineTitle={<Localized prop={uiModule.title} />}
      renderPreview={(showPreview) => (
        <TournamentListPreview tournamentIds={uiModule.tournamentReferenceIds || []} showing={showPreview} />
      )}
      {...props}
    />
  );
};

export default TournamentListListItem;
