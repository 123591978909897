import React, { useEffect, useState } from 'react';
import { Search } from '@mui/icons-material';
import { InputAdornment, InputLabel, SelectChangeEvent } from '@mui/material';
import { DateTime } from 'luxon';
import { useRecoilState, useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { useData } from '../../../../data-layer';
import { textFilterToRegExp, useLocales, useTimezones } from '../../../../hooks';
import { withCouponsSplitPane } from '../../../../state/General';
import { DataGrid, DataGridColDef } from '../../../shared/DataGrid';
import { IdBadge } from '../../../shared/IdBadge';
import TextField from '../../../shared/TextField';
import CouponDiscount from '../CouponDiscount/CouponDiscount';
import CouponDuration from '../CouponDuration/CouponDuration';
import PlanBadge from '../../Plans/PlanBadge/PlanBadge';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import { ALL } from '../../../../utils/types/genericTypes';
import FormControl from '../../../shared/FormControl';
import { withCouponsFilters } from '../../../../state/Payments/withPaymentsFilters';
import { CouponCodeState, CouponResponse, CouponType, PermissionsGroupId } from '../../../../API';
import { useNavigate } from 'react-router-dom';
import { usePermissions } from '../../../../hooks/Permissions/usePermissions';
import { AppRoutes } from '../../../../Routes';
import { usePermissionsGuard } from '../../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../../state/theme';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  filters: {
    display: 'flex',
    gap: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  textFilter: {
    width: 400,
    marginBottom: '0 !important'
  },
  gridContainer: {
    display: 'flex',
    flexGrow: 1
  },
  statusFilter: {
    width: 260
  },
  eligiblePlan: {
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(1)
    }
  }
}));

export const testIds = {
  textFilter: 'coupons-grid.text-filter',
  stateFilter: 'coupons-grid.state-filter',
  stateFilterOption: 'coupons-grid.state-filter-option',
  typeFilter: 'coupons-grid.type-filter',
  typeFilterOption: 'coupons-grid.type-filter-option'
};

export function CouponsGrid(): JSX.Element {
  const { t } = useLocales();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const {
    coupons: {
      state: { withAllRecords }
    }
  } = useData();
  const allCoupons = useRecoilValue(withAllRecords);
  const { timestamp } = useTimezones();

  const { hasReadPermission } = usePermissions();
  const { canRead } = usePermissionsGuard({
    homepageOption: HomepageOptions.MONETIZATION
  });
  const hasMonetizationReadPermissions = hasReadPermission(PermissionsGroupId.MONETIZATION) || canRead;

  const [filters, setFilters] = useRecoilState(withCouponsFilters);
  const [filteredCoupons, setFilteredCoupons] = useState<CouponResponse[] | undefined>();

  useEffect(() => {
    updateFilteredCoupons();
  }, [allCoupons, filters]);

  useEffect(() => {
    if (!hasMonetizationReadPermissions) {
      navigate(AppRoutes.welcome);
    }
  }, [hasMonetizationReadPermissions]);

  const stringSortHandleUndefined = (key: keyof CouponResponse) => (a: CouponResponse, b: CouponResponse) => {
    const aProp = a[key] || '';
    const bProp = b[key] || '';
    return String(aProp).localeCompare(String(bProp));
  };

  const dateSortHandleUndefined = (key: keyof CouponResponse) => (a: CouponResponse, b: CouponResponse) => {
    if (!a[key] && !b[key]) return 0;
    if (!a[key]) return 1;
    if (!b[key]) return -1;
    const aProp = DateTime.fromISO(String(a[key]));
    const bProp = DateTime.fromISO(String(b[key]));
    return aProp.diff(bProp).toObject().milliseconds || 0;
  };

  const columns: DataGridColDef<CouponResponse>[] = [
    {
      key: 'name',
      cellRenderer: (coupon) => coupon.name || <i>{t('payments.unnamed_coupon')}</i>,
      label: t('payments.name'),
      sortFunction: stringSortHandleUndefined('name'),
      baseWidth: '12%'
    },
    {
      key: 'code',
      cellRenderer: (coupon) => <>{coupon.code && <IdBadge id={coupon.code} />}</>,
      label: t('payments.coupon_code'),
      sortFunction: stringSortHandleUndefined('code'),
      baseWidth: '12%'
    },
    {
      key: 'status',
      cellRenderer: (coupon) => t(`payments.coupon_status.${coupon.state}`),
      label: t('payments.status'),
      sortFunction: stringSortHandleUndefined('state'),
      baseWidth: '8%'
    },
    {
      key: 'redeemBy',
      cellRenderer: (coupon) => <>{coupon.redeemBy && timestamp(coupon.redeemBy)}</>,
      label: t('payments.redeem_by'),
      sortFunction: dateSortHandleUndefined('redeemBy'),
      baseWidth: '15%'
    },
    {
      key: 'expiredAt',
      cellRenderer: (coupon) => <>{coupon.expiredAt && timestamp(coupon.expiredAt)}</>,
      label: t('payments.expired_at'),
      sortFunction: dateSortHandleUndefined('expiredAt'),
      baseWidth: '15%'
    },
    {
      key: 'couponType',
      cellRenderer: (coupon) => t(`payments.coupon_types.${coupon.couponType}`),
      label: t('payments.coupon_type'),
      sortFunction: stringSortHandleUndefined('couponType'),
      baseWidth: '8%'
    },
    {
      key: 'discount',
      cellRenderer: (coupon) => <CouponDiscount coupon={coupon} />,
      label: t('payments.discount'),
      baseWidth: '8%'
    },
    {
      key: 'duration',
      cellRenderer: (coupon) => <CouponDuration coupon={coupon} />,
      label: t('payments.duration'),
      baseWidth: '8%'
    },
    {
      key: 'plans',
      cellRenderer: (coupon) => (
        <>
          {coupon.plans?.map((plan) => (
            <div key={plan.code} className={classes.eligiblePlan}>
              <PlanBadge planCode={plan.code as string} tooltipPlacement="left" />
            </div>
          ))}
        </>
      ),
      label: t('payments.eligible_plans'),
      baseWidth: '14%'
    }
  ];

  const onTextFilterChanged: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
    setFilters({
      ...filters,
      text: evt.target.value
    });
  };

  const onStateFilterChanged = (evt: SelectChangeEvent<string>) => {
    setFilters({
      ...filters,
      couponState: evt.target.value
    });
  };

  const onTypeFilterChanged = (evt: SelectChangeEvent<string>) => {
    setFilters({
      ...filters,
      couponType: evt.target.value
    });
  };

  const updateFilteredCoupons = () => {
    if (!allCoupons) return;
    const newFilteredCoupons = allCoupons.filter((coupon) => {
      if (filters.text) {
        const textFilterRgx = textFilterToRegExp(filters.text);
        if (!textFilterRgx.test(coupon.code) && !(coupon.name && textFilterRgx.test(coupon.name))) {
          return false;
        }
      }
      if (filters.couponState !== ALL) {
        if (coupon.state !== filters.couponState) {
          return false;
        }
      }
      if (filters.couponType !== ALL) {
        if (coupon.couponType !== filters.couponType) {
          return false;
        }
      }
      return true;
    });
    setFilteredCoupons(newFilteredCoupons);
  };

  return (
    <div className={classes.root}>
      <div className={classes.filters}>
        <TextField
          clearable
          label={t('payments.filter_by_keywords')}
          value={filters.text}
          onChange={onTextFilterChanged}
          className={classes.textFilter}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
          data-testid={testIds.textFilter}
        />

        <FormControl className={classes.statusFilter}>
          <InputLabel shrink variant="standard">
            {t('payments.filter_by_status')}
          </InputLabel>
          <Select
            label={t('payments.filter_by_status')}
            value={filters.couponState}
            onChange={onStateFilterChanged}
            data-testid={testIds.stateFilter}
          >
            <MenuItem value={ALL} data-testid={testIds.stateFilterOption}>
              {t('filters.all')}
            </MenuItem>
            {Object.values(CouponCodeState).map((state) => (
              <MenuItem key={state} value={state} data-testid={testIds.stateFilterOption} data-option={state}>
                {t(`payments.coupon_status.${state}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.statusFilter}>
          <InputLabel shrink variant="standard">
            {t('payments.filter_by_type')}
          </InputLabel>
          <Select
            label={t('payments.filter_by_type')}
            value={filters.couponType}
            onChange={onTypeFilterChanged}
            data-testid={testIds.typeFilter}
          >
            <MenuItem value={ALL} data-testid={testIds.typeFilterOption}>
              {t('filters.all')}
            </MenuItem>
            {Object.values(CouponType).map((couponType) => (
              <MenuItem key={couponType} value={couponType} data-testid={testIds.typeFilterOption}>
                {t(`payments.coupon_types.${couponType}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={classes.gridContainer}>
        <DataGrid
          rows={filteredCoupons}
          columns={columns}
          withColumnSizes={withCouponsSplitPane}
          useVirtualization
          rowKey="id"
        />
      </div>
    </div>
  );
}
