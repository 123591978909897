import React, { useEffect, useState } from 'react';
import { LocalizedFieldBody } from '../../../API';
import { useLocales } from '../../../hooks';
import { getAssetUrl } from '../../../utils/assetHelpers';

type AssetImageProps = {
  className?: string;
  path?: LocalizedFieldBody | string;
  size?: number;
  attribute?: string;
  'data-testid'?: string;
};

function AssetImage({
  className,
  path,
  size = 40,
  attribute = 'h',
  'data-testid': dataTestId
}: AssetImageProps): JSX.Element {
  const [error, setError] = useState(false);
  const { localize } = useLocales();

  useEffect(() => {
    setError(false);
  }, [path]);

  const logoPath = typeof path === 'string' ? path : localize(path as LocalizedFieldBody);

  const onError = () => {
    setError(true);
  };

  if (logoPath && !error) {
    return (
      <img
        className={className}
        src={`${getAssetUrl(logoPath)}?tr=${attribute}-${size * 2}`}
        style={{ height: size }}
        onError={onError}
        data-testid={dataTestId}
      />
    );
  }

  return <></>;
}

export default AssetImage;
