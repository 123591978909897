import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { InputLabel, MenuItem, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Controller, useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { LayoutType, TournamentListUiModuleBody, TournamentResponse } from '../../../../../API';
import { useData } from '../../../../../data-layer';
import { useLocales, useNotifications } from '../../../../../hooks';
import { LEAGUE_ID_PARAM } from '../../../../../utils/consts/uiModules';
import { markAsRequired } from '../../../../../utils/formHelpers';
import FormControl from '../../../../shared/FormControl';
import Localized from '../../../../shared/Localized';
import { TransferList } from '../../../../shared/TransferList';
import { FormBody } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { ALL } from '../../../../../utils/types/genericTypes';
import Select from '../../../../shared/Select';

const useStyles = makeStyles()((theme) => ({
  formFieldHorizontal: {
    display: 'inline-flex',
    padding: theme.spacing(0, 4, 4, 0),
    gap: theme.spacing(9)
  },
  tournamentListContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 4, 4, 0),
    gap: theme.spacing(4)
  },
  transferListContainer: {
    position: 'relative',
    flex: 1
  },
  selector: {
    minWidth: 260
  }
}));

export const testIds = {
  formBody: 'tournament-list-module-form.form-body',
  sportSelect: 'tournament-list-module-form.sport-select',
  sportSelectItem: 'tournament-list-module-form.sport-select-item'
};

const TournamentListForm = forwardRef<UIModuleTypeForm<TournamentListUiModuleBody>>((_, ref) => {
  const { control, watch, setValue } = useFormContext<TournamentListUiModuleBody>();

  const { classes } = useStyles();
  const { t, localize } = useLocales();
  const { notifyError } = useNotifications();

  const [tournamentIds, templateUrlPath] = watch(['tournamentReferenceIds', 'templateUrlPath']);

  const {
    sports: {
      state: { withAllRecords: withAllSports },
      hook: { getAll: getAllSports }
    },
    tournaments: {
      state: { withAllRecords: withAllTournaments, withIsFetching: withIsFetchingTournaments },
      hook: { getAll: getAllTournaments }
    },
    pages: {
      state: { withRecordBucket: withPagesByType }
    }
  } = useData();

  const sports = useRecoilValue(withAllSports);
  const tournaments = useRecoilValue(withAllTournaments);
  const pages = useRecoilValue(withPagesByType(LayoutType.PAGE));
  const isFetchingTournaments = useRecoilValue(withIsFetchingTournaments);

  const [availableLeagues, setAvailableLeagues] = useState([] as TournamentResponse[]);
  const [selectedLeagues, setSelectedLeagues] = useState([] as TournamentResponse[]);
  const [selectedSport, setSelectedSport] = useState(ALL);

  const availablePages = pages?.filter((page) => page.urlPath.search(LEAGUE_ID_PARAM) !== -1);

  useImperativeHandle(ref, () => ({
    isValid(uiModule) {
      let valid = true;

      if (valid && !uiModule.tournamentReferenceIds?.length) {
        notifyError(t('errors.layouts.empty_tournament_list'));
        valid = false;
      }

      if (valid && !uiModule.templateUrlPath.length) {
        notifyError(t('errors.layouts.empty_template_url_path'));
        valid = false;
      }

      return valid;
    }
  }));

  useEffect(() => {
    if (!sports) getAllSports();
    if (!tournaments) getAllTournaments();
    if (availablePages?.length && !templateUrlPath) {
      setValue('templateUrlPath', availablePages[0].urlPath);
    }
  }, []);

  useEffect(() => {
    if (!tournaments?.length) return;
    setAvailableLeagues(tournaments.filter((league) => selectedSport === ALL || league.sport === selectedSport));
    setSelectedLeagues(
      tournamentIds?.reduce<TournamentResponse[]>((m, currentId) => {
        const league = tournaments.find(({ id }) => id === currentId);
        if (league) m.push(league);
        return m;
      }, []) || []
    );
  }, [tournaments, selectedSport]);

  useEffect(() => {
    if (!tournaments?.length) return;
    setValue(
      'tournamentReferenceIds',
      selectedLeagues.map(({ id }) => id)
    );
    setValue('tournamentIds', []);
  }, [selectedLeagues]);

  return (
    <FormBody data-testid={testIds.formBody}>
      <div className={classes.formFieldHorizontal}>
        <FormControl fullWidth>
          <InputLabel id="page-select-label">{markAsRequired(t('layouts.template_url_path'))}</InputLabel>
          <Controller
            control={control}
            name="templateUrlPath"
            render={({ field: { value, onChange } }) => (
              <Select
                label={t('layouts.template_url_path')}
                labelId="page-select-label"
                value={value}
                disabled={!availablePages}
                onChange={onChange}
                required
              >
                {availablePages &&
                  availablePages.map((page) => (
                    <MenuItem key={page.urlPath} value={page.urlPath} data-page-key={page.urlPath}>
                      {page.urlPath}
                    </MenuItem>
                  ))}
              </Select>
            )}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="sports-select-label">{t('sports.sport')}</InputLabel>
          {sports && (
            <Select
              label={t('sports.sport')}
              className={classes.selector}
              labelId="sports-select-label"
              value={selectedSport}
              onChange={({ target: { value } }) => setSelectedSport(value)}
              data-testid={testIds.sportSelect}
            >
              <MenuItem value={ALL} data-testid={testIds.sportSelectItem} data-value={ALL}>
                {t('layouts.all_sports')}
              </MenuItem>
              {sports.map((sport, i) => (
                <MenuItem key={i} value={sport.id} data-testid={testIds.sportSelectItem} data-value={sport.id}>
                  <Localized prop={sport.name} />
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </div>
      <div className={classes.tournamentListContent}>
        <div className={classes.transferListContainer}>
          <TransferList
            leftTitle={
              <Typography variant="body2" color="textSecondary">
                {t('leagues.selected_leagues')}
              </Typography>
            }
            rightTitle={
              <Typography variant="body2" color="textSecondary">
                {t('leagues.available_leagues')}
              </Typography>
            }
            leftList={selectedLeagues}
            rightList={availableLeagues}
            setLeftList={setSelectedLeagues}
            isLoading={isFetchingTournaments}
            skeletonNumber={30}
            keyExtractor={(child: TournamentResponse) => child.id}
            renderChild={(child: TournamentResponse) => <Localized prop={child.name} />}
            searchFn={(text, child) => localize(child.name).toLowerCase().search(text.toLowerCase()) !== -1}
          />
        </div>
      </div>
    </FormBody>
  );
});

TournamentListForm.displayName = 'TournamentListForm';

export default TournamentListForm;
