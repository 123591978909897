import { Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ContentPreview } from '..';
import { TournamentResponse } from '../../../../../API';
import { useData } from '../../../../../data-layer';
import { withAllSettings } from '../../../../../state/Config';
import { getAssetUrl, getImageBucketPath } from '../../../../../utils/assetHelpers';
import Localized from '../../../../shared/Localized';

export interface ITournamentListPreviewProps {
  tournamentIds: string[];
  showing: boolean;
}

const useStyles = makeStyles()(() => ({
  landscapeThumbnail: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  landscapeBackgroundImg: {
    width: '100%'
  }
}));

export const testIds = {
  thumbnail: 'tournament-list-preview.thumbnail'
};

export function TournamentListPreview({ tournamentIds, showing }: ITournamentListPreviewProps): React.ReactElement {
  const { classes } = useStyles();
  const {
    tournaments: {
      state: { withAllRecords: withAllTournaments, withAllRecordsById: withTournamentsById },
      hook: { getAll: getAllTournaments }
    }
  } = useData();
  const [tournaments, setTournaments] = useState<TournamentResponse[]>();
  const allTournaments = useRecoilValue(withAllTournaments);
  const tournamentsById = useRecoilValue(withTournamentsById);
  const config = useRecoilValue(withAllSettings);

  useEffect(() => {
    if (showing && !allTournaments) {
      getAllTournaments();
    }
  }, [showing]);

  useEffect(() => {
    if (!allTournaments) return;
    populateTournaments();
  }, [allTournaments, tournamentIds]);

  const populateTournaments = () => {
    setTournaments(tournamentIds.map((tournamentId) => tournamentsById[tournamentId]));
  };

  const tournamentListImage = (tournament: TournamentResponse) => {
    const background = tournament.cardBackgroundImage ?? config?.sports.defaultTournamentBackgroundImage;
    if (!background) return '/assets/vix-landscape-fallback.jpeg';
    const logo = tournament.logoImage ?? config?.sports.defaultTeamLogoImage;
    const urlParts = [`${background}?tr=w-200,h-200,ow-125`];
    if (logo) {
      urlParts.push(`,oit-false,oi-${getImageBucketPath()}/${logo}`);
    }
    return getAssetUrl(urlParts.join());
  };

  const thumbnailComponent = (tournament: TournamentResponse) => {
    return (
      <Tooltip title={<Localized prop={tournament.name} />}>
        <div className={classes.landscapeThumbnail} data-testid={testIds.thumbnail}>
          <img className={classes.landscapeBackgroundImg} src={tournamentListImage(tournament)} />
        </div>
      </Tooltip>
    );
  };

  return (
    <ContentPreview
      showing={showing}
      displayAs="square" // Only square supported for now
      model={{
        contentList: tournaments,
        thumbnailComponent,
        idField: 'id'
      }}
      loaderCount={tournamentIds.length}
    />
  );
}
