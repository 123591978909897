import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import React, { forwardRef } from 'react';
import { Controller, FieldError, Path, useFieldArray, useFormContext } from 'react-hook-form';
import {
  OneBrandPromoUiModuleBody,
  OneBrandPromoUiModuleBodyPaymentMethodOptionBody,
  OneBrandPromoUiModuleBodyPlanCardPartnerLogoBody
} from '../../../../../API';
import { useLocales, useTheme } from '../../../../../hooks';
import { LocaleKeys } from '../../../../../locales/i18n';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { stringToNumber } from '../../../../../utils/consts/uiModules';
import { markAsRequired } from '../../../../../utils/formHelpers';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import TextValidator from '../../../../shared/TextValidator';
import { FormBody, FormField } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';
import Button from '../../../../shared/Button';
import { Add, Delete } from '@mui/icons-material';
import generateId from '../../../../../utils/generateId';
import { Sortable } from '../../../../shared/Sortable';
import IconButton from '../../../../shared/IconButton';
import FoldableSection from '../../../../shared/FoldableSection';
import { withStyles } from 'tss-react/mui';
import { withLayoutErrors } from '../../../../../state/Layouts';
import { useRecoilValue } from 'recoil';
import { intersection } from 'lodash';

const CustomFoldableSection = withStyles(FoldableSection, (theme) => ({
  root: {
    ['&.MuiAccordion-root']: {
      backgroundColor: theme.palette.background.default
    }
  },
  region: {
    ['& .MuiAccordionDetails-root']: {
      padding: theme.spacing(2)
    }
  }
}));

export const testIds = {
  formBody: 'one-brand-promo.form-body',
  formField: (
    name:
      | Path<OneBrandPromoUiModuleBody>
      | keyof OneBrandPromoUiModuleBodyPaymentMethodOptionBody
      | keyof OneBrandPromoUiModuleBodyPlanCardPartnerLogoBody
  ): string => `one-brand-promo.form-field.${name}`,
  paymentMethodOptions: 'one-brand-promo.payment-method-options',
  paymentMethodOptionsAddButton: 'one-brand-promo.payment-method-options-add-button',
  paymentMethodOptionsRemoveButton: 'one-brand-promo.payment-method-options-remove-button',
  planCardPartnerLogos: 'one-brand-promo.plan-card-partner-logos',
  planCardPartnerLogosAddButton: 'one-brand-promo.plan-card-partner-logos-add-button',
  planCardPartnerLogosRemoveButton: 'one-brand-promo.plan-card-partner-logos-remove-button'
};

type OneBrandPromoFieldProps = {
  name: Path<OneBrandPromoUiModuleBody>;
  required?: boolean;
  'data-testid'?: string;
};

interface OneBrandPromoPaymentMethodOptionsFieldProps extends Omit<OneBrandPromoFieldProps, 'name'> {
  name: keyof OneBrandPromoUiModuleBodyPaymentMethodOptionBody;
  assetType: AssetTypesEnum;
  position: number;
}

interface OneBrandPromoPartnerLogoFieldProps extends Omit<OneBrandPromoFieldProps, 'name'> {
  name: keyof OneBrandPromoUiModuleBodyPlanCardPartnerLogoBody;
  assetType: AssetTypesEnum;
  position: number;
}

const OneBrandPromoForm = forwardRef<UIModuleTypeForm<OneBrandPromoUiModuleBody>>(() => {
  const { control, watch } = useFormContext<OneBrandPromoUiModuleBody>();
  const { t } = useLocales();
  const { formControlColor, theme } = useTheme();
  const layoutErrors = useRecoilValue(withLayoutErrors);
  const {
    append: appendPaymentMethod,
    remove: removePaymentMethod,
    fields: fieldsPaymentMethod,
    replace: replacePaymentMethod
  } = useFieldArray({ control, name: 'paymentMethodOptions' });
  const {
    append: appendPartnerLogo,
    remove: removePartnerLogo,
    fields: fieldsPartnerLogo,
    replace: replacePartnerLogo
  } = useFieldArray({ control, name: 'planCardPartnerLogos' });

  const moduleId = watch('moduleId');

  const renderAssetPicker = (
    name:
      | Path<OneBrandPromoUiModuleBody>
      | keyof OneBrandPromoUiModuleBodyPaymentMethodOptionBody
      | keyof OneBrandPromoUiModuleBodyPlanCardPartnerLogoBody,
    assetType: AssetTypesEnum,
    moduleId: string,
    required: boolean,
    value: string,
    onChange: (event: string | React.ChangeEvent<Element>) => void,
    error: FieldError | undefined
  ) => (
    <FormField>
      <AssetBrowser
        label={markAsRequired(t(`layouts.one_brand_promo.fields.${name}`), required)}
        assetId={moduleId}
        assetType={assetType}
        value={value}
        data-testid={testIds.formField(name)}
        hasError={!!error}
        onChange={onChange}
      />
    </FormField>
  );

  const AssetPicker = ({
    name,
    assetType,
    required = false
  }: OneBrandPromoFieldProps & { assetType: AssetTypesEnum }): React.ReactElement => (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) =>
        renderAssetPicker(name, assetType, moduleId || '', required, value as string, onChange, error)
      }
    />
  );

  const AssetPickerPaymentMethodOptions = ({
    name,
    assetType,
    position,
    required = false
  }: OneBrandPromoPaymentMethodOptionsFieldProps): React.ReactElement => (
    <Controller
      control={control}
      name={`paymentMethodOptions.${position}.${name}`}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) =>
        renderAssetPicker(name, assetType, moduleId || '', required, value as string, onChange, error)
      }
    />
  );

  const AssetPickerPartnerLogo = ({
    name,
    assetType,
    position,
    required = false
  }: OneBrandPromoPartnerLogoFieldProps): React.ReactElement => (
    <Controller
      control={control}
      name={`planCardPartnerLogos.${position}.${name}`}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) =>
        renderAssetPicker(name, assetType, moduleId || '', required, value as string, onChange, error)
      }
    />
  );

  const LokaliseField = (name: Path<OneBrandPromoUiModuleBody>, required = false): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.one_brand_promo.fields.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  const LokaliseFieldPaymentMethodOptions = (
    name: keyof OneBrandPromoUiModuleBodyPaymentMethodOptionBody,
    position: number,
    required = false
  ): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={`paymentMethodOptions.${position}.${name}`}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.one_brand_promo.fields.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  const CheckboxField = ({ name }: OneBrandPromoFieldProps): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <FormControlLabel
            label={t(`layouts.one_brand_promo.fields.${name}` as LocaleKeys)}
            control={
              <Checkbox
                name={t('layouts.use_collection_name')}
                checked={value as boolean}
                color={formControlColor}
                onChange={onChange}
                data-testid={testIds.formField(name)}
              />
            }
          />
        )}
      />
    </FormField>
  );

  const NumberField = ({ name }: OneBrandPromoFieldProps): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <TextValidator
            type="number"
            name={name}
            value={value}
            label={t(`layouts.one_brand_promo.fields.${name}` as LocaleKeys)}
            validators={['minNumber:0', 'matchRegexp:^[0-9]+$']}
            color={formControlColor}
            fullWidth
            onChange={({ target }) => onChange(stringToNumber((target as HTMLInputElement).value))}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  const TextField = ({ name }: OneBrandPromoFieldProps): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value } }) => (
          <TextValidator
            type="text"
            disabled={true}
            name={name}
            value={value}
            label={t(`layouts.one_brand_promo.fields.${name}` as LocaleKeys)}
            color={formControlColor}
            fullWidth
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  const headerColor = (required: string[]) =>
    intersection(layoutErrors, required).length
      ? {
          color: theme.palette.error.main
        }
      : {};

  const onAddPaymentMethodOptions = () => {
    appendPaymentMethod({
      id: generateId(),
      paymentMethodOptionIcon: '',
      paymentMethodOptionText: ''
    });
  };

  const onAddPartnerLogos = () => {
    appendPartnerLogo({
      id: generateId(),
      planCardPartnerLogo: ''
    });
  };

  return (
    <FormBody data-testid={testIds.formBody}>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor(['landscapeFillImage', 'portraitFillImage'])} variant="h6">
            {t('layouts.one_brand_promo.headers.images')}
          </Typography>
        }
        expanded
        disableGutters
      >
        <AssetPicker name="landscapeFillImage" assetType={AssetTypes.oneBrandPromo.landscape} required />
        <AssetPicker name="portraitFillImage" assetType={AssetTypes.oneBrandPromo.portrait} required />
        <AssetPicker name="desktopFillImage" assetType={AssetTypes.oneBrandPromo.desktop} />
        <AssetPicker name="mobileFillImage" assetType={AssetTypes.oneBrandPromo.mobile} />
        <AssetPicker name="ctvFillImage" assetType={AssetTypes.oneBrandPromo.ctv} />
        <AssetPicker name="tabletFillImage" assetType={AssetTypes.oneBrandPromo.tablet} />
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor(['popupHeader', 'popupSubheader', 'popupCtaText'])} variant="h6">
            {t('layouts.one_brand_promo.headers.popup_paywall')}
          </Typography>
        }
        expanded
        disableGutters
      >
        {LokaliseField('popupHeader', true)}
        {LokaliseField('popupSubheader', true)}
        {LokaliseField('popupText')}
        {LokaliseField('popupCtaText', true)}
        {LokaliseField('popupAnonSignInCtaText')}
        {LokaliseField('popupAuthSignInCtaText')}
        {LokaliseField('popupValuePropositions')}
        {LokaliseField('popupLegalDisclosure')}
        {LokaliseField('popupGiftCardCta')}
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor(['fullHeader', 'fullSubheader', 'fullCtaText'])} variant="h6">
            {t('layouts.one_brand_promo.headers.full_paywall')}
          </Typography>
        }
        expanded
        disableGutters
      >
        {LokaliseField('fullHeader', true)}
        {LokaliseField('fullSubheader', true)}
        {LokaliseField('fullValuePropositions')}
        {LokaliseField('fullCtaText', true)}
        {LokaliseField('fullText')}
        {LokaliseField('fullAnonSignInCtaText')}
        {LokaliseField('fullAuthSignInCtaText')}
        {LokaliseField('fullLegalDisclosure')}
        {LokaliseField('fullGiftCardCta')}
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={<Typography variant="h6">{t('layouts.one_brand_promo.headers.plan_picker')}</Typography>}
        disableGutters
      >
        {LokaliseField('planPickerHeader')}
        {LokaliseField('planPickerSubheader')}
        {LokaliseField('planPickerPlanName')}
        {LokaliseField('planPickerPlanDescription')}
        {LokaliseField('planPickerPlanPrice')}
        {LokaliseField('planPickerPlanSavings')}
        <CheckboxField name="isPlanPickerPlanHighlighted" />
        <CheckboxField name="isPlanPickerPlanDefault" />
        {LokaliseField('planPickerValuePropositionHeader')}
        <NumberField name="planPickerPlanPriority" />
        {LokaliseField('planPickerValuePropositions')}
        {LokaliseField('planPickerFreeAccountCtaText')}
        {LokaliseField('planPickerLegalDisclosure')}
        {LokaliseField('planPickerInstructions')}
        {LokaliseField('planPickerPartnerDisclaimer')}
        {LokaliseField('ctaAcceptChange')}
        {LokaliseField('headerFreeCard')}
        {LokaliseField('subheaderFreeCard')}
        {LokaliseField('listOfLostBenefits')}
        {LokaliseField('ctaCancelPremium')}
        {LokaliseField('ctaKeepSubscription')}
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={<Typography variant="h6">{t('layouts.one_brand_promo.headers.plan_switching')}</Typography>}
        disableGutters
      >
        {LokaliseField('planSwitchingHeader')}
        {LokaliseField('planSwitchingSubheader')}
        {LokaliseField('planPickerPriceTag')}
        {LokaliseField('planPickerCurrentPriceTag')}
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={<Typography variant="h6">{t('payments.payment_method')}</Typography>}
        disableGutters
      >
        {LokaliseField('paymentMethodHeader')}
        <div>
          <Sortable
            list={fieldsPaymentMethod}
            setList={replacePaymentMethod}
            ghostClass="sortableGhost"
            dragClass="sortableDragDefault"
          >
            {fieldsPaymentMethod.map((paymentMethod, i) => (
              <Stack
                key={paymentMethod.id}
                data-testid={testIds.paymentMethodOptions}
                gap={6}
                marginBottom={5}
                direction="row"
                alignItems="center"
              >
                <AssetPickerPaymentMethodOptions
                  name="paymentMethodOptionIcon"
                  assetType={AssetTypes.oneBrandPromo.ctv}
                  position={i}
                />
                {LokaliseFieldPaymentMethodOptions('paymentMethodOptionText', i)}
                <IconButton
                  title={t('layouts.one_brand_promo.actions.remove_payment_method')}
                  disabled={fieldsPaymentMethod.length === 1}
                  onClick={() => removePaymentMethod(i)}
                  sx={{ mt: 4 }}
                  data-testid={testIds.paymentMethodOptionsRemoveButton}
                >
                  <Delete />
                </IconButton>
              </Stack>
            ))}
          </Sortable>
          <Button
            startIcon={<Add />}
            color={formControlColor}
            variant="outlined"
            onClick={onAddPaymentMethodOptions}
            data-testid={testIds.paymentMethodOptionsAddButton}
          >
            {t('layouts.one_brand_promo.actions.add_payment_method')}
          </Button>
        </div>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={<Typography variant="h6">{t('payments.plan_selection')}</Typography>}
        disableGutters
      >
        <CheckboxField name="planSelectionToggle" />
        <div />
        {LokaliseField('planSelectionHeader')}
        {LokaliseField('planSelectionToggleOpt1')}
        {LokaliseField('planSelectionToggleOpt2')}
      </CustomFoldableSection>
      <CustomFoldableSection summary={<Typography variant="h6">{t('payments.plan_card')}</Typography>} disableGutters>
        <CheckboxField name="isPlanCardBundlePlan" />
        <CheckboxField name="isAMonthlyPlan" />
        <div />
        {LokaliseField('planCardChoosePlanCta')}
        {LokaliseField('planCardMoreBenefitsCta')}
        {LokaliseField('planCardTag')}
        {LokaliseField('collapseMoreBenefitsCta')}
        {LokaliseField('planCardLegalDisclosure')}
        {LokaliseField('planCardLegalDisclosureDetails')}
        {LokaliseField('planCardLegalDisclosureBody')}
        <TextField name="planCardTier" />
        <AssetPicker name="planCardVixLogo" assetType={AssetTypes.accountCreation.vixLogo} />
        <Sortable
          list={fieldsPartnerLogo}
          setList={replacePartnerLogo}
          ghostClass="sortableGhost"
          dragClass="sortableDragDefault"
        >
          {fieldsPartnerLogo.map((partnerLogo, i) => (
            <Stack
              key={partnerLogo.id}
              data-testid={testIds.planCardPartnerLogos}
              gap={6}
              marginBottom={5}
              direction="row"
              alignItems="center"
            >
              <AssetPickerPartnerLogo
                name="planCardPartnerLogo"
                assetType={AssetTypes.oneBrandPromo.ctv}
                position={i}
              />
              <IconButton
                title={t('layouts.one_brand_promo.actions.remove_partner_logo')}
                disabled={fieldsPartnerLogo.length === 1}
                onClick={() => removePartnerLogo(i)}
                sx={{ mt: 4 }}
                data-testid={testIds.planCardPartnerLogosRemoveButton}
              >
                <Delete />
              </IconButton>
            </Stack>
          ))}
        </Sortable>
        <Button
          startIcon={<Add />}
          color={formControlColor}
          variant="outlined"
          onClick={onAddPartnerLogos}
          data-testid={testIds.planCardPartnerLogosAddButton}
        >
          {t('layouts.one_brand_promo.actions.add_partner_logo')}
        </Button>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={<Typography variant="h6">{t('layouts.one_brand_promo.headers.spin_premia')}</Typography>}
        disableGutters
      >
        {LokaliseField('spinPremia.points')}
        {LokaliseField('spinPremia.description')}
        <CheckboxField name="spinPremia.checkbox" />
        {LokaliseField('spinPremia.checkboxCopy')}
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography variant="h6">{t('layouts.one_brand_promo.headers.gift_card_balance_reminder')}</Typography>
        }
        disableGutters
      >
        <AssetPicker name="giftCardLandscapeFillImage" assetType={AssetTypes.oneBrandPromo.landscape} />
        <AssetPicker name="giftCardPortraitFillImage" assetType={AssetTypes.oneBrandPromo.portrait} />
        <AssetPicker name="giftCardDesktopFillImage" assetType={AssetTypes.oneBrandPromo.desktop} />
        <AssetPicker name="giftCardMobileFillImage" assetType={AssetTypes.oneBrandPromo.mobile} />
        <AssetPicker name="giftCardCtvFillImage" assetType={AssetTypes.oneBrandPromo.ctv} />
        <AssetPicker name="giftCardTabletFillImage" assetType={AssetTypes.oneBrandPromo.tablet} />
        <div />
        {LokaliseField('giftCardBalanceReminderHeader')}
        {LokaliseField('giftCardBalanceReminderSubtitle')}
        {LokaliseField('giftCardBalanceReminderOne')}
        {LokaliseField('giftCardBalanceReminderTwo')}
        {LokaliseField('giftCardBalanceReminderCtaOne')}
        {LokaliseField('giftCardBalanceReminderCtaTwo')}
      </CustomFoldableSection>
    </FormBody>
  );
});

OneBrandPromoForm.displayName = 'OneBrandPromo';

export default OneBrandPromoForm;
