import React, { useEffect } from 'react';
import { ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../../../hooks';
import { DragHandle, Edit } from '@mui/icons-material';
import IconButton from '../../../shared/IconButton';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../../Routes';
import { EPGFeaturedCategoryBadge } from '../EPGFeaturedCategoryBadge';
import DraftBadge from '../../../shared/DraftBadge';
import { useData } from '../../../../data-layer';
import { useRecoilValue } from 'recoil';
import { VersionedDocumentStatus } from '../../../../API';
import PremiumBadge from '../../../shared/PremiumBadge';
import { themeColors } from '../../../../theme';
import { useLokalise } from '../../../../hooks/General/useLokalise';
import LokaliseFallback from '../../../shared/LokaliseFallback';

export interface IEPGCategoryListItemProps {
  categoryBundleId: string;
  categoryId: string;
}

const useStyles = makeStyles()((theme) => ({
  categoriesListItem: {
    padding: theme.spacing(2, 7, 2, 3),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& .MuiListItemAvatar-root': {
      display: 'flex',
      minWidth: theme.spacing(6)
    }
  },
  categoriesListItemInner: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  dragHandle: {
    cursor: 'grab'
  },
  skeletonCategory: {
    height: theme.spacing(6),
    margin: theme.spacing(2, 3)
  },
  highlighted: {
    boxShadow: `inset 0 0 2px 2px ${themeColors.orange}`
  }
}));

export const testIds = {
  categoryListItem: 'categories.list-item',
  categoryListItemEditButton: 'categories.list-item.edit-button'
};

export function EPGCategoryListItem({ categoryBundleId, categoryId }: IEPGCategoryListItemProps): React.ReactElement {
  const { classes, cx } = useStyles();
  const { t } = useLocales();
  const navigate = useNavigate();
  const {
    categories: {
      hook: { edit: editCategory, queueIdToFetch, setSelected: setSelectedCategory },
      state: { withSelectedId: withSelectedCategoryId, withRecordById }
    },
    channels: {
      state: { withSpotlightChannelId }
    }
  } = useData();
  const selectedCategoryId = useRecoilValue(withSelectedCategoryId);
  const category = useRecoilValue(withRecordById(categoryId));
  const spotlightChannel = useRecoilValue(withSpotlightChannelId);

  const { lt } = useLokalise();

  useEffect(() => {
    if (!category) {
      queueIdToFetch(categoryId);
    }
  }, [categoryId]);

  useEffect(() => {
    if (!category) return;
    if (categoryId === selectedCategoryId) {
      setSelectedCategory(categoryId);
    }
  }, [category]);

  const onCategoryClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!category) return;
    navigate(AppRoutes.epg(categoryBundleId, categoryId));
  };

  const onEditCategory = () => {
    editCategory(categoryId);
  };

  const hasSpotlightChannel = !!category?.categoryChannels.find((c) => c.channel === spotlightChannel);

  return (
    <>
      {category && (
        <ListItem
          className={cx(classes.categoriesListItem, { [classes.highlighted]: hasSpotlightChannel })}
          button
          selected={selectedCategoryId === category.entityId}
          onClick={onCategoryClick}
          data-testid={testIds.categoryListItem}
          data-category-id={category.entityId}
        >
          <ListItemAvatar>
            <DragHandle className={classes.dragHandle} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <div className={classes.categoriesListItemInner}>
                <>{lt(category.titleLokaliseKey) || <LokaliseFallback fallback={category.title} />}</>
                {category.isFeatured && <EPGFeaturedCategoryBadge />}
                {category.isPremium && <PremiumBadge height={20} />}
                {category.status === VersionedDocumentStatus.DRAFT && <DraftBadge />}
              </div>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              onClick={onEditCategory}
              data-testid={testIds.categoryListItemEditButton}
              title={t('edit_category')}
              placement="left"
              size="large"
            >
              <Edit fontSize="small" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )}
      {!category && <Skeleton className={classes.skeletonCategory} animation="wave" />}
    </>
  );
}
