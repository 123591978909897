import React, { useCallback } from 'react';
import { MediaResponse } from '../../../API';
import { makeStyles } from 'tss-react/mui';
import { noop } from 'lodash-es';
import { useData } from '../../../data-layer';
import { useRecoilValue } from 'recoil';
import { DisplayAsOptions } from '../../../utils/types/genericTypes';
import { Sortable, SortableGroups } from '../../shared/Sortable';
import { getMediaGridTemplateColumns } from '../../../utils/styleUtils';
import Repeat from '../../shared/Repeat';
import { Skeleton } from '@mui/material';
import ShadowScroller from '../../shared/ShadowScroller';
import Match from '../../Collections/CollectionsPanel/Match';

export interface IMatchListProps {
  matches: MediaResponse[];
  loading?: boolean;
}

const useStyles = makeStyles<{ displayAs: DisplayAsOptions; isList: boolean }>()((theme, { displayAs, isList }) => ({
  matchList: {
    margin: theme.spacing(2),
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: !isList ? getMediaGridTemplateColumns(displayAs) : 'auto',
    gridTemplateRows: 'repeat(auto-fit, minmax(0, max-content))'
  },
  skeletonRow: {
    margin: theme.spacing(2),
    height: 40
  }
}));

export const testIds = {
  matchList: 'match-list.list',
  matchListItem: 'match-list.list-item',
  skeleton: 'match-list.skeleton',
  skeletonRow: 'match-list.skeleton-row'
};

export function MatchList({ matches, loading, ...rest }: IMatchListProps): React.ReactElement {
  const {
    collections: {
      state: { withDisplayAs, withSelectedCollectionContent, withSelected }
    }
  } = useData();

  const displayAs = useRecoilValue(withDisplayAs);
  const selectedCollectionContent = useRecoilValue(withSelectedCollectionContent);
  const selectedCollection = useRecoilValue(withSelected);
  const isList = displayAs === DisplayAsOptions.LIST;
  const { classes } = useStyles({ displayAs, isList });

  const checkInCollection = useCallback(
    (id: string) => selectedCollectionContent?.includes(id),
    [selectedCollectionContent]
  );

  if (loading) {
    return (
      <ShadowScroller data-testid={testIds.skeleton}>
        <Repeat n={20}>
          <Skeleton className={classes.skeletonRow} animation="wave" data-testid={testIds.skeletonRow} />
        </Repeat>
      </ShadowScroller>
    );
  }

  return (
    <ShadowScroller data-testid={testIds.matchList} {...rest}>
      <Sortable
        disabled={!matches.length}
        setList={noop}
        list={matches?.map((m) => m.contentId) || []}
        className={classes.matchList}
        animation={100}
        ghostClass="sortableGhost"
        dragClass="sortableDragPaper"
        filter=".sortableFiltered"
        isStringList
        group={{ name: SortableGroups.collections, pull: 'clone', put: false }}
      >
        {matches.map((match, i) => (
          <Match
            key={i}
            match={match}
            data-testid={testIds.matchListItem}
            previewMode={false}
            inCollection={checkInCollection(match.contentId)}
            collectionForKids={selectedCollection?.kids}
          />
        ))}
      </Sortable>
    </ShadowScroller>
  );
}
