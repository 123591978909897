import React from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerProps,
  ControllerRenderProps,
  FieldValues,
  UseFormStateReturn
} from 'react-hook-form';

export interface IInputControllerProps<T extends FieldValues> extends ControllerProps<T> {
  'data-testid'?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function InputController(props: IInputControllerProps<any>): React.ReactElement {
  const renderPatched = ({
    field,
    fieldState,
    formState
  }: {
    field: ControllerRenderProps<FieldValues, string>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<FieldValues>;
  }) => {
    return React.cloneElement(props.render({ field, fieldState, formState }), {
      'data-testid': props['data-testid']
    });
  };

  return <Controller {...props} render={renderPatched} />;
}
