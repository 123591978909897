import { LocalizedFieldBody } from '../../../API';
import { useTheme } from '../../../hooks';
import { Link } from 'react-router-dom';
import Button from '../Button';
import Localized from '../Localized';
import { Launch } from '@mui/icons-material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const stopPropagation: React.MouseEventHandler = (evt) => {
  evt.stopPropagation();
};
const useLinkWithIconStyles = makeStyles()(() => ({
  link: {
    textDecoration: 'none'
  },
  linkIcon: {
    minWidth: 0
  }
}));

const LinkWithIcon = ({
  pathname,
  title,
  align,
  ...props
}: {
  align?: 'left' | 'right' | 'center';
  pathname: string;
  title: LocalizedFieldBody | string;
  'data-testid'?: string;
}): JSX.Element => {
  const { classes } = useLinkWithIconStyles();
  const { formControlColor } = useTheme();
  return (
    <>
      <Link className={classes.link} to={{ pathname }} onClick={stopPropagation}>
        <Button variant="text" size="small" color={formControlColor} sx={{ minWidth: 0 }}>
          <span style={{ textAlign: align }} data-testid={props['data-testid']}>
            {typeof title === 'string' ? title : <Localized prop={title} />}
          </span>
        </Button>
      </Link>
      <Link to={{ pathname }} target="_blank" rel="noopener noreferrer" onClick={stopPropagation}>
        <Button
          className={classes.linkIcon}
          variant="text"
          size="small"
          color={formControlColor}
          startIcon={<Launch />}
        ></Button>
      </Link>
    </>
  );
};

export default LinkWithIcon;
