/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AiSearchUiModuleBodySuggestionsBody } from './ai-search-ui-module-body-suggestions-body';
import { EntitlementType } from './entitlement-type';
import { LocalizedFieldBody } from './localized-field-body';
import { TargetPlatform } from './target-platform';
import { UiModuleExperimentGroup } from './ui-module-experiment-group';

/**
 * 
 * @export
 * @interface AiSearchUiModuleBody
 */
export interface AiSearchUiModuleBody {
    /**
     * 
     * @type {string}
     * @memberof AiSearchUiModuleBody
     */
    moduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof AiSearchUiModuleBody
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof AiSearchUiModuleBody
     */
    moduleType: AiSearchUiModuleBodyModuleTypeEnum;
    /**
     * 
     * @type {Array<TargetPlatform>}
     * @memberof AiSearchUiModuleBody
     */
    targetPlatform?: Array<TargetPlatform>;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof AiSearchUiModuleBody
     */
    caption?: LocalizedFieldBody;
    /**
     * 
     * @type {Array<EntitlementType>}
     * @memberof AiSearchUiModuleBody
     */
    entitlements?: Array<EntitlementType>;
    /**
     * 
     * @type {Array<UiModuleExperimentGroup>}
     * @memberof AiSearchUiModuleBody
     */
    experiments?: Array<UiModuleExperimentGroup> | null;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof AiSearchUiModuleBody
     */
    title: LocalizedFieldBody;
    /**
     * 
     * @type {string}
     * @memberof AiSearchUiModuleBody
     */
    searchTitle: string;
    /**
     * 
     * @type {string}
     * @memberof AiSearchUiModuleBody
     */
    searchSubTitle: string;
    /**
     * 
     * @type {string}
     * @memberof AiSearchUiModuleBody
     */
    searchPlaceholder: string;
    /**
     * 
     * @type {Array<AiSearchUiModuleBodySuggestionsBody>}
     * @memberof AiSearchUiModuleBody
     */
    suggestions: Array<AiSearchUiModuleBodySuggestionsBody>;
    /**
     * 
     * @type {string}
     * @memberof AiSearchUiModuleBody
     */
    backgroundFillImage?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AiSearchUiModuleBodyModuleTypeEnum {
    AI_SEARCH = 'AI_SEARCH'
}



