import { DocumentLocale, LocalizedFieldBody } from '../API';

export function localeFallback(currentLang: DocumentLocale | string, prop: LocalizedFieldBody, fallback = ''): string {
  const fallbackLang = DocumentLocale.ES;
  const fallbackLang2 = DocumentLocale.EN;
  const fallbackLang3 = DocumentLocale.PT;

  return (
    prop[currentLang as DocumentLocale] || prop[fallbackLang] || prop[fallbackLang2] || prop[fallbackLang3] || fallback
  );
}
