import React, { useState } from 'react';
import { useLocales, useTheme } from '../../../../../hooks';
import { OneBrandPromoUiModuleBody } from '../../../../../API';
import Modal from '../../../../shared/Modal';
import { Box, Button as MuiButton, FormControlLabel, Switch, Tab, Typography, Grid } from '@mui/material';
import Button from '../../../../shared/Button';
import { themeColors } from '../../../../../theme';
import { useLokalise } from '../../../../../hooks/General/useLokalise';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import { getAssetUrl } from '../../../../../utils/assetHelpers';
import { makeStyles } from 'tss-react/mui';
import { Close } from '@mui/icons-material';
import { Stack } from '@mui/system';
import ShadowScroller from '../../../../shared/ShadowScroller';
import { TextButton, VixOrangeButton } from '../shared/SharedButtons';

export interface OneBrandPromoPreviewProps {
  module: OneBrandPromoUiModuleBody;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export const testIds = {
  root: 'one-brand-promo-preview.root',
  popupContainer: 'one-brand-promo-preview.popup-container',
  fullContainer: 'one-brand-promo-preview.full-container'
};

enum OneBrandPromoTabs {
  Popup = 'popup_paywall',
  Full = 'full_paywall',
  PlanPicker = 'plan_picker'
}

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    height: '100%'
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  scroller: {
    display: 'flex',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    position: 'relative',
    width: 450,
    color: 'white',
    overflow: 'hidden'
  },
  image: {
    width: '100%',
    position: 'absolute',
    pointerEvents: 'none'
  },
  popup: {
    position: 'relative',
    marginTop: '50%',
    background: '#0B0D17',
    width: '100%',
    borderRadius: '10px 10px 0 0',
    padding: '30px 40px'
  },
  popupBar: {
    width: 50,
    height: 3,
    background: 'white',
    borderRadius: 5,
    margin: 'auto'
  },
  full: {
    background: '#0B0D17',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px'
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    textAlign: 'left',
    li: {
      '&::marker': {
        content: '"✓ "',
        color: themeColors.orange
      }
    }
  },
  plan: {
    padding: '16px',
    background: '#22242D',
    borderRadius: 10,
    position: 'relative',
    overflow: 'hidden',
    border: '2px solid #22242D'
  },
  planHighlight: {
    borderColor: themeColors.orange
  },
  planListContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    textAlign: 'left',
    li: {
      listStyleType: 'decimal',
      '&::marker': {
        color: themeColors.orange
      }
    }
  },
  paywallProps: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    li: {
      padding: '20px 0',
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&::marker': {
        content: '"✓ "'
      }
    }
  }
}));

export function OneBrandPromoPreview({ module, isOpen, setIsOpen }: OneBrandPromoPreviewProps): React.ReactElement {
  const { classes, cx } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { lt } = useLokalise();
  const [tabValue, setTabValue] = useState<OneBrandPromoTabs>(OneBrandPromoTabs.Popup);
  const [displayAsAuthenticated, setDisplayAsAuthenticated] = useState(false);
  const [displayInstructions, setDisplayInstructions] = useState(false);

  const onModalClose = () => setIsOpen(false);
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => setTabValue(newValue as OneBrandPromoTabs);

  return (
    <Modal
      open={isOpen}
      onClose={onModalClose}
      headerLeft={t('layouts.one_brand_promo.preview')}
      headerRight={
        <Grid container direction="column" justifyContent="center" alignItems="flex-start">
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  color={formControlColor}
                  onChange={({ target: { checked } }) => setDisplayAsAuthenticated(checked)}
                />
              }
              label={t('layouts.one_brand_promo.display_as_auth')}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  color={formControlColor}
                  onChange={({ target: { checked } }) => setDisplayInstructions(checked)}
                />
              }
              label={t('layouts.one_brand_promo.display_instructions')}
            />
          </Grid>
        </Grid>
      }
      footerLeft={
        <Button color="grey" onClick={onModalClose} sx={{ width: 140 }}>
          {t('general.close')}
        </Button>
      }
    >
      <Box data-testid={testIds.root} className={classes.root}>
        <TabContext value={tabValue}>
          <TabList
            indicatorColor={formControlColor}
            textColor={formControlColor}
            onChange={handleTabChange}
            centered
            className={classes.tabs}
          >
            {Object.values(OneBrandPromoTabs).map((tab) => (
              <Tab key={tab} label={t(`layouts.one_brand_promo.headers.${tab}`)} value={tab} />
            ))}
          </TabList>
          <ShadowScroller>
            <div className={classes.scroller}>
              <TabPanel value={OneBrandPromoTabs.Popup} data-testid={testIds.popupContainer}>
                <Box className={classes.container}>
                  {/* Background Image */}
                  <img className={classes.image} src={getAssetUrl(module.portraitFillImage)} />
                  <Box className={classes.popup}>
                    <div className={classes.popupBar} />
                    <Stack gap="24px" marginTop="50px">
                      <Stack direction="column">
                        <Typography variant="h5" fontWeight="bold">
                          <span dangerouslySetInnerHTML={{ __html: lt(module.popupHeader) }} />
                        </Typography>
                        <Typography variant="h6" fontWeight="bold">
                          <span dangerouslySetInnerHTML={{ __html: lt(module.popupSubheader) }} />
                        </Typography>
                      </Stack>
                      <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                        <span dangerouslySetInnerHTML={{ __html: lt(module.popupText) }} />
                      </Typography>
                      <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                        <span
                          className={classes.listContainer}
                          dangerouslySetInnerHTML={{ __html: lt(module.popupValuePropositions) }}
                        />
                      </Typography>
                      <Stack direction="column" gap="20px">
                        <VixOrangeButton fullWidth>{lt(module.popupCtaText)}</VixOrangeButton>
                        <TextButton>
                          {lt(displayAsAuthenticated ? module.popupAuthSignInCtaText : module.popupAnonSignInCtaText)}
                        </TextButton>
                      </Stack>
                      <Typography variant="subtitle1" marginTop="30px" color="rgba(255, 255, 255, 0.5)">
                        <span dangerouslySetInnerHTML={{ __html: lt(module.popupLegalDisclosure) }} />
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </TabPanel>

              <TabPanel value={OneBrandPromoTabs.Full} data-testid={testIds.fullContainer}>
                <Box className={classes.container}>
                  <Box className={classes.full}>
                    <Close sx={{ alignSelf: 'end' }} />

                    {/* Vix Logo */}
                    <Box component="img" src="/assets/vix-white.svg" sx={{ alignSelf: 'start', mt: '40px' }} />

                    <Stack gap="24px" marginTop="50px">
                      <Stack direction="column">
                        <Typography variant="h4" fontWeight="bold">
                          <span dangerouslySetInnerHTML={{ __html: lt(module.fullHeader) }} />
                        </Typography>
                        <Typography variant="h6" fontWeight="bold">
                          <span dangerouslySetInnerHTML={{ __html: lt(module.fullSubheader) }} />
                        </Typography>
                      </Stack>
                      <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                        <span
                          className={classes.listContainer}
                          dangerouslySetInnerHTML={{ __html: lt(module.fullText) }}
                        />
                      </Typography>
                      <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                        <span
                          className={classes.listContainer}
                          dangerouslySetInnerHTML={{ __html: lt(module.fullValuePropositions) }}
                        />
                      </Typography>
                      <VixOrangeButton fullWidth>{lt(module.fullCtaText)}</VixOrangeButton>
                      <TextButton>
                        {lt(displayAsAuthenticated ? module.fullAuthSignInCtaText : module.fullAnonSignInCtaText)}
                      </TextButton>
                      <Typography variant="subtitle1" marginTop="30px" color="rgba(255, 255, 255, 0.5)">
                        <span dangerouslySetInnerHTML={{ __html: lt(module.fullLegalDisclosure) }} />
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </TabPanel>

              <TabPanel value={OneBrandPromoTabs.PlanPicker} data-testid={testIds.fullContainer}>
                <Box className={classes.container}>
                  <Box className={classes.full}>
                    <Stack gap="40px">
                      {/* Vix Logo */}
                      <Box component="img" src="/assets/vix-white.svg" sx={{ alignSelf: 'start' }} />

                      <Typography variant="h4">
                        <span dangerouslySetInnerHTML={{ __html: lt(module.planPickerHeader) }} />
                      </Typography>

                      {module.planPickerSubheader && (
                        <Typography variant="subtitle1">
                          <span dangerouslySetInnerHTML={{ __html: lt(module.planPickerSubheader) }} />
                        </Typography>
                      )}

                      {module.planPickerInstructions && displayInstructions && (
                        <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                          <span
                            className={classes.planListContainer}
                            dangerouslySetInnerHTML={{ __html: lt(module.planPickerInstructions) }}
                          />
                        </Typography>
                      )}

                      <Stack direction="column" gap="10px">
                        <div
                          className={cx(classes.plan, { [classes.planHighlight]: module.isPlanPickerPlanHighlighted })}
                        >
                          <Stack direction="row" justifyContent="space-between">
                            <Stack direction="column">
                              <Typography fontWeight="bold" fontSize="1.3rem">
                                <span dangerouslySetInnerHTML={{ __html: lt(module.planPickerPlanName) }} />
                              </Typography>
                              <Typography fontWeight="bold" fontSize="1.3rem">
                                <span dangerouslySetInnerHTML={{ __html: lt(module.planPickerPlanPrice) }} />
                              </Typography>
                              <Typography variant="body2">
                                <span dangerouslySetInnerHTML={{ __html: lt(module.planPickerPlanDescription) }} />
                              </Typography>
                              <Typography variant="body2">
                                <span dangerouslySetInnerHTML={{ __html: lt(module.planPickerPlanSavings) }} />
                              </Typography>
                            </Stack>
                            <MuiButton
                              variant="contained"
                              color="inherit"
                              sx={{ alignSelf: 'center', color: 'black' }}
                              disableElevation
                            >
                              Elegir
                            </MuiButton>
                          </Stack>
                        </div>
                      </Stack>
                      <MuiButton variant="text" color="inherit">
                        {lt(module.planPickerFreeAccountCtaText)}
                      </MuiButton>
                      <Box margin="0 20px">
                        <Typography variant="h6" fontWeight="bold">
                          <span dangerouslySetInnerHTML={{ __html: lt(module.planPickerValuePropositionHeader) }} />
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                          <span
                            className={classes.paywallProps}
                            dangerouslySetInnerHTML={{ __html: lt(module.planPickerValuePropositions) }}
                          />
                        </Typography>
                      </Box>
                      <Typography variant="body1" color="rgba(255, 255, 255, 0.5)">
                        <span dangerouslySetInnerHTML={{ __html: lt(module.planPickerLegalDisclosure) }} />
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </TabPanel>
            </div>
          </ShadowScroller>
        </TabContext>
      </Box>
    </Modal>
  );
}
