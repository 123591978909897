import React, { useState, useCallback } from 'react';
import { Autocomplete } from '@mui/material';
import { useFilters, useTheme } from '../../../hooks';
import { debounce } from 'lodash-es';
import TextField from '../../shared/TextField';

export const testIds = {
  autocomplete: 'suggestions-autocomplete.autocomplete',
  textField: 'suggestions-autocomplete.text-field'
};

type ClassNames = {
  autocomplete?: string;
  textField?: string;
};

type SuggestionsAutocompleteProps = {
  propertyName: string;
  value?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  label?: string;
  clearable?: boolean;
  ['data-testid']?: string;
  classNames?: ClassNames;
};

export function SuggestionsAutocomplete({
  propertyName,
  value,
  onChange,
  disabled,
  label,
  clearable,
  classNames,
  ['data-testid']: dataTestId
}: SuggestionsAutocompleteProps): JSX.Element {
  const { formControlColor } = useTheme();
  const [autocompleteOptions, setAutocompleteOptions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getSuggestions } = useFilters();

  const handleSearch = async (search: string) => {
    if (!search) return;
    setIsLoading(true);
    const suggestions = await getSuggestions(propertyName, search);
    if (suggestions) {
      setAutocompleteOptions(suggestions);
    }
    setIsLoading(false);
  };

  const searchDebounced = useCallback(debounce(handleSearch, 300), []);

  const onClear = () => {
    onChange('');
  };

  return (
    <Autocomplete
      value={value}
      className={classNames?.autocomplete}
      loading={isLoading}
      options={autocompleteOptions}
      disabled={disabled}
      onInputChange={(event, newValue) => {
        if (event) {
          searchDebounced(newValue);
        }
      }}
      onChange={(_, newValue) => onChange(newValue || '')}
      renderInput={(params) => (
        <TextField
          {...params}
          color={formControlColor}
          label={label}
          clearable={clearable}
          onClear={onClear}
          className={classNames?.textField}
          onChange={({ target: { value } }) => onChange(value)}
          InputLabelProps={{ shrink: !!value }}
          data-testid={dataTestId || testIds.textField}
          disabled={disabled}
        />
      )}
      freeSolo
      autoSelect
      autoHighlight
      data-testid={testIds.autocomplete}
    />
  );
}
