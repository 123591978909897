import { Chip } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useData } from '../../../data-layer';
import { useLocales } from '../../../hooks';
import Localized from '../../shared/Localized';

interface SportBadgeProps {
  sportId: string | undefined;
  'data-testid'?: string;
}

export function SportBadge({ sportId, 'data-testid': dataTestId }: SportBadgeProps): React.ReactElement {
  const {
    sports: {
      state: { withAllRecordsIncludingDeleted },
      hook: { getAll }
    }
  } = useData();
  const { t } = useLocales();
  const sports = useRecoilValue(withAllRecordsIncludingDeleted);
  const sport = useMemo(() => sports?.find((sport) => sport.sportId === sportId), [sports, sportId]);

  useEffect(() => {
    if (!sports || !sport) {
      const includeDeleted = true;
      getAll([includeDeleted]);
    }
  }, []);

  return (
    <Chip
      label={!sports ? `[${sportId}]` : sport ? <Localized prop={sport.name} /> : t('layouts.all_sports')}
      size="small"
      data-testid={dataTestId}
    />
  );
}
