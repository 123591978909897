import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Info } from '@mui/icons-material';

export interface IHelpTextProps {
  className?: string;
  children: React.ReactNode;
}

const useStyles = makeStyles()((theme) => ({
  helpTextContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center'
  },
  infoIcon: {
    fontSize: 20,
    color: theme.palette.text.disabled
  }
}));

export const testIds = {};

export function HelpText({ children, className }: IHelpTextProps): React.ReactElement {
  const { cx, classes } = useStyles();

  return (
    <div className={cx(className, classes.helpTextContainer)}>
      <Info className={classes.infoIcon} />
      <Typography variant="body2" color="textSecondary">
        {children}
      </Typography>
    </div>
  );
}
