import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { useLayouts, useLocales } from '../../../../hooks';
import { withCopiedModules } from '../../../../state/Layouts';
import Button from '../../../shared/Button';
import { UIModuleMenu } from '../../LayoutView/UIModuleMenu';

export interface IUIModuleListItemDividerProps {
  index: number;
}

const buttonsContainerClass = 'UIModuleListItemDivider-buttons-container';

const useStyles = makeStyles()((theme) => ({
  expandableDivider: {
    height: 9,
    transition: 'height 0.3s ease-in-out 0.1s',
    overflow: 'hidden',
    [`& .${buttonsContainerClass}`]: {
      transition: 'opacity 0.3s ease-in-out 0.1s',
      opacity: 0
    },
    borderBottom: `1px dotted ${theme.palette.divider}`,
    boxSizing: 'content-box'
  },
  expandableDividerHover: {
    background: theme.palette.background.paper,
    height: 56,
    [`& .${buttonsContainerClass}`]: {
      opacity: 1
    }
  }
}));

export function UIModuleListItemDivider({ index }: IUIModuleListItemDividerProps): React.ReactElement {
  const { cx, classes } = useStyles();
  const { t } = useLocales();
  const { pasteModules } = useLayouts();
  const copiedModules = useRecoilValue(withCopiedModules);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const onMouseEnter = () => {
    setIsMouseOver(true);
  };

  const onMouseLeave = () => {
    setIsMouseOver(false);
  };

  const onMenuToggle = (isOpen: boolean) => {
    if (isOpen) setIsMouseOver(false);
    setIsMenuOpen(isOpen);
  };

  const onPaste = () => {
    pasteModules(index);
  };

  return (
    <Stack
      direction="row"
      className={cx(classes.expandableDivider, { [classes.expandableDividerHover]: isMenuOpen || isMouseOver })}
      justifyContent="space-around"
      alignItems="center"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Stack className={buttonsContainerClass} direction="row" gap={4}>
        <UIModuleMenu onMenuToggle={onMenuToggle} index={index} />
        {!!copiedModules.length && (
          <Button color="grey" onClick={onPaste}>
            {t('layouts.paste_modules', { count: copiedModules.length })}
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
