import { ALL } from '../../utils/types/genericTypes';
import { localAtom } from '../localStorageState';

export type CouponsGridFilters = {
  text: string;
  couponState: string;
  couponType: string;
};

export const withCouponsFilters = localAtom({
  key: 'payments.couponsFilters',
  default: {
    text: '',
    couponState: ALL,
    couponType: ALL
  }
});

export type PromotionsGridFilters = {
  text: string;
  status: string;
};

export const withPromotionsFilters = localAtom({
  key: 'payments.promotionsFilters',
  default: {
    text: '',
    status: ALL,
    audience: ALL,
    promoType: ALL
  }
});

export type PlansGridFilters = {
  text: string;
  promoGroup: string;
  device: string;
  countries: string[];
};

export const withPlansFilters = localAtom({
  key: 'payments.plansFilters',
  default: {
    text: '',
    promoGroup: ALL,
    device: ALL,
    countries: []
  } as PlansGridFilters
});
