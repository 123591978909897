import { Close, Edit } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { AssetResponse } from '../../../../API';
import { useLocales, useTimezones } from '../../../../hooks';
import Image from '../../../shared/Image';
import { themeColors } from '../../../../theme';
import IconButton from '../../../shared/IconButton';

export const testIds = {
  cardRoot: 'AssetCard-root',
  cardName: 'AssetCard-title',
  cardDate: 'AssetCard-date',
  cardImage: 'AssetCard-image',
  cardInvalid: 'AssetCard-invalid',
  cardBlank: 'AssetCard-blank',
  cardError: 'AssetCard-error',
  skeletonCard: 'AssetCard-skeleton',
  cardClearButton: 'AssetCard-clearBtn',
  cardEditButton: 'AssetCard-editBtn'
};

const useStyles = makeStyles()((theme) => ({
  noAsset: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
    margin: theme.spacing(1)
  },
  assetResult: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  assetImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  assetImageDark: {
    background: themeColors.dark.default,
    borderRadius: theme.shape.borderRadius
  },
  assetDetails: {
    width: 0,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    justifyContent: 'space-around',
    alignSelf: 'stretch',
    '& > p': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }
  },
  hasError: {
    color: theme.palette.error.main
  }
}));

type AssetCardProps = {
  asset: AssetResponse | undefined;
  imageWidth?: number;
  clearable?: boolean;
  hasError?: boolean;
  invalid?: boolean;
  onClear?: () => void;
  onEdit?: (asset: AssetResponse) => void;
  useDarkBackground?: boolean;
};

export function AssetCard({
  asset,
  imageWidth = 48,
  clearable = false,
  hasError = false,
  invalid = false,
  onClear,
  onEdit,
  useDarkBackground
}: AssetCardProps): React.ReactElement {
  const { classes, cx } = useStyles();
  const { t } = useLocales();
  const { timestamp } = useTimezones();

  if (!asset)
    return (
      <div data-testid={testIds.skeletonCard} className={cx(classes.noAsset, { [classes.hasError]: hasError })}>
        {!invalid ? (
          <Typography variant="body1" data-testid={testIds.cardBlank}>
            {t('assets.blank_asset')}
          </Typography>
        ) : (
          <>
            <Typography variant="body1" data-testid={testIds.cardInvalid}>
              {t('assets.invalid')}
            </Typography>
            <IconButton title={t('assets.clear')} data-testid={testIds.cardClearButton} size="small" onClick={onClear}>
              <Close />
            </IconButton>
          </>
        )}
        {hasError && (
          <Typography variant="caption" data-testid={testIds.cardError}>
            {t('general.field_is_required')}
          </Typography>
        )}
      </div>
    );

  const { assetName, createdDate, url } = asset;

  return (
    <div className={classes.assetResult} data-testid={testIds.cardRoot} data-asset-id={asset.id}>
      <div
        className={`${classes.assetImage} ${useDarkBackground ? classes.assetImageDark : ''}`}
        data-testid={testIds.cardImage}
        style={{
          width: imageWidth,
          height: imageWidth
        }}
      >
        <Image src={`${url}?tr=w-${imageWidth * 2}`} />
      </div>
      <div className={classes.assetDetails}>
        <Tooltip title={assetName} placement="bottom-start">
          <Typography variant="body1" data-testid={testIds.cardName}>
            {assetName}
          </Typography>
        </Tooltip>
        <Typography color="textSecondary" variant="caption" data-testid={testIds.cardDate}>
          {t('assets.added_on')} {timestamp(createdDate)}
        </Typography>
      </div>
      {clearable && (
        <IconButton title={t('assets.clear')} data-testid={testIds.cardClearButton} size="small" onClick={onClear}>
          <Close />
        </IconButton>
      )}
      {onEdit && (
        <IconButton
          title={t('assets.edit_asset')}
          data-testid={testIds.cardEditButton}
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            if (onEdit) onEdit(asset);
          }}
        >
          <Edit sx={{ fontSize: 20 }} />
        </IconButton>
      )}
    </div>
  );
}
