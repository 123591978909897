import React, { useEffect, useMemo } from 'react';
import { useLocales, useTheme } from '../../../../hooks';
import { useRecoilValue } from 'recoil';
import { Typography, Chip, MenuItem, InputLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Button from '../../../shared/Button';
import Drawer from '../../../shared/Drawer';
import CountryPicker from '../../../shared/CountryPicker';
import { DocumentLocale, VllCategoriesBundleResponse } from '../../../../API';
import { Controller, useForm } from 'react-hook-form';
import { markAsRequired, handleSaveDraft, handleSavePublish, isFormValid } from '../../../../utils/formHelpers';
import FormControl from '../../../shared/FormControl';
import { useData } from '../../../../data-layer';
import { flatten } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../../Routes';
import TextValidator from '../../../shared/TextValidator';
import Select from '../../../shared/Select';
import { usePermissionsGuard } from '../../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../../state/theme';

const useStyles = makeStyles()((theme) => ({
  header: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center'
  },
  formBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    gap: theme.spacing(4)
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  languageInput: {
    width: 260
  },
  textInput: {
    width: '50%',
    maxWidth: 400
  },
  footerButton: {
    minWidth: 120,
    marginRight: theme.spacing(4)
  },
  countryPickerContainer: {
    flexGrow: 1
  }
}));

export const testIds = {
  formBody: 'region-form-body',
  regionNameInput: 'region-name-input',
  cloneBadge: 'region-clone-badge',
  defaultLocaleSelector: 'region-default-locale-selector',
  saveButton: 'region-save-button',
  publishButton: 'region-publish-button',
  cancelButton: 'region-cancel-button'
};

function EPGRegionForm(): JSX.Element {
  const { canSave, canPublish } = usePermissionsGuard({ homepageOption: HomepageOptions.EPG });
  const { classes } = useStyles();
  const { t } = useLocales();
  const formRef = React.useRef<ValidatorForm>(null);
  const { formControlColor } = useTheme();
  const {
    categoryBundles: {
      state: { withAllRecords: withCategoryBundles, withFormMetadata, withIsSaving, withIsPublishing },
      hook: { closeForm, save: saveCategoryBundleDraft, saveAndPublish: saveAndPublishCategoryBundle }
    }
  } = useData();
  const formMetadata = useRecoilValue(withFormMetadata);
  const region = formMetadata.record;
  const isSaving = useRecoilValue(withIsSaving);
  const isPublishing = useRecoilValue(withIsPublishing);
  const regions = useRecoilValue(withCategoryBundles);
  const navigate = useNavigate();

  const { handleSubmit, control, reset } = useForm<VllCategoriesBundleResponse>();

  useEffect(() => {
    reset(region);
  }, [region]);

  const disabledCountries = useMemo(() => {
    if (!formMetadata || !regions) return [];
    const regionsCopy = formMetadata.isEditing
      ? regions.filter((region) => region.entityId !== (formMetadata.record as VllCategoriesBundleResponse).entityId)
      : regions;
    return flatten(regionsCopy.map((region) => region.countries));
  }, [formMetadata, regions]);

  const onSubmit = async (region: VllCategoriesBundleResponse, shouldPublish = false) => {
    if (!(await isFormValid(formRef))) return;
    let savedRegion: VllCategoriesBundleResponse | undefined;
    if (shouldPublish) {
      savedRegion = await saveAndPublishCategoryBundle(region);
    } else {
      savedRegion = await saveCategoryBundleDraft(region);
    }
    if (savedRegion) {
      closeForm();
      if (formMetadata.isNew) {
        navigate(AppRoutes.epg(savedRegion.entityId, savedRegion.categories?.[0]));
      }
    }
  };

  const handleOnClose = () => {
    if (!isSaving && !isPublishing) {
      closeForm();
    }
  };

  ValidatorForm.addValidationRule('uniqueRegionId', (value: string) => {
    if (formMetadata.isEditing) return true;
    if (regions) {
      return regions.map((value) => value.entityId).indexOf(value) < 0;
    }
    return true;
  });

  return (
    <Drawer
      open={formMetadata.isShowingForm}
      formRef={formRef}
      onClose={handleOnClose}
      headerLeft={
        region && (
          <div className={classes.header}>
            <Typography variant="h6">{t(formMetadata.isNew ? 'new_region' : 'edit_region')}</Typography>
            {formMetadata.isCloning && (
              <Chip
                size="small"
                color="secondary"
                label={`${t('general.clone_of')} ${formMetadata.cloningRecord?.regionName}`}
                data-testid={testIds.cloneBadge}
              />
            )}
          </div>
        )
      }
      footerLeft={
        <>
          <Button
            color="secondary"
            onClick={handleSaveDraft(handleSubmit, onSubmit)}
            className={classes.footerButton}
            loading={isSaving}
            disabled={!canSave || isSaving || isPublishing}
            data-testid={testIds.saveButton}
          >
            {t('general.saveAsDraft')}
          </Button>
          <Button
            onClick={handleSavePublish(handleSubmit, onSubmit)}
            className={classes.footerButton}
            loading={isPublishing}
            disabled={!canSave || !canPublish || isSaving || isPublishing}
            data-testid={testIds.publishButton}
          >
            {t('general.saveAndPublish')}
          </Button>
        </>
      }
      footerRight={
        <Button
          color="grey"
          className={classes.footerButton}
          onClick={handleOnClose}
          data-testid={testIds.cancelButton}
          loading={isSaving || isPublishing}
        >
          {t('general.cancel')}
        </Button>
      }
    >
      {region && (
        <div className={classes.bodyContainer}>
          <div className={classes.formBody} data-testid={testIds.formBody}>
            <div className={classes.topRow}>
              <div className={classes.textInput}>
                <Controller
                  name="regionName"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextValidator
                      fullWidth
                      color={formControlColor}
                      name="regionName"
                      label={markAsRequired(t('region_name'))}
                      value={value}
                      validators={['required']}
                      errorMessages={[t('general.field_is_required')]}
                      onChange={onChange}
                      data-testid={testIds.regionNameInput}
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <Controller
                name="defaultLocale"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl>
                    <InputLabel>{t('general.default_language')}</InputLabel>
                    <Select
                      name="defaultLocale"
                      className={classes.languageInput}
                      value={value}
                      onChange={onChange}
                      data-testid={testIds.defaultLocaleSelector}
                    >
                      {Object.values(DocumentLocale).map((language) => (
                        <MenuItem key={language} value={language}>
                          {t(`languages.${language}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </div>
          </div>
          <div className={classes.countryPickerContainer}>
            <Controller
              name="countries"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CountryPicker value={value} disabledCountries={disabledCountries} onChange={onChange} />
              )}
            />
          </div>
        </div>
      )}
    </Drawer>
  );
}

export default EPGRegionForm;
