import { getLuminance, Theme } from '@mui/material';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { withIsDarkMode, withThemeColorPrimary, withThemeColorSecondary, withThemeSpacing } from '../../state/theme';
import { createCustomTheme } from '../../theme';

type ReturnType = {
  isDarkMode: boolean;
  formControlColor: 'primary' | 'secondary';
  lowerContrastColor: 'primary' | 'secondary';
  theme: Theme;
};

export function useTheme(): ReturnType {
  const isDarkMode = useRecoilValue(withIsDarkMode);
  const primaryColor = useRecoilValue(withThemeColorPrimary);
  const secondaryColor = useRecoilValue(withThemeColorSecondary);
  const spacing = useRecoilValue(withThemeSpacing);

  // Returns the color with the best contrast depending on which mode you're using
  const formControlColor = useMemo(() => {
    const primaryLuminance = getLuminance(primaryColor);
    const secondaryLuminance = getLuminance(secondaryColor);
    if (isDarkMode) {
      return primaryLuminance > secondaryLuminance ? 'primary' : 'secondary';
    }
    // else light mode
    return primaryLuminance > secondaryLuminance ? 'secondary' : 'primary';
  }, [isDarkMode, primaryColor, secondaryColor]);

  // Returns the color with the lowest contrast depending on which mode you're using
  const lowerContrastColor = useMemo(() => {
    const primaryLuminance = getLuminance(primaryColor);
    const secondaryLuminance = getLuminance(secondaryColor);
    if (isDarkMode) {
      return primaryLuminance > secondaryLuminance ? 'secondary' : 'primary';
    }
    // else light mode
    return primaryLuminance > secondaryLuminance ? 'primary' : 'secondary';
  }, [isDarkMode, primaryColor, secondaryColor]);

  const theme = useMemo(() => {
    return createCustomTheme(isDarkMode, primaryColor, secondaryColor, spacing);
  }, [isDarkMode, primaryColor, secondaryColor, spacing]);

  return {
    isDarkMode,
    formControlColor,
    lowerContrastColor,
    theme
  };
}
