import React from 'react';
import { SetterOrUpdater } from 'recoil';
import { EntitlementType } from '../../../API';
import { useLocales } from '../../../hooks';
import { EntitlementsMultiSelect } from './EntitlementsMultiSelect';

export { testIds } from './EntitlementsMultiSelect';

export function EntitlementsFilter({
  entitlements,
  setEntitlements
}: {
  entitlements: EntitlementType[];
  setEntitlements: SetterOrUpdater<EntitlementType[]>;
}): React.ReactElement {
  const { t } = useLocales();

  return (
    <EntitlementsMultiSelect
      label={t('entitlements.filter_by_entitlement')}
      value={entitlements}
      onChange={setEntitlements}
    />
  );
}
