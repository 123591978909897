import React, { useState } from 'react';
import { List } from '@mui/icons-material';
import { Avatar, Checkbox, Chip, MenuItem, Popover, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from 'tss-react/mui';
import { DocumentLocale } from '../../../API';
import { useLocales, useTheme } from '../../../hooks';
import { COUNTRIES, COUNTRIES_BY_ID, COUNTRY_PRESETS, ICountry, sortByCountryName } from '../../../utils/countryCodes';
import CountryBadge from '../CountryBadge';
import CountryBadgesMerged from '../CountryBadgesMerged';
import IconButton from '../IconButton';
import Button from '../Button';
import { alpha } from '@mui/system';

export interface ICountryPickerLiteProps {
  className?: string;
  label: string;
  value: string[];
  onChange: (changedValues: string[]) => void;
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  mergeAfterCount?: number;
  disableClearable?: boolean;
  hidePresets?: boolean;
  disableCountry?: (countryCode: string) => boolean;
  disablePreset?: (countryCodes: string[]) => boolean;
  textFieldTestId?: string;
  'data-testid'?: string;
  singleSelection?: boolean;
}

const useStyles = makeStyles<{ formControlColor: 'primary' | 'secondary' }>()((theme, { formControlColor }) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  optionCheckbox: {
    marginRight: theme.spacing(2)
  },
  autoComplete: {
    flexGrow: 1,
    '& .MuiFormControl-root': {
      marginBottom: '0 !important'
    }
  },
  presetMenuContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'end'
  },
  presetsButton: {
    margin: 0
  },
  option: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    paddingRight: theme.spacing(2),
    '&:hover': {
      background: alpha(theme.palette[formControlColor].light, 0.15)
    }
  },
  countryName: {
    flexGrow: 1
  },
  disabledOption: {
    pointerEvents: 'none',
    opacity: 0.5
  },
  onlyButton: {
    borderColor: 'transparent',
    '&:hover': {
      background: alpha(theme.palette[formControlColor].light, 0.15)
    }
  },
  textField: {
    '& input': {
      minWidth: '0 !important'
    },
    '& .MuiInput-root': {
      paddingRight: '10px !important'
    }
  },
  singleCountryBadge: {
    backgroundColor: 'transparent',
    padding: theme.spacing(2),
    '& .MuiChip-avatar': {
      marginRight: theme.spacing(1)
    },
    '& .MuiChip-label': {
      paddingLeft: 0
    }
  }
}));

export const testIds = {
  autocomplete: 'cpl.autocomplete',
  textField: 'cpl.text-field',
  option: 'cpl.option',
  chip: 'cpl.chip',
  presetMenuButton: 'cpl.preset-menu-button',
  presetMenuItem: 'cpl.preset-menu-item',
  onlyButton: 'cpl.only-button'
};

export default function CountryPickerLite({
  className,
  label,
  value,
  onChange,
  required = false,
  disabled = false,
  fullWidth = false,
  mergeAfterCount = 4,
  disableClearable = false,
  disableCountry,
  disablePreset,
  hidePresets = false,
  textFieldTestId,
  singleSelection = false,
  ...props
}: ICountryPickerLiteProps): React.ReactElement {
  const { formControlColor } = useTheme();
  const { classes, cx } = useStyles({ formControlColor });
  const { t, currentLang } = useLocales();
  const [presetMenuAnchorEl, setPresetMenuAnchorEl] = useState<null | HTMLElement>(null);

  const langForCountryName = currentLang === DocumentLocale.ES ? DocumentLocale.ES : DocumentLocale.EN;

  // Use English name unless currentLang is Spanish (no Portuguese country names atm)
  const options = [...COUNTRIES].sort(sortByCountryName(langForCountryName));

  const openPresetMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPresetMenuAnchorEl(event.currentTarget);
  };

  const closePresetMenu = () => {
    setPresetMenuAnchorEl(null);
  };

  const selectPresetCountries = (countries: string[]) => {
    if (disablePreset?.(countries)) return;
    onChange(countries);
    closePresetMenu();
  };

  const onSelectOnlyCountryClick = (event: React.MouseEvent<HTMLButtonElement>, country: string) => {
    event.stopPropagation();
    onChange([country]);
  };

  const SingleCountryBadge = ({ countryCode }: { countryCode: string }) => {
    const country = COUNTRIES_BY_ID[countryCode];
    return (
      <Chip
        className={classes.singleCountryBadge}
        avatar={<Avatar src={`/assets/flags/${countryCode.toLowerCase()}.png`} />}
        size="medium"
        label={country.name[langForCountryName]}
        data-testid={testIds.chip}
        {...props}
      />
    );
  };

  return (
    <div className={className} data-testid={props['data-testid']}>
      <div className={classes.container}>
        <Autocomplete
          className={classes.autoComplete}
          multiple
          disabled={disabled}
          options={options}
          getOptionLabel={(country) => (country.name[langForCountryName] as string) + country.code}
          disableCloseOnSelect
          disableClearable={disableClearable}
          fullWidth={fullWidth}
          color={formControlColor}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              data-testid={testIds.option}
              data-option={option.code}
              className={cx(classes.option, { [classes.disabledOption]: disableCountry?.(option.code) })}
            >
              {!singleSelection ? (
                <>
                  <Checkbox className={classes.optionCheckbox} checked={selected} color={formControlColor} />
                  <div className={classes.countryName}>{option.name[langForCountryName]}</div>
                  <Button
                    className={classes.onlyButton}
                    variant="text"
                    size="small"
                    color={formControlColor}
                    onClick={(event) => onSelectOnlyCountryClick(event, option.code)}
                    data-testid={testIds.onlyButton}
                  >
                    {t('general.only')}
                  </Button>
                </>
              ) : (
                <SingleCountryBadge countryCode={option.code} />
              )}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.textField}
              color={formControlColor}
              error={required && !value.length}
              helperText={required && !value.length && t('general.field_is_required')}
              label={label}
              data-testid={textFieldTestId ?? testIds.textField}
            />
          )}
          renderTags={(countries: ICountry[], getTagProps) => {
            if (countries.length < mergeAfterCount) {
              return countries.map(
                (country: ICountry, index: number) =>
                  !!country && (
                    // eslint-disable-next-line react/jsx-key
                    <CountryBadge countryCode={country.code} data-testid={testIds.chip} {...getTagProps({ index })} />
                  )
              );
            }
            const x = countries.map((_, index) => () => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { className, ...tagProps } = getTagProps({ index });
              return tagProps;
            });
            return <CountryBadgesMerged countryCodes={countries.map((country) => country.code)} getTagProps={x} />;
          }}
          value={value.map((countryCode) => COUNTRIES_BY_ID[countryCode])}
          onChange={(_, changedValues) => {
            if (singleSelection) {
              onChange(changedValues.map((country) => country?.code).slice(-1));
              return;
            }
            onChange(changedValues.map((country) => country?.code));
          }}
          data-testid={testIds.autocomplete}
        />
        {!hidePresets && (
          <div className={classes.presetMenuContainer}>
            <IconButton
              className={classes.presetsButton}
              onClick={openPresetMenu}
              disabled={disabled}
              title={t('general.presets')}
              data-testid={testIds.presetMenuButton}
            >
              <List />
            </IconButton>
            <Popover
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              anchorEl={presetMenuAnchorEl}
              keepMounted
              open={Boolean(presetMenuAnchorEl)}
              onClose={closePresetMenu}
            >
              {COUNTRY_PRESETS.map((preset, i) => (
                <MenuItem
                  key={i}
                  onClick={() => selectPresetCountries(preset.countries)}
                  data-testid={testIds.presetMenuItem}
                  className={cx({ [classes.disabledOption]: disablePreset?.(preset.countries) })}
                >
                  {preset.name[langForCountryName]}
                </MenuItem>
              ))}
            </Popover>
          </div>
        )}
      </div>
    </div>
  );
}
