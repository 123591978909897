/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiPaginatedResponseOfTournamentResponse } from '../model';
// @ts-ignore
import { ApiResponseOfIsDeletableResponse } from '../model';
// @ts-ignore
import { ApiResponseOfTeamResponseArray } from '../model';
// @ts-ignore
import { ApiResponseOfTournamentResponse } from '../model';
// @ts-ignore
import { ApiResponseOfTournamentResponseArray } from '../model';
// @ts-ignore
import { TournamentBody } from '../model';
/**
 * TournamentsApi - axios parameter creator
 * @export
 */
export const TournamentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TournamentBody} tournamentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (tournamentBody: TournamentBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tournamentBody' is not null or undefined
            assertParamExists('create', 'tournamentBody', tournamentBody)
            const localVarPath = `/api/v1/tournaments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tournamentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tournamentId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (tournamentId: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tournamentId' is not null or undefined
            assertParamExists('getById', 'tournamentId', tournamentId)
            const localVarPath = `/api/v1/tournaments/{tournamentId}`
                .replace(`{${"tournamentId"}}`, encodeURIComponent(String(tournamentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} tournamentIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIds: async (tournamentIds: Array<string>, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tournamentIds' is not null or undefined
            assertParamExists('getByIds', 'tournamentIds', tournamentIds)
            const localVarPath = `/api/v1/tournaments/getByIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tournamentIds) {
                localVarQueryParameter['tournamentIds'] = tournamentIds;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tournamentId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByTournamentId: async (tournamentId: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tournamentId' is not null or undefined
            assertParamExists('getByTournamentId', 'tournamentId', tournamentId)
            const localVarPath = `/api/v1/tournaments/getByTournamentId/{tournamentId}`
                .replace(`{${"tournamentId"}}`, encodeURIComponent(String(tournamentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} tournamentIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByTournamentIds: async (tournamentIds: Array<string>, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tournamentIds' is not null or undefined
            assertParamExists('getByTournamentIds', 'tournamentIds', tournamentIds)
            const localVarPath = `/api/v1/tournaments/getByTournamentIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tournamentIds) {
                localVarQueryParameter['tournamentIds'] = tournamentIds;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated: async (limit?: number, page?: number, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tournaments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tournamentId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsByTournament: async (tournamentId: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tournamentId' is not null or undefined
            assertParamExists('getTeamsByTournament', 'tournamentId', tournamentId)
            const localVarPath = `/api/v1/tournaments/{tournamentId}/teams`
                .replace(`{${"tournamentId"}}`, encodeURIComponent(String(tournamentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isDeletable: async (tournamentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tournamentId' is not null or undefined
            assertParamExists('isDeletable', 'tournamentId', tournamentId)
            const localVarPath = `/api/v1/tournaments/{tournamentId}/isDeletable`
                .replace(`{${"tournamentId"}}`, encodeURIComponent(String(tournamentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (tournamentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tournamentId' is not null or undefined
            assertParamExists('remove', 'tournamentId', tournamentId)
            const localVarPath = `/api/v1/tournaments/{tournamentId}`
                .replace(`{${"tournamentId"}}`, encodeURIComponent(String(tournamentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tournamentId 
         * @param {TournamentBody} tournamentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (tournamentId: string, tournamentBody: TournamentBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tournamentId' is not null or undefined
            assertParamExists('update', 'tournamentId', tournamentId)
            // verify required parameter 'tournamentBody' is not null or undefined
            assertParamExists('update', 'tournamentBody', tournamentBody)
            const localVarPath = `/api/v1/tournaments/{tournamentId}`
                .replace(`{${"tournamentId"}}`, encodeURIComponent(String(tournamentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tournamentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TournamentsApi - functional programming interface
 * @export
 */
export const TournamentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TournamentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TournamentBody} tournamentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(tournamentBody: TournamentBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfTournamentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(tournamentBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tournamentId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(tournamentId: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfTournamentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(tournamentId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} tournamentIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIds(tournamentIds: Array<string>, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfTournamentResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIds(tournamentIds, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tournamentId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByTournamentId(tournamentId: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfTournamentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByTournamentId(tournamentId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} tournamentIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByTournamentIds(tournamentIds: Array<string>, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfTournamentResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByTournamentIds(tournamentIds, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginated(limit?: number, page?: number, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfTournamentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginated(limit, page, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tournamentId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamsByTournament(tournamentId: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfTeamResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamsByTournament(tournamentId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isDeletable(tournamentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfIsDeletableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isDeletable(tournamentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(tournamentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(tournamentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tournamentId 
         * @param {TournamentBody} tournamentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(tournamentId: string, tournamentBody: TournamentBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfTournamentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(tournamentId, tournamentBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TournamentsApi - factory interface
 * @export
 */
export const TournamentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TournamentsApiFp(configuration)
    return {
        /**
         * 
         * @param {TournamentBody} tournamentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(tournamentBody: TournamentBody, options?: any): AxiosPromise<ApiResponseOfTournamentResponse> {
            return localVarFp.create(tournamentBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tournamentId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(tournamentId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfTournamentResponse> {
            return localVarFp.getById(tournamentId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} tournamentIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIds(tournamentIds: Array<string>, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfTournamentResponseArray> {
            return localVarFp.getByIds(tournamentIds, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tournamentId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByTournamentId(tournamentId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfTournamentResponse> {
            return localVarFp.getByTournamentId(tournamentId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} tournamentIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByTournamentIds(tournamentIds: Array<string>, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfTournamentResponseArray> {
            return localVarFp.getByTournamentIds(tournamentIds, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated(limit?: number, page?: number, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfTournamentResponse> {
            return localVarFp.getPaginated(limit, page, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tournamentId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsByTournament(tournamentId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfTeamResponseArray> {
            return localVarFp.getTeamsByTournament(tournamentId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isDeletable(tournamentId: string, options?: any): AxiosPromise<ApiResponseOfIsDeletableResponse> {
            return localVarFp.isDeletable(tournamentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(tournamentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(tournamentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tournamentId 
         * @param {TournamentBody} tournamentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(tournamentId: string, tournamentBody: TournamentBody, options?: any): AxiosPromise<ApiResponseOfTournamentResponse> {
            return localVarFp.update(tournamentId, tournamentBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TournamentsApi - interface
 * @export
 * @interface TournamentsApi
 */
export interface TournamentsApiInterface {
    /**
     * 
     * @param {TournamentBody} tournamentBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApiInterface
     */
    create(tournamentBody: TournamentBody, options?: any): AxiosPromise<ApiResponseOfTournamentResponse>;

    /**
     * 
     * @param {string} tournamentId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApiInterface
     */
    getById(tournamentId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfTournamentResponse>;

    /**
     * 
     * @param {Array<string>} tournamentIds 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApiInterface
     */
    getByIds(tournamentIds: Array<string>, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfTournamentResponseArray>;

    /**
     * 
     * @param {string} tournamentId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApiInterface
     */
    getByTournamentId(tournamentId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfTournamentResponse>;

    /**
     * 
     * @param {Array<string>} tournamentIds 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApiInterface
     */
    getByTournamentIds(tournamentIds: Array<string>, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfTournamentResponseArray>;

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApiInterface
     */
    getPaginated(limit?: number, page?: number, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfTournamentResponse>;

    /**
     * 
     * @param {string} tournamentId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApiInterface
     */
    getTeamsByTournament(tournamentId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfTeamResponseArray>;

    /**
     * 
     * @param {string} tournamentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApiInterface
     */
    isDeletable(tournamentId: string, options?: any): AxiosPromise<ApiResponseOfIsDeletableResponse>;

    /**
     * 
     * @param {string} tournamentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApiInterface
     */
    remove(tournamentId: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {string} tournamentId 
     * @param {TournamentBody} tournamentBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApiInterface
     */
    update(tournamentId: string, tournamentBody: TournamentBody, options?: any): AxiosPromise<ApiResponseOfTournamentResponse>;

}

/**
 * TournamentsApi - object-oriented interface
 * @export
 * @class TournamentsApi
 * @extends {BaseAPI}
 */
export class TournamentsApi extends BaseAPI implements TournamentsApiInterface {
    /**
     * 
     * @param {TournamentBody} tournamentBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApi
     */
    public create(tournamentBody: TournamentBody, options?: any) {
        return TournamentsApiFp(this.configuration).create(tournamentBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tournamentId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApi
     */
    public getById(tournamentId: string, includeDeleted?: boolean, options?: any) {
        return TournamentsApiFp(this.configuration).getById(tournamentId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} tournamentIds 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApi
     */
    public getByIds(tournamentIds: Array<string>, includeDeleted?: boolean, options?: any) {
        return TournamentsApiFp(this.configuration).getByIds(tournamentIds, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tournamentId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApi
     */
    public getByTournamentId(tournamentId: string, includeDeleted?: boolean, options?: any) {
        return TournamentsApiFp(this.configuration).getByTournamentId(tournamentId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} tournamentIds 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApi
     */
    public getByTournamentIds(tournamentIds: Array<string>, includeDeleted?: boolean, options?: any) {
        return TournamentsApiFp(this.configuration).getByTournamentIds(tournamentIds, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApi
     */
    public getPaginated(limit?: number, page?: number, includeDeleted?: boolean, options?: any) {
        return TournamentsApiFp(this.configuration).getPaginated(limit, page, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tournamentId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApi
     */
    public getTeamsByTournament(tournamentId: string, includeDeleted?: boolean, options?: any) {
        return TournamentsApiFp(this.configuration).getTeamsByTournament(tournamentId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tournamentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApi
     */
    public isDeletable(tournamentId: string, options?: any) {
        return TournamentsApiFp(this.configuration).isDeletable(tournamentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tournamentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApi
     */
    public remove(tournamentId: string, options?: any) {
        return TournamentsApiFp(this.configuration).remove(tournamentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tournamentId 
     * @param {TournamentBody} tournamentBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApi
     */
    public update(tournamentId: string, tournamentBody: TournamentBody, options?: any) {
        return TournamentsApiFp(this.configuration).update(tournamentId, tournamentBody, options).then((request) => request(this.axios, this.basePath));
    }
}
