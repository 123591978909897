import React, { useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { CirclePicker, CirclePickerProps, ColorChangeHandler, SketchPicker } from 'react-color';
import { Box, Button, Popover } from '@mui/material';
import { debounce } from 'lodash-es';

export interface IColorPickerProps extends CirclePickerProps {
  circlePickerClassName?: string;
  value: string;
  'data-testid'?: string;
}

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex'
  },
  button: {
    width: 28,
    height: 28,
    borderRadius: 99,
    marginRight: 14,
    marginBottom: 14,
    cursor: 'pointer',
    transform: 'scale(1)',
    transition: 'transform 100ms ease 0s',
    minWidth: 'unset',
    padding: 3,
    '&:hover': {
      transform: 'scale(1.2)'
    }
  },
  customColor: {
    width: '100%',
    height: '100%',
    borderRadius: 99
  },
  rainbow: {
    background: `conic-gradient(
      rgba(255, 0, 0, 1) 0%,
      rgba(255, 154, 0, 1) 10%,
      rgba(208, 222, 33, 1) 20%,
      rgba(79, 220, 74, 1) 30%,
      rgba(63, 218, 216, 1) 40%,
      rgba(47, 201, 226, 1) 50%,
      rgba(28, 127, 238, 1) 60%,
      rgba(95, 21, 242, 1) 70%,
      rgba(186, 12, 248, 1) 80%,
      rgba(251, 7, 217, 1) 90%,
      rgba(255, 0, 0, 1) 100%
  )`
  }
}));

export function ColorPicker({
  circlePickerClassName,
  value,
  colors,
  onChange,
  ...props
}: IColorPickerProps): React.ReactElement {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [customColor, setCustomColor] = useState<string | undefined>(
    value && !colors?.find((c) => c === value) ? value : undefined
  );

  const onChangeDebounced = useMemo(() => {
    return debounce((colorResult, evt) => {
      onChange?.(colorResult, evt);
    }, 1000);
  }, [onChange]);

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (value && !customColor) {
      setCustomColor(value);
    }
  };

  const onPopoverClose = () => {
    setAnchorEl(null);
  };

  const onSketchPickerChange: ColorChangeHandler = (colorResult, evt) => {
    setCustomColor(colorResult.hex);
    onChangeDebounced(colorResult, evt);
  };

  const onCirclePickerChange: ColorChangeHandler = (colorResult, evt) => {
    setCustomColor(undefined);
    onChange?.(colorResult, evt);
  };

  return (
    <div className={`${classes.root} ${props.className} `} data-testid={props['data-testid']}>
      <Button className={`${classes.button} ${classes.rainbow}`} variant="text" onClick={openPopover}>
        <Box className={classes.customColor} sx={{ bgcolor: customColor }} />
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <SketchPicker color={customColor} onChange={onSketchPickerChange} />
      </Popover>
      {colors && value && (
        <CirclePicker
          className={circlePickerClassName}
          color={value}
          colors={colors}
          {...props}
          onChange={onCirclePickerChange}
        />
      )}
    </div>
  );
}
