import { Configuration, MediaApi } from '../../../API';
export class MediaAPI {
  constructor(config: Configuration) {
    const api = new MediaApi(config);
    Object.assign(this, api);

    this.exportCsv = api.exportCsv;
    this.getPaginatedPost = api.getPaginated;
    this.getByCollectionId = api.getByCollectionId;
    this.getByIds = api.getByIds;
    this.getById = api.getById;
    this.getBySmartQuery = api.getBySmartQuery;
    this.getMatches = api.getMatches;
    this.getMatchesByIds = api.getMatchesByIds;
    this.getTransmissionsByIds = api.getTransmissionsByIds;
    this.getChannelsByIds = api.getChannelsByIds;
  }

  exportCsv!: typeof MediaApi.prototype.exportCsv;
  getPaginatedPost!: typeof MediaApi.prototype.getPaginated;
  getByCollectionId!: typeof MediaApi.prototype.getByCollectionId;
  getChannelsByIds!: typeof MediaApi.prototype.getChannelsByIds;
  getByIds!: typeof MediaApi.prototype.getByIds;
  getById!: typeof MediaApi.prototype.getById;
  getBySmartQuery!: typeof MediaApi.prototype.getBySmartQuery;
  getMatches!: typeof MediaApi.prototype.getMatches;
  getMatchesByIds!: typeof MediaApi.prototype.getMatchesByIds;
  getTransmissionsByIds!: typeof MediaApi.prototype.getTransmissionsByIds;
}
