import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { DataPresetType, HeroDataPresetDataBody, HeroUiModuleBody, HeroModuleItemType } from '../../../../../API';
import { useConfirm, useLocales, useNotifications, useTheme, useStatsig } from '../../../../../hooks';
import { Sortable } from '../../../../shared/Sortable';
import Button from '../../../../shared/Button';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { allRequired, isDateValid } from '../../../../../utils/formHelpers';
import { FormBody } from '../../styles';
import { blankHeroContentItem, HeroUiModuleItem } from '../../../../../state/Layouts';
import { makeStyles } from 'tss-react/mui';
import { isHeroMessageValid } from './HeroMessage';
import { HeroContentItemPreview } from './HeroContentItemPreview';
import { HeroContentItemForm, HeroContentItemFormMetadata } from './HeroContentItemForm';
import { PresetDataType, Presets } from '../../../../shared/Presets';
import { HPCPicker } from '../../../../shared/HPCPicker';
import { useData } from '../../../../../data-layer';
import { useRecoilValue } from 'recoil';
import { ArrowDownward } from '@mui/icons-material';
import { AllHeroPresetTypes, getContentTypeFromHeroPreset } from '../../../../../utils/uiModuleUtils';
import { stringToNumberValidated } from '../../../../../utils/consts/uiModules';
import { StatsigGates } from '../../../../../utils/consts/statsigGates';
import TextValidator from '../../../../shared/TextValidator';
import { DateTime } from 'luxon';

const useStyles = makeStyles()((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4)
  },
  container: {
    borderBottom: `1px dotted ${theme.palette.divider}`,
    padding: theme.spacing(3, 0)
  },
  actionPanel: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center',
    marginBottom: theme.spacing(4)
  },
  presetContainer: {
    flexGrow: 1
  },
  hpcGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: '1fr auto',
    gap: theme.spacing(2),
    alignItems: 'center'
  },
  convertButton: {
    gridColumn: 2,
    gridRow: 1
  }
}));

export const testIds = {
  formBody: 'hero-form.form-body',
  hpcConvert: 'hero-form.hpc-convert',
  contentItem: 'hero-form.content-item',
  scrollingTimeSeconds: 'hero-form.scrolling-time-seconds'
};

const MIN_SCROLLING_TIME = 1;
const MAX_SCROLLING_TIME = 9;

const HeroForm = forwardRef<UIModuleTypeForm<HeroUiModuleBody>>((_, ref) => {
  const {
    heroPresetCollections: {
      state: { withLatestPublishedRecordById: withHPCById },
      hook: { queueLatestPublishedIdToFetch: queueHPCFetch }
    },
    presets: {
      state: { withDataCache: withPresetsCache },
      hook: { getByIds: getPresetsByIds }
    }
  } = useData();
  const { confirm } = useConfirm();
  const { classes } = useStyles();
  const { formControlColor } = useTheme();
  const { control, watch, setValue } = useFormContext<HeroUiModuleBody>();
  const { CheckGate } = useStatsig();
  const heroPresetCollection = watch('heroPresetCollection');
  const heroCTVImgFillActive = CheckGate(StatsigGates.heroCTVImgFillActive);
  const heroMobileImgFillActive = CheckGate(StatsigGates.heroMobileImgFillActive);
  const { append, remove, fields, replace, update } = useFieldArray({ control, name: 'heroContents' });
  const { t } = useLocales();
  const { notifyError } = useNotifications();
  const [contentItemFormMetadata, setContentItemFormMetadata] = useState<HeroContentItemFormMetadata>();
  const [loadedPreset, setLoadedPreset] = useState<PresetDataType>();
  const hpcRecord = useRecoilValue(withHPCById(heroPresetCollection));
  const presetsCache = useRecoilValue(withPresetsCache);
  const hpcPresets = useMemo(
    () => hpcRecord?.presets.map((id) => presetsCache[id]?.object) ?? [],
    [hpcRecord, presetsCache]
  );

  useEffect(() => {
    if (heroPresetCollection && !hpcRecord) {
      queueHPCFetch(heroPresetCollection);
    }
  }, [heroPresetCollection, hpcRecord]);

  useEffect(() => {
    if (hpcRecord) {
      getPresetsByIds(hpcRecord.presets);
    }
  }, [hpcRecord]);

  useImperativeHandle(ref, () => ({
    isValid(module) {
      let valid = true;

      if (hasNoContentItems(module)) {
        if (!module.heroPresetCollection) {
          notifyError(t('errors.layouts.no_empty_hero_items'));
          valid = false;
        }
        if (module.heroPresetCollection && !hpcRecord?.presets.length) {
          notifyError(t('errors.layouts.no_empty_hpc_presets'));
          valid = false;
        }
      }

      if (isImagesInvalid(module)) {
        notifyError(t('errors.layouts.images_invalid'));
        valid = false;
      }

      if (isSponsorInvalid(module)) {
        notifyError(t('errors.layouts.sponsor_invalid'));
        valid = false;
      }

      if (areHeroMessagesInvalid(module)) {
        notifyError(t('errors.layouts.hero_messages_invalid'));
        valid = false;
      }

      if (heroCTVImgFillActive && areCTVFillImagesInvalid(module)) {
        notifyError(t('errors.layouts.ctv_fill_image_invalid'));
        valid = false;
      }

      if (heroMobileImgFillActive && areMobileFillImagesInvalid(module)) {
        notifyError(t('errors.layouts.hero_mobile_fill_images_invalid'));
        valid = false;
      }

      return valid;
    }
  }));

  const hasNoContentItems = (module: HeroUiModuleBody) => !module.heroContents.length && !module.heroPresetCollection;

  const isImagesInvalid = (module: HeroUiModuleBody) =>
    module.heroContents.some(
      (item) => !item.landscapeFillImage !== !item.portraitFillImage || !item.logoImage !== !item.portraitLogoImage
    );

  const isSponsorInvalid = (module: HeroUiModuleBody) => {
    for (const contents of module.heroContents) {
      if (!allRequired([contents.sponsorLogo, contents.sponsorStartDate, contents.sponsorEndDate])) return true;
      if (!isDateValid(contents.sponsorStartDate, contents.sponsorEndDate)) return true;
    }
    return false;
  };

  const areHeroMessagesInvalid = (module: HeroUiModuleBody) => {
    for (const contents of module.heroContents) {
      if (!isHeroMessageValid(contents.subtitleText, contents.subtitleDate, contents.subtitleDay)) return true;
    }
    return false;
  };

  const areCTVFillImagesInvalid = (module: HeroUiModuleBody) => {
    let isAnyCTVFillImageEmpty = module.heroContents.some((item) => !item.ctvFillImage);
    let isAnyCTVFillImageFilled = module.heroContents.some((item) => !!item.ctvFillImage);

    if (hpcRecord) {
      isAnyCTVFillImageEmpty =
        isAnyCTVFillImageEmpty || hpcPresets.some((preset) => !(preset.data as AllHeroPresetTypes).ctvFillImage);
      isAnyCTVFillImageFilled =
        isAnyCTVFillImageFilled || hpcPresets.some((preset) => !!(preset.data as AllHeroPresetTypes).ctvFillImage);
    }

    if (isAnyCTVFillImageEmpty && isAnyCTVFillImageFilled) return true;
    return false;
  };

  const areMobileFillImagesInvalid = (module: HeroUiModuleBody) => {
    let isAnyMobileFillImageEmpty = module.heroContents.some((item) => !item.mobileFillImage);
    let isAnyMobileFillImageFilled = module.heroContents.some((item) => !!item.mobileFillImage);

    if (hpcRecord) {
      isAnyMobileFillImageEmpty =
        isAnyMobileFillImageEmpty || hpcPresets.some((preset) => !(preset.data as AllHeroPresetTypes).mobileFillImage);
      isAnyMobileFillImageFilled =
        isAnyMobileFillImageFilled ||
        hpcPresets.some((preset) => !!(preset.data as AllHeroPresetTypes).mobileFillImage);
    }

    if (isAnyMobileFillImageEmpty && isAnyMobileFillImageFilled) return true;
    return false;
  };

  const handleAdd = () => {
    setContentItemFormMetadata({
      isShowingForm: true,
      isEditing: false,
      record: blankHeroContentItem(HeroModuleItemType.VOD)
    });
  };

  const handleAddPreset = () => {
    if (!loadedPreset) return;
    const presetData = loadedPreset as AllHeroPresetTypes;
    const heroItemType = getContentTypeFromHeroPreset(presetData);
    const addedContentItem: HeroUiModuleItem = {
      ...presetData,
      heroItemType
    };
    append(addedContentItem);
  };

  const handleEdit = (index: number, contentItem: HeroUiModuleItem) => {
    setContentItemFormMetadata({
      isShowingForm: true,
      isEditing: true,
      index,
      record: contentItem
    });
  };

  const transformDateTimes = (data: HeroContentItemFormMetadata): HeroContentItemFormMetadata => {
    if (data.record) {
      return {
        ...data,
        record: {
          ...data.record,
          scheduleTimes: data.record.scheduleTimes?.map((item) => ({
            ...item,
            startTime: `${DateTime.fromISO(item.startTime, { setZone: true }).toLocaleString(
              DateTime.TIME_24_WITH_SECONDS
            )}Z`,
            endTime: `${DateTime.fromISO(item.endTime, { setZone: true }).toLocaleString(
              DateTime.TIME_24_WITH_SECONDS
            )}Z`
          }))
        }
      };
    }
    return data;
  };

  const onContentItemSubmit = (contentItemMetadata: HeroContentItemFormMetadata) => {
    const contentItemMetadataWithDateTimes = transformDateTimes(contentItemMetadata);
    if (contentItemMetadataWithDateTimes.index !== undefined && contentItemMetadataWithDateTimes.index >= 0) {
      update(
        contentItemMetadataWithDateTimes.index as number,
        contentItemMetadataWithDateTimes.record as HeroUiModuleItem
      );
    } else {
      append(contentItemMetadataWithDateTimes.record as HeroUiModuleItem);
    }
  };

  const onContentItemDelete = (contentItemMetadata: HeroContentItemFormMetadata) => {
    if (contentItemMetadata.index !== undefined && contentItemMetadata.index >= 0) {
      remove(contentItemMetadata.index);
    }
  };

  const loadPresetData = (presetData: PresetDataType) => {
    setLoadedPreset(presetData);
  };

  const onConvert = async () => {
    if (!hpcRecord?.presets.length) return;

    const answer = await confirm({
      body: (
        <Stack gap={2}>
          <Typography variant="h6">{t('hpc.convert.header')}</Typography>
          <Typography variant="body2">{t('hpc.convert.message')}</Typography>
        </Stack>
      )
    });

    if (answer) {
      append(hpcPresets.map((preset) => preset.data as HeroDataPresetDataBody));
      setValue('heroPresetCollection', '');
    }
  };

  return (
    <>
      <FormBody data-testid={testIds.formBody}>
        <Stack direction="column" gap={4}>
          <Stack direction="column" gap={2}>
            <Typography variant="h6">{t('hpc.scrolling_time_seconds')}</Typography>
            <div>
              <Controller
                name="scrollingTimeSeconds"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextValidator
                    type="number"
                    name="scrollingTimeSeconds"
                    value={value ?? ''}
                    color={formControlColor}
                    onChange={({ target }) =>
                      onChange(
                        stringToNumberValidated(
                          (target as HTMLInputElement).value,
                          MIN_SCROLLING_TIME,
                          MAX_SCROLLING_TIME
                        ) || undefined
                      )
                    }
                    validators={[
                      `minNumber:${MIN_SCROLLING_TIME}`,
                      `maxNumber:${MAX_SCROLLING_TIME}`,
                      'matchRegexp:^[0-9]+$'
                    ]}
                    label={t('hpc.scrolling_time_seconds')}
                    errorMessages={t('general.field_needs_to_be_number_between', {
                      min: MIN_SCROLLING_TIME,
                      max: MAX_SCROLLING_TIME
                    })}
                    data-testid={testIds.scrollingTimeSeconds}
                    fullWidth
                  />
                )}
              />
            </div>
          </Stack>
          <Stack direction="column" gap={2}>
            <Typography variant="h6">{t('hpc.hero_preset_collection')}</Typography>
            <div className={classes.hpcGrid}>
              <Controller
                control={control}
                name="heroPresetCollection"
                render={({ field: { value, onChange } }) => <HPCPicker value={value} onChange={onChange} />}
              />
              <Button
                className={classes.convertButton}
                startIcon={<ArrowDownward />}
                onClick={onConvert}
                disabled={!heroPresetCollection}
                data-testid={testIds.hpcConvert}
              >
                {t('general.convert')}
              </Button>
            </div>
          </Stack>
          <div className={classes.header}>
            <Typography variant="h6">{t('layouts.hero_contents')}</Typography>
            <Typography variant="body2" color="textSecondary" sx={{ fontStyle: 'italic' }}>
              {t('layouts.drag_text')}
            </Typography>
          </div>
          <Sortable
            list={fields}
            setList={replace}
            animation={100}
            ghostClass="sortableGhost"
            dragClass="sortableDragDefault"
          >
            {fields.map((contentItem, i) => (
              <div className={classes.container} key={contentItem.id} data-testid={testIds.contentItem}>
                <HeroContentItemPreview
                  index={i}
                  heroContentItem={contentItem}
                  onRemove={remove}
                  onEdit={() => handleEdit(i, contentItem)}
                />
              </div>
            ))}
          </Sortable>
        </Stack>
        <div className={classes.actionPanel}>
          <div>
            <Button color="grey" onClick={handleAdd}>
              {t('layouts.hero_new_blank_content_item')}
            </Button>
          </div>
          {t('query_builder.operators.or')}
          <div>
            <Button color="grey" onClick={handleAddPreset} disabled={!loadedPreset}>
              {t('layouts.hero_new_content_item_from_preset')}
            </Button>
          </div>
          <div className={classes.presetContainer}>
            <Presets presetType={DataPresetType.HERO} onChange={loadPresetData} hideControls />
          </div>
        </div>
      </FormBody>
      <HeroContentItemForm
        formMetadata={contentItemFormMetadata}
        onSubmit={onContentItemSubmit}
        onDelete={onContentItemDelete}
      />
    </>
  );
});

HeroForm.displayName = 'HeroForm';

export default HeroForm;
