/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { EntitlementType } from './entitlement-type';
import { LocalizedFieldBody } from './localized-field-body';
import { TargetPlatform } from './target-platform';
import { UiModuleExperimentGroup } from './ui-module-experiment-group';

/**
 * 
 * @export
 * @interface InlinePageUiModuleBody
 */
export interface InlinePageUiModuleBody {
    /**
     * 
     * @type {string}
     * @memberof InlinePageUiModuleBody
     */
    moduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof InlinePageUiModuleBody
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof InlinePageUiModuleBody
     */
    moduleType: InlinePageUiModuleBodyModuleTypeEnum;
    /**
     * 
     * @type {Array<TargetPlatform>}
     * @memberof InlinePageUiModuleBody
     */
    targetPlatform?: Array<TargetPlatform>;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof InlinePageUiModuleBody
     */
    caption?: LocalizedFieldBody;
    /**
     * 
     * @type {Array<EntitlementType>}
     * @memberof InlinePageUiModuleBody
     */
    entitlements?: Array<EntitlementType>;
    /**
     * 
     * @type {Array<UiModuleExperimentGroup>}
     * @memberof InlinePageUiModuleBody
     */
    experiments?: Array<UiModuleExperimentGroup> | null;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof InlinePageUiModuleBody
     */
    title: LocalizedFieldBody;
    /**
     * 
     * @type {string}
     * @memberof InlinePageUiModuleBody
     */
    portraitImage: string;
    /**
     * 
     * @type {string}
     * @memberof InlinePageUiModuleBody
     */
    landscapeImage: string;
    /**
     * 
     * @type {string}
     * @memberof InlinePageUiModuleBody
     */
    mobileImage?: string;
    /**
     * 
     * @type {string}
     * @memberof InlinePageUiModuleBody
     */
    ctvImage?: string;
    /**
     * 
     * @type {string}
     * @memberof InlinePageUiModuleBody
     */
    header?: string;
    /**
     * 
     * @type {string}
     * @memberof InlinePageUiModuleBody
     */
    ctaName: string;
    /**
     * 
     * @type {string}
     * @memberof InlinePageUiModuleBody
     */
    ctaUrlPath: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InlinePageUiModuleBodyModuleTypeEnum {
    INLINE_PAGE = 'INLINE_PAGE'
}



