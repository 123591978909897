import { AspectRatiosEnum } from './aspectRatios';
import { AssetFileTypesEnum, AssetGuidelinesType, AssetTypesEnum } from './assetTypes';

const transparentImages = [AssetFileTypesEnum.PNG, AssetFileTypesEnum.WEBP, AssetFileTypesEnum.SVG];
const jpegs = [AssetFileTypesEnum.JPG];

export const AssetGuidelines: AssetGuidelinesType = {
  [AssetTypesEnum.CHANNEL_LOGO]: {
    preferredFileTypes: transparentImages,
    minWidth: 122,
    minHeight: 92,
    preferredAspectRatio: AspectRatiosEnum.a4x3
  },
  [AssetTypesEnum.CHANNEL_PORTRAIT]: {
    preferredFileTypes: jpegs,
    minWidth: 1200,
    minHeight: 1800,
    preferredAspectRatio: AspectRatiosEnum.a2x3
  },
  [AssetTypesEnum.CHANNEL_LANDSCAPE]: {
    preferredFileTypes: jpegs,
    minWidth: 1920,
    minHeight: 1080,
    preferredAspectRatio: AspectRatiosEnum.a16x9
  },
  [AssetTypesEnum.COLLECTIONS_CARD]: {
    preferredFileTypes: jpegs,
    minWidth: 1920,
    minHeight: 1080,
    preferredAspectRatio: AspectRatiosEnum.a16x9
  },
  [AssetTypesEnum.COLLECTIONS_LANDSCAPE]: {
    // not sure here
  },
  [AssetTypesEnum.COLLECTIONS_PORTRAIT]: {
    // not sure here
  },
  [AssetTypesEnum.HERO_LANDSCAPE]: {
    preferredFileTypes: jpegs,
    minWidth: 1920,
    minHeight: 1080,
    preferredAspectRatio: AspectRatiosEnum.a16x9
  },
  [AssetTypesEnum.HERO_PORTRAIT]: {
    preferredFileTypes: jpegs,
    minWidth: 1200,
    minHeight: 1200,
    preferredAspectRatio: AspectRatiosEnum.a1x1
  },
  [AssetTypesEnum.HERO_MOBILE]: {
    preferredFileTypes: jpegs,
    minWidth: 1920,
    minHeight: 1080,
    preferredAspectRatio: AspectRatiosEnum.a16x9
  },
  [AssetTypesEnum.HERO_LOGO]: {
    preferredFileTypes: transparentImages,
    minWidth: 455,
    minHeight: 341,
    preferredAspectRatio: AspectRatiosEnum.a4x3
  },
  [AssetTypesEnum.HERO_LOGO_PORTRAIT]: {
    preferredFileTypes: transparentImages,
    minWidth: 455,
    minHeight: 341,
    preferredAspectRatio: AspectRatiosEnum.a4x3
  },
  [AssetTypesEnum.HERO_SPONSOR]: {
    // not sure here
  },
  [AssetTypesEnum.CONTENT_LIST_LANDSCAPE]: {
    preferredFileTypes: jpegs,
    minWidth: 1920,
    minHeight: 1080,
    preferredAspectRatio: AspectRatiosEnum.a16x9
  },
  [AssetTypesEnum.CONTENT_LIST_PORTRAIT]: {
    preferredFileTypes: jpegs,
    minWidth: 1200,
    minHeight: 1800,
    preferredAspectRatio: AspectRatiosEnum.a2x3
  },
  [AssetTypesEnum.SPORT_LANDSCAPE]: {
    // not sure here
  },
  [AssetTypesEnum.SPORT_PORTRAIT]: {
    // not sure here
  },
  [AssetTypesEnum.LEAGUE_LOGO]: {
    preferredFileTypes: transparentImages
    // too much variance to determine height/width/aspect-ratio
  },
  [AssetTypesEnum.LEAGUE_CARD]: {
    preferredFileTypes: jpegs,
    minWidth: 600,
    minHeight: 340,
    preferredAspectRatio: AspectRatiosEnum.a16x9
  },
  [AssetTypesEnum.LEAGUE_SPLASH]: {
    preferredFileTypes: jpegs,
    minWidth: 1920,
    minHeight: 1080,
    preferredAspectRatio: AspectRatiosEnum.a16x9
  },
  [AssetTypesEnum.TEAM_LOGO]: {
    preferredFileTypes: transparentImages
    // too much variance to determine height/width/aspect-ratio
  },
  [AssetTypesEnum.SPORTS_DEFAULT_LEAGUE_BG]: {
    preferredFileTypes: jpegs,
    // Not sure if this is right (could be same as LEAGUE_CARD)
    minWidth: 1920,
    minHeight: 1080,
    preferredAspectRatio: AspectRatiosEnum.a16x9
  },
  [AssetTypesEnum.SPORTS_DEFAULT_LEAGUE_SPLASH]: {
    preferredFileTypes: jpegs,
    minWidth: 1920,
    minHeight: 1080,
    preferredAspectRatio: AspectRatiosEnum.a16x9
  },
  [AssetTypesEnum.SPORTS_DEFAULT_LEAGUE_LOGO]: {
    preferredFileTypes: transparentImages
  },
  [AssetTypesEnum.SPORTS_DEFAULT_TEAM_LOGO]: {
    preferredFileTypes: transparentImages
  },
  [AssetTypesEnum.PROMO_UPSELL_PORTRAIT]: {
    preferredFileTypes: jpegs
    // too much variance to determine height/width/aspect-ratio
  },
  [AssetTypesEnum.PROMO_UPSELL_LANDSCAPE]: {
    preferredFileTypes: jpegs
    // too much variance to determine height/width/aspect-ratio
  },
  [AssetTypesEnum.PROMO_UPSELL_DESKTOP]: {
    preferredFileTypes: jpegs
    // too much variance to determine height/width/aspect-ratio
  },
  [AssetTypesEnum.PROMO_UPSELL_MOBILE]: {
    preferredFileTypes: jpegs
    // too much variance to determine height/width/aspect-ratio
  },
  [AssetTypesEnum.PROMO_UPSELL_CTV]: {
    preferredFileTypes: jpegs
    // too much variance to determine height/width/aspect-ratio
  },
  [AssetTypesEnum.PROMO_UPSELL_TABLET]: {
    preferredFileTypes: jpegs
    // no data
  },
  [AssetTypesEnum.LIVE_PLUS_TAKEOVER_PORTRAIT]: {
    preferredFileTypes: jpegs,
    minWidth: 1080,
    minHeight: 1920,
    preferredAspectRatio: AspectRatiosEnum.a9x16
  },
  [AssetTypesEnum.LIVE_PLUS_TAKEOVER_LANDSCAPE]: {
    preferredFileTypes: jpegs,
    minWidth: 1920,
    minHeight: 1080,
    preferredAspectRatio: AspectRatiosEnum.a16x9
  },
  [AssetTypesEnum.LIVE_PLUS_TAKEOVER_DESKTOP]: {
    preferredFileTypes: jpegs
    // no data
  },
  [AssetTypesEnum.LIVE_PLUS_TAKEOVER_TABLET]: {
    preferredFileTypes: jpegs,
    minWidth: 1080,
    minHeight: 1440,
    preferredAspectRatio: AspectRatiosEnum.a3x4
  },
  [AssetTypesEnum.LIVE_PLUS_TAKEOVER_MOBILE]: {
    preferredFileTypes: jpegs
    // no data
  },
  [AssetTypesEnum.LIVE_PLUS_TAKEOVER_CTV]: {
    preferredFileTypes: jpegs
    // no data
  },
  [AssetTypesEnum.LIVE_PLUS_END_GAME_PORTRAIT]: {
    preferredFileTypes: jpegs
    // not sure / not enough data
  },
  [AssetTypesEnum.LIVE_PLUS_END_GAME_LANDSCAPE]: {
    preferredFileTypes: jpegs,
    minWidth: 1920,
    minHeight: 1080,
    preferredAspectRatio: AspectRatiosEnum.a16x9
  },
  [AssetTypesEnum.LIVE_PLUS_END_GAME_DESKTOP]: {
    preferredFileTypes: jpegs
    // no data
  },
  [AssetTypesEnum.LIVE_PLUS_END_GAME_TABLET]: {
    preferredFileTypes: jpegs
    // no data
  },
  [AssetTypesEnum.LIVE_PLUS_END_GAME_MOBILE]: {
    preferredFileTypes: jpegs
    // no data
  },
  [AssetTypesEnum.LIVE_PLUS_END_GAME_CTV]: {
    preferredFileTypes: jpegs
    // no data
  },
  [AssetTypesEnum.INLINE_PROMO_PORTRAIT]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.INLINE_PROMO_LANDSCAPE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.INLINE_PROMO_DESKTOP]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.INLINE_PROMO_TABLET]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.INLINE_PROMO_MOBILE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.ACCOUNT_SUCCESS_PORTRAIT]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.ACCOUNT_SUCCESS_LANDSCAPE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.ACCOUNT_SUCCESS_DESKTOP]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.ACCOUNT_SUCCESS_TABLET]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.ACCOUNT_SUCCESS_MOBILE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.ACCOUNT_SUCCESS_CTV]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.ONE_BRAND_PROMO_LANDSCAPE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.ONE_BRAND_PROMO_PORTRAIT]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.ONE_BRAND_PROMO_DESKTOP]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.ONE_BRAND_PROMO_MOBILE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.ONE_BRAND_PROMO_CTV]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.ONE_BRAND_PROMO_TABLET]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.PROFILE_LANDSCAPE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.PROFILE_PORTRAIT]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.PROFILE_DESKTOP]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.PROFILE_MOBILE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.PROFILE_CTV]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.PROFILE_TABLET]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.REG_WALL_LANDSCAPE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.REG_WALL_PORTRAIT]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.REG_WALL_DESKTOP]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.REG_WALL_MOBILE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.REG_WALL_CTV]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.REG_WALL_TABLET]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.INLINE_PAGE_PORTRAIT]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.INLINE_PAGE_LANDSCAPE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.INLINE_PAGE_MOBILE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.INLINE_PAGE_CTV]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.LANDING_PROMO_LANDSCAPE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.LANDING_PROMO_PORTRAIT]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.LANDING_PROMO_DESKTOP]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.LANDING_PROMO_MOBILE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.LANDING_PROMO_CTV]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.LANDING_PROMO_TABLET]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.AI_SEARCH_BACKGROUND_FILL_IMAGE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.TDS_GIFT_CARDS_LOGO]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.TDS_GIFT_CARDS_PARTNER_LOGO]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.TDS_GIFT_CARDS_LANDSCAPE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.TDS_GIFT_CARDS_PORTRAIT]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.TDS_GIFT_CARDS_DESKTOP]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.TDS_GIFT_CARDS_MOBILE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.TDS_GIFT_CARDS_CTV]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.TDS_GIFT_CARDS_TABLET]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.TDS_GIFT_CARDS_HOW_TO_IMAGE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.TDS_GIFT_CARDS_WELCOME_LANDSCAPE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.TDS_GIFT_CARDS_WELCOME_PORTRAIT]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.TDS_GIFT_CARDS_WELCOME_DESKTOP]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.TDS_GIFT_CARDS_WELCOME_MOBILE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.TDS_GIFT_CARDS_WELCOME_CTV]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.TDS_GIFT_CARDS_WELCOME_TABLET]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.SPIN_PREMIA_VIX_LOGO]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.SPIN_PREMIA_LANDSCAPE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.SPIN_PREMIA_PORTRAIT]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.SPIN_PREMIA_DESKTOP]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.SPIN_PREMIA_MOBILE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.SPIN_PREMIA_CTV]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.SPIN_PREMIA_TABLET]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.NEW_PARTNER_LANDING_VIX_LOGO]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.NEW_PARTNER_LANDING_PARTNER_LOGO]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.PAGE_LIST_CARD_IMAGE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION1_LOGO]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION1_DESKTOP]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION1_MOBILE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION1_CTV]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION1_TABLET]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION1_LANDSCAPE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION1_PORTRAIT]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION2_PROVIDER_LOGO]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION5_DESKTOP]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION5_MOBILE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION5_CTV]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION5_TABLET]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION5_LANDSCAPE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION5_PORTRAIT]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION10_DESKTOP]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION10_MOBILE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION10_CTV]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION10_TABLET]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION10_LANDSCAPE]: {
    preferredFileTypes: jpegs
    // not enough data
  },
  [AssetTypesEnum.OUTBOUND_SECTION10_PORTRAIT]: {
    preferredFileTypes: jpegs
    // not enough data
  }
};
