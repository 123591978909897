import {
  DocumentLocale,
  EntitlementType,
  InlinePageUiModuleBody,
  InlinePageUiModuleBodyModuleTypeEnum
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankInlinePageModule: NewModuleFunction<InlinePageUiModuleBody> = (module) => ({
  moduleType: InlinePageUiModuleBodyModuleTypeEnum.INLINE_PAGE,
  moduleId: generateId(),
  title: {
    [DocumentLocale.ES]: ''
  },
  entitlements: [EntitlementType.ANON, EntitlementType.FREE, EntitlementType.SUB],
  portraitImage: '',
  landscapeImage: '',
  ctaName: '',
  ctaUrlPath: '',
  ...module
});
