import React from 'react';
import { Warning } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useLocales } from '../../../hooks';

export function DeletedBadge(): React.ReactElement {
  const { t } = useLocales();
  return (
    <Tooltip title={t('general.entity_is_deleted')} arrow>
      <Warning color="warning" />
    </Tooltip>
  );
}
