import React, { forwardRef } from 'react';
import { Add, Delete } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { PromotionUiModuleBody, PromotionUiModuleUpsellImagesBody } from '../../../../../API';
import { useLocales, useTheme } from '../../../../../hooks';
import { LocaleKeys } from '../../../../../locales/i18n';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { markAsRequired } from '../../../../../utils/formHelpers';
import generateId from '../../../../../utils/generateId';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import Button from '../../../../shared/Button';
import IconButton from '../../../../shared/IconButton';
import { Sortable } from '../../../../shared/Sortable';
import TextValidator from '../../../../shared/TextValidator';
import { ITextValidatorProps } from '../../../../shared/TextValidator/TextValidator';
import { FormBody, FormField, SectionHeader } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { stringToNumber } from '../../../../../utils/consts/uiModules';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';

export const testIds = {
  formBody: 'promotion.form-body',
  formField: (name: string): string => `promotion-form.field.${name}`,
  // Images
  upsellImages: 'promotion-form.upsell-images',
  upsellImagesRemoveButton: 'promotion-form.upsell-images-remove-button',
  upsellImagesAddButton: 'promotion-form.upsell-images-add-button'
};

const PromotionForm = forwardRef<UIModuleTypeForm<PromotionUiModuleBody>>(() => {
  const { control, watch } = useFormContext<PromotionUiModuleBody>();
  const { append, remove, fields, replace } = useFieldArray({ control, name: 'upsellImages' });

  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const moduleId = watch('moduleId');

  const commonTextValidatorProps: Partial<ITextValidatorProps> = {
    required: true,
    color: formControlColor,
    fullWidth: true,
    validators: ['required'],
    errorMessages: [t('general.field_is_required')]
  };

  type PromotionProps = {
    name: keyof PromotionUiModuleBody;
    required?: boolean;
    'data-testid'?: string;
  };

  const PromotionTextValidator = (name: keyof PromotionUiModuleBody, required = false): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.promotion.fields.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  const PromotionNumberValidator = ({ name }: PromotionProps): JSX.Element => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <TextValidator
            type="number"
            name={name}
            value={value}
            label={t(`layouts.promotion.fields.${name}` as LocaleKeys) as string}
            validators={['minNumber:0', 'matchRegexp:^[0-9]+$']}
            color={formControlColor}
            fullWidth
            onChange={({ target }) => onChange(stringToNumber((target as HTMLInputElement).value))}
          />
        )}
      />
    </FormField>
  );

  const PromotionCheckboxValidator = ({ name }: PromotionProps): JSX.Element => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <FormControlLabel
            label={t(`layouts.promotion.fields.${name}` as LocaleKeys) as string}
            control={
              <Checkbox
                name={name}
                checked={value as boolean}
                color={formControlColor}
                onChange={({ target: { checked } }) => {
                  onChange(checked);
                }}
              />
            }
          />
        )}
      />
    </FormField>
  );

  const onAddUpsellImages = () => {
    append({
      id: generateId(),
      landscapeUpsellImage: '',
      portraitUpsellImage: '',
      desktopUpsellImage: '',
      mobileUpsellImage: '',
      ctvUpsellImage: '',
      tabletUpsellImage: ''
    });
  };

  const PromotionAssetPicker = ({
    name,
    assetType,
    position,
    required = false
  }: {
    name: keyof PromotionUiModuleUpsellImagesBody;
    assetType: AssetTypesEnum;
    position: number;
    required?: boolean;
  }): JSX.Element => {
    const isRequired = (label: string) => (required ? markAsRequired(label) : label);
    return (
      <Controller
        control={control}
        name={`upsellImages.${position}.${name}`}
        rules={{ required }}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <FormField>
              <AssetBrowser
                label={isRequired(t(`layouts.promotion.fields.${name}`))}
                assetId={moduleId || ''}
                assetType={assetType}
                value={value}
                data-testid={testIds.formField(name)}
                hasError={!!error}
                onChange={onChange}
              />
            </FormField>
          );
        }}
      />
    );
  };

  return (
    <FormBody data-testid={testIds.formBody}>
      <Box>
        <SectionHeader sx={{ display: 'flex', alignItems: 'center', gap: (theme) => theme.spacing(4) }}>
          {t('layouts.promotion.headers.upsellImages')}
          <Typography variant="body2" color="textSecondary" sx={{ fontStyle: 'italic' }}>
            {t('layouts.drag_text')}
          </Typography>
        </SectionHeader>
        <Sortable list={fields} setList={replace} ghostClass="sortableGhost" dragClass="sortableDragDefault">
          {fields.map((upsellImage, i) => (
            <Stack
              key={upsellImage.id}
              data-testid={testIds.upsellImages}
              gap={6}
              marginBottom={5}
              direction="row"
              alignItems="center"
            >
              <Box>
                <PromotionAssetPicker
                  name="landscapeUpsellImage"
                  assetType={AssetTypes.promotion.landscapeUpsell}
                  position={i}
                  required
                />
                <PromotionAssetPicker
                  name="portraitUpsellImage"
                  assetType={AssetTypes.promotion.portraitUpsell}
                  position={i}
                  required
                />
                <PromotionAssetPicker
                  name="desktopUpsellImage"
                  assetType={AssetTypes.promotion.desktopUpsell}
                  position={i}
                />
                <PromotionAssetPicker
                  name="mobileUpsellImage"
                  assetType={AssetTypes.promotion.mobileUpsell}
                  position={i}
                />
                <PromotionAssetPicker name="ctvUpsellImage" assetType={AssetTypes.promotion.ctvUpsell} position={i} />
                <PromotionAssetPicker
                  name="tabletUpsellImage"
                  assetType={AssetTypes.promotion.tabletUpsell}
                  position={i}
                />
              </Box>
              <IconButton
                title={t('layouts.promotion.actions.remove_upsell_images')}
                disabled={fields.length === 1}
                onClick={() => remove(i)}
                sx={{ mt: 4 }}
                data-testid={testIds.upsellImagesRemoveButton}
              >
                <Delete />
              </IconButton>
            </Stack>
          ))}
        </Sortable>

        <Button
          startIcon={<Add />}
          color={formControlColor}
          variant="outlined"
          onClick={onAddUpsellImages}
          data-testid={testIds.upsellImagesAddButton}
        >
          {t('layouts.promotion.actions.add_upsell_images')}
        </Button>
        <Box>
          <Controller
            control={control}
            name="autoRotateSeconds"
            render={({ field: { value, onChange } }) => (
              <TextValidator
                name="autoRotateSeconds"
                sx={{ width: 280, mt: 5 }}
                inputProps={{ type: 'number', inputMode: 'numeric', pattern: '[0-9]*' }}
                label={t('layouts.promotion.fields.autoRotate')}
                value={value || ''}
                onChange={({ target: { value } }) => {
                  onChange(value ? parseInt(value) : undefined);
                }}
                {...commonTextValidatorProps}
              />
            )}
          />
        </Box>
      </Box>
      <Box>
        <SectionHeader>{t('layouts.promotion.headers.upsell')}</SectionHeader>
        {PromotionTextValidator('upsellHeader', true)}
        {PromotionTextValidator('upsellSubheader', true)}
        {PromotionTextValidator('upsellLead', true)}
        {PromotionTextValidator('upsellCallToAction', true)}
        {PromotionTextValidator('redeemCouponCallToAction')}
      </Box>
      <Box>
        <SectionHeader>{t('layouts.promotion.headers.value')}</SectionHeader>
        {PromotionTextValidator('valuePropsHeader', true)}
        {PromotionTextValidator('valuePropsSubheader', true)}
        {PromotionTextValidator('valueProps', true)}
        {PromotionTextValidator('valuePropsDisclaimer', true)}
        {PromotionTextValidator('valuePropsCallToAction', true)}
        {PromotionTextValidator('returnCallToAction', true)}
      </Box>
      <Box>
        <SectionHeader>{t('layouts.promotion.headers.welcome')}</SectionHeader>
        {PromotionTextValidator('welcomeHeader')}
        {PromotionTextValidator('welcomeSubheader')}
        {PromotionTextValidator('loginCallToAction')}
        {PromotionTextValidator('moreCallToAction')}
      </Box>
      <Box>
        <SectionHeader>{t('layouts.promotion.headers.planPickerInfo')}</SectionHeader>
        {PromotionTextValidator('planPickerHeader')}
        {PromotionTextValidator('planTileTitle')}
        {PromotionTextValidator('planTileDescription')}
        {PromotionTextValidator('planTilePrice')}
        {PromotionTextValidator('planTileBadge')}
        <PromotionNumberValidator name="planTilePriority" />
        <PromotionCheckboxValidator name="planTileIsHighlighted" />
        <PromotionCheckboxValidator name="isDefault" />
      </Box>
    </FormBody>
  );
});

PromotionForm.displayName = 'PromotionForm';

export default PromotionForm;
