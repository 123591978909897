import React, { useCallback } from 'react';
import { useLocales } from '../../../hooks';
import { useRecoilValue } from 'recoil';
import { Download, Warning } from '@mui/icons-material';
import { ExportEntityType, PageLayoutBody } from '../../../API';
import { withStatsigUiModulesDefaultConfig, withUiModulesConfigByType } from '../../../state/Experiments';
import { useData } from '../../../data-layer';
import IconButton from '../../shared/IconButton';
import { UIModuleType } from '../../../utils/layouts';
import { withHistoryManager } from '../../../state/General/withHistoryManager';
import { UiModuleBody } from '../../../state/Layouts';
import { makeStyles } from 'tss-react/mui';
import { Box } from '@mui/material';

type UIModuleExportProps = {
  uiModule: UiModuleBody;
  moduleType: UIModuleType;
};

const useStyles = makeStyles()((theme) => ({
  containerButtons: {
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    gap: theme.spacing(0.5)
  },
  warningIcon: {
    color: `${theme.palette.warning.main} !important`
  }
}));

function UIModuleExport({ uiModule, moduleType }: UIModuleExportProps): JSX.Element | null {
  const {
    dataExchanges: {
      hook: { exportEntity }
    }
  } = useData();
  const { t } = useLocales();
  const { classes } = useStyles();

  const uiModulesDefaultConfig = useRecoilValue(withStatsigUiModulesDefaultConfig);
  const uiModuleConfig = useRecoilValue(withUiModulesConfigByType(moduleType));
  const isExchangeable = uiModuleConfig?.exchangeable ?? uiModulesDefaultConfig?.exchangeable;
  const historyManager = useRecoilValue(withHistoryManager);

  const handleExport = async () => {
    uiModule.moduleId && (await exportEntity(uiModule.moduleId, ExportEntityType.UI_MODULE));
  };

  const uiModuleHaveChanges = useCallback(() => {
    const { originalState, editHistory } = historyManager.state as {
      originalState: PageLayoutBody;
      editHistory: PageLayoutBody[];
    };
    if (!originalState || !editHistory?.length) return false;
    const originalModule = originalState.uiModules.find((module) => module.moduleId === uiModule.moduleId);
    if (!originalModule) return false;
    return JSON.stringify(originalModule) !== JSON.stringify(uiModule);
  }, [uiModule, historyManager]);

  const uiModuleIsNew = useCallback(() => {
    const { originalState } = historyManager.state as { originalState: PageLayoutBody };
    if (!originalState) return false;
    return !originalState.uiModules.find((module) => module.moduleId === uiModule.moduleId);
  }, [uiModule, historyManager]);

  return isExchangeable && !uiModuleIsNew() ? (
    <Box className={classes.containerButtons}>
      <IconButton color="secondary" onClick={handleExport} title={t('layouts.export_ui_module')}>
        <Download />
      </IconButton>
      {uiModuleHaveChanges() && (
        <IconButton color="secondary" title={t('layouts.export_ui_module_warning')}>
          <Warning className={classes.warningIcon} />
        </IconButton>
      )}
    </Box>
  ) : null;
}

export default UIModuleExport;
