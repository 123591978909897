/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum SmartQueryOperatorSymbol {
    EQ = 'eq',
    NEQ = 'neq',
    LT = 'lt',
    LTE = 'lte',
    GT = 'gt',
    GTE = 'gte',
    CONTAINS = 'contains',
    ANY_OF = 'any-of',
    AND = 'and',
    OR = 'or',
    NOT_CONTAINS = 'not-contains'
}



