import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../hooks';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    gap: theme.spacing(4),
    height: '100%',
    textAlign: 'center'
  },
  header: {
    fontSize: '5em'
  },
  footer: {
    fontSize: '3.5em'
  },
  imageContainer: {
    flexGrow: 1,
    backgroundImage: 'url("/assets/404.png")',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  }
}));

export const testIds = {
  componentRoot: '404-component-root'
};

export default function FourOhFour(): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();

  return (
    <div className={classes.container} data-testid={testIds.componentRoot}>
      <div className={classes.header}>404</div>
      <div className={classes.imageContainer} />
      <div className={classes.footer}>{t('general.404')}</div>
    </div>
  );
}
