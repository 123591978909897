import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ContentPreview } from '..';
import { InlinePageUiModuleBody } from '../../../../../API';
import { useLokalise } from '../../../../../hooks/General/useLokalise';
import { getAssetUrl } from '../../../../../utils/assetHelpers';
import { DisplayAsOptions } from '../../../../../utils/types/genericTypes';
import { VixOrangeButton } from '../shared/SharedButtons';

export const useStyles = makeStyles()((theme) => ({
  backgroundImage: {
    margin: theme.spacing(0, 4, 4),
    width: 750,
    height: 150,
    backgroundSize: 'cover',
    overflow: 'hidden'
  },
  ctaContainer: {
    width: '80%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    overflow: 'hidden'
  },
  ctaContainerInner: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: 70,
    color: 'white',
    overflow: 'hidden'
  },
  header: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
}));

export interface InlinePagePreviewProps {
  showing: boolean;
  module: InlinePageUiModuleBody;
}

export const testIds = {
  background: 'inline-page-preview.background',
  cta: 'inline-page-preview.cta'
};

export function InlinePagePreview({ showing, module }: InlinePagePreviewProps): React.ReactElement {
  const { classes } = useStyles();
  const { lt } = useLokalise();
  const backgroundImageUrl = module.landscapeImage ? getAssetUrl(module.landscapeImage) : '';

  return (
    <ContentPreview showing={showing} displayAs={DisplayAsOptions.LANDSCAPE}>
      <div
        className={classes.backgroundImage}
        style={{ backgroundImage: `url(${backgroundImageUrl})` }}
        data-testid={testIds.background}
      >
        <div className={classes.ctaContainer}>
          <div className={classes.ctaContainerInner}>
            {module.header && (
              <Typography className={classes.header} variant="body2">
                {lt(module.header)}
              </Typography>
            )}
            <VixOrangeButton size="small" data-testid={testIds.cta}>
              {lt(module.ctaName)}
            </VixOrangeButton>
          </div>
        </div>
      </div>
    </ContentPreview>
  );
}
