import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import IconButton from '../../../../shared/IconButton';
import { AddOutlined, Delete, Info, PushPin, Search } from '@mui/icons-material';
import { IconButtonProps, useTheme } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { useLocales } from '../../../../../hooks';
import { isEntityDeleted, stopPropagation } from '../../../../../utils/generalUtils';
import { useData } from '../../../../../data-layer';
import { useRecoilValue } from 'recoil';

export const testIds = {
  addButton: 'MediaButtons-addBtn',
  editButton: 'MediaButtons-editBtn',
  searchButton: 'MediaButtons-searchBtn',
  deleteButton: 'MediaButtons-deleteBtn',
  pinButton: 'MediaButtons-pinBtn',
  infoButton: 'MediaButtons-infoBtn',
  cleanUpButton: 'MediaButtons-cleanupBtn'
};

const StyledIcon = withStyles(IconButton, (theme, { size }) => ({
  root: {
    color: 'inherit',
    padding: size === 'small' ? theme.spacing(1) : undefined,
    ['&[data-backdrop="true"]']: {
      color: 'white',
      background: 'radial-gradient(circle at center, black, transparent 70%)'
    }
  }
}));

interface MediaButtonsPartial {
  className?: string;
  backdrop?: boolean;
  inCollection?: boolean;
  collectionForKids?: boolean;
  aptForKids?: boolean;
  collectionSelected?: boolean;
  isEditing?: boolean;
  previewMode?: boolean;
  onAdd?: (evt?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => unknown;
  onEdit?: () => unknown;
  onSearch?: () => unknown;
  onDelete?: () => unknown;
  onPin?: () => unknown;
  isPinned?: boolean;
  autoRotateIndex?: number;
  enablePinning?: boolean;
}

export type MediaButtonsProps = MediaButtonsPartial & Pick<IconButtonProps, 'size'>;

function MediaButtons({
  className,
  size = 'medium',
  backdrop,
  inCollection,
  collectionForKids,
  aptForKids,
  collectionSelected,
  isEditing,
  previewMode,
  onAdd,
  onEdit,
  onDelete,
  onSearch,
  onPin,
  isPinned,
  enablePinning,
  autoRotateIndex = -1
}: MediaButtonsProps): JSX.Element {
  const {
    collections: {
      state: { withSelected }
    }
  } = useData();

  const selectedCollection = useRecoilValue(withSelected);
  const [pinButtonStyles, setPinButtonStyles] = useState<{ color: string; transform: string }>();

  const onKidsCollection = (): boolean => {
    if (!collectionForKids) return true;
    return !!aptForKids;
  };

  const { t } = useLocales();

  const handleAdd = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onAdd?.(evt);
  const handleEdit = () => onEdit?.();
  const handleDelete = () => onDelete?.();
  const handleSearch = () => onSearch?.();
  const handlePin = () => onPin?.();

  const canAdd =
    onAdd &&
    !inCollection &&
    onKidsCollection() &&
    !previewMode &&
    collectionSelected &&
    !isEntityDeleted(selectedCollection);
  const canEdit = onEdit && previewMode;
  const canSearch = onSearch && !isEditing && inCollection && !previewMode;
  const canDelete = onDelete && isEditing && !previewMode;
  const canPin = onPin && isEditing && !previewMode;

  const { palette } = useTheme();

  useEffect(() => {
    if (isPinned) {
      setPinButtonStyles({
        color: palette.success.main,
        transform: 'none'
      });
    } else {
      setPinButtonStyles({
        color: palette.common.white,
        transform: 'rotate(45deg)'
      });
    }
  }, [isPinned]);

  return (
    <Box className={className} style={{ display: 'flex', opacity: 1 }}>
      {/* Add Button */}
      {canAdd && (
        <StyledIcon
          size={size}
          data-testid={testIds.addButton}
          onClick={stopPropagation(handleAdd)}
          data-backdrop={backdrop}
        >
          <AddOutlined />
        </StyledIcon>
      )}

      {/* Edit Button */}
      {canEdit && (
        <StyledIcon
          size={size}
          data-testid={testIds.editButton}
          onClick={stopPropagation(handleEdit)}
          data-backdrop={backdrop}
          title={t('content.view_more')}
        >
          <Info />
        </StyledIcon>
      )}

      {/* Search Button */}
      {canSearch && (
        <StyledIcon
          size={size}
          data-testid={testIds.searchButton}
          onClick={stopPropagation(handleSearch)}
          data-backdrop={backdrop}
        >
          <Search />
        </StyledIcon>
      )}

      {/* Pin Button */}
      {canPin && enablePinning && (
        <StyledIcon
          title={t('collections.auto_rotate.current_position_tooltip', { x: autoRotateIndex })}
          size={size}
          style={pinButtonStyles}
          data-testid={testIds.pinButton}
          onClick={stopPropagation(handlePin)}
          data-backdrop={backdrop}
        >
          <PushPin />
        </StyledIcon>
      )}

      {/* Delete Button */}
      {canDelete && (
        <StyledIcon
          size={size}
          data-testid={testIds.deleteButton}
          onClick={stopPropagation(handleDelete)}
          data-backdrop={backdrop}
        >
          <Delete />
        </StyledIcon>
      )}
    </Box>
  );
}

export default MediaButtons;
