import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import {
  LiveNewsUiModuleBody,
  LiveNewsModuleContentItem,
  LiveNewsUIModuleContentTreatment,
  VllChannelResponse
} from '../../../../../API';
import { constructChannelsBucketId, useData } from '../../../../../data-layer';
import { useLocales, useNotifications } from '../../../../../hooks';
import {
  withChannelLiveFilter,
  withChannelPremiumFilter,
  withChannelTypeFilter,
  withSelectedLayoutCountries
} from '../../../../../state/Layouts';
import { ALL, FALSE, TRUE } from '../../../../../utils/types/genericTypes';
import EPGChannelItem from '../../../../EPG/EPGChannelItem';
import { EPGChannelFilters } from '../../../../EPG/EPGChannels/EPGChannelFilters';
import { TransferList } from '../../../../shared/TransferList';
import { FormBody } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { makeStyles } from 'tss-react/mui';
import TreatmentPicker from '../../TreatmentPicker';

const useStyles = makeStyles()((theme) => ({
  transferList: {
    height: '100%',
    width: '100%'
  },
  channelsSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    flex: 1
  },
  channelsSectionTitle: {
    flexBasis: 1,
    flexGrow: 1
  },
  channelsSectionFilters: {
    flexBasis: 1,
    flexGrow: 1,
    display: 'flex',
    gap: theme.spacing(2),
    position: 'relative',
    top: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  channelsSectionFiltersSpacer: {
    width: 40,
    paddingLeft: theme.spacing(3)
  },
  transferListContainer: {
    position: 'relative',
    flex: 1
  }
}));

export const testIds = {
  formBody: 'liveNews-module-form.formBody'
};

const ChannelsForm = forwardRef<UIModuleTypeForm<LiveNewsUiModuleBody>>((_, ref) => {
  const { control, watch } = useFormContext<LiveNewsUiModuleBody>();

  const { t, localize } = useLocales();
  const { notifyError } = useNotifications();
  const { classes } = useStyles();

  const {
    channels: {
      hook: { getBucket },
      state: { withIsFetching, withRecordBucket }
    }
  } = useData();
  const layoutCountries = useRecoilValue(withSelectedLayoutCountries);
  const bucketId = constructChannelsBucketId({ layoutCountries });
  const channels = useRecoilValue(withRecordBucket(bucketId));
  const liveNewsContents = watch('liveNewsContents');
  const [filteredContentItems, setFilteredContentItems] = useState<LiveNewsModuleContentItem[]>([]);
  const isFetchingChannels = useRecoilValue(withIsFetching);

  // Filters
  const channelType = useRecoilValue(withChannelTypeFilter);
  const isLive = useRecoilValue(withChannelLiveFilter);
  const isPremium = useRecoilValue(withChannelPremiumFilter);

  useEffect(() => {
    if (!channels) {
      getBucket(bucketId);
    }
  }, [layoutCountries]);

  useEffect(() => {
    if (!channels) return;
    filterChannels(channels);
  }, [channels, liveNewsContents, channelType, isLive, isPremium]);

  useImperativeHandle(ref, () => ({
    isValid(uiModule) {
      if (checkHasEmptyContentItems(uiModule)) {
        notifyError(t('errors.layouts.no_empty_channels_module'));
        return false;
      }
      return true;
    }
  }));

  const checkHasEmptyContentItems = (uiModule: LiveNewsUiModuleBody) => !uiModule?.liveNewsContents.length;

  const filterChannels = (channels: VllChannelResponse[]) => {
    setFilteredContentItems(
      channels
        .filter((channel) => {
          if (channelType !== ALL && channel.type !== channelType) {
            return false;
          }
          if ((isLive === TRUE && !channel.isLive) || (isLive === FALSE && channel.isLive)) {
            return false;
          }
          if ((isPremium === TRUE && !channel.isPremium) || (isPremium === FALSE && channel.isPremium)) {
            return false;
          }
          return true;
        })
        .map(({ entityId }) => ({ contentId: entityId }) as LiveNewsModuleContentItem)
    );
  };

  return (
    <FormBody data-testid={testIds.formBody}>
      <div>
        <Controller
          control={control}
          name="contentTreatment"
          render={({ field: { value, onChange } }) => (
            <TreatmentPicker
              options={LiveNewsUIModuleContentTreatment}
              value={value}
              onChange={(value) => onChange(value as LiveNewsUIModuleContentTreatment)}
            />
          )}
        />
      </div>
      <div className={classes.channelsSection}>
        <Box sx={{ display: 'flex' }}>
          <div className={classes.channelsSectionTitle}>
            <Typography variant="h6">{t('layouts.live_news_new_content_title')}</Typography>
            <Typography variant="body2">{t('layouts.live_news_new_content_sub_text')}</Typography>
          </div>
          <div className={classes.channelsSectionFilters}>
            <div className={classes.channelsSectionFiltersSpacer} />
            <EPGChannelFilters />
          </div>
        </Box>
        <div className={classes.transferListContainer}>
          <Controller
            control={control}
            name="liveNewsContents"
            render={({ field: { value, onChange } }) => (
              <TransferList
                className={classes.transferList}
                leftList={value}
                rightList={filteredContentItems}
                setLeftList={onChange}
                isLoading={isFetchingChannels}
                skeletonNumber={30}
                keyExtractor={(child: LiveNewsModuleContentItem) => child.contentId}
                renderChild={(child: LiveNewsModuleContentItem) => {
                  const channel = channels?.find(({ entityId }) => entityId === child.contentId);
                  return channel ? <EPGChannelItem ignoreMixedView channel={channel} isStandalone={true} /> : <></>;
                }}
                searchFn={(text, child) =>
                  localize(channels?.find(({ entityId }) => entityId === child.contentId)?.title)
                    .toLowerCase()
                    .search(text.toLowerCase()) !== -1
                }
              />
            )}
          />
        </div>
      </div>
    </FormBody>
  );
});

ChannelsForm.displayName = 'ChannelsForm';

export default ChannelsForm;
