import React from 'react';
import MaterialSelect, { SelectProps } from '@mui/material/Select';

export interface ISelectProps extends Omit<SelectProps, 'onChange'> {
  'data-testid'?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const testIds = {
  select: 'select'
};

export const Select = ({ 'data-testid': dataTestId, onChange, ...props }: ISelectProps): JSX.Element => {
  return (
    <MaterialSelect
      data-testid={dataTestId || testIds.select}
      onChange={(evt) => onChange(evt as React.ChangeEvent<HTMLInputElement>)}
      {...props}
    />
  );
};

Select.displayName = 'Select';
