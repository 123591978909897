import {
  DocumentLocale,
  EntitlementType,
  RegistrationWallUiModuleBody,
  RegistrationWallUiModuleBodyModuleTypeEnum
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankRegWallModule: NewModuleFunction<RegistrationWallUiModuleBody> = (module) => ({
  moduleType: RegistrationWallUiModuleBodyModuleTypeEnum.REGISTRATION_WALL,
  moduleId: generateId(),
  title: {
    [DocumentLocale.ES]: ''
  },
  entitlements: [EntitlementType.ANON, EntitlementType.FREE, EntitlementType.SUB],
  header: '',
  ...module
});
