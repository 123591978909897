/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiPaginatedResponseOfAssetResponse } from '../model';
// @ts-ignore
import { ApiResponseOfAssetResponse } from '../model';
// @ts-ignore
import { AssetUpdateBody } from '../model';
// @ts-ignore
import { FocusMode } from '../model';
/**
 * AssetsApi - axios parameter creator
 * @export
 */
export const AssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates an image asset in the **Vix CMS** application.
         * @param {string} assetId Asset´s identifier
         * @param {string} assetName Asset´s name
         * @param {string} assetType Asset´s type
         * @param {FocusMode} focusOptionsMode 
         * @param {number} [focusOptionsFocusX] 
         * @param {number} [focusOptionsFocusY] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (assetId: string, assetName: string, assetType: string, focusOptionsMode: FocusMode, focusOptionsFocusX?: number, focusOptionsFocusY?: number, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('create', 'assetId', assetId)
            // verify required parameter 'assetName' is not null or undefined
            assertParamExists('create', 'assetName', assetName)
            // verify required parameter 'assetType' is not null or undefined
            assertParamExists('create', 'assetType', assetType)
            // verify required parameter 'focusOptionsMode' is not null or undefined
            assertParamExists('create', 'focusOptionsMode', focusOptionsMode)
            const localVarPath = `/api/v1/assets/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (assetId !== undefined) {
                localVarQueryParameter['assetId'] = assetId;
            }

            if (assetName !== undefined) {
                localVarQueryParameter['assetName'] = assetName;
            }

            if (assetType !== undefined) {
                localVarQueryParameter['assetType'] = assetType;
            }

            if (focusOptionsMode !== undefined) {
                localVarQueryParameter['focusOptions[mode]'] = focusOptionsMode;
            }

            if (focusOptionsFocusX !== undefined) {
                localVarQueryParameter['focusOptions[focusX]'] = focusOptionsFocusX;
            }

            if (focusOptionsFocusY !== undefined) {
                localVarQueryParameter['focusOptions[focusY]'] = focusOptionsFocusY;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the asset by filePath in the **Vix CMS** application.
         * @param {string} filePath 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByFilePath: async (filePath: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filePath' is not null or undefined
            assertParamExists('getByFilePath', 'filePath', filePath)
            const localVarPath = `/api/v1/assets/filePath`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filePath !== undefined) {
                localVarQueryParameter['filePath'] = filePath;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the image of a video asset in the **Vix CMS** application.
         * @param {string} assetId 
         * @param {boolean} includeDeleted 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (assetId: string, includeDeleted: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getById', 'assetId', assetId)
            // verify required parameter 'includeDeleted' is not null or undefined
            assertParamExists('getById', 'includeDeleted', includeDeleted)
            const localVarPath = `/api/v1/assets/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [assetType] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated: async (limit?: number, page?: number, assetType?: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (assetType !== undefined) {
                localVarQueryParameter['assetType'] = assetType;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (assetId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('remove', 'assetId', assetId)
            const localVarPath = `/api/v1/assets/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assetId 
         * @param {AssetUpdateBody} assetUpdateBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (assetId: string, assetUpdateBody: AssetUpdateBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('update', 'assetId', assetId)
            // verify required parameter 'assetUpdateBody' is not null or undefined
            assertParamExists('update', 'assetUpdateBody', assetUpdateBody)
            const localVarPath = `/api/v1/assets/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetUpdateBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetsApi - functional programming interface
 * @export
 */
export const AssetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates an image asset in the **Vix CMS** application.
         * @param {string} assetId Asset´s identifier
         * @param {string} assetName Asset´s name
         * @param {string} assetType Asset´s type
         * @param {FocusMode} focusOptionsMode 
         * @param {number} [focusOptionsFocusX] 
         * @param {number} [focusOptionsFocusY] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(assetId: string, assetName: string, assetType: string, focusOptionsMode: FocusMode, focusOptionsFocusX?: number, focusOptionsFocusY?: number, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfAssetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(assetId, assetName, assetType, focusOptionsMode, focusOptionsFocusX, focusOptionsFocusY, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the asset by filePath in the **Vix CMS** application.
         * @param {string} filePath 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByFilePath(filePath: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfAssetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByFilePath(filePath, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the image of a video asset in the **Vix CMS** application.
         * @param {string} assetId 
         * @param {boolean} includeDeleted 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(assetId: string, includeDeleted: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfAssetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(assetId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [assetType] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginated(limit?: number, page?: number, assetType?: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfAssetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginated(limit, page, assetType, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(assetId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} assetId 
         * @param {AssetUpdateBody} assetUpdateBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(assetId: string, assetUpdateBody: AssetUpdateBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfAssetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(assetId, assetUpdateBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetsApi - factory interface
 * @export
 */
export const AssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetsApiFp(configuration)
    return {
        /**
         * Creates an image asset in the **Vix CMS** application.
         * @param {string} assetId Asset´s identifier
         * @param {string} assetName Asset´s name
         * @param {string} assetType Asset´s type
         * @param {FocusMode} focusOptionsMode 
         * @param {number} [focusOptionsFocusX] 
         * @param {number} [focusOptionsFocusY] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(assetId: string, assetName: string, assetType: string, focusOptionsMode: FocusMode, focusOptionsFocusX?: number, focusOptionsFocusY?: number, file?: any, options?: any): AxiosPromise<ApiResponseOfAssetResponse> {
            return localVarFp.create(assetId, assetName, assetType, focusOptionsMode, focusOptionsFocusX, focusOptionsFocusY, file, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the asset by filePath in the **Vix CMS** application.
         * @param {string} filePath 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByFilePath(filePath: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfAssetResponse> {
            return localVarFp.getByFilePath(filePath, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the image of a video asset in the **Vix CMS** application.
         * @param {string} assetId 
         * @param {boolean} includeDeleted 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(assetId: string, includeDeleted: boolean, options?: any): AxiosPromise<ApiResponseOfAssetResponse> {
            return localVarFp.getById(assetId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [assetType] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated(limit?: number, page?: number, assetType?: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfAssetResponse> {
            return localVarFp.getPaginated(limit, page, assetType, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(assetId: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} assetId 
         * @param {AssetUpdateBody} assetUpdateBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(assetId: string, assetUpdateBody: AssetUpdateBody, options?: any): AxiosPromise<ApiResponseOfAssetResponse> {
            return localVarFp.update(assetId, assetUpdateBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetsApi - interface
 * @export
 * @interface AssetsApi
 */
export interface AssetsApiInterface {
    /**
     * Creates an image asset in the **Vix CMS** application.
     * @param {string} assetId Asset´s identifier
     * @param {string} assetName Asset´s name
     * @param {string} assetType Asset´s type
     * @param {FocusMode} focusOptionsMode 
     * @param {number} [focusOptionsFocusX] 
     * @param {number} [focusOptionsFocusY] 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    create(assetId: string, assetName: string, assetType: string, focusOptionsMode: FocusMode, focusOptionsFocusX?: number, focusOptionsFocusY?: number, file?: any, options?: any): AxiosPromise<ApiResponseOfAssetResponse>;

    /**
     * Retrieves the asset by filePath in the **Vix CMS** application.
     * @param {string} filePath 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    getByFilePath(filePath: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfAssetResponse>;

    /**
     * Retrieves the image of a video asset in the **Vix CMS** application.
     * @param {string} assetId 
     * @param {boolean} includeDeleted 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    getById(assetId: string, includeDeleted: boolean, options?: any): AxiosPromise<ApiResponseOfAssetResponse>;

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [assetType] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    getPaginated(limit?: number, page?: number, assetType?: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfAssetResponse>;

    /**
     * 
     * @param {string} assetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    remove(assetId: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {string} assetId 
     * @param {AssetUpdateBody} assetUpdateBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    update(assetId: string, assetUpdateBody: AssetUpdateBody, options?: any): AxiosPromise<ApiResponseOfAssetResponse>;

}

/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
export class AssetsApi extends BaseAPI implements AssetsApiInterface {
    /**
     * Creates an image asset in the **Vix CMS** application.
     * @param {string} assetId Asset´s identifier
     * @param {string} assetName Asset´s name
     * @param {string} assetType Asset´s type
     * @param {FocusMode} focusOptionsMode 
     * @param {number} [focusOptionsFocusX] 
     * @param {number} [focusOptionsFocusY] 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public create(assetId: string, assetName: string, assetType: string, focusOptionsMode: FocusMode, focusOptionsFocusX?: number, focusOptionsFocusY?: number, file?: any, options?: any) {
        return AssetsApiFp(this.configuration).create(assetId, assetName, assetType, focusOptionsMode, focusOptionsFocusX, focusOptionsFocusY, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the asset by filePath in the **Vix CMS** application.
     * @param {string} filePath 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public getByFilePath(filePath: string, includeDeleted?: boolean, options?: any) {
        return AssetsApiFp(this.configuration).getByFilePath(filePath, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the image of a video asset in the **Vix CMS** application.
     * @param {string} assetId 
     * @param {boolean} includeDeleted 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public getById(assetId: string, includeDeleted: boolean, options?: any) {
        return AssetsApiFp(this.configuration).getById(assetId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [assetType] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public getPaginated(limit?: number, page?: number, assetType?: string, includeDeleted?: boolean, options?: any) {
        return AssetsApiFp(this.configuration).getPaginated(limit, page, assetType, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} assetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public remove(assetId: string, options?: any) {
        return AssetsApiFp(this.configuration).remove(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} assetId 
     * @param {AssetUpdateBody} assetUpdateBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public update(assetId: string, assetUpdateBody: AssetUpdateBody, options?: any) {
        return AssetsApiFp(this.configuration).update(assetId, assetUpdateBody, options).then((request) => request(this.axios, this.basePath));
    }
}
