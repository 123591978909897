/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { EntitlementType } from './entitlement-type';
import { LocalizedFieldBody } from './localized-field-body';
import { TargetPlatform } from './target-platform';
import { UiModuleExperimentGroup } from './ui-module-experiment-group';

/**
 * 
 * @export
 * @interface AccountCreationUiModuleBody
 */
export interface AccountCreationUiModuleBody {
    /**
     * 
     * @type {string}
     * @memberof AccountCreationUiModuleBody
     */
    moduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationUiModuleBody
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationUiModuleBody
     */
    moduleType: AccountCreationUiModuleBodyModuleTypeEnum;
    /**
     * 
     * @type {Array<TargetPlatform>}
     * @memberof AccountCreationUiModuleBody
     */
    targetPlatform?: Array<TargetPlatform>;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof AccountCreationUiModuleBody
     */
    caption?: LocalizedFieldBody;
    /**
     * 
     * @type {Array<EntitlementType>}
     * @memberof AccountCreationUiModuleBody
     */
    entitlements?: Array<EntitlementType>;
    /**
     * 
     * @type {Array<UiModuleExperimentGroup>}
     * @memberof AccountCreationUiModuleBody
     */
    experiments?: Array<UiModuleExperimentGroup> | null;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationUiModuleBody
     */
    signInCta: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationUiModuleBody
     */
    signUpCta: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationUiModuleBody
     */
    header: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationUiModuleBody
     */
    subHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationUiModuleBody
     */
    emailText: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationUiModuleBody
     */
    passwordText: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationUiModuleBody
     */
    vixDisclaimer: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationUiModuleBody
     */
    partnerDisclaimer?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountCreationUiModuleBody
     */
    legalCheckbox?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationUiModuleBody
     */
    vixLogo: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationUiModuleBody
     */
    partnerLogo?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationUiModuleBody
     */
    signInTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationUiModuleBody
     */
    subHeaderSignIn?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationUiModuleBody
     */
    mainCtaSignIn?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationUiModuleBody
     */
    secondaryCtaSignIn?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationUiModuleBody
     */
    createAccountCtaSignIn?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AccountCreationUiModuleBodyModuleTypeEnum {
    ACCOUNT_CREATION = 'ACCOUNT_CREATION'
}



