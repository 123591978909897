/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { EntitlementType } from './entitlement-type';
import { LocalizedFieldBody } from './localized-field-body';
import { TargetPlatform } from './target-platform';
import { UiModuleExperimentGroup } from './ui-module-experiment-group';

/**
 * 
 * @export
 * @interface RegistrationWallUiModuleBody
 */
export interface RegistrationWallUiModuleBody {
    /**
     * 
     * @type {string}
     * @memberof RegistrationWallUiModuleBody
     */
    moduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationWallUiModuleBody
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationWallUiModuleBody
     */
    moduleType: RegistrationWallUiModuleBodyModuleTypeEnum;
    /**
     * 
     * @type {Array<TargetPlatform>}
     * @memberof RegistrationWallUiModuleBody
     */
    targetPlatform?: Array<TargetPlatform>;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof RegistrationWallUiModuleBody
     */
    caption?: LocalizedFieldBody;
    /**
     * 
     * @type {Array<EntitlementType>}
     * @memberof RegistrationWallUiModuleBody
     */
    entitlements?: Array<EntitlementType>;
    /**
     * 
     * @type {Array<UiModuleExperimentGroup>}
     * @memberof RegistrationWallUiModuleBody
     */
    experiments?: Array<UiModuleExperimentGroup> | null;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof RegistrationWallUiModuleBody
     */
    title: LocalizedFieldBody;
    /**
     * 
     * @type {string}
     * @memberof RegistrationWallUiModuleBody
     */
    landscapeImage?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationWallUiModuleBody
     */
    portraitImage?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationWallUiModuleBody
     */
    desktopImage?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationWallUiModuleBody
     */
    tabletImage?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationWallUiModuleBody
     */
    mobileImage?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationWallUiModuleBody
     */
    ctvImage?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationWallUiModuleBody
     */
    header: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationWallUiModuleBody
     */
    valuePropositionsList?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationWallUiModuleBody
     */
    registerCta?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationWallUiModuleBody
     */
    signInCta?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationWallUiModuleBody
     */
    providerCta?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RegistrationWallUiModuleBodyModuleTypeEnum {
    REGISTRATION_WALL = 'REGISTRATION_WALL'
}



