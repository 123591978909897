import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Typography, List, Skeleton, alpha, Stack, InputAdornment } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  ArrowBack,
  DynamicFeed,
  Edit,
  FilterAlt,
  FilterList,
  Subscriptions,
  FileDownload,
  Search
} from '@mui/icons-material';
import { useLocales, useTextFilter, useTheme, useStatsig, useConfirm } from '../../../../hooks';
import ShadowScroller from '../../../shared/ShadowScroller';
import Localized from '../../../shared/Localized';
import {
  ContentCollectionResponse,
  ContentCollectionSortingType,
  DocumentLocale,
  VersionedDocumentStatus,
  SmartQueryBody,
  CollectionAsset
} from '../../../../API';
import Media from '../Media';
import { Sortable, SortableGroups } from '../../../shared/Sortable';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { DisplayAs } from '../../DisplayAs/DisplayAs';
import HistoryManager, { testIds as historyManagerTestIds } from '../../../shared/HistoryManager';
import IconButton from '../../../shared/IconButton';
import NoMediaItems from '../../../shared/NoMediaItems';
import RevisionManager from '../../../shared/RevisionManager';
import Repeat from '../../../shared/Repeat';
import DraftBadge from '../../../shared/DraftBadge';
import { getMediaAspectRatio, getMediaGridTemplateColumns } from '../../../../utils/styleUtils';
import { useData } from '../../../../data-layer';
import { CollectionsPanelTabViewOptions } from '../../../../data-layer';
import CountryPickerLite from '../../../shared/CountryPickerLite';
import { difference, intersection } from 'lodash-es';
import CollectionExpand from '../../CollectionExpand';
import { collectionsHeaderHeight } from '../../../../utils/consts/collectionsConsts';
import { EntitlementsVisibilityBadge } from '../../../shared/Entitlements';
import { VerticalAccordion } from '../../../shared/VerticalAccordion';
import GenreBadge from '../../../shared/GenreBadge';
import { EditorNotes } from '../../../shared/EditorNotes';
import { UserBadge } from '../../../shared/UserBadge';
import Button from '../../../shared/Button';
import { PermissionsGroupBadge } from '../../../shared/PermissionsGroupBadge';
import { usePermissions } from '../../../../hooks/Permissions/usePermissions';
import { DisplayAsOptions } from '../../../../utils/types/genericTypes';
import { DeletedBadge } from '../../../shared/DeletedBadge';
import { isEntityDeleted } from '../../../../utils/generalUtils';
import TextField from '../../../shared/TextField';
import { AutoRotateBadge } from './AutoRotateBadge';
import { StatsigGates } from '../../../../utils/consts/statsigGates';
import { autoRotateGetCurrentIndex, ensurePinnedElementsAtTheBeginning } from '../../../../utils/autoRotate';
import AddAllToCuratedButton, { addAllToCuratedTestIds } from './AddAllToCuratedButton';
import { KidsBadge } from './KidsBadge';
import { withHistoryManager } from '../../../../state/General/withHistoryManager';
import { usePermissionsGuard } from '../../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../../state/theme';
import { getAssetTypeByTab } from '../../../../utils/mediaUtilities';

export const HISTORY_MANAGER_ID = 'collection';

const useStyles = makeStyles<{ displayAs: DisplayAsOptions }>()((theme, props) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  topSection: {
    minHeight: collectionsHeaderHeight,
    paddingRight: theme.spacing(4),
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  collectionTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(0, 3, 0, 1),
    flexGrow: 1,
    minWidth: 0
  },
  collectionTitle: {
    overflowX: 'hidden',
    textOverflow: 'ellipsis'
  },
  historyManagerContainer: {
    display: 'flex'
  },
  searchBarContainer: {
    maxWidth: 300,
    flexGrow: 1
  },
  textFilter: {
    '&.MuiTextField-root': {
      marginBottom: '0 !important'
    }
  },
  countryPicker: {
    flexGrow: 1,
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  collectionInfo: {
    margin: theme.spacing(4, 4, 1),
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2)
  },
  collectionDescription: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  contentsTopBar: {
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(4)
  },
  displayAsContainer: {
    marginLeft: theme.spacing(4)
  },
  listContainer: {
    minHeight: '100%',
    height: '100%',
    overflowX: 'hidden'
  },
  contentGrid: {
    display: 'grid',
    minHeight: '100%',
    overflow: 'hidden',
    padding: theme.spacing(0, 2),
    gridGap: theme.spacing(2),
    gridTemplateColumns: getMediaGridTemplateColumns(props.displayAs),
    gridTemplateRows: 'repeat(auto-fit, minmax(0, max-content))'
  },
  skeletonList: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(2)
  },
  emptyMedia: {
    position: 'absolute',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(12),
    inset: 0,
    pointerEvents: 'none'
  },
  skeletonMedia: {
    minHeight: 40,
    aspectRatio: getMediaAspectRatio(props.displayAs)
  },
  contentSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: alpha(theme.palette.text.primary, 0.6),
    gap: theme.spacing(2)
  },
  contentSubtitle: {
    padding: theme.spacing(0, 4),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  collectionInfoRightSide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: theme.spacing(1),
    minWidth: 300
  }
}));

export const testIds = {
  root: 'collection-manager.root',
  backButton: 'collection-manager.back-button',
  editButton: 'collection-manager.edit-button',
  editCuratedButton: 'collection-manager.edit-curated-button',
  editQueryButton: 'collection-manager.edit-query-button',
  saveButton: historyManagerTestIds.saveButton,
  publishButton: historyManagerTestIds.publishButton,
  addAllButton: addAllToCuratedTestIds.addAllButton,
  title: 'collection-manager.title',
  description: 'collection-manager.description',
  curatedItemsCount: 'collection-manager.curated-items-count',
  curatedItems: 'collection-manager.curated-items',
  queryItemsCount: 'collection-manager.query-items-count',
  queryItems: 'collection-manager.query-items',
  draftBadge: 'collection-manager.draft-badge',
  countryPicker: 'collection-manager.country-picker',
  textFilter: 'collection-manager.text-filter',
  exportContent: 'collection-manager.export-content',
  associatedLayoutsButton: 'collection-manager.associated-layouts-button'
};

interface CollectionManagerProps {
  useSelected?: boolean;
  onGoBack: () => void;
  collection: ContentCollectionResponse | undefined;
}

function CollectionManager({ collection, onGoBack, useSelected = true }: CollectionManagerProps): JSX.Element {
  const { canDelete, canPublish, canSave } = usePermissionsGuard({
    homepageOption: HomepageOptions.COLLECTIONS
  });
  const {
    collections: {
      state: {
        withSelected,
        withDisplayAs,
        withCollectionsPanelTabView,
        withSelectedCollectionContent,
        withCollectionCountryFilter,
        withCollectionsExpanded,
        withCollectionQuery,
        withAssociatedLayoutsCollection,
        withSelectedCollectionAsset,
        withMixedCollectionDisplay
      },
      hook: { edit, update, publish, getRevisions, allLeafNodesValuesAreDefined, exportContentsCsv }
    },
    media: {
      state: { withDataCache },
      hook: { getMediaBySmartQuery }
    }
  } = useData();
  const { formControlColor } = useTheme();

  const { CheckGate } = useStatsig();

  const isPinningFeatGateActive = CheckGate(StatsigGates.pinCollectionContentsActive);

  const expanded = useRecoilValue(withCollectionsExpanded);
  const displayAs = useRecoilValue(withDisplayAs);
  const { classes } = useStyles({ displayAs });
  const setSelectedCollection = useSetRecoilState(withSelected);
  const [selectedCollectionContent, setSelectedCollectionContent] = useRecoilState(withSelectedCollectionContent);
  const [selectedCollectionAssets, setSelectedCollectionAssets] = useRecoilState(withSelectedCollectionAsset);
  const asMixedType = useRecoilValue(withMixedCollectionDisplay);
  const mediaCache = useRecoilValue(withDataCache);

  const { t } = useLocales();
  const [mediaList, setMediaList] = useState<string[]>(useSelected && collection ? collection.contents : []);
  const [queryMediaList, setQueryMediaList] = useState<string[]>([]);
  const selectedCollectionQuery = useRecoilValue(withCollectionQuery(collection?.entityId));
  const [collectionsPanelTabView, setCollectionsPanelTabView] = useRecoilState(withCollectionsPanelTabView);
  const [mediaQueryIsLoading, setMediaQueryIsLoading] = useState(false);
  const [countriesFilter, setCountriesFilter] = useRecoilState(withCollectionCountryFilter);
  const setAssociatedLayoutsCollection = useSetRecoilState(withAssociatedLayoutsCollection);
  const [enablePinning, setEnablePinning] = useState(false);

  const { isClean: isHistoryManagerClean } = useRecoilValue(withHistoryManager);
  const { confirmUnsavedChanges } = useConfirm();

  const isCuratedFirst = collection ? collection.sortingType === ContentCollectionSortingType.CURATED_FIRST : true;
  const [selectedViewIndex, setSelectedViewIndex] = useState(reverseIfSmartQueryFirst([0, 1])[collectionsPanelTabView]);
  const { hasPermissions } = usePermissions();
  const { UPSERT: hasUpsertPermission, PUBLISH: hasPublishPermission } = hasPermissions(
    collection?.ownerPermissionsGroup
  );

  const hasSmartQuery = !asMixedType && collection && collection.query;
  const searchRef = useRef<HTMLInputElement>();
  const { onFilterInputChange, clearFilter, filterRegExp } = useTextFilter([]);
  const hasFilterApplied = !!countriesFilter.length || !!filterRegExp;

  useEffect(() => {
    if (!hasSmartQuery) {
      return;
    }

    if (selectedCollectionQuery) {
      if (!allLeafNodesValuesAreDefined(selectedCollectionQuery)) {
        return;
      }
      fetchAndUpdateQueryMediaList(selectedCollectionQuery);
    } else {
      setQueryMediaList([]);
      if (collection?.query) {
        setMediaQueryIsLoading(false);
      }
    }
  }, [selectedCollectionQuery]);

  useEffect(() => {
    if (useSelected) {
      const whichView = reverseIfSmartQueryFirst([
        CollectionsPanelTabViewOptions.CONTENT_SEARCH,
        CollectionsPanelTabViewOptions.QUERY_BUILDER
      ])[selectedViewIndex] as CollectionsPanelTabViewOptions;

      if (whichView && whichView !== collectionsPanelTabView) {
        setTimeout(() => {
          setCollectionsPanelTabView(whichView);
        });
      }
    }
  }, [selectedViewIndex]);

  useEffect(() => {
    if (!collection || collectionsPanelTabView === CollectionsPanelTabViewOptions.BROWSE_COLLECTONS) return;
    setSelectedIndexFromTabView();
  }, [collectionsPanelTabView]);

  useEffect(() => {
    if (!collection) return;
    setMediaList(collection.contents);
    setSelectedIndexFromTabView();
    setEnablePinning(!!collection?.autoRotate && !!isPinningFeatGateActive);
  }, [collection]);

  const checkInCollection = useCallback(
    (id: string) => {
      return !!selectedCollectionContent?.find((collection) => collection === id);
    },
    [selectedCollectionContent]
  );

  const setSelectedIndexFromTabView = () => {
    if (useSelected) {
      const whichViewIndex = reverseIfSmartQueryFirst([0, 1])[
        collectionsPanelTabView === CollectionsPanelTabViewOptions.QUERY_BUILDER ? 1 : 0
      ];
      if (selectedViewIndex !== whichViewIndex) {
        setSelectedViewIndex(whichViewIndex);
      }
    }
  };

  // change to collectionId
  const handleOnSort = (all_media: string[]) => {
    const media = [...new Set(all_media)];
    let updatedMedia = media;
    let updatedAsset = selectedCollectionAssets || [];

    if (asMixedType) {
      if (updatedMedia.length !== mediaList.length) {
        const contentId = difference(updatedMedia, mediaList)[0];
        const indexToBePositioned = updatedMedia.findIndex((mediaId) => mediaId === contentId);
        const asset = { assetId: contentId, assetType: getAssetTypeByTab(collectionsPanelTabView) };

        updatedAsset = [
          ...updatedAsset.slice(0, indexToBePositioned),
          asset,
          ...updatedAsset.slice(indexToBePositioned)
        ] as CollectionAsset[];
      } else {
        const newAssetsSorted = [...updatedAsset];
        updatedAsset = newAssetsSorted.sort(
          (a, b) => updatedMedia.indexOf(a.assetId) - updatedMedia.indexOf(b.assetId)
        );
      }
    }

    if (enablePinning && collection?.pinnedContents?.length) {
      updatedMedia = ensurePinnedElementsAtTheBeginning(collection.pinnedContents, media) as string[];
      if (asMixedType) {
        updatedAsset = ensurePinnedElementsAtTheBeginning(collection.pinnedContents, updatedAsset) as CollectionAsset[];
      }
    }

    setMediaList(updatedMedia);

    if (useSelected) {
      setSelectedCollectionContent(updatedMedia);
      if (asMixedType) {
        setSelectedCollectionAssets(updatedAsset);
      }
    }
  };

  // gets media objects for selected smart query content and sets in the queryMediaList
  const fetchAndUpdateQueryMediaList = async (query: SmartQueryBody) => {
    setMediaQueryIsLoading(true);
    const media = await getMediaBySmartQuery(query);
    if (media) setQueryMediaList(media.map((m) => m.contentId));
    setMediaQueryIsLoading(false);
  };

  const filterMediaList = (inMediaList: string[], ensurePinnedElementsGoFirst = false) => {
    let newMediaList: string[] = [];

    if (!hasFilterApplied || !inMediaList.length) {
      newMediaList = inMediaList;
    } else {
      newMediaList = inMediaList.filter((contentId: string) => {
        const media = mediaCache[contentId]?.object;
        if (!media) return true;
        if (filterRegExp) {
          if (
            (media.title[DocumentLocale.ES] && !filterRegExp.test(media.title[DocumentLocale.ES] as string)) ||
            (media.title[DocumentLocale.EN] && !filterRegExp.test(media.title[DocumentLocale.EN] as string))
          ) {
            return false;
          }
        }
        if (countriesFilter.length && media.usagePolicy) {
          // an issue with the type not allowing for "default" and trying to use "_default"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const policyType = (media.usagePolicy as any).default;
          const isFound = intersection(media.usagePolicy.exceptionCountryCodes, countriesFilter).length > 0;
          return policyType === 'BLOCK' ? isFound : !isFound;
        }
        return true;
      });
    }

    return ensurePinnedElementsGoFirst && enablePinning
      ? ensurePinnedElementsAtTheBeginning(collection?.pinnedContents, newMediaList)
      : newMediaList;
  };

  const handleEdit = async () => {
    const shallContinue = isHistoryManagerClean || (await confirmUnsavedChanges());
    if (shallContinue && collection) {
      edit(collection.entityId);
    }
  };

  const handleSave = async () => {
    if (collection) {
      await update(collection.entityId, collection);
    }
  };

  const handlePublish = async () => {
    if (collection) {
      await publish(collection.entityId);
    }
  };

  const handleExportContent = async () => {
    if (collection) {
      await exportContentsCsv(collection.entityId);
    }
  };

  const getRevisionsPromise = useCallback(
    async (limit?: number, page?: number) => {
      if (collection) {
        return getRevisions(collection.entityId, limit, page);
      }
    },
    [collection, getRevisions]
  );

  function reverseIfSmartQueryFirst<T>(array: Array<T>): Array<T> {
    if (!isCuratedFirst) {
      return array.slice().reverse();
    }
    return array;
  }

  function removeSmartQueryContentIfMixed<T>(array: Array<T>): Array<T> {
    if (asMixedType) {
      return array.slice(0, -1);
    }
    return array;
  }

  function getSmartQueryResultsText(): React.ReactElement | string {
    if (!hasSmartQuery) {
      return t('collections.no_smart_query_configured');
    }
    if (mediaQueryIsLoading) {
      return <Skeleton animation="wave" width={200} height={18} />;
    }
    if (hasFilterApplied) {
      return t('collections.n_filtered_items_from_smart_query_n_not_shown', {
        f: filteredQueryMediaList.length,
        n: queryMediaList.length - filteredQueryMediaList.length
      });
    }

    return t('collections.n_items_from_smart_query', { n: queryMediaList.length });
  }

  function showAssociatedLayoutsModal() {
    if (collection) {
      setAssociatedLayoutsCollection(collection);
    }
  }

  const isDeleted = isEntityDeleted(collection);
  const isLoading = !collection;

  const filteredMediaList = filterMediaList(mediaList, true);
  const filteredQueryMediaList = filterMediaList(queryMediaList);

  const autoRotateCurrentIndex = autoRotateGetCurrentIndex(collection);
  const autoRotateIndexMediaHash: Record<string, number> = {};
  if (autoRotateCurrentIndex > -1) {
    for (let index = 0; index < mediaList.length; index++) {
      const position = index === 0 ? autoRotateCurrentIndex : (autoRotateCurrentIndex + index) % mediaList.length;

      autoRotateIndexMediaHash[mediaList[position]] = index + 1;
    }
  }

  return (
    <div data-testid={testIds.root} className={classes.root}>
      <div className={classes.topSection}>
        <IconButton data-testid={testIds.backButton} onClick={onGoBack} size="large">
          <ArrowBack />
        </IconButton>
        <div className={classes.collectionTitleContainer}>
          {!isLoading && (
            <Typography className={classes.collectionTitle} data-testid={testIds.title} variant="h6">
              <Stack direction="row" alignItems="center" gap={2}>
                {isDeleted && <DeletedBadge />}
                <Localized prop={collection.title} />
              </Stack>
            </Typography>
          )}
          {isLoading && <Skeleton animation="wave" sx={{ width: 200, height: 20, margin: 1 }} />}
          {collection && collection.status === VersionedDocumentStatus.DRAFT && (
            <DraftBadge sx={{ marginLeft: 2 }} data-testid={testIds.draftBadge} />
          )}
          {useSelected && (
            <IconButton
              disabled={isLoading || isDeleted}
              data-testid={testIds.editButton}
              onClick={handleEdit}
              title={t('collections.edit_collection_metadata')}
              size="large"
            >
              <Edit />
            </IconButton>
          )}
          {useSelected && (
            <RevisionManager
              getRevisionsPromise={getRevisionsPromise}
              state={collection}
              onChange={setSelectedCollection}
            />
          )}
        </div>
        {useSelected && <CollectionExpand />}
        {expanded && (
          <div className={classes.displayAsContainer}>
            <DisplayAs state={withDisplayAs} />
          </div>
        )}
      </div>
      <div className={classes.collectionInfo}>
        <div>
          <div className={classes.collectionDescription}>
            {collection ? (
              <>
                <Localized prop={collection.description} data-testid={testIds.description} />
                {collection.genre && <GenreBadge label={collection.genre.key} forSimilars={collection.forSimilars} />}
                <UserBadge record={collection} useEntityCreatedDate />
                {collection.ownerPermissionsGroup && <PermissionsGroupBadge value={collection.ownerPermissionsGroup} />}
                {collection?.entitlements && (
                  <EntitlementsVisibilityBadge size="small" entitlements={collection.entitlements} />
                )}
                {collection.kids && <KidsBadge collection={collection} />}
              </>
            ) : (
              <Skeleton sx={{ width: 250 }} animation="wave" />
            )}
          </div>
          <div>
            <EditorNotes object={collection} />
          </div>
        </div>
        <div className={classes.collectionInfoRightSide}>
          <div>
            <Button
              variant="text"
              color={isLoading || isDeleted ? 'grey' : formControlColor}
              size="small"
              startIcon={<DynamicFeed />}
              onClick={showAssociatedLayoutsModal}
              data-testid={testIds.associatedLayoutsButton}
              disabled={isLoading || isDeleted}
            >
              {t('collections.view_collection_layouts')}
            </Button>
          </div>
          <CountryPickerLite
            data-testid={testIds.countryPicker}
            className={classes.countryPicker}
            label={t('country_picker.filter_by_countries')}
            value={countriesFilter}
            onChange={setCountriesFilter}
          />
        </div>
      </div>
      <div className={classes.contentsTopBar}>
        <div className={classes.historyManagerContainer}>
          {useSelected && !isDeleted && (
            <HistoryManager
              id={HISTORY_MANAGER_ID}
              state={collection}
              onChange={setSelectedCollection}
              saveCallback={handleSave}
              publishCallback={handlePublish}
              hasUpsertPermission={canSave && hasUpsertPermission}
              hasPublishPermission={canPublish && hasPublishPermission}
            />
          )}
        </div>
        <div className={classes.searchBarContainer}>
          <TextField
            debounced
            clearable
            fullWidth
            inputRef={searchRef}
            placeholder={t('general.filter_by_text')}
            className={classes.textFilter}
            onChange={onFilterInputChange}
            onClear={clearFilter}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            data-testid={testIds.textFilter}
          />
        </div>
      </div>
      <VerticalAccordion
        selectedIndex={selectedViewIndex}
        setSelectedIndex={setSelectedViewIndex}
        sections={reverseIfSmartQueryFirst(
          removeSmartQueryContentIfMixed([
            {
              header: (
                <Typography
                  variant="subtitle1"
                  className={classes.contentSubtitle}
                  data-testid={testIds.editCuratedButton}
                >
                  <Stack direction="row" alignItems="center" gap={4}>
                    {t('collections.curated_content')}
                    {collection && (
                      <AutoRotateBadge collection={collection} onCollectionChange={setSelectedCollection} />
                    )}
                  </Stack>
                  <div className={classes.contentSummary}>
                    {!!mediaList.length && (
                      <IconButton
                        disabled={isLoading}
                        data-testid={testIds.exportContent}
                        onClick={handleExportContent}
                        title={t('collections.export_csv')}
                        size="small"
                      >
                        <FileDownload />
                      </IconButton>
                    )}
                    <Subscriptions fontSize="small" />
                    <span data-testid={testIds.curatedItemsCount}>
                      {hasFilterApplied
                        ? t('collections.n_filtered_items_n_not_shown', {
                            f: filteredMediaList.length,
                            n: mediaList.length - filteredMediaList.length
                          })
                        : t('collections.n_curated_items', { n: (Array.isArray(mediaList) && mediaList.length) || 0 })}
                    </span>
                  </div>
                </Typography>
              ),
              body: (
                <ShadowScroller loading={isLoading} data-testid={testIds.curatedItems}>
                  <List className={classes.listContainer} disablePadding={displayAs === 'list'}>
                    {Array.isArray(mediaList) && mediaList.length === 0 && !isDeleted && (
                      <div className={classes.emptyMedia}>
                        <NoMediaItems
                          header={t('general.no_media_items.header')}
                          message={t('general.no_media_items.message')}
                        />
                      </div>
                    )}
                    <Sortable
                      className={displayAs !== 'list' ? classes.contentGrid : undefined}
                      group={{
                        name: SortableGroups.collections,
                        pull: 'clone',
                        put: useSelected && hasUpsertPermission && canSave
                      }}
                      list={mediaList}
                      setList={handleOnSort}
                      animation={200}
                      sort={useSelected}
                      ghostClass="sortableGhost"
                      filter=".sortableFiltered"
                      isStringList
                      disabled={!canSave || !hasUpsertPermission || isDeleted}
                    >
                      {(filteredMediaList as string[]).map((contentId: string) => (
                        <Media
                          key={contentId}
                          mediaId={contentId}
                          isEditing={useSelected}
                          inCollection={checkInCollection(contentId)}
                          canAddDelete={canDelete && canSave && hasUpsertPermission}
                          disablePortal
                          enablePinning={enablePinning}
                          autoRotateIndex={autoRotateIndexMediaHash[contentId]}
                        />
                      ))}
                    </Sortable>
                  </List>
                </ShadowScroller>
              )
            },
            {
              header: (
                <Typography
                  variant="subtitle1"
                  className={classes.contentSubtitle}
                  data-testid={testIds.editQueryButton}
                >
                  <Stack direction="row" alignItems="center" gap={4}>
                    {t('collections.smart_query_content')}
                    <AddAllToCuratedButton
                      filteredQueryMediaList={filteredQueryMediaList as string[]}
                      filteredMediaList={filteredMediaList as string[]}
                      hasUpsertPermission={canSave && hasUpsertPermission}
                    />
                  </Stack>
                  <div className={classes.contentSummary}>
                    <FilterAlt fontSize="small" />
                    <span data-testid={testIds.queryItemsCount}>{getSmartQueryResultsText()}</span>
                  </div>
                </Typography>
              ),
              body: (
                <ShadowScroller loading={mediaQueryIsLoading} data-testid={testIds.queryItems}>
                  <List className={classes.listContainer} disablePadding={displayAs === 'list'}>
                    {((!mediaQueryIsLoading && Array.isArray(queryMediaList) && queryMediaList.length === 0) ||
                      !selectedCollectionQuery) &&
                      !isDeleted && (
                        <div className={classes.emptyMedia}>
                          <NoMediaItems
                            icon={<FilterList />}
                            header={t('general.no_media_items.header')}
                            message={t('general.no_media_items.query_message')}
                          />
                        </div>
                      )}
                    {selectedCollectionQuery && mediaQueryIsLoading && (
                      <div
                        data-display-as={displayAs}
                        className={displayAs !== 'list' ? classes.contentGrid : classes.skeletonList}
                      >
                        <Repeat n={30}>
                          <Skeleton className={classes.skeletonMedia} animation="wave" />
                        </Repeat>
                      </div>
                    )}
                    {selectedCollectionQuery && !mediaQueryIsLoading && (
                      <Sortable
                        className={displayAs !== 'list' ? classes.contentGrid : undefined}
                        group={{ name: SortableGroups.collections, pull: 'clone' }}
                        list={filteredQueryMediaList}
                        setList={handleOnSort}
                        animation={200}
                        sort={true}
                        disabled={useSelected}
                        ghostClass="sortableGhost"
                        filter=".sortableFiltered"
                        isStringList
                      >
                        {(filteredQueryMediaList as string[]).map((contentId) => (
                          <Media
                            key={contentId}
                            mediaId={contentId}
                            isEditing={useSelected}
                            inCollection={checkInCollection(contentId)}
                            canAddDelete={canSave && canDelete && hasUpsertPermission}
                            disableSorting
                            disablePortal
                            isSmartQuery
                          />
                        ))}
                      </Sortable>
                    )}
                  </List>
                </ShadowScroller>
              )
            }
          ])
        )}
      />
    </div>
  );
}

export default CollectionManager;
