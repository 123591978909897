/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum UiModulePageListTreatment {
    VERTICAL_LIST = 'VERTICAL_LIST',
    HORIZONTAL_BUTTONS = 'HORIZONTAL_BUTTONS',
    HORIZONTAL_LOGO_LIST = 'HORIZONTAL_LOGO_LIST',
    CAROUSELS_LIST = 'CAROUSELS_LIST'
}



