import { withStyles } from 'tss-react/mui';
import PremiumBadge from '../PremiumBadge';

export const StyledPremiumBadge = withStyles(PremiumBadge, {
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: '0 0 0 12px'
  }
});
