import React, { useEffect, useState } from 'react';
import { useLocales } from '../../../../hooks';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Button from '../../../shared/Button';
import Drawer from '../../../shared/Drawer';
import ContentSearch from '../../../Collections/CollectionsPanel/ContentSearch';
import DisplayAs from '../../../Collections/DisplayAs';
import Localized from '../../../shared/Localized';
import { useData } from '../../../../data-layer';
import { MediaResponse } from '../../../../API';
import { ViewContentButton } from '../../../shared/ViewContentButon';

const useStyles = makeStyles()((theme) => ({
  contentPreview: {
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    width: '100%'
  },
  contentPreviewError: {
    borderColor: theme.palette.error.main
  },
  footerButton: {
    minWidth: 120,
    marginRight: theme.spacing(4)
  }
}));

export const testIds = {
  root: 'content-picker.root',
  title: 'content-picker.title',
  noMedia: 'content-picker.no-media',
  closeDrawerButton: 'content-picker.close-drawer-button'
};

interface IContentPickerProps {
  value: string;
  onChange: (value: string) => void;
  error?: boolean;
}

function ContentPicker({ value, onChange, error }: IContentPickerProps): JSX.Element {
  const {
    collections: {
      state: { withDisplayAs }
    },
    media: {
      hook: { getById }
    }
  } = useData();
  const { cx, classes } = useStyles();
  const { t } = useLocales();
  const [media, setMedia] = useState<MediaResponse>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (value) {
      fetchMedia(value);
    }
  }, [value]);

  const fetchMedia = async (contentId: string) => {
    const mediaResponse = await getById(contentId);
    if (!mediaResponse) return;
    setMedia(mediaResponse);
  };

  const onContentPicked = (contentId: string) => {
    onChange(contentId);
    closeDrawer();
  };

  const openDrawer = () => {
    setIsOpen(true);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Stack direction="row" gap={2} alignItems="center">
        <div
          data-testid={testIds.root}
          className={cx(classes.contentPreview, { [classes.contentPreviewError]: error })}
          onClick={openDrawer}
        >
          {media && <Localized data-testid={testIds.title} prop={media.title} />}
          {!media && (
            <Typography data-testid={testIds.noMedia} color={error ? 'error' : 'textSecondary'}>
              {t('layouts.select_media')}
            </Typography>
          )}
        </div>
        <ViewContentButton contentId={value} size="small" />
      </Stack>
      <Drawer
        open={isOpen}
        onClose={closeDrawer}
        headerLeft={<Typography variant="h6">{t('layouts.content_search')}</Typography>}
        headerRight={<DisplayAs state={withDisplayAs} />}
        footerRight={
          <Button
            color="grey"
            className={classes.footerButton}
            onClick={closeDrawer}
            data-testid={testIds.closeDrawerButton}
          >
            {t('general.cancel')}
          </Button>
        }
      >
        <ContentSearch isContentPicker onContentPicked={onContentPicked} isForLayout />
      </Drawer>
    </>
  );
}

export default ContentPicker;
