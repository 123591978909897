import {
  BecauseYouSource,
  BecauseYouType,
  BecauseYouUiModuleBody,
  BecauseYouUiModuleBodyModuleTypeEnum,
  BecauseYouUIModuleContentTreatment,
  DocumentLocale
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankBecauseYouModule: NewModuleFunction<BecauseYouUiModuleBody> = (module) => ({
  title: {
    [DocumentLocale.ES]: ''
  },
  moduleType: BecauseYouUiModuleBodyModuleTypeEnum.BECAUSE_YOU,
  contentTreatment: BecauseYouUIModuleContentTreatment.PORTRAIT,
  type: BecauseYouType.WATCHED,
  source: BecauseYouSource.SMART_START,
  textTitle: '',
  moduleId: generateId(),
  ...module
});
