import React from 'react';
import Clamp from 'react-multiline-clamp';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useLocales, useTheme } from '../../../hooks';

const useStyles = makeStyles()(() => ({
  toggleText: {
    cursor: 'pointer'
  }
}));

export interface ITextClampProps {
  lines?: number;
  showMoreText?: string;
  showLessText?: string;
  hideShowMore?: boolean;
  children: React.ReactNode;
}

const TextClamp = (props: ITextClampProps): JSX.Element => {
  const { classes } = useStyles();
  const { formControlColor } = useTheme();
  const { t } = useLocales();
  const { lines, children, showMoreText, showLessText } = props;

  return (
    <Clamp
      lines={lines}
      maxLines={1000}
      withToggle={true}
      showMoreElement={
        props.hideShowMore
          ? () => <></>
          : (input: { toggle: () => void }) => (
              <Typography
                className={classes.toggleText}
                variant="body2"
                color={formControlColor}
                onClick={input.toggle}
              >
                {showMoreText || t('general.show_more')}
              </Typography>
            )
      }
      showLessElement={(input: { toggle: () => void }) => (
        <Typography className={classes.toggleText} variant="body2" color={formControlColor} onClick={input.toggle}>
          {showLessText || t('general.show_less')}
        </Typography>
      )}
    >
      {children}
    </Clamp>
  );
};

export default TextClamp;
