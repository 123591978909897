import React, { forwardRef } from 'react';
import {
  TdsGiftCardsUiModuleBody,
  TdsGiftCardsUiModuleCodeHintBody,
  SharedModalBody,
  TdsGiftCardsUiModuleTopUpDoneBody,
  BackgroundBody
} from '../../../../../API';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useLocales, useTheme } from '../../../../../hooks';
import { FormBody, FormField, SectionHeader } from '../../styles';
import { Box, Button, FormControlLabel, IconButton, Stack, Switch } from '@mui/material';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { LocaleKeys } from '../../../../../locales/i18n';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { markAsRequired } from '../../../../../utils/formHelpers';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { Sortable } from '../../../../shared/Sortable';
import { Add, Delete } from '@mui/icons-material';
import generateId from '../../../../../utils/generateId';

type TdsGiftCardsProps = keyof TdsGiftCardsUiModuleBody;
type TdsGiftCardsCheckboxProps = keyof Pick<
  TdsGiftCardsUiModuleBody,
  'stepOneCheckbox' | 'stepThreeACheckbox' | 'stepThreeBCheckboxTopUp' | 'stepThreeCCheckboxDtc' | 'stepFourCheckbox'
>;
type TdsGiftCardsErrorsProps = Pick<
  TdsGiftCardsUiModuleBody,
  'notSupportedPaymentMethodError' | 'unableToRedeemError' | 'usedAccountError'
>;

interface BackgroundFieldProps {
  prop: 'stepOneBackground' | 'stepFourBackground';
  name: keyof BackgroundBody;
  required?: boolean;
  'data-testid'?: string;
}

export const testIds = {
  formBody: 'tds-gift-cards.form-body',
  formField: (
    name:
      | keyof TdsGiftCardsUiModuleBody
      | keyof TdsGiftCardsUiModuleCodeHintBody
      | keyof SharedModalBody
      | keyof TdsGiftCardsUiModuleTopUpDoneBody
      | keyof BackgroundBody
  ): string => `tds-gift-cards.form-field.${name}`,
  codeHint: 'tds-gift-cards.codeHint',
  codeHintAddButton: 'tds-gift-cards.codeHint-add-button',
  codeHintRemoveButton: 'tds-gift-cards.codeHint-remove-button',
  error: 'tds-gift-cards.error',
  errorAddButton: 'tds-gift-cards.error-add-button',
  errorRemoveButton: 'tds-gift-cards.error-remove-button'
};

const TdsGiftCardsForm = forwardRef<UIModuleTypeForm<TdsGiftCardsUiModuleBody>>(() => {
  const { control, watch } = useFormContext<TdsGiftCardsUiModuleBody>();

  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const {
    append: appendCodeHints,
    remove: removeCodeHints,
    fields: fieldsCodeHints,
    replace: replaceCodeHintsMethod
  } = useFieldArray({ control, name: 'stepTwoCodeHints' });

  const moduleId = watch('moduleId');

  const LokaliseField = (name: keyof TdsGiftCardsUiModuleBody, required = false): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.tds_gift_cards.fields.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  const LokaliseFieldCodeHints = (
    name: keyof TdsGiftCardsUiModuleCodeHintBody,
    position: number,
    required = false
  ): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={`stepTwoCodeHints.${position}.${name}`}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.tds_gift_cards.fields.stepTwoCodeHints.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  const LokaliseFieldTopUpDone = (
    parent: keyof Pick<TdsGiftCardsUiModuleBody, 'topUpDone'>,
    name: keyof TdsGiftCardsUiModuleTopUpDoneBody,
    required = false
  ): React.ReactElement => {
    return (
      <FormField>
        <Controller
          control={control}
          name={`${parent}.${name}`}
          render={({ field: { value, onChange } }) => (
            <LokaliseAutocomplete
              label={t(`layouts.tds_gift_cards.fields.topUpDone.${name}` as LocaleKeys)}
              name={`${parent}.${name}`}
              value={value as string}
              onChange={onChange}
              required={required}
              data-testid={testIds.formField(name)}
            />
          )}
        />
      </FormField>
    );
  };

  const LokaliseFieldError = (
    parent: keyof TdsGiftCardsErrorsProps,
    name: keyof SharedModalBody,
    required = false
  ): React.ReactElement => {
    return (
      <FormField>
        <Controller
          control={control}
          name={`${parent}.${name}`}
          render={({ field: { value, onChange } }) => (
            <LokaliseAutocomplete
              label={t(`layouts.tds_gift_cards.fields.errors.${name}` as LocaleKeys)}
              name={`${parent}.${name}`}
              value={value as string}
              onChange={onChange}
              required={required}
              data-testid={testIds.formField(name)}
            />
          )}
        />
      </FormField>
    );
  };

  const LokaliseFieldsErrors = (name: keyof TdsGiftCardsErrorsProps): React.ReactElement => (
    <>
      {LokaliseFieldError(name, 'header', true)}
      {LokaliseFieldError(name, 'body', true)}
      {LokaliseFieldError(name, 'fstCta', true)}
      {LokaliseFieldError(name, 'sndCta', true)}
    </>
  );

  const AssetPicker = ({
    name,
    label,
    assetType,
    required = false
  }: {
    name: TdsGiftCardsProps;
    label: string;
    assetType: AssetTypesEnum;
    required?: boolean;
  }): JSX.Element => {
    const isRequired = (label: string) => (required ? markAsRequired(label) : label);
    return (
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormField>
            <AssetBrowser
              label={isRequired(label)}
              assetId={moduleId || ''}
              assetType={assetType}
              value={value as string}
              data-testid={testIds.formField(name)}
              hasError={!!error}
              onChange={onChange}
            />
          </FormField>
        )}
      />
    );
  };

  const BackgroundAssetPicker = ({
    prop,
    name,
    assetType,
    required = false
  }: BackgroundFieldProps & { assetType: AssetTypesEnum }): React.ReactElement => (
    <Controller
      control={control}
      name={`${prop}.${name}`}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormField>
          <AssetBrowser
            required={required}
            label={t(`layouts.tds_gift_cards.fields.${prop}.${name}`)}
            assetId={moduleId || ''}
            assetType={assetType}
            value={value as string}
            data-testid={testIds.formField(name)}
            hasError={!!error}
            onChange={onChange}
          />
        </FormField>
      )}
    />
  );

  const CheckboxField = ({ name }: { name: TdsGiftCardsCheckboxProps }): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <FormControlLabel
            label={t(`layouts.tds_gift_cards.fields.${name}` as LocaleKeys)}
            control={
              <Switch
                color={formControlColor}
                checked={value}
                onChange={onChange}
                data-testid={testIds.formField(name)}
              />
            }
          />
        )}
      />
    </FormField>
  );

  const onAddCodeHints = () => {
    appendCodeHints({
      id: generateId(),
      codeHint: ''
    });
  };

  return (
    <FormBody data-testid={testIds.formBody}>
      <Box>
        <SectionHeader>{t('layouts.tds_gift_cards.headers.redemption_flow')}</SectionHeader>
        <AssetPicker
          name="logo"
          label={t('layouts.tds_gift_cards.fields.logo')}
          assetType={AssetTypes.tdsGiftCards.logo}
          required
        />
        <AssetPicker
          name="partnerLogo"
          label={t('layouts.tds_gift_cards.fields.partnerLogo')}
          assetType={AssetTypes.tdsGiftCards.partnerLogo}
        />
        <Controller
          control={control}
          name={'stepOneBackground.id'}
          defaultValue={generateId()}
          render={({ field: { value, onChange } }) => <input type="hidden" value={value} onChange={onChange} />}
        />
        <BackgroundAssetPicker
          prop="stepOneBackground"
          name="portraitFillImage"
          assetType={AssetTypes.tdsGiftCards.backgroundPortraitFillImage}
        />
        <BackgroundAssetPicker
          prop="stepOneBackground"
          name="landscapeFillImage"
          assetType={AssetTypes.tdsGiftCards.backgroundLandscapeFillImage}
        />
        <BackgroundAssetPicker
          prop="stepOneBackground"
          name="mobileFillImage"
          assetType={AssetTypes.tdsGiftCards.backgroundMobileFillImage}
        />
        <BackgroundAssetPicker
          prop="stepOneBackground"
          name="ctvFillImage"
          assetType={AssetTypes.tdsGiftCards.backgroundCtvFillImage}
        />
        <BackgroundAssetPicker
          prop="stepOneBackground"
          name="desktopFillImage"
          assetType={AssetTypes.tdsGiftCards.backgroundDesktopFillImage}
        />
        <BackgroundAssetPicker
          prop="stepOneBackground"
          name="tabletFillImage"
          assetType={AssetTypes.tdsGiftCards.backgroundTabletFillImage}
        />

        {LokaliseField('stepOneHeading', true)}
        {LokaliseField('stepOneSubheader')}
        {LokaliseField('stepOneEmail', true)}
        {LokaliseField('stepOnePassword', true)}
        {LokaliseField('stepOneLegalCopy')}
        {LokaliseField('stepOneCta', true)}
        {LokaliseField('stepOneSignIn', true)}
        <CheckboxField name="stepOneCheckbox" />
      </Box>
      <Box>
        <SectionHeader>{t('layouts.tds_gift_cards.headers.enter_code')}</SectionHeader>
        {LokaliseField('stepTwoHeading', true)}
        {LokaliseField('stepTwoSubheader')}
        {LokaliseField('stepTwoHowToHelper')}
        <AssetPicker
          name="stepTwoHowToImage"
          label={t('layouts.tds_gift_cards.fields.stepTwoHowToImage')}
          assetType={AssetTypes.tdsGiftCards.stepTwoHowToImage}
        />
        {LokaliseField('stepTwoHowToHeader')}
        {LokaliseField('stepTwoHowToSteps')}
        {LokaliseField('stepTwoHowToCtaOne')}
        {LokaliseField('stepTwoHowToCtaTwo')}
        {LokaliseField('stepTwoCta', true)}
        <div>
          <Sortable
            list={fieldsCodeHints}
            setList={replaceCodeHintsMethod}
            ghostClass="sortableGhost"
            dragClass="sortableDragDefault"
          >
            {fieldsCodeHints.map((suggestion, i) => (
              <Stack
                key={suggestion.id}
                data-testid={testIds.codeHint}
                gap={6}
                marginBottom={5}
                direction="row"
                alignItems="center"
              >
                {LokaliseFieldCodeHints('codeHint', i)}
                <IconButton
                  title={t('layouts.tds_gift_cards.actions.remove_code_hints')}
                  disabled={fieldsCodeHints.length === 1}
                  onClick={() => removeCodeHints(i)}
                  sx={{ mt: 4 }}
                  data-testid={testIds.codeHintRemoveButton}
                >
                  <Delete />
                </IconButton>
              </Stack>
            ))}
          </Sortable>
          <Button
            startIcon={<Add />}
            color={formControlColor}
            variant="outlined"
            onClick={onAddCodeHints}
            data-testid={testIds.codeHintAddButton}
          >
            {t('layouts.tds_gift_cards.actions.add_code_hints')}
          </Button>
        </div>
      </Box>
      <Box>
        <SectionHeader>{t('layouts.tds_gift_cards.headers.preview_new_user')}</SectionHeader>
        {LokaliseField('stepThreeAHeading', true)}
        {LokaliseField('stepThreeASubheader')}
        {LokaliseField('stepThreeAPlanHeader')}
        {LokaliseField('stepThreeAPlanName')}
        {LokaliseField('stepThreeAPlanDescription')}
        {LokaliseField('stepThreeAPlanPrice')}
        {LokaliseField('stepThreeAPlanTotalLabel')}
        {LokaliseField('stepThreeAPlanTotalPrice')}
        {LokaliseField('stepThreeAReminderHeading')}
        {LokaliseField('stepThreeAReminderOneStartDate')}
        {LokaliseField('stepThreeAReminderTwoEndDate')}
        <CheckboxField name="stepThreeACheckbox" />
        {LokaliseField('stepThreeALegalCopy', true)}
        {LokaliseField('stepThreeACtaOne', true)}
        {LokaliseField('stepThreeACtaTwo', true)}
      </Box>
      <Box>
        <SectionHeader>{t('layouts.tds_gift_cards.headers.top_up')}</SectionHeader>
        {LokaliseField('stepThreeBHeadingTopUp', true)}
        {LokaliseField('stepThreeBSubheaderTopUp')}
        {LokaliseField('stepThreeBPlanHeaderTopUp')}
        {LokaliseField('stepThreeBPlanNameTopUp')}
        {LokaliseField('stepThreeBPlanDescriptionTopUp')}
        {LokaliseField('stepThreeBPlanPriceTopUp')}
        {LokaliseField('stepThreeBPlanTotalLabelTopUp')}
        {LokaliseField('stepThreeBPlanTotalPriceTopUp')}
        {LokaliseField('stepThreeBReminderHeadingTopUp')}
        {LokaliseField('stepThreeBReminderOneStartDateTopUp')}
        {LokaliseField('stepThreeBReminderTwoEndDateTopUp')}
        <CheckboxField name="stepThreeBCheckboxTopUp" />
        {LokaliseField('stepThreeBLegalCopyTopUp', true)}
        {LokaliseField('stepThreeBCtaOneTopUp', true)}
        {LokaliseField('stepThreeBCtaTwoTopUp', true)}
      </Box>
      <Box>
        <SectionHeader>{t('layouts.tds_gift_cards.headers.direct_billing')}</SectionHeader>
        {LokaliseField('stepThreeCHeadingDtc', true)}
        {LokaliseField('stepThreeCSubheaderDtc')}
        {LokaliseField('stepThreeCPlanHeaderDtc')}
        {LokaliseField('stepThreeCPlanNameDtc')}
        {LokaliseField('stepThreeCPlanDescriptionDtc')}
        {LokaliseField('stepThreeCPlanPriceDtc')}
        {LokaliseField('stepThreeCPlanTotalLabelDtc')}
        {LokaliseField('stepThreeCPlanTotalPriceDtc')}
        {LokaliseField('stepThreeCBalanceDtc')}
        {LokaliseField('stepThreeCReminderHeadingDtc')}
        {LokaliseField('stepThreeCReminderOneStartDateDtc')}
        {LokaliseField('stepThreeCReminderTwoEndDateDtc')}
        {LokaliseField('stepThreeCReminderThreeDtc')}
        <CheckboxField name="stepThreeCCheckboxDtc" />
        {LokaliseField('stepThreeCLegalCopyDtc', true)}
        {LokaliseField('stepThreeCCtaOneDtc', true)}
        {LokaliseField('stepThreeCCtaTwoDtc', true)}
        {LokaliseField('stepThreeCBalanceDtcHeading')}
      </Box>
      <Box>
        <SectionHeader>{t('layouts.tds_gift_cards.headers.welcome')}</SectionHeader>
        <Controller
          control={control}
          name={'stepFourBackground.id'}
          defaultValue={generateId()}
          render={({ field: { value, onChange } }) => <input type="hidden" value={value} onChange={onChange} />}
        />
        <BackgroundAssetPicker
          prop="stepFourBackground"
          name="portraitFillImage"
          assetType={AssetTypes.tdsGiftCards.stepFourBackgroundPortraitFillImage}
        />
        <BackgroundAssetPicker
          prop="stepFourBackground"
          name="landscapeFillImage"
          assetType={AssetTypes.tdsGiftCards.stepFourBackgroundLandscapeFillImage}
        />
        <BackgroundAssetPicker
          prop="stepFourBackground"
          name="mobileFillImage"
          assetType={AssetTypes.tdsGiftCards.stepFourBackgroundMobileFillImage}
        />
        <BackgroundAssetPicker
          prop="stepFourBackground"
          name="ctvFillImage"
          assetType={AssetTypes.tdsGiftCards.stepFourBackgroundCtvFillImage}
        />
        <BackgroundAssetPicker
          prop="stepFourBackground"
          name="desktopFillImage"
          assetType={AssetTypes.tdsGiftCards.stepFourBackgroundDesktopFillImage}
        />
        <BackgroundAssetPicker
          prop="stepFourBackground"
          name="tabletFillImage"
          assetType={AssetTypes.tdsGiftCards.stepFourBackgroundTabletFillImage}
        />
        {LokaliseField('stepFourHeading', true)}
        {LokaliseField('stepFourSubheader')}
        {LokaliseField('stepFourLegalCopy')}
        <CheckboxField name="stepFourCheckbox" />
        {LokaliseField('stepFourCtaOne', true)}
        {LokaliseField('stepFourCtaTwo', true)}
      </Box>
      <Box>
        <SectionHeader>{t('layouts.tds_gift_cards.headers.top_up_done')}</SectionHeader>
        {LokaliseFieldTopUpDone('topUpDone', 'message', true)}
        {LokaliseFieldTopUpDone('topUpDone', 'cta', true)}
      </Box>
      <Box>
        <SectionHeader>{`${t('layouts.tds_gift_cards.headers.warning_messages')} - ${t(
          'layouts.tds_gift_cards.headers.unsupported_payment_method'
        )}`}</SectionHeader>
        {LokaliseFieldsErrors('notSupportedPaymentMethodError')}
      </Box>
      <Box>
        <SectionHeader>{`${t('layouts.tds_gift_cards.headers.warning_messages')} - ${t(
          'layouts.tds_gift_cards.headers.unable_to_redeem'
        )}`}</SectionHeader>
        {LokaliseFieldsErrors('unableToRedeemError')}
      </Box>
      <Box>
        <SectionHeader>{`${t('layouts.tds_gift_cards.headers.warning_messages')} - ${t(
          'layouts.tds_gift_cards.headers.used_account'
        )}`}</SectionHeader>
        {LokaliseFieldsErrors('usedAccountError')}
      </Box>
    </FormBody>
  );
});

TdsGiftCardsForm.displayName = 'TdsGiftCardsForm';

export default TdsGiftCardsForm;
