import { MediaResponse } from '../../../API';
import { useMediaAPI } from '../../../hooks/API/Media/useMediaAPI';
import { CreateDataManager, DataManagerReturnType } from '../../DataManager';
import { MediaHook, MediaHookReturnType } from './MediaHook';
import { MediaState, MediaStateFullReturnType } from './MediaState';

export type MediaDataManagerType = {
  state: MediaStateFullReturnType;
  hook: MediaHookReturnType;
};

export interface MediaDataManagerReturnType extends DataManagerReturnType<MediaResponse> {
  state: MediaStateFullReturnType;
  hook: () => MediaHookReturnType;
}

export const MediaDataManager = CreateDataManager<MediaResponse, MediaResponse, MediaDataManagerReturnType>({
  name: 'media',
  idField: 'contentId',
  useApiHook: useMediaAPI,
  hookInitializer: MediaHook,
  stateInitializer: MediaState,
  toString: (record, locales) => locales.localize(record.title)
});
