import {
  DocumentLocale,
  EntitlementType,
  WhoIsWatchingUiModuleBody,
  WhoIsWatchingUiModuleBodyModuleTypeEnum
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankWhoIsWatchingModule: NewModuleFunction<WhoIsWatchingUiModuleBody> = (module) => ({
  moduleType: WhoIsWatchingUiModuleBodyModuleTypeEnum.WHO_IS_WATCHING,
  moduleId: generateId(),
  title: {
    [DocumentLocale.ES]: ''
  },
  entitlements: [EntitlementType.ANON, EntitlementType.FREE, EntitlementType.SUB],
  ...module,
  headerMain: '',
  subHeaderMain: '',
  ctaMain: [
    {
      id: generateId(),
      cta: ''
    }
  ]
});
