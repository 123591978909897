import { uniq } from 'lodash-es';
import { PaymentPlanResponse, PromotionResponse } from '../API';
import { PromotionStatusEnum } from './types/paymentsTypes';

export const getPromotionStatus = (promotion: PromotionResponse): PromotionStatusEnum => {
  if (!promotion.startedAt) return PromotionStatusEnum.DRAFT;
  if (!promotion.endedAt) return PromotionStatusEnum.ACTIVE;
  return PromotionStatusEnum.ENDED;
};

export const getPromoGroups = (plans: PaymentPlanResponse[]): string[] =>
  uniq(
    plans
      .filter((plan) => plan.promoGroup)
      .map((plan) => plan.promoGroup)
      .filter(Boolean) as string[]
  );
