import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { AddToPhotosRounded } from '@mui/icons-material';
import { ChipProps } from '@mui/material/Chip';

export interface INoMediaItems extends ChipProps {
  icon?: React.ReactElement;
  header: string;
  message: string;
}

const useStyles = makeStyles()(() => ({
  root: {
    textAlign: 'center'
  },
  iconContainer: {
    '& .MuiSvgIcon-root': {
      fontSize: 80
    }
  }
}));

function NoMediaItems({ header, message, icon }: INoMediaItems): JSX.Element {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.iconContainer}>
        {icon}
        {!icon && <AddToPhotosRounded />}
      </div>
      <Typography variant="h5">{header}</Typography>
      <Typography variant="body1">{message}</Typography>
    </div>
  );
}

export default NoMediaItems;
