import { Configuration, ContentServiceApi } from '../../../API';

export class ContentServiceAPI {
  constructor(config: Configuration) {
    const api = new ContentServiceApi(config);
    Object.assign(this, api);

    this.patch = api.patch;
  }

  patch!: typeof ContentServiceApi.prototype.patch;
}
