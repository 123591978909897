import React, { useRef, useState } from 'react';
import { Button, MenuItem, ListItemText, Popover } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useLocales } from '../../../../hooks';
import TranslateIcon from '@mui/icons-material/Translate';

const localesInTheirLanguages = {
  en: 'English',
  es: 'Español',
  pt: 'Português'
};

const useStyles = makeStyles()(() => ({
  button: {
    color: 'inherit'
  }
}));

export const testIds = {
  menuButton: 'language-popover.menu-button',
  popover: 'language-popover.popover',
  languageMenuItem: 'language-popover.menu-item'
};

function LanguagePopover(): JSX.Element {
  const { classes } = useStyles();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const { allLanguages, currentLang, onChangeLang } = useLocales();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLang = (value: string) => {
    onChangeLang(value);
    handleClose();
  };

  return (
    <>
      <Button
        className={classes.button}
        startIcon={<TranslateIcon />}
        ref={anchorRef}
        onClick={handleOpen}
        data-testid={testIds.menuButton}
      >
        {currentLang}
      </Button>

      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        data-testid={testIds.popover}
      >
        {allLanguages.map((lang) => (
          <MenuItem
            key={lang}
            selected={lang === currentLang}
            onClick={() => handleChangeLang(lang)}
            data-testid={testIds.languageMenuItem}
          >
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{localesInTheirLanguages[lang]}</ListItemText>
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}

export default LanguagePopover;
