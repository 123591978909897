import { EntitlementType, PlanSummaryUiModuleBody, PlanSummaryUiModuleBodyModuleTypeEnum } from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankPlanSummaryModule: NewModuleFunction<PlanSummaryUiModuleBody> = (module) => ({
  moduleType: PlanSummaryUiModuleBodyModuleTypeEnum.PLAN_SUMMARY,
  moduleId: generateId(),
  entitlements: [EntitlementType.ANON, EntitlementType.FREE, EntitlementType.SUB],
  planName: '',
  priceFirstCharge: '',
  planCost: '',
  totalAmount: '',
  totalSummaryTitle: '',
  legalDisclaimer: '',
  signUpCta: '',
  notNowCta: '',
  checkboxConfirmation: false,
  ...module
});
