import { TOptions } from 'i18next';
import { OperationIds } from '../../API';
import {
  ErrorDetails,
  isForbiddenError,
  useHandleError,
  useLocales,
  UseLocalesReturnType,
  useNotifications
} from '../../hooks';
import { LocaleKeys } from '../../locales/i18n';
import { newRelicAgent, newRelicCmsActions } from '../../utils/newRelicAgent';
import { HttpError } from '../../utils/parseError';

interface DataManagerHelpersProps<R, B> {
  name: string;
  toString?: (value: R | B, locales: UseLocalesReturnType) => string;
}

interface DataManagerHelpersReturnType<R, B> {
  missingActionError: (action: string) => Error;
  handleApiError: (err: unknown, action: string, details?: ErrorDetails) => void;
  handleSuccess: (action: OperationIds | string, contents?: string, localeOptions?: TOptions) => void;
  getRecordString: (record: R | B, locales?: UseLocalesReturnType) => string;
  newRelicLog: (action: OperationIds, data: unknown) => void;
}

export function DataManagerHelpers<R, B>({
  name,
  toString
}: DataManagerHelpersProps<R, B>): DataManagerHelpersReturnType<R, B> {
  const notifications = useNotifications();
  const errorHandler = useHandleError();
  const locales = useLocales();

  const missingActionError = (action: string) => {
    return Error(`${name}Api.${action} was not provided`);
  };

  const handleApiError = (err: unknown, action: string, details?: ErrorDetails) => {
    console.error(`[ApiError][${name}:${action}]`, err);

    const fallback = locales.t(isForbiddenError(err) ? 'errors.unauthorized' : 'errors.general_api_error', {
      action,
      entity: name,
      statusCode: (err as HttpError)?.response?.status
    });

    errorHandler.handleError(err, fallback, details);
  };

  const handleSuccess = (action: OperationIds | string, contents?: string, localeOptions?: TOptions) => {
    const tKey = `success.${name}.${action}` as LocaleKeys;
    try {
      notifications.notifySuccess(locales.t(tKey, localeOptions), contents);
    } catch (err) {
      console.error(`No translation key '${tKey}'`);
    }
  };

  const getRecordString = (record: R | B) => toString?.(record, locales) ?? String(record);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newRelicLog = (action: OperationIds, data: any) => {
    if (newRelicCmsActions[name]?.[action]) {
      newRelicAgent(newRelicCmsActions[name][action] as string, data);
    }
  };

  return {
    missingActionError,
    handleApiError,
    handleSuccess,
    getRecordString,
    newRelicLog
  };
}
