import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { makeStyles } from 'tss-react/mui';
import { DataPresetType, HeroDataPresetDataBody } from '../../../../../../API';
import { FormMetadata } from '../../../../../../data-layer';
import { useLocales, useStatsig } from '../../../../../../hooks';
import {
  getHeroContentTypeFromPresetType,
  getHeroModuleTypeFromItem,
  getPresetTypeFromHeroModuleType
} from '../../../../../../utils/uiModuleUtils';
import Button from '../../../../../shared/Button';
import Drawer from '../../../../../shared/Drawer';
import { PresetDataType, Presets } from '../../../../../shared/Presets';
import { SponsorSection } from '../../SponsorSection';
import { HeroMessage } from '../HeroMessage';
import { HeroUiModuleItem } from '../../../../../../state/Layouts';
import { omit } from 'lodash';
import { HeroItemFormFields } from '../../../../../shared/HeroItemFormFields';
import { HelpText } from '../../../../../shared/HelpText/HelpText';
import { ScheduleTimePicker } from '../../../../../shared/ScheduleTime';
import { SeStatsigGates } from '../../../../../../utils/consts/statsigGates';
import { shouldDisplayScheduleTimes } from '../../../../../../utils/heroContentItemUtils';

const useStyles = makeStyles()((theme) => ({
  formBody: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6)
  },
  footerButton: {
    minWidth: 120
  },
  scheduledTimesHelpText: {
    margin: theme.spacing(2, 0, 3)
  },
  schedule: {
    marginTop: theme.spacing(2)
  }
}));

export interface HeroContentItemFormMetadata extends FormMetadata<HeroUiModuleItem> {
  index?: number;
}

export type HeroContentItemFormProps = {
  formMetadata: HeroContentItemFormMetadata | undefined;
  onSubmit: (formMetadata: HeroContentItemFormMetadata) => void;
  onDelete: (formMetadata: HeroContentItemFormMetadata) => void;
};

export const testIds = {
  formBody: 'hero-content-item-form.form-body',
  deleteButton: 'hero-content-item-form.delete-button',
  submitButton: 'hero-content-item-form.save-button',
  oneBrand: {
    title: 'hero-content-item.one-brand.title',
    description: 'hero-content-item.one-brand.description',
    cta: 'hero-content-item.one-brand.cta',
    price: 'hero-content-item.one-brand.price'
  }
};

export function HeroContentItemForm({
  formMetadata,
  onSubmit,
  onDelete
}: HeroContentItemFormProps): React.ReactElement {
  const formRef = useRef<ValidatorForm>(null);
  const { classes } = useStyles();
  const { t } = useLocales();
  const formMethods = useForm<HeroUiModuleItem>();
  const { handleSubmit, reset, setValue, watch } = formMethods;
  const [isDrawerShowing, setIsDrawerShowing] = useState(false);
  const { CheckGate } = useStatsig();
  const isScheduleActive = CheckGate(SeStatsigGates.HeroContentsScheduleActive);

  useEffect(() => {
    if (!formMetadata?.record) return;
    reset(formMetadata.record);
    if (!formMetadata.record.heroItemType) {
      setValue('heroItemType', getHeroModuleTypeFromItem(formMetadata.record));
    }
    setIsDrawerShowing(true);
  }, [formMetadata]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isInvalid, heroItemType, sponsorEndDate, sponsorLogo, sponsorStartDate, trackingPixelUrl, ...restData } =
    watch();

  const formData = omit(restData, ['id']);

  const presetData = useMemo(() => {
    const presetType = getPresetTypeFromHeroModuleType(heroItemType);
    return {
      heroItemType,
      sponsorLogo,
      ...formData,
      type: presetType
    };
  }, [formData]);

  const loadPresetData = (presetData: PresetDataType) => {
    const content = presetData as HeroDataPresetDataBody;
    const contentType = getHeroContentTypeFromPresetType(content.type);
    reset({ ...content, heroItemType: contentType });
  };

  const handleClose = () => {
    setIsDrawerShowing(false);
  };

  const handleDeleteClick = async () => {
    if (!formMetadata) return;
    onDelete(formMetadata);
    handleClose();
  };

  const onFormSubmit: SubmitHandler<HeroUiModuleItem> = async (submittedHeroContentItem) => {
    if (!formMetadata) return;
    onSubmit({
      ...formMetadata,
      record: submittedHeroContentItem
    });
    handleClose();
  };

  const onSaveClick = () => {
    (formRef.current as ValidatorForm & { submit: () => void }).submit();
  };

  return (
    <Drawer
      open={isDrawerShowing}
      formRef={formRef}
      onSubmit={handleSubmit(onFormSubmit)}
      onClose={handleClose}
      headerLeft={
        <Typography variant="h6">{t(`layouts.hero_${formMetadata?.isNew ? 'new' : 'edit'}_content_item`)}</Typography>
      }
      headerRight={
        formMetadata?.isEditing && (
          <Button color="error" onClick={handleDeleteClick} data-testid={testIds.deleteButton}>
            {t('general.delete')}
          </Button>
        )
      }
      footerLeft={
        <Button className={classes.footerButton} data-testid={testIds.submitButton} onClick={onSaveClick}>
          {t('general.save')}
        </Button>
      }
      footerRight={
        <Button color="grey" className={classes.footerButton} onClick={handleClose}>
          {t('general.cancel')}
        </Button>
      }
    >
      <FormProvider {...formMethods}>
        <div className={classes.formBody} data-testid={testIds.formBody}>
          <HeroItemFormFields>
            <Presets
              isEditing={formMetadata?.isEditing}
              presetType={DataPresetType.HERO}
              data={presetData}
              onChange={loadPresetData}
            />
          </HeroItemFormFields>
          <SponsorSection entityId={'hero'} />
          <HeroMessage />
          {shouldDisplayScheduleTimes(watch('heroItemType')) && isScheduleActive && (
            <div>
              <Typography variant="h6">{t('scheduled_time.label')}</Typography>
              <HelpText className={classes.scheduledTimesHelpText}>{t('scheduled_time.helper')}</HelpText>
              <Controller
                name="scheduleTimes"
                control={formMethods.control}
                render={({ field: { onChange, value } }) => (
                  <ScheduleTimePicker className={classes.schedule} value={value} onChange={onChange} />
                )}
              />
            </div>
          )}
        </div>
      </FormProvider>
    </Drawer>
  );
}
