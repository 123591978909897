import { RecoilRoot } from 'recoil';
import './locales/i18n';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import reportWebVitals from './reportWebVitals';
import App from './App';

import { SNACKBAR_ICONS } from './utils/snackbarUtils';
import { addCustomValidators } from './utils/customValidators';

import 'react-image-crop/dist/ReactCrop.css';
import 'allotment/dist/style.css';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    <RecoilRoot>
      <SnackbarProvider maxSnack={1} iconVariant={SNACKBAR_ICONS}>
        <App />
      </SnackbarProvider>
    </RecoilRoot>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

addCustomValidators();
