import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { CouponDuration as CouponDurationEnum, CouponResponse } from '../../../../API';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2)
  }
}));

export interface ICouponDurationProps {
  coupon: CouponResponse;
}

export const testIds = {
  componentRoot: 'coupon-duration.component-root'
};

export default function CouponDuration({ coupon }: ICouponDurationProps): React.ReactElement {
  const { classes } = useStyles();

  return (
    <>
      {coupon.duration && (
        <div className={classes.root} data-testid={testIds.componentRoot}>
          {coupon.duration !== CouponDurationEnum.TEMPORAL && <div>{coupon.duration}</div>}
          {coupon.duration === CouponDurationEnum.TEMPORAL && (
            <div>
              {coupon.temporalAmount} {coupon.temporalUnit}
            </div>
          )}
        </div>
      )}
    </>
  );
}
