import { useRef } from 'react';
import { noop } from 'lodash-es';
import { useSetRecoilState } from 'recoil';
import { IConfirmDialogProps } from '../../components/shared/ConfirmDialog';
import { withConfirmParams } from '../../state/General';
import { useLocales } from './useLocales';

export type ConfirmParams = Pick<
  IConfirmDialogProps,
  'confirmColor' | 'confirmText' | 'cancelColor' | 'cancelText' | 'isShown'
> & {
  body: React.ReactElement | string;
  'data-testid'?: string;
};

export interface UseConfirmReturn {
  confirm: (params: ConfirmParams) => Promise<boolean>;
  confirmUnsavedChanges: () => Promise<boolean>;
}

type ResolverFunction = (confirm: boolean) => void;

export function useConfirm(): UseConfirmReturn {
  const resolver = useRef<ResolverFunction>(noop);
  const setParams = useSetRecoilState(withConfirmParams);
  const { t } = useLocales();

  const confirm = async (params: ConfirmParams): Promise<boolean> => {
    setParams({ ...params, children: params.body, open: true, onClose, onConfirm } as IConfirmDialogProps);
    return new Promise((resolve) => {
      resolver.current = resolve;
    });
  };

  const onConfirm = () => {
    resolver.current && resolver.current(true);
    onHide();
  };

  const onClose = () => {
    resolver.current && resolver.current(false);
    onHide();
  };

  const onHide = () => {
    setParams((params) => ({ ...params, open: false }));
  };

  const confirmUnsavedChanges = async (): Promise<boolean> => {
    return confirm({
      confirmColor: 'warning',
      confirmText: t('general.continue'),
      body: t('confirm.unsave_warning'),
      isShown: true
    });
  };

  return { confirm, confirmUnsavedChanges };
}

export default useConfirm;
