import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { List, Skeleton } from '@mui/material';
import { Virtuoso, VirtuosoGrid } from 'react-virtuoso';
import { makeStyles } from 'tss-react/mui';
import { CollectionAsset, CollectionAssetType, McpChannelResponse, VllChannelResponse } from '../../../../API';
import Repeat from '../../../shared/Repeat';
import ShadowScroller from '../../../shared/ShadowScroller';
import EPGChannelItem from '../../EPGChannelItem';
import { Sortable, SortableGroups } from '../../../shared/Sortable';
import { noop } from 'lodash-es';
import { useData } from '../../../../data-layer';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DisplayAsOptions } from '../../../../utils/types/genericTypes';
import { getMediaGridTemplateColumns } from '../../../../utils/styleUtils';
import { useSelectedCollectionContent } from '../../../../hooks/Collections/useSelectedCollectionContent';
import { FOUND_CLASS } from '../../../Collections/CollectionsPanel/Media';
import { MediaButtons, MediaButtonsProps } from '../../../Collections/CollectionsPanel/Media/MediaButtons';
import { ensurePinnedElementsAtTheBeginning } from '../../../../utils/autoRotate';

type ChannelUnionType = McpChannelResponse | VllChannelResponse;

const useStyles = makeStyles<{ displayAs: DisplayAsOptions }>()((theme, { displayAs }) => ({
  channelsList: {
    flexGrow: 1
  },
  skeletonChannel: {
    height: theme.spacing(6),
    margin: theme.spacing(2, 3)
  },
  tabContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  searchResultsInner: {
    display: 'grid',
    minHeight: '100%',
    overflow: 'hidden',
    padding: theme.spacing(0, 2),
    gridGap: theme.spacing(2),
    gridTemplateColumns: getMediaGridTemplateColumns(displayAs),
    gridTemplateRows: 'repeat(auto-fit, minmax(0, max-content))'
  },
  mediaButtons: {
    position: 'absolute',
    top: 0,
    left: 0,
    flexDirection: 'column-reverse'
  },
  styleFound: {
    position: 'absolute',
    inset: 0,
    transition: 'box-shadow 0.5s ease',
    pointerEvents: 'none',
    [`&.${FOUND_CLASS}`]: {
      boxShadow: `inset 0 0 0 5px ${theme.palette.primary.main}`
    }
  }
}));

export const testIds = {
  channelsListItem: 'channels.channel-list-item'
};

type EPGChannelsTabProps = {
  channels: ChannelUnionType[] | undefined;
  isLoading?: boolean;
  isChannelPicker?: boolean;
  isContentServiceChannel?: boolean;
  blockedChannels?: string[] | undefined;
  onChannelPicked?: (channel: VllChannelResponse) => void;
};

export function EPGChannelsTab({
  channels,
  isLoading = false,
  isChannelPicker = false,
  isContentServiceChannel = false,
  blockedChannels,
  onChannelPicked
}: EPGChannelsTabProps): ReactElement {
  const {
    collections: {
      state: {
        withDisplayAs,
        withMixedCollectionDisplay,
        withSelected,
        withSelectedCollectionContent,
        withSelectedCollectionAsset
      }
    }
  } = useData();

  const displayAs = useRecoilValue(withDisplayAs);
  const selectedCollectionContent = useRecoilValue(withSelectedCollectionContent);
  const asMixedType = useRecoilValue(withMixedCollectionDisplay);
  const selectedCollection = useRecoilValue(withSelected);
  const setSelectedCollectionContents = useSetRecoilState(withSelectedCollectionContent);
  const setSelectedCollectionAssets = useSetRecoilState(withSelectedCollectionAsset);

  const { classes, cx } = useStyles({ displayAs });
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const itemRef = useRef<HTMLDivElement>(null);
  const [scrollElement, setScrollElement] = useState<HTMLElement>();
  const { isContentIdInActiveCollection } = useSelectedCollectionContent();
  const collectionSelected = !!selectedCollection;

  useEffect(() => {
    if (scrollRef.current) {
      setScrollElement(scrollRef.current);
    }
  }, []);

  const checkInCollection = useCallback(
    (id: string) => selectedCollectionContent?.includes(id),
    [selectedCollectionContent]
  );

  const renderEPGChannelListItem = (channel: ChannelUnionType) => {
    const channelId = isContentServiceChannel
      ? (channel as McpChannelResponse).id
      : (channel as VllChannelResponse).entityId;

    const onAdd = (evt?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!selectedCollection) return;
      // Add to the end if the user is holding SHIFT, otherwise add to the beginning
      const addToEnd = !!evt?.nativeEvent.shiftKey;
      setSelectedCollectionContents(
        (contents) =>
          ensurePinnedElementsAtTheBeginning(
            selectedCollection.pinnedContents,
            contents ? (addToEnd ? [...contents, channelId] : [channelId, ...contents]) : [channelId]
          ) as string[]
      );

      if (asMixedType) {
        const asset = { assetId: channelId, assetType: CollectionAssetType.CHANNEL };
        setSelectedCollectionAssets(
          (contents) =>
            ensurePinnedElementsAtTheBeginning(
              selectedCollection.pinnedContents,
              contents ? (addToEnd ? [...contents, asset] : [asset, ...contents]) : [asset]
            ) as CollectionAsset[]
        );
      }
    };

    // shared props for media buttons
    const mediaButtonsProps: MediaButtonsProps = {
      inCollection: checkInCollection(channelId),
      collectionForKids: false,
      previewMode: false,
      collectionSelected,
      onAdd: onAdd
    };

    const epgChannelItem = (
      <EPGChannelItem
        key={channelId}
        channel={channel}
        isChannelPicker={isChannelPicker}
        data-channel-id={channelId}
        data-testid={testIds.channelsListItem}
        isContentServiceChannel={isContentServiceChannel}
        onChannelPicked={onChannelPicked}
        blocked={blockedChannels?.includes(channelId)}
        className={cx({
          sortableFiltered: isContentIdInActiveCollection(channelId)
        })}
      >
        <MediaButtons className={classes.mediaButtons} backdrop size="small" {...mediaButtonsProps} />
        <div className={classes.styleFound} ref={itemRef} />
      </EPGChannelItem>
    );

    if (isContentServiceChannel) return epgChannelItem;
    const channelList = [channel] as VllChannelResponse[];
    return (
      <Sortable
        group={{
          name: asMixedType ? SortableGroups.collections : SortableGroups.channelCollection,
          pull: 'clone',
          put: false
        }}
        list={asMixedType ? channelList.map((c) => c.entityId) : channelList}
        sort={asMixedType}
        isStringList={asMixedType}
        setList={noop}
        animation={100}
        ghostClass="sortableGhost"
        dragClass="sortableDragPaper"
        filter=".sortableFiltered"
      >
        {epgChannelItem}
      </Sortable>
    );
  };

  return (
    <div className={classes.tabContent}>
      <div className={classes.channelsList}>
        <ShadowScroller ref={scrollRef} loading={isLoading}>
          <List disablePadding={displayAs === DisplayAsOptions.LIST}>
            {!isLoading && displayAs !== DisplayAsOptions.LIST && asMixedType ? (
              <VirtuosoGrid
                listClassName={classes.searchResultsInner}
                useWindowScroll
                data={channels}
                customScrollParent={scrollElement}
                itemContent={(_, channel) => {
                  return renderEPGChannelListItem(channel);
                }}
              />
            ) : (
              <Virtuoso
                useWindowScroll
                data={channels}
                customScrollParent={scrollElement}
                itemContent={(_, channel) => {
                  return renderEPGChannelListItem(channel);
                }}
              />
            )}
            {isLoading && (
              <Repeat n={15}>
                <Skeleton className={classes.skeletonChannel} animation="wave" />
              </Repeat>
            )}
          </List>
        </ShadowScroller>
      </div>
    </div>
  );
}
