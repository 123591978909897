import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../../hooks';
import Image from '../../shared/Image';
import { getAssetUrl } from '../../../utils/assetHelpers';
import { Box } from '@mui/system';

export interface ISportsLogoProps {
  className?: string;
  logoImagePath?: string;
  'data-testid'?: string;
}

const useStyles = makeStyles()((theme) => ({
  logo: {
    width: SPORTS_LOGO_SIZE,
    height: SPORTS_LOGO_SIZE,
    objectFit: 'contain'
  },
  noLogo: {
    border: '1px dashed',
    borderColor: theme.palette.text.primary,
    fontSize: '0.7rem',
    lineHeight: '0.7rem',
    color: theme.palette.text.primary,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    borderRadius: 3,
    opacity: 0.3
  }
}));

export const SPORTS_LOGO_SIZE = 40;
const logoSizeImageKit = SPORTS_LOGO_SIZE * 2;

export function SportsLogo({ logoImagePath, ...props }: ISportsLogoProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();

  return (
    <Image
      className={`${classes.logo} ${props.className}`}
      backgroundSize="contain"
      src={logoImagePath ? `${getAssetUrl(logoImagePath)}?tr=h-${logoSizeImageKit}` : ''}
      data-testid={props['data-testid']}
      fallback={<Box className={classes.noLogo}>{t('general.no_logo')}</Box>}
    />
  );
}
