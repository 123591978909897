import React from 'react';
import { CustomIcon, ICustomIconProps } from '../../CustomIcon/CustomIcon';

export function FreeIcon(props: Omit<ICustomIconProps, 'pathString' | 'viewBox'>): React.ReactElement {
  return (
    <CustomIcon
      // eslint-disable-next-line max-len
      pathString="M181,1 C233.467051,1 276,43.5329488 276,96 C276,116.995774 269.18892,136.400735 257.655759,152.125886 C313.093538,168.365781 360.052205,201.911739 360.985835,249.552044 L361,251 L361,341 C361,354.194444 354.471499,360.860388 341.414497,361 L21,361 C7.80555556,361 1.13961227,354.471499 1,341.414497 L1,251 C1,202.591201 48.3464844,168.529821 104.343578,152.1232 C92.8110796,136.400735 86,116.995774 86,96 C86,43.5329488 128.532949,1 181,1 Z M234.887939,176.29248 C220.825439,176.29248 220.825439,176.29248 212.311035,183.268799 C203.796631,190.245117 203.796631,190.245117 192.920166,210.459961 C173.254639,247.59375 173.254639,247.59375 160.510498,289.012207 C149.304443,269.566406 149.304443,269.566406 141.943604,262.64502 C134.582764,255.723633 134.582764,255.723633 125.134521,255.723633 C117.444092,255.723633 117.444092,255.723633 108.545166,263.084473 C123.774309,277.348389 123.774309,277.348389 137.851819,299.045577 L138.537842,300.108398 L142.932373,306.810059 L148.755127,315.928711 C154.248291,324.60791 154.248291,324.60791 164.465576,343.064941 C191.272217,263.963379 191.272217,263.963379 208.191162,232.652344 C225.110107,201.341309 225.110107,201.341309 253.344971,178.599609 C242.358643,176.29248 242.358643,176.29248 234.887939,176.29248 Z"
      viewBox="0 0 362 362"
      {...props}
    />
  );
}
