import { Role } from '../API';

export enum BasicPermission {
  Read = 'READ',
  Write = 'WRITE',
  Publish = 'PUBLISH',
  ManageUsers = 'MANAGE_USERS'
}

const userPermissions = [] as BasicPermission[];

const editorPermissions = [BasicPermission.Read, BasicPermission.Write, BasicPermission.Publish];

const adminPermissions = [...editorPermissions, BasicPermission.ManageUsers];

export const ROLE_PERMISSIONS: Readonly<Record<Role, BasicPermission[]>> = Object.freeze({
  [Role.USER]: userPermissions,
  [Role.EDITOR]: editorPermissions,
  [Role.ADMIN]: adminPermissions
});

export const getPermissionsWithUpsertAccess = (): BasicPermission[] => {
  return [BasicPermission.Write, BasicPermission.Publish];
};

export const getPermissionsWithPublishAccess = (): BasicPermission[] => {
  return [BasicPermission.Publish];
};

export const getPermissionsWithDeleteAccess = (): BasicPermission[] => {
  return [BasicPermission.Write, BasicPermission.Publish];
};

export const getPermissionsWithReadAccess = (): BasicPermission[] => {
  return [BasicPermission.Read];
};
