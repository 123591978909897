import { CachedRecord } from '.';
import { SetterOrUpdater } from 'recoil';
import { DateTime } from 'luxon';

export const CACHE_STALE_TIMEOUT = 15; // minutes

export interface CacheManagerReturnType<R> {
  createCachedRecord: (object: R, lastUpdated?: DateTime) => CachedRecord<R>;
  createCachedRecordsArray: (records: R[], lastUpdated?: DateTime) => CachedRecord<R>[];
  createCachedRecordsHash: (cachedRecords: CachedRecord<R>[]) => Record<string, CachedRecord<R>>;
  addRecordsToCache: (records: R[]) => Record<string, CachedRecord<R>>;
  addRecordToCache: (record: R) => Record<string, CachedRecord<R>>;
  addLatestPublishedRecordsToCache: (records: R[]) => Record<string, CachedRecord<R>>;
  addLatestPublishedRecordToCache: (record: R) => Record<string, CachedRecord<R>>;
  isStale: (cachedRecord: CachedRecord<unknown>) => boolean;
  flattenCache: (cachedRecords: CachedRecord<R>[]) => R[];
}

export function CacheManager<R>(
  idField: keyof R,
  setCache: SetterOrUpdater<Record<string, CachedRecord<R>>>,
  setLatestPublishedCache: SetterOrUpdater<Record<string, CachedRecord<R>>> = setCache
): CacheManagerReturnType<R> {
  const createCachedRecord = (object: R, lastUpdated = DateTime.now()): CachedRecord<R> => ({ object, lastUpdated });

  const createCachedRecordsArray = (records: R[], lastUpdated = DateTime.now()) =>
    records && records.length ? records.map((record) => createCachedRecord(record, lastUpdated)) : [];

  const createCachedRecordsHash = (cachedRecords: CachedRecord<R>[]) => {
    const cachedRecordsHash: Record<string, CachedRecord<R>> = {};
    cachedRecords.forEach((cachedRecord) => {
      cachedRecordsHash[String(cachedRecord.object[idField])] = cachedRecord;
    });
    return cachedRecordsHash;
  };

  const addRecordsToCache = (records: R[]) => {
    const cachedRecords = createCachedRecordsArray(records);
    const cachedRecordsHash = createCachedRecordsHash(cachedRecords);
    setCache((currentCache) => ({ ...currentCache, ...cachedRecordsHash }));
    return cachedRecordsHash; // Return the new cached records as a hash
  };

  const addRecordToCache = (record: R) => addRecordsToCache([record]);

  const addLatestPublishedRecordsToCache = (records: R[]) => {
    const cachedRecords = createCachedRecordsArray(records);
    const cachedRecordsHash = createCachedRecordsHash(cachedRecords);
    setLatestPublishedCache((currentCache) => ({ ...currentCache, ...cachedRecordsHash }));
    return cachedRecordsHash; // Return the new cached records as a hash
  };

  const addLatestPublishedRecordToCache = (record: R) => addLatestPublishedRecordsToCache([record]);

  const isStale = (cachedRecord: CachedRecord<unknown>) =>
    cachedRecord.lastUpdated < DateTime.now().minus({ minutes: CACHE_STALE_TIMEOUT });

  const flattenCache = (cachedRecords: CachedRecord<R>[]) => cachedRecords.map((cachedRecord) => cachedRecord.object);

  return {
    createCachedRecord,
    createCachedRecordsArray,
    createCachedRecordsHash,
    addRecordsToCache,
    addRecordToCache,
    addLatestPublishedRecordsToCache,
    addLatestPublishedRecordToCache,
    isStale,
    flattenCache
  };
}
