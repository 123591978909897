import React from 'react';
import { Chip, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { CollectionSplashHeroUiModuleBody } from '../../../../../API';
import { useLocales } from '../../../../../hooks';
import { withPreviewCollectionId } from '../../../../../state/Layouts';
import { COLLECTION_ID_PARAM } from '../../../../../utils/consts/uiModules';
import { CollectionLink } from '../../../../Collections/CollectionLink';
import Localized from '../../../../shared/Localized';
import { CollectionSplashHeroPreview } from '../../ContentPreview/CollectionSplashHeroPreview';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';

export type CollectionSplashHeroListItem = IBaseUIModuleListItemProps<CollectionSplashHeroUiModuleBody>;

const CollectionListListItem = ({ uiModule, ...props }: CollectionSplashHeroListItem): JSX.Element => {
  const { t } = useLocales();

  const previewCollectionId = useRecoilValue(withPreviewCollectionId);

  const isDynamic = uiModule.collectionId === COLLECTION_ID_PARAM;
  const collectionId = isDynamic ? previewCollectionId : uiModule.collectionId;

  return (
    <UIModuleListItem
      uiModule={uiModule}
      topLineTitle={<Localized prop={uiModule.title} />}
      collectionLine={[
        <>
          <Typography variant="body2" color="textSecondary">
            {t('layouts.collection')}:
          </Typography>
          {isDynamic && <Chip size="small" label={t('layouts.collection_id_param')} />}
          {!isDynamic && collectionId && <CollectionLink collectionId={collectionId} />}
        </>
      ]}
      renderPreview={(showPreview) =>
        collectionId && <CollectionSplashHeroPreview collectionId={collectionId} showing={showPreview} />
      }
      {...props}
    />
  );
};

export default CollectionListListItem;
