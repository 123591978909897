import { StayPrimaryPortrait } from '@mui/icons-material';
import React, { useState } from 'react';
import { CopyUiModuleBody } from '../../../../../API';
import { useLocales, useTheme } from '../../../../../hooks';
import Button from '../../../../shared/Button';
import { CopyPreview } from '../../ContentPreview/CopyPreview';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';

export type CopyListItemProps = IBaseUIModuleListItemProps<CopyUiModuleBody>;

const CopyListItem = ({ uiModule, ...props }: CopyListItemProps): JSX.Element => {
  const { t } = useLocales();
  const [isPreviewShowing, setIsPreviewShowing] = useState(false);
  const { formControlColor } = useTheme();

  const onPreviewButtonClick = () => {
    setIsPreviewShowing(true);
  };

  return (
    <>
      <UIModuleListItem
        uiModule={uiModule}
        topLineTitle={uiModule.translationKey}
        collectionLine={[
          <Button
            key="preview"
            variant="text"
            size="small"
            startIcon={<StayPrimaryPortrait />}
            color={formControlColor}
            onClick={onPreviewButtonClick}
          >
            {t('layouts.preview_contents')}
          </Button>
        ]}
        {...props}
      />
      <CopyPreview copyModule={uiModule} isOpen={isPreviewShowing} setIsOpen={setIsPreviewShowing} />
    </>
  );
};

export default CopyListItem;
