import React, { FC } from 'react';
import { CustomIcon, ICustomIconProps } from '../CustomIcon';

export const PremiumIcon: FC<React.PropsWithChildren<Omit<ICustomIconProps, 'pathString' | 'viewBox'>>> = (props) => (
  <CustomIcon
    // eslint-disable-next-line max-len
    pathString="M36.8083 0.406133C33.7735 0.406133 31.2765 2.88114 31.2765 5.88922C31.2765 8.8973 33.7735 11.3723 36.8083 11.3723C39.843 11.3723 42.34 8.8973 42.34 5.88922C42.34 2.88114 39.843 0.406133 36.8083 0.406133ZM9.97512 1.52945C9.85988 1.05349 9.49494 0.786948 8.99555 0.786948H0.582689C0.19854 0.786948 -0.0895711 1.18676 0.0256735 1.52945L8.13121 27.0981C8.28487 27.6122 8.64981 27.8406 9.1492 27.8406H20.6737C21.1923 27.8406 21.538 27.5931 21.6917 27.0981L29.7588 1.52945C29.874 1.16772 29.6051 0.786948 29.221 0.786948H21.0386C20.52 0.786948 20.1743 1.07253 20.059 1.52945L14.9883 19.4066L9.97512 1.52945ZM41.762 27.0409C41.762 27.4979 41.4547 27.8215 40.9745 27.8215H32.6385C32.1775 27.8215 31.851 27.5169 31.851 27.0409V14.2661C31.851 13.8092 32.1583 13.4855 32.6385 13.4855H40.9745C41.4355 13.4855 41.762 13.7902 41.762 14.2661V27.0409ZM52.481 14.3043L44.606 26.8506C44.3179 27.3266 44.5676 27.8216 45.1054 27.8216H53.8255C54.4594 27.8216 54.8051 27.6312 55.074 27.1743L59.3957 19.6922L63.7174 27.1743C63.9863 27.6312 64.3512 27.8216 64.9659 27.8216H73.8781C74.4159 27.8216 74.6464 27.3075 74.3775 26.8506L66.5025 14.3043L74.3775 1.75791C74.6656 1.28195 74.4159 0.786948 73.8781 0.786948H65.1579C64.5433 0.786948 64.1976 0.977333 63.9095 1.43426L59.5878 8.91638L55.2661 1.43426C54.9972 0.977333 54.6323 0.786948 54.0176 0.786948H45.1054C44.5676 0.786948 44.3179 1.30099 44.606 1.75791L52.481 14.3043Z M85.9691 0.642639C86.3609 0.642639 86.6786 0.960298 86.6786 1.35215V5.96374L91.2904 5.96398C91.6823 5.96398 91.9999 6.28164 91.9999 6.67349V10.9306C91.9999 11.3224 91.6823 11.6401 91.2904 11.6401H86.6786V16.2519C86.6786 16.6437 86.3609 16.9614 85.9691 16.9614H81.712C81.3201 16.9614 81.0025 16.6437 81.0025 16.2519V11.6401H76.3907C75.9988 11.6401 75.6812 11.3224 75.6812 10.9306V6.67349C75.6812 6.28164 75.9988 5.96398 76.3907 5.96398H81.0025V1.35215C81.0025 0.960298 81.3201 0.642639 81.712 0.642639H85.9691Z"
    viewBox="0 0 92 28"
    {...props}
  />
);
