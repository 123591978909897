import {
  DocumentLocale,
  VersionedDocumentStatus,
  VllCategoriesBundleBody,
  VllCategoriesBundleResponse
} from '../../../../API';
import { useCategoryBundlesAPI } from '../../../../hooks/API/EPG/useCategoryBundlesAPI';
import { CreateDataManager, DataManagerHookReturnType, DataManagerReturnType } from '../../../DataManager';
import { CategoryBundlesHook } from './CategoryBundlesHook';
import { CategoryBundlesState, CategoryBundlesStateFullReturnType } from './CategoryBundlesState';

export type CategoryBundlesDataManagerType = {
  state: CategoryBundlesStateFullReturnType;
  hook: DataManagerHookReturnType<VllCategoriesBundleResponse, VllCategoriesBundleBody>;
};
export interface CategoryBundlesDataManagerReturnType
  extends DataManagerReturnType<VllCategoriesBundleResponse, VllCategoriesBundleBody> {
  state: CategoryBundlesStateFullReturnType;
  hook: () => DataManagerHookReturnType<VllCategoriesBundleResponse, VllCategoriesBundleBody>;
}

const generateNewCategoryBundle = (): VllCategoriesBundleResponse => {
  return {
    entityId: '',
    regionName: '',
    defaultLocale: DocumentLocale.ES,
    countries: [],
    categories: [],
    authorId: '',
    status: VersionedDocumentStatus.DRAFT,
    revision: 0,
    createdDate: '',
    updatedDate: ''
  };
};

export const CategoryBundlesDataManager = CreateDataManager<
  VllCategoriesBundleResponse,
  VllCategoriesBundleBody,
  CategoryBundlesDataManagerReturnType
>({
  name: 'category_bundles',
  idField: 'entityId',
  isVersionedEntity: true,
  useApiHook: useCategoryBundlesAPI,
  generateNew: generateNewCategoryBundle,
  hookInitializer: CategoryBundlesHook,
  stateInitializer: CategoryBundlesState,
  useUpdateForCreate: true,
  toString: (record) => record.regionName
});
