import { useSetRecoilState } from 'recoil';
import { Page } from '../../../API';
import { withLayoutsType } from '../../../state/Layouts';
import {
  CachedRecord,
  CacheManager,
  DataManagerHook,
  DataManagerHookReturnType,
  IDataManagerHookProps,
  useGetRecoilState
} from '../../DataManager';

export interface PagesHookReturnType extends DataManagerHookReturnType<Page, Page> {
  savePage: (page: Page) => Promise<void>;
}

export function PagesHook(params: IDataManagerHookProps<Page, Page>): PagesHookReturnType {
  const dataManagerHook = DataManagerHook<Page, Page>(params);

  const getBuckets = useGetRecoilState(params.state.withRecordBucketsById);
  const setBuckets = useSetRecoilState(params.state.withRecordBucketsById);
  const getLayoutsType = useGetRecoilState(withLayoutsType);

  const setCache = useSetRecoilState(params.state.withDataCache);
  const { addRecordToCache } = CacheManager(params.idField, setCache);

  const savePage = async (page: Page): Promise<void> => {
    const layoutType = await getLayoutsType();
    const buckets = { ...(await getBuckets()) };
    if (buckets[layoutType] && buckets[layoutType].object) {
      buckets[layoutType] = {
        ...buckets[layoutType],
        object: await dataManagerHook.addRecordToIdsList(page, buckets[layoutType] as CachedRecord<string[]>)
      };
      setBuckets(buckets);
    }
    addRecordToCache(page);
    dataManagerHook.closeForm();
  };

  return {
    ...dataManagerHook,
    savePage
  };
}
