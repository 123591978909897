import React, { useEffect, useState } from 'react';
import { PublishWindowsDefault } from '../../../../../API';
import { checkLicense, LicenseStatus, LicenseType } from '../../../../../utils/mediaUtilities';
import { useLocales } from '../../../../../hooks';
import { Theme } from '@mui/material';
import { Box } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

export const testIds = {
  licenseLabel: 'License-label'
};

export const LicenseHeight = 24;

export const LicenseClass = 'license';
export const LabelClass = 'license-label';
export const ImageClass = 'license-image';

interface LicenseProps {
  publishWindow: PublishWindowsDefault | undefined;
  className?: string;
}

type PassedProps = {
  status: LicenseStatus | undefined;
};

const getLicenseColor = (status: LicenseStatus | undefined, theme: Theme) => {
  switch (status) {
    case LicenseStatus.STARTS:
      return theme.palette.info.main;
    case LicenseStatus.EXPIRES:
      return theme.palette.warning.main;
    case LicenseStatus.EXPIRED:
    default:
      return theme.palette.error.main;
  }
};

const useStyles = makeStyles<PassedProps>()((theme, { status }) => ({
  license: {
    display: 'flex',
    height: LicenseHeight,
    background: getLicenseColor(status, theme),
    borderRadius: theme.shape.borderRadius,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  image: {
    height: '100%'
  },
  text: {
    fontSize: '0.65rem',
    marginLeft: 4,
    color: theme.palette.getContrastText(getLicenseColor(status, theme))
  }
}));

function License({ publishWindow, className }: LicenseProps): JSX.Element {
  const { t, currentLang } = useLocales();

  const [license, setLicense] = useState<LicenseType>();
  const [label, setLabel] = useState<string>();
  const [image, setImage] = useState<string>();

  const { classes, cx } = useStyles({ status: license?.status });

  const getLabel = (type: LicenseType | undefined) => {
    if (!type) return;
    const licenseTranslation = t(`media.license_${type.status}`, { date: type.time });
    setLabel(licenseTranslation.trim());
  };

  useEffect(() => {
    getLabel(license);
  }, [currentLang]);

  useEffect(() => {
    const licenseObject = checkLicense(publishWindow);
    setLicense(licenseObject);

    if (licenseObject) {
      setImage(licenseObject.image);
      getLabel(licenseObject);
    }
  }, [publishWindow]);

  if (!license) return <></>;

  return (
    <Box
      className={cx(classes.license, className, LicenseClass)}
      data-testid={testIds.licenseLabel}
      data-license-status={license.status}
    >
      <img className={cx(classes.image, ImageClass)} src={image} />
      <div className={cx(classes.text, LabelClass)}>{label}</div>
    </Box>
  );
}

export default React.memo(License);
