import {
  CollectionSplashHeroUiModuleBody,
  CollectionSplashHeroUiModuleBodyModuleTypeEnum,
  DocumentLocale
} from '../../../API';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankCollectionSplashHero: NewModuleFunction<CollectionSplashHeroUiModuleBody> = (module) => ({
  moduleType: CollectionSplashHeroUiModuleBodyModuleTypeEnum.COLLECTION_SPLASH_HERO,
  title: {
    [DocumentLocale.ES]: ''
  },
  collectionId: '',
  ...module
});
