import React, { useEffect, useMemo, useState } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { keyBy } from 'lodash-es';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import {
  HeroModuleItemType,
  HeroUiModuleCollectionLinkItem,
  HeroUiModulePromotionItem,
  MediaResponse
} from '../../../../../../API';
import { useData } from '../../../../../../data-layer';
import { useLocales, useLokalise, useMatches, useStatsig } from '../../../../../../hooks';
import { HeroUiModuleItem } from '../../../../../../state/Layouts';
import { getAssetUrl } from '../../../../../../utils/assetHelpers';
import { ThumbnailOrientation } from '../../../../../../utils/types/genericTypes';
import {
  AllHeroPresetTypes,
  getContentIdFromHeroItem,
  getHeroModuleTypeFromContentId,
  getHeroModuleTypeFromItem
} from '../../../../../../utils/uiModuleUtils';
import Image from '../../../../../shared/Image';
import Localized from '../../../../../shared/Localized';
import StyledPremiumBadge from '../../../../../shared/StyledPremiumBadge';
import { IMatchThumbnailProps, MatchThumbnail } from '../../../../../Sports/MatchThumbnail';
import { VixOrangeButton } from '../../shared/SharedButtons';
import { isInScheduledTime } from '../../../../../shared/ScheduleTime/scheduleTimeHelpers';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ScheduleTimePreviewUntilTime } from '../../../../../shared/ScheduleTime/ScheduleTimePreview';
import { SeStatsigGates } from '../../../../../../utils/consts/statsigGates';
import { shouldDisplayScheduleTimes } from '../../../../../../utils/heroContentItemUtils';

const useStyles = makeStyles<{ orientation: ThumbnailOrientation }>()((theme, props) => {
  const isLandscape = props.orientation === ThumbnailOrientation.LANDSCAPE;

  return {
    thumbnailContainer: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      overflow: 'hidden',
      width: '100%',
      height: '100%'
    },
    backgroundImage: {
      width: '100%'
    },
    logoContainer: {
      position: 'absolute',
      left: isLandscape ? '5%' : '20%',
      height: '100%',
      width: isLandscape ? '25%' : '60%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around'
    },
    logoText: {
      color: '#fff',
      fontSize: '12px',
      textTransform: 'uppercase',
      textShadow: '0px 0px 5px #000',
      whiteSpace: 'break-spaces',
      width: '150%'
    },
    mask: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: isLandscape
        ? 'linear-gradient(to right, rgba(0,0,0,0.7), transparent)'
        : 'linear-gradient(to top, rgba(0,0,0,0.7), transparent)'
    },
    accessTimeIcon: {
      position: 'absolute',
      top: theme.spacing(2),
      left: theme.spacing(2),
      color: 'white'
    },
    disabled: {
      filter: 'grayscale(1)',
      opacity: 0.5
    },
    overlay: {
      position: 'absolute',
      inset: 0,
      margin: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'center',
      overflow: 'hidden',
      color: 'white',
      '& p': {
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      }
    },
    overlayButton: {
      margin: theme.spacing(2, 0)
    }
  };
});

const testIds = {
  heroContentItem: 'hero-thumbnail.hero-content-item'
};

type HeroThumbnailProps = {
  heroContentItem: HeroUiModuleItem | AllHeroPresetTypes;
  orientation: ThumbnailOrientation;
  disabled?: boolean;
};

export const HeroThumbnail = ({
  heroContentItem,
  orientation,
  disabled = false
}: HeroThumbnailProps): React.ReactElement => {
  const { classes, cx } = useStyles({ orientation });

  const {
    media: { hook: useMedia, state: mediaState },
    channels: { hook: useChannels, state: channelsState },
    collections: { state: collectionsState }
  } = useData();
  const { getMatchesByIds } = useMatches();
  const { t, localize } = useLocales();
  const { lt } = useLokalise();

  const hci = heroContentItem as HeroUiModuleItem;
  // TODO remove the first part once the deprecated HeroDataPresetDataBody is removed
  const heroContentItemContentId =
    (heroContentItem as { contentId?: string }).contentId || getContentIdFromHeroItem(hci);

  const [matchData, setMatchData] = useState<MediaResponse | undefined>();
  const media = useRecoilValue(mediaState.withRecordById(heroContentItemContentId));
  const channel = useRecoilValue(channelsState.withRecordById(heroContentItemContentId));
  const collection = useRecoilValue(
    collectionsState.withRecordById((hci as HeroUiModuleCollectionLinkItem).collectionId)
  );
  const { CheckGate } = useStatsig();

  const isScheduleActive = CheckGate(SeStatsigGates.HeroContentsScheduleActive);
  const isLandscape = orientation === ThumbnailOrientation.LANDSCAPE;
  const backgroundImage = isLandscape ? heroContentItem.landscapeFillImage : heroContentItem.portraitFillImage;
  const logoImage = isLandscape ? heroContentItem.logoImage : heroContentItem.portraitLogoImage;

  const match = useMemo<IMatchThumbnailProps>(
    () => ({
      matchData,
      customBackground: backgroundImage ? getAssetUrl(backgroundImage) : undefined
    }),
    [matchData, heroContentItem]
  );

  const type =
    hci.heroItemType || getHeroModuleTypeFromItem(hci) || getHeroModuleTypeFromContentId(heroContentItemContentId);

  const shouldDisplaySchedule = shouldDisplayScheduleTimes(type) && isScheduleActive;
  const isDisabled = disabled || (shouldDisplaySchedule && !isInScheduledTime(hci.scheduleTimes));

  useEffect(() => {
    switch (type) {
      case HeroModuleItemType.CHANNEL:
        if (!channel) {
          useChannels.queueIdToFetch(heroContentItemContentId);
        }
        break;
      case HeroModuleItemType.EVENTS:
        if (!matchData) {
          fetchMatch();
        }
        break;
      case HeroModuleItemType.VOD:
        if (!media) {
          useMedia.queueIdToFetch(heroContentItemContentId);
        }
        break;
    }
  }, [heroContentItem]);

  const fetchMatch = async () => {
    const matches = await getMatchesByIds([heroContentItemContentId]);
    const matchesHash = keyBy(matches, 'contentId');
    if (matchesHash[heroContentItemContentId]) {
      setMatchData(matchesHash[heroContentItemContentId]);
    }
  };

  if (type === HeroModuleItemType.EVENTS) {
    return <MatchThumbnail {...match} data-testid={testIds.heroContentItem} />;
  }

  const TooltipContents: React.FC<{ label: string }> = ({ label }) => (
    <Stack>
      <Typography variant="subtitle2">{label}</Typography>
      <Typography variant="subtitle2">{collection?.slug}</Typography>
      <Typography variant="caption">
        {t('layouts.type')}: {t(`general.${type.toLowerCase()}`)}
      </Typography>
      {shouldDisplaySchedule && hci.scheduleTimes && hci.scheduleTimes.length > 0 && (
        <ScheduleTimePreviewUntilTime value={hci.scheduleTimes} />
      )}
    </Stack>
  );

  const OverlayWithCTA = () => (
    <div className={classes.overlay}>
      <Typography variant="body1" fontWeight="bold">
        {lt(hci.lokaliseTitle)}
      </Typography>
      <Typography variant="body2">{lt(hci.description)}</Typography>
      <VixOrangeButton size="small" className={classes.overlayButton}>
        {lt((hci as HeroUiModulePromotionItem).ctaName)}
      </VixOrangeButton>
      <Typography variant="caption">{lt((hci as HeroUiModulePromotionItem).priceText)}</Typography>
    </div>
  );

  const content = media || channel;

  return (
    <Tooltip title={<TooltipContents label={content ? localize(content.title) : heroContentItemContentId} />}>
      <div className={classes.thumbnailContainer} data-testid={testIds.heroContentItem}>
        <div className={classes.mask} />
        <Image
          className={cx(classes.backgroundImage, { [classes.disabled]: isDisabled })}
          src={getAssetUrl(backgroundImage)}
        />
        {shouldDisplaySchedule && (hci.scheduleTimes?.length || 0) > 0 && (
          <AccessTimeIcon className={classes.accessTimeIcon} />
        )}
        <div className={cx(classes.logoContainer, { [classes.disabled]: isDisabled })}>
          {logoImage && <img src={getAssetUrl(logoImage)} />}
          {!logoImage && (
            <div className={classes.logoText}>
              {media && <Localized prop={media.title} />}
              {channel && <Localized prop={channel.title} />}
            </div>
          )}
        </div>
        {media?.isPremium && <StyledPremiumBadge />}
        {type === HeroModuleItemType.PROMOTION && <OverlayWithCTA />}
        {type === HeroModuleItemType.PAGE && <OverlayWithCTA />}
      </div>
    </Tooltip>
  );
};
