import {
  DataPresetBody,
  DataPresetResponse,
  DataPresetType,
  HeroItemVodDataPresetBody,
  HeroModuleItemType
} from '../../../API';
import { usePresetsAPI } from '../../../hooks/API/Presets/usePresetsAPI';
import { NewHeroPresetTypes } from '../../../utils/uiModuleUtils';
import { CreateDataManager, DataManagerReturnType } from '../../DataManager';
import { PresetsHook, PresetsHookReturnType } from './PresetsHook';
import { PresetsState, PresetsStateReturnType } from './PresetsState';

export type PresetsDataManagerType = {
  state: PresetsStateReturnType;
  hook: PresetsHookReturnType;
};

export const generateNewPreset = (data = {} as DataPresetResponse): DataPresetResponse | DataPresetBody => ({
  ...data,
  name: '',
  type: DataPresetType.HERO_ITEM_VOD,
  data: <NewHeroPresetTypes>{
    ...data.data,
    type: DataPresetType.HERO_ITEM_VOD,
    heroItemType: HeroModuleItemType.VOD,
    mobileFillImage: '',
    landscapeFillImage: '',
    portraitFillImage: '',
    portraitLogoImage: '',
    logoImage: '',
    ctvFillImage: ''
  }
});

export interface PresetsDataManagerReturnType extends DataManagerReturnType<DataPresetResponse, DataPresetBody> {
  state: PresetsDataManagerType['state'];
  hook: () => PresetsDataManagerType['hook'];
}

export const PresetsDataManager = CreateDataManager<DataPresetResponse, DataPresetBody, PresetsDataManagerReturnType>({
  name: 'presets',
  idField: 'id',
  stateInitializer: PresetsState,
  hookInitializer: PresetsHook,
  useApiHook: usePresetsAPI,
  generateNew: generateNewPreset,
  toString: (preset) => preset.name,
  // Bucket only supporting the VOD presets
  bucketIdToQueryParams(id) {
    return [[DataPresetType.HERO, DataPresetType.HERO_ITEM_VOD], undefined, id];
  },
  // Strictly only checking VOD presets
  getRecordBucketIds(record) {
    return [((record as DataPresetResponse).data as HeroItemVodDataPresetBody).contentId];
  }
});
