import { useSetRecoilState } from 'recoil';
import { EMPTY_TOURNAMENT, EMPTY_TOURNAMENT_ID, TournamentsStateFullReturnType } from '.';
import {
  ApiResponseOfTournamentResponse,
  ApiResponseOfTournamentResponseArray,
  TournamentBody,
  TournamentResponse,
  TournamentsApi
} from '../../../../API';
import { CacheManager, DataManagerHook, DataManagerHookReturnType, IDataManagerHookProps } from '../../../DataManager';
import { debounce } from 'lodash';

export type TournamentsHookReturnType = DataManagerHookReturnType<TournamentResponse, TournamentBody> & {
  getByTournamentId: (id: string) => Promise<ApiResponseOfTournamentResponse>;
  getByTournamentIds: (ids: string[]) => Promise<ApiResponseOfTournamentResponseArray>;
  queueTournamentIdToFetch: (id: string) => void;
};

export function TournamentsHook(
  params: IDataManagerHookProps<TournamentResponse, TournamentBody>
): TournamentsHookReturnType {
  const api = params.useApiHook() as TournamentsApi;

  const dataManagerHook = DataManagerHook<TournamentResponse, TournamentBody>(params);
  const state = params.state as TournamentsStateFullReturnType;
  const setSelectedRecord = useSetRecoilState(state.withSelected);
  const setSelectedRecordId = useSetRecoilState(state.withSelectedId);

  const setCache = useSetRecoilState(params.state.withDataCache);
  const { addRecordToCache, addRecordsToCache } = CacheManager(params.idField, setCache);

  const setSelected = async (id: string | undefined) => {
    if (id === EMPTY_TOURNAMENT_ID) {
      setSelectedRecordId(id);
      setSelectedRecord(EMPTY_TOURNAMENT);
    } else {
      dataManagerHook.setSelected(id);
    }
  };

  const idsToFetch: Set<string> = new Set();

  const queueTournamentIdToFetch = (id: string) => {
    idsToFetch.add(id);
    fetchAllFromQueue();
  };

  const fetchAllFromQueue = debounce(() => {
    const idsCopy = [...idsToFetch];
    idsToFetch.clear();
    if (idsCopy.length === 1) {
      getByTournamentId(idsCopy[0]);
    } else {
      getByTournamentIds(idsCopy);
    }
  }, 50);

  const getByTournamentId = async (tournamentId: string) => {
    const data = (await api.getByTournamentId(tournamentId)).data;
    addRecordToCache(data.body);
    return data;
  };

  const getByTournamentIds = async (tournamentIds: string[]) => {
    const data = (await api.getByTournamentIds(tournamentIds)).data;
    addRecordsToCache(data.body);
    return data;
  };

  return {
    ...dataManagerHook,
    setSelected,
    queueTournamentIdToFetch,
    getByTournamentId,
    getByTournamentIds
  };
}
