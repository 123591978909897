export const ALL = 'ALL';
export const NONE = 'NONE';
export const TRUE = 'TRUE';
export const FALSE = 'FALSE';
export type IncludeAll<T> = T | typeof ALL;
export type TrinaryBoolean = typeof TRUE | typeof FALSE | typeof ALL;
export type BadgeSize = 'small' | 'medium';
export type RecordOfType<T extends Array<unknown> | undefined> = Record<string, Exclude<T, undefined>[number]>;
export type KeysOfUnion<T> = T extends T ? keyof T : never;
export enum ThumbnailOrientation {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape'
}
export enum DisplayAsOptions {
  LIST = 'list',
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape'
}
export function transformAll<T>(value: T | typeof ALL): T | undefined {
  return value === ALL ? undefined : value;
}
