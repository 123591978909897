/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { useLocales, useTheme } from '../../../hooks';
import { Button, MenuItem, Select, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import IconButton from '../IconButton';
import { DocumentLocale } from '../../../API';
import { Add, Delete } from '@mui/icons-material';
import { UseFormGetValues, UseFormReset } from 'react-hook-form';
import { get } from 'lodash-es';

interface ILocalizedInputCollectionProps {
  defaultLanguage?: DocumentLocale;
  fields: FieldObjectType[];
  containerClassName?: string;
  getValues: UseFormGetValues<any>;
  reset: UseFormReset<any>;
}

export type ComponentTypeProps = {
  key: string | number;
  className?: string;
  label?: string;
  name: string;
  'data-testid'?: string;
};

export type FieldObjectType = {
  component: React.ReactElement<ComponentTypeProps>;
};

export const testIds = {
  field: (language: DocumentLocale, fieldName: string): string => `lic-field-${language}-${fieldName}`,
  addButton: 'lic-add-button',
  removeButton: (language: DocumentLocale): string => `lic-remove-${language}`,
  languageSelect: 'lic-language-select',
  languageMenuItem: 'lic-language-menu-item'
};

const documentLocaleLanguages = Object.values(DocumentLocale);

const createLanguageHash = (f: (language: DocumentLocale) => any) => {
  return documentLocaleLanguages.reduce(
    (dictionary: Record<DocumentLocale, any>, next) => {
      dictionary[next] = f(next);
      return dictionary;
    },
    {} as Record<DocumentLocale, any>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  languageRow: {
    display: 'flex',
    flexDirection: 'row',
    '&:not(:first-of-type)': {
      paddingTop: theme.spacing(2),
      borderTop: `1px dotted ${theme.palette.divider}`
    }
  },
  addLanguageSelector: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
    borderTop: `1px dotted ${theme.palette.divider}`,
    padding: theme.spacing(3, 0, 4)
  },
  languageInput: {
    minWidth: 160
  },
  languageRowRight: {
    marginLeft: theme.spacing(4),
    alignSelf: 'center'
  },
  languageRowMiddle: {
    flexGrow: 1
  },
  languageRowLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    marginRight: theme.spacing(4)
  }
}));

function LocalizedInputCollection({
  fields,
  defaultLanguage = DocumentLocale.ES,
  containerClassName,
  getValues,
  reset
}: ILocalizedInputCollectionProps): JSX.Element {
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { classes, cx } = useStyles();
  const [selectedAddLanguage, setSelectedAddLanguage] = useState<DocumentLocale>();
  const [unsupportedLanguages, setUnsupportedLanguages] = useState<DocumentLocale[]>([]);
  const [showingLanguage, setShowingLanguage] = useState<Record<DocumentLocale, boolean>>(
    createLanguageHash(() => false)
  );

  useEffect(() => {
    initializeLanguages();
  }, []);

  useEffect(() => {
    const newUnsupportedLanguages = documentLocaleLanguages.filter(
      (language: DocumentLocale) => !showingLanguage[language]
    );
    setSelectedAddLanguage(newUnsupportedLanguages[0]);
    setUnsupportedLanguages(newUnsupportedLanguages);
  }, [showingLanguage]);

  const initializeLanguages = () => {
    const newShowingLanguageHash = { ...showingLanguage };
    const values = getValues();
    fields.forEach((field) => {
      const fieldName = field.component.props.name;
      documentLocaleLanguages.forEach((locale) => {
        if (!get(values, fieldName)) return;
        if (![undefined, null].includes(get(values, fieldName)[locale])) {
          newShowingLanguageHash[locale] = true;
        }
      });
    });
    setShowingLanguage(newShowingLanguageHash);
  };

  const addLanguage = (language: DocumentLocale) => {
    const newFormState = getValues();
    fields.forEach((field) => {
      const fieldName = field.component.props.name;
      get(newFormState, fieldName)[language] = '';
    });
    reset(newFormState);

    setShowingLanguage({
      ...showingLanguage,
      [language]: true
    });
  };

  const deleteLanguage = (language: DocumentLocale) => {
    const newFormState = getValues();
    fields.forEach((field) => {
      const fieldName = field.component.props.name;
      // setting the field to undefined does not work properly, but the SE should handle null just the same
      get(newFormState, fieldName)[language] = null;
    });
    reset(newFormState);

    setShowingLanguage({
      ...showingLanguage,
      [language]: false
    });
  };

  return (
    <div className={classes.container}>
      {/* Language Fields */}
      {documentLocaleLanguages
        .filter((language) => showingLanguage[language])
        .map((language) => (
          <div className={classes.languageRow} key={language}>
            <div className={classes.languageRowLeft}>
              <Typography variant="h6">{language}</Typography>
            </div>
            <div className={cx(containerClassName, classes.languageRowMiddle)}>
              {/* language fields */}
              {fields?.map((field) => {
                const fieldName = `${field.component.props.name}.[${language}]`;
                return React.cloneElement(field.component, {
                  key: fieldName,
                  name: fieldName,
                  'data-testid': testIds.field(language, field.component.props.name)
                } as ComponentTypeProps);
              })}
            </div>
            <div className={classes.languageRowRight}>
              <IconButton
                data-testid={testIds.removeButton(language)}
                disabled={defaultLanguage === language}
                onClick={() => deleteLanguage(language)}
                title={t('tooltips.delete_language')}
                size="large"
              >
                <Delete />
              </IconButton>
            </div>
          </div>
        ))}
      {/* Add Language Option */}
      {unsupportedLanguages.length > 0 && (
        <div className={classes.addLanguageSelector}>
          <Select
            className={classes.languageInput}
            color={formControlColor}
            title={t('general.add_language')}
            value={selectedAddLanguage}
            data-testid={testIds.languageSelect}
            onChange={({ target: { value } }) => setSelectedAddLanguage(value as DocumentLocale)}
          >
            {unsupportedLanguages.map((languageCode: DocumentLocale) => (
              <MenuItem data-testid={testIds.languageMenuItem} key={languageCode} value={languageCode}>
                {t(`languages.${languageCode}`)}
              </MenuItem>
            ))}
          </Select>
          <Button
            color={formControlColor}
            startIcon={<Add />}
            data-testid={testIds.addButton}
            onClick={() => addLanguage(selectedAddLanguage as DocumentLocale)}
          >
            {t('general.add_language')}
          </Button>
        </div>
      )}
    </div>
  );
}

export default LocalizedInputCollection;
