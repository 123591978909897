import { useSetRecoilState } from 'recoil';
import { useHandleError, useLocales, useNotifications } from '../General';
import { useConfigAPI } from '../API/Config/useConfigAPI';
import { withAllSettings } from '../../state/Config';
import { ConfigBody, ConfigResponse } from '../../API';

type ReturnType = {
  updateCmsConfig: (appConfig: ConfigResponse) => void;
  fetchCmsConfig: () => void;
};

export function useConfig(): ReturnType {
  const configApi = useConfigAPI();
  const { t } = useLocales();
  const { handleError } = useHandleError();
  const { notifySuccess } = useNotifications();
  const setAllConfiguration = useSetRecoilState(withAllSettings);

  const fetchCmsConfig = async () => {
    try {
      const {
        data: { body }
      } = await configApi.getDefaultConfig();
      setAllConfiguration(body);
    } catch (err) {
      handleError(err, t('errors.app_settings.configuration_fetch'));
    }
  };

  const updateCmsConfig = async (appConfig: ConfigResponse) => {
    try {
      if (!appConfig) return;
      const {
        data: { body }
      } = await configApi.updateConfig(appConfig?.id, appConfig as ConfigBody);
      notifySuccess(t('success.app_settings.configuration_update'));
      setAllConfiguration(body);
    } catch (err) {
      handleError(err, t('errors.app_settings.configuration_update'));
    }
  };

  return {
    fetchCmsConfig,
    updateCmsConfig
  };
}
