import { SupportedLanguage } from '../../locales/i18n';
import { useData } from '../../data-layer';

export type UseLokaliseReturnType = {
  lt: (key?: string, language?: SupportedLanguage) => string;
  getTranslations: (prefix?: string, language?: SupportedLanguage) => Record<string, string>;
  searchTranslations: (term: string, prefix?: string, language?: SupportedLanguage) => Record<string, string>;
};

export function useLokalise(): UseLokaliseReturnType {
  const {
    lokalise: { hook: lokaliseHook }
  } = useData();

  const searchTranslations = (term: string, prefix?: string, language?: SupportedLanguage): Record<string, string> => {
    const translationList: Record<string, string> = {};
    lokaliseHook.searchKeys(term, prefix).forEach((key) => {
      translationList[key.key] = lokaliseHook.getTranslation(key.key, language);
    });
    return translationList;
  };

  const getTranslations = (prefix?: string, language?: SupportedLanguage): Record<string, string> => {
    return searchTranslations('', prefix, language);
  };

  return {
    lt: lokaliseHook.getTranslation,
    getTranslations,
    searchTranslations
  };
}
