import { LinkingAccountUiModuleBody, LinkingAccountUiModuleBodyModuleTypeEnum, EntitlementType } from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankLinkingAccountModule: NewModuleFunction<LinkingAccountUiModuleBody> = (module) => ({
  moduleType: LinkingAccountUiModuleBodyModuleTypeEnum.LINKING_ACCOUNT,
  moduleId: generateId(),
  entitlements: [EntitlementType.ANON, EntitlementType.FREE, EntitlementType.SUB],
  vixLogo: '',
  partnerLogo: '',
  background: '',
  avodHeader: '',
  avodSubHeader: '',
  createAccountCta: '',
  signInCta: '',
  linkExistingAccountCta: '',
  createNewAccountCta: '',
  loggedInHeader: '',
  loggedInSubHeader: '',
  loggedInLinkingCta: '',
  loggedInCreateNewAccountCta: '',
  svodHeader: '',
  svodSubHeader: '',
  svodCreateAccountCta: '',
  notNowCta: '',
  ...module
});
