import React, { useMemo } from 'react';
import { Collapse } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Skeleton } from '@mui/material';
import Repeat from '../../../shared/Repeat';
import SimpleBar from 'simplebar-react';
import { useLocales, useResponsive } from '../../../../hooks';
import { DisplayAsOptions } from '../../../../utils/types/genericTypes';

const portraitThumbnailWidth = 80;
const landscapeThumbnailWidth = 130;
const heroThumbnailWidth = 300;
const squareThumbnailWidth = 100;

const heroPortraitThumbnailSize = 200;

// TODO get these from somewhere else
const landscapeAspectRatio = 9 / 16;
const portraitAspectRatio = 1.5;
const backgroundImageAspectRatio = 115 / 29;
const backgroundImageMaxHeight = 250;

export const useStyles = makeStyles<{ backgroundPosition?: string }>()((theme, props) => ({
  thumbnailContainer: {
    padding: theme.spacing(0, 0, 3, 3),
    display: 'inline-flex'
  },
  thumbnail: {
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    '&.portrait': {
      width: portraitThumbnailWidth,
      height: portraitThumbnailWidth * portraitAspectRatio
    },
    '&.landscape': {
      width: landscapeThumbnailWidth,
      height: landscapeThumbnailWidth * landscapeAspectRatio
    },
    '&.heroLandscape': {
      width: heroThumbnailWidth,
      height: heroThumbnailWidth * landscapeAspectRatio
    },
    '&.heroPortrait': {
      width: heroPortraitThumbnailSize,
      height: heroPortraitThumbnailSize
    },
    '&.square': {
      width: squareThumbnailWidth,
      height: squareThumbnailWidth
    }
  },
  noContent: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3)
  },
  carousel: {
    display: 'flex',
    whiteSpace: 'nowrap',
    '&.loading': {
      overflowX: 'hidden'
    }
  },
  container: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column-reverse'
  },
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: props.backgroundPosition
  }
}));

export type ContentPreviewModel<T> = {
  contentList: Array<T> | undefined;
  thumbnailComponent: (content: T) => React.ReactElement;
  idField: keyof T;
};
export interface IContentPreviewProps {
  displayAs: DisplayAsOptions | 'heroLandscape' | 'heroPortrait' | 'square';
  showing: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  model?: ContentPreviewModel<any>;
  loaderCount?: number;
  backgroundImageUrl?: string;
  backgroundPosition?: string;
  children?: React.ReactNode;
  header?: React.ReactNode;
}

export function ContentPreview({
  displayAs,
  showing,
  model,
  loaderCount = 16,
  backgroundImageUrl,
  backgroundPosition = 'center',
  children,
  header
}: IContentPreviewProps): JSX.Element {
  const { classes } = useStyles({ backgroundPosition });
  const { t } = useLocales();
  const { ref: container, width } = useResponsive();

  const backgroundHeight = useMemo(() => {
    return Math.min(backgroundImageMaxHeight, Math.floor(width / backgroundImageAspectRatio));
  }, [width]);

  return (
    <Collapse in={showing} timeout="auto" mountOnEnter unmountOnExit ref={container}>
      {header}
      <div className={classes.container} style={{ height: backgroundImageUrl ? `${backgroundHeight}px` : 'unset' }}>
        {backgroundImageUrl && (
          <div
            className={classes.backgroundImage}
            style={{ backgroundImage: `url(${backgroundImageUrl})`, height: `${backgroundHeight}px` }}
          />
        )}
        <SimpleBar forceVisible="x" autoHide={false}>
          <div className={`${classes.carousel} ${!!children || model?.contentList ? '' : 'loading'}`}>
            {children}
            {!children && model && (
              <>
                {model.contentList &&
                  model.contentList.map((content) => (
                    <div key={content[model.idField] || content} className={classes.thumbnailContainer}>
                      <div className={`${classes.thumbnail} ${displayAs}`}>{model.thumbnailComponent(content)}</div>
                    </div>
                  ))}
                {model.contentList && model.contentList.length === 0 && (
                  <div className={classes.thumbnailContainer}>
                    <div className={`${classes.thumbnail} ${classes.noContent} ${displayAs}`}>
                      {t('no_results.content')}
                    </div>
                  </div>
                )}
                {!model.contentList && (
                  <Repeat n={loaderCount}>
                    <div className={classes.thumbnailContainer}>
                      <Skeleton className={`${classes.thumbnail} ${displayAs}`} animation="wave" />
                    </div>
                  </Repeat>
                )}
              </>
            )}
          </div>
        </SimpleBar>
      </div>
    </Collapse>
  );
}
