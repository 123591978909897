import React from 'react';
import { LocalizedFieldBody } from '../../../API';
import { useLocales } from '../../../hooks';

type ReturnType = {
  prop: LocalizedFieldBody;
  className?: string;
  'data-testid'?: string;
};

export function Localized({ prop, 'data-testid': dataTestId = '', className }: ReturnType): React.ReactElement {
  const { localize } = useLocales();
  return (
    <span data-testid={dataTestId} className={className}>
      {localize(prop)}
    </span>
  );
}
