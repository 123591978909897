import { ValidatorForm } from 'react-material-ui-form-validator';

export const CustomValidators = {
  requiredIfDefined: 'requiredIfDefined',
  isSlugValid: 'isSlugValid',
  isAdsKeyValid: 'isAdsKeyValid',
  isPagePathValid: 'isPagePathValid',
  isValidUrl: 'isValidUrl'
};

export function addCustomValidators(): void {
  ValidatorForm.addValidationRule(CustomValidators.requiredIfDefined, (value: string) => {
    return value !== '';
  });

  ValidatorForm.addValidationRule(CustomValidators.isSlugValid, (value: string) => {
    if (value) {
      return /^[a-z0-9]+(?:[a-z0-9-]+)*$/g.test(value);
    }
    return true;
  });

  ValidatorForm.addValidationRule(CustomValidators.isAdsKeyValid, (value: string) => {
    if (value) {
      return /^[a-z0-9_]*$/gi.test(value);
    }
    return true;
  });

  ValidatorForm.addValidationRule(CustomValidators.isPagePathValid, (value: string) => {
    if (value) {
      const slug = '[a-z0-9]+(?:-[a-z0-9]+)*';
      const param = ':[a-zA-Z][a-zA-Z0-9]*';
      return new RegExp(`^${slug}(?:/(?:${slug}|${param}))*$`, 'g').test(value);
    }
    return true;
  });

  ValidatorForm.addValidationRule(CustomValidators.isValidUrl, (value: string) => {
    let url;
    try {
      url = new URL(value);
    } catch (_) {
      return false;
    }
    return (url.protocol === 'http:' || url.protocol === 'https:') && url.toString().startsWith(value);
  });
}
