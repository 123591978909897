import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Chip } from '@mui/material';
import Button from '../Button';
import { Add, Delete } from '@mui/icons-material';
import { DateTime } from 'luxon';
import IconButton from '../IconButton';
import { ScheduleTime } from '../../../API';
import { useLocales, useTheme } from '../../../hooks';
import { ScheduledTimeDays, testIds } from './scheduleTimeConstants';
import { ScheduleInputTime } from './ScheduleInputTime';

type ScheduleTimeProps = {
  className?: string;
  value: ScheduleTime[] | undefined;
  onChange: (changedValue: ScheduleTime[] | undefined) => void;
};

const useStyles = makeStyles()((theme) => ({
  days: {
    display: 'flex',
    gap: theme.spacing(1),
    '& .MuiChip-root': {
      width: 28,
      height: 28
    },
    '& .MuiChip-label': {
      padding: 0
    },
    '& .MuiChip-filled': {
      border: '1px solid transparent'
    }
  },
  fields: {
    display: 'flex',
    width: '100%',
    gap: theme.spacing(4),
    alignItems: 'center',
    marginBottom: theme.spacing(4)
  }
}));

export function ScheduleTimePicker({ className, value, onChange }: ScheduleTimeProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const handleAdd = () => {
    onChange([
      ...(value || []),
      {
        startTime: DateTime.now().startOf('day').toISO() || '',
        endTime: DateTime.now().endOf('day').toISO() || '',
        daysOfWeek: Array.from(Array(7).keys())
      }
    ]);
  };

  const handleRemove = (index: number) => {
    if (!value) return;
    onChange(value.slice(0, index).concat(value.slice(index + 1)));
  };

  const onStartTimeChange = (index: number, val: DateTime) => {
    if (!value) return;
    const newValue = [...value];
    newValue[index] = {
      ...newValue[index],
      startTime: val.toUTC().toISO() || ''
    };
    onChange(newValue);
  };

  const onEndTimeChange = (index: number, val: DateTime) => {
    if (!value) return;
    const newValue = [...value];
    newValue[index] = {
      ...newValue[index],
      endTime: val.toUTC().toISO() || ''
    };
    onChange(newValue);
  };

  const onDayToggle = (index: number, key: number) => {
    if (!value) return;
    const newValue = [...value];
    const newDaysOfWeek = newValue[index].daysOfWeek.includes(key)
      ? newValue[index].daysOfWeek.filter((day) => day !== key)
      : [...newValue[index].daysOfWeek, key];
    newValue[index] = {
      ...newValue[index],
      daysOfWeek: [...newDaysOfWeek]
    };
    onChange(newValue);
  };

  return (
    <div className={className} data-testid={testIds.root}>
      {value?.map((schedule, i) => (
        <div className={classes.fields} key={i} data-testid={testIds.keyValueRow}>
          <ScheduleInputTime
            index={i}
            label={t('scheduled_time.start_time')}
            value={schedule.startTime}
            onTimeChange={onStartTimeChange}
          />
          <ScheduleInputTime
            index={i}
            label={t('scheduled_time.end_time')}
            value={schedule.endTime}
            onTimeChange={onEndTimeChange}
          />
          <div className={classes.days}>
            {Object.keys(ScheduledTimeDays)
              .slice(0, 7)
              .map((key) => {
                return (
                  <Chip
                    key={key}
                    label={ScheduledTimeDays[Number(key)].slice(0, 1).toUpperCase()}
                    color={formControlColor}
                    variant={schedule.daysOfWeek.includes(Number(key)) ? 'filled' : 'outlined'}
                    onClick={() => onDayToggle(i, Number(key))}
                  />
                );
              })}
          </div>
          <div>
            <IconButton
              onClick={() => handleRemove(i)}
              title={t('scheduled_time.remove_schedule')}
              size="large"
              data-testid={testIds.removeScheduleButton}
            >
              <Delete />
            </IconButton>
          </div>
        </div>
      ))}
      <Button
        color={formControlColor}
        startIcon={<Add />}
        onClick={handleAdd}
        variant="text"
        data-testid={testIds.addScheduleButton}
      >
        {t('scheduled_time.add_schedule')}
      </Button>
    </div>
  );
}
