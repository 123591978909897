import { EntitlementType } from '../../API';
import { LocaleKeys } from '../../locales/i18n';
import { useLocales } from './useLocales';

type ReturnType = {
  localizeEntitlement: (entitlement: EntitlementType | string) => string;
};

export function useEntitlements(): ReturnType {
  const { t } = useLocales();

  const localizeEntitlement = (entitlement: EntitlementType | string): string => {
    return t(`entitlements.${entitlement.toLowerCase()}` as LocaleKeys) as string;
  };

  return { localizeEntitlement };
}
