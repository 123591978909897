/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// Tests whether a value belongs to an enum
import { COUNTRIES_BY_ID } from './countryCodes';

export const isValidEnumValue = (enumObject: Record<string, any>, value: any): boolean => {
  return Object.values(enumObject).includes(value);
};

export const validEnumFilter = (enumObject: Record<string, any>) => {
  // return a default value if the provided value is not part of the enum
  return (value: any, defaultValue: any) => {
    return isValidEnumValue(enumObject, value) ? value : defaultValue;
  };
};

export const validEnumArrayFilter = (enumObject: Record<string, any>, defaultIfEmpty: any[] = []) => {
  // filter out any values that are not part of the enum
  return (value: any) => {
    const filteredValues = (value as any[]).filter((v) => isValidEnumValue(enumObject, v));
    return filteredValues.length === 0 ? defaultIfEmpty : filteredValues;
  };
};

// Filter out any values that are not valid country codes
export const validCountryCodeFilter = (values: any) => {
  return (values as string[]).filter((countryCode) => !!COUNTRIES_BY_ID[countryCode]);
};
