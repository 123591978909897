import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useData } from '../../../../../data-layer';
import { DisplayAsOptions } from '../../../../../utils/types/genericTypes';
import AssetImage from '../../../../shared/AssetImage';
import { ContentPreview } from '../ContentPreview';
import { useLocales } from '../../../../../hooks';
import { ImageNotSupported } from '@mui/icons-material';
import { ContentCollectionResponse } from '../../../../../API';

const ASSET_SIZE = 96;

export const testIds = {
  preview: 'collection-list.preview',
  asset: 'collection-list-preview.asset',
  noImage: 'collection-list-preview.no-image'
};

const useStyles = makeStyles()((theme) => ({
  preview: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden'
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    aspectRatio: '16 / 9'
  },
  noImage: {
    height: ASSET_SIZE,
    aspectRatio: '16 / 9',
    background: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

type CollectionListPreviewProps = {
  showing: boolean;
  collectionIds: string[];
};

export function CollectionListPreview({ showing, collectionIds }: CollectionListPreviewProps): React.ReactElement {
  const {
    collections: {
      state: { withAllRecordsById },
      hook: { queueIdToFetch }
    }
  } = useData();

  const { localize } = useLocales();
  const { classes } = useStyles();
  const allCollections = useRecoilValue(withAllRecordsById);
  const collections = useMemo(
    () =>
      collectionIds.reduce<Record<string, ContentCollectionResponse | undefined>>((acc, value) => {
        if (!allCollections[value]) {
          queueIdToFetch(value);
        }
        return { ...acc, [value]: allCollections[value] };
      }, {}),
    [allCollections, collectionIds]
  );

  const NoImage = () => (
    <div className={classes.noImage} data-testid={testIds.noImage}>
      <ImageNotSupported />
    </div>
  );

  const thumbnailComponent = (collectionId: string) => {
    const collection = collections[collectionId];
    return (
      <Tooltip title={localize(collection?.title)} arrow>
        <div className={classes.preview} data-testid={testIds.preview}>
          {collection?.cardLandscapeImage && (
            <AssetImage
              path={collection?.cardLandscapeImage}
              size={ASSET_SIZE}
              className={classes.image}
              data-testid={testIds.asset}
            />
          )}
          {!collection?.cardLandscapeImage && <NoImage />}
        </div>
      </Tooltip>
    );
  };

  return (
    <ContentPreview
      displayAs={DisplayAsOptions.LIST}
      showing={showing}
      model={{ contentList: collectionIds, thumbnailComponent, idField: 'entityId' }}
    />
  );
}
