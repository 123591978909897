import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useInterval, useLocales, useTimezones } from '../../../hooks';
import { withGlobalTimeDiff } from '../../../state/Timezone';
import { getSecondsFormatted, largeNumber } from '../../../utils/dateHelpers';

export interface ILiveTimestampProps {
  className?: string;
  'data-testid'?: string;
  dateString: string;
}

const useStyles = makeStyles()(() => ({
  timestamp: {
    height: 19
  },
  serious: {
    fontWeight: 'bold',
    animation: 'pulse-orange 2s linear infinite'
  }
}));

// thresholds in milliseconds
const countingThreshold = 300000;
const seriousCountingThreshold = 10000;

export function LiveTimestamp({ dateString, ...props }: ILiveTimestampProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { timestamp } = useTimezones();
  const globalTimeDiff = useRecoilValue(withGlobalTimeDiff);
  const liveDate = DateTime.fromISO(dateString);
  const [liveIn, setLiveIn] = useState<number>();
  const [intervalTime, setIntervalTime] = useState<number>(largeNumber);

  useEffect(() => {
    check();
  }, [dateString]);

  const check = () => {
    const diff = getDiff();
    if (diff < 0) {
      setIntervalTime(largeNumber);
      setLiveIn(undefined);
    } else if (diff < countingThreshold) {
      setIntervalTime(1000);
      setLiveIn(diff);
    } else {
      setIntervalTime(diff - countingThreshold);
      setLiveIn(undefined);
    }
    return diff;
  };

  useInterval(check, intervalTime);

  const getDiff = () => liveDate.toMillis() - DateTime.now().toMillis() + globalTimeDiff;

  const getLiveTranslationKey = () => {
    const diff = getDiff();
    if (diff < 0) {
      return 'layouts.went_live_on';
    }
    return 'layouts.goes_live_on';
  };

  return (
    <div className={`${classes.timestamp} ${props.className}`}>
      <Typography variant="body2" color="textSecondary" data-testid={props['data-testid']}>
        {!liveIn && t(getLiveTranslationKey(), { date: timestamp(dateString) })}
        {liveIn && (
          <>
            {t('layouts.goes_live_in')}{' '}
            <span className={liveIn < seriousCountingThreshold ? classes.serious : ''}>
              {getSecondsFormatted(liveIn)}
            </span>
          </>
        )}
      </Typography>
    </div>
  );
}
