import { DateTime } from 'luxon';
import React, { ReactElement } from 'react';
import { useTheme, useTimezones } from '../../../hooks';
import { TimePicker } from '@mui/x-date-pickers';
import { parseValueToDateTime } from './scheduleTimeHelpers';
import { DateTimeTz } from '../DateTime/DateTimeTz';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  containerInputTime: {
    display: 'flex',
    flexDirection: 'column'
  },
  dateTimeTz: {
    marginTop: theme.spacing(1)
  },
  inputTime: {
    '&&.MuiTextField-root': {
      marginBottom: theme.spacing(0)
    }
  }
}));

export function ScheduleInputTime({
  index,
  label,
  value,
  onTimeChange
}: {
  index: number;
  label: string;
  value?: string;
  onTimeChange: (index: number, val: DateTime) => void;
}): ReactElement {
  const { classes } = useStyles();
  const { formControlColor } = useTheme();
  const { getTimezonesList, currentTimezone } = useTimezones();

  return (
    <div className={classes.containerInputTime}>
      <TimePicker
        timezone={currentTimezone.label}
        className={classes.inputTime}
        label={label}
        value={parseValueToDateTime(value)}
        onChange={(val) => onTimeChange(index, val as unknown as DateTime)}
        slotProps={{ textField: { color: formControlColor } }}
      />
      {value && (
        <DateTimeTz
          className={classes.dateTimeTz}
          size={'small'}
          time={(parseValueToDateTime(value) as DateTime).toJSDate()}
          timezones={getTimezonesList()}
          disabled
        />
      )}
    </div>
  );
}
