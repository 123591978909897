import React from 'react';
import { GlobalStyles } from '@mui/material';
import { themeColors } from './theme';

export const VixGlobalStyles = (): React.ReactElement => (
  <GlobalStyles
    styles={(theme) => ({
      ':root': {
        '--focus-border': `${theme.palette.secondary.main} !important`
      },
      '::-webkit-scrollbar': {
        width: 11,
        height: 11
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: themeColors[theme.palette.mode].scrollbar.track,
        boxShadow: `0 0 1px ${themeColors[theme.palette.mode].scrollbar.shadow} inset`
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: themeColors[theme.palette.mode].scrollbar.thumb,
        borderRadius: 6,
        boxShadow: `0 0 1px ${themeColors[theme.palette.mode].scrollbar.shadow} inset`,
        boxSizing: 'border-box',
        border: 'none !important'
      },
      '.simplebar-scrollbar::before': {
        backgroundColor: `${themeColors[theme.palette.mode].scrollbar.simpleBar} !important`
      },
      '.sortableGhost': {
        opacity: 0.2
      },
      '.sortableDragDefault': {
        backgroundColor: theme.palette.background.default
      },
      '.sortableDragPaper': {
        backgroundColor: theme.palette.background.paper
      },
      '.sortable-chosen .MuiTooltip-popper': {
        display: 'none'
      },
      '.MuiTooltip-tooltip': {
        minWidth: 'fit-content'
      },
      '.MuiSkeleton-text': {
        transform: 'scale(1) !important'
      },
      '.MuiFormHelperText-root.Mui-error': {
        position: 'absolute',
        bottom: -parseInt(theme.spacing(4)),
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        left: 0,
        right: 0
      },
      '.MuiFormControl-root.MuiTextField-root': {
        marginBottom: theme.spacing(4)
      },
      '.MuiFormLabel-root.MuiFormLabel-filled': {
        transform: 'translate(0, -1.5px) scale(0.75)'
      },
      '@keyframes pulse-orange': {
        '0%': {
          color: theme.palette.text.primary
        },
        '50%': {
          color: themeColors.orange
        },
        '100%': {
          color: theme.palette.text.primary
        }
      }
    })}
  />
);
