import { DateTime } from 'luxon';
import { ScheduleTime } from '../../../API';

export const parseValueToDateTime = (val?: string): DateTime | string | undefined => {
  if (!val) return val;
  const isoTime = DateTime.fromISO(val);
  return DateTime.now().set({
    hour: isoTime.hour,
    minute: isoTime.minute,
    second: isoTime.second
  });
};

export const parseValueToTime = (val?: string): string | undefined => {
  return !val ? val : (parseValueToDateTime(val) as DateTime)?.toFormat('hh:mm a');
};

export function isInScheduledTime(schedules?: ScheduleTime[]): boolean {
  if (!schedules || schedules.length === 0) return true;
  const localNow = parseValueToDateTime(DateTime.now().toISO() as string) as DateTime;
  const localNowTime = localNow.toFormat('T'); // Extracts the time as 'HH:mm:ss.sss'
  const localNowDay = localNow.weekday; // Gets the day of the week (1 = Monday, 7 = Sunday)

  return schedules.some((schedule) => {
    // Check if the current day of the week is in the schedule's daysOfWeek
    if (schedule.daysOfWeek.includes(localNowDay)) {
      const startLocalTime = (parseValueToDateTime(schedule.startTime) as DateTime)?.toFormat('T');
      const endLocalTime = (parseValueToDateTime(schedule.endTime) as DateTime)?.toFormat('T');
      // Check if the current local time is between the start and end times of the schedule
      return localNowTime >= startLocalTime && localNowTime <= endLocalTime;
    }
    return false;
  });
}

export function getActiveEndTime(schedules?: ScheduleTime[]): string {
  if (!schedules || schedules.length === 0) return '';
  const localNow = parseValueToDateTime(DateTime.now().toISO() as string) as DateTime;
  const localNowTime = localNow.toFormat('T'); // Extracts the time as 'HH:mm:ss.sss'
  const localNowDay = localNow.weekday; // Gets the day of the week (1 = Monday, 7 = Sunday)

  const activeSchedule = schedules.find((schedule) => {
    // Check if the current day of the week is in the schedule's daysOfWeek
    if (schedule.daysOfWeek.includes(localNowDay)) {
      const startLocalTime = (parseValueToDateTime(schedule.startTime) as DateTime)?.toFormat('T');
      const endLocalTime = (parseValueToDateTime(schedule.endTime) as DateTime)?.toFormat('T');
      // Check if the current local time is between the start and end times of the schedule
      return localNowTime >= startLocalTime && localNowTime <= endLocalTime;
    }
    return false;
  });

  if (activeSchedule) {
    return (parseValueToDateTime(activeSchedule.endTime) as DateTime)?.toFormat('hh:mm a');
  }
  return '';
}
