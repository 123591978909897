import {
  ContentCollectionBody,
  ContentCollectionResponse,
  ContentCollectionSortingType,
  ContentCollectionType,
  DocumentLocale,
  EntitlementType
} from '../../../API';
import { useCollectionsAPI } from '../../../hooks/API/Collections/useCollectionsAPI';
import generateId from '../../../utils/generateId';
import { CreateDataManager, DataManagerReturnType } from '../../DataManager';
import { CollectionsHook, CollectionsHookReturnType } from './CollectionsHook';
import { CollectionsState, CollectionsStateFullReturnType } from './CollectionsState';

export type CollectionsDataManagerType = {
  state: CollectionsStateFullReturnType;
  hook: CollectionsHookReturnType;
};

export interface CollectionsDataManagerReturnType
  extends DataManagerReturnType<ContentCollectionResponse, ContentCollectionBody> {
  state: CollectionsStateFullReturnType;
  hook: () => CollectionsHookReturnType;
}

export const COLLECTION_PREFIX = 'collection:cms:';

const generateNewCollection = (): ContentCollectionBody => ({
  contents: [],
  collectionType: ContentCollectionType.VIDEO_LIST,
  title: {
    [DocumentLocale.ES]: ''
  },
  description: {
    [DocumentLocale.ES]: ''
  },
  logo: {
    [DocumentLocale.ES]: ''
  },
  editorNotes: '',
  sortingType: ContentCollectionSortingType.CURATED_FIRST,
  entitlements: [EntitlementType.ANON, EntitlementType.FREE],
  pinnedContents: [],
  kids: false
});

const generateCollectionEntityId = (): string => {
  return `${COLLECTION_PREFIX}${generateId()}`;
};

export const CollectionsDataManager = CreateDataManager<
  ContentCollectionResponse,
  ContentCollectionBody,
  CollectionsDataManagerReturnType
>({
  name: 'collections',
  idField: 'entityId',
  isVersionedEntity: true,
  useApiHook: useCollectionsAPI,
  generateNew: generateNewCollection,
  useUpdateForCreate: true,
  stateInitializer: CollectionsState,
  hookInitializer: CollectionsHook,
  toString: (record, locales) => locales.localize(record.title),
  generateEntityId: generateCollectionEntityId
});
