import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useResponsive } from '../../../hooks';
import { makeStyles } from 'tss-react/mui';

export interface IVerticalAccordionProps {
  selectedIndex: number;
  setSelectedIndex: (value: number) => void;
  sections: VerticalAccordionSection[];
  headerHeight?: number;
}

export type VerticalAccordionSection = {
  header: React.ReactElement;
  body: React.ReactElement;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'relative',
    height: '100%'
  },
  section: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  animate: {
    transition: 'transform 150ms ease-in-out'
  },
  body: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1
  },
  header: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderLeft: 'none',
    borderRight: 'none',
    cursor: 'pointer'
  }
}));

export function VerticalAccordion({
  selectedIndex,
  setSelectedIndex,
  sections,
  headerHeight = 40
}: IVerticalAccordionProps): React.ReactElement {
  const { classes, cx } = useStyles();
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const { ref: container, height: containerHeight } = useResponsive();

  useEffect(() => {
    setShouldAnimate(containerHeight > 0);
  }, [containerHeight]);

  const sectionHeight = containerHeight > 0 ? containerHeight - headerHeight * (sections.length - 1) : headerHeight;

  const getYOffset = (index: number) => {
    return index * (headerHeight - 1) + (selectedIndex < index ? sectionHeight - headerHeight + 1 : 0);
  };

  return (
    <Box className={classes.container} ref={container}>
      {sections.map((section, i) => (
        <Box
          key={i}
          className={cx(classes.section, { [classes.animate]: shouldAnimate })}
          sx={{ height: `${sectionHeight}px`, transform: `translateY(${getYOffset(i)}px)` }}
        >
          <Box className={classes.header} sx={{ height: `${headerHeight}px` }} onClick={() => setSelectedIndex(i)}>
            {section.header}
          </Box>
          <Box className={classes.body}>{section.body}</Box>
        </Box>
      ))}
    </Box>
  );
}
