import React, { useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MenuItem, Popover, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../hooks';
import { AddOutlined, MoreVertOutlined } from '@mui/icons-material';
import {
  withAllLayouts,
  withLayoutsType,
  withLayoutsTypeLowercase,
  withNewLayout,
  withShowLayoutForm,
  withLayoutSplitPane
} from '../../state/Layouts';
import LayoutView from './LayoutView';
import LayoutForm from './LayoutForm';
import UIModuleForm from './UIModuleForm';
import Button from '../shared/Button';
import IconButton from '../shared/IconButton';
import PageForm from './PageForm';
import LayoutWarningsModal from './LayoutWarningsModal';
import { LayoutsPageSelector } from './LayoutsPageSelector';
import { usePermissions } from '../../hooks/Permissions/usePermissions';
import { useSplitPane } from '../../hooks/General/useSplitPane';
import { Allotment } from 'allotment';
import { LayoutsExportModal } from './LayoutsExportModal';
import { useData } from '../../data-layer';
import { LayoutsRouter } from './LayoutsRouter';
import { LayoutsCountryFilter } from './LayoutsCountryFilter';
import { LayoutsTypeSelector } from './LayoutTypeSelector';
import { LayoutsViewTabs } from './LayoutsViewTabs';
import { usePermissionsGuard } from '../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../state/theme';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  leftColumn: {
    background: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  layoutTypeSelect: {
    maxWidth: 120
  },
  topPanel: {
    padding: theme.spacing(4, 0, 1),
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  topLine: {
    display: 'flex',
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  title: {
    marginBlockStart: 10,
    marginRight: theme.spacing(4)
  },
  pageSelect: {
    width: '100%',
    display: 'inline-block'
  },
  countryPicker: {
    margin: theme.spacing(0, 2, 0, 4)
  },
  viewTabs: {
    marginTop: theme.spacing(3)
  },
  bottomPanel: {
    padding: theme.spacing(0, 4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: theme.spacing(8),
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

export const testIds = {
  componentRoot: 'layouts.root',
  newLayoutButton: 'layouts.new-layout-button',
  newPageButton: 'layouts.new-page-button',
  pageMenuButton: 'layouts.page-menu-button',
  menuSelector: 'layouts.menu-selector',
  menuSelectorItem: 'layouts.menu-selector-item',
  layoutsTypeSelector: 'layouts.type-selector',
  layoutsDeletePopUp: 'layouts.delete-confirm-popup'
};

function Layouts(): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const newLayout = useSetRecoilState(withNewLayout);
  const allLayouts = useRecoilValue(withAllLayouts);
  const showingLayoutForm = useRecoilValue(withShowLayoutForm);
  const layoutsType = useRecoilValue(withLayoutsType);
  const layoutsTypeLowercase = useRecoilValue(withLayoutsTypeLowercase);
  const [pageMenuAnchorEl, setPageMenuAnchorEl] = useState<null | HTMLElement>(null);
  const { hasUpsertPermission } = usePermissions();
  const { canSave } = usePermissionsGuard({
    homepageOption: HomepageOptions.LAYOUTS
  });
  const { sizes, onChange: onSplitPaneChange } = useSplitPane(withLayoutSplitPane);
  const [csvModalOpen, setCsvModalOpen] = useState(false);
  const buttonRef = useRef<HTMLLIElement>(null);

  const {
    pages: {
      state: { withRecordBucket: withPagesByType, withSelected: withSelectedPage },
      hook: { new: setNewPage }
    }
  } = useData();
  const pages = useRecoilValue(withPagesByType(layoutsType));
  const selectedPage = useRecoilValue(withSelectedPage);
  const hasLayoutUpsertPermission = hasUpsertPermission(selectedPage?.ownerPermissionsGroup);

  const openPageMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPageMenuAnchorEl(event.currentTarget);
  };

  const closePageMenu = () => {
    setPageMenuAnchorEl(null);
  };

  const newPage = () => {
    closePageMenu();
    setNewPage();
  };

  const onDownload = () => {
    closePageMenu();
    setCsvModalOpen(true);
  };

  const handleNewLayoutClick = () => {
    if (!selectedPage) return;
    newLayout(selectedPage);
  };

  const noPageSelected = pages && !selectedPage;

  return (
    <>
      <LayoutsRouter />
      <Allotment
        defaultSizes={sizes}
        onChange={onSplitPaneChange}
        data-testid={testIds.componentRoot}
        className={classes.root}
      >
        <Allotment.Pane minSize={420} preferredSize={555} maxSize={800}>
          <div className={classes.leftColumn}>
            <div className={classes.topPanel}>
              <div className={classes.topLine}>
                <Typography className={classes.title} variant="h5">
                  {t('layouts.layouts')}
                </Typography>
                <LayoutsTypeSelector className={classes.layoutTypeSelect} data-testid={testIds.layoutsTypeSelector} />
                <LayoutsPageSelector className={classes.pageSelect} />
                <IconButton
                  onClick={openPageMenu}
                  disabled={!pages}
                  data-testid={testIds.pageMenuButton}
                  size="large"
                  title={t(`layouts.${layoutsTypeLowercase}_actions`)}
                >
                  <MoreVertOutlined />
                </IconButton>
                <Popover
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  anchorEl={pageMenuAnchorEl}
                  keepMounted
                  open={Boolean(pageMenuAnchorEl)}
                  onClose={closePageMenu}
                >
                  <MenuItem
                    onClick={newPage}
                    data-testid={testIds.newPageButton}
                    disabled={!hasLayoutUpsertPermission || !canSave}
                  >
                    {t(`layouts.create_new_${layoutsTypeLowercase}`)}
                  </MenuItem>
                  <MenuItem ref={buttonRef} onClick={onDownload}>
                    {t('layouts.download_csv')}
                  </MenuItem>
                </Popover>
              </div>
              <LayoutsCountryFilter className={classes.countryPicker} />
            </div>
            {!noPageSelected && (
              <>
                <LayoutsViewTabs className={classes.viewTabs} />
                <div className={classes.bottomPanel}>
                  <div></div>
                  <div>
                    <Button
                      endIcon={<AddOutlined />}
                      disabled={!allLayouts || showingLayoutForm || !hasLayoutUpsertPermission || !canSave}
                      onClick={handleNewLayoutClick}
                      data-testid={testIds.newLayoutButton}
                    >
                      {t(`layouts.new_${layoutsTypeLowercase}_layout`)}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </Allotment.Pane>
        <Allotment.Pane>
          <LayoutView />
        </Allotment.Pane>
      </Allotment>
      <PageForm />
      <LayoutForm />
      <UIModuleForm hasUpsertPermission={hasLayoutUpsertPermission && canSave} />
      <LayoutWarningsModal />
      <LayoutsExportModal open={csvModalOpen} onClose={() => setCsvModalOpen(false)} />
    </>
  );
}

export default Layouts;
