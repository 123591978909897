import React from 'react';
import { cloneDeep, intersection } from 'lodash-es';
import { makeStyles, withStyles } from 'tss-react/mui';
import { emphasize } from '@mui/system';
import { Chip, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLocales, useTheme } from '../../../hooks';
import {
  SmartQuerySortingDirection,
  SmartQuerySortingSymbol,
  SmartQueryBody,
  SmartQuerySortingItemBody
} from '../../../API';
import { Add, DeleteOutline, DragHandle, Sort, SortByAlpha } from '@mui/icons-material';
import Button from '../../shared/Button';
import IconButton from '../../shared/IconButton';
import { Sortable } from '../../shared/Sortable';
import FoldableSection from '../../shared/FoldableSection';
import { useData } from '../../../data-layer';
import { isEntityDeleted } from '../../../utils/generalUtils';

const useStyles = makeStyles()((theme) => ({
  querySortingContainer: {
    paddingRight: '20px'
  },
  accordionContainer: {
    display: 'flex',
    gap: theme.spacing(8),
    flexDirection: 'row',
    padding: theme.spacing(2)
  },
  currentSortings: {
    flex: 2
  },
  availableSortings: {
    flex: 1
  },
  sortingsContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column'
  },
  sectionTitle: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2)
  },
  sortingItem: {
    backgroundColor: emphasize(theme.palette.background.paper, 0.1),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(4)
  },
  dragHandle: {
    cursor: 'grab'
  },
  sortingItemText: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  sortingItemActions: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center'
  },
  enableSortingButton: {
    backgroundColor: emphasize(theme.palette.background.paper, 0.1),
    color: theme.palette.getContrastText(emphasize(theme.palette.background.paper, 0.1)),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'none',
    fontWeight: 'normal',
    '&:hover': {
      backgroundColor: emphasize(theme.palette.background.paper, 0.2)
    }
  },
  toggleButtonContent: {
    padding: theme.spacing(1)
  },
  blankState: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    height: '100%'
  }
}));

const StyledToggleButtonGroup = withStyles(ToggleButtonGroup, (theme) => ({
  root: {
    background: theme.palette.background.default,
    '& .MuiToggleButtonGroup-grouped': {
      margin: theme.spacing(1),
      border: 0,
      lineHeight: 1,
      padding: 0,
      '&.Mui-disabled': {
        border: 0
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      },
      '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius
      },
      '&:first-of-type': {
        borderRadius: theme.shape.borderRadius
      }
    }
  }
}));

export const testIds = {
  container: 'collection-query-sortings-editor.container',
  sortingField: (field: string): string => `collection-query-sortings-editor.sorting-field-${field}`,
  enableSortingButton: (field: string): string => `collection-query-sortings-editor.enable-sorting-button-${field}`,
  disableSortingButton: (field: string): string => `collection-query-sortings-editor.disable-sorting-button-${field}`,
  ascButton: (field: string): string => `collection-query-sortings-editor.asc-button-${field}`,
  descButton: (field: string): string => `collection-query-sortings-editor.desc-button-${field}`,
  blankState: 'collection-query-sortings-editor.empty-state',
  currentSortingsList: 'collection-query-sortings-editor.current-sortings-list',
  availableSortingsList: 'collection-query-sortings-editor.available-sortings-list'
};

function CollectionQuerySortingsEditor(): JSX.Element {
  const {
    collections: {
      state: { withCollectionQuerySortings, withCollectionContentTypes, withSelectedCollectionQuery, withSelected }
    }
  } = useData();

  const { classes } = useStyles();
  const { t, localize } = useLocales();
  const { formControlColor } = useTheme();

  const collection = useRecoilValue(withSelected);
  const querySortings = useRecoilValue(withCollectionQuerySortings);
  const [collectionQuery, setCollectionQuery] = useRecoilState(withSelectedCollectionQuery);
  const collectionContentTypes = useRecoilValue(withCollectionContentTypes);
  const isDeleted = isEntityDeleted(collection);

  const availableSortings = querySortings.filter(({ symbol, contentTypes }) => {
    const availableForContentType =
      !contentTypes ||
      !collectionContentTypes ||
      !contentTypes.length ||
      !!intersection(contentTypes, collectionContentTypes).length;

    const alreadyUsed = collectionQuery?.sortings?.some(({ field }) => field === symbol);
    return availableForContentType && !alreadyUsed;
  });

  const handleEnableSorting = (fieldToAdd: SmartQuerySortingSymbol) => {
    if (!collectionQuery) return;
    const newCollectionQuery = cloneDeep(collectionQuery) as SmartQueryBody;
    newCollectionQuery.sortings.push({ field: fieldToAdd, direction: SmartQuerySortingDirection.ASC });
    setCollectionQuery(newCollectionQuery);
  };

  const handleDisableSorting = (fieldToRemove: string) => {
    if (!collectionQuery) return;
    const newCollectionQuery = cloneDeep({
      ...collectionQuery,
      sortings: collectionQuery.sortings.filter(({ field }) => field !== fieldToRemove)
    }) as SmartQueryBody;
    setCollectionQuery(newCollectionQuery);
  };

  const handleChangeDirection = (fieldToEdit: string, newDirection: SmartQuerySortingDirection) => {
    if (!collectionQuery) return;
    const newCollectionQuery = cloneDeep(collectionQuery) as SmartQueryBody;
    const sortingToEdit = newCollectionQuery.sortings.find(({ field }) => field === fieldToEdit);
    if (sortingToEdit) {
      sortingToEdit.direction = newDirection;
      setCollectionQuery(newCollectionQuery);
    }
  };

  const handleSortSortings = (list: SmartQuerySortingItemBody[]) => {
    if (!collectionQuery) return;
    const newCollectionQuery = cloneDeep(collectionQuery) as SmartQueryBody;
    newCollectionQuery.sortings = list;
    setCollectionQuery(newCollectionQuery);
  };

  // This prevents from the page from crashing, but we have additional checks which should prevent this from happening.
  if (!collectionQuery || !collectionQuery.conditions) {
    return <></>;
  }

  return (
    <div data-testid={testIds.container} className={classes.querySortingContainer}>
      <FoldableSection
        containerClassName={classes.accordionContainer}
        summary={
          <>
            <Sort />
            {t('query_builder.edit_results_sorting')}
            {!!collectionQuery?.sortings.length && (
              <Chip color={formControlColor} label={collectionQuery.sortings.length} size="small" />
            )}
          </>
        }
      >
        <div className={classes.currentSortings}>
          {!!collectionQuery?.sortings.length && (
            <>
              <div className={classes.sectionTitle}>{t('query_builder.sort_results_by')}</div>
              <div data-testid={testIds.currentSortingsList}>
                <Sortable
                  className={classes.sortingsContainer}
                  list={collectionQuery?.sortings}
                  animation={100}
                  setList={handleSortSortings}
                  disabled={isDeleted}
                >
                  {collectionQuery?.sortings.map(({ field, direction }) => {
                    const sortingName = querySortings.find(({ symbol }) => symbol === field)?.name;
                    return (
                      <div className={classes.sortingItem} key={field} data-testid={testIds.sortingField(field)}>
                        <div className={classes.sortingItemText}>
                          <DragHandle className={classes.dragHandle} />
                          {localize(sortingName)}
                        </div>
                        <div className={classes.sortingItemActions}>
                          <StyledToggleButtonGroup
                            value={direction}
                            exclusive
                            size="small"
                            onChange={(_, newDirection) =>
                              handleChangeDirection(field, newDirection as SmartQuerySortingDirection)
                            }
                            disabled={isDeleted}
                          >
                            <ToggleButton
                              value={SmartQuerySortingDirection.ASC}
                              aria-label={t('general.ascending_order')}
                              data-testid={testIds.ascButton(field)}
                            >
                              <div className={classes.toggleButtonContent}>{t('general.ascending_order')}</div>
                            </ToggleButton>
                            <ToggleButton
                              value={SmartQuerySortingDirection.DESC}
                              aria-label={t('general.descending_order')}
                              data-testid={testIds.descButton(field)}
                            >
                              <div className={classes.toggleButtonContent}>{t('general.descending_order')}</div>
                            </ToggleButton>
                          </StyledToggleButtonGroup>
                          <IconButton
                            onClick={() => handleDisableSorting(field)}
                            title={t('query_builder.disable_sorting')}
                            color="error"
                            data-testid={testIds.disableSortingButton(field)}
                            disabled={isDeleted}
                          >
                            <DeleteOutline fontSize="small" />
                          </IconButton>
                        </div>
                      </div>
                    );
                  })}
                </Sortable>
              </div>
            </>
          )}
          {!collectionQuery?.sortings.length && (
            <div className={classes.blankState} data-testid={testIds.blankState}>
              <SortByAlpha style={{ fontSize: 40 }} />
              <Typography variant="body1">{t('query_builder.sortings_blank_state_header')}</Typography>
              <Typography variant="body2">{t('query_builder.sortings_blank_state_text')}</Typography>
            </div>
          )}
        </div>
        <div className={classes.availableSortings} data-testid={testIds.availableSortingsList}>
          {!!availableSortings.length && (
            <>
              <div className={classes.sectionTitle}>{t('query_builder.available_sorting_fields')}</div>
              <div className={classes.sortingsContainer}>
                {availableSortings.map((sorting) => (
                  <Button
                    className={classes.enableSortingButton}
                    onClick={() => handleEnableSorting(sorting.symbol)}
                    variant="contained"
                    color="grey"
                    data-testid={testIds.enableSortingButton(sorting.symbol)}
                    key={sorting.symbol}
                    endIcon={<Add fontSize="small" />}
                    disableElevation
                    size="small"
                    disabled={isDeleted}
                  >
                    {localize(sorting.name)}
                  </Button>
                ))}
              </div>
            </>
          )}
        </div>
      </FoldableSection>
    </div>
  );
}

export default CollectionQuerySortingsEditor;
