import React from 'react';
import { Badge, badgeClasses, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AppRegistration, Edit, FileCopy } from '@mui/icons-material';
import Localized from '../../shared/Localized';
import IconButton from '../../shared/IconButton';
import { useData } from '../../../data-layer';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../Routes';
import { VersionedDocumentStatus } from '../../../API';
import DraftBadge from '../../shared/DraftBadge';
import { Stack } from '@mui/system';
import { useLocales } from '../../../hooks';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import { PermissionsGroupBadge } from '../../shared/PermissionsGroupBadge';
import { PermissionsLevel } from '../../../utils/types/permissionsTypes';
import { stopPropagation } from '../../../utils/generalUtils';

export const testIds = {
  root: 'hpc-list-item.root',
  editContentsButton: 'hpc-list-item.edit-contents-button',
  editMetadataButton: 'hpc-list-item.edit-metadata-button',
  itemButton: 'hpc-list-item.item-button',
  cloneButton: 'hpc-list-item.clone-button',
  name: 'hpc-list-item.name',
  draftBadge: 'hpc-list-item.draft-badge',
  contentsBubble: 'hpc-list-item.contents-bubble'
};

const useStyles = makeStyles()((theme) => ({
  listItem: {
    padding: 0,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  badge: {
    [`& .${badgeClasses.badge}`]: {
      pointerEvents: 'none',
      height: 15,
      lineHeight: '100%',
      padding: '0 3px',
      fontSize: 11,
      top: 6,
      right: 7,
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
    }
  }
}));

type HPCListItemProps = {
  collectionId: string;
  onPick?: (id: string) => unknown;
};

export function HPCListItem({ collectionId, onPick }: HPCListItemProps): React.ReactElement {
  const {
    heroPresetCollections: {
      state: { withRecordById },
      hook: { clone, edit }
    }
  } = useData();

  const { hasPermissions, hasAnyPermission } = usePermissions();
  const { t } = useLocales();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const collection = useRecoilValue(withRecordById(collectionId));

  const { READ: canRead, UPSERT: canUpsert } = hasPermissions(collection?.ownerPermissionsGroup);
  const canClone = hasAnyPermission(PermissionsLevel.UPSERT);

  if (!collection) {
    return <></>;
  }

  const handleClick = () => {
    if (onPick) {
      onPick(collectionId);
      return;
    }
    if (canRead) {
      navigate(AppRoutes.heroPresetCollections(collectionId));
    }
  };

  const handleEditMetadata = () => {
    if (canRead) {
      edit(collectionId);
    }
  };

  const handleClone = () => {
    if (canClone) {
      clone(collectionId);
    }
  };

  const collectionLabel = (
    <Stack spacing={2} direction="row" alignItems="center">
      <Localized prop={collection.name} data-testid={testIds.name} className={classes.name} />
      {collection.status === VersionedDocumentStatus.DRAFT && <DraftBadge data-testid={testIds.draftBadge} />}
      {collection.ownerPermissionsGroup && <PermissionsGroupBadge value={collection.ownerPermissionsGroup} />}
    </Stack>
  );

  return (
    <ListItem data-testid={testIds.root} className={classes.listItem} disablePadding>
      <ListItemButton
        disabled={!canRead}
        onClick={handleClick}
        data-collection-id={collectionId}
        data-testid={testIds.itemButton}
        sx={{ gap: 2 }}
      >
        <ListItemText primary={collectionLabel} />
        <Stack gap={1} alignItems="center" direction="row" visibility={onPick && 'hidden'}>
          <Badge
            className={classes.badge}
            color="success"
            badgeContent={collection.presets.length}
            max={999}
            data-testid={testIds.contentsBubble}
          >
            <IconButton
              title={t('general.edit_contents')}
              disabled={!canRead || !canUpsert}
              onClick={stopPropagation(handleClick)}
              data-testid={testIds.editContentsButton}
            >
              <AppRegistration fontSize="small" />
            </IconButton>
          </Badge>
          <IconButton
            title={t('general.edit_metadata')}
            disabled={!canRead || !canUpsert}
            onClick={stopPropagation(handleEditMetadata)}
            data-testid={testIds.editMetadataButton}
          >
            <Edit fontSize="small" />
          </IconButton>
          <IconButton
            title={t('general.clone')}
            disabled={!canClone}
            onClick={stopPropagation(handleClone)}
            data-testid={testIds.cloneButton}
          >
            <FileCopy fontSize="small" />
          </IconButton>
        </Stack>
      </ListItemButton>
    </ListItem>
  );
}
