import React, { forwardRef } from 'react';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { LinkingAccountUiModuleBody } from '../../../../../API';
import { FormBody, FormField, SectionHeader } from '../../styles';
import { useLocales } from '../../../../../hooks';
import { Controller, useFormContext } from 'react-hook-form';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { LocaleKeys } from '../../../../../locales/i18n';

export const testIds = {
  formBody: 'linking-account.form-body',
  formField: (name: keyof LinkingAccountUiModuleBody): string => `linking-account.form-field.${name}`
};

type LinkingAccountFieldProps = {
  name: keyof LinkingAccountUiModuleBody;
  required?: boolean;
  'data-testid'?: string;
};

const LinkingAccountForm = forwardRef<UIModuleTypeForm<LinkingAccountUiModuleBody>>(() => {
  const { control, watch } = useFormContext<LinkingAccountUiModuleBody>();
  const { t } = useLocales();

  const moduleId = watch('moduleId');

  const AssetPicker = ({
    name,
    assetType,
    required = false
  }: LinkingAccountFieldProps & { assetType: AssetTypesEnum }): React.ReactElement => (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormField>
          <AssetBrowser
            required={required}
            label={t(`layouts.linking_account.fields.${name}`)}
            assetId={moduleId || ''}
            assetType={assetType}
            value={value as string}
            data-testid={testIds.formField(name)}
            hasError={!!error}
            onChange={onChange}
          />
        </FormField>
      )}
    />
  );

  const LokaliseField = (name: keyof LinkingAccountUiModuleBody, required = false): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.linking_account.fields.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  return (
    <FormBody data-testid={testIds.formBody}>
      <div>
        <SectionHeader>{t('layouts.linking_account.headers.images')}</SectionHeader>
        <AssetPicker name="vixLogo" assetType={AssetTypes.linkingAccount.vixLogo} required />
        <AssetPicker name="partnerLogo" assetType={AssetTypes.linkingAccount.partnerLogo} required />
        <AssetPicker name="background" assetType={AssetTypes.linkingAccount.background} required />
      </div>
      <div>
        <SectionHeader>{`${t('layouts.linking_account.headers.linking_account')} - ${t(
          'layouts.linking_account.headers.registered_users'
        )}`}</SectionHeader>
        {LokaliseField('avodHeader')}
        {LokaliseField('avodSubHeader')}
        {LokaliseField('createAccountCta')}
        {LokaliseField('signInCta')}
        {LokaliseField('linkExistingAccountCta')}
        {LokaliseField('createNewAccountCta')}
      </div>
      <div>
        <SectionHeader>{`${t('layouts.linking_account.headers.linking_account')} - ${t(
          'layouts.linking_account.headers.logged_in'
        )}`}</SectionHeader>
        {LokaliseField('loggedInHeader')}
        {LokaliseField('loggedInSubHeader')}
        {LokaliseField('loggedInLinkingCta')}
        {LokaliseField('loggedInCreateNewAccountCta')}
      </div>
      <div>
        <SectionHeader>{`${t('layouts.linking_account.headers.linking_account')} - ${t(
          'layouts.linking_account.headers.svod_users'
        )}`}</SectionHeader>
        {LokaliseField('svodHeader')}
        {LokaliseField('svodSubHeader')}
        {LokaliseField('svodCreateAccountCta')}
        {LokaliseField('notNowCta')}
      </div>
    </FormBody>
  );
});

LinkingAccountForm.displayName = 'LinkingAccountForm';

export default LinkingAccountForm;
