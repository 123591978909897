import { DateTime } from 'luxon';
import { OperationIds, PromotionBody, PromotionResponse } from '../../../../API';
import { usePromotionsAPI } from '../../../../hooks/API/Payments/usePromotionsAPI';
import { CreateDataManager, DataManagerReturnType } from '../../../DataManager';
import { PromotionsHook, PromotionsHookReturnType } from './PromotionsHook';
import { PromotionsState, PromotionsStateFullReturnType } from './PromotionsState';

export type PromotionsDataManagerType = {
  state: PromotionsStateFullReturnType;
  hook: PromotionsHookReturnType;
};

export interface PromotionsDataManagerReturnType extends DataManagerReturnType<PromotionResponse, PromotionBody> {
  state: PromotionsStateFullReturnType;
  hook: () => PromotionsHookReturnType;
}

const generateNew = (): PromotionBody => {
  return {
    name: '',
    startDate: DateTime.now().plus({ days: 1 }).startOf('day').toISO() || '',
    endDate: DateTime.now().plus({ days: 8 }).startOf('day').toISO() || '',
    plans: []
  };
};

export const PromotionsDataManager = CreateDataManager<
  PromotionResponse,
  PromotionBody,
  PromotionsDataManagerReturnType
>({
  name: 'promotions',
  idField: 'id',
  isVersionedEntity: true,
  useApiHook: usePromotionsAPI,
  generateNew,
  hookInitializer: PromotionsHook,
  stateInitializer: PromotionsState,
  toString: (promotion: PromotionBody | PromotionResponse) => promotion.name,
  operationIdGetAll: OperationIds.GET_PAGINATED
});
