import React from 'react';
import { InlinePageUiModuleBody } from '../../../../../API';
import Localized from '../../../../shared/Localized';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import InlinePagePreview from '../../ContentPreview/InlinePagePreview';

export type InlinePageListItemProps = IBaseUIModuleListItemProps<InlinePageUiModuleBody>;

export const testIds = {
  preview: 'inline-page-list-item.preview'
};

const InlinePageListItem = ({ uiModule, ...props }: InlinePageListItemProps): JSX.Element => {
  return (
    <UIModuleListItem
      uiModule={uiModule}
      topLineTitle={<Localized prop={uiModule.title} />}
      renderPreview={(showPreview) => (
        <div data-testid={testIds.preview}>
          <InlinePagePreview showing={showPreview} module={uiModule} />
        </div>
      )}
      {...props}
    />
  );
};

export default InlinePageListItem;
