/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiPaginatedResponseOfContentCollectionResponse } from '../model';
// @ts-ignore
import { ApiResponseOfCollectionLayoutsResponse } from '../model';
// @ts-ignore
import { ApiResponseOfContentCollectionResponse } from '../model';
// @ts-ignore
import { ApiResponseOfContentCollectionResponseArray } from '../model';
// @ts-ignore
import { ApiResponseOfStringArray } from '../model';
// @ts-ignore
import { CollectionAssetType } from '../model';
// @ts-ignore
import { CollectionAssociatedErrorResponse } from '../model';
// @ts-ignore
import { CollectionUsedFilter } from '../model';
// @ts-ignore
import { ContentCollectionBody } from '../model';
// @ts-ignore
import { ContentCollectionType } from '../model';
// @ts-ignore
import { EntitlementType } from '../model';
// @ts-ignore
import { SortDirection } from '../model';
// @ts-ignore
import { VersionedDocumentStatus } from '../model';
/**
 * ContentCollectionsApi - axios parameter creator
 * @export
 */
export const ContentCollectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportContentsCsv: async (collectionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('exportContentsCsv', 'collectionId', collectionId)
            const localVarPath = `/api/v1/collections/exportContentsCsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (collectionId !== undefined) {
                localVarQueryParameter['collectionId'] = collectionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionLayouts: async (entityId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getCollectionLayouts', 'entityId', entityId)
            const localVarPath = `/api/v1/collections/{entityId}/layouts`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenres: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/collections/genres`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the contents of the latest published version of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestPublishedRevision: async (entityId: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getLatestPublishedRevision', 'entityId', entityId)
            const localVarPath = `/api/v1/collections/{entityId}/revisions/latest/published`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} contentCollectionIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestPublishedRevisionsByIds: async (contentCollectionIds: Array<string>, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentCollectionIds' is not null or undefined
            assertParamExists('getLatestPublishedRevisionsByIds', 'contentCollectionIds', contentCollectionIds)
            const localVarPath = `/api/v1/collections/revisions/latest/published`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentCollectionIds) {
                localVarQueryParameter['contentCollectionIds'] = contentCollectionIds;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the contents of the latest version of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestRevision: async (entityId: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getLatestRevision', 'entityId', entityId)
            const localVarPath = `/api/v1/collections/{entityId}/revisions/latest`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} contentCollectionIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestRevisionsByIds: async (contentCollectionIds: Array<string>, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentCollectionIds' is not null or undefined
            assertParamExists('getLatestRevisionsByIds', 'contentCollectionIds', contentCollectionIds)
            const localVarPath = `/api/v1/collections/revisions/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentCollectionIds) {
                localVarQueryParameter['contentCollectionIds'] = contentCollectionIds;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all the **collections** available and their contents from the **Vix CMS** application.
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {string} [search] 
         * @param {string} [genreFilter] 
         * @param {string} [contentId] 
         * @param {CollectionUsedFilter} [usedFilter] 
         * @param {boolean} [forSimilars] 
         * @param {Array<EntitlementType>} [entitlements] 
         * @param {boolean} [includeCardImage] 
         * @param {boolean} [isLatestPublishedRevision] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [hasAutoRotate] 
         * @param {boolean} [hasSmartQuery] 
         * @param {Array<ContentCollectionType>} [collectionTypes] 
         * @param {Array<CollectionAssetType>} [hasAssetsTheseTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated: async (limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, search?: string, genreFilter?: string, contentId?: string, usedFilter?: CollectionUsedFilter, forSimilars?: boolean, entitlements?: Array<EntitlementType>, includeCardImage?: boolean, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, hasAutoRotate?: boolean, hasSmartQuery?: boolean, collectionTypes?: Array<ContentCollectionType>, hasAssetsTheseTypes?: Array<CollectionAssetType>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (authorId !== undefined) {
                localVarQueryParameter['authorId'] = authorId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (genreFilter !== undefined) {
                localVarQueryParameter['genreFilter'] = genreFilter;
            }

            if (contentId !== undefined) {
                localVarQueryParameter['contentId'] = contentId;
            }

            if (usedFilter !== undefined) {
                localVarQueryParameter['usedFilter'] = usedFilter;
            }

            if (forSimilars !== undefined) {
                localVarQueryParameter['forSimilars'] = forSimilars;
            }

            if (entitlements) {
                localVarQueryParameter['entitlements[]'] = entitlements;
            }

            if (includeCardImage !== undefined) {
                localVarQueryParameter['includeCardImage'] = includeCardImage;
            }

            if (isLatestPublishedRevision !== undefined) {
                localVarQueryParameter['isLatestPublishedRevision'] = isLatestPublishedRevision;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (hasAutoRotate !== undefined) {
                localVarQueryParameter['hasAutoRotate'] = hasAutoRotate;
            }

            if (hasSmartQuery !== undefined) {
                localVarQueryParameter['hasSmartQuery'] = hasSmartQuery;
            }

            if (collectionTypes) {
                localVarQueryParameter['collectionTypes[]'] = collectionTypes;
            }

            if (hasAssetsTheseTypes) {
                localVarQueryParameter['hasAssetsTheseTypes[]'] = hasAssetsTheseTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the contents of a specific version of a **collection** from the **Vix CMS** application by providing an `entityId` and a `revisionId`.
         * @param {string} entityId 
         * @param {number} revisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevision: async (entityId: string, revisionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getRevision', 'entityId', entityId)
            // verify required parameter 'revisionId' is not null or undefined
            assertParamExists('getRevision', 'revisionId', revisionId)
            const localVarPath = `/api/v1/collections/{entityId}/revisions/{revisionId}`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"revisionId"}}`, encodeURIComponent(String(revisionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the contents of all the versions of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {string} [search] 
         * @param {string} [genreFilter] 
         * @param {string} [contentId] 
         * @param {CollectionUsedFilter} [usedFilter] 
         * @param {boolean} [forSimilars] 
         * @param {Array<EntitlementType>} [entitlements] 
         * @param {boolean} [includeCardImage] 
         * @param {boolean} [isLatestPublishedRevision] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [hasAutoRotate] 
         * @param {boolean} [hasSmartQuery] 
         * @param {Array<ContentCollectionType>} [collectionTypes] 
         * @param {Array<CollectionAssetType>} [hasAssetsTheseTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevisions: async (entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, search?: string, genreFilter?: string, contentId?: string, usedFilter?: CollectionUsedFilter, forSimilars?: boolean, entitlements?: Array<EntitlementType>, includeCardImage?: boolean, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, hasAutoRotate?: boolean, hasSmartQuery?: boolean, collectionTypes?: Array<ContentCollectionType>, hasAssetsTheseTypes?: Array<CollectionAssetType>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getRevisions', 'entityId', entityId)
            const localVarPath = `/api/v1/collections/{entityId}/revisions`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (authorId !== undefined) {
                localVarQueryParameter['authorId'] = authorId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (genreFilter !== undefined) {
                localVarQueryParameter['genreFilter'] = genreFilter;
            }

            if (contentId !== undefined) {
                localVarQueryParameter['contentId'] = contentId;
            }

            if (usedFilter !== undefined) {
                localVarQueryParameter['usedFilter'] = usedFilter;
            }

            if (forSimilars !== undefined) {
                localVarQueryParameter['forSimilars'] = forSimilars;
            }

            if (entitlements) {
                localVarQueryParameter['entitlements[]'] = entitlements;
            }

            if (includeCardImage !== undefined) {
                localVarQueryParameter['includeCardImage'] = includeCardImage;
            }

            if (isLatestPublishedRevision !== undefined) {
                localVarQueryParameter['isLatestPublishedRevision'] = isLatestPublishedRevision;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (hasAutoRotate !== undefined) {
                localVarQueryParameter['hasAutoRotate'] = hasAutoRotate;
            }

            if (hasSmartQuery !== undefined) {
                localVarQueryParameter['hasSmartQuery'] = hasSmartQuery;
            }

            if (collectionTypes) {
                localVarQueryParameter['collectionTypes[]'] = collectionTypes;
            }

            if (hasAssetsTheseTypes) {
                localVarQueryParameter['hasAssetsTheseTypes[]'] = hasAssetsTheseTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Changes the `status` of a **collection** from `DRAFT` to `PUBLISHED` in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publish: async (entityId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('publish', 'entityId', entityId)
            const localVarPath = `/api/v1/collections/{entityId}/publish`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (entityId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('remove', 'entityId', entityId)
            const localVarPath = `/api/v1/collections/{entityId}`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} collectionId 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeContentFromCollection: async (collectionId: string, contentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('removeContentFromCollection', 'collectionId', collectionId)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('removeContentFromCollection', 'contentId', contentId)
            const localVarPath = `/api/v1/collections/{collectionId}/content/{contentId}`
                .replace(`{${"collectionId"}}`, encodeURIComponent(String(collectionId)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeContentFromCollections: async (contentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('removeContentFromCollections', 'contentId', contentId)
            const localVarPath = `/api/v1/collections/removeContent/{contentId}`
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the contents of the latest version of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {ContentCollectionBody} contentCollectionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (entityId: string, contentCollectionBody: ContentCollectionBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('update', 'entityId', entityId)
            // verify required parameter 'contentCollectionBody' is not null or undefined
            assertParamExists('update', 'contentCollectionBody', contentCollectionBody)
            const localVarPath = `/api/v1/collections/{entityId}/revisions/latest`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentCollectionBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentCollectionsApi - functional programming interface
 * @export
 */
export const ContentCollectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContentCollectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportContentsCsv(collectionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportContentsCsv(collectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectionLayouts(entityId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfCollectionLayoutsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectionLayouts(entityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGenres(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfStringArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGenres(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the contents of the latest published version of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestPublishedRevision(entityId: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfContentCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestPublishedRevision(entityId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} contentCollectionIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestPublishedRevisionsByIds(contentCollectionIds: Array<string>, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfContentCollectionResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestPublishedRevisionsByIds(contentCollectionIds, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the contents of the latest version of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestRevision(entityId: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfContentCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestRevision(entityId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} contentCollectionIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestRevisionsByIds(contentCollectionIds: Array<string>, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfContentCollectionResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestRevisionsByIds(contentCollectionIds, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all the **collections** available and their contents from the **Vix CMS** application.
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {string} [search] 
         * @param {string} [genreFilter] 
         * @param {string} [contentId] 
         * @param {CollectionUsedFilter} [usedFilter] 
         * @param {boolean} [forSimilars] 
         * @param {Array<EntitlementType>} [entitlements] 
         * @param {boolean} [includeCardImage] 
         * @param {boolean} [isLatestPublishedRevision] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [hasAutoRotate] 
         * @param {boolean} [hasSmartQuery] 
         * @param {Array<ContentCollectionType>} [collectionTypes] 
         * @param {Array<CollectionAssetType>} [hasAssetsTheseTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, search?: string, genreFilter?: string, contentId?: string, usedFilter?: CollectionUsedFilter, forSimilars?: boolean, entitlements?: Array<EntitlementType>, includeCardImage?: boolean, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, hasAutoRotate?: boolean, hasSmartQuery?: boolean, collectionTypes?: Array<ContentCollectionType>, hasAssetsTheseTypes?: Array<CollectionAssetType>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfContentCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginated(limit, page, sortBy, sortDirection, status, authorId, search, genreFilter, contentId, usedFilter, forSimilars, entitlements, includeCardImage, isLatestPublishedRevision, includeDeleted, hasAutoRotate, hasSmartQuery, collectionTypes, hasAssetsTheseTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the contents of a specific version of a **collection** from the **Vix CMS** application by providing an `entityId` and a `revisionId`.
         * @param {string} entityId 
         * @param {number} revisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRevision(entityId: string, revisionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfContentCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRevision(entityId, revisionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the contents of all the versions of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {string} [search] 
         * @param {string} [genreFilter] 
         * @param {string} [contentId] 
         * @param {CollectionUsedFilter} [usedFilter] 
         * @param {boolean} [forSimilars] 
         * @param {Array<EntitlementType>} [entitlements] 
         * @param {boolean} [includeCardImage] 
         * @param {boolean} [isLatestPublishedRevision] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [hasAutoRotate] 
         * @param {boolean} [hasSmartQuery] 
         * @param {Array<ContentCollectionType>} [collectionTypes] 
         * @param {Array<CollectionAssetType>} [hasAssetsTheseTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRevisions(entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, search?: string, genreFilter?: string, contentId?: string, usedFilter?: CollectionUsedFilter, forSimilars?: boolean, entitlements?: Array<EntitlementType>, includeCardImage?: boolean, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, hasAutoRotate?: boolean, hasSmartQuery?: boolean, collectionTypes?: Array<ContentCollectionType>, hasAssetsTheseTypes?: Array<CollectionAssetType>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfContentCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRevisions(entityId, limit, page, sortBy, sortDirection, status, authorId, search, genreFilter, contentId, usedFilter, forSimilars, entitlements, includeCardImage, isLatestPublishedRevision, includeDeleted, hasAutoRotate, hasSmartQuery, collectionTypes, hasAssetsTheseTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Changes the `status` of a **collection** from `DRAFT` to `PUBLISHED` in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publish(entityId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfContentCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publish(entityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(entityId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(entityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} collectionId 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeContentFromCollection(collectionId: string, contentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeContentFromCollection(collectionId, contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeContentFromCollections(contentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfContentCollectionResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeContentFromCollections(contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the contents of the latest version of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {ContentCollectionBody} contentCollectionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(entityId: string, contentCollectionBody: ContentCollectionBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfContentCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(entityId, contentCollectionBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContentCollectionsApi - factory interface
 * @export
 */
export const ContentCollectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContentCollectionsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportContentsCsv(collectionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.exportContentsCsv(collectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionLayouts(entityId: string, options?: any): AxiosPromise<ApiResponseOfCollectionLayoutsResponse> {
            return localVarFp.getCollectionLayouts(entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenres(options?: any): AxiosPromise<ApiResponseOfStringArray> {
            return localVarFp.getGenres(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the contents of the latest published version of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestPublishedRevision(entityId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfContentCollectionResponse> {
            return localVarFp.getLatestPublishedRevision(entityId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} contentCollectionIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestPublishedRevisionsByIds(contentCollectionIds: Array<string>, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfContentCollectionResponseArray> {
            return localVarFp.getLatestPublishedRevisionsByIds(contentCollectionIds, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the contents of the latest version of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestRevision(entityId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfContentCollectionResponse> {
            return localVarFp.getLatestRevision(entityId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} contentCollectionIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestRevisionsByIds(contentCollectionIds: Array<string>, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfContentCollectionResponseArray> {
            return localVarFp.getLatestRevisionsByIds(contentCollectionIds, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all the **collections** available and their contents from the **Vix CMS** application.
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {string} [search] 
         * @param {string} [genreFilter] 
         * @param {string} [contentId] 
         * @param {CollectionUsedFilter} [usedFilter] 
         * @param {boolean} [forSimilars] 
         * @param {Array<EntitlementType>} [entitlements] 
         * @param {boolean} [includeCardImage] 
         * @param {boolean} [isLatestPublishedRevision] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [hasAutoRotate] 
         * @param {boolean} [hasSmartQuery] 
         * @param {Array<ContentCollectionType>} [collectionTypes] 
         * @param {Array<CollectionAssetType>} [hasAssetsTheseTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, search?: string, genreFilter?: string, contentId?: string, usedFilter?: CollectionUsedFilter, forSimilars?: boolean, entitlements?: Array<EntitlementType>, includeCardImage?: boolean, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, hasAutoRotate?: boolean, hasSmartQuery?: boolean, collectionTypes?: Array<ContentCollectionType>, hasAssetsTheseTypes?: Array<CollectionAssetType>, options?: any): AxiosPromise<ApiPaginatedResponseOfContentCollectionResponse> {
            return localVarFp.getPaginated(limit, page, sortBy, sortDirection, status, authorId, search, genreFilter, contentId, usedFilter, forSimilars, entitlements, includeCardImage, isLatestPublishedRevision, includeDeleted, hasAutoRotate, hasSmartQuery, collectionTypes, hasAssetsTheseTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the contents of a specific version of a **collection** from the **Vix CMS** application by providing an `entityId` and a `revisionId`.
         * @param {string} entityId 
         * @param {number} revisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevision(entityId: string, revisionId: number, options?: any): AxiosPromise<ApiResponseOfContentCollectionResponse> {
            return localVarFp.getRevision(entityId, revisionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the contents of all the versions of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {VersionedDocumentStatus} [status] 
         * @param {string} [authorId] 
         * @param {string} [search] 
         * @param {string} [genreFilter] 
         * @param {string} [contentId] 
         * @param {CollectionUsedFilter} [usedFilter] 
         * @param {boolean} [forSimilars] 
         * @param {Array<EntitlementType>} [entitlements] 
         * @param {boolean} [includeCardImage] 
         * @param {boolean} [isLatestPublishedRevision] 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [hasAutoRotate] 
         * @param {boolean} [hasSmartQuery] 
         * @param {Array<ContentCollectionType>} [collectionTypes] 
         * @param {Array<CollectionAssetType>} [hasAssetsTheseTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevisions(entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, search?: string, genreFilter?: string, contentId?: string, usedFilter?: CollectionUsedFilter, forSimilars?: boolean, entitlements?: Array<EntitlementType>, includeCardImage?: boolean, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, hasAutoRotate?: boolean, hasSmartQuery?: boolean, collectionTypes?: Array<ContentCollectionType>, hasAssetsTheseTypes?: Array<CollectionAssetType>, options?: any): AxiosPromise<ApiPaginatedResponseOfContentCollectionResponse> {
            return localVarFp.getRevisions(entityId, limit, page, sortBy, sortDirection, status, authorId, search, genreFilter, contentId, usedFilter, forSimilars, entitlements, includeCardImage, isLatestPublishedRevision, includeDeleted, hasAutoRotate, hasSmartQuery, collectionTypes, hasAssetsTheseTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * Changes the `status` of a **collection** from `DRAFT` to `PUBLISHED` in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publish(entityId: string, options?: any): AxiosPromise<ApiResponseOfContentCollectionResponse> {
            return localVarFp.publish(entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(entityId: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} collectionId 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeContentFromCollection(collectionId: string, contentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeContentFromCollection(collectionId, contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeContentFromCollections(contentId: string, options?: any): AxiosPromise<ApiResponseOfContentCollectionResponseArray> {
            return localVarFp.removeContentFromCollections(contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the contents of the latest version of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
         * @param {string} entityId 
         * @param {ContentCollectionBody} contentCollectionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(entityId: string, contentCollectionBody: ContentCollectionBody, options?: any): AxiosPromise<ApiResponseOfContentCollectionResponse> {
            return localVarFp.update(entityId, contentCollectionBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContentCollectionsApi - interface
 * @export
 * @interface ContentCollectionsApi
 */
export interface ContentCollectionsApiInterface {
    /**
     * 
     * @param {string} collectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApiInterface
     */
    exportContentsCsv(collectionId: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApiInterface
     */
    getCollectionLayouts(entityId: string, options?: any): AxiosPromise<ApiResponseOfCollectionLayoutsResponse>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApiInterface
     */
    getGenres(options?: any): AxiosPromise<ApiResponseOfStringArray>;

    /**
     * Retrieves the contents of the latest published version of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApiInterface
     */
    getLatestPublishedRevision(entityId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfContentCollectionResponse>;

    /**
     * 
     * @param {Array<string>} contentCollectionIds 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApiInterface
     */
    getLatestPublishedRevisionsByIds(contentCollectionIds: Array<string>, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfContentCollectionResponseArray>;

    /**
     * Retrieves the contents of the latest version of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApiInterface
     */
    getLatestRevision(entityId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfContentCollectionResponse>;

    /**
     * 
     * @param {Array<string>} contentCollectionIds 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApiInterface
     */
    getLatestRevisionsByIds(contentCollectionIds: Array<string>, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfContentCollectionResponseArray>;

    /**
     * Retrieves all the **collections** available and their contents from the **Vix CMS** application.
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {VersionedDocumentStatus} [status] 
     * @param {string} [authorId] 
     * @param {string} [search] 
     * @param {string} [genreFilter] 
     * @param {string} [contentId] 
     * @param {CollectionUsedFilter} [usedFilter] 
     * @param {boolean} [forSimilars] 
     * @param {Array<EntitlementType>} [entitlements] 
     * @param {boolean} [includeCardImage] 
     * @param {boolean} [isLatestPublishedRevision] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [hasAutoRotate] 
     * @param {boolean} [hasSmartQuery] 
     * @param {Array<ContentCollectionType>} [collectionTypes] 
     * @param {Array<CollectionAssetType>} [hasAssetsTheseTypes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApiInterface
     */
    getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, search?: string, genreFilter?: string, contentId?: string, usedFilter?: CollectionUsedFilter, forSimilars?: boolean, entitlements?: Array<EntitlementType>, includeCardImage?: boolean, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, hasAutoRotate?: boolean, hasSmartQuery?: boolean, collectionTypes?: Array<ContentCollectionType>, hasAssetsTheseTypes?: Array<CollectionAssetType>, options?: any): AxiosPromise<ApiPaginatedResponseOfContentCollectionResponse>;

    /**
     * Retrieves the contents of a specific version of a **collection** from the **Vix CMS** application by providing an `entityId` and a `revisionId`.
     * @param {string} entityId 
     * @param {number} revisionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApiInterface
     */
    getRevision(entityId: string, revisionId: number, options?: any): AxiosPromise<ApiResponseOfContentCollectionResponse>;

    /**
     * Retrieves the contents of all the versions of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {VersionedDocumentStatus} [status] 
     * @param {string} [authorId] 
     * @param {string} [search] 
     * @param {string} [genreFilter] 
     * @param {string} [contentId] 
     * @param {CollectionUsedFilter} [usedFilter] 
     * @param {boolean} [forSimilars] 
     * @param {Array<EntitlementType>} [entitlements] 
     * @param {boolean} [includeCardImage] 
     * @param {boolean} [isLatestPublishedRevision] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [hasAutoRotate] 
     * @param {boolean} [hasSmartQuery] 
     * @param {Array<ContentCollectionType>} [collectionTypes] 
     * @param {Array<CollectionAssetType>} [hasAssetsTheseTypes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApiInterface
     */
    getRevisions(entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, search?: string, genreFilter?: string, contentId?: string, usedFilter?: CollectionUsedFilter, forSimilars?: boolean, entitlements?: Array<EntitlementType>, includeCardImage?: boolean, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, hasAutoRotate?: boolean, hasSmartQuery?: boolean, collectionTypes?: Array<ContentCollectionType>, hasAssetsTheseTypes?: Array<CollectionAssetType>, options?: any): AxiosPromise<ApiPaginatedResponseOfContentCollectionResponse>;

    /**
     * Changes the `status` of a **collection** from `DRAFT` to `PUBLISHED` in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApiInterface
     */
    publish(entityId: string, options?: any): AxiosPromise<ApiResponseOfContentCollectionResponse>;

    /**
     * 
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApiInterface
     */
    remove(entityId: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {string} collectionId 
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApiInterface
     */
    removeContentFromCollection(collectionId: string, contentId: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApiInterface
     */
    removeContentFromCollections(contentId: string, options?: any): AxiosPromise<ApiResponseOfContentCollectionResponseArray>;

    /**
     * Updates the contents of the latest version of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {ContentCollectionBody} contentCollectionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApiInterface
     */
    update(entityId: string, contentCollectionBody: ContentCollectionBody, options?: any): AxiosPromise<ApiResponseOfContentCollectionResponse>;

}

/**
 * ContentCollectionsApi - object-oriented interface
 * @export
 * @class ContentCollectionsApi
 * @extends {BaseAPI}
 */
export class ContentCollectionsApi extends BaseAPI implements ContentCollectionsApiInterface {
    /**
     * 
     * @param {string} collectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApi
     */
    public exportContentsCsv(collectionId: string, options?: any) {
        return ContentCollectionsApiFp(this.configuration).exportContentsCsv(collectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApi
     */
    public getCollectionLayouts(entityId: string, options?: any) {
        return ContentCollectionsApiFp(this.configuration).getCollectionLayouts(entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApi
     */
    public getGenres(options?: any) {
        return ContentCollectionsApiFp(this.configuration).getGenres(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the contents of the latest published version of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApi
     */
    public getLatestPublishedRevision(entityId: string, includeDeleted?: boolean, options?: any) {
        return ContentCollectionsApiFp(this.configuration).getLatestPublishedRevision(entityId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} contentCollectionIds 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApi
     */
    public getLatestPublishedRevisionsByIds(contentCollectionIds: Array<string>, includeDeleted?: boolean, options?: any) {
        return ContentCollectionsApiFp(this.configuration).getLatestPublishedRevisionsByIds(contentCollectionIds, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the contents of the latest version of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApi
     */
    public getLatestRevision(entityId: string, includeDeleted?: boolean, options?: any) {
        return ContentCollectionsApiFp(this.configuration).getLatestRevision(entityId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} contentCollectionIds 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApi
     */
    public getLatestRevisionsByIds(contentCollectionIds: Array<string>, includeDeleted?: boolean, options?: any) {
        return ContentCollectionsApiFp(this.configuration).getLatestRevisionsByIds(contentCollectionIds, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all the **collections** available and their contents from the **Vix CMS** application.
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {VersionedDocumentStatus} [status] 
     * @param {string} [authorId] 
     * @param {string} [search] 
     * @param {string} [genreFilter] 
     * @param {string} [contentId] 
     * @param {CollectionUsedFilter} [usedFilter] 
     * @param {boolean} [forSimilars] 
     * @param {Array<EntitlementType>} [entitlements] 
     * @param {boolean} [includeCardImage] 
     * @param {boolean} [isLatestPublishedRevision] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [hasAutoRotate] 
     * @param {boolean} [hasSmartQuery] 
     * @param {Array<ContentCollectionType>} [collectionTypes] 
     * @param {Array<CollectionAssetType>} [hasAssetsTheseTypes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApi
     */
    public getPaginated(limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, search?: string, genreFilter?: string, contentId?: string, usedFilter?: CollectionUsedFilter, forSimilars?: boolean, entitlements?: Array<EntitlementType>, includeCardImage?: boolean, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, hasAutoRotate?: boolean, hasSmartQuery?: boolean, collectionTypes?: Array<ContentCollectionType>, hasAssetsTheseTypes?: Array<CollectionAssetType>, options?: any) {
        return ContentCollectionsApiFp(this.configuration).getPaginated(limit, page, sortBy, sortDirection, status, authorId, search, genreFilter, contentId, usedFilter, forSimilars, entitlements, includeCardImage, isLatestPublishedRevision, includeDeleted, hasAutoRotate, hasSmartQuery, collectionTypes, hasAssetsTheseTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the contents of a specific version of a **collection** from the **Vix CMS** application by providing an `entityId` and a `revisionId`.
     * @param {string} entityId 
     * @param {number} revisionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApi
     */
    public getRevision(entityId: string, revisionId: number, options?: any) {
        return ContentCollectionsApiFp(this.configuration).getRevision(entityId, revisionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the contents of all the versions of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {VersionedDocumentStatus} [status] 
     * @param {string} [authorId] 
     * @param {string} [search] 
     * @param {string} [genreFilter] 
     * @param {string} [contentId] 
     * @param {CollectionUsedFilter} [usedFilter] 
     * @param {boolean} [forSimilars] 
     * @param {Array<EntitlementType>} [entitlements] 
     * @param {boolean} [includeCardImage] 
     * @param {boolean} [isLatestPublishedRevision] 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [hasAutoRotate] 
     * @param {boolean} [hasSmartQuery] 
     * @param {Array<ContentCollectionType>} [collectionTypes] 
     * @param {Array<CollectionAssetType>} [hasAssetsTheseTypes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApi
     */
    public getRevisions(entityId: string, limit?: number, page?: number, sortBy?: string, sortDirection?: SortDirection, status?: VersionedDocumentStatus, authorId?: string, search?: string, genreFilter?: string, contentId?: string, usedFilter?: CollectionUsedFilter, forSimilars?: boolean, entitlements?: Array<EntitlementType>, includeCardImage?: boolean, isLatestPublishedRevision?: boolean, includeDeleted?: boolean, hasAutoRotate?: boolean, hasSmartQuery?: boolean, collectionTypes?: Array<ContentCollectionType>, hasAssetsTheseTypes?: Array<CollectionAssetType>, options?: any) {
        return ContentCollectionsApiFp(this.configuration).getRevisions(entityId, limit, page, sortBy, sortDirection, status, authorId, search, genreFilter, contentId, usedFilter, forSimilars, entitlements, includeCardImage, isLatestPublishedRevision, includeDeleted, hasAutoRotate, hasSmartQuery, collectionTypes, hasAssetsTheseTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Changes the `status` of a **collection** from `DRAFT` to `PUBLISHED` in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApi
     */
    public publish(entityId: string, options?: any) {
        return ContentCollectionsApiFp(this.configuration).publish(entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApi
     */
    public remove(entityId: string, options?: any) {
        return ContentCollectionsApiFp(this.configuration).remove(entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} collectionId 
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApi
     */
    public removeContentFromCollection(collectionId: string, contentId: string, options?: any) {
        return ContentCollectionsApiFp(this.configuration).removeContentFromCollection(collectionId, contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApi
     */
    public removeContentFromCollections(contentId: string, options?: any) {
        return ContentCollectionsApiFp(this.configuration).removeContentFromCollections(contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the contents of the latest version of a specific **collection** in the **Vix CMS** application by providing an `entityId`.
     * @param {string} entityId 
     * @param {ContentCollectionBody} contentCollectionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentCollectionsApi
     */
    public update(entityId: string, contentCollectionBody: ContentCollectionBody, options?: any) {
        return ContentCollectionsApiFp(this.configuration).update(entityId, contentCollectionBody, options).then((request) => request(this.axios, this.basePath));
    }
}
