import { cloneDeep } from 'lodash-es';
import { useSetRecoilState } from 'recoil';
import { VllCategoriesBundleBody, VllCategoriesBundleResponse } from '../../../../API';
import {
  DataManagerHook,
  DataManagerHookReturnType,
  IDataManagerHookProps,
  useGetRecoilState
} from '../../../DataManager';

export function CategoryBundlesHook(
  params: IDataManagerHookProps<VllCategoriesBundleResponse, VllCategoriesBundleBody>
): DataManagerHookReturnType<VllCategoriesBundleResponse, VllCategoriesBundleBody> {
  const dataManagerHook = DataManagerHook<VllCategoriesBundleResponse, VllCategoriesBundleBody>(params);
  const setFormMetaData = useSetRecoilState(params.state.withFormMetadata);
  const getCache = useGetRecoilState(params.state.withDataCache);

  // Override the clone method to provide this custom behavior
  const clone = async (id: string) => {
    const cache = await getCache();
    const cachedRecord = cache[id];
    if (!cachedRecord) return;
    const clonedRecord: VllCategoriesBundleResponse = cloneDeep(cachedRecord.object);
    clonedRecord.entityId = '';
    clonedRecord.regionName = '';
    clonedRecord.countries = [];
    setFormMetaData({
      isShowingForm: true,
      isCloning: true,
      isNew: true,
      cloningRecord: cloneDeep(cachedRecord.object),
      record: clonedRecord
    });
    return clonedRecord;
  };

  return {
    ...dataManagerHook,
    clone
  };
}
