/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiPaginatedResponseOfOfferMappingResponse } from '../model';
// @ts-ignore
import { ApiResponseOfAppStoresResponse } from '../model';
// @ts-ignore
import { ApiResponseOfOfferMappingResponse } from '../model';
// @ts-ignore
import { OfferMappingBody } from '../model';
// @ts-ignore
import { SortDirection } from '../model';
/**
 * OfferMappingApi - axios parameter creator
 * @export
 */
export const OfferMappingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OfferMappingBody} offerMappingBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (offerMappingBody: OfferMappingBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerMappingBody' is not null or undefined
            assertParamExists('create', 'offerMappingBody', offerMappingBody)
            const localVarPath = `/api/v1/offerMappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerMappingBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppStores: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/offerMappings/appStores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} offerMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (offerMappingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerMappingId' is not null or undefined
            assertParamExists('getById', 'offerMappingId', offerMappingId)
            const localVarPath = `/api/v1/offerMappings/{offerMappingId}`
                .replace(`{${"offerMappingId"}}`, encodeURIComponent(String(offerMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} source 
         * @param {string} offerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBySourceAndOfferCode: async (source: string, offerCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('getBySourceAndOfferCode', 'source', source)
            // verify required parameter 'offerCode' is not null or undefined
            assertParamExists('getBySourceAndOfferCode', 'offerCode', offerCode)
            const localVarPath = `/api/v1/offerMappings/{source}/offerCode/{offerCode}`
                .replace(`{${"source"}}`, encodeURIComponent(String(source)))
                .replace(`{${"offerCode"}}`, encodeURIComponent(String(offerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated: async (limit?: number, page?: number, sortDirection?: SortDirection, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/offerMappings/paginated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} offerMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (offerMappingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerMappingId' is not null or undefined
            assertParamExists('remove', 'offerMappingId', offerMappingId)
            const localVarPath = `/api/v1/offerMappings/{offerMappingId}`
                .replace(`{${"offerMappingId"}}`, encodeURIComponent(String(offerMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} offerMappingId 
         * @param {OfferMappingBody} offerMappingBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (offerMappingId: string, offerMappingBody: OfferMappingBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerMappingId' is not null or undefined
            assertParamExists('update', 'offerMappingId', offerMappingId)
            // verify required parameter 'offerMappingBody' is not null or undefined
            assertParamExists('update', 'offerMappingBody', offerMappingBody)
            const localVarPath = `/api/v1/offerMappings/{offerMappingId}`
                .replace(`{${"offerMappingId"}}`, encodeURIComponent(String(offerMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerMappingBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferMappingApi - functional programming interface
 * @export
 */
export const OfferMappingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferMappingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {OfferMappingBody} offerMappingBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(offerMappingBody: OfferMappingBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfOfferMappingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(offerMappingBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppStores(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfAppStoresResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppStores(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} offerMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(offerMappingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfOfferMappingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(offerMappingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} source 
         * @param {string} offerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBySourceAndOfferCode(source: string, offerCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfOfferMappingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBySourceAndOfferCode(source, offerCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginated(limit?: number, page?: number, sortDirection?: SortDirection, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfOfferMappingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginated(limit, page, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} offerMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(offerMappingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(offerMappingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} offerMappingId 
         * @param {OfferMappingBody} offerMappingBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(offerMappingId: string, offerMappingBody: OfferMappingBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfOfferMappingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(offerMappingId, offerMappingBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferMappingApi - factory interface
 * @export
 */
export const OfferMappingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferMappingApiFp(configuration)
    return {
        /**
         * 
         * @param {OfferMappingBody} offerMappingBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(offerMappingBody: OfferMappingBody, options?: any): AxiosPromise<ApiResponseOfOfferMappingResponse> {
            return localVarFp.create(offerMappingBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppStores(options?: any): AxiosPromise<ApiResponseOfAppStoresResponse> {
            return localVarFp.getAppStores(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} offerMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(offerMappingId: string, options?: any): AxiosPromise<ApiResponseOfOfferMappingResponse> {
            return localVarFp.getById(offerMappingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} source 
         * @param {string} offerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBySourceAndOfferCode(source: string, offerCode: string, options?: any): AxiosPromise<ApiResponseOfOfferMappingResponse> {
            return localVarFp.getBySourceAndOfferCode(source, offerCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated(limit?: number, page?: number, sortDirection?: SortDirection, options?: any): AxiosPromise<ApiPaginatedResponseOfOfferMappingResponse> {
            return localVarFp.getPaginated(limit, page, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} offerMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(offerMappingId: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(offerMappingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} offerMappingId 
         * @param {OfferMappingBody} offerMappingBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(offerMappingId: string, offerMappingBody: OfferMappingBody, options?: any): AxiosPromise<ApiResponseOfOfferMappingResponse> {
            return localVarFp.update(offerMappingId, offerMappingBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferMappingApi - interface
 * @export
 * @interface OfferMappingApi
 */
export interface OfferMappingApiInterface {
    /**
     * 
     * @param {OfferMappingBody} offerMappingBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferMappingApiInterface
     */
    create(offerMappingBody: OfferMappingBody, options?: any): AxiosPromise<ApiResponseOfOfferMappingResponse>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferMappingApiInterface
     */
    getAppStores(options?: any): AxiosPromise<ApiResponseOfAppStoresResponse>;

    /**
     * 
     * @param {string} offerMappingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferMappingApiInterface
     */
    getById(offerMappingId: string, options?: any): AxiosPromise<ApiResponseOfOfferMappingResponse>;

    /**
     * 
     * @param {string} source 
     * @param {string} offerCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferMappingApiInterface
     */
    getBySourceAndOfferCode(source: string, offerCode: string, options?: any): AxiosPromise<ApiResponseOfOfferMappingResponse>;

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferMappingApiInterface
     */
    getPaginated(limit?: number, page?: number, sortDirection?: SortDirection, options?: any): AxiosPromise<ApiPaginatedResponseOfOfferMappingResponse>;

    /**
     * 
     * @param {string} offerMappingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferMappingApiInterface
     */
    remove(offerMappingId: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {string} offerMappingId 
     * @param {OfferMappingBody} offerMappingBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferMappingApiInterface
     */
    update(offerMappingId: string, offerMappingBody: OfferMappingBody, options?: any): AxiosPromise<ApiResponseOfOfferMappingResponse>;

}

/**
 * OfferMappingApi - object-oriented interface
 * @export
 * @class OfferMappingApi
 * @extends {BaseAPI}
 */
export class OfferMappingApi extends BaseAPI implements OfferMappingApiInterface {
    /**
     * 
     * @param {OfferMappingBody} offerMappingBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferMappingApi
     */
    public create(offerMappingBody: OfferMappingBody, options?: any) {
        return OfferMappingApiFp(this.configuration).create(offerMappingBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferMappingApi
     */
    public getAppStores(options?: any) {
        return OfferMappingApiFp(this.configuration).getAppStores(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} offerMappingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferMappingApi
     */
    public getById(offerMappingId: string, options?: any) {
        return OfferMappingApiFp(this.configuration).getById(offerMappingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} source 
     * @param {string} offerCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferMappingApi
     */
    public getBySourceAndOfferCode(source: string, offerCode: string, options?: any) {
        return OfferMappingApiFp(this.configuration).getBySourceAndOfferCode(source, offerCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferMappingApi
     */
    public getPaginated(limit?: number, page?: number, sortDirection?: SortDirection, options?: any) {
        return OfferMappingApiFp(this.configuration).getPaginated(limit, page, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} offerMappingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferMappingApi
     */
    public remove(offerMappingId: string, options?: any) {
        return OfferMappingApiFp(this.configuration).remove(offerMappingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} offerMappingId 
     * @param {OfferMappingBody} offerMappingBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferMappingApi
     */
    public update(offerMappingId: string, offerMappingBody: OfferMappingBody, options?: any) {
        return OfferMappingApiFp(this.configuration).update(offerMappingId, offerMappingBody, options).then((request) => request(this.axios, this.basePath));
    }
}
