import { Add, Delete } from '@mui/icons-material';
import { Button, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { difference } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { PermissionsGroupId, PermissionsGroupRole, UserPermissionsGroupBody } from '../../../../API';
import { useLocales, useTheme } from '../../../../hooks';
import CountryPickerLite from '../../../shared/CountryPickerLite';
import FormControl from '../../../shared/FormControl';
import IconButton from '../../../shared/IconButton';

export type UserPermissionsGroupsProps = {
  value: UserPermissionsGroupBody[] | null | undefined;
  onChange: (value: UserPermissionsGroupBody[] | null | undefined) => void;
  className?: string;
};

const useStyles = makeStyles()((theme) => ({
  permissionsGroupRow: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    margin: theme.spacing(2, 0)
  },
  groupSelector: {
    minWidth: 130
  },
  permissionsSelector: {
    minWidth: 250
  },
  countryPicker: {
    minWidth: 350,
    flexGrow: 1
  }
}));

export const testIds = {
  root: 'user-permissions-groups.root',
  userPermissionsRow: 'user-permissions-groups.row',
  addPermissionsGroupButton: 'user-permissions-groups.add-button',
  groupSelector: 'user-permissions-groups.group-selector',
  groupSelectorItem: 'user-permissions-groups.group-selector-item',
  roleSelector: 'user-permissions-groups.role-selector',
  roleSelectorItem: 'user-permissions-groups.role-selector-item',
  removePermissionsGroupButton: 'user-permissions-group.remove-button'
};

const getUnusedGroups = (permissionsGroups: UserPermissionsGroupBody[] | null | undefined) => {
  return difference(Object.values(PermissionsGroupId), permissionsGroups?.map((x) => x.group) || []);
};

export const newUserPermissionsGroup = (
  permissionsGroups: UserPermissionsGroupBody[] | null | undefined
): UserPermissionsGroupBody => {
  const unusedGroups = getUnusedGroups(permissionsGroups);
  return {
    group: unusedGroups[0],
    permissions: [],
    role: PermissionsGroupRole.ADMIN,
    countries: []
  };
};

export function UserPermissionsGroups({ value, onChange, className }: UserPermissionsGroupsProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const updateValue = (index: number, updatedFields: Partial<UserPermissionsGroupBody>) => {
    if (!value) return;
    const newValue = [...value];
    newValue[index] = {
      ...value[index],
      ...updatedFields
    };
    onChange(newValue);
  };

  const onGroupChange = (index: number, group: PermissionsGroupId) => {
    updateValue(index, { group, permissions: [] });
  };

  const onPermissionsChange = (index: number, role: PermissionsGroupRole) => {
    updateValue(index, { role });
  };

  const onCountriesChange = (index: number, countries: string[]) => {
    updateValue(index, { countries });
  };

  const handleAdd = () => {
    onChange([...(value || []), newUserPermissionsGroup(value)]);
  };

  const handleRemove = (index: number) => {
    if (!value) return;
    onChange(value.slice(0, index).concat(value.slice(index + 1)));
  };

  const isUsedByOtherGroup = (group: PermissionsGroupId, excludeIndex: number) => {
    if (!value) return false;
    for (let i = 0; i < value.length; i++) {
      if (value[i].group === group && i !== excludeIndex) return true;
    }
    return false;
  };

  return (
    <div className={className} data-testid={testIds.root}>
      <Typography variant="h6">{t('permissions.permissions_groups')}</Typography>
      {value?.map((userPermissionsGroup, i) => (
        <div className={classes.permissionsGroupRow} key={i} data-testid={testIds.userPermissionsRow}>
          <FormControl className={classes.groupSelector}>
            <InputLabel>{t('permissions.group')}</InputLabel>
            <Select
              label={t('permissions.group')}
              value={userPermissionsGroup.group}
              color={formControlColor}
              onChange={({ target: { value: group } }) => onGroupChange(i, group as PermissionsGroupId)}
              SelectDisplayProps={{ style: { textTransform: 'capitalize' } }}
              data-testid={testIds.groupSelector}
            >
              {Object.values(PermissionsGroupId).map((permissionsGroup) => (
                <MenuItem
                  key={permissionsGroup}
                  value={permissionsGroup}
                  color={formControlColor}
                  disabled={isUsedByOtherGroup(permissionsGroup, i)}
                  data-testid={testIds.groupSelectorItem}
                  data-group={permissionsGroup}
                >
                  {permissionsGroup}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.permissionsSelector}>
            <InputLabel>{t('permissions.permissions')}</InputLabel>
            <Select
              label={t('permissions.permissions')}
              value={userPermissionsGroup.role}
              color={formControlColor}
              onChange={({ target: { value: role } }) => onPermissionsChange(i, role as PermissionsGroupRole)}
              SelectDisplayProps={{ style: { textTransform: 'capitalize' } }}
              data-testid={testIds.roleSelector}
            >
              {[
                PermissionsGroupRole.ADMIN,
                PermissionsGroupRole.PUBLISHER,
                PermissionsGroupRole.EDITOR,
                PermissionsGroupRole.READ_ONLY
              ]
                // {Object.values(PermissionsGroupRole)
                .filter(
                  (role) =>
                    userPermissionsGroup.group !== PermissionsGroupId.ADMIN || role === PermissionsGroupRole.ADMIN
                )
                .map((permissionsGroupRole) => (
                  <MenuItem
                    key={permissionsGroupRole}
                    value={permissionsGroupRole}
                    color={formControlColor}
                    data-testid={testIds.roleSelectorItem}
                    data-role={permissionsGroupRole}
                  >
                    {permissionsGroupRole}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <CountryPickerLite
            label={t('media.countries')}
            className={classes.countryPicker}
            value={userPermissionsGroup.countries || []}
            onChange={(countries) => onCountriesChange(i, countries)}
          />
          <div>
            <IconButton
              onClick={() => handleRemove(i)}
              title={t('permissions.remove_permissions_group')}
              size="large"
              data-testid={testIds.removePermissionsGroupButton}
            >
              <Delete />
            </IconButton>
          </div>
        </div>
      ))}
      {!!getUnusedGroups(value).length && (
        <Button
          color={formControlColor}
          startIcon={<Add />}
          onClick={handleAdd}
          variant="text"
          data-testid={testIds.addPermissionsGroupButton}
        >
          {t('permissions.add_permissions_group')}
        </Button>
      )}
    </div>
  );
}
