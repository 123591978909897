import { McpChannelResponse } from '../../../../API';
import { useContentServiceChannelsAPI } from '../../../../hooks/API/EPG/useContentServiceChannelsAPI';
import { CreateDataManager, DataManagerStateFullReturnType } from '../../../DataManager';
import { ContentServiceChannelsHook, ContentServiceChannelsHookReturnType } from './ContentServiceChannelsHook';

export type ContentServiceChannelsDataManagerType = {
  state: DataManagerStateFullReturnType<McpChannelResponse, McpChannelResponse>;
  hook: ContentServiceChannelsHookReturnType;
};

export type ContentServiceChannelsDataManagerReturnType = {
  state: DataManagerStateFullReturnType<McpChannelResponse, McpChannelResponse>;
  hook: () => ContentServiceChannelsHookReturnType;
};

function bucketIdToQueryParams(bucketId: string): Array<string[] | undefined> {
  return [undefined, ...bucketId.split(';').map((part) => (part ? part.split('-') : undefined))];
}

export const ContentServiceChannelsDataManager = CreateDataManager<
  McpChannelResponse,
  McpChannelResponse,
  ContentServiceChannelsDataManagerReturnType
>({
  name: 'contentServiceChannels',
  idField: 'id',
  hookInitializer: ContentServiceChannelsHook,
  useApiHook: useContentServiceChannelsAPI,
  bucketIdToQueryParams
});
