import { useSetRecoilState } from 'recoil';
import { McpChannelResponse } from '../../../../API';
import { CacheManager, DataManagerHook, DataManagerHookReturnType, IDataManagerHookProps } from '../../../DataManager';

export interface ContentServiceChannelsHookReturnType
  extends DataManagerHookReturnType<McpChannelResponse, McpChannelResponse> {
  removeFromCache: (id: string) => Promise<boolean>;
  unlinkChannel: (id: string) => Promise<void>;
  linkChannel: (id: string) => Promise<void>;
}

export function ContentServiceChannelsHook(
  params: IDataManagerHookProps<McpChannelResponse, McpChannelResponse>
): ContentServiceChannelsHookReturnType {
  const dataManagerHook = DataManagerHook<McpChannelResponse, McpChannelResponse>(params);
  const setCache = useSetRecoilState(params.state.withDataCache);
  const { addRecordToCache } = CacheManager(params.idField, setCache);

  const removeFromCache = async (id: string) => {
    const response = await dataManagerHook.removeFromCache(id);
    return response;
  };

  const unlinkChannel = async (id: string) => {
    const channel = await dataManagerHook.getById(id);
    if (channel) {
      addRecordToCache({ ...channel, linked: false });
    }
  };

  const linkChannel = async (id: string) => {
    const channel = await dataManagerHook.getById(id);
    if (channel) {
      addRecordToCache({ ...channel, linked: true });
    }
  };

  return {
    ...dataManagerHook,
    removeFromCache,
    unlinkChannel,
    linkChannel
  };
}
