import { USER_DATA_TOKEN } from '../hooks';
import { OperationIds } from '../API';

const customMetricName = 'custom-cms-';
// eslint-disable-next-line
export const newRelicAgent = (path: string, data?: any): void => {
  if (process.env.REACT_APP_NEW_RELIC_ENABLED && window.newrelic) {
    const newrelic = window.newrelic;
    newrelic.addPageAction(`${customMetricName}${path}`, sendActionInfo(data));
  }
};

// eslint-disable-next-line
const sendActionInfo = (data?: any) => {
  const editor = JSON.parse(localStorage.getItem(USER_DATA_TOKEN) as string);
  return {
    ...data,
    editorEmail: editor.profile.email,
    editorModifiedDate: new Date().toISOString()
  };
};

type NewRelicActionsStruct = {
  [key in OperationIds]?: string;
};

export const newRelicCmsActions: Record<string, NewRelicActionsStruct> = {
  users: {
    create: 'user-created',
    remove: 'user-deleted',
    update: 'user-updated'
  },
  channels: {
    create: 'channel-created',
    remove: 'channel-deleted',
    update: 'channel-updated',
    publish: 'channel-published'
  },
  categories: {
    create: 'category-created',
    update: 'category-updated',
    publish: 'category-published',
    remove: 'category-deleted'
  },
  collections: {
    create: 'collection-created',
    update: 'collection-updated',
    remove: 'collection-deleted',
    publish: 'collection-published'
  },
  layouts: {
    create: 'layout-created',
    update: 'layout-updated',
    remove: 'layout-deleted'
  }
};
