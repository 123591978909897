import { DocumentLocale, TournamentBody, TournamentResponse } from '../../../../API';
import { localeCompareFunction, UseLocalesReturnType } from '../../../../hooks';
import { useTournamentsAPI } from '../../../../hooks/API/Sports/useTournamentsAPI';
import { CreateDataManager, DataManagerReturnType } from '../../../DataManager';
import { TournamentsHook, TournamentsHookReturnType } from './TournamentsHook';
import { TournamentsState, TournamentsStateFullReturnType } from './TournamentsState';

export const EMPTY_TOURNAMENT_ID = 'null';

export const EMPTY_TOURNAMENT = {
  id: EMPTY_TOURNAMENT_ID,
  name: {
    [DocumentLocale.EN]: 'Non-League Teams',
    [DocumentLocale.ES]: 'Equipos sin Liga',
    [DocumentLocale.PT]: 'Equipas não-ligares'
  },
  tournamentId: EMPTY_TOURNAMENT_ID,
  sport: '',
  slug: 'non-league-teams'
};

export type TournamentsDataManagerType = {
  state: TournamentsStateFullReturnType;
  hook: TournamentsHookReturnType;
};

export interface TournamentsDataManagerReturnType extends DataManagerReturnType<TournamentResponse, TournamentBody> {
  state: TournamentsStateFullReturnType;
  hook: () => TournamentsHookReturnType;
}

export const TournamentsDataManager = CreateDataManager<
  TournamentResponse,
  TournamentBody,
  TournamentsDataManagerReturnType
>({
  stateInitializer: TournamentsState,
  hookInitializer: TournamentsHook,
  name: 'tournaments',
  idField: 'id',
  useApiHook: useTournamentsAPI,
  generateNew: (sportId: string) => ({
    tournamentId: '',
    sport: sportId,
    name: {
      [DocumentLocale.ES]: ''
    },
    description: {
      [DocumentLocale.ES]: ''
    },
    teams: [],
    slug: ''
  }),
  // Function used to generate a tournament string for success & error handling
  toString: (tournament: TournamentBody | TournamentResponse, locales: UseLocalesReturnType) =>
    `${locales.localize(tournament.name)} (${tournament.tournamentId})`,
  // Curried function to initialize a sort function
  sortFunctionInitializer: (locales) => localeCompareFunction<TournamentResponse>(locales, 'name')
});
