import { OfferMappingBody, OfferMappingResponse, OperationIds } from '../../../../API';
import { useOfferMappingsAPI } from '../../../../hooks/API/Payments/useOfferMappingsAPI';
import { CreateDataManager, DataManagerReturnType } from '../../../DataManager';
import { OfferMappingsHook, OfferMappingsHookReturnType } from './OfferMappingsHook';
import { OfferMappingsState, OfferMappingsStateFullReturnType } from './OfferMappingsState';

export type OfferMappingsDataManagerType = {
  state: OfferMappingsStateFullReturnType;
  hook: OfferMappingsHookReturnType;
};

export interface OfferMappingsDataManagerReturnType
  extends DataManagerReturnType<OfferMappingResponse, OfferMappingBody> {
  state: OfferMappingsStateFullReturnType;
  hook: () => OfferMappingsHookReturnType;
}

const generateNew = (): OfferMappingBody => {
  return {
    notes: '',
    source: {
      name: '',
      offer: {
        code: '',
        subscriptionManagerCouponMappings: [
          {
            code: '',
            country: ''
          }
        ]
      }
    }
  };
};

export const OfferMappingsDataManager = CreateDataManager<
  OfferMappingResponse,
  OfferMappingBody,
  OfferMappingsDataManagerReturnType
>({
  name: 'offerMappings',
  idField: 'id',
  isVersionedEntity: true,
  useApiHook: useOfferMappingsAPI,
  generateNew,
  hookInitializer: OfferMappingsHook,
  stateInitializer: OfferMappingsState,
  toString: (offerMapping: OfferMappingBody | OfferMappingResponse) => offerMapping.source.name,
  operationIdGetAll: OperationIds.GET_PAGINATED
});
