import { useSetRecoilState } from 'recoil';
import { useHandleError, useLocales } from '../General';
import { withAllMatches, withFetchingMatches } from '../../state/Sports';
import { useMediaAPI } from '../API/Media/useMediaAPI';
import { MediaResponse, UiModuleSportsEventType } from '../../API';

export interface SportsSmartQueryParameters {
  sportIds?: string[] | string | undefined;
  leagueIds?: string[] | string | undefined;
  countries?: string[];
}

type ReturnType = {
  getAllUpcomingMatches: (countries?: string[] | undefined) => Promise<void>;
  getMatchesByIds: (matchId: string[]) => Promise<MediaResponse[] | undefined>;
  getMatches: (
    eventsType: UiModuleSportsEventType,
    parameters?: SportsSmartQueryParameters
  ) => Promise<MediaResponse[] | undefined>;
};

export function useMatches(): ReturnType {
  const matchApi = useMediaAPI();
  const { t } = useLocales();
  const { handleError } = useHandleError();
  const setAllMatches = useSetRecoilState(withAllMatches);
  const setFetchingMatches = useSetRecoilState(withFetchingMatches);

  const getAllUpcomingMatches = async (countries?: string[] | undefined) => {
    setAllMatches(undefined);
    setFetchingMatches(true);
    const matches = await fetchMatches(UiModuleSportsEventType.LIVE_AND_UPCOMING, { countries: countries });
    setAllMatches(matches?.body);
    setFetchingMatches(false);
  };

  const asArray = (value: string[] | string | undefined): string[] | undefined => {
    return value ? (!Array.isArray(value) ? [value] : value) : undefined;
  };

  const getMatches = async (eventsType: UiModuleSportsEventType, parameters?: SportsSmartQueryParameters) => {
    return (await fetchMatches(eventsType, parameters))?.body;
  };

  const getMatchesByIds = async (matchIds: string[]) => {
    return await fetchMatchesById(matchIds);
  };

  const fetchMatchesById = async (matchIds: string[]) => {
    try {
      const { data } = await matchApi.getMatchesByIds(matchIds);
      return data.body;
    } catch (err) {
      console.error(err);
      handleError(err, t('errors.sports.get_matches'), [t('general.query_fields'), matchIds.join()]);
    }
  };

  const fetchMatches = async (eventsType: UiModuleSportsEventType, parameters?: SportsSmartQueryParameters) => {
    try {
      const { data } = await matchApi.getMatches(
        eventsType,
        asArray(parameters?.leagueIds),
        asArray(parameters?.sportIds),
        asArray(parameters?.countries)
      );
      return data;
    } catch (err) {
      console.error(err);
      handleError(err, t('errors.sports.get_matches'), [
        t('general.query_fields'),
        eventsType,
        asArray(parameters?.leagueIds)?.join() || '',
        asArray(parameters?.sportIds)?.join() || '',
        parameters?.countries?.join() || ''
      ]);
    }
  };

  return {
    getMatches,
    getMatchesByIds,
    getAllUpcomingMatches
  };
}
