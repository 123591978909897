import { atom } from 'recoil';
import { MediaResponse } from '../../API';

export const withAllMatches = atom<MediaResponse[] | undefined>({
  key: 'sports.allMatches',
  default: undefined
});

export const withFetchingMatches = atom({
  key: 'sports.fetchingMatches',
  default: false
});
