import React, { useEffect, useState } from 'react';
import { Add, Search } from '@mui/icons-material';
import { InputAdornment, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { useData } from '../../../../data-layer';
import { textFilterToRegExp, useLocales, useTimezones } from '../../../../hooks';
import { withPromotionsSplitPane } from '../../../../state/General';
import { ALL } from '../../../../utils/types/genericTypes';
import { PromotionStatusEnum } from '../../../../utils/types/paymentsTypes';
import Button from '../../../shared/Button';
import { DataGrid, DataGridColDef } from '../../../shared/DataGrid';
import FormControl from '../../../shared/FormControl';
import TextField from '../../../shared/TextField';
import PromotionStatus from '../PromoStatus/PromoStatus';
import PlanBadge from '../../Plans/PlanBadge/PlanBadge';
import CouponBadge from '../../Coupons/CouponBadge/CouponBadge';
import { DateTime } from 'luxon';
import { withPromotionsFilters } from '../../../../state/Payments/withPaymentsFilters';
import { SelectChangeEvent } from '@mui/material';
import { getPromotionStatus } from '../../../../utils/paymentsHelpers';
import { LayoutType, PermissionsGroupId, PromotionResponse } from '../../../../API';
import { usePermissions } from '../../../../hooks/Permissions/usePermissions';
import { IdBadge } from '../../../shared/IdBadge';
import { AppRoutes } from '../../../../Routes';
import { useNavigate } from 'react-router-dom';
import { usePermissionsGuard } from '../../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../../state/theme';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  filters: {
    display: 'flex',
    gap: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  textFilterContainer: {
    flexGrow: 1,
    display: 'flex',
    gap: theme.spacing(4)
  },
  textFilter: {
    width: 500,
    marginBottom: '0 !important'
  },
  gridContainer: {
    display: 'flex',
    flexGrow: 1
  },
  sharedSelectFilter: {
    width: 200
  },
  promoPlan: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    '&:not(:first-of-type)': {
      paddingTop: theme.spacing(2)
    },
    '&:not(:last-of-type)': {
      paddingBottom: theme.spacing(2),
      borderBottom: `1px dotted ${theme.palette.divider}`
    }
  }
}));

export const testIds = {
  textFilter: 'promo-manager.text-filter',
  statusFilter: 'promo-manager.status-filter',
  statusFilterOption: 'promo-manager.status-filter-option',
  audienceFilter: 'promo-manager.audience-filter',
  audienceFilterOption: 'promo-manager.audience-filter-option',
  promoTypeFilter: 'promo-manager.promo-type-filter',
  promoTypeFilterOption: 'promo-manager.promo-type-filter-option',
  promoIds: 'promo-manager.promo-id'
};

export function PromoManager(): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { timestamp } = useTimezones();
  const navigate = useNavigate();
  const {
    promotions: {
      state: { withAllRecords: withAllPromotions, withPromotionTypes },
      hook: { edit: editPromotion, new: newPromotion, getPromotionTypes }
    },
    plans: {
      state: { withAllRecords: withAllPlans }
    },
    coupons: {
      state: { withAllRecords: withAllCoupons }
    },
    pages: {
      state: { withRecordBucket }
    },
    audiences: {
      state: { withAllRecords: withAllAudiences }
    }
  } = useData();
  const promotions = useRecoilValue(withAllPromotions);
  const coupons = useRecoilValue(withAllCoupons);
  const plans = useRecoilValue(withAllPlans);
  const promoPages = useRecoilValue(withRecordBucket(LayoutType.PROMOS));
  const [promotionTypes, setPromotionTypes] = useRecoilState(withPromotionTypes);
  const audiences = useRecoilValue(withAllAudiences);

  const [promotionTypesMap, setPromotionTypesMap] = useState<{ [key: string]: string }>({});

  const { canSave, canRead } = usePermissionsGuard({
    homepageOption: HomepageOptions.MONETIZATION
  });
  const { hasUpsertPermission, hasReadPermission } = usePermissions();
  const hasMonetizationReadPermissions = hasReadPermission(PermissionsGroupId.MONETIZATION) || canRead;

  const [filters, setFilters] = useRecoilState(withPromotionsFilters);
  const [filteredPromotions, setFilteredPromotions] = useState<PromotionResponse[] | undefined>();

  const allLoaded = !!promotions && !!coupons && !!plans && !!promoPages && !!promotionTypes && !!audiences;

  useEffect(() => {
    if (!hasMonetizationReadPermissions) {
      navigate(AppRoutes.welcome);
    }
  }, [hasMonetizationReadPermissions]);

  const fetchPromotionTypes = async () => {
    const promotionTypes = await getPromotionTypes();
    if (promotionTypes && Array.isArray(promotionTypes)) {
      setPromotionTypes(promotionTypes);

      const typesMap: { [key: string]: string } = {};

      promotionTypes.forEach((promotionType) => {
        if (promotionType.code && promotionType.description) {
          typesMap[promotionType.code] = promotionType.description;
        }
      });

      setPromotionTypesMap(typesMap);
    }
  };

  useEffect(() => {
    updateFilteredPromotions();
  }, [promotions, filters]);

  useEffect(() => {
    if (!promotionTypes.length) {
      fetchPromotionTypes();
    }
  }, []);

  const dateSortWithFallbackDate =
    (key: keyof PromotionResponse, fallbackKey: keyof PromotionResponse) =>
    (a: PromotionResponse, b: PromotionResponse) => {
      const aDate = a[key] || a[fallbackKey];
      const bDate = b[key] || b[fallbackKey];
      if (!aDate && bDate) return 0;
      if (!aDate) return 1;
      if (!bDate) return -1;
      const aProp = DateTime.fromISO(String(aDate));
      const bProp = DateTime.fromISO(String(bDate));
      return aProp.diff(bProp).toObject().milliseconds || 0;
    };

  const columns: DataGridColDef<PromotionResponse>[] = [
    {
      key: 'name',
      cellRenderer: (promotion) => promotion.name,
      label: t('payments.name'),
      sortFunction: (a, b) => a.name.localeCompare(b.name),
      baseWidth: '15%'
    },
    {
      key: 'id',
      cellRenderer: (promotion) => <>{promotion.id && <IdBadge id={promotion.id} data-testid={testIds.promoIds} />}</>,
      label: t('payments.promotion_id'),
      baseWidth: '12%'
    },
    {
      key: 'status',
      cellRenderer: (promotion) => <PromotionStatus promotion={promotion} />,
      label: t('payments.status'),
      baseWidth: '10%'
    },
    {
      key: 'startedAt',
      cellRenderer: (promotion) => {
        const startDate = promotion.startedAt || promotion.startDate;
        if (startDate) return timestamp(startDate);
      },
      label: t('date_picker.start_date'),
      sortFunction: dateSortWithFallbackDate('startedAt', 'startDate'),
      baseWidth: '15%'
    },
    {
      key: 'endedAt',
      cellRenderer: (promotion) => {
        const endDate = promotion.endedAt || promotion.endDate;
        if (endDate) return timestamp(endDate);
      },
      label: t('date_picker.end_date'),
      sortFunction: dateSortWithFallbackDate('endedAt', 'endDate'),
      baseWidth: '15%'
    },
    {
      key: 'type',
      cellRenderer: (promotion) => {
        if (promotion?.type) {
          return promotionTypesMap[promotion.type] || promotion.type;
        }
        return '';
      },
      sortFunction: (a, b) => (a.type || '').localeCompare(b.type || ''),
      label: t('payments.promotion_type'),
      baseWidth: '15%'
    },
    {
      key: 'audience',
      cellRenderer: (promotion) => {
        return promotion.audience;
      },
      sortFunction: (a, b) => (a.audience || '').localeCompare(b.audience || ''),
      label: t('payments.promotion_audiences'),
      baseWidth: '15%'
    },
    {
      key: 'plans',
      cellRenderer: (promotion) => (
        <>
          {promotion.plans.map((promoPlan, i) => (
            <div className={classes.promoPlan} key={i}>
              <Stack direction="row" gap={2}>
                <PlanBadge planCode={promoPlan.code} />
                {promoPlan.changes?.couponCodes?.map((couponCode) => (
                  <CouponBadge key={couponCode} couponCode={couponCode} />
                ))}
              </Stack>
              {promoPlan.changes && (
                <Stack direction="row" gap={5}>
                  <div>
                    <b>{t('payments.promo_group')}:</b> {promoPlan.changes.promoGroup}
                  </div>
                  <div>
                    <b>{t('payments.template_id')}:</b> {promoPlan.changes.localizationTemplateId}
                  </div>
                  <div>
                    <b>{t('payments.duration')}:</b> {promoPlan.changes.promoPriceLength}{' '}
                    {promoPlan.changes.promoPriceUnit}
                  </div>
                </Stack>
              )}
            </div>
          ))}
        </>
      ),
      label: t('payments.plan_items'),
      baseWidth: '45%'
    }
  ];

  const onPromotionClick = (promotion: PromotionResponse) => {
    if (!allLoaded) return;
    editPromotion(promotion.id);
  };

  const onTextFilterChanged: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
    setFilters({
      ...filters,
      text: evt.target.value
    });
  };

  const onStatusFilterChanged = (evt: SelectChangeEvent<string>) => {
    setFilters({
      ...filters,
      status: evt.target.value
    });
  };

  const onAudienceFilterChanged = (evt: SelectChangeEvent<string>) => {
    setFilters({
      ...filters,
      audience: evt.target.value
    });
  };

  const onTypeFilterChanged = (evt: SelectChangeEvent<string>) => {
    setFilters({
      ...filters,
      promoType: evt.target.value
    });
  };

  const updateFilteredPromotions = () => {
    if (!promotions) return;
    const newFilteredPromotions = promotions.filter((promotion) => {
      if (filters.text) {
        const textFilterRgx = textFilterToRegExp(filters.text);
        if (!textFilterRgx.test(promotion.name) && !textFilterRgx.test(promotion.id)) {
          return false;
        }
      }
      if (filters.status !== ALL) {
        if (getPromotionStatus(promotion) !== filters.status) {
          return false;
        }
      }

      if (
        filters.audience &&
        filters.audience !== '' &&
        filters.audience !== ALL &&
        promotion.audience !== filters.audience
      ) {
        return false;
      }

      if (
        filters.promoType &&
        filters.promoType !== '' &&
        filters.promoType !== ALL &&
        promotion.type !== filters.promoType
      ) {
        return false;
      }

      return true;
    });
    setFilteredPromotions(newFilteredPromotions);
  };

  return (
    <div className={classes.root}>
      <div className={classes.filters}>
        <div className={classes.textFilterContainer}>
          <TextField
            clearable
            label={t('payments.filter_by_keywords')}
            className={classes.textFilter}
            value={filters.text}
            onChange={onTextFilterChanged}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            data-testid={testIds.textFilter}
          />
          <FormControl className={classes.sharedSelectFilter}>
            <InputLabel shrink variant="standard">
              {t('payments.filter_by_status')}
            </InputLabel>
            <Select
              label={t('payments.filter_by_status')}
              value={filters.status}
              onChange={onStatusFilterChanged}
              data-testid={testIds.statusFilter}
            >
              <MenuItem value={ALL} data-testid={testIds.statusFilterOption}>
                {t('filters.all')}
              </MenuItem>
              {Object.values(PromotionStatusEnum).map((status) => (
                <MenuItem key={status} value={status} data-testid={testIds.statusFilterOption}>
                  {t(`payments.promo_status.${status}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.sharedSelectFilter}>
            <InputLabel shrink variant="standard">
              {t('payments.filter_by_audience')}
            </InputLabel>
            <Select
              label={t('payments.filter_by_audience')}
              value={filters.audience}
              onChange={onAudienceFilterChanged}
              data-testid={testIds.audienceFilter}
              defaultValue={ALL}
            >
              <MenuItem value={ALL} data-testid={testIds.audienceFilterOption}>
                {t('filters.all')}
              </MenuItem>
              {audiences &&
                audiences.map((audience) => (
                  <MenuItem key={audience.name} value={audience.name} data-testid={testIds.audienceFilterOption}>
                    {audience.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl className={classes.sharedSelectFilter}>
            <InputLabel shrink variant="standard">
              {t('payments.filter_by_type')}
            </InputLabel>
            <Select
              label={t('payments.filter_by_type')}
              value={filters.promoType}
              onChange={onTypeFilterChanged}
              data-testid={testIds.promoTypeFilter}
              defaultValue={ALL}
            >
              <MenuItem value={ALL} data-testid={testIds.promoTypeFilterOption}>
                {t('filters.all')}
              </MenuItem>
              {promotionTypes.map((promoType) => (
                <MenuItem
                  key={promoType?.code || ''}
                  value={promoType?.code || ''}
                  data-testid={testIds.promoTypeFilterOption}
                >
                  {promoType.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Button
          endIcon={<Add />}
          disabled={!allLoaded || (!hasUpsertPermission(PermissionsGroupId.MONETIZATION) && !canSave)}
          onClick={newPromotion}
        >
          {t('payments.new_promotion')}
        </Button>
      </div>
      <div className={classes.gridContainer}>
        <DataGrid
          rows={filteredPromotions}
          columns={columns}
          onRowClick={(promotion) => onPromotionClick(promotion)}
          withColumnSizes={withPromotionsSplitPane}
          useVirtualization
          rowKey="name"
        />
      </div>
    </div>
  );
}
