/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiPaginatedResponseOfDataPresetResponse } from '../model';
// @ts-ignore
import { ApiResponseOfDataPresetResponse } from '../model';
// @ts-ignore
import { ApiResponseOfDataPresetResponseArray } from '../model';
// @ts-ignore
import { DataPresetBody } from '../model';
// @ts-ignore
import { DataPresetType } from '../model';
/**
 * DataPresetApi - axios parameter creator
 * @export
 */
export const DataPresetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DataPresetBody} dataPresetBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (dataPresetBody: DataPresetBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataPresetBody' is not null or undefined
            assertParamExists('create', 'dataPresetBody', dataPresetBody)
            const localVarPath = `/api/v1/dataPresets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataPresetBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} hash 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByHash: async (hash: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('getByHash', 'hash', hash)
            const localVarPath = `/api/v1/dataPresets/getByHash/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataPresetId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (dataPresetId: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataPresetId' is not null or undefined
            assertParamExists('getById', 'dataPresetId', dataPresetId)
            const localVarPath = `/api/v1/dataPresets/{dataPresetId}`
                .replace(`{${"dataPresetId"}}`, encodeURIComponent(String(dataPresetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} dataPresetIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIds: async (dataPresetIds: Array<string>, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataPresetIds' is not null or undefined
            assertParamExists('getByIds', 'dataPresetIds', dataPresetIds)
            const localVarPath = `/api/v1/dataPresets/getByIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataPresetIds) {
                localVarQueryParameter['dataPresetIds'] = dataPresetIds;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {DataPresetType} [type] 
         * @param {string} [name] 
         * @param {string} [contentId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPaginated: async (page?: number, limit?: number, type?: DataPresetType, name?: string, contentId?: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dataPresets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (contentId !== undefined) {
                localVarQueryParameter['contentId'] = contentId;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {Array<DataPresetType>} [type] 
         * @param {string} [name] 
         * @param {string} [contentId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedV2: async (limit?: number, page?: number, type?: Array<DataPresetType>, name?: string, contentId?: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/dataPresets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (contentId !== undefined) {
                localVarQueryParameter['contentId'] = contentId;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataPresetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (dataPresetId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataPresetId' is not null or undefined
            assertParamExists('remove', 'dataPresetId', dataPresetId)
            const localVarPath = `/api/v1/dataPresets/{dataPresetId}`
                .replace(`{${"dataPresetId"}}`, encodeURIComponent(String(dataPresetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataPresetId 
         * @param {DataPresetBody} dataPresetBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (dataPresetId: string, dataPresetBody: DataPresetBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataPresetId' is not null or undefined
            assertParamExists('update', 'dataPresetId', dataPresetId)
            // verify required parameter 'dataPresetBody' is not null or undefined
            assertParamExists('update', 'dataPresetBody', dataPresetBody)
            const localVarPath = `/api/v1/dataPresets/{dataPresetId}`
                .replace(`{${"dataPresetId"}}`, encodeURIComponent(String(dataPresetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataPresetBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataPresetApi - functional programming interface
 * @export
 */
export const DataPresetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataPresetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DataPresetBody} dataPresetBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(dataPresetBody: DataPresetBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfDataPresetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(dataPresetBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} hash 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByHash(hash: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfDataPresetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByHash(hash, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dataPresetId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(dataPresetId: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfDataPresetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(dataPresetId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} dataPresetIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIds(dataPresetIds: Array<string>, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfDataPresetResponseArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIds(dataPresetIds, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {DataPresetType} [type] 
         * @param {string} [name] 
         * @param {string} [contentId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getPaginated(page?: number, limit?: number, type?: DataPresetType, name?: string, contentId?: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfDataPresetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginated(page, limit, type, name, contentId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {Array<DataPresetType>} [type] 
         * @param {string} [name] 
         * @param {string} [contentId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedV2(limit?: number, page?: number, type?: Array<DataPresetType>, name?: string, contentId?: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfDataPresetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedV2(limit, page, type, name, contentId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dataPresetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(dataPresetId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(dataPresetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dataPresetId 
         * @param {DataPresetBody} dataPresetBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(dataPresetId: string, dataPresetBody: DataPresetBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOfDataPresetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(dataPresetId, dataPresetBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataPresetApi - factory interface
 * @export
 */
export const DataPresetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataPresetApiFp(configuration)
    return {
        /**
         * 
         * @param {DataPresetBody} dataPresetBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(dataPresetBody: DataPresetBody, options?: any): AxiosPromise<ApiResponseOfDataPresetResponse> {
            return localVarFp.create(dataPresetBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} hash 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByHash(hash: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfDataPresetResponse> {
            return localVarFp.getByHash(hash, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataPresetId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(dataPresetId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfDataPresetResponse> {
            return localVarFp.getById(dataPresetId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} dataPresetIds 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIds(dataPresetIds: Array<string>, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfDataPresetResponseArray> {
            return localVarFp.getByIds(dataPresetIds, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {DataPresetType} [type] 
         * @param {string} [name] 
         * @param {string} [contentId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPaginated(page?: number, limit?: number, type?: DataPresetType, name?: string, contentId?: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfDataPresetResponse> {
            return localVarFp.getPaginated(page, limit, type, name, contentId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {Array<DataPresetType>} [type] 
         * @param {string} [name] 
         * @param {string} [contentId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedV2(limit?: number, page?: number, type?: Array<DataPresetType>, name?: string, contentId?: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfDataPresetResponse> {
            return localVarFp.getPaginatedV2(limit, page, type, name, contentId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataPresetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(dataPresetId: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(dataPresetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataPresetId 
         * @param {DataPresetBody} dataPresetBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(dataPresetId: string, dataPresetBody: DataPresetBody, options?: any): AxiosPromise<ApiResponseOfDataPresetResponse> {
            return localVarFp.update(dataPresetId, dataPresetBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataPresetApi - interface
 * @export
 * @interface DataPresetApi
 */
export interface DataPresetApiInterface {
    /**
     * 
     * @param {DataPresetBody} dataPresetBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPresetApiInterface
     */
    create(dataPresetBody: DataPresetBody, options?: any): AxiosPromise<ApiResponseOfDataPresetResponse>;

    /**
     * 
     * @param {string} hash 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPresetApiInterface
     */
    getByHash(hash: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfDataPresetResponse>;

    /**
     * 
     * @param {string} dataPresetId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPresetApiInterface
     */
    getById(dataPresetId: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfDataPresetResponse>;

    /**
     * 
     * @param {Array<string>} dataPresetIds 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPresetApiInterface
     */
    getByIds(dataPresetIds: Array<string>, includeDeleted?: boolean, options?: any): AxiosPromise<ApiResponseOfDataPresetResponseArray>;

    /**
     * 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {DataPresetType} [type] 
     * @param {string} [name] 
     * @param {string} [contentId] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DataPresetApiInterface
     */
    getPaginated(page?: number, limit?: number, type?: DataPresetType, name?: string, contentId?: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfDataPresetResponse>;

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {Array<DataPresetType>} [type] 
     * @param {string} [name] 
     * @param {string} [contentId] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPresetApiInterface
     */
    getPaginatedV2(limit?: number, page?: number, type?: Array<DataPresetType>, name?: string, contentId?: string, includeDeleted?: boolean, options?: any): AxiosPromise<ApiPaginatedResponseOfDataPresetResponse>;

    /**
     * 
     * @param {string} dataPresetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPresetApiInterface
     */
    remove(dataPresetId: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {string} dataPresetId 
     * @param {DataPresetBody} dataPresetBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPresetApiInterface
     */
    update(dataPresetId: string, dataPresetBody: DataPresetBody, options?: any): AxiosPromise<ApiResponseOfDataPresetResponse>;

}

/**
 * DataPresetApi - object-oriented interface
 * @export
 * @class DataPresetApi
 * @extends {BaseAPI}
 */
export class DataPresetApi extends BaseAPI implements DataPresetApiInterface {
    /**
     * 
     * @param {DataPresetBody} dataPresetBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPresetApi
     */
    public create(dataPresetBody: DataPresetBody, options?: any) {
        return DataPresetApiFp(this.configuration).create(dataPresetBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} hash 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPresetApi
     */
    public getByHash(hash: string, includeDeleted?: boolean, options?: any) {
        return DataPresetApiFp(this.configuration).getByHash(hash, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataPresetId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPresetApi
     */
    public getById(dataPresetId: string, includeDeleted?: boolean, options?: any) {
        return DataPresetApiFp(this.configuration).getById(dataPresetId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} dataPresetIds 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPresetApi
     */
    public getByIds(dataPresetIds: Array<string>, includeDeleted?: boolean, options?: any) {
        return DataPresetApiFp(this.configuration).getByIds(dataPresetIds, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {DataPresetType} [type] 
     * @param {string} [name] 
     * @param {string} [contentId] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DataPresetApi
     */
    public getPaginated(page?: number, limit?: number, type?: DataPresetType, name?: string, contentId?: string, includeDeleted?: boolean, options?: any) {
        return DataPresetApiFp(this.configuration).getPaginated(page, limit, type, name, contentId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {Array<DataPresetType>} [type] 
     * @param {string} [name] 
     * @param {string} [contentId] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPresetApi
     */
    public getPaginatedV2(limit?: number, page?: number, type?: Array<DataPresetType>, name?: string, contentId?: string, includeDeleted?: boolean, options?: any) {
        return DataPresetApiFp(this.configuration).getPaginatedV2(limit, page, type, name, contentId, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataPresetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPresetApi
     */
    public remove(dataPresetId: string, options?: any) {
        return DataPresetApiFp(this.configuration).remove(dataPresetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataPresetId 
     * @param {DataPresetBody} dataPresetBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPresetApi
     */
    public update(dataPresetId: string, dataPresetBody: DataPresetBody, options?: any) {
        return DataPresetApiFp(this.configuration).update(dataPresetId, dataPresetBody, options).then((request) => request(this.axios, this.basePath));
    }
}
