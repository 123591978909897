import React from 'react';
import { GitHub, Label } from '@mui/icons-material';
import { Link, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { VersionLogCommit } from '../../../../API';
import { useLocales, useTheme, useTimezones } from '../../../../hooks';
import Button from '../../../shared/Button';

const useStyles = makeStyles()((theme) => ({
  listItem: {
    padding: theme.spacing(3, 4),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  link: {
    textDecoration: 'none'
  },
  timestamp: {
    marginTop: theme.spacing(1)
  },
  bottomLine: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center'
  }
}));

interface VersionLogCommitItemProps {
  commit: VersionLogCommit;
  githubProject: string;
}

export interface VersionLogCommitMetadata {
  linkUrl: string;
  message: string;
  ticketId?: string;
  ticketUrl?: string;
}

export const extractCommitMetadata = (commit: VersionLogCommit, githubProject: string): VersionLogCommitMetadata => {
  const prMatch = commit.message.match(/(.*)(\s\(#(\d+)\))/);
  let message = prMatch?.[1] ?? commit.message;
  const linkUrl = prMatch
    ? `https://github.com/televisa-univision/${githubProject}/pull/${prMatch[3]}`
    : `https://github.com/televisa-univision/${githubProject}/commit/${commit.sha}`;

  const ticketMatch = message.match(/^[^\w]*cms.?(\d+)[^\w]*(.*)/i);
  const ticketId = ticketMatch ? ticketMatch[1] : undefined;
  const ticketUrl = ticketMatch ? `https://televisaunivision.atlassian.net/browse/CMS-${ticketMatch[1]}` : undefined;
  message = ticketMatch ? ticketMatch[2] : message;
  return {
    linkUrl,
    message,
    ticketId,
    ticketUrl
  };
};

export function VersionLogCommitItem({ commit, githubProject }: VersionLogCommitItemProps): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { timestamp } = useTimezones();
  const { formControlColor } = useTheme();

  const { linkUrl, message, ticketId, ticketUrl } = extractCommitMetadata(commit, githubProject);

  return (
    <div className={classes.listItem}>
      <div>{message}</div>
      <div className={classes.bottomLine}>
        <Typography className={classes.timestamp} variant="body2" color="textSecondary">
          {t('version_log.merged_on')} {`${timestamp(commit.date)}`}
        </Typography>
        <Link target="_blank" rel="noreferrer" className={classes.link} color={formControlColor} href={linkUrl}>
          <Button variant="text" size="small" color={formControlColor} startIcon={<GitHub />}>
            {t('general.view_details')}
          </Button>
        </Link>
        {ticketId && (
          <Link target="_blank" rel="noreferrer" className={classes.link} color={formControlColor} href={ticketUrl}>
            <Button variant="text" size="small" color={formControlColor} startIcon={<Label />}>
              CMS-{ticketId}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
}
