import React, { useEffect } from 'react';
import { useLocales, useTheme } from '../../../hooks';
import { OpenInBrowser } from '@mui/icons-material';
import { useData } from '../../../data-layer';
import { Button, IButtonProps } from '../Button/Button';
import { useRecoilValue } from 'recoil';
import { Tooltip } from '@mui/material';
import { stopPropagation } from '../../../utils/generalUtils';

export const testIds = {
  root: 'view-content-button.root'
};

type ViewContentButtonProps = {
  contentId: string | undefined;
  color?: IButtonProps['color'] | 'formControlColor';
} & Pick<IButtonProps, 'variant' | 'size'>;

export function ViewContentButton({ contentId, variant, size, color }: ViewContentButtonProps): React.ReactElement {
  const {
    media: {
      state: { withRecordById, withFormMetadata },
      hook: { edit, queueIdToFetch }
    }
  } = useData();
  const formMetadata = useRecoilValue(withFormMetadata);
  const content = useRecoilValue(withRecordById(contentId));
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  useEffect(() => {
    if (!content && contentId) {
      queueIdToFetch(contentId);
    }
  }, [contentId]);

  const handleClick = () => {
    if (contentId) {
      edit(contentId);
    }
  };

  return (
    <Tooltip title={t('content.view_more')} arrow>
      <span>
        <Button
          disabled={!contentId || !content || formMetadata.isShowingForm}
          data-testid={testIds.root}
          color={color === 'formControlColor' ? formControlColor : color}
          variant={variant}
          size={size}
          onClick={stopPropagation(handleClick)}
          endIcon={<OpenInBrowser />}
        >
          {t('content.drawer.view')}
        </Button>
      </span>
    </Tooltip>
  );
}
