import React, { forwardRef } from 'react';
import { Box, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { LivePlusUiModuleBody } from '../../../../../API';
import { useLocales } from '../../../../../hooks';
import { LocaleKeys } from '../../../../../locales/i18n';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { markAsRequired } from '../../../../../utils/formHelpers';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { FormBody, FormField, SectionHeader } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';

export const testIds = {
  formBody: 'live-plus-form.form-body',
  formField: (name: string): string => `live-plus-form.${name}`
};

const LivePlusForm = forwardRef<UIModuleTypeForm<LivePlusUiModuleBody>>(() => {
  const { control, watch } = useFormContext<LivePlusUiModuleBody>();
  const { t } = useLocales();

  const moduleId = watch('moduleId');

  const renderFormLabel = (label: string, className?: string) => {
    return (
      <Typography component="div" color="textSecondary" variant="body2" className={className}>
        {label}
      </Typography>
    );
  };

  type LivePlusProps = keyof LivePlusUiModuleBody;

  const LivePlusTextValidator = (name: LivePlusProps): JSX.Element => (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <LokaliseAutocomplete
          label={t(`layouts.live_plus.fields.${name}` as LocaleKeys) as string}
          name={name}
          value={value as string}
          data-testid={testIds.formField(name)}
          onChange={onChange}
        />
      )}
    />
  );

  const LivePlusAssetPicker = ({
    name,
    assetType,
    required = false
  }: {
    name: LivePlusProps;
    assetType: AssetTypesEnum;
    required?: boolean;
  }): JSX.Element => {
    const isRequired = (label: string) => (required ? markAsRequired(label) : label);
    return (
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormField>
            {renderFormLabel(isRequired(t(`layouts.live_plus.fields.${name}`)))}
            <AssetBrowser
              assetId={moduleId || ''}
              assetType={assetType}
              value={value as string}
              data-testid={testIds.formField(name)}
              hasError={!!error}
              onChange={({ target: { value } }) => onChange(value)}
            />
          </FormField>
        )}
      />
    );
  };

  return (
    <FormBody data-testid={testIds.formBody}>
      <Box>
        <SectionHeader sx={{ display: 'flex', alignItems: 'center', gap: (theme) => theme.spacing(4) }}>
          {t('layouts.live_plus.headers.home')}
          <Typography variant="body2" color="textSecondary" sx={{ fontStyle: 'italic' }}>
            {t('layouts.drag_text')}
          </Typography>
        </SectionHeader>
        <LivePlusAssetPicker name="landscapeTakeoverImage" assetType={AssetTypes.livePlus.landscapeTakeover} required />
        <LivePlusAssetPicker name="portraitTakeoverImage" assetType={AssetTypes.livePlus.portraitTakeover} required />
        <LivePlusAssetPicker name="desktopTakeoverImage" assetType={AssetTypes.livePlus.desktopTakeover} />
        <LivePlusAssetPicker name="tabletTakeoverImage" assetType={AssetTypes.livePlus.tabletTakeover} />
        <LivePlusAssetPicker name="mobileTakeoverImage" assetType={AssetTypes.livePlus.mobileTakeover} />
        <LivePlusAssetPicker name="ctvTakeoverImage" assetType={AssetTypes.livePlus.ctvTakeover} />
        <FormField>{LivePlusTextValidator('takeoverHeader')}</FormField>
        <FormField>{LivePlusTextValidator('takeoverLead')}</FormField>
        <FormField>{LivePlusTextValidator('takeoverExitCta')}</FormField>
      </Box>
      <Box>
        <SectionHeader>{t('layouts.live_plus.headers.modal')}</SectionHeader>
        <FormField>{LivePlusTextValidator('formHeader')}</FormField>
        <FormField>{LivePlusTextValidator('formLead')}</FormField>
        <FormField>{LivePlusTextValidator('formEmailPlaceholder')}</FormField>
        <FormField>{LivePlusTextValidator('formEmailValidationError')}</FormField>
        <FormField>{LivePlusTextValidator('formOptInLabel')}</FormField>
        <FormField>{LivePlusTextValidator('formOptInValidationError')}</FormField>
        <FormField>{LivePlusTextValidator('formInvalidEmailValidationError')}</FormField>
        <FormField>{LivePlusTextValidator('formSubmitCta')}</FormField>
        <FormField>{LivePlusTextValidator('formCancelCta')}</FormField>
      </Box>
      <Box>
        <SectionHeader>{t('layouts.live_plus.headers.end_game')}</SectionHeader>
        <LivePlusAssetPicker name="landscapeEndGameImage" assetType={AssetTypes.livePlus.landscapeEndGame} required />
        <LivePlusAssetPicker name="portraitEndGameImage" assetType={AssetTypes.livePlus.portraitEndGame} required />
        <LivePlusAssetPicker name="desktopEndGameImage" assetType={AssetTypes.livePlus.desktopEndGame} />
        <LivePlusAssetPicker name="tabletEndGameImage" assetType={AssetTypes.livePlus.tabletEndGame} />
        <LivePlusAssetPicker name="mobileEndGameImage" assetType={AssetTypes.livePlus.mobileEndGame} />
        <LivePlusAssetPicker name="ctvEndGameImage" assetType={AssetTypes.livePlus.ctvEndGame} />
        <FormField>{LivePlusTextValidator('endGameHeader')}</FormField>
        <FormField>{LivePlusTextValidator('endGameLead')}</FormField>
        <FormField>{LivePlusTextValidator('endGameContinueVixCta')}</FormField>
      </Box>
    </FormBody>
  );
});

LivePlusForm.displayName = 'LivePlusForm';

export default LivePlusForm;
