import { UIModuleType } from '../layouts';

/**
 * Mapping of UIModuleType to the localized field path in the CMS
 */
export const UI_MODULES_LOCALIZED_FIELDS: Partial<Record<UIModuleType, string>> = {
  ACCOUNT_CREATION: 'layouts.account_creation.fields',
  ACCOUNT_LOADING: 'layouts.account_loading.fields',
  ACCOUNT_SUCCESS: 'layouts.account_success.fields',
  AI_SEARCH: 'layouts.ai_search.fields',
  INLINE_PAGE: 'layouts.inline_page.fields',
  GEO_BLOCK: 'layouts.geo_block.fields',
  LANDING_PROMO: 'layouts.landing_promo.fields',
  LINKING_ACCOUNT: 'layouts.linking_account.fields',
  LIVE_PLUS: 'layouts.live_plus.fields',
  NEW_PARTNER_LANDING: 'layouts.new_partner_landing.fields',
  ONE_BRAND_PROMO: 'layouts.one_brand_promo.fields',
  PLAN_SUMMARY: 'layouts.plan_summary.fields',
  PLAYBACK_PAYWALL: 'layouts.playback_paywall.fields',
  PROFILE: 'layouts.profile.fields',
  PROMOTION: 'layouts.promotion.fields',
  REGISTRATION_WALL: 'layouts.reg_wall.fields',
  SPIN_PREMIA: 'layouts.spin_premia.fields',
  TDS_GIFT_CARDS: 'layouts.tds_gift_cards.fields',
  WHO_IS_WATCHING: 'layouts.who_is_watching.fields'
};

export function getLocalizedModuleField(moduleType?: UIModuleType): string {
  return moduleType ? UI_MODULES_LOCALIZED_FIELDS[moduleType] ?? '' : '';
}
