import React, { forwardRef, MutableRefObject, useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, SxProps, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import useResizeObserver from 'use-resize-observer';

export interface IShadowScrollerStoryProps {
  loading?: boolean;
  saving?: boolean;
  paper?: boolean;
  sx?: SxProps<Theme>;
  ref?: React.RefObject<HTMLDivElement>;
  horizontalScrolling?: boolean;
  'data-testid'?: string;
}

interface IShadowScrollerProps extends IShadowScrollerStoryProps {
  children: React.ReactNode;
}

const shadowHeight = 13;

const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    background: theme.palette.background.default
  },
  containerPaper: {
    background: theme.palette.background.paper
  },
  slot: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    minHeight: '100%',
    transition: 'filter 0.3s ease-in-out'
  },
  slotSaving: {
    filter: 'blur(2px)'
  },
  shadowContainer: {
    position: 'sticky',
    width: '100%',
    zIndex: 10
  },
  shadowContainerTop: {
    top: 0
  },
  shadowContainerBottom: {
    bottom: 0
  },
  shadow: {
    position: 'absolute',
    width: '100%',
    height: shadowHeight,
    opacity: 0
  },
  shadowTop: {
    top: 0,
    background: `linear-gradient(to bottom, rgba(0,0,0,${theme.palette.mode === 'dark' ? 0.15 : 0.07}), rgba(0,0,0,0))`,
    borderTop: '1px solid',
    borderTopColor: `rgba(0,0,0,${theme.palette.mode === 'dark' ? 0.25 : 0.1})`,
    pointerEvents: 'none'
  },
  shadowBottom: {
    bottom: 0,
    background: `linear-gradient(to top, rgba(0,0,0,${theme.palette.mode === 'dark' ? 0.15 : 0.07}), rgba(0,0,0,0))`,
    borderBottom: '1px solid',
    borderBottomColor: `rgba(0,0,0,${theme.palette.mode === 'dark' ? 0.25 : 0.1})`,
    pointerEvents: 'none'
  },
  loadingFade: {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to bottom, transparent, ' + theme.palette.background.default + ')'
  },
  loadingFadePaper: {
    background: 'linear-gradient(to bottom, transparent, ' + theme.palette.background.paper + ')'
  },
  savingContainer: {
    position: 'sticky',
    zIndex: 1,
    top: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  savingContainerInner: {
    textAlign: 'center'
  }
}));

const scrollDistanceToMaxOpacity = 50;

const ShadowScroller = forwardRef<HTMLDivElement, IShadowScrollerProps>(
  ({ loading, saving, paper, children, horizontalScrolling = true, sx, ...props }, ref) => {
    const [topShadowOpacity, setTopShadowOpacity] = useState<number>(0);
    const [bottomShadowOpacity, setBottomShadowOpacity] = useState<number>(0);
    const scrollerRef = useRef<HTMLDivElement | null>(null);
    const [scrollerHeight, setScrollerHeight] = useState(0);
    const { cx, classes } = useStyles();
    const { height } = useResizeObserver({ ref: scrollerRef });

    useEffect(() => {
      setScrollerHeight(height ?? 0);
      resetBottomShadowOpacity();
    }, [height, children]);

    useEffect(() => {
      if (scrollerRef && scrollerRef.current && loading) {
        scrollerRef.current.scrollTop = 0;
      }
    }, [loading]);

    const resetBottomShadowOpacity = () => {
      if (scrollerRef && scrollerRef.current) {
        const newShadowOpacity = getShadowOpacity(
          scrollerRef.current.scrollHeight - scrollerRef.current.offsetHeight - scrollerRef.current.scrollTop
        );
        if (newShadowOpacity !== bottomShadowOpacity) {
          setBottomShadowOpacity(newShadowOpacity);
        }
      }
    };

    const onScroll = () => {
      if (scrollerRef && scrollerRef.current) {
        setTopShadowOpacity(getShadowOpacity(scrollerRef.current.scrollTop));
        setBottomShadowOpacity(
          getShadowOpacity(
            scrollerRef.current.scrollHeight - scrollerRef.current.offsetHeight - scrollerRef.current.scrollTop
          )
        );
      }
    };

    const getShadowOpacity = (scrollPosition: number) => {
      if (scrollPosition > scrollDistanceToMaxOpacity) {
        return 1;
      }
      return scrollPosition / scrollDistanceToMaxOpacity;
    };

    resetBottomShadowOpacity();

    return (
      <Box
        sx={sx}
        ref={(element: HTMLDivElement) => {
          scrollerRef.current = element;
          if (typeof ref === 'function') ref(element);
          else if (ref) (ref as MutableRefObject<HTMLDivElement | null>).current = element;
        }}
        className={`${classes.container} ${paper ? classes.containerPaper : ''}`}
        onScroll={onScroll}
        style={{ overflowY: loading ? 'hidden' : 'auto', overflowX: horizontalScrolling ? undefined : 'hidden' }}
        data-testid={props['data-testid']}
      >
        {!loading && (
          <>
            <div className={cx(classes.shadowContainer, classes.shadowContainerTop)}>
              <div className={cx(classes.shadow, classes.shadowTop)} style={{ opacity: topShadowOpacity }} />
            </div>
            <div className={cx(classes.shadowContainer, classes.shadowContainerBottom)} style={{ top: scrollerHeight }}>
              <div className={cx(classes.shadow, classes.shadowBottom)} style={{ opacity: bottomShadowOpacity }} />
            </div>
          </>
        )}
        {loading && <div className={`${classes.loadingFade} ${paper ? classes.loadingFadePaper : ''}`} />}
        <div className={cx(classes.slot, { [classes.slotSaving]: saving })}>{children}</div>
        {saving && (
          <div className={classes.savingContainer}>
            <div className={classes.savingContainerInner}>
              <CircularProgress size={48} color="primary" />
            </div>
          </div>
        )}
      </Box>
    );
  }
);
ShadowScroller.displayName = 'ShadowScroller';

export default ShadowScroller;
