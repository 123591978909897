import React, { createContext, useContext } from 'react';
import { ChannelsDataManager, ChannelsDataManagerType } from './Controllers';
import { ContentServiceChannelsDataManager, ContentServiceChannelsDataManagerType } from './Controllers';
import { CollectionsDataManager, CollectionsDataManagerType } from './Controllers';
import { MediaDataManager, MediaDataManagerType } from './Controllers';
import { SportsDataManager, SportsDataManagerType } from './Controllers';
import { TeamsDataManager, TeamsDataManagerType } from './Controllers';
import { TournamentsDataManager, TournamentsDataManagerType } from './Controllers';
import { UsersDataManager, UsersDataManagerType } from './Controllers';
import { CategoriesDataManager, CategoriesDataManagerType } from './Controllers';
import { CategoryBundlesDataManager, CategoryBundlesDataManagerType } from './Controllers';
import { AssetsDataManager, AssetsDataManagerType } from './Controllers';
import { SavedSearchesDataManager, SavedSearchesDataManagerType } from './Controllers';
import { PresetsDataManager, PresetsDataManagerType } from './Controllers';
import { HPCDataManager, HPCDataManagerType } from './Controllers';
import { LokaliseDataManager, LokaliseDataManagerType } from './Controllers';
import { PagesDataManager, PagesDataManagerType } from './Controllers/Pages';
import {
  CouponsDataManager,
  CouponsDataManagerType,
  PlansDataManager,
  PlansDataManagerType,
  PromotionsDataManager,
  PromotionsDataManagerType
} from './Controllers/Payments';
import { AudiencesDataManager, AudiencesDataManagerType } from './Controllers/Experience';
import { OfferMappingsDataManager, OfferMappingsDataManagerType } from './Controllers/Payments/OfferMappings';
import { DataExchangesDataManager, DataExchangesDataManagerType } from './Controllers';

export type DataLayerContextType = {
  users: UsersDataManagerType;
  collections: CollectionsDataManagerType;
  media: MediaDataManagerType;
  assets: AssetsDataManagerType;
  presets: PresetsDataManagerType;
  heroPresetCollections: HPCDataManagerType;
  channels: ChannelsDataManagerType;
  contentServiceChannels: ContentServiceChannelsDataManagerType;
  categories: CategoriesDataManagerType;
  categoryBundles: CategoryBundlesDataManagerType;
  savedSearches: SavedSearchesDataManagerType;
  sports: SportsDataManagerType;
  tournaments: TournamentsDataManagerType;
  teams: TeamsDataManagerType;
  lokalise: LokaliseDataManagerType;
  pages: PagesDataManagerType;
  plans: PlansDataManagerType;
  promotions: PromotionsDataManagerType;
  coupons: CouponsDataManagerType;
  audiences: AudiencesDataManagerType;
  offerMappings: OfferMappingsDataManagerType;
  dataExchanges: DataExchangesDataManagerType;
};

/**
 * createContext requires a valid default value, but these values
 * should never be needed, so we can just pass in empty objects.
 */
const DataLayerContext = createContext<DataLayerContextType>({
  users: {} as UsersDataManagerType,
  collections: {} as CollectionsDataManagerType,
  media: {} as MediaDataManagerType,
  assets: {} as AssetsDataManagerType,
  presets: {} as PresetsDataManagerType,
  heroPresetCollections: {} as HPCDataManagerType,
  channels: {} as ChannelsDataManagerType,
  contentServiceChannels: {} as ContentServiceChannelsDataManagerType,
  categories: {} as CategoriesDataManagerType,
  categoryBundles: {} as CategoryBundlesDataManagerType,
  savedSearches: {} as SavedSearchesDataManagerType,
  sports: {} as SportsDataManagerType,
  tournaments: {} as TournamentsDataManagerType,
  teams: {} as TeamsDataManagerType,
  lokalise: {} as LokaliseDataManagerType,
  pages: {} as PagesDataManagerType,
  plans: {} as PlansDataManagerType,
  promotions: {} as PromotionsDataManagerType,
  coupons: {} as CouponsDataManagerType,
  audiences: {} as AudiencesDataManagerType,
  offerMappings: {} as OfferMappingsDataManagerType,
  dataExchanges: {} as DataExchangesDataManagerType
});

export const useData: () => DataLayerContextType = () => useContext(DataLayerContext);

export function DataLayer({ children }: { children: React.ReactNode }): React.ReactElement {
  const users = {
    state: UsersDataManager.state,
    hook: UsersDataManager.hook()
  };
  const collections = {
    state: CollectionsDataManager.state,
    hook: CollectionsDataManager.hook()
  };
  const media = {
    state: MediaDataManager.state,
    hook: MediaDataManager.hook()
  };
  const assets = {
    state: AssetsDataManager.state,
    hook: AssetsDataManager.hook()
  };
  const presets = {
    state: PresetsDataManager.state,
    hook: PresetsDataManager.hook()
  };
  const savedSearches = {
    state: SavedSearchesDataManager.state,
    hook: SavedSearchesDataManager.hook()
  };
  const heroPresetCollections = {
    state: HPCDataManager.state,
    hook: HPCDataManager.hook()
  };

  // EPG
  const channels = {
    state: ChannelsDataManager.state,
    hook: ChannelsDataManager.hook()
  };
  const contentServiceChannels = {
    state: ContentServiceChannelsDataManager.state,
    hook: ContentServiceChannelsDataManager.hook()
  };
  const categories = {
    state: CategoriesDataManager.state,
    hook: CategoriesDataManager.hook()
  };
  const categoryBundles = {
    state: CategoryBundlesDataManager.state,
    hook: CategoryBundlesDataManager.hook()
  };

  // Sports
  const sports = {
    state: SportsDataManager.state,
    hook: SportsDataManager.hook()
  };
  const tournaments = {
    state: TournamentsDataManager.state,
    hook: TournamentsDataManager.hook()
  };
  const teams = {
    state: TeamsDataManager.state,
    hook: TeamsDataManager.hook()
  };

  const lokalise = {
    state: LokaliseDataManager.state,
    hook: LokaliseDataManager.hook()
  };

  // Layouts
  const pages = {
    state: PagesDataManager.state,
    hook: PagesDataManager.hook()
  };

  // Payments
  const plans = {
    state: PlansDataManager.state,
    hook: PlansDataManager.hook()
  };
  const promotions = {
    state: PromotionsDataManager.state,
    hook: PromotionsDataManager.hook()
  };
  const coupons = {
    state: CouponsDataManager.state,
    hook: CouponsDataManager.hook()
  };
  const offerMappings = {
    state: OfferMappingsDataManager.state,
    hook: OfferMappingsDataManager.hook()
  };

  // Experience
  const audiences = {
    state: AudiencesDataManager.state,
    hook: AudiencesDataManager.hook()
  };

  const dataExchanges = {
    state: DataExchangesDataManager.state,
    hook: DataExchangesDataManager.hook()
  };

  return (
    <DataLayerContext.Provider
      value={{
        users,
        collections,
        media,
        assets,
        presets,
        heroPresetCollections,
        channels,
        contentServiceChannels,
        categories,
        categoryBundles,
        savedSearches,
        sports,
        tournaments,
        teams,
        lokalise,
        pages,
        plans,
        promotions,
        coupons,
        audiences,
        offerMappings,
        dataExchanges
      }}
    >
      {children}
    </DataLayerContext.Provider>
  );
}
