export enum Environments {
  'PRODUCTION' = 'prd',
  'STAGING' = 'stg',
  'QA' = 'qa',
  'DEVELOPMENT' = 'de'
}

export const whichEnv = (): Environments => {
  if (/\.prd/.test(window?.location?.origin)) return Environments.PRODUCTION;
  if (/\.stg/.test(window?.location?.origin)) return Environments.STAGING;
  if (/\.qa/.test(window?.location?.origin)) return Environments.QA;
  return Environments.DEVELOPMENT;
};
