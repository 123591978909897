import {
  NewPartnerLandingUiModuleBody,
  NewPartnerLandingUiModuleBodyModuleTypeEnum,
  EntitlementType
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankNewPartnerLandingModule: NewModuleFunction<NewPartnerLandingUiModuleBody> = (module) => ({
  moduleType: NewPartnerLandingUiModuleBodyModuleTypeEnum.NEW_PARTNER_LANDING,
  moduleId: generateId(),
  entitlements: [EntitlementType.ANON, EntitlementType.FREE, EntitlementType.SUB],
  header: '',
  vixLogo: '',
  planName: '',
  selectPlanCta: '',
  legalDisclaimer: '',
  ...module
});
