import { RecoilState, RecoilValueReadOnly, selectorFamily } from 'recoil';
import { AssetBody, AssetResponse } from '../../../API';
import { localAtom } from '../../../state/localStorageState';
import { AssetTypesEnum, INITIAL_ASSET_TYPE } from '../../../utils/assetTypes';
import { DataManagerState, DataManagerStateFullReturnType } from '../../DataManager';

export interface AssetStateReturnType extends DataManagerStateFullReturnType<AssetResponse, AssetBody> {
  withAssetByFilePath: (param: string | undefined) => RecoilValueReadOnly<AssetResponse | undefined>;
  withSelectedAssetType: RecoilState<AssetTypesEnum>;
}

export function AssetsState(name: string): AssetStateReturnType {
  const genericState = DataManagerState<AssetResponse, AssetBody>(name);

  const withAssetByFilePath = selectorFamily<AssetResponse | undefined, string | undefined>({
    key: 'assets.assetByFilePath',
    get: (filePath) => {
      return ({ get }) => {
        if (!filePath) return;
        const data = get(genericState.withDataCache);
        return Object.values(data).find((asset) => asset.object.filePath === filePath)?.object;
      };
    }
  });

  const withSelectedAssetType = localAtom<AssetTypesEnum>({
    key: 'assets.selectedAssetType',
    default: INITIAL_ASSET_TYPE
  });

  return {
    ...genericState,
    withAssetByFilePath,
    withSelectedAssetType
  };
}
