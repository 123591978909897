/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import removeAccents from 'remove-accents';
import { escapeRegExp } from 'lodash-es';

type ReturnType = {
  onFilterInputChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  clearFilter: () => void;
  filteredResults: <T extends Array<unknown>>(contents: T) => T;
  filterRegExp: RegExp | undefined;
};

type FilterExactType = {
  field: string;
};

export const textFilterToRegExp = (text: string): RegExp =>
  RegExp(text.split('').map(escapeRegExp).join('[^\\w]*'), 'i');

/**
 *
 * @param filterableFields
 * If you want to filter a value by partial matching you can pass a simple string to filterableFields
 * If you want to filter by exact value, pass a FilterExactType object to filterableFields
 */

export const useTextFilter = (filterableFields: (string | FilterExactType)[]): ReturnType => {
  const [filter, setFilter] = useState<RegExp>();
  const [filterRawValue, setFilterRawValue] = useState<string>();

  const onFilterInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let newFilter;
    if (evt.target.value) {
      newFilter = textFilterToRegExp(evt.target.value);
    }
    setFilter(newFilter);
    setFilterRawValue(evt.target.value);
  };

  const clearFilter = () => {
    setFilter(undefined);
    setFilterRawValue(undefined);
  };

  const filteredResults = <T extends Array<unknown>>(contents: T): T => {
    return (contents ? contents.filter((content) => shouldFilter(content)) : []) as T;
  };

  const shouldFilter = (entity: any) => {
    if (filter && filterRawValue) {
      for (const field of filterableFields) {
        if (typeof field === 'string') {
          if (typeof entity[field] === 'string') {
            if (filter.test(removeAccents(entity[field]))) {
              return true;
            }
          } else if (typeof entity[field] === 'object') {
            for (const key in entity[field]) {
              const prop = entity[field][key];
              if (prop && filter.test(removeAccents(prop))) {
                return true;
              }
            }
          }
        } else {
          // Else we use the exact matching
          const { field: fieldName } = field;

          if (typeof entity[fieldName] === 'object') {
            for (const key in entity[fieldName]) {
              if (entity[fieldName][key] === filterRawValue) {
                return true;
              }
            }
          } else if (entity[fieldName] === filterRawValue) {
            return true;
          }
        }
      }

      return false;
    }
    return true;
  };

  return { onFilterInputChange, clearFilter, filteredResults, filterRegExp: filter };
};
