import { atom } from 'recoil';
import { ConfigResponse } from '../../API';
import { localAtom } from '../localStorageState';

export enum ConfigPanelTabViewOptions {
  FALLBACK_ASSETS,
  SEO_SECTION
}

export const withAllSettings = atom<ConfigResponse | undefined>({
  key: 'config.settings',
  default: undefined
});

export const withSavingUserVisitedPage = localAtom<string | undefined>({
  key: 'config.userVisitedPage',
  default: undefined
});

export const withConfigPanelTabView = atom<ConfigPanelTabViewOptions>({
  key: 'config.configPanelTabView',
  default: ConfigPanelTabViewOptions.FALLBACK_ASSETS
});
