import { useState, useMemo, RefCallback, RefObject } from 'react';
import { throttle } from 'lodash-es';
import useResizeObserver from 'use-resize-observer';

type ObservedSize = {
  width: number | undefined;
  height: number | undefined;
};

type HookResponse<T extends HTMLElement> = {
  ref: RefCallback<T>;
} & ObservedSize;

export default <T extends HTMLElement>(wait: number, defaultRef?: RefObject<T> | HTMLElement): HookResponse<T> => {
  const [size, setSize] = useState<ObservedSize>({ width: 0, height: 0 });
  const onResize = useMemo(() => throttle(setSize, wait), [wait]);
  const { ref } = useResizeObserver<T>({ onResize, ref: defaultRef as RefObject<T> });
  const { width, height } = size;
  return { ref, width, height };
};
