import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useRecoilState } from 'recoil';
import { useLocales, useTheme } from '../../../../hooks';
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import Button from '../../../shared/Button';
import { withCheckedModuleIds, withCopiedModules, withSelectedLayoutUIModules } from '../../../../state/Layouts';
import generateId from '../../../../utils/generateId';
import { usePermissionsGuard } from '../../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../../state/theme';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center'
  },
  checkbox: {
    height: 30
  }
}));

export const testIds = {
  selectAllCheckbox: 'module-actions.select-all',
  copyButton: 'module-actions.copy-button',
  pasteButton: 'module-actions.paste-button',
  deleteButton: 'module-actions.delete-button'
};

export function LayoutViewModuleActions({ isEditable }: { isEditable: boolean }): JSX.Element {
  const { canSave, canDelete } = usePermissionsGuard({
    homepageOption: HomepageOptions.LAYOUTS
  });
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const [checkedModuleIds, setCheckedModuleIds] = useRecoilState(withCheckedModuleIds);
  const [copiedModules, setCopiedModules] = useRecoilState(withCopiedModules);
  const [selectedLayoutModules, setSelectedLayoutModules] = useRecoilState(withSelectedLayoutUIModules);

  const onCheckboxChange = (evt: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newCheckedModuleIds: Record<string, boolean> = {};
    if (checked) {
      selectedLayoutModules?.forEach((module) => {
        newCheckedModuleIds[String(module.moduleId)] = true;
      });
    }
    setCheckedModuleIds(newCheckedModuleIds);
  };

  const onCopy = () => {
    const newCopiedModules = getCheckedModules();
    if (!newCopiedModules) return;
    setCopiedModules(newCopiedModules);
    setCheckedModuleIds({}); // Clear the selected items when copying
  };

  const onPaste = () => {
    if (!selectedLayoutModules) return;
    setSelectedLayoutModules([
      ...selectedLayoutModules,
      ...copiedModules.map((module) => ({ ...module, moduleId: generateId() }))
    ]);
  };

  const onDelete = () => {
    if (!selectedLayoutModules) return;
    setSelectedLayoutModules(selectedLayoutModules.filter((module) => !checkedModuleIds[String(module.moduleId)]));
    setCheckedModuleIds({}); // Clear the selected items when deleting
  };

  const getCheckedModules = () => {
    return selectedLayoutModules?.filter((module) => checkedModuleIds[String(module.moduleId)]);
  };

  const checkedModules = getCheckedModules();
  const someChecked = checkedModules && checkedModules.length > 0;
  const numChecked = checkedModules?.length || 0;
  const allChecked = someChecked && checkedModules && checkedModules.length === selectedLayoutModules?.length;

  return (
    <div className={classes.root}>
      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox
            color={formControlColor}
            checked={allChecked}
            indeterminate={someChecked && !allChecked}
            disabled={!selectedLayoutModules?.length}
            onChange={onCheckboxChange}
            data-testid={testIds.selectAllCheckbox}
          />
        }
        label={<Typography variant="body2">{t('general.select_all')}</Typography>}
      />
      <Stack direction="row" gap={2}>
        <Button
          size="small"
          color="grey"
          onClick={onCopy}
          disabled={!someChecked}
          tooltip={t('layouts.copy_modules', { count: numChecked })}
          data-testid={testIds.copyButton}
        >
          {t('general.copy')}
        </Button>
        {isEditable && (
          <>
            <Button
              size="small"
              color="primary"
              onClick={onPaste}
              disabled={!copiedModules.length || !canSave}
              tooltip={t('layouts.paste_modules', { count: copiedModules.length })}
              data-testid={testIds.pasteButton}
            >
              {t('general.paste')}
            </Button>
            <Button
              size="small"
              color="error"
              onClick={onDelete}
              disabled={!someChecked || !canDelete}
              tooltip={t('layouts.delete_modules', { count: numChecked })}
              data-testid={testIds.deleteButton}
            >
              {t('general.delete')}
            </Button>
          </>
        )}
      </Stack>
    </div>
  );
}
