import { RefCallback, RefObject } from 'react';
import useThrottledResizeObserver from './useThrottledResizeObserver';

export enum BreakPoint {
  SMALL = 980,
  MEDIUM = 1280
}

type ReturnType<T> = {
  ref: RefCallback<T>;
  minWidth: (width: number | BreakPoint) => boolean;
  maxWidth: (width: number | BreakPoint) => boolean;
  minHeight: (height: number | BreakPoint) => boolean;
  maxHeight: (height: number | BreakPoint) => boolean;
  width: number;
  height: number;
};

export const useResponsive = <T extends HTMLElement>(
  defaultRef?: RefObject<T> | HTMLElement,
  defaultHeight?: number
): ReturnType<T> => {
  const { ref, width = 0, height = 0 } = useThrottledResizeObserver(100, defaultRef);

  const minWidth = (currentWidth: number | BreakPoint) => {
    return !!width && Number(currentWidth) <= width;
  };

  const maxWidth = (currentWidth: number | BreakPoint) => {
    return !!width && Number(currentWidth) >= width;
  };

  const minHeight = (currentHeight: number | BreakPoint) => {
    return !!height && Number(currentHeight) <= height;
  };

  const maxHeight = (currentHeight: number | BreakPoint) => {
    return !!height && Number(currentHeight) >= height;
  };

  return {
    ref,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
    width,
    // This is to fix the issue of height being returned "0" in safari
    // It's an issue with "use-resize-observer" library.
    // Related github issue: https://github.com/ZeeCoder/use-resize-observer/issues/85
    height: defaultHeight && !height ? defaultHeight : height
  };
};
