import React, { useEffect, useMemo, useState } from 'react';
import { Search } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Checkbox, Chip, FormControlLabel, InputAdornment, Tab, Typography } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { McpChannelResponse, UsagePolicyType, VllChannelResponse } from '../../../API';
import { useData } from '../../../data-layer';
import { useLocales, useTextFilter, useTheme } from '../../../hooks';
import { withChannelLiveFilter, withChannelPremiumFilter, withChannelTypeFilter } from '../../../state/Layouts';
import { ALL, FALSE, TRUE } from '../../../utils/types/genericTypes';
import TextField from '../../shared/TextField';
import { EPGChannelFilters } from './EPGChannelFilters';
import { EPGChannelsTab } from './EPGChannelsTab';
import { difference } from 'lodash-es';
import { EPGChannelsDownload } from './EPGChannelsDownload';

interface IChannelsProps {
  isChannelPicker?: boolean;
  onChannelPicked?: (channel: VllChannelResponse) => void;
}

const LINKED_CHANNELS_TAB = '0';
const UNLINKED_CHANNELS_TAB = '1';

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  channelFilters: {
    padding: theme.spacing(4)
  },
  header: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4)
  },
  searchBarContainer: {
    width: '100%'
  },
  searchBar: {
    minWidth: 250
  },
  filtersContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'space-between'
  },
  globalSearchToggle: {
    margin: theme.spacing(0, 0, 2),
    '& .MuiFormControlLabel-label': {
      fontSize: '0.9rem',
      whiteSpace: 'nowrap'
    }
  },
  tabsContainer: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  tab: {
    height: '100%',
    padding: 0
  },
  tabWithCount: {
    flexDirection: 'row',
    gap: theme.spacing(4)
  }
}));

export const testIds = {
  root: 'channels.root',
  searchBar: 'channels.search-bar',
  blockedChannelsCheckbox: 'channels.blocked-channels-checkbox',
  showLinkedTab: 'channels.show-linked-tab',
  showUnlinkedTab: 'channels.show-unlinked-tab'
};

function EPGChannels({ isChannelPicker = false, onChannelPicked }: IChannelsProps): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const {
    onFilterInputChange,
    clearFilter,
    filteredResults: filterByText
  } = useTextFilter(['title', 'entityId', 'id', 'editorNotes']);
  const { formControlColor } = useTheme();
  const [currentTab, setCurrentTab] = useState(LINKED_CHANNELS_TAB);
  const channelType = useRecoilValue(withChannelTypeFilter);
  const isLive = useRecoilValue(withChannelLiveFilter);
  const isPremium = useRecoilValue(withChannelPremiumFilter);

  const {
    categoryBundles: {
      state: { withSelected }
    },
    channels: {
      state: { withShowBlockedChannels, withAllRecords: withAllChannels },
      hook: { getAll: getAllChannels }
    },
    contentServiceChannels: {
      state: { withAllRecords: withAllContentServiceChannels },
      hook: { getAll: getAllContentServiceChannels }
    }
  } = useData();
  const selectedCategoryBundle = useRecoilValue(withSelected);
  const selectedCategoryBundleCountries = selectedCategoryBundle?.countries;
  const allChannels = useRecoilValue(withAllChannels);
  const allContentServiceChannels = useRecoilValue(withAllContentServiceChannels);
  const [showingBlockedChannels, setShowingBlockedChannels] = useRecoilState(withShowBlockedChannels);

  const isChannelAllowed = ({ usagePolicy }: VllChannelResponse | McpChannelResponse) => {
    if (isChannelPicker) return true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const usagePolicyType = (usagePolicy as any)?.['default'];
    if (!usagePolicyType) return true;
    const includesCountry = usagePolicy?.exceptionCountryCodes?.some((code) =>
      selectedCategoryBundleCountries?.includes(code)
    );
    const isBlock = usagePolicyType === UsagePolicyType.BLOCK;
    return (includesCountry && isBlock) || (!includesCountry && !isBlock);
  };

  const allowedChannels = useMemo(
    () => allChannels?.filter(isChannelAllowed),
    [allChannels, selectedCategoryBundleCountries]
  );

  const allowedContentServiceChannels = useMemo(
    () => allContentServiceChannels?.filter(isChannelAllowed),
    [allContentServiceChannels, selectedCategoryBundleCountries]
  );

  useEffect(() => {
    if (!isChannelPicker && !selectedCategoryBundleCountries) return;
    if (!allChannels) {
      getAllChannels();
    }
    if (!allContentServiceChannels) {
      getAllContentServiceChannels();
    }
  }, [isChannelPicker, selectedCategoryBundleCountries]);

  const filterFunction = (channel: VllChannelResponse | McpChannelResponse): boolean => {
    if (channelType !== ALL && channel.type !== channelType) {
      return false;
    }
    if ((isLive === TRUE && !channel.isLive) || (isLive === FALSE && channel.isLive)) {
      return false;
    }
    if ((isPremium === TRUE && !channel.isPremium) || (isPremium === FALSE && channel.isPremium)) {
      return false;
    }
    return true;
  };

  const blockedChannels = useMemo(() => {
    if (!allChannels || !allowedChannels) return [];
    return difference(allChannels, allowedChannels).map((channel) => channel.entityId);
  }, [allowedChannels]);

  const blockedContentServiceChannels = useMemo(() => {
    if (!allContentServiceChannels || !allowedContentServiceChannels) return [];
    return difference(allContentServiceChannels, allowedContentServiceChannels).map((channel) => channel.id);
  }, [allowedContentServiceChannels]);

  // Using channels either all or the bucket channels depending on if we are showing blocked channels
  const usingChannels = showingBlockedChannels ? allChannels : allowedChannels;
  const usingContentServiceChannels = showingBlockedChannels
    ? allContentServiceChannels
    : allowedContentServiceChannels;

  const filteredChannels: VllChannelResponse[] = usingChannels
    ? filterByText(usingChannels).filter(filterFunction)
    : [];

  const filteredContentServiceChannels: McpChannelResponse[] = usingContentServiceChannels
    ? filterByText(usingContentServiceChannels.filter(({ linked }) => !linked)).filter(filterFunction)
    : [];

  return (
    <div data-testid={testIds.root} className={classes.root}>
      <div className={classes.channelFilters}>
        <div className={classes.header}>
          <Typography variant="h5">{t('epg.channels.channels')}</Typography>
          <EPGChannelsDownload />
        </div>
        <div className={classes.filtersContainer}>
          <div className={classes.searchBarContainer}>
            <TextField
              debounced
              fullWidth
              onChange={onFilterInputChange}
              className={classes.searchBar}
              placeholder={t('general.filter_by_text')}
              clearable={true}
              onClear={clearFilter}
              data-testid={testIds.searchBar}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
            />
          </div>
          {!isChannelPicker && (
            <FormControlLabel
              className={classes.globalSearchToggle}
              label={t('epg.channels.show_geo_blocked')}
              control={
                <Checkbox
                  checked={showingBlockedChannels}
                  color={formControlColor}
                  onChange={({ target: { checked } }) => setShowingBlockedChannels(checked)}
                  data-testid={testIds.blockedChannelsCheckbox}
                />
              }
            />
          )}
        </div>
        <div className={classes.filtersContainer}>
          <EPGChannelFilters />
        </div>
      </div>
      <TabContext value={currentTab}>
        <div className={classes.tabsContainer}>
          {!isChannelPicker && (
            <TabList
              indicatorColor={formControlColor}
              textColor={formControlColor}
              variant="fullWidth"
              onChange={(event: React.SyntheticEvent, newValue: number) => setCurrentTab(String(newValue))}
            >
              <Tab
                className={classes.tabWithCount}
                label={
                  <>
                    <span>{t('general.linked')}</span>
                    <Chip
                      label={!usingChannels ? '...' : filteredChannels.length}
                      color={currentTab === LINKED_CHANNELS_TAB ? formControlColor : 'default'}
                      size="small"
                    />
                  </>
                }
                value={LINKED_CHANNELS_TAB}
                data-testid={testIds.showLinkedTab}
              />
              <Tab
                className={classes.tabWithCount}
                label={
                  <>
                    <span>{t('general.unlinked')}</span>
                    <Chip
                      label={!usingContentServiceChannels ? '...' : filteredContentServiceChannels.length}
                      color={currentTab === UNLINKED_CHANNELS_TAB ? formControlColor : 'default'}
                      size="small"
                    />
                  </>
                }
                value={UNLINKED_CHANNELS_TAB}
                data-testid={testIds.showUnlinkedTab}
              />
            </TabList>
          )}
        </div>
        <TabPanel value={LINKED_CHANNELS_TAB} className={classes.tab}>
          <EPGChannelsTab
            channels={filteredChannels}
            isLoading={!usingChannels}
            isChannelPicker={isChannelPicker}
            onChannelPicked={onChannelPicked}
            blockedChannels={showingBlockedChannels ? blockedChannels : undefined}
          />
        </TabPanel>
        <TabPanel value={UNLINKED_CHANNELS_TAB} className={classes.tab}>
          <EPGChannelsTab
            channels={filteredContentServiceChannels}
            isLoading={!usingChannels}
            isContentServiceChannel
            blockedChannels={showingBlockedChannels ? blockedContentServiceChannels : undefined}
          />
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default EPGChannels;
