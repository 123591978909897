import React from 'react';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '../../../hooks';
import { EntitlementType } from '../../../API';
import { useEntitlements } from '../../../hooks/General/useEntitlements';
import { EntitlementsVisibilityBadge } from './EntitlementsVisibilityBadge';
import { EntitlementsIcon } from './EntitlementsIcon';

export interface IEntitlementsMultiSelectProps {
  label: string;
  value: EntitlementType[];
  onChange: (values: EntitlementType[]) => void;
}

const useStyles = makeStyles()((theme) => ({
  autocomplete: {
    width: 200,
    '& .MuiFormControl-root': {
      marginBottom: '0 !important'
    }
  },
  entitlementIconContainer: {
    width: 18,
    display: 'flex',
    marginRight: theme.spacing(2)
  },
  textField: {
    caretColor: 'transparent',
    '&, & input': {
      cursor: 'pointer'
    },
    '& .MuiInput-root.Mui-disabled:before': {
      borderBottomStyle: 'solid'
    }
  }
}));

export const testIds = {
  autocomplete: 'entitlements-filter.autocomplete',
  autocompleteOption: 'entitlements-filter.autocomplete-option'
};

const allEntitlements = Object.keys(EntitlementType);

export function EntitlementsMultiSelect({ value, onChange, label }: IEntitlementsMultiSelectProps): React.ReactElement {
  const { classes } = useStyles();
  const { formControlColor } = useTheme();
  const { localizeEntitlement } = useEntitlements();

  return (
    <Autocomplete
      multiple={true}
      className={classes.autocomplete}
      options={allEntitlements}
      getOptionLabel={(option) => localizeEntitlement(option)}
      disableClearable
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => (
        <li {...props} data-testid={testIds.autocompleteOption} data-option={option}>
          <Checkbox color={formControlColor} checked={selected} />
          <div className={classes.entitlementIconContainer}>
            <EntitlementsIcon entitlement={option as EntitlementType} />
          </div>
          {localizeEntitlement(option)}
        </li>
      )}
      renderTags={(values: string[]) => {
        return <EntitlementsVisibilityBadge entitlements={values as EntitlementType[]} />;
      }}
      renderInput={(params) => {
        const inputProps = { ...params.InputProps, disabled: true };
        return (
          <TextField
            className={classes.textField}
            color={formControlColor}
            label={label}
            {...params}
            InputProps={inputProps}
          />
        );
      }}
      value={value}
      onChange={(_, changedValues) => {
        if (changedValues.length) onChange(changedValues as EntitlementType[]);
      }}
      data-testid={testIds.autocomplete}
    />
  );
}
