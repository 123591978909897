import { RecoilValueReadOnly, selector } from 'recoil';
import { PaymentPlanResponse } from '../../../../API';
import { getPromoGroups } from '../../../../utils/paymentsHelpers';
import { DataManagerState, DataManagerStateFullReturnType } from '../../../DataManager/DataManagerState';

export interface PlansStateFullReturnType
  extends DataManagerStateFullReturnType<PaymentPlanResponse, PaymentPlanResponse> {
  withPlanGroups: RecoilValueReadOnly<string[] | undefined>;
}

export function PlansState(name: string): PlansStateFullReturnType {
  const genericState = DataManagerState<PaymentPlanResponse, PaymentPlanResponse>(name);

  const withPlanGroups = selector<string[] | undefined>({
    key: `${name}.planGroups`,
    get: ({ get }) => {
      const plans = get(genericState.withAllRecords);
      if (plans) {
        return getPromoGroups(plans);
      }
    }
  });

  return {
    ...genericState,
    withPlanGroups
  };
}
