import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { AllowedTargetId, LayoutType, Page } from '../../../../API';
import { useLocales } from '../../../../hooks';
import { sortBy, union } from 'lodash-es';
import { useData } from '../../../../data-layer';

interface PagesAutocompleteProps {
  value: string | undefined;
  onChange: (event: string | React.ChangeEvent<Element>) => void;
  renderInput: (params: AutocompleteRenderInputParams) => React.ReactElement;
  'data-testid'?: string;
  filter?: (page: string) => boolean;
  useOnlyPages?: boolean;
  disableClearable?: boolean;
}

type Combined = {
  pages: string[];
  specials: string[];
};

export function PagesAutocomplete({
  value,
  onChange,
  renderInput,
  filter,
  useOnlyPages,
  disableClearable = true,
  ...props
}: PagesAutocompleteProps): JSX.Element {
  const { t } = useLocales();

  const {
    pages: {
      state: { withRecordBucket: withPagesByType, withIsFetching },
      hook: { getBucket: getPagesByType }
    }
  } = useData();

  const [inputValue, setInputValue] = useState<string>();

  const isLoadingPages = useRecoilValue(withIsFetching);
  const pages = useRecoilValue(withPagesByType(LayoutType.PAGE));
  const specials = useRecoilValue(withPagesByType(LayoutType.SPECIAL));

  useEffect(() => {
    if (!pages) getPagesByType(LayoutType.PAGE);
    if (!specials) getPagesByType(LayoutType.SPECIAL);
  }, []);

  const combined = useMemo<Combined>(
    () => ({
      pages: sortBy(union(pages?.map(({ urlPath }) => urlPath) || [], Object.values(AllowedTargetId))),
      specials: sortBy(specials?.map(({ urlPath }) => urlPath) || [])
    }),
    [pages, specials]
  );

  const fullPages = useMemo(() => [...combined.pages, ...combined.specials], [combined.pages, combined.specials]);
  const onlyPages = useMemo(() => [...combined.pages], [combined.pages]);

  const isPage = (page: Page | string): page is Page => (page as Page).urlPath !== undefined;
  const getOptionLabel = (page: Page | string): string => (isPage(page) ? page.urlPath : page);

  const onValueChange = (event: SyntheticEvent, changedValue: string | null) => {
    if (event && event.type === 'click') {
      onChange(changedValue ?? '');
      setInputValue('');
    }
  };

  const onInputChange = (event: SyntheticEvent, newValue: string) => {
    if (!event) return;
    if (event.type === 'change' && newValue !== inputValue) {
      setInputValue(newValue);
    } else {
      setInputValue('');
    }
  };

  const pagesToUse = useOnlyPages ? onlyPages : fullPages;

  return (
    <Autocomplete
      clearOnBlur
      clearOnEscape={!disableClearable}
      blurOnSelect
      value={value}
      options={filter ? pagesToUse.filter(filter) : pagesToUse}
      getOptionLabel={getOptionLabel}
      groupBy={(option) => {
        if (combined.pages.includes(option)) return t('layouts.page');
        if (combined.specials.includes(option)) return t('layouts.special');
        return '';
      }}
      onChange={onValueChange}
      onInputChange={onInputChange}
      renderInput={renderInput}
      disableClearable={disableClearable}
      disabled={isLoadingPages || !pages || !specials}
      data-testid={props['data-testid']}
    />
  );
}
