import React, { useState } from 'react';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { AccountCreationUiModuleBody } from '../../../../../API';
import Button from '../../../../shared/Button';
import { StayPrimaryPortrait } from '@mui/icons-material';
import { useLocales, useTheme } from '../../../../../hooks';
import { AccountCreationPreview } from '../../ContentPreview/AccountCreationPreview';

export type AccountCreationListItemProps = IBaseUIModuleListItemProps<AccountCreationUiModuleBody>;

export const testIds = {
  button: 'account-creation-list-item.preview-button'
};

export function AccountCreationListItem({ uiModule, ...props }: AccountCreationListItemProps): React.ReactElement {
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const onPreviewButtonClick = () => setIsOpen(true);

  return (
    <>
      <UIModuleListItem
        uiModule={uiModule}
        topLineTitle={t('layouts.ui_module_account_creation')}
        collectionLine={[
          <Button
            key="preview"
            variant="text"
            size="small"
            startIcon={<StayPrimaryPortrait />}
            color={formControlColor}
            onClick={onPreviewButtonClick}
            data-testid={testIds.button}
          >
            {t('layouts.preview_contents')}
          </Button>
        ]}
        {...props}
      />
      <AccountCreationPreview module={uiModule} isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}
