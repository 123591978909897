import {
  TdsGiftCardsUiModuleBody,
  TdsGiftCardsUiModuleBodyModuleTypeEnum,
  DocumentLocale,
  EntitlementType
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankTdsGiftCardsModule: NewModuleFunction<TdsGiftCardsUiModuleBody> = (module) => ({
  moduleType: TdsGiftCardsUiModuleBodyModuleTypeEnum.TDS_GIFT_CARDS,
  moduleId: generateId(),
  title: {
    [DocumentLocale.ES]: ''
  },
  entitlements: [EntitlementType.ANON, EntitlementType.FREE, EntitlementType.SUB],
  logo: '',
  stepOneHeading: '',
  stepOneEmail: '',
  stepOnePassword: '',
  stepOneCta: '',
  stepOneSignIn: '',
  stepTwoHeading: '',
  stepTwoCta: '',
  stepThreeAHeading: '',
  stepThreeALegalCopy: '',
  stepThreeACtaOne: '',
  stepThreeACtaTwo: '',
  stepThreeBHeadingTopUp: '',
  stepThreeBLegalCopyTopUp: '',
  stepThreeBCtaOneTopUp: '',
  stepThreeBCtaTwoTopUp: '',
  stepThreeCHeadingDtc: '',
  stepThreeCLegalCopyDtc: '',
  stepThreeCCtaOneDtc: '',
  stepThreeCCtaTwoDtc: '',
  stepFourHeading: '',
  stepFourCtaOne: '',
  stepFourCtaTwo: '',
  notSupportedPaymentMethodError: {
    id: generateId(),
    header: '',
    body: '',
    fstCta: '',
    sndCta: ''
  },
  unableToRedeemError: {
    id: generateId(),
    header: '',
    body: '',
    fstCta: '',
    sndCta: ''
  },
  usedAccountError: {
    id: generateId(),
    header: '',
    body: '',
    fstCta: '',
    sndCta: ''
  },
  topUpDone: {
    id: generateId(),
    message: '',
    cta: ''
  },
  ...module
});
