import {
  DocumentLocale,
  WatchListUIModuleContentTreatment,
  WatchListUiModuleBody,
  WatchListUiModuleBodyModuleTypeEnum
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankWatchListModule: NewModuleFunction<WatchListUiModuleBody> = (module) => ({
  title: {
    [DocumentLocale.ES]: ''
  },
  moduleType: WatchListUiModuleBodyModuleTypeEnum.WATCH_LIST,
  contentTreatment: WatchListUIModuleContentTreatment.PORTRAIT,
  moduleId: generateId(),
  ...module
});
