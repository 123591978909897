import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ContentPreview } from '..';
import { MediaResponse, UiModuleSportsEventType } from '../../../../../API';
import { useMatches } from '../../../../../hooks';
import { withPreviewLeagueId, withSelectedLayoutCountries } from '../../../../../state/Layouts';
import { LEAGUE_ID_PARAM } from '../../../../../utils/consts/uiModules';
import { DisplayAsOptions } from '../../../../../utils/types/genericTypes';
import { MatchThumbnail } from '../../../../Sports/MatchThumbnail';

export interface ISportsPreviewProps {
  eventsType: UiModuleSportsEventType;
  leagueIds: string[] | undefined;
  sportIds: string[] | undefined;
  matchIds?: string[] | undefined;
  showing: boolean;
}

export const testIds = {
  matchThumbnail: 'sports-preview.match-thumbnail'
};

export function SportsPreview({
  eventsType,
  leagueIds,
  sportIds,
  matchIds,
  showing
}: ISportsPreviewProps): React.ReactElement {
  const { getMatches, getMatchesByIds } = useMatches();
  const [matches, setMatches] = useState<MediaResponse[]>();
  const previewLeagueId = useRecoilValue(withPreviewLeagueId);
  const actualLeagueIds = useMemo(
    () => (leagueIds?.[0] === LEAGUE_ID_PARAM && previewLeagueId ? [previewLeagueId] : leagueIds),
    [leagueIds, previewLeagueId]
  );
  const countries = useRecoilValue(withSelectedLayoutCountries);

  useEffect(() => {
    if (showing && !matches) {
      fetchMatches();
    }
  }, [showing]);

  useEffect(() => {
    setMatches(undefined);
    if (showing) {
      fetchMatches();
    }
  }, [actualLeagueIds, eventsType, sportIds, matchIds, countries]);

  const fetchMatches = async () => {
    let matches;
    if (matchIds) {
      matches = await getMatchesByIds(matchIds);
    } else {
      matches = await getMatches(eventsType, { leagueIds: actualLeagueIds, sportIds, countries });
    }
    if (!matches) return;
    setMatches(matches);
  };

  const matchThumbnail = (match: MediaResponse) => {
    return <MatchThumbnail matchData={match} data-testid={testIds.matchThumbnail} />;
  };

  return (
    <ContentPreview
      showing={showing}
      displayAs={DisplayAsOptions.LANDSCAPE} // Only landscape supported for now
      loaderCount={matchIds?.length}
      model={{
        contentList: matches,
        thumbnailComponent: matchThumbnail,
        idField: 'contentId'
      }}
    />
  );
}
