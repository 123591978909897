import React, { CSSProperties, forwardRef, HTMLAttributes, useImperativeHandle, useRef } from 'react';
import type { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Badge } from '@mui/material';
import { themeColors } from '../../../theme';
import { useTheme } from '../../../hooks';
import { ScrollTo } from './SortableTreeTypes';

export interface ISortableTreeItemProps<T> extends Omit<HTMLAttributes<HTMLDivElement>, 'id'> {
  id: UniqueIdentifier;
  renderItem: (object: T, index: number, parentIndex: number | null) => React.ReactNode;
  item?: T;
  childCount?: number;
  depth: number;
  index: number;
  parentIndex: number | null;
  indentationWidth: number;
  wrapperRef?(node: HTMLDivElement): void;
  dragging?: boolean;
  disabled?: boolean;
}

/* eslint-disable react/display-name */
export const SortableTreeItem = forwardRef(
  // eslint-disable-next-line comma-spacing
  <T,>(
    {
      id,
      depth,
      renderItem,
      indentationWidth,
      childCount,
      item,
      index,
      parentIndex,
      dragging = false,
      disabled = false,
      ...props
    }: ISortableTreeItemProps<T>,
    ref: React.Ref<ScrollTo>
  ) => {
    const { theme } = useTheme();
    const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const containerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      scrollTo: () => {
        containerRef.current?.scrollIntoView();
      }
    }));

    const style: CSSProperties = {
      cursor: disabled ? 'default' : dragging ? 'grabbing' : 'grab',
      transform: CSS.Transform.toString(transform),
      transition,
      opacity: isDragging ? 0.2 : dragging ? 0.9 : 1,
      marginLeft: `${indentationWidth * depth}px`,
      background: dragging ? themeColors.dark.paper : undefined,
      boxShadow: dragging ? theme.shadows[4] : undefined
    };

    return (
      <div ref={containerRef}>
        {childCount && <Badge color="primary" badgeContent={childCount - 1} sx={{ position: 'absolute' }} />}
        <div ref={setNodeRef} style={style} {...attributes} {...listeners} {...props}>
          {item && renderItem(item, index, parentIndex)}
        </div>
      </div>
    );
  }
);
