import { DocumentLocale, MenuSectionUiModuleBody, MenuSectionUiModuleBodyModuleTypeEnum } from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';
import { NONE } from '../../../utils/types/genericTypes';

export const blankMenuSectionModule: NewModuleFunction<MenuSectionUiModuleBody> = (module) => ({
  title: {
    [DocumentLocale.ES]: ''
  },
  moduleType: MenuSectionUiModuleBodyModuleTypeEnum.MENU_SECTION,
  iconName: NONE as never,
  menuItems: [],
  moduleId: generateId(),
  ...module
});
