import React from 'react';
import { useLocales } from '../../../../../hooks';
import { makeStyles } from 'tss-react/mui';
import IconButton from '../../../../shared/IconButton';
import { Delete } from '@mui/icons-material';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { Typography } from '@mui/material';
import { SportsHeroUiModuleBody } from '../../../../../API';
import MatchPicker from '../../MatchPicker';
import { SponsorSection } from '../SponsorSection';
import { Accept } from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';

interface ISportsHeroMatchItemProps {
  index: number;
  onRemove: () => unknown;
}

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex'
  },
  rowLeft: {
    flexGrow: 1
  },
  rowRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  formField: {
    display: 'inline-flex',
    flexDirection: 'column',
    width: '50%',
    padding: theme.spacing(0, 6, 4, 0)
  },
  contentRow: {
    display: 'inline-flex',
    alignItems: 'flex-end',
    gap: theme.spacing(4),
    width: '100%',
    float: 'left'
  },
  contentPicker: {
    width: '50%',
    paddingRight: theme.spacing(6),
    marginBottom: theme.spacing(4)
  }
}));

export const testIds = {};

function SportsHeroMatchItem({ index, onRemove }: ISportsHeroMatchItemProps): JSX.Element {
  const { control, setValue, watch } = useFormContext<SportsHeroUiModuleBody>();

  const { t } = useLocales();
  const { classes } = useStyles();

  const contentId = watch(`matchHeroContents.${index}.matchId`);

  const renderFormLabel = (label: string, className?: string) => {
    return (
      <Typography component="div" color="textSecondary" variant="body2" className={className}>
        {label}
      </Typography>
    );
  };

  const renderAssetBrowser = (
    type: AssetTypesEnum,
    value: string | undefined,
    onChange: (value: string) => void,
    acceptedFileTypes?: Accept
  ) => {
    return (
      <div>
        <AssetBrowser
          assetId={`${contentId}-${type}`}
          assetType={type}
          value={value}
          onChange={({ target: { value } }) => onChange(value)}
          acceptedFileTypes={acceptedFileTypes}
        />
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.rowLeft}>
        <div className={classes.contentRow}>
          <div className={classes.contentPicker}>
            <Controller
              control={control}
              name={`matchHeroContents.${index}.title`}
              render={({ field: { value } }) => (
                <MatchPicker
                  title={value}
                  onChange={({ contentId, title }) => {
                    setValue(`matchHeroContents.${index}.matchId`, contentId);
                    setValue(`matchHeroContents.${index}.title`, title);
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className={classes.formField}>
          <Controller
            control={control}
            name={`matchHeroContents.${index}.landscapeFillImage`}
            render={({ field: { value, onChange } }) => (
              <>
                {renderFormLabel(t('layouts.landscape_fill_image'))}
                {renderAssetBrowser(AssetTypes.hero.landscape, value, onChange)}
              </>
            )}
          />
        </div>
        <div className={classes.formField}>
          <Controller
            control={control}
            name={`matchHeroContents.${index}.portraitFillImage`}
            render={({ field: { value, onChange } }) => (
              <>
                {renderFormLabel(t('layouts.portrait_fill_image'))}
                {renderAssetBrowser(AssetTypes.hero.portrait, value, onChange)}
              </>
            )}
          />
        </div>
        <SponsorSection entityId={contentId} namePrefix={`matchHeroContents.${index}`} />
      </div>
      <div className={classes.rowRight}>
        <IconButton onClick={onRemove} title={t('layouts.hero_delete_content_item')} size="large">
          <Delete />
        </IconButton>
      </div>
    </div>
  );
}

export default SportsHeroMatchItem;
