import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useData } from '../../../../data-layer';
import AssetImage from '../../../shared/AssetImage';
import { Box, emphasize } from '@mui/material';
import { DisplayAsOptions } from '../../../../utils/types/genericTypes';
import { useRecoilValue } from 'recoil';
import { LocalizedFieldBody } from '../../../../API';
import { getMediaAspectRatio } from '../../../../utils/styleUtils';
import { useLocales } from '../../../../hooks';

interface IEPGChannelImageProps {
  channelLogo: LocalizedFieldBody;
  displayAs?: DisplayAsOptions;
  blocked?: boolean;
  title?: LocalizedFieldBody;
}

type StyleProps = Pick<IEPGChannelImageProps, 'displayAs'>;

const useStyles = makeStyles<StyleProps>()((theme, { displayAs }) => ({
  channelImageItem: {
    position: 'relative',
    background: emphasize(theme.palette.background.paper, 0.1),
    fontSize: '0.9rem',
    userSelect: 'none',
    borderRadius: theme.shape.borderRadius,
    aspectRatio: getMediaAspectRatio(displayAs),
    overflow: 'hidden',
    '&:hover': {
      '& > div.title': {
        display: 'unset'
      }
    }
  },
  logo: {
    filter: theme.palette.mode === 'light' ? 'invert(100%) grayscale(100%)' : 'none',
    position: 'absolute',
    inset: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    aspectRatio: getMediaAspectRatio(displayAs),
    marginTop: theme.spacing(5)
  },
  noLogo: {
    border: '1px dashed',
    borderColor: theme.palette.text.primary,
    fontSize: '0.7rem',
    lineHeight: '3.0rem',
    color: theme.palette.text.primary,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3,
    opacity: 0.3,
    width: '100%',
    height: '100%',
    boxSizing: 'border-box'
  },
  titleText: {
    margin: 0,
    padding: 0,
    lineHeight: 1.2,
    textAlign: 'center',
    wordWrap: 'break-word',
    width: '100%'
  }
}));

function EPGChannelImage({ channelLogo, displayAs: displayAsProp, title }: IEPGChannelImageProps): JSX.Element {
  const {
    collections: {
      state: { withDisplayAs }
    }
  } = useData();
  const { t, localize } = useLocales();

  const displayAsGlobal = useRecoilValue(withDisplayAs);
  const displayAs = displayAsProp || displayAsGlobal;

  const assetProps = useMemo(
    () => (displayAs === DisplayAsOptions.PORTRAIT ? { attribute: 'h', size: 120 } : { attribute: 'w', size: 100 }),
    [displayAs]
  );

  const { classes } = useStyles({ displayAs });

  const fallback = (
    <Box className={classes.noLogo}>
      <div className={classes.titleText}>{localize(title)}</div>
      <div>{t('general.no_logo')}</div>
    </Box>
  );

  return (
    <Box className={classes.channelImageItem}>
      {channelLogo && channelLogo.es !== '' ? (
        <AssetImage
          size={assetProps.size}
          attribute={assetProps.attribute}
          path={channelLogo}
          className={classes.logo}
        />
      ) : (
        fallback
      )}
    </Box>
  );
}

export default EPGChannelImage;
