/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum DataPresetType {
    HERO = 'HERO',
    SPONSOR = 'SPONSOR',
    HERO_ITEM_VOD = 'HERO_ITEM_VOD',
    HERO_ITEM_CHANNEL = 'HERO_ITEM_CHANNEL',
    HERO_ITEM_EVENTS = 'HERO_ITEM_EVENTS',
    HERO_ITEM_PAGE = 'HERO_ITEM_PAGE',
    HERO_ITEM_COLLECTION_LINK = 'HERO_ITEM_COLLECTION_LINK',
    HERO_ITEM_PROMOTION = 'HERO_ITEM_PROMOTION',
    HERO_ITEM_CLIPS = 'HERO_ITEM_CLIPS'
}



