/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DataManagerHook,
  DataManagerHookReturnType,
  DataManagerState,
  DataManagerStateFullReturnType,
  IDataManagerHookProps
} from '.';
import { IDataApiController } from '../../hooks/API';
import { UseLocalesReturnType } from '../../hooks/General/useLocales';
import { OperationIds } from '../../API';

export interface IDataManagerProps<Response, Body> {
  name: string;
  stateInitializer?: (name: string) => DataManagerStateFullReturnType<Response, Body>;
  hookInitializer?: (params: IDataManagerHookProps<Response, Body>) => DataManagerHookReturnType<Response, Body>;
  idField: keyof Response;
  isVersionedEntity?: boolean;
  useApiHook: () => Partial<IDataApiController<Response, Body>>;
  generateNew?: (...args: any) => Response | Body;
  toString?: (record: Response | Body, locales: UseLocalesReturnType) => string;
  useUpdateForCreate?: boolean;
  selectOnCreation?: boolean;
  sortFunctionInitializer?: (locales: UseLocalesReturnType) => (a: Response, b: Response) => number;
  bucketIdToQueryParams?: (id: string) => any[];
  getRecordBucketIds?: (record: Response | Body) => string[];
  paginationLimit?: number;
  generateEntityId?: (length?: number) => string;
  operationIdGetAll?: OperationIds.GET_ALL | OperationIds.GET_PAGINATED;
}

export interface DataManagerReturnType<Response, Body = Response> {
  state: DataManagerStateFullReturnType<Response, Body>;
  hook: () => DataManagerHookReturnType<Response, Body>;
}

export function CreateDataManager<
  Response,
  Body = Response,
  ReturnType extends DataManagerReturnType<Response, Body> = DataManagerReturnType<Response, Body>
>({
  stateInitializer = DataManagerState,
  hookInitializer = DataManagerHook,
  ...props
}: IDataManagerProps<Response, Body>): ReturnType {
  const state = stateInitializer(props.name);
  const hook = () => hookInitializer({ state, ...props });

  return <ReturnType>{
    state,
    hook
  };
}
