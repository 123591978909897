import React, { ChangeEvent, useEffect, useState } from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';
import { useLocales, useTheme } from '../../../hooks';
import {
  InputBaseComponentProps,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  textFieldClasses,
  Typography
} from '@mui/material';
import {
  DateLabel,
  getSmartQueryDateTimeTokens,
  parseSmartQueryDateTimeField,
  ReferenceDate,
  TimeOrder,
  UnitOfTime
} from '../../../utils/queryBuilder';
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
  pickersCalendarHeaderClasses
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTime } from 'luxon';
import { List } from '@mui/icons-material';
import DropdownButton, { testIds as dropDownTestIds } from '../../shared/DropdownButton';
import { pickersArrowSwitcherClasses } from '@mui/x-date-pickers/internals';

const useStyles = makeStyles()((theme) => ({
  contents: {
    padding: 0,
    width: '100%'
  },
  root: {
    display: 'flex',
    gap: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    minWidth: 420
  },
  textField: {
    [`&.${textFieldClasses.root}`]: {
      marginBottom: '0 !important',
      '& input': {
        padding: '2px 0 4px'
      }
    }
  },
  manualSelector: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    alignItems: 'center',
    padding: theme.spacing(4, 2),
    '& > div': {
      flexGrow: 1
    }
  },
  previewCalendarContainer: {
    userSelect: 'none',
    position: 'relative',
    marginBottom: theme.spacing(2),
    background: theme.palette.background.paper,
    [`& .${pickersCalendarHeaderClasses.labelContainer}`]: {
      pointerEvents: 'none'
    },
    [`& .${pickersArrowSwitcherClasses.button}`]: {
      padding: theme.spacing(1),
      margin: 0
    }
  },
  dateHeader: {
    background: theme.palette.background.paper,
    padding: theme.spacing(2, 4),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  dateBody: {
    margin: theme.spacing(2)
  }
}));

interface CustomPickerDayProps extends PickersDayProps<Date> {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
  isToday: boolean;
}

const CustomPickersDay = withStyles(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ dayIsBetween, isFirstDay, isLastDay, isToday, ...pickerProps }: CustomPickerDayProps) => (
    <PickersDay {...pickerProps} />
  ),
  (theme, { dayIsBetween, isFirstDay, isLastDay, isToday }) => ({
    root: {
      ...(dayIsBetween && {
        borderRadius: 0,
        border: 'none !important',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
          backgroundColor: theme.palette.primary.dark
        },
        '&:before':
          (isToday && {
            content: '""',
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
            borderRadius: '999px',
            width: '100%',
            height: '100%',
            position: 'absolute'
          }) ||
          undefined
      }),
      ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%'
      }),
      ...(isLastDay && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%'
      })
    }
  })
) as React.ComponentType<React.PropsWithChildren<CustomPickerDayProps>>;

export const testIds = {
  root: 'collection-query-dynamic-date-chooser.root',
  periodInput: 'collection-query-dynamic-date-chooser.period-input',
  unitOfTimeInput: 'collection-query-dynamic-date-chooser.unitOfTime-input',
  orderInput: 'collection-query-dynamic-date-chooser.order-input',
  referenceDateInput: 'collection-query-dynamic-date-chooser.referenceDate-input',
  presets: dropDownTestIds.menuButton,
  preset: (preset: string): string => dropDownTestIds.menuItem(preset)
};

const numericInputProps = {
  type: 'number',
  inputMode: 'numeric',
  pattern: '[0-9]*',
  min: 0,
  max: 999
} as InputBaseComponentProps;

const quickActions = {
  [DateLabel.A_MONTH_AGO]: '1 months before today',
  [DateLabel.A_WEEK_AGO]: '1 weeks before today',
  [DateLabel.YESTERDAY]: '1 days before today',
  [DateLabel.TODAY]: '0 days before today',
  [DateLabel.TOMORROW]: '1 days after today',
  [DateLabel.A_WEEK_FROM_NOW]: '1 weeks after today',
  [DateLabel.A_MONTH_FROM_NOW]: '1 months after today',
  [DateLabel.START_OF_LAST_MONTH]: '1 months before this_month',
  [DateLabel.START_OF_LAST_WEEK]: '1 weeks before this_week',
  [DateLabel.START_OF_THIS_MONTH]: '0 months before this_month',
  [DateLabel.START_OF_THIS_WEEK]: '0 weeks before this_week',
  [DateLabel.START_OF_NEXT_WEEK]: '1 weeks after this_week',
  [DateLabel.START_OF_NEXT_MONTH]: '1 months after this_month'
} as Record<DateLabel, string>;

interface CollectionQueryDynamicDateChooserProps {
  value: string;
  onChange: (newValue: string) => void;
  operator: string;
}

function CollectionQueryDynamicDateChooser({
  value,
  onChange,
  operator
}: CollectionQueryDynamicDateChooserProps): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const [period, setPeriod] = useState(1);
  const [unitOfTime, setUnitOfTime] = useState('days');
  const [order, setOrder] = useState('before');
  const [referenceDate, setReferenceDate] = useState('today');

  const presetActions: Record<string, string> = {};

  Object.values(DateLabel).forEach((key) => {
    presetActions[key] = t(`query_builder.date_labels.${key}`);
  });

  useEffect(() => {
    onChange(`${period} ${unitOfTime} ${order} ${referenceDate}`);
  }, [period, unitOfTime, order, referenceDate]);

  useEffect(() => {
    const tokens = getSmartQueryDateTimeTokens(value);
    if (!tokens) return;
    const [_period, _unitOfTime, _order, _referenceDate] = tokens;
    setPeriod(parseInt(_period));
    setUnitOfTime(_unitOfTime);
    setOrder(_order);
    setReferenceDate(_referenceDate);
  }, [value]);

  const handlePresetClick = (key: string) => {
    onChange(quickActions[key as DateLabel]);
  };

  const renderCustomPickerDay = (props: PickersDayProps<Date>): JSX.Element => {
    if (!value) return <PickersDay {...props} />;

    const comparableDate = DateTime.fromJSDate(props.day);
    const comparableValue = DateTime.fromISO(parseSmartQueryDateTimeField(value) as string);
    const start = ['le', 'leq', 'neq'].includes(operator) ? DateTime.fromMillis(0) : comparableValue;
    const end = ['ge', 'geq', 'neq'].includes(operator) ? DateTime.fromMillis(8.64e15) : comparableValue;
    const dayIsBetween =
      comparableDate.startOf('day') >= start.startOf('day') && comparableDate.startOf('day') <= end.startOf('day');
    const isFirstDay = comparableDate.hasSame(start, 'day');
    const isLastDay = comparableDate.hasSame(end, 'day');
    const isToday = comparableDate.hasSame(DateTime.now(), 'day');

    return (
      <CustomPickersDay
        {...props}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
        isToday={isToday}
      />
    );
  };

  return (
    <div className={classes.root} data-testid={testIds.root}>
      <div className={classes.contents}>
        <div className={classes.dateHeader}>
          <Typography variant="button" display="block">
            {t('query_builder.choose_dynamic_date')}
          </Typography>
          <DropdownButton
            actions={presetActions}
            buttonCaption={t('query_builder.presets')}
            onSelectAction={handlePresetClick}
            buttonProps={{ endIcon: <List />, color: formControlColor }}
          />
        </div>
        <div className={classes.dateBody}>
          <div className={classes.manualSelector}>
            <TextField
              value={period}
              color={formControlColor}
              className={classes.textField}
              variant="standard"
              inputProps={numericInputProps}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setPeriod(Number(event.target.value));
              }}
              data-testid={testIds.periodInput}
            />
            <Select
              value={unitOfTime}
              color={formControlColor}
              size="small"
              aria-label="unit of time"
              onChange={(event: SelectChangeEvent) => setUnitOfTime(event.target.value)}
              data-testid={testIds.unitOfTimeInput}
            >
              {Object.values(UnitOfTime).map((unit) => (
                <MenuItem key={unit} value={unit}>
                  {t(`query_builder.units_of_time.${unit}`)}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={order}
              color={formControlColor}
              size="small"
              aria-label="time order"
              onChange={(event: SelectChangeEvent) => setOrder(event.target.value)}
              data-testid={testIds.orderInput}
            >
              {Object.values(TimeOrder).map((order) => (
                <MenuItem key={order} value={order}>
                  {t(`query_builder.operators.${order}`)}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={referenceDate}
              color={formControlColor}
              size="small"
              aria-label="reference date"
              onChange={(event: SelectChangeEvent) => setReferenceDate(event.target.value)}
              data-testid={testIds.referenceDateInput}
            >
              {Object.values(ReferenceDate).map((reference) => (
                <MenuItem key={reference} value={reference}>
                  {t(`query_builder.date_labels.${reference}`)}
                </MenuItem>
              ))}
            </Select>
          </div>
          <Typography variant="button" display="block">
            {t('query_builder.preview')}
          </Typography>
          <div className={classes.previewCalendarContainer}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateCalendar
                value={new Date(parseSmartQueryDateTimeField(value) as string)}
                slots={{ day: renderCustomPickerDay, switchViewButton: () => <></> }}
                readOnly
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CollectionQueryDynamicDateChooser;
