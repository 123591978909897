import React, { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { withTimelineTracks, withLayouts, FUTURE_LAYOUTS, PAST_LAYOUTS } from '../../../../state/Layouts';
import { drawLine } from '../../LayoutListItem/LayoutListItemGraph/LayoutListItemGraph';
import { useResponsive } from '../../../../hooks/General/useResponsive';

const useStyles = makeStyles()(() => ({
  componentRoot: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex'
  },
  canvas: {}
}));

export const testIds = {
  canvas: 'layout-timeline-extender.canvas'
};

function LayoutsTimelineExtender(): JSX.Element {
  const { classes } = useStyles();
  const canvas = useRef<HTMLCanvasElement>(null);
  const timelineTracks = useRecoilValue(withTimelineTracks);
  const layouts = useRecoilValue(withLayouts);
  const { ref: container, width: canvasWidth, height: canvasHeight } = useResponsive();

  useEffect(() => {
    canvasDrawTimeline();
  }, [canvas, timelineTracks, canvasWidth, canvasHeight]);

  const canvasDrawTimeline = () => {
    if (canvas.current && timelineTracks && layouts) {
      const lastLayout =
        layouts[FUTURE_LAYOUTS][layouts[FUTURE_LAYOUTS].length - 1] ||
        layouts[PAST_LAYOUTS][layouts[PAST_LAYOUTS].length - 1];
      if (!lastLayout) return;
      const tracks = timelineTracks[lastLayout.id];
      const ctx = canvas.current.getContext('2d');
      if (!ctx) return;
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);
      tracks.tracks.forEach((track, i) => {
        if (track.countries.length) {
          drawLine(ctx, i, false, canvasHeight);
        }
      });
    }
  };

  return (
    <div className={classes.componentRoot} ref={container}>
      <canvas
        className={classes.canvas}
        ref={canvas}
        width={`${canvasWidth}px`}
        height={`${canvasHeight - 1}px`}
        data-testid={testIds.canvas}
        style={{ width: `${canvasWidth}px`, height: `${canvasHeight - 1}px` }}
      ></canvas>
    </div>
  );
}

export default LayoutsTimelineExtender;
