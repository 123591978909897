import { atom } from 'recoil';
import { IConfirmDialogProps } from '../../components/shared/ConfirmDialog';

export const withConfirmParams = atom<IConfirmDialogProps>({
  key: 'confirm.params',
  default: {
    open: false,
    confirmColor: 'error'
  } as IConfirmDialogProps
});
