import { Configuration, DataPresetApi } from '../../../API';

export class DataPresetAPI {
  constructor(config: Configuration) {
    const api = new DataPresetApi(config);
    Object.assign(this, api);

    this.getPaginated = api.getPaginatedV2;
    this.getByIds = api.getByIds;
    this.getById = api.getById;
    this.getByHash = api.getByHash;
    this.create = api.create;
    this.update = api.update;
    this.remove = api.remove;
  }

  getPaginated!: typeof DataPresetApi.prototype.getPaginatedV2;
  getByIds!: typeof DataPresetApi.prototype.getByIds;
  getById!: typeof DataPresetApi.prototype.getById;
  getByHash!: typeof DataPresetApi.prototype.getByHash;
  create!: typeof DataPresetApi.prototype.create;
  update!: typeof DataPresetApi.prototype.update;
  remove!: typeof DataPresetApi.prototype.remove;
}
