import { atom, RecoilState, selector } from 'recoil';
import { HeroPresetCollectionBody, HeroPresetCollectionResponse } from '../../../API';
import { localAtom } from '../../../state/localStorageState';
import { DisplayAsOptions } from '../../../utils/types/genericTypes';
import { DataManagerState, DataManagerStateFullReturnType } from '../../DataManager';

export interface HPCStateReturnType
  extends DataManagerStateFullReturnType<HeroPresetCollectionResponse, HeroPresetCollectionBody> {
  withDisplayAs: RecoilState<DisplayAsOptions>;
  withSplitPane: RecoilState<number[]>;
  withSelectedPresets: RecoilState<string[] | undefined>;
  withScrollTo: RecoilState<string | undefined>;
}

export function HPCState(name: string): HPCStateReturnType {
  const genericState = DataManagerState<HeroPresetCollectionResponse, HeroPresetCollectionBody>(name);

  const withDisplayAs = localAtom({
    key: `${name}.displayAs`,
    default: DisplayAsOptions.LANDSCAPE
  });

  const withSplitPane = localAtom<number[]>({
    key: `${name}.splitPane`,
    default: []
  });

  const withSelectedPresets = selector({
    key: `${name}.selectedPresets`,
    get({ get }) {
      const selectedCollection = get(genericState.withSelected);
      return selectedCollection?.presets;
    },
    set({ get, set }, newPresets) {
      const selectedCollection = get(genericState.withSelected);
      if (selectedCollection) {
        set(genericState.withSelected, {
          ...selectedCollection,
          presets: newPresets
        } as HeroPresetCollectionResponse);
      }
    }
  });

  const withScrollTo = atom<string | undefined>({
    key: `${name}.scrollTo`,
    default: undefined
  });

  return { ...genericState, withDisplayAs, withSplitPane, withSelectedPresets, withScrollTo };
}
