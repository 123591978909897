import React, { memo, useEffect, useMemo, useRef, useState } from 'react';

import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import { Box, Skeleton } from '@mui/material';
import { pull, remove } from 'lodash-es';
import { useData } from '../../../../data-layer';

import { testIds as licenseTestIds } from './License';
import { MediaButtons, MediaButtonsProps, testIds as mediaButtonTestIds } from './MediaButtons';
import MediaList from './MediaList';

import { scrollIntoViewIfNeeded } from '../../../../utils/scrollIntoViewIfNeeded';
import { getMediaAspectRatio } from '../../../../utils/styleUtils';

import MediaImage from './MediaImage';
import { makeStyles } from 'tss-react/mui';
import { DisplayAsOptions } from '../../../../utils/types/genericTypes';
import { ensurePinnedElementsAtTheBeginning } from '../../../../utils/autoRotate';
import { useRatings } from '../../../../hooks/Media/useRatings';
import { CollectionAsset, CollectionAssetType } from '../../../../API';

export const testIds = {
  root: 'Media-root',
  ...mediaButtonTestIds,
  ...licenseTestIds,
  confirmDialog: 'Media-confirmDialog'
};

// used when looking for media in a collection
export const FOUND_CLASS = 'found';

const useStyles = makeStyles<{ displayAs: DisplayAsOptions }>()((theme, props) => ({
  imageSkeleton: {
    aspectRatio: getMediaAspectRatio(props.displayAs)
  },
  listSkeleton: {
    margin: theme.spacing(2),
    height: 50
  },
  styleFound: {
    position: 'absolute',
    inset: 0,
    transition: 'box-shadow 0.5s ease',
    pointerEvents: 'none',
    [`&.${FOUND_CLASS}`]: {
      boxShadow: `inset 0 0 0 5px ${theme.palette.primary.main}`
    }
  },
  mediaButtons: {
    position: 'absolute',
    top: 0,
    left: 0,
    flexDirection: 'column-reverse'
  }
}));

export interface MediaProps {
  mediaId: string;
  isEditing?: boolean;
  inCollection?: boolean;
  disableSorting?: boolean;
  enablePinning?: boolean;
  collectionForKids?: boolean;
  isSmartQuery?: boolean;
  onContentPicked?: (contentId: string) => void;
  previewMode?: boolean;
  collectionSelected?: boolean;
  displayAs?: DisplayAsOptions;
  disablePortal?: boolean;
  canAddDelete?: boolean;
  autoRotateIndex?: number;
  'data-testid'?: string;
}

export type MediaComponentProps = Pick<
  MediaProps,
  | 'mediaId'
  | 'previewMode'
  | 'displayAs'
  | 'inCollection'
  | 'collectionForKids'
  | 'collectionSelected'
  | 'isEditing'
  | 'isSmartQuery'
> & {
  imageUrl: string;
  disabled?: boolean;
  allowSort?: boolean;
  children: React.ReactNode;
};

function Media({
  mediaId,
  isEditing,
  inCollection,
  collectionForKids,
  disableSorting,
  isSmartQuery = false,
  displayAs: displayAsProp,
  previewMode,
  onContentPicked,
  canAddDelete,
  enablePinning,
  autoRotateIndex = -1,
  ...props
}: MediaProps): JSX.Element {
  const {
    collections: {
      state: {
        withDisplayAs,
        withScrollTo,
        withSelectedCollectionContent,
        withPinnedCollectionContent,
        withSelected,
        withMixedCollectionDisplay,
        withSelectedCollectionAsset
      }
    },
    media: {
      state: { withRecordById },
      hook: { queueIdToFetch, edit }
    }
  } = useData();
  const { isSuitableForKids } = useRatings(mediaId);

  const media = useRecoilValue(withRecordById(mediaId));

  const displayAsGlobal = useRecoilValue(withDisplayAs);
  const [scrollTo, setScrollTo] = useRecoilState(withScrollTo);
  const ref = useRef<HTMLDivElement>(null);
  const displayAs = displayAsProp || displayAsGlobal;
  const selectedCollection = useRecoilValue(withSelected);
  const setSelectedCollectionContents = useSetRecoilState(withSelectedCollectionContent);
  const setSelectedCollectionAssets = useSetRecoilState(withSelectedCollectionAsset);
  const setPinnedCollectionContents = useSetRecoilState(withPinnedCollectionContent);
  const isMixedCollection = useRecoilValue(withMixedCollectionDisplay);
  const [isPinned, setIsPinned] = useState(false);

  const isList = displayAs === DisplayAsOptions.LIST;
  const inActiveCollection = !isEditing && !!inCollection;
  const disabledItem = disableSorting || inActiveCollection;
  const collectionSelected = !!selectedCollection;

  const { classes } = useStyles({ displayAs });

  useEffect(() => {
    if (!media) {
      queueIdToFetch(mediaId);
    }
  }, [mediaId]);

  const imageUrl = useMemo(
    () =>
      displayAs === DisplayAsOptions.PORTRAIT
        ? media?.thumbnail?.portraitURL + '?tr=h-280'
        : media?.thumbnail?.landscapeURL + '?tr=w-300',
    [media?.thumbnail, displayAs]
  );

  useEffect(() => {
    // ignore scroll if we're not in the left browser and aren't being scrolled to
    if (!isEditing || !scrollTo || scrollTo !== media?.contentId || !ref.current) return;
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      scrollIntoViewIfNeeded(ref.current!);
      ref.current?.classList.add(FOUND_CLASS);
      setTimeout(() => ref.current?.classList.remove(FOUND_CLASS), 3000);
      setScrollTo(undefined);
    }, 200);
  }, [scrollTo]);

  useEffect(() => {
    if (media && selectedCollection?.pinnedContents?.includes(media.contentId)) {
      setIsPinned(true);
    } else {
      setIsPinned(false);
    }
  }, [media, selectedCollection?.pinnedContents]);

  if (!media) {
    return isList ? (
      <Skeleton className={classes.listSkeleton} animation="wave" />
    ) : (
      <Skeleton className={classes.imageSkeleton} animation="wave" />
    );
  }

  const onSearch = () => {
    setScrollTo(media?.contentId);
  };

  const onEdit = () => {
    edit(media.contentId);
  };

  const onAdd = (evt?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!selectedCollection) return;
    // Add to the end if the user is holding SHIFT, otherwise add to the beginning
    const addToEnd = !!evt?.nativeEvent.shiftKey;
    setSelectedCollectionContents(
      (contents) =>
        ensurePinnedElementsAtTheBeginning(
          selectedCollection.pinnedContents,
          contents ? (addToEnd ? [...contents, media.contentId] : [media.contentId, ...contents]) : [media.contentId]
        ) as string[]
    );

    if (isMixedCollection) {
      const asset = { assetId: media.contentId, assetType: CollectionAssetType.CONTENT };
      setSelectedCollectionAssets(
        (contents) =>
          ensurePinnedElementsAtTheBeginning(
            selectedCollection.pinnedContents,
            contents ? (addToEnd ? [...contents, asset] : [asset, ...contents]) : [asset]
          ) as CollectionAsset[]
      );
    }
  };

  const onDelete = () => {
    let pinnedContents: string[] = [];

    setPinnedCollectionContents((oldPinnedContents) => {
      if (!oldPinnedContents) return [];

      const newPinnedContents = [...oldPinnedContents];

      pinnedContents = pull(newPinnedContents, media.contentId);

      return pinnedContents;
    });

    setSelectedCollectionContents((contents) => {
      if (!contents) return [];

      const newContents = [...contents];

      return ensurePinnedElementsAtTheBeginning(pinnedContents, pull(newContents, media.contentId)) as string[];
    });

    if (isMixedCollection) {
      setSelectedCollectionAssets((contents) => {
        if (!contents) return [];

        const newContents = [...contents];

        return ensurePinnedElementsAtTheBeginning(
          pinnedContents,
          remove(newContents, (asset) => asset.assetId !== media.contentId)
        ) as CollectionAsset[];
      });
    }
  };

  const onRootClick = () => (onContentPicked ? onContentPicked(media.contentId) : onEdit());

  const onPin = () => {
    let newPinnedContents: string[] = [];

    setPinnedCollectionContents((pinnedContents) => {
      if (!pinnedContents?.length || !pinnedContents.includes(media.contentId)) {
        setIsPinned(true);

        newPinnedContents = [...(pinnedContents ? pinnedContents : []), media.contentId];
      } else {
        setIsPinned(false);
        newPinnedContents = pinnedContents.filter((contentId) => contentId !== media.contentId);
      }

      return newPinnedContents;
    });

    setSelectedCollectionContents((contents) => {
      if (!contents?.length) {
        return [];
      }

      return ensurePinnedElementsAtTheBeginning(newPinnedContents, contents) as string[];
    });

    if (isMixedCollection) {
      setSelectedCollectionAssets((contents) => {
        if (!contents) return [];

        return ensurePinnedElementsAtTheBeginning(newPinnedContents, contents) as CollectionAsset[];
      });
    }
  };

  // shared props for media buttons
  const mediaButtonsProps: MediaButtonsProps = {
    inCollection,
    aptForKids: isSuitableForKids(),
    collectionForKids,
    isEditing,
    previewMode,
    collectionSelected,
    onAdd: canAddDelete ? onAdd : undefined,
    onDelete: !isSmartQuery && canAddDelete ? onDelete : undefined,
    onEdit: onContentPicked ? onEdit : undefined,
    onSearch,
    onPin,
    isPinned,
    enablePinning,
    autoRotateIndex
  };

  return (
    <Box
      className={inActiveCollection ? 'sortableFiltered' : ''}
      onClick={onRootClick}
      data-testid={props['data-testid'] || testIds.root}
      sx={{ cursor: 'pointer' }}
    >
      {isList ? (
        <MediaList
          imageUrl={imageUrl}
          mediaId={mediaId}
          allowSort={!disableSorting}
          collectionForKids={collectionForKids}
          collectionSelected={collectionSelected}
          disabled={disabledItem || (collectionForKids && !isSuitableForKids())}
        >
          <MediaButtons {...mediaButtonsProps} />
          <div className={classes.styleFound} ref={ref} />
        </MediaList>
      ) : (
        <MediaImage
          mediaId={mediaId}
          imageUrl={imageUrl}
          isEditing={isEditing}
          displayAs={displayAs}
          inCollection={inCollection}
          collectionForKids={collectionForKids}
          previewMode={previewMode}
          collectionSelected={collectionSelected}
          isSmartQuery={isSmartQuery}
        >
          <MediaButtons className={classes.mediaButtons} backdrop size="small" {...mediaButtonsProps} />
          <div className={classes.styleFound} ref={ref} />
        </MediaImage>
      )}
    </Box>
  );
}

export default memo(Media);
