import React from 'react';
import { InlinePromoUiModuleBody } from '../../../../../API';
import Localized from '../../../../shared/Localized';
import { InlinePromoPreview } from '../../ContentPreview/InlinePromoPreview';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';

export type InlinePromoListItemProps = IBaseUIModuleListItemProps<InlinePromoUiModuleBody>;

export const testIds = {
  preview: 'inline-promo-list-item.preview'
};

const InlinePromoListItem = ({ uiModule, ...props }: InlinePromoListItemProps): JSX.Element => {
  return (
    <UIModuleListItem
      uiModule={uiModule}
      topLineTitle={<Localized prop={uiModule.title} />}
      renderPreview={(showPreview) => (
        <div data-testid={testIds.preview}>
          <InlinePromoPreview showing={showPreview} module={uiModule} />
        </div>
      )}
      {...props}
    />
  );
};

export default InlinePromoListItem;
