import {
  DocumentLocale,
  RecommendationsUiModuleBody,
  RecommendationsUiModuleBodyModuleTypeEnum,
  RecommendationsType,
  RecommendationsUIModuleContentTreatment
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankRecommendationsModule: NewModuleFunction<RecommendationsUiModuleBody> = (module) => ({
  title: {
    [DocumentLocale.ES]: ''
  },
  moduleType: RecommendationsUiModuleBodyModuleTypeEnum.RECOMMENDATIONS,
  contentTreatment: RecommendationsUIModuleContentTreatment.PORTRAIT,
  recommendationsType: RecommendationsType.TRENDING_NOW,
  moduleId: generateId(),
  contentTypes: [],
  ...module
});
