import React, { forwardRef } from 'react';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { WatchListUIModuleContentTreatment, WatchListUiModuleBody } from '../../../../../API';
import { Controller, useFormContext } from 'react-hook-form';
import { FormBody } from '../../styles';
import TreatmentPicker from '../../TreatmentPicker';

export const testIds = {
  formBody: 'watch-list-form.form-body'
};

const WathcListForm = forwardRef<UIModuleTypeForm<WatchListUiModuleBody>>(() => {
  const { control } = useFormContext<WatchListUiModuleBody>();

  return (
    <FormBody data-testid={testIds.formBody}>
      <div>
        <Controller
          control={control}
          name="contentTreatment"
          render={({ field: { value, onChange } }) => (
            <TreatmentPicker
              options={WatchListUIModuleContentTreatment}
              value={value}
              onChange={(value) => onChange(value as WatchListUIModuleContentTreatment)}
            />
          )}
        />
      </div>
    </FormBody>
  );
});

WathcListForm.displayName = 'WatchListForm';

export default WathcListForm;
