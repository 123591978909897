import { EntitlementType, SpinPremiaUiModuleBody, SpinPremiaUiModuleBodyModuleTypeEnum } from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankSpinPremiaModule: NewModuleFunction<SpinPremiaUiModuleBody> = (module) => ({
  moduleType: SpinPremiaUiModuleBodyModuleTypeEnum.SPIN_PREMIA,
  moduleId: generateId(),
  entitlements: [EntitlementType.ANON, EntitlementType.FREE, EntitlementType.SUB],
  vixLogo: '',
  spinLogo: '',
  planPickerHeader: '',
  planPickerSubheader: '',
  legalCopy: '',
  otp1Header: '',
  opt1CountryCode: '',
  opt1MobileHint: '',
  opt1Cta: '',
  otp2Header: '',
  opt2EditNumber: '',
  opt2ExpirationNotice: '',
  opt2ResendCode: '',
  summaryHeader: '',
  summaryPlanName: '',
  summaryPlanSku: '',
  summaryPlanPoints: '',
  summaryPlanTotal: '',
  summaryReminderHeader: '',
  summaryStartDate: '',
  summaryEndDate: '',
  summaryLegalCopy: '',
  summaryCta1: '',
  summaryCta2: '',
  usedAccountError: {
    id: generateId(),
    header: '',
    body: '',
    fstCta: '',
    sndCta: ''
  },
  unregisteredNumberError: {
    id: generateId(),
    header: '',
    body: '',
    fstCta: '',
    sndCta: ''
  },
  fundsError: {
    id: generateId(),
    header: '',
    body: '',
    fstCta: '',
    sndCta: ''
  },
  failedError: {
    id: generateId(),
    header: '',
    body: '',
    fstCta: '',
    sndCta: ''
  },
  unlinkAccount: {
    id: generateId(),
    header: '',
    body: '',
    fstCta: '',
    sndCta: ''
  },
  linkedAccountConfirmation: {
    id: generateId(),
    body: '',
    ack: ''
  },
  unlinkedAccountConfirmation: {
    id: generateId(),
    body: '',
    ack: ''
  },
  ...module
});
