import React, { useMemo } from 'react';
import { Skeleton } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { useData } from '../../../data-layer';
import { getMediaGridTemplateColumns, getPresetAspectRatio } from '../../../utils/styleUtils';
import { DisplayAsOptions } from '../../../utils/types/genericTypes';
import Repeat from '../../shared/Repeat';

const useStyles = makeStyles<{ displayAs: DisplayAsOptions }>()((theme, { displayAs }) => ({
  grid: {
    display: 'grid',
    minHeight: '100%',
    overflow: 'hidden',
    padding: theme.spacing(2),
    gridGap: theme.spacing(2),
    gridTemplateColumns: getMediaGridTemplateColumns(displayAs),
    gridTemplateRows: 'repeat(auto-fit, minmax(0, max-content))'
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2)
  },
  skeleton: {
    aspectRatio: getPresetAspectRatio(displayAs)
  },
  skeletonList: {
    height: 100
  }
}));

type PresetSkeletonProps = {
  'data-testid'?: string;
};

export function PresetSkeleton({ 'data-testid': dataTestId }: PresetSkeletonProps): React.ReactElement {
  const {
    heroPresetCollections: {
      state: { withDisplayAs }
    }
  } = useData();
  const displayAs = useRecoilValue(withDisplayAs);
  const { classes, cx } = useStyles({ displayAs });
  const isList = useMemo(() => displayAs === DisplayAsOptions.LIST, [displayAs]);

  return (
    <Skeleton
      className={cx({ [classes.skeleton]: !isList, [classes.skeletonList]: isList })}
      data-testid={dataTestId}
      animation="wave"
    />
  );
}

export function PresetsSkeleton({ 'data-testid': dataTestId }: PresetSkeletonProps): React.ReactElement {
  const {
    heroPresetCollections: {
      state: { withDisplayAs }
    }
  } = useData();
  const displayAs = useRecoilValue(withDisplayAs);
  const { classes, cx } = useStyles({ displayAs });
  const isList = useMemo(() => displayAs === DisplayAsOptions.LIST, [displayAs]);

  return (
    <div className={cx({ [classes.grid]: !isList, [classes.list]: isList })}>
      <Repeat n={50}>
        <PresetSkeleton data-testid={dataTestId} />
      </Repeat>
    </div>
  );
}
