import React, { useState } from 'react';
import Modal from '../../../../shared/Modal';
import { useLocales, useTheme } from '../../../../../hooks';
import { PlaybackPaywallUiModuleBody } from '../../../../../API';
import Button from '../../../../shared/Button';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useLokalise } from '../../../../../hooks/General/useLokalise';
import { makeStyles } from 'tss-react/mui';
import { Box, FormControlLabel, Skeleton, Stack, Switch, Tab, Typography } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { TextButton, VixOrangeButton } from '../shared/SharedButtons';
import ShadowScroller from '../../../../shared/ShadowScroller';

export interface PlaybackPaywallPreviewProps {
  module: PlaybackPaywallUiModuleBody;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export const testIds = {
  root: 'playback-paywall-preview.root',
  trailer: 'playback-paywall-preview.trailer',
  upNext: 'playback-paywall-preview.upNext'
};

enum PlaybackPaywallTabs {
  Trailer = 'trailer',
  UpNext = 'up_next'
}

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    height: '100%',
    color: 'white'
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  tabContent: {
    display: 'flex',
    justifyContent: 'center',
    ['& > div']: {
      width: '100%',
      padding: 0
    }
  },
  content: {
    background: 'linear-gradient(0deg, #0a0a0a, #222, #0a0a0a)',
    aspectRatio: '19.5 / 9',
    width: '100%'
  },
  leftSide: {
    padding: '32px 0 0 48px',
    flexDirection: 'row',
    gap: '24px'
  },
  rightSide: {
    background: '#0B0D17',
    width: '48%',
    height: '100%',
    padding: '32px 64px',
    display: 'flex',
    alignItems: 'center'
  },
  thumbnail: {
    aspectRatio: '16 / 9',
    borderRadius: '8px',
    background: '#333',
    height: 'auto'
  }
}));

export function PlaybackPaywallPreview({ module, isOpen, setIsOpen }: PlaybackPaywallPreviewProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { lt } = useLokalise();

  const [tab, setTab] = useState<PlaybackPaywallTabs>(PlaybackPaywallTabs.Trailer);
  const [isAuthed, setIsAuthed] = useState(false);

  const onModalClose = () => setIsOpen(false);
  const handleTabChange = (event: React.SyntheticEvent, newValue: PlaybackPaywallTabs) => setTab(newValue);

  const Screen: React.FC<React.PropsWithChildren> = ({ children }) => (
    <Box className={classes.content}>
      <Stack direction="row" justifyContent="space-between" height="100%">
        <Stack className={classes.leftSide}>
          <Box marginTop="4px">
            <ArrowBackIos />
          </Box>
          <Stack gap="4px">
            <Skeleton animation={false} variant="text" height="18px" width="115px" />
            <Skeleton animation={false} variant="text" height="12px" width="220px" />
            <Skeleton animation={false} variant="rounded" height="16px" width="42px" sx={{ borderRadius: 100 }} />
          </Stack>
        </Stack>
        <Box className={classes.rightSide}>{children}</Box>
      </Stack>
    </Box>
  );

  return (
    <Modal
      open={isOpen}
      onClose={onModalClose}
      headerLeft={t('layouts.playback_paywall.preview')}
      headerRight={
        <FormControlLabel
          control={
            <Switch
              color={formControlColor}
              checked={isAuthed}
              onChange={({ target: { checked } }) => setIsAuthed(checked)}
            />
          }
          label={t('layouts.one_brand_promo.display_as_auth')}
        />
      }
      footerLeft={
        <Button color="grey" onClick={onModalClose} sx={{ width: 140 }}>
          {t('general.close')}
        </Button>
      }
    >
      <Box className={classes.root} data-testid={testIds.root}>
        <TabContext value={tab}>
          <TabList
            indicatorColor={formControlColor}
            textColor={formControlColor}
            onChange={handleTabChange}
            centered
            className={classes.tabs}
          >
            {Object.values(PlaybackPaywallTabs).map((tab) => (
              <Tab key={tab} label={t(`layouts.playback_paywall.headers.${tab}`)} value={tab} />
            ))}
          </TabList>
          <ShadowScroller>
            <Box className={classes.tabContent}>
              <TabPanel value={PlaybackPaywallTabs.Trailer} data-testid={testIds.trailer}>
                <Screen>
                  <Stack gap="24px">
                    <Typography variant="h5">
                      <span dangerouslySetInnerHTML={{ __html: lt(module.postTrailerPrimaryText) }}></span>
                    </Typography>
                    <VixOrangeButton>{lt(module.postTrailerPrimaryCta)}</VixOrangeButton>
                    <TextButton>
                      {lt(isAuthed ? module.postTrailerSecondaryCtaAuth : module.postTrailerSecondaryCtaAnon)}
                    </TextButton>
                  </Stack>
                </Screen>
              </TabPanel>
              <TabPanel value={PlaybackPaywallTabs.UpNext} data-testid={testIds.upNext}>
                <Screen>
                  <Stack gap="24px" width="100%">
                    <Stack gap="8px">
                      <Typography>Ver T6:E9 con Premium</Typography>
                      <Skeleton animation={false} variant="rounded" className={classes.thumbnail} />
                    </Stack>
                    <VixOrangeButton>{lt(module.postEpisodePrimaryCta)}</VixOrangeButton>
                    <TextButton>
                      {lt(isAuthed ? module.postEpisodeSecondaryCtaAuth : module.postEpisodeSecondaryCtaAnon)}
                    </TextButton>
                  </Stack>
                </Screen>
              </TabPanel>
            </Box>
          </ShadowScroller>
        </TabContext>
      </Box>
    </Modal>
  );
}
