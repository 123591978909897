import { atom, RecoilState } from 'recoil';
import { VllChannelResponse } from '../../../../API';
import { localAtom } from '../../../../state/localStorageState';
import { DataManagerState, DataManagerStateFullReturnType } from '../../../DataManager/DataManagerState';

export interface ChannelsStateFullReturnType
  extends DataManagerStateFullReturnType<VllChannelResponse, VllChannelResponse> {
  withSpotlightChannelId: RecoilState<string | undefined>;
  withShowBlockedChannels: RecoilState<boolean>;
}

export function ChannelsState(name: string): ChannelsStateFullReturnType {
  const genericState = DataManagerState<VllChannelResponse, VllChannelResponse>(name);

  const withSpotlightChannelId = atom<string | undefined>({
    key: `${name}.spotlightChannelId`,
    default: undefined
  });

  const withShowBlockedChannels = localAtom<boolean>({
    key: `${name}.showGeoBlockedChannels`,
    default: true
  });

  return {
    ...genericState,
    withSpotlightChannelId,
    withShowBlockedChannels
  };
}
