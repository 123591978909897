import React, { useEffect, useMemo } from 'react';
import { Collapse, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { useData } from '../../../../../data-layer';
import AssetImage from '../../../../shared/AssetImage';
import Localized from '../../../../shared/Localized';

const COLLECTION_HERO_PREVIEW_HEIGHT = 620;

type CollectionSplashHeroPreviewProps = {
  collectionId: string;
  showing: boolean;
};

const useStyles = makeStyles<{ backgroundPosition: string | undefined }>()((theme, { backgroundPosition }) => ({
  preview: {
    position: 'relative',
    height: COLLECTION_HERO_PREVIEW_HEIGHT / 2,
    margin: theme.spacing(4),
    marginTop: 0,
    overflow: 'hidden',
    aspectRatio: '16 / 9'
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100% !important',
    objectPosition: backgroundPosition || 'center',
    borderRadius: theme.shape.borderRadius
  },
  details: {
    position: 'absolute',
    inset: 0,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    textShadow: '0 2px 5px black',
    justifyContent: 'end',
    background: 'linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)'
  }
}));

export function CollectionSplashHeroPreview({
  collectionId,
  showing
}: CollectionSplashHeroPreviewProps): React.ReactElement {
  const {
    collections: {
      state: { withRecordById: withCollectionRecordById },
      hook: { queueIdToFetch: queueCollectionIdToFetch }
    },
    assets: {
      state: { withAssetByFilePath },
      hook: { getByFilePath, getBackgroundPosition }
    }
  } = useData();
  const collection = useRecoilValue(withCollectionRecordById(collectionId));
  const asset = useRecoilValue(withAssetByFilePath(collection?.splashLandscapeImage));
  const backgroundPosition = useMemo(() => (asset ? getBackgroundPosition(asset, 'center') : undefined), [asset]);
  const { classes } = useStyles({ backgroundPosition });

  useEffect(() => {
    if (collection?.splashLandscapeImage && !asset) {
      getByFilePath(collection.splashLandscapeImage);
    }
  }, [collection, asset]);

  useEffect(() => {
    if (!collection && collectionId) {
      queueCollectionIdToFetch(collectionId);
    }
  }, [collectionId]);

  return (
    <Collapse in={showing} timeout="auto" mountOnEnter unmountOnExit>
      <div className={classes.preview}>
        {collection && (
          <>
            <AssetImage
              path={collection.splashLandscapeImage}
              size={COLLECTION_HERO_PREVIEW_HEIGHT}
              className={classes.image}
            />
            <Stack gap={2} className={classes.details}>
              <Typography variant="h6">
                <Localized prop={collection.title} />
              </Typography>
              <Typography variant="subtitle1">
                <Localized prop={collection.description} />
              </Typography>
            </Stack>
          </>
        )}
      </div>
    </Collapse>
  );
}
