import React, { useState } from 'react';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  UniqueIdentifier,
  DragOverlay,
  DragStartEvent
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
  SortingStrategy,
  rectSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box } from '@mui/material';
import { createPortal } from 'react-dom';
import { isNumber } from 'lodash-es';
import { VirtuosoHandle, VirtuosoProps } from 'react-virtuoso';
import { ShadowList } from '../Virtuoso';
import { atom, useSetRecoilState } from 'recoil';

export const withDndActive = atom<boolean>({
  key: 'dnd.active',
  default: false
});

interface ISortableItemProps {
  id: UniqueIdentifier;
  children: React.ReactNode;
}

function SortableItem({ id, children }: ISortableItemProps): React.ReactElement {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style: React.CSSProperties = {
    cursor: 'grab',
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.2 : 1
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
}

interface ISortable2Props<T> {
  list: T[];
  setList: (newState: T[]) => void;
  idField: keyof T;
  renderListItem: (item: T, index?: number) => React.ReactElement;
  virtualized?: boolean;
  disabled?: boolean;
  sortingStrategy?: SortingStrategy;
  draggableBackgroundColor?: 'default' | 'paper';
  virtuosoProps?: VirtuosoProps<MappedType<T>, unknown>;
  virtuosoRef?: React.Ref<VirtuosoHandle>;
}

type MappedType<T> = {
  id: UniqueIdentifier;
  object: T;
};

export function Sortable2<T>({
  list,
  setList,
  idField,
  renderListItem,
  disabled = false,
  draggableBackgroundColor,
  sortingStrategy,
  virtualized,
  virtuosoProps,
  virtuosoRef
}: ISortable2Props<T>): React.ReactElement {
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 1 } }));
  const [activeItem, setActiveItem] = useState<T | null>(null);
  const setIsDndActive = useSetRecoilState(withDndActive);

  const sortStrat = virtualized
    ? sortingStrategy === rectSortingStrategy
      ? verticalListSortingStrategy
      : sortingStrategy
    : sortingStrategy;

  const listTransformed: MappedType<T>[] = list.map((item) => ({
    id: item[idField] as UniqueIdentifier,
    object: item
  }));

  function handleDragStart(event: DragStartEvent) {
    if (!event.active) return;
    setIsDndActive(true);
    const index = event.active.data.current?.sortable.index;
    if (isNumber(index)) {
      setActiveItem(list[index]);
    }
  }

  function handleDragEnd(event: DragEndEvent) {
    setActiveItem(null);
    setIsDndActive(false);
    const { active, over } = event;

    if (over?.id && active.id !== over.id) {
      const oldIndex = listTransformed.findIndex((item) => item.id === active.id);
      const newIndex = listTransformed.findIndex((item) => item.id === over.id);

      const newList = arrayMove(listTransformed, oldIndex, newIndex);
      setList(newList.map(({ object }) => object));
    }
  }

  function handleDragCancel() {
    setActiveItem(null);
    setIsDndActive(false);
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext disabled={disabled} items={listTransformed} strategy={sortStrat}>
        {virtualized && (
          <ShadowList
            data={listTransformed}
            virtuosoRef={virtuosoRef}
            itemContent={(i, item) => (
              <SortableItem key={item.id} id={item.id}>
                {renderListItem(item.object, i)}
              </SortableItem>
            )}
            {...virtuosoProps}
          />
        )}
        {!virtualized &&
          listTransformed.map((item, i) => (
            <SortableItem key={item.id} id={item.id}>
              {renderListItem(item.object, i)}
            </SortableItem>
          ))}
      </SortableContext>
      {createPortal(
        <DragOverlay>
          {activeItem ? (
            <Box
              sx={(theme) => ({
                cursor: 'grabbing',
                backgroundColor: draggableBackgroundColor ? theme.palette.background[draggableBackgroundColor] : 'unset'
              })}
            >
              {renderListItem(activeItem, -1)}
            </Box>
          ) : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
}
