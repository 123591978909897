/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BecauseYouSource } from './because-you-source';
import { BecauseYouType } from './because-you-type';
import { BecauseYouUIModuleContentTreatment } from './because-you-uimodule-content-treatment';
import { EntitlementType } from './entitlement-type';
import { LocalizedFieldBody } from './localized-field-body';
import { TargetPlatform } from './target-platform';
import { UiModuleExperimentGroup } from './ui-module-experiment-group';

/**
 * 
 * @export
 * @interface BecauseYouUiModuleBody
 */
export interface BecauseYouUiModuleBody {
    /**
     * 
     * @type {string}
     * @memberof BecauseYouUiModuleBody
     */
    moduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof BecauseYouUiModuleBody
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof BecauseYouUiModuleBody
     */
    moduleType: BecauseYouUiModuleBodyModuleTypeEnum;
    /**
     * 
     * @type {Array<TargetPlatform>}
     * @memberof BecauseYouUiModuleBody
     */
    targetPlatform?: Array<TargetPlatform>;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof BecauseYouUiModuleBody
     */
    caption?: LocalizedFieldBody;
    /**
     * 
     * @type {Array<EntitlementType>}
     * @memberof BecauseYouUiModuleBody
     */
    entitlements?: Array<EntitlementType>;
    /**
     * 
     * @type {Array<UiModuleExperimentGroup>}
     * @memberof BecauseYouUiModuleBody
     */
    experiments?: Array<UiModuleExperimentGroup> | null;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof BecauseYouUiModuleBody
     */
    title: LocalizedFieldBody;
    /**
     * 
     * @type {string}
     * @memberof BecauseYouUiModuleBody
     */
    textTitle: string;
    /**
     * 
     * @type {BecauseYouUIModuleContentTreatment}
     * @memberof BecauseYouUiModuleBody
     */
    contentTreatment: BecauseYouUIModuleContentTreatment;
    /**
     * 
     * @type {BecauseYouType}
     * @memberof BecauseYouUiModuleBody
     */
    type: BecauseYouType;
    /**
     * 
     * @type {BecauseYouSource}
     * @memberof BecauseYouUiModuleBody
     */
    source: BecauseYouSource;
}

/**
    * @export
    * @enum {string}
    */
export enum BecauseYouUiModuleBodyModuleTypeEnum {
    BECAUSE_YOU = 'BECAUSE_YOU'
}



