import { atom } from 'recoil';
import { localAtom } from '../localStorageState';

export type Timezone = {
  label: string;
  description?: string;
  offset: number;
  isLocal?: boolean;
};

export const withCurrentTimezone = localAtom<Timezone>({
  key: 'timezone.currentTimezone',
  default: { label: '', description: '', offset: 0 }
});

export const withGlobalTimeDiff = atom<number>({
  key: 'globalTimeDiff',
  default: 0
});
