import {
  DocumentLocale,
  SportsEventUiModuleBody,
  SportsEventUiModuleBodyModuleTypeEnum,
  SportsEventUIModuleContentTreatment,
  UiModuleSportsEventType
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankSportsEventModule: NewModuleFunction<SportsEventUiModuleBody> = (module) => ({
  title: {
    [DocumentLocale.ES]: ''
  },
  moduleType: SportsEventUiModuleBodyModuleTypeEnum.SPORTS_EVENT,
  contentTreatment: SportsEventUIModuleContentTreatment.LANDSCAPE,
  sportsEventType: UiModuleSportsEventType.LIVE_AND_UPCOMING,
  sportId: '',
  moduleId: generateId(),
  ...module
});
