/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum FocusMode {
    AUTO = 'fo-auto',
    CUSTOM = 'fo-custom',
    FACE = 'fo-face',
    CENTER = 'fo-center',
    LEFT = 'fo-left',
    RIGHT = 'fo-right',
    TOP = 'fo-top',
    TOP_LEFT = 'fo-top_left',
    TOP_RIGHT = 'fo-top_right',
    BOTTOM = 'fo-bottom',
    BOTTOM_LEFT = 'fo-bottom_left',
    BOTTOM_RIGHT = 'fo-bottom_right'
}



