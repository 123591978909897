import { DocumentLocale } from '../API';

export interface ICountry {
  code: string;
  name: {
    [lang in DocumentLocale]?: string;
  };
  region: string;
  grouping: {
    [lang in DocumentLocale]?: string;
  };
  continent?: string;
  supported: boolean;
}

export function sortByCountryName(langForCountryName: DocumentLocale): (a: ICountry, b: ICountry) => 1 | 0 | -1 {
  return (a, b) => {
    const an = a.name[langForCountryName];
    const bn = b.name[langForCountryName];
    if (an === bn) return 0;
    if (!an) return -1;
    if (!bn) return 1;
    return an > bn ? 1 : -1;
  };
}

const COUNTRY_GROUPINGS = {
  NA: { [DocumentLocale.EN]: 'North America', [DocumentLocale.ES]: 'Norteamérica' },
  SA: { [DocumentLocale.EN]: 'South America', [DocumentLocale.ES]: 'Sudamerica' },
  CA: { [DocumentLocale.EN]: 'Central America', [DocumentLocale.ES]: 'Centroamérica' },
  CB: { [DocumentLocale.EN]: 'The Caribbean', [DocumentLocale.ES]: 'El caribe' },
  ROW: { [DocumentLocale.EN]: 'Rest of world', [DocumentLocale.ES]: 'Resto del mundo' }
};

export const ALL_COUNTRIES: ICountry[] = [
  {
    code: 'AD',
    name: {
      [DocumentLocale.ES]: 'Andorra',
      [DocumentLocale.EN]: 'Andorra'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'AE',
    name: {
      [DocumentLocale.ES]: 'Emiratos Árabes Unidos',
      [DocumentLocale.EN]: 'United Arab Emirates'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'AF',
    name: {
      [DocumentLocale.ES]: 'Afganistán',
      [DocumentLocale.EN]: 'Afghanistan'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'AG',
    name: {
      [DocumentLocale.ES]: 'Antigua y Barbuda',
      [DocumentLocale.EN]: 'Antigua and Barbuda'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'AI',
    name: {
      [DocumentLocale.ES]: 'Anguila',
      [DocumentLocale.EN]: 'Anguilla'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'AL',
    name: {
      [DocumentLocale.ES]: 'Albania',
      [DocumentLocale.EN]: 'Albania'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'AM',
    name: {
      [DocumentLocale.ES]: 'Armenia',
      [DocumentLocale.EN]: 'Armenia'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'AO',
    name: {
      [DocumentLocale.ES]: 'Angola',
      [DocumentLocale.EN]: 'Angola'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'AQ',
    name: {
      [DocumentLocale.ES]: 'Antártida',
      [DocumentLocale.EN]: 'Antarctica'
    },
    region: 'Antarctica',
    grouping: {},
    continent: 'AN',
    supported: false
  },
  {
    code: 'AR',
    name: {
      [DocumentLocale.ES]: 'Argentina',
      [DocumentLocale.EN]: 'Argentina'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.SA,
    continent: 'SA',
    supported: true
  },
  {
    code: 'AS',
    name: {
      [DocumentLocale.ES]: 'Samoa Americana',
      [DocumentLocale.EN]: 'American Samoa'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'AT',
    name: {
      [DocumentLocale.ES]: 'Austria',
      [DocumentLocale.EN]: 'Austria'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'AU',
    name: {
      [DocumentLocale.ES]: 'Australia',
      [DocumentLocale.EN]: 'Australia'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'AW',
    name: {
      [DocumentLocale.ES]: 'Aruba',
      [DocumentLocale.EN]: 'Aruba'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'AX',
    name: {
      [DocumentLocale.ES]: 'Islas Åland',
      [DocumentLocale.EN]: 'Åland Islands'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'AZ',
    name: {
      [DocumentLocale.ES]: 'Azerbaiyán',
      [DocumentLocale.EN]: 'Azerbaijan'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'BA',
    name: {
      [DocumentLocale.ES]: 'Bosnia y Herzegovina',
      [DocumentLocale.EN]: 'Bosnia and Herzegovina'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'BB',
    name: {
      [DocumentLocale.ES]: 'Barbados',
      [DocumentLocale.EN]: 'Barbados'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'BD',
    name: {
      [DocumentLocale.ES]: 'Bangladesh',
      [DocumentLocale.EN]: 'Bangladesh'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'BE',
    name: {
      [DocumentLocale.ES]: 'Bélgica',
      [DocumentLocale.EN]: 'Belgium'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'BF',
    name: {
      [DocumentLocale.ES]: 'Burkina Faso',
      [DocumentLocale.EN]: 'Burkina Faso'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'BG',
    name: {
      [DocumentLocale.ES]: 'Bulgaria',
      [DocumentLocale.EN]: 'Bulgaria'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'BH',
    name: {
      [DocumentLocale.ES]: 'Bahrein',
      [DocumentLocale.EN]: 'Bahrain'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'BI',
    name: {
      [DocumentLocale.ES]: 'Burundi',
      [DocumentLocale.EN]: 'Burundi'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'BJ',
    name: {
      [DocumentLocale.ES]: 'Benin',
      [DocumentLocale.EN]: 'Benin'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'BL',
    name: {
      [DocumentLocale.ES]: 'San Bartolomé',
      [DocumentLocale.EN]: 'Saint Barthélemy'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'BM',
    name: {
      [DocumentLocale.ES]: 'Bermuda',
      [DocumentLocale.EN]: 'Bermuda'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.NA,
    continent: 'NA',
    supported: false
  },
  {
    code: 'BN',
    name: {
      [DocumentLocale.ES]: 'Brunei Darussalam',
      [DocumentLocale.EN]: 'Brunei Darussalam'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'BO',
    name: {
      [DocumentLocale.ES]: 'Bolivia',
      [DocumentLocale.EN]: 'Bolivia'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.SA,
    continent: 'SA',
    supported: true
  },
  {
    code: 'BQ',
    name: {
      [DocumentLocale.ES]: 'Bonaire, San Eustaquio y Saba',
      [DocumentLocale.EN]: 'Bonaire, Sint Eustatius and Saba'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'BR',
    name: {
      [DocumentLocale.ES]: 'Brasil',
      [DocumentLocale.EN]: 'Brazil'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.SA,
    continent: 'SA',
    supported: false
  },
  {
    code: 'BS',
    name: {
      [DocumentLocale.ES]: 'Bahamas',
      [DocumentLocale.EN]: 'Bahamas'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'BT',
    name: {
      [DocumentLocale.ES]: 'Bhután',
      [DocumentLocale.EN]: 'Bhutan'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'BV',
    name: {
      [DocumentLocale.ES]: 'Isla Bouvet',
      [DocumentLocale.EN]: 'Bouvet Island'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'AN',
    supported: false
  },
  {
    code: 'BW',
    name: {
      [DocumentLocale.ES]: 'Botswana',
      [DocumentLocale.EN]: 'Botswana'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'BY',
    name: {
      [DocumentLocale.ES]: 'Belarús',
      [DocumentLocale.EN]: 'Belarus'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'BZ',
    name: {
      [DocumentLocale.ES]: 'Belice',
      [DocumentLocale.EN]: 'Belize'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CA,
    continent: 'NA',
    supported: false
  },
  {
    code: 'CA',
    name: {
      [DocumentLocale.ES]: 'Canadá',
      [DocumentLocale.EN]: 'Canada'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.NA,
    continent: 'NA',
    supported: false
  },
  {
    code: 'CC',
    name: {
      [DocumentLocale.ES]: 'Islas Cocos (Keeling)',
      [DocumentLocale.EN]: 'Cocos (Keeling) Islands'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'CD',
    name: {
      [DocumentLocale.ES]: 'República Democrática del Congo',
      [DocumentLocale.EN]: 'Democratic Republic of the Congo'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'CF',
    name: {
      [DocumentLocale.ES]: 'República Centroafricana',
      [DocumentLocale.EN]: 'Central African Republic'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'CG',
    name: {
      [DocumentLocale.ES]: 'Congo',
      [DocumentLocale.EN]: 'Congo'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'CH',
    name: {
      [DocumentLocale.ES]: 'Suiza',
      [DocumentLocale.EN]: 'Switzerland'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'CI',
    name: {
      [DocumentLocale.ES]: "Côte d'Ivoire",
      [DocumentLocale.EN]: "Côte d'Ivoire"
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'CK',
    name: {
      [DocumentLocale.ES]: 'Islas Cook',
      [DocumentLocale.EN]: 'Cook Islands'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'CL',
    name: {
      [DocumentLocale.ES]: 'Chile',
      [DocumentLocale.EN]: 'Chile'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.SA,
    continent: 'SA',
    supported: true
  },
  {
    code: 'CM',
    name: {
      [DocumentLocale.ES]: 'Camerún',
      [DocumentLocale.EN]: 'Cameroon'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'CN',
    name: {
      [DocumentLocale.ES]: 'China',
      [DocumentLocale.EN]: 'China'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'CO',
    name: {
      [DocumentLocale.ES]: 'Colombia',
      [DocumentLocale.EN]: 'Colombia'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.SA,
    continent: 'SA',
    supported: true
  },
  {
    code: 'CR',
    name: {
      [DocumentLocale.ES]: 'Costa Rica',
      [DocumentLocale.EN]: 'Costa Rica'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CA,
    continent: 'NA',
    supported: true
  },
  {
    code: 'CU',
    name: {
      [DocumentLocale.ES]: 'Cuba',
      [DocumentLocale.EN]: 'Cuba'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'CV',
    name: {
      [DocumentLocale.ES]: 'Cabo Verde',
      [DocumentLocale.EN]: 'Cabo Verde'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'CW',
    name: {
      [DocumentLocale.ES]: 'Curaçao',
      [DocumentLocale.EN]: 'Curaçao'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'CX',
    name: {
      [DocumentLocale.ES]: 'Isla de Navidad',
      [DocumentLocale.EN]: 'Christmas Island'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'CY',
    name: {
      [DocumentLocale.ES]: 'Chipre',
      [DocumentLocale.EN]: 'Cyprus'
    },
    region: 'Asia',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'CZ',
    name: {
      [DocumentLocale.ES]: 'Chequia',
      [DocumentLocale.EN]: 'Czechia'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'DE',
    name: {
      [DocumentLocale.ES]: 'Alemania',
      [DocumentLocale.EN]: 'Germany'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'DJ',
    name: {
      [DocumentLocale.ES]: 'Djibouti',
      [DocumentLocale.EN]: 'Djibouti'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'DK',
    name: {
      [DocumentLocale.ES]: 'Dinamarca',
      [DocumentLocale.EN]: 'Denmark'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'DM',
    name: {
      [DocumentLocale.ES]: 'Dominica',
      [DocumentLocale.EN]: 'Dominica'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'DO',
    name: {
      [DocumentLocale.ES]: 'República Dominicana',
      [DocumentLocale.EN]: 'Dominican Republic'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: true
  },
  {
    code: 'DZ',
    name: {
      [DocumentLocale.ES]: 'Argelia',
      [DocumentLocale.EN]: 'Algeria'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'EC',
    name: {
      [DocumentLocale.ES]: 'Ecuador',
      [DocumentLocale.EN]: 'Ecuador'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.SA,
    continent: 'SA',
    supported: true
  },
  {
    code: 'EE',
    name: {
      [DocumentLocale.ES]: 'Estonia',
      [DocumentLocale.EN]: 'Estonia'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'EG',
    name: {
      [DocumentLocale.ES]: 'Egipto',
      [DocumentLocale.EN]: 'Egypt'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'EH',
    name: {
      [DocumentLocale.ES]: 'Sáhara Occidental',
      [DocumentLocale.EN]: 'Western Sahara'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'ER',
    name: {
      [DocumentLocale.ES]: 'Eritrea',
      [DocumentLocale.EN]: 'Eritrea'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'ES',
    name: {
      [DocumentLocale.ES]: 'España',
      [DocumentLocale.EN]: 'Spain'
    },
    region: 'Europe',
    grouping: COUNTRY_GROUPINGS.ROW,
    continent: 'EU',
    supported: false
  },
  {
    code: 'ET',
    name: {
      [DocumentLocale.ES]: 'Etiopía',
      [DocumentLocale.EN]: 'Ethiopia'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'FI',
    name: {
      [DocumentLocale.ES]: 'Finlandia',
      [DocumentLocale.EN]: 'Finland'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'FJ',
    name: {
      [DocumentLocale.ES]: 'Fiji',
      [DocumentLocale.EN]: 'Fiji'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'FK',
    name: {
      [DocumentLocale.ES]: 'Islas Malvinas (Falkland)',
      [DocumentLocale.EN]: 'Falkland Islands (Malvinas)'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'SA',
    supported: false
  },
  {
    code: 'FM',
    name: {
      [DocumentLocale.ES]: 'Micronesia (Estados Federados de)',
      [DocumentLocale.EN]: 'Micronesia (Federated States of)'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'FO',
    name: {
      [DocumentLocale.ES]: 'Islas Feroe',
      [DocumentLocale.EN]: 'Faroe Islands'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'FR',
    name: {
      [DocumentLocale.ES]: 'Francia',
      [DocumentLocale.EN]: 'France'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'GA',
    name: {
      [DocumentLocale.ES]: 'Gabón',
      [DocumentLocale.EN]: 'Gabon'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'GB',
    name: {
      [DocumentLocale.ES]: 'Reino Unido de Gran Bretaña e Irlanda del Norte',
      [DocumentLocale.EN]: 'United Kingdom of Great Britain and Northern Ireland'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'GD',
    name: {
      [DocumentLocale.ES]: 'Granada',
      [DocumentLocale.EN]: 'Grenada'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'GE',
    name: {
      [DocumentLocale.ES]: 'Georgia',
      [DocumentLocale.EN]: 'Georgia'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'GF',
    name: {
      [DocumentLocale.ES]: 'Guayana Francesa',
      [DocumentLocale.EN]: 'French Guiana'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.SA,
    continent: 'SA',
    supported: false
  },
  {
    code: 'GG',
    name: {
      [DocumentLocale.ES]: 'Guernsey',
      [DocumentLocale.EN]: 'Guernsey'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'GH',
    name: {
      [DocumentLocale.ES]: 'Ghana',
      [DocumentLocale.EN]: 'Ghana'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'GI',
    name: {
      [DocumentLocale.ES]: 'Gibraltar',
      [DocumentLocale.EN]: 'Gibraltar'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'GL',
    name: {
      [DocumentLocale.ES]: 'Groenlandia',
      [DocumentLocale.EN]: 'Greenland'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.NA,
    continent: 'NA',
    supported: false
  },
  {
    code: 'GM',
    name: {
      [DocumentLocale.ES]: 'Gambia',
      [DocumentLocale.EN]: 'Gambia'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'GN',
    name: {
      [DocumentLocale.ES]: 'Guinea',
      [DocumentLocale.EN]: 'Guinea'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'GP',
    name: {
      [DocumentLocale.ES]: 'Guadalupe',
      [DocumentLocale.EN]: 'Guadeloupe'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'GQ',
    name: {
      [DocumentLocale.ES]: 'Guinea Ecuatorial',
      [DocumentLocale.EN]: 'Equatorial Guinea'
    },
    region: 'Africa',
    grouping: COUNTRY_GROUPINGS.ROW,
    continent: 'AF',
    supported: false
  },
  {
    code: 'GR',
    name: {
      [DocumentLocale.ES]: 'Grecia',
      [DocumentLocale.EN]: 'Greece'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'GS',
    name: {
      [DocumentLocale.ES]: 'Georgia del Sur y las Islas Sandwich del Sur',
      [DocumentLocale.EN]: 'South Georgia and the South Sandwich Islands'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'AN',
    supported: false
  },
  {
    code: 'GT',
    name: {
      [DocumentLocale.ES]: 'Guatemala',
      [DocumentLocale.EN]: 'Guatemala'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CA,
    continent: 'NA',
    supported: true
  },
  {
    code: 'GU',
    name: {
      [DocumentLocale.ES]: 'Guam',
      [DocumentLocale.EN]: 'Guam'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'GW',
    name: {
      [DocumentLocale.ES]: 'Guinea-Bissau',
      [DocumentLocale.EN]: 'Guinea-Bissau'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'GY',
    name: {
      [DocumentLocale.ES]: 'Guyana',
      [DocumentLocale.EN]: 'Guyana'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'SA',
    supported: false
  },
  {
    code: 'HK',
    name: {
      [DocumentLocale.ES]: 'China, región administrativa especial de Hong Kong',
      [DocumentLocale.EN]: 'China, Hong Kong Special Administrative Region'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'HM',
    name: {
      [DocumentLocale.ES]: 'Islas Heard y McDonald',
      [DocumentLocale.EN]: 'Heard Island and McDonald Islands'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'AN',
    supported: false
  },
  {
    code: 'HN',
    name: {
      [DocumentLocale.ES]: 'Honduras',
      [DocumentLocale.EN]: 'Honduras'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CA,
    continent: 'NA',
    supported: true
  },
  {
    code: 'HR',
    name: {
      [DocumentLocale.ES]: 'Croacia',
      [DocumentLocale.EN]: 'Croatia'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'HT',
    name: {
      [DocumentLocale.ES]: 'Haití',
      [DocumentLocale.EN]: 'Haiti'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'HU',
    name: {
      [DocumentLocale.ES]: 'Hungría',
      [DocumentLocale.EN]: 'Hungary'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'ID',
    name: {
      [DocumentLocale.ES]: 'Indonesia',
      [DocumentLocale.EN]: 'Indonesia'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'IE',
    name: {
      [DocumentLocale.ES]: 'Irlanda',
      [DocumentLocale.EN]: 'Ireland'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'IL',
    name: {
      [DocumentLocale.ES]: 'Israel',
      [DocumentLocale.EN]: 'Israel'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'IM',
    name: {
      [DocumentLocale.ES]: 'Isla de Man',
      [DocumentLocale.EN]: 'Isle of Man'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'IN',
    name: {
      [DocumentLocale.ES]: 'India',
      [DocumentLocale.EN]: 'India'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'IO',
    name: {
      [DocumentLocale.ES]: 'Territorio Británico del Océano Índico',
      [DocumentLocale.EN]: 'British Indian Ocean Territory'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'IQ',
    name: {
      [DocumentLocale.ES]: 'Iraq',
      [DocumentLocale.EN]: 'Iraq'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'IR',
    name: {
      [DocumentLocale.ES]: 'Irán (República Islámica del)',
      [DocumentLocale.EN]: 'Iran (Islamic Republic of)'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'IS',
    name: {
      [DocumentLocale.ES]: 'Islandia',
      [DocumentLocale.EN]: 'Iceland'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'IT',
    name: {
      [DocumentLocale.ES]: 'Italia',
      [DocumentLocale.EN]: 'Italy'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'JE',
    name: {
      [DocumentLocale.ES]: 'Jersey',
      [DocumentLocale.EN]: 'Jersey'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'JM',
    name: {
      [DocumentLocale.ES]: 'Jamaica',
      [DocumentLocale.EN]: 'Jamaica'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'JO',
    name: {
      [DocumentLocale.ES]: 'Jordania',
      [DocumentLocale.EN]: 'Jordan'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'JP',
    name: {
      [DocumentLocale.ES]: 'Japón',
      [DocumentLocale.EN]: 'Japan'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'KE',
    name: {
      [DocumentLocale.ES]: 'Kenya',
      [DocumentLocale.EN]: 'Kenya'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'KG',
    name: {
      [DocumentLocale.ES]: 'Kirguistán',
      [DocumentLocale.EN]: 'Kyrgyzstan'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'KH',
    name: {
      [DocumentLocale.ES]: 'Camboya',
      [DocumentLocale.EN]: 'Cambodia'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'KI',
    name: {
      [DocumentLocale.ES]: 'Kiribati',
      [DocumentLocale.EN]: 'Kiribati'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'KM',
    name: {
      [DocumentLocale.ES]: 'Comoras',
      [DocumentLocale.EN]: 'Comoros'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'KN',
    name: {
      [DocumentLocale.ES]: 'Saint Kitts y Nevis',
      [DocumentLocale.EN]: 'Saint Kitts and Nevis'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'KP',
    name: {
      [DocumentLocale.ES]: 'República Popular Democrática de Corea',
      [DocumentLocale.EN]: "Democratic People's Republic of Korea"
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'KR',
    name: {
      [DocumentLocale.ES]: 'República de Corea',
      [DocumentLocale.EN]: 'Republic of Korea'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'KW',
    name: {
      [DocumentLocale.ES]: 'Kuwait',
      [DocumentLocale.EN]: 'Kuwait'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'KY',
    name: {
      [DocumentLocale.ES]: 'Islas Caimán',
      [DocumentLocale.EN]: 'Cayman Islands'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'KZ',
    name: {
      [DocumentLocale.ES]: 'Kazajstán',
      [DocumentLocale.EN]: 'Kazakhstan'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'LA',
    name: {
      [DocumentLocale.ES]: 'República Democrática Popular Lao',
      [DocumentLocale.EN]: "Lao People's Democratic Republic"
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'LB',
    name: {
      [DocumentLocale.ES]: 'Líbano',
      [DocumentLocale.EN]: 'Lebanon'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'LC',
    name: {
      [DocumentLocale.ES]: 'Santa Lucía',
      [DocumentLocale.EN]: 'Saint Lucia'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'LI',
    name: {
      [DocumentLocale.ES]: 'Liechtenstein',
      [DocumentLocale.EN]: 'Liechtenstein'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'LK',
    name: {
      [DocumentLocale.ES]: 'Sri Lanka',
      [DocumentLocale.EN]: 'Sri Lanka'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'LR',
    name: {
      [DocumentLocale.ES]: 'Liberia',
      [DocumentLocale.EN]: 'Liberia'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'LS',
    name: {
      [DocumentLocale.ES]: 'Lesotho',
      [DocumentLocale.EN]: 'Lesotho'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'LT',
    name: {
      [DocumentLocale.ES]: 'Lituania',
      [DocumentLocale.EN]: 'Lithuania'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'LU',
    name: {
      [DocumentLocale.ES]: 'Luxemburgo',
      [DocumentLocale.EN]: 'Luxembourg'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'LV',
    name: {
      [DocumentLocale.ES]: 'Letonia',
      [DocumentLocale.EN]: 'Latvia'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'LY',
    name: {
      [DocumentLocale.ES]: 'Libia',
      [DocumentLocale.EN]: 'Libya'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'MA',
    name: {
      [DocumentLocale.ES]: 'Marruecos',
      [DocumentLocale.EN]: 'Morocco'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'MC',
    name: {
      [DocumentLocale.ES]: 'Mónaco',
      [DocumentLocale.EN]: 'Monaco'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'MD',
    name: {
      [DocumentLocale.ES]: 'República de Moldova',
      [DocumentLocale.EN]: 'Republic of Moldova'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'ME',
    name: {
      [DocumentLocale.ES]: 'Montenegro',
      [DocumentLocale.EN]: 'Montenegro'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'MF',
    name: {
      [DocumentLocale.ES]: 'San Martín (parte francesa)',
      [DocumentLocale.EN]: 'Saint Martin (French Part)'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'MG',
    name: {
      [DocumentLocale.ES]: 'Madagascar',
      [DocumentLocale.EN]: 'Madagascar'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'MH',
    name: {
      [DocumentLocale.ES]: 'Islas Marshall',
      [DocumentLocale.EN]: 'Marshall Islands'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'MK',
    name: {
      [DocumentLocale.ES]: 'ex República Yugoslava de Macedonia',
      [DocumentLocale.EN]: 'The former Yugoslav Republic of Macedonia'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'ML',
    name: {
      [DocumentLocale.ES]: 'Malí',
      [DocumentLocale.EN]: 'Mali'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'MM',
    name: {
      [DocumentLocale.ES]: 'Myanmar',
      [DocumentLocale.EN]: 'Myanmar'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'MN',
    name: {
      [DocumentLocale.ES]: 'Mongolia',
      [DocumentLocale.EN]: 'Mongolia'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'MO',
    name: {
      [DocumentLocale.ES]: 'China, región administrativa especial de Macao',
      [DocumentLocale.EN]: 'China, Macao Special Administrative Region'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'MP',
    name: {
      [DocumentLocale.ES]: 'Islas Marianas Septentrionales',
      [DocumentLocale.EN]: 'Northern Mariana Islands'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'MQ',
    name: {
      [DocumentLocale.ES]: 'Martinica',
      [DocumentLocale.EN]: 'Martinique'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'MR',
    name: {
      [DocumentLocale.ES]: 'Mauritania',
      [DocumentLocale.EN]: 'Mauritania'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'MS',
    name: {
      [DocumentLocale.ES]: 'Montserrat',
      [DocumentLocale.EN]: 'Montserrat'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'MT',
    name: {
      [DocumentLocale.ES]: 'Malta',
      [DocumentLocale.EN]: 'Malta'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'MU',
    name: {
      [DocumentLocale.ES]: 'Mauricio',
      [DocumentLocale.EN]: 'Mauritius'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'MV',
    name: {
      [DocumentLocale.ES]: 'Maldivas',
      [DocumentLocale.EN]: 'Maldives'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'MW',
    name: {
      [DocumentLocale.ES]: 'Malawi',
      [DocumentLocale.EN]: 'Malawi'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'MX',
    name: {
      [DocumentLocale.ES]: 'México',
      [DocumentLocale.EN]: 'Mexico'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.NA,
    continent: 'NA',
    supported: true
  },
  {
    code: 'MY',
    name: {
      [DocumentLocale.ES]: 'Malasia',
      [DocumentLocale.EN]: 'Malaysia'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'MZ',
    name: {
      [DocumentLocale.ES]: 'Mozambique',
      [DocumentLocale.EN]: 'Mozambique'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'NA',
    name: {
      [DocumentLocale.ES]: 'Namibia',
      [DocumentLocale.EN]: 'Namibia'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'NC',
    name: {
      [DocumentLocale.ES]: 'Nueva Caledonia',
      [DocumentLocale.EN]: 'New Caledonia'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'NE',
    name: {
      [DocumentLocale.ES]: 'Níger',
      [DocumentLocale.EN]: 'Niger'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'NF',
    name: {
      [DocumentLocale.ES]: 'Islas Norfolk',
      [DocumentLocale.EN]: 'Norfolk Island'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'NG',
    name: {
      [DocumentLocale.ES]: 'Nigeria',
      [DocumentLocale.EN]: 'Nigeria'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'NI',
    name: {
      [DocumentLocale.ES]: 'Nicaragua',
      [DocumentLocale.EN]: 'Nicaragua'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CA,
    continent: 'NA',
    supported: true
  },
  {
    code: 'NL',
    name: {
      [DocumentLocale.ES]: 'Países Bajos',
      [DocumentLocale.EN]: 'Netherlands'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'NO',
    name: {
      [DocumentLocale.ES]: 'Noruega',
      [DocumentLocale.EN]: 'Norway'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'NP',
    name: {
      [DocumentLocale.ES]: 'Nepal',
      [DocumentLocale.EN]: 'Nepal'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'NR',
    name: {
      [DocumentLocale.ES]: 'Nauru',
      [DocumentLocale.EN]: 'Nauru'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'NU',
    name: {
      [DocumentLocale.ES]: 'Niue',
      [DocumentLocale.EN]: 'Niue'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'NZ',
    name: {
      [DocumentLocale.ES]: 'Nueva Zelandia',
      [DocumentLocale.EN]: 'New Zealand'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'OM',
    name: {
      [DocumentLocale.ES]: 'Omán',
      [DocumentLocale.EN]: 'Oman'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'PA',
    name: {
      [DocumentLocale.ES]: 'Panamá',
      [DocumentLocale.EN]: 'Panama'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CA,
    continent: 'NA',
    supported: true
  },
  {
    code: 'PE',
    name: {
      [DocumentLocale.ES]: 'Perú',
      [DocumentLocale.EN]: 'Peru'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.SA,
    continent: 'SA',
    supported: true
  },
  {
    code: 'PF',
    name: {
      [DocumentLocale.ES]: 'Polinesia Francesa',
      [DocumentLocale.EN]: 'French Polynesia'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'PG',
    name: {
      [DocumentLocale.ES]: 'Papua Nueva Guinea',
      [DocumentLocale.EN]: 'Papua New Guinea'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'PH',
    name: {
      [DocumentLocale.ES]: 'Filipinas',
      [DocumentLocale.EN]: 'Philippines'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'PK',
    name: {
      [DocumentLocale.ES]: 'Pakistán',
      [DocumentLocale.EN]: 'Pakistan'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'PL',
    name: {
      [DocumentLocale.ES]: 'Polonia',
      [DocumentLocale.EN]: 'Poland'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'PM',
    name: {
      [DocumentLocale.ES]: 'Saint Pierre y Miquelon',
      [DocumentLocale.EN]: 'Saint Pierre and Miquelon'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.NA,
    continent: 'NA',
    supported: false
  },
  {
    code: 'PN',
    name: {
      [DocumentLocale.ES]: 'Pitcairn',
      [DocumentLocale.EN]: 'Pitcairn'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'PR',
    name: {
      [DocumentLocale.ES]: 'Puerto Rico',
      [DocumentLocale.EN]: 'Puerto Rico'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: true
  },
  {
    code: 'PS',
    name: {
      [DocumentLocale.ES]: 'Estado de Palestina',
      [DocumentLocale.EN]: 'State of Palestine'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'PT',
    name: {
      [DocumentLocale.ES]: 'Portugal',
      [DocumentLocale.EN]: 'Portugal'
    },
    region: 'Europe',
    grouping: COUNTRY_GROUPINGS.ROW,
    continent: 'EU',
    supported: false
  },
  {
    code: 'PW',
    name: {
      [DocumentLocale.ES]: 'Palau',
      [DocumentLocale.EN]: 'Palau'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'PY',
    name: {
      [DocumentLocale.ES]: 'Paraguay',
      [DocumentLocale.EN]: 'Paraguay'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.SA,
    continent: 'SA',
    supported: true
  },
  {
    code: 'QA',
    name: {
      [DocumentLocale.ES]: 'Qatar',
      [DocumentLocale.EN]: 'Qatar'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'RE',
    name: {
      [DocumentLocale.ES]: 'Reunión',
      [DocumentLocale.EN]: 'Réunion'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'RO',
    name: {
      [DocumentLocale.ES]: 'Rumania',
      [DocumentLocale.EN]: 'Romania'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'RS',
    name: {
      [DocumentLocale.ES]: 'Serbia',
      [DocumentLocale.EN]: 'Serbia'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'RU',
    name: {
      [DocumentLocale.ES]: 'Federación de Rusia',
      [DocumentLocale.EN]: 'Russian Federation'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'RW',
    name: {
      [DocumentLocale.ES]: 'Rwanda',
      [DocumentLocale.EN]: 'Rwanda'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'SA',
    name: {
      [DocumentLocale.ES]: 'Arabia Saudita',
      [DocumentLocale.EN]: 'Saudi Arabia'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'SB',
    name: {
      [DocumentLocale.ES]: 'Islas Salomón',
      [DocumentLocale.EN]: 'Solomon Islands'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'SC',
    name: {
      [DocumentLocale.ES]: 'Seychelles',
      [DocumentLocale.EN]: 'Seychelles'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'SD',
    name: {
      [DocumentLocale.ES]: 'Sudán',
      [DocumentLocale.EN]: 'Sudan'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'SE',
    name: {
      [DocumentLocale.ES]: 'Suecia',
      [DocumentLocale.EN]: 'Sweden'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'SG',
    name: {
      [DocumentLocale.ES]: 'Singapur',
      [DocumentLocale.EN]: 'Singapore'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'SH',
    name: {
      [DocumentLocale.ES]: 'Santa Elena',
      [DocumentLocale.EN]: 'Saint Helena'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'SI',
    name: {
      [DocumentLocale.ES]: 'Eslovenia',
      [DocumentLocale.EN]: 'Slovenia'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'SJ',
    name: {
      [DocumentLocale.ES]: 'Islas Svalbard y Jan Mayen',
      [DocumentLocale.EN]: 'Svalbard and Jan Mayen Islands'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'SK',
    name: {
      [DocumentLocale.ES]: 'Eslovaquia',
      [DocumentLocale.EN]: 'Slovakia'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'SL',
    name: {
      [DocumentLocale.ES]: 'Sierra Leona',
      [DocumentLocale.EN]: 'Sierra Leone'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'SM',
    name: {
      [DocumentLocale.ES]: 'San Marino',
      [DocumentLocale.EN]: 'San Marino'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'SN',
    name: {
      [DocumentLocale.ES]: 'Senegal',
      [DocumentLocale.EN]: 'Senegal'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'SO',
    name: {
      [DocumentLocale.ES]: 'Somalia',
      [DocumentLocale.EN]: 'Somalia'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'SR',
    name: {
      [DocumentLocale.ES]: 'Suriname',
      [DocumentLocale.EN]: 'Suriname'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.SA,
    continent: 'SA',
    supported: false
  },
  {
    code: 'SS',
    name: {
      [DocumentLocale.ES]: 'Sudán del Sur',
      [DocumentLocale.EN]: 'South Sudan'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'ST',
    name: {
      [DocumentLocale.ES]: 'Santo Tomé y Príncipe',
      [DocumentLocale.EN]: 'Sao Tome and Principe'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'SV',
    name: {
      [DocumentLocale.ES]: 'El Salvador',
      [DocumentLocale.EN]: 'El Salvador'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CA,
    continent: 'NA',
    supported: true
  },
  {
    code: 'SX',
    name: {
      [DocumentLocale.ES]: 'San Martín (parte holandés)',
      [DocumentLocale.EN]: 'Sint Maarten (Dutch part)'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'SY',
    name: {
      [DocumentLocale.ES]: 'República Árabe Siria',
      [DocumentLocale.EN]: 'Syrian Arab Republic'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'SZ',
    name: {
      [DocumentLocale.ES]: 'Eswatini',
      [DocumentLocale.EN]: 'Eswatini'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'TC',
    name: {
      [DocumentLocale.ES]: 'Islas Turcas y Caicos',
      [DocumentLocale.EN]: 'Turks and Caicos Islands'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'TD',
    name: {
      [DocumentLocale.ES]: 'Chad',
      [DocumentLocale.EN]: 'Chad'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'TF',
    name: {
      [DocumentLocale.ES]: 'Territorio de las Tierras Australes Francesas',
      [DocumentLocale.EN]: 'French Southern Territories'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AN',
    supported: false
  },
  {
    code: 'TG',
    name: {
      [DocumentLocale.ES]: 'Togo',
      [DocumentLocale.EN]: 'Togo'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'TH',
    name: {
      [DocumentLocale.ES]: 'Tailandia',
      [DocumentLocale.EN]: 'Thailand'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'TJ',
    name: {
      [DocumentLocale.ES]: 'Tayikistán',
      [DocumentLocale.EN]: 'Tajikistan'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'TK',
    name: {
      [DocumentLocale.ES]: 'Tokelau',
      [DocumentLocale.EN]: 'Tokelau'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'TL',
    name: {
      [DocumentLocale.ES]: 'Timor-Leste',
      [DocumentLocale.EN]: 'Timor-Leste'
    },
    region: 'Asia',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'TM',
    name: {
      [DocumentLocale.ES]: 'Turkmenistán',
      [DocumentLocale.EN]: 'Turkmenistan'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'TN',
    name: {
      [DocumentLocale.ES]: 'Túnez',
      [DocumentLocale.EN]: 'Tunisia'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'TO',
    name: {
      [DocumentLocale.ES]: 'Tonga',
      [DocumentLocale.EN]: 'Tonga'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'TR',
    name: {
      [DocumentLocale.ES]: 'Turquía',
      [DocumentLocale.EN]: 'Turkey'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'TT',
    name: {
      [DocumentLocale.ES]: 'Trinidad y Tabago',
      [DocumentLocale.EN]: 'Trinidad and Tobago'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'TV',
    name: {
      [DocumentLocale.ES]: 'Tuvalu',
      [DocumentLocale.EN]: 'Tuvalu'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'TW',
    name: {
      [DocumentLocale.ES]: 'Taiwán',
      [DocumentLocale.EN]: 'Taiwan'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'TZ',
    name: {
      [DocumentLocale.ES]: 'República Unida de Tanzanía',
      [DocumentLocale.EN]: 'United Republic of Tanzania'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'UA',
    name: {
      [DocumentLocale.ES]: 'Ucrania',
      [DocumentLocale.EN]: 'Ukraine'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'UG',
    name: {
      [DocumentLocale.ES]: 'Uganda',
      [DocumentLocale.EN]: 'Uganda'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'UM',
    name: {
      [DocumentLocale.ES]: 'Islas menores alejadas de Estados Unidos',
      [DocumentLocale.EN]: 'United States Minor Outlying Islands'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'US',
    name: {
      [DocumentLocale.ES]: 'Estados Unidos de América',
      [DocumentLocale.EN]: 'United States of America'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.NA,
    continent: 'NA',
    supported: true
  },
  {
    code: 'UY',
    name: {
      [DocumentLocale.ES]: 'Uruguay',
      [DocumentLocale.EN]: 'Uruguay'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.SA,
    continent: 'SA',
    supported: true
  },
  {
    code: 'UZ',
    name: {
      [DocumentLocale.ES]: 'Uzbekistán',
      [DocumentLocale.EN]: 'Uzbekistan'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'VA',
    name: {
      [DocumentLocale.ES]: 'Santa Sede',
      [DocumentLocale.EN]: 'Holy See'
    },
    region: 'Europe',
    grouping: {},
    continent: 'EU',
    supported: false
  },
  {
    code: 'VC',
    name: {
      [DocumentLocale.ES]: 'San Vicente y las Granadinas',
      [DocumentLocale.EN]: 'Saint Vincent and the Grenadines'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'VE',
    name: {
      [DocumentLocale.ES]: 'Venezuela',
      [DocumentLocale.EN]: 'Venezuela'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.SA,
    continent: 'SA',
    supported: true
  },
  {
    code: 'VG',
    name: {
      [DocumentLocale.ES]: 'Islas Vírgenes Británicas',
      [DocumentLocale.EN]: 'British Virgin Islands'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'VI',
    name: {
      [DocumentLocale.ES]: 'Islas Vírgenes de los Estados Unidos',
      [DocumentLocale.EN]: 'United States Virgin Islands'
    },
    region: 'Americas',
    grouping: COUNTRY_GROUPINGS.CB,
    continent: 'NA',
    supported: false
  },
  {
    code: 'VN',
    name: {
      [DocumentLocale.ES]: 'Viet Nam',
      [DocumentLocale.EN]: 'Viet Nam'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'VU',
    name: {
      [DocumentLocale.ES]: 'Vanuatu',
      [DocumentLocale.EN]: 'Vanuatu'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'WF',
    name: {
      [DocumentLocale.ES]: 'Islas Wallis y Futuna',
      [DocumentLocale.EN]: 'Wallis and Futuna Islands'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'WS',
    name: {
      [DocumentLocale.ES]: 'Samoa',
      [DocumentLocale.EN]: 'Samoa'
    },
    region: 'Oceania',
    grouping: {},
    continent: 'OC',
    supported: false
  },
  {
    code: 'YE',
    name: {
      [DocumentLocale.ES]: 'Yemen',
      [DocumentLocale.EN]: 'Yemen'
    },
    region: 'Asia',
    grouping: {},
    continent: 'AS',
    supported: false
  },
  {
    code: 'YT',
    name: {
      [DocumentLocale.ES]: 'Mayotte',
      [DocumentLocale.EN]: 'Mayotte'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'ZA',
    name: {
      [DocumentLocale.ES]: 'Sudáfrica',
      [DocumentLocale.EN]: 'South Africa'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'ZM',
    name: {
      [DocumentLocale.ES]: 'Zambia',
      [DocumentLocale.EN]: 'Zambia'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  },
  {
    code: 'ZW',
    name: {
      [DocumentLocale.ES]: 'Zimbabwe',
      [DocumentLocale.EN]: 'Zimbabwe'
    },
    region: 'Africa',
    grouping: {},
    continent: 'AF',
    supported: false
  }
];

const COUNTRIES = ALL_COUNTRIES.filter((country) => country.supported);

const COUNTRY_PRESETS = [
  {
    name: {
      [DocumentLocale.ES]: 'EEUU y Puerto Rico',
      [DocumentLocale.EN]: 'US & Puerto Rico'
    },
    countries: ['US', 'PR']
  },
  {
    name: {
      [DocumentLocale.ES]: 'LAT-AM',
      [DocumentLocale.EN]: 'LAT-AM'
    },
    countries: ['MX', 'AR', 'CL', 'UY', 'PY', 'BO', 'PE', 'EC', 'CO', 'VE', 'PA', 'CR', 'NI', 'HN', 'SV', 'GT', 'DO']
  },
  {
    name: {
      [DocumentLocale.ES]: 'Todos Países',
      [DocumentLocale.EN]: 'All Countries'
    },
    countries: COUNTRIES.map((country) => country.code)
  }
];

const continentsHash: Record<string, boolean> = {};
const CONTINENTS: string[] = [];
const COUNTRIES_BY_ID: Record<string, ICountry> = {};

ALL_COUNTRIES.forEach((country) => {
  if (country.supported && !continentsHash[country.region]) {
    continentsHash[country.region] = true;
    CONTINENTS.push(country.region);
  }
  COUNTRIES_BY_ID[country.code] = country;
});

export { COUNTRIES, COUNTRY_GROUPINGS, COUNTRIES_BY_ID, CONTINENTS, COUNTRY_PRESETS };
