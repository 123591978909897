import { DocumentLocale, MenuActionType, MenuItemUiModuleBody, MenuItemUiModuleBodyModuleTypeEnum } from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';
import { NONE } from '../../../utils/types/genericTypes';

export const blankMenuItemModule: NewModuleFunction<MenuItemUiModuleBody> = (module) => ({
  title: {
    [DocumentLocale.ES]: ''
  },
  moduleType: MenuItemUiModuleBodyModuleTypeEnum.MENU_ITEM,
  menuActionType: MenuActionType.PAGE,
  targetId: '',
  iconImage: '',
  iconName: NONE as never,
  moduleId: generateId(),
  ...module
});
