import i18n, { ParseKeys } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enLocales from './en.json';
import esLocales from './es.json';
import ptLocales from './pt.json';

export const resources = {
  en: { translation: enLocales },
  es: { translation: esLocales },
  pt: { translation: ptLocales }
} as const;

export type LocaleKeys = ParseKeys extends infer A ? A : never;

export type SupportedLanguage = keyof typeof resources;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'es',
    load: 'languageOnly',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
