import { RecoilState, selector, selectorFamily, RecoilValueReadOnly } from 'recoil';
import { CategoryOverrideBody, VllCategoriesBundleBody, VllCategoriesBundleResponse } from '../../../../API';
import { localAtom } from '../../../../state/localStorageState';
import { DataManagerState, DataManagerStateFullReturnType } from '../../../DataManager/DataManagerState';

export interface CategoryBundlesStateFullReturnType
  extends DataManagerStateFullReturnType<VllCategoriesBundleResponse, VllCategoriesBundleBody> {
  withSelectedCategoryOverrideIndex: RecoilState<number>;
  withSelectedCategoryBundleCategories: RecoilState<string[] | undefined>;
  withSelectedCategoryExists: (categoryId: string) => RecoilValueReadOnly<boolean>;
}

export function CategoryBundlesState(name: string): CategoryBundlesStateFullReturnType {
  const genericState = DataManagerState<VllCategoriesBundleResponse, VllCategoriesBundleBody>(name);

  // If 0, will point to the default categories list
  // If > 0, subtract 1 and use that value as the index of the overrides array
  const withSelectedCategoryOverrideIndex = localAtom<number>({
    key: `${name}.selectedCategoryOverrideIndex`,
    default: 0
  });

  // Helper selector that references the list of categories to use and update,
  // taking into account the selected override index
  const withSelectedCategoryBundleCategories = selector<string[] | undefined>({
    key: `${name}.selectedCategoryBundleCategories`,
    get: ({ get }) => {
      const selectedCategoryBundle = get(genericState.withSelected);
      if (!selectedCategoryBundle) return;
      const overrideIndex = get(withSelectedCategoryOverrideIndex);
      if (overrideIndex && selectedCategoryBundle.categoryOverrides?.[overrideIndex - 1]) {
        return selectedCategoryBundle.categoryOverrides[overrideIndex - 1].order;
      }
      return selectedCategoryBundle.categories;
    },
    set: ({ get, set }, categories) => {
      const selectedCategoryBundle = get(genericState.withSelected);
      if (!selectedCategoryBundle || !categories) return;
      const overrideIndex = get(withSelectedCategoryOverrideIndex);
      if (overrideIndex && selectedCategoryBundle.categoryOverrides) {
        const categoryOverrides = selectedCategoryBundle.categoryOverrides
          .slice(0, overrideIndex - 1)
          .concat({
            ...selectedCategoryBundle.categoryOverrides[overrideIndex - 1],
            order: categories
          } as CategoryOverrideBody)
          .concat(selectedCategoryBundle.categoryOverrides.slice(overrideIndex));
        set(genericState.withSelected, { ...selectedCategoryBundle, categoryOverrides } as VllCategoriesBundleResponse);
      } else {
        set(genericState.withSelected, { ...selectedCategoryBundle, categories } as VllCategoriesBundleResponse);
      }
    }
  });

  // Helper selector that check if the selected category exists in selectedCategoryBundle
  const withSelectedCategoryExists = selectorFamily<boolean, string>({
    key: `${name}.selectedCategoryExists`,
    get: (categoryId) => {
      return ({ get }) => {
        const selectedCategoryBundle = get(genericState.withSelected);
        if (!selectedCategoryBundle) return false;
        return selectedCategoryBundle.categories.includes(categoryId);
      };
    }
  });

  return {
    ...genericState,
    withSelectedCategoryOverrideIndex,
    withSelectedCategoryBundleCategories,
    withSelectedCategoryExists
  };
}
