import { ApiErrorResponse, ErrorType } from '../../API';
import { ErrorDetails, UseLocalesReturnType } from '../../hooks';
import { parseDeleteChannelError } from './channels';
import { parseCollectionAssociatedError } from './collections';

export type ApiErrorChecker = (error: ApiErrorResponse) => boolean;
export type ApiErrorParser<T extends ApiErrorResponse = ApiErrorResponse> = (
  error: T,
  locale: UseLocalesReturnType,
  details: ErrorDetails | undefined
) => ErrorDetails | undefined;

export const ApiErrors: Partial<Record<ErrorType, ApiErrorParser>> = {
  [ErrorType.COLLECTION_ASSOCIATED_ERROR]: <ApiErrorParser>parseCollectionAssociatedError,
  [ErrorType.DELETE_CHANNEL_ERROR]: <ApiErrorParser>parseDeleteChannelError
};
