import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getAvailablePlatforms, useLocales, useNotifications, useTheme } from '../../../hooks';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  UiModuleBody,
  withLayoutFormMetadata,
  withSelectedLayoutUIModules,
  withLayoutErrors
} from '../../../state/Layouts';
import { Box, Chip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Button from '../../shared/Button';
import Drawer from '../../shared/Drawer';
import { AddOutlined, Upload } from '@mui/icons-material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import TextValidator from '../../shared/TextValidator';
import LocalizedInputCollection from '../../shared/LocalizedInputCollection';
import { EntitlementsMultiSelect } from '../../shared/Entitlements';
import { LocaleKeys } from '../../../locales/i18n';
import InputController from '../../shared/InputController';
import { UiModules } from '../../../utils/layouts';
import {
  DataExchange,
  ExportEntityType,
  MenuItemUiModuleBody,
  MenuItemUiModuleBodyModuleTypeEnum,
  MenuSectionUiModuleBody,
  MenuSectionUiModuleBodyModuleTypeEnum
} from '../../../API';
import { markAsRequired, fieldErrorsToArray } from '../../../utils/formHelpers';
import { PlatformMultiSelect } from '../../shared/Platforms/PlatformMultiSelect';
import { NONE } from '../../../utils/types/genericTypes';
import { isFunction } from 'lodash-es';
import { ExperimentsPicker } from '../../shared/ExperimentsPicker';
import { withStatsigUiModulesDefaultConfig, withUiModulesConfigByType } from '../../../state/Experiments';
import { useData } from '../../../data-layer';
import { useDropzone } from 'react-dropzone';
import { withUiModuleImportInconsistenciesModal } from '../../../state/General/withExchange';
import UIModuleImportInconsistenciesModal from '../UIModuleImportInconsistenciesModal';
import IconButton from '../../shared/IconButton';
import UIModuleExport from '../UIModuleExport';

const useStyles = makeStyles()((theme) => ({
  header: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center'
  },
  headerRight: {
    display: 'flex',
    gap: theme.spacing(4)
  },
  footerButton: {
    minWidth: 120,
    marginRight: theme.spacing(4)
  },
  topSection: {
    marginBottom: theme.spacing(4)
  },
  importEntityPicker: {
    display: 'inline-flex'
  }
}));

export const testIds = {
  validatorForm: 'uiModule-form.validator-form',
  cancelButton: 'uiModule-form.cancel-button',
  saveButton: 'uiModule-form.save-button',
  publishButton: 'uiModule-form.publish-button',
  treatmentSelector: 'uiModule-form.treatment-selector',
  treatmentSelectorItem: 'uiModule-form.treatment-selector-item',
  typeSelector: 'uiModule-form.type-selector',
  typeSelectorItem: 'uiModule-form.type-selector-item'
};

export type UIModuleTypeForm<T = UiModuleBody> = {
  isValid: (module: T) => Promise<boolean> | boolean;
};

export type UIModuleTypeFormProps = {
  headerRef: MutableRefObject<HTMLDivElement | null>;
};

type UIModuleFormProps = {
  hasUpsertPermission?: boolean;
};

function UIModuleForm({ hasUpsertPermission }: UIModuleFormProps): JSX.Element {
  const {
    dataExchanges: {
      hook: { validateImportEntity }
    }
  } = useData();

  const validatorRef = useRef<ValidatorForm>(null);
  const moduleRef = useRef<UIModuleTypeForm>(null);

  const { classes } = useStyles();
  const { t } = useLocales();
  const [uiModules, setUIModules] = useRecoilState(withSelectedLayoutUIModules);
  const setUiModuleImportInconsistenciesModal = useSetRecoilState(withUiModuleImportInconsistenciesModal);
  const { formControlColor } = useTheme();
  const { notifyError } = useNotifications();

  const [formMetadata, setFormMetadata] = useRecoilState(withLayoutFormMetadata);
  const setLayoutErrors = useSetRecoilState(withLayoutErrors);

  const methods = useForm<UiModuleBody>();
  const { control, reset, getValues, handleSubmit, watch } = methods;

  const [shouldDisableTitle, setShouldDisableTitle] = useState(false);

  const uiModulesDefaultConfig = useRecoilValue(withStatsigUiModulesDefaultConfig);
  const uiModuleConfig = useRecoilValue(withUiModulesConfigByType(formMetadata.record?.moduleType));
  const canUseExperiments = uiModuleConfig?.areExperimentsActive ?? uiModulesDefaultConfig?.areExperimentsActive;
  const availablePlatforms = getAvailablePlatforms(uiModulesDefaultConfig, uiModuleConfig) || [];
  const isExchangeable = uiModuleConfig?.exchangeable ?? uiModulesDefaultConfig?.exchangeable;

  const module = useMemo(() => {
    if (formMetadata.record) {
      return UiModules[formMetadata.record.moduleType];
    }
  }, [formMetadata]);

  useEffect(() => {
    const experiments = formMetadata.record?.experiments;
    const platforms = formMetadata.record?.targetPlatform || [];
    reset({
      ...formMetadata.record,
      experiments: experiments?.length ? experiments : null,
      targetPlatform: availablePlatforms.length > platforms.length ? platforms : availablePlatforms
    });
    if (formMetadata.record) {
      const disableTitle = module?.disableTitle;
      if (isFunction(disableTitle)) {
        const disableTitleFunction = (data: UiModuleBody) => setShouldDisableTitle(disableTitle(data) || false);
        disableTitleFunction(formMetadata.record);
        const watchSubscribe = watch((data) => disableTitleFunction(data as UiModuleBody));
        return () => watchSubscribe.unsubscribe();
      }
      setShouldDisableTitle(!!disableTitle);
    }
  }, [formMetadata]);

  const onDrop = useCallback(
    (files: File[]) => {
      if (files && files.length) {
        const file = files[0];
        const contentFile = new FileReader();
        contentFile.onload = async (event) => {
          const content = event.target?.result;
          const dataExchange = JSON.parse(content as string) as DataExchange;
          const record = dataExchange.uiModule as UiModuleBody;

          if (!record || record.moduleType !== formMetadata.record?.moduleType) {
            notifyError(t('layouts.invalid_ui_module'));
            return;
          }

          const validateImport = await validateImportEntity({
            entityType: ExportEntityType.UI_MODULE,
            data: dataExchange
          });

          if (!validateImport?.success) {
            setUiModuleImportInconsistenciesModal({
              isOpen: true,
              uiModuleType: formMetadata.record.moduleType,
              data: validateImport
            });
          }

          setFormMetadata({
            ...formMetadata,
            record,
            isNew: true,
            isShowingForm: true
          });
        };
        contentFile.readAsText(file);
      }
    },
    [formMetadata]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: { 'application/json': ['.json'] }
  });

  if (!formMetadata.record) return <></>;

  const setUiModuleState = (uiModules: UiModuleBody[]) => {
    setUIModules(uiModules);
  };

  const showForm = (show: boolean) => {
    setFormMetadata((state) => ({ ...state, isShowingForm: show }));
    setUiModuleImportInconsistenciesModal({
      isOpen: false
    });
  };

  const onSubmit = async (uiModule: UiModuleBody) => {
    // invalid refs
    if (!validatorRef.current || !uiModules) {
      return;
    }

    // validates form contents with validator components
    if (!validatorRef.current.isFormValid(false)) {
      notifyError(t('general.form_error'));
      return;
    }

    // validates the sub form elements
    if (moduleRef.current && !moduleRef.current.isValid(uiModule)) {
      return;
    }

    const menu: string[] = [
      MenuItemUiModuleBodyModuleTypeEnum.MENU_ITEM,
      MenuSectionUiModuleBodyModuleTypeEnum.MENU_SECTION
    ];

    if (menu.includes(uiModule.moduleType) && (uiModule as MenuItemUiModuleBody).iconName === (NONE as never)) {
      (uiModule as MenuItemUiModuleBody).iconName = undefined;
    }

    if (formMetadata.isNew) {
      const index = formMetadata.index as number;
      setUiModuleState(
        index === -1
          ? [...uiModules, uiModule]
          : uiModules.slice(0, index).concat(uiModule).concat(uiModules.slice(index))
      );
    } else {
      const newUiModules = [...uiModules];
      if (Array.isArray(formMetadata.index)) {
        const menuSection = { ...newUiModules[formMetadata.index[0]] } as MenuSectionUiModuleBody;
        const menuItems = [...menuSection.menuItems];
        menuItems[formMetadata.index[1]] = uiModule as MenuItemUiModuleBody;
        menuSection.menuItems = menuItems;
        newUiModules[formMetadata.index[0]] = menuSection;
      } else {
        newUiModules[formMetadata.index] = uiModule;
      }
      setUiModuleState(newUiModules);
    }

    showForm(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onInvalid = async (errors: any) => {
    if (Array.isArray(errors) && errors[0].props) {
      setLayoutErrors(errors.map((error) => error.props?.name));
    } else {
      setLayoutErrors(Object.keys(errors));
    }
    notifyError(t('general.form_error'), fieldErrorsToArray(errors));
  };

  const additionalFields = module?.additionalLocalizedFields?.map((field) => field({ control, t }));

  return (
    <Drawer
      open={formMetadata.isShowingForm}
      onSubmit={handleSubmit(onSubmit, onInvalid)}
      onFormError={onInvalid}
      onClose={() => showForm(false)}
      formRef={validatorRef}
      data-testid={testIds.validatorForm}
      headerLeft={
        <div className={classes.header}>
          <Typography variant="h6">{t(`layouts.${formMetadata.isNew ? 'new' : 'edit'}_ui_module`)}</Typography>
          <Chip
            label={
              <Typography variant="h6">
                {t(`layouts.ui_module_${formMetadata.record.moduleType?.toLowerCase()}` as LocaleKeys)}
              </Typography>
            }
          />
          {!formMetadata.isNew && formMetadata.record.moduleId && (
            <UIModuleExport uiModule={formMetadata.record} moduleType={formMetadata.record.moduleType} />
          )}
          {isExchangeable && formMetadata.isNew && (
            <div className={classes.importEntityPicker} {...getRootProps()}>
              <IconButton color="secondary" title={t('layouts.import_ui_module')}>
                <Upload />
              </IconButton>
              <input {...getInputProps()} />
            </div>
          )}
        </div>
      }
      headerRight={
        <div className={classes.headerRight}>
          {!module?.disablePlatformTargeting && (
            <Controller
              name="targetPlatform"
              control={control}
              render={({ field: { value, onChange } }) => (
                <PlatformMultiSelect
                  label={t('platforms.platforms')}
                  value={value || []}
                  onChange={onChange}
                  platforms={availablePlatforms}
                />
              )}
            />
          )}
          {!module?.disableEntitlements && (
            <Controller
              name="entitlements"
              control={control}
              render={({ field: { value, onChange } }) => (
                <EntitlementsMultiSelect
                  label={t('general.user_access_level')}
                  value={value || []}
                  onChange={onChange}
                />
              )}
            />
          )}
        </div>
      }
      footerLeft={
        <Box>
          <Button
            type="submit"
            endIcon={<AddOutlined />}
            className={classes.footerButton}
            disabled={!hasUpsertPermission}
            data-testid={testIds.saveButton}
          >
            {t(`layouts.${formMetadata.isNew ? 'add' : 'save'}_ui_module`)}
          </Button>
        </Box>
      }
      footerRight={
        <Button
          color="grey"
          className={classes.footerButton}
          onClick={() => showForm(false)}
          data-testid={testIds.cancelButton}
        >
          {t('general.cancel')}
        </Button>
      }
    >
      <FormProvider {...methods}>
        <Box sx={{ p: 4 }}>
          {!shouldDisableTitle && (
            <div className={classes.topSection}>
              <LocalizedInputCollection
                reset={reset}
                getValues={getValues}
                fields={[
                  {
                    component: (
                      <InputController
                        name="title"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <TextValidator
                            fullWidth
                            name="title"
                            value={value}
                            onChange={onChange}
                            label={markAsRequired(t('general.title'))}
                            color={formControlColor}
                            validators={['required']}
                            errorMessages={[t('general.field_is_required')]}
                          />
                        )}
                      />
                    )
                  },
                  ...(additionalFields || [])
                ]}
              />
            </div>
          )}
          {module && <module.Form ref={moduleRef} />}
          {canUseExperiments && (
            <div>
              <InputController
                name="experiments"
                control={control}
                rules={{ validate: (value) => !value || !!value.length }}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return <ExperimentsPicker value={value} onChange={onChange} hasFormError={!!error} />;
                }}
              />
            </div>
          )}
        </Box>
      </FormProvider>
      <UIModuleImportInconsistenciesModal />
    </Drawer>
  );
}

export default UIModuleForm;
