import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  withAllLayouts,
  withShowLayoutForm,
  withSelectedLayout,
  withSavingLayout,
  withLayoutSelectedCountries,
  withIsNewLayout,
  withPastLayoutsMeta,
  withLoadingPastLayouts,
  Layouts,
  DRAFT_LAYOUTS,
  FUTURE_LAYOUTS,
  PAST_LAYOUTS,
  WhichLayouts,
  withLiveLayouts,
  LiveLayoutMap,
  withLayoutsView,
  withLayoutsType,
  LAYOUT_VIEW_TYPE,
  withShowLayoutWarnings,
  withLayoutWarnings,
  withCopiedModules,
  withSelectedLayoutUIModules
} from '../../state/Layouts';
import { useHandleError, useLocales, useNotifications } from '../General';
import { uniqBy, intersection } from 'lodash-es';

import {
  LayoutType,
  Page,
  PageLayoutBodyV2,
  PageLayoutResponseV2,
  QuickScheduling,
  TimeQueryDirection
} from '../../API';
import { useLayoutsAPI } from '../API/Layouts/useLayoutsAPI';
import { newRelicAgent, newRelicCmsActions } from '../../utils/newRelicAgent';
import { DateTime } from 'luxon';
import { saveAsFile } from '../../utils/saveAsFile';
import { useData, useGetRecoilState } from '../../data-layer';
import { useLayoutsRouter } from './useLayoutsRouter';
import { countriesToParam } from '../../utils/layouts';
import { DUMMY_LAYOUT_ID } from '../../utils/appDefaults';
import generateId from '../../utils/generateId';

type ReturnType = {
  getLayouts: (page: Page, type: LayoutType, countries: string[], layoutId: string) => void;
  getLayoutById: (id: string) => Promise<PageLayoutResponseV2 | undefined>;
  refreshLayout: (id?: string) => Promise<void>;
  getOlderLayouts: () => void;
  saveFormLayout: (layoutToSave: PageLayoutBodyV2, quickScheduling?: QuickScheduling) => void;
  saveSelectedLayout: () => Promise<void>;
  onLayoutChange: (layoutId: string) => void;
  onViewTypeChange: (view: LAYOUT_VIEW_TYPE) => void;
  deleteDraftLayout: () => void;
  validateLayout: (layoutToValidate: PageLayoutBodyV2) => void;
  exportLayoutsCsv: (date: string) => Promise<void>;
  pasteModules: (index?: number) => Promise<void>;
};

function sortLayouts(a: PageLayoutResponseV2, b: PageLayoutResponseV2) {
  return new Date(a.startTime as string).getTime() - new Date(b.startTime as string).getTime();
}

export function useLayouts(): ReturnType {
  const layoutsApi = useLayoutsAPI();
  const { t, localize } = useLocales();
  const { handleError } = useHandleError();
  const { notifyError, notifySuccess } = useNotifications();
  const [savedLayout, setSavedLayout] = useState<PageLayoutResponseV2>();
  const { updateLayoutsRoute } = useLayoutsRouter();

  const [allLayouts, setLayouts] = useRecoilState(withAllLayouts);
  const setLiveLayouts = useSetRecoilState(withLiveLayouts);
  const setShowLayoutForm = useSetRecoilState(withShowLayoutForm);
  const setShowLayoutWarnings = useSetRecoilState(withShowLayoutWarnings);
  const setLayoutWarnings = useSetRecoilState(withLayoutWarnings);
  const setIsSaving = useSetRecoilState(withSavingLayout);
  const whichView = useRecoilValue(withLayoutsView);
  const copiedModules = useRecoilValue(withCopiedModules);
  const getSelectedLayoutModules = useGetRecoilState(withSelectedLayoutUIModules);
  const setSelectedLayoutModules = useSetRecoilState(withSelectedLayoutUIModules);

  const {
    pages: {
      state: { withSelected: withSelectedPage }
    }
  } = useData();
  const selectedPage = useRecoilValue(withSelectedPage);

  const selectedLayout = useRecoilValue(withSelectedLayout);
  const isNewLayout = useRecoilValue(withIsNewLayout);
  const [pastLayoutsMeta, setPastLayoutsMeta] = useRecoilState(withPastLayoutsMeta);
  const setLoadingPastLayouts = useSetRecoilState(withLoadingPastLayouts);

  const selectedCountries = useRecoilValue(withLayoutSelectedCountries);
  const layoutsType = useRecoilValue(withLayoutsType);

  useEffect(() => {
    updateLayoutInState();
  }, [savedLayout]);

  const onLayoutChange = (layoutId: string, countries: string[] = selectedCountries) => {
    updateLayoutsRoute({
      layoutId,
      countries: countriesToParam(countries)
    });
  };

  const getLiveLayouts = async (layoutType: LayoutType, page: string, countries: string[] = selectedCountries) => {
    const response = await layoutsApi.getLive(layoutType, page, countries, 50, 1);
    return response.data.body;
  };

  const handleLiveLayouts = (liveLayoutsList: PageLayoutResponseV2[]) => {
    const liveLayoutsObj: Record<string, LiveLayoutMap> = {};
    const foundLiveLayout: Record<string, boolean> = {};
    selectedCountries.forEach((country) => {
      foundLiveLayout[country] = false;
    });
    [...liveLayoutsList].reverse().forEach((layout) => {
      const countries: string[] = [];
      selectedCountries.forEach((country) => {
        if (!foundLiveLayout[country] && layout.countries.indexOf(country) >= 0) {
          countries.push(country);
          foundLiveLayout[country] = true;
        }
      });
      liveLayoutsObj[layout.id] = {
        layout,
        countries
      };
    });
    setLiveLayouts(liveLayoutsObj);
  };

  const getLayoutById = async (id: string) => {
    try {
      const {
        data: { body: layout }
      } = await layoutsApi.getById(id);
      setSavedLayout(layout);
      return layout;
    } catch (err) {
      console.error(err);
      handleError(err, t('errors.layouts.get_specific_layout', { id }), `${t('layouts.id')}: ${id}`);
    }
  };

  const getLayouts = async (page: Page, type: LayoutType, countries: string[], layoutId: string) => {
    try {
      setLayouts(undefined);
      setLiveLayouts(undefined);
      setPastLayoutsMeta(undefined);
      const pagePath = page.urlPath;
      const futureLayouts = await layoutsApi.getSchedule(
        50,
        1,
        pagePath,
        countries,
        new Date().toISOString(),
        TimeQueryDirection.AFTER
      );
      const liveLayouts = await getLiveLayouts(type, pagePath, countries);
      handleLiveLayouts(liveLayouts);
      const drafts = await getDrafts(type, pagePath, countries);
      const newLayouts = {
        [PAST_LAYOUTS]: liveLayouts,
        [FUTURE_LAYOUTS]: futureLayouts.data.body.results,
        [DRAFT_LAYOUTS]: drafts
      };
      setLayouts(newLayouts);
      await setDefaultSelectedLayoutInLayouts(newLayouts, page, layoutId, countries);
    } catch (err) {
      console.error(err);
      handleError(err, t('errors.layouts.getLayouts'), `${t('layouts.page_key')}: ${page.urlPath}`);
    }
  };

  const refreshLayout = async (id?: string) => {
    if (!id || !allLayouts || !selectedPage) return;
    const page = selectedPage.urlPath;
    try {
      const liveLayoutsList = await getLiveLayouts(layoutsType, page);
      const refreshedLayout = liveLayoutsList.find((layout) => layout.id === id);
      if (!refreshedLayout) return;
      handleLiveLayouts(liveLayoutsList);
      const newLayouts = { ...allLayouts };
      newLayouts[FUTURE_LAYOUTS] = newLayouts[FUTURE_LAYOUTS].filter((layout) => layout.id !== id);
      newLayouts[PAST_LAYOUTS] = [...newLayouts[PAST_LAYOUTS], refreshedLayout];
      setLayouts(newLayouts);

      // if layouts that were previously live are not live, DO NOT remove them but remove their live status
      // add the newly refreshed layout
    } catch (err) {
      console.error(err);
      handleError(err, t('errors.layouts.getLayouts'), `${t('layouts.page_key')}: ${page}`);
    }
  };

  const onViewTypeChange = (view: LAYOUT_VIEW_TYPE) => {
    if (!allLayouts || !selectedPage) return;
    if (view === LAYOUT_VIEW_TYPE.VIEW_DRAFTS) {
      onLayoutChange(allLayouts[DRAFT_LAYOUTS][0]?.id);
    } else {
      onLayoutChange(allLayouts[PAST_LAYOUTS][0]?.id || allLayouts[FUTURE_LAYOUTS][0]?.id);
    }
  };

  const setDefaultSelectedLayoutInLayouts = async (
    theLayouts: Layouts,
    page: Page,
    layoutId: string,
    countries: string[] = selectedCountries
  ) => {
    // Look for the provided layoutId in SCHEDULE and DRAFTS
    if (
      theLayouts[DRAFT_LAYOUTS].find((layout) => layout.id === layoutId) ||
      theLayouts[PAST_LAYOUTS].find((layout) => layout.id === layoutId) ||
      theLayouts[FUTURE_LAYOUTS].find((layout) => layout.id === layoutId)
    ) {
      onLayoutChange(layoutId, countries);
      return;
    }

    // Check it the provided layoutId is an older, previously live layout
    const layout = await fetchAndValidateOlderLayout(layoutId, page);
    if (layout) {
      const newLayouts = {
        ...theLayouts,
        [PAST_LAYOUTS]: [layout, ...theLayouts[PAST_LAYOUTS]]
      };
      setLayouts(newLayouts);
      onLayoutChange(layoutId, countries);
      return;
    }

    // First search for a layout in the currently selected view (SCHEDULE / DRAFT)
    let newLayoutId: string | undefined;
    if (whichView === LAYOUT_VIEW_TYPE.VIEW_DRAFTS) {
      newLayoutId = theLayouts[DRAFT_LAYOUTS][0]?.id;
    } else {
      newLayoutId = theLayouts[PAST_LAYOUTS][0]?.id || theLayouts[FUTURE_LAYOUTS][0]?.id;
    }

    // If we still don't have one, find one in the other section
    if (!newLayoutId) {
      if (whichView === LAYOUT_VIEW_TYPE.VIEW_DRAFTS) {
        newLayoutId = theLayouts[PAST_LAYOUTS][0]?.id || theLayouts[FUTURE_LAYOUTS][0]?.id;
      } else {
        newLayoutId = theLayouts[DRAFT_LAYOUTS][0]?.id;
      }
    }

    if (newLayoutId) {
      updateLayoutsRoute(
        {
          layoutId: newLayoutId
        },
        true
      );
    }
  };

  const fetchAndValidateOlderLayout = async (
    layoutId: string,
    page: Page
  ): Promise<PageLayoutResponseV2 | undefined> => {
    if (layoutId === DUMMY_LAYOUT_ID) return;
    try {
      const {
        data: { body: layout }
      } = await layoutsApi.getById(layoutId);
      /* When hotlinking to a past layout, it should only be valid if...
       * The layout is in the past
       * The URL path is the same as the selected one
       * There is some intersection between the layouts countries and the selected country filters */
      if (
        layout.startTime &&
        DateTime.fromISO(layout.startTime).toMillis() < DateTime.now().toMillis() &&
        layout.urlPath === page.urlPath &&
        intersection(layout.countries, selectedCountries).length > 0
      ) {
        return layout;
      }
    } catch (err) {
      console.error(err);
      handleError(err, t('errors.layouts.get_specific_layout', { id: layoutId }), `${t('layouts.id')}: ${layoutId}`);
    }
  };

  const getPastLayouts = async (page: string, pageNumber: number) => {
    const response = await layoutsApi.getSchedule(
      10,
      pageNumber,
      page,
      selectedCountries,
      new Date().toISOString(),
      TimeQueryDirection.BEFORE
    );
    setPastLayoutsMeta(response.data.body.meta);
    return response.data.body.results;
  };

  const getOlderLayouts = async () => {
    if (!allLayouts || !selectedPage) return;
    const newLayouts = getLayoutsShallowCopy(allLayouts);
    try {
      setLoadingPastLayouts(true);
      const page = selectedPage.urlPath;
      const pastLayouts = await getPastLayouts(page, pastLayoutsMeta?.nextPage || 1);
      newLayouts[PAST_LAYOUTS] = uniqBy(pastLayouts.reverse().concat(newLayouts[PAST_LAYOUTS]), (layout) => layout.id);
      newLayouts[PAST_LAYOUTS].sort(sortLayouts);
      setLayouts(newLayouts);
    } catch (err) {
      console.error(err);
      handleError(err, t('errors.layouts.getLayouts'), `${t('layouts.page_key')}: ${selectedPage.urlPath}`);
    }
    setLoadingPastLayouts(false);
  };

  const getDrafts = async (layoutType: string, page: string, countries: string[] = selectedCountries) => {
    const response = await layoutsApi.getDrafts(layoutType, page, countries, 50, 1);
    return response.data.body.results;
  };

  const getLayoutsShallowCopy = (layouts: Layouts) => {
    return {
      [PAST_LAYOUTS]: [...layouts[PAST_LAYOUTS]],
      [FUTURE_LAYOUTS]: [...layouts[FUTURE_LAYOUTS]],
      [DRAFT_LAYOUTS]: [...layouts[DRAFT_LAYOUTS]]
    };
  };

  const updateLayoutInState = () => {
    if (!allLayouts || !savedLayout) return;
    const newLayouts = getLayoutsShallowCopy(allLayouts);
    const isDraft = !savedLayout.startTime;
    const which = isDraft ? DRAFT_LAYOUTS : FUTURE_LAYOUTS;

    const indexFromDrafts = findLayoutIndex(savedLayout.id, DRAFT_LAYOUTS);
    const indexFromFuture = findLayoutIndex(savedLayout.id, FUTURE_LAYOUTS);
    let index = -1;
    if (indexFromFuture >= 0) {
      if (isDraft) {
        newLayouts[FUTURE_LAYOUTS] = newLayouts[FUTURE_LAYOUTS].slice(0, indexFromFuture).concat(
          newLayouts[FUTURE_LAYOUTS].slice(indexFromFuture + 1)
        );
      } else {
        index = indexFromFuture;
      }
    } else if (indexFromDrafts >= 0) {
      if (!isDraft) {
        newLayouts[DRAFT_LAYOUTS] = newLayouts[DRAFT_LAYOUTS].slice(0, indexFromDrafts).concat(
          newLayouts[DRAFT_LAYOUTS].slice(indexFromDrafts + 1)
        );
      } else {
        index = indexFromDrafts;
      }
    }
    if (index === -1) index = newLayouts[which].length;
    newLayouts[which][index] = savedLayout;
    newLayouts[which].sort(sortLayouts);

    let newSelectedCountries = selectedCountries;
    if (intersection(savedLayout.countries, selectedCountries).length === 0) {
      newSelectedCountries = [...selectedCountries, savedLayout.countries[0]];
    }

    setLayouts(newLayouts);
    onLayoutChange(savedLayout.id, newSelectedCountries);
  };

  const findLayoutIndex = (id: string, which: WhichLayouts = FUTURE_LAYOUTS) => {
    if (!allLayouts) return -1;
    return allLayouts[which].findIndex((layout) => layout.id === id);
  };

  const updateLayout = async (layoutToSave: PageLayoutResponseV2, quickScheduling?: QuickScheduling) => {
    if (!layoutToSave || !allLayouts) return;

    const isValidLayoutUIModules = layoutToSave.startTime
      ? layoutToSave.uiModules && layoutToSave.uiModules.length > 0
      : true;
    if (!isValidLayoutUIModules) {
      notifyError(
        t('errors.layouts.scheduled_layout_no_ui_modules'),
        `${t('layouts.layout')}: ${localize(layoutToSave.pageName)}`
      );
      return false;
    }

    const oldLayout = ([] as PageLayoutResponseV2[])
      .concat(allLayouts[DRAFT_LAYOUTS], allLayouts[FUTURE_LAYOUTS])
      .filter(Boolean)
      .find(({ id }) => id === layoutToSave.id);

    setSavedLayout(layoutToSave);
    try {
      const {
        data: { body: updatedLayout }
      } = await layoutsApi.update(layoutToSave.id, layoutToSave, quickScheduling);
      newRelicAgent(newRelicCmsActions.layouts.update as string, updatedLayout);
      notifySuccess(t('success.layouts.update'), [
        `${t('layouts.id')}: ${updatedLayout.id}`,
        `${t('layouts.layout')}: ${localize(updatedLayout.pageName)}`
      ]);
      setSavedLayout(updatedLayout);
      return true;
    } catch (err) {
      console.error(err);
      handleError(err, t('errors.layouts.update'), [
        `${t('general.id')}: ${layoutToSave.id}`,
        `${t('general.name')}: ${localize(layoutToSave.pageName)}`
      ]);
      setSavedLayout(oldLayout);
      return false;
    }
  };

  const deleteDraftLayout = async () => {
    if (!allLayouts || !selectedLayout || !selectedPage) return;
    const newLayouts = getLayoutsShallowCopy(allLayouts);
    const indexFromDrafts = findLayoutIndex(selectedLayout.id, DRAFT_LAYOUTS);
    try {
      newLayouts[DRAFT_LAYOUTS] = newLayouts[DRAFT_LAYOUTS].slice(0, indexFromDrafts).concat(
        newLayouts[DRAFT_LAYOUTS].slice(indexFromDrafts + 1)
      );
      setLayouts(newLayouts);
      await layoutsApi.remove(selectedLayout.id);
      setDefaultSelectedLayoutInLayouts(newLayouts, selectedPage, DUMMY_LAYOUT_ID);
      newRelicAgent(newRelicCmsActions.layouts.remove as string, selectedLayout.id);
      notifySuccess(t('success.layouts.delete'), [
        `${t('general.id')}: ${selectedLayout.id}`,
        `${t('general.name')}: ${localize(selectedLayout.pageName)}`
      ]);
    } catch (err) {
      console.error(err);
      handleError(err, t('errors.layouts.delete'), [
        `${t('general.id')}: ${selectedLayout.id}`,
        `${t('general.name')}: ${localize(selectedLayout.pageName)}`
      ]);
      setLayouts(allLayouts);
      return false;
    }
  };

  const createLayout = async (layoutToSave: PageLayoutBodyV2, quickScheduling?: QuickScheduling) => {
    if (!layoutToSave) return;
    setIsSaving(true);
    try {
      const {
        data: { body: layout }
      } = await layoutsApi.create(layoutToSave, quickScheduling);
      newRelicAgent(newRelicCmsActions.layouts.create as string, layout);
      notifySuccess(t('success.layouts.create'), `${t('general.name')}: ${localize(layoutToSave.pageName)}`);
      setSavedLayout(layout);
      setIsSaving(false);
      return true;
    } catch (err) {
      console.error(err);
      handleError(err, t('errors.layouts.create'), `${t('layouts.layout')}: ${localize(layoutToSave.pageName)}`);
      setShowLayoutForm(true);
      setIsSaving(false);
      return false;
    }
  };

  const saveLayout = async (
    layoutToSave: PageLayoutResponseV2 | PageLayoutBodyV2,
    quickScheduling?: QuickScheduling
  ) => {
    if (!layoutToSave) return;
    if (isNewLayout) {
      return await createLayout(layoutToSave, quickScheduling);
    }
    return await updateLayout(layoutToSave as PageLayoutResponseV2, quickScheduling);
  };

  const saveFormLayout = async (layoutToSave: PageLayoutBodyV2, quickScheduling?: QuickScheduling) => {
    return await saveLayout(layoutToSave, quickScheduling);
  };

  const validateLayout = async (layoutToValidate: PageLayoutBodyV2) => {
    try {
      const {
        data: { body: data }
      } = await layoutsApi.validateLayout(layoutToValidate);
      if (data?.contentErrors?.length) {
        setShowLayoutWarnings(true);
        setLayoutWarnings(data);
        return false;
      }
      return true;
    } catch (err) {
      console.error(err);
      handleError(err, t('errors.layouts.create'), `${t('layouts.layout')}: ${localize(layoutToValidate.pageName)}`);
      return false;
    }
  };

  const saveSelectedLayout = async () => {
    if (!selectedLayout) return;
    await updateLayout(selectedLayout);
  };

  const exportLayoutsCsv = async (date: string) => {
    try {
      const response = await layoutsApi.downloadCsv(date);
      saveAsFile(response);
    } catch (err) {
      handleError(
        err,
        t('errors.layouts.download_csv'),
        <div>
          {t('date_picker.start_date')}: {date}
        </div>
      );
    }
  };

  const pasteModules = async (index?: number) => {
    const selectedLayoutModules = await getSelectedLayoutModules();
    if (!selectedLayoutModules) return;
    setSelectedLayoutModules(
      selectedLayoutModules
        .slice(0, index)
        .concat(copiedModules.map((module) => ({ ...module, moduleId: generateId() })))
        .concat(selectedLayoutModules.slice(index))
    );
  };

  return {
    getLayouts,
    getLayoutById,
    refreshLayout,
    getOlderLayouts,
    saveFormLayout,
    saveSelectedLayout,
    onLayoutChange,
    onViewTypeChange,
    deleteDraftLayout,
    validateLayout,
    exportLayoutsCsv,
    pasteModules
  };
}
