import React, { useEffect, useRef, useState } from 'react';
import { InputAdornment } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import IconButton from '../../../shared/IconButton';
import { Search } from '@mui/icons-material';
import TextField from '../../../shared/TextField';
import { useFind } from '../../../../hooks';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  UiModuleSearchable,
  withLayoutModulesSearchTerm,
  withSearchFocusModuleId,
  withSelectedLayoutUIModulesSearchable
} from '../../../../state/Layouts';
import { stringToRegExp } from '../../../../utils/textUtils';

const useStyles = makeStyles()(() => ({
  textField: {
    width: 30,
    marginBottom: '0px !important',
    transition: 'width 0.3s ease-in-out',
    '.MuiInputBase-root:before': {
      borderBottomColor: 'transparent',
      color: 'transparent'
    }
  },
  textFieldOpen: {
    width: 300,
    '.MuiInputBase-root:before': {
      borderBottomColor: 'unset',
      color: 'unset'
    }
  }
}));

export const testIds = {
  textField: 'layout-view-search.text-field'
};

export function LayoutViewSearch(): React.ReactElement {
  const { cx, classes } = useStyles();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchText, setSearchText] = useRecoilState(withLayoutModulesSearchTerm);
  const searchRef = useRef<HTMLInputElement>();
  const selectedUiModulesSearchable = useRecoilValue(withSelectedLayoutUIModulesSearchable);
  const [matches, setMatches] = useState<UiModuleSearchable[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const setFocusedModuleId = useSetRecoilState(withSearchFocusModuleId);

  useEffect(() => {
    if (!selectedUiModulesSearchable) return;
    if (searchText) {
      const rgx = stringToRegExp(searchText, 'i');
      const newMatches = selectedUiModulesSearchable.filter(({ text }) => rgx.test(text));
      setMatches(newMatches);
      if (newMatches.length) {
        setCurrentIndex(0);
        setFocusedModuleId(newMatches[0].moduleId);
      }
    } else {
      setMatches([]);
    }
  }, [searchText, selectedUiModulesSearchable]);

  useFind(() => {
    if (!isSearchOpen) {
      setIsSearchOpen(true);
    }
    focusSearch();
  });

  const toggleSearch = () => {
    if (isSearchOpen) {
      setSearchText('');
    } else {
      focusSearch();
    }
    setIsSearchOpen(!isSearchOpen);
  };

  const focusSearch = () => {
    searchRef.current?.focus();
  };

  const openSearch = () => {
    setIsSearchOpen(true);
  };

  const keyHandler: React.KeyboardEventHandler<HTMLDivElement> = (evt) => {
    if (evt.key === 'Enter') {
      setNextFocusedModuleId();
    }
  };

  const setNextFocusedModuleId = () => {
    if (!matches.length) return;
    const newCurrentIndex = (currentIndex + 1) % matches.length;
    setFocusedModuleId(matches[newCurrentIndex].moduleId);
    setCurrentIndex(newCurrentIndex);
  };

  return (
    <TextField
      inputRef={searchRef}
      className={cx(classes.textField, { [classes.textFieldOpen]: isSearchOpen })}
      clearable
      debounced
      onClear={toggleSearch}
      onBlur={toggleSearch}
      onFocus={openSearch}
      onKeyPress={keyHandler}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={toggleSearch} size="small">
              <Search />
            </IconButton>
          </InputAdornment>
        )
      }}
      value={searchText}
      onChange={({ target: { value } }) => setSearchText(value)}
      data-testid={testIds.textField}
    />
  );
}
