import { RecoilState } from 'recoil';
import { TeamBody, TeamResponse } from '../../../../API';
import { localAtom } from '../../../../state/localStorageState';
import { DataManagerState, DataManagerStateFullReturnType } from '../../../DataManager/DataManagerState';

export interface TeamsStateFullReturnType extends DataManagerStateFullReturnType<TeamResponse, TeamBody> {
  withIsGlobalTeamSearch: RecoilState<boolean>;
}

export function TeamsState(name: string): TeamsStateFullReturnType {
  const genericState = DataManagerState<TeamResponse, TeamBody>(name);

  const withIsGlobalTeamSearch = localAtom<boolean>({
    key: `${name}.isGlobalTeamSearch`,
    default: true
  });

  return {
    ...genericState,
    withIsGlobalTeamSearch
  };
}
