import { ConfigResult, useConfig, useExperiment, useGate } from 'statsig-react';
import { useHandleError, useLocales } from '../General';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { withDebugMenuFeatureFlags } from '../../state/DebugMenu';
import { useStatsigAPI } from '../API/Statsig/useStatsigAPI';
import { withExperiments, withStatsigUiModulesConfig } from '../../state/Experiments';
import {
  StatsigTargetPlatformsPolicy,
  StatsigUiModuleConfig,
  StatsigUiModulesDefaultsConfig,
  TargetPlatform,
  TargetPlatformPolicy
} from '../../API';

export type useStatsigReturnType = {
  CheckGate: (key: string) => boolean | undefined;
  GetConfig: (key: string) => ConfigResult | undefined;
  GetExperiment: (key: string) => ConfigResult | undefined;
  getExperiments: () => Promise<void>;
  getUiModulesConfig: () => Promise<void>;
};

export const getAvailablePlatforms = (
  uiModulesDefaultConfig: StatsigUiModulesDefaultsConfig | undefined,
  uiModuleConfig: StatsigUiModuleConfig | undefined
): TargetPlatform[] | undefined => {
  const allPlatforms = Object.values(TargetPlatform);
  const getPlatformsFromPolicy = (targetPlatformsPolicy: StatsigTargetPlatformsPolicy) =>
    targetPlatformsPolicy.policy === TargetPlatformPolicy.BLOCK
      ? allPlatforms.filter((platform) => !targetPlatformsPolicy.targetPlatforms.includes(platform))
      : targetPlatformsPolicy.targetPlatforms;
  if (uiModuleConfig) {
    return getPlatformsFromPolicy(uiModuleConfig.targetPlatformsPolicy);
  }
  if (uiModulesDefaultConfig) {
    return getPlatformsFromPolicy(uiModulesDefaultConfig.targetPlatformsPolicy);
  }
};

export function useStatsig(): useStatsigReturnType {
  const statsigApi = useStatsigAPI();
  const { handleError } = useHandleError();
  const { t } = useLocales();
  const setExperiments = useSetRecoilState(withExperiments);
  const setUiModulesConfig = useSetRecoilState(withStatsigUiModulesConfig);

  const getExperiments = async () => {
    try {
      const { data } = await statsigApi.getExperiments();
      setExperiments(data.body);
    } catch (error) {
      console.error(error);
      handleError(error, t('errors.statsig.experiments'));
    }
  };

  const getUiModulesConfig = async () => {
    try {
      const {
        data: { body }
      } = await statsigApi.getUiModulesConfig();
      setUiModulesConfig(body);
    } catch (error) {
      console.error(error);
      handleError(error, t('errors.statsig.experiments'));
    }
  };

  const CheckGate = (gateName: string) => {
    const featureFlags = useRecoilValue(withDebugMenuFeatureFlags);
    try {
      const gateValue = useGate(gateName).value;
      if (featureFlags[gateName] !== undefined) {
        return featureFlags[gateName];
      }
      return gateValue;
    } catch (error) {
      console.error(error);
      handleError(error, t('errors.statsig.gates'), [gateName]);
    }
  };

  const GetConfig = (configName: string) => {
    try {
      return useConfig(configName);
    } catch (error) {
      console.error(error);
      handleError(error, t('errors.statsig.config'), [configName]);
    }
  };

  const GetExperiment = (experimentName: string) => {
    try {
      return useExperiment(experimentName);
    } catch (error) {
      console.error(error);
      handleError(error, t('errors.statsig.experiment'), [experimentName]);
    }
  };

  return {
    CheckGate,
    GetConfig,
    GetExperiment,
    getExperiments,
    getUiModulesConfig
  };
}
