/* eslint-disable @typescript-eslint/no-explicit-any */
import { atom, selector, AtomOptions, RecoilState, DefaultValue } from 'recoil';

const transforms = {
  string: {
    in: (x: any): string => x,
    out: (x: string) => x
  },
  number: {
    in: (x: any): string => String(x),
    out: (x: string) => parseFloat(x)
  },
  bigint: {
    in: (x: any): string => String(x),
    out: (x: string) => parseInt(x, 10)
  },
  boolean: {
    in: (x: any): string => String(x),
    out: (x: string) => x === 'true'
  },
  symbol: {
    in: (x: any): string => String(x),
    out: (x: string) => x
  },
  undefined: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    in: (x: any): string => 'undefined',
    out: () => undefined
  },
  object: {
    in: (x: any): string => JSON.stringify(x),
    out: (x: string) => JSON.parse(x)
  },
  function: {
    in: (x: any): string => String(x),
    out: (x: string) => x
  }
};

const LocalStorageKeys = 'local-storage-keys';

function getLocalStorageKeys(): Record<string, number> {
  const currentLocalStorage = localStorage.getItem(LocalStorageKeys);
  return currentLocalStorage ? JSON.parse(currentLocalStorage) : {};
}

function addLocalStorageKey(key: string) {
  const keys = getLocalStorageKeys();
  keys[key] = 1;
  localStorage.setItem(LocalStorageKeys, JSON.stringify(keys));
}

export function clearLocalStorage(): void {
  Object.keys(getLocalStorageKeys()).forEach((key) => {
    localStorage.removeItem(key);
  });
}

export function localAtom<T>(
  options: AtomOptions<T> & { default: any; filter?: (value: DefaultValue | T, defaultValue: any) => DefaultValue | T }
): RecoilState<T> {
  const key = options.key;
  const defaultType = typeof options.default;
  addLocalStorageKey(key);

  const optionsCopy = { ...options };
  const storedValue = localStorage.getItem(key);
  if (storedValue) {
    optionsCopy.default = transforms[defaultType].out(storedValue);
  }
  const theAtom = atom<T>(optionsCopy);
  const theSelector = selector<T>({
    key: `${key}_LOCAL`,
    get: ({ get }) => get(theAtom),
    set: ({ set }, newValue) => {
      const valueToStore = options.filter ? options.filter(newValue, options.default) : newValue;
      localStorage.setItem(key, transforms[typeof valueToStore].in(valueToStore));
      set(theAtom, valueToStore);
    }
  });

  return theSelector;
}
